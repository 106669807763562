/* eslint-disable */

import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Placement } from 'tippy.js';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SearchList } from '@/components/TiptapEditor/components/ui/SearchList';
import { useCurrentPublication } from '@/hooks';
import { usePaywall, usePaywalls } from '@/hooks/usePaywalls';
import usePostTheme from '@/hooks/usePostTheme';
import { Paywall } from '@/interfaces/paywall';
import { PaywallElement } from '@shared/dream-components';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

type PaywallItem = {
  value: string;
  label: string;
};

export const PaywallBreakView = ({ node, editor, getPos }: NodeViewWrapperProps) => {
  const isDefault = node.attrs?.data?.isDefault;
  const [isDataSet, setIsDataSet] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data: currentPublication } = useCurrentPublication();
  const { data: postTheme } = usePostTheme();
  const { data, isFetched } = usePaywalls();
  const { data: defaultPremiumPaywall, isFetched: isDefaultPremiumPaywallFetched } = usePaywall({
    paywallId: '1',
    defaultPremiumPaywall: true,
  });
  const paywalls = data?.pages.flatMap((page) => page.paywalls) || [];
  const hasPaywalls = paywalls.length > 0;
  const utilizeFallback = isFetched && isDefaultPremiumPaywallFetched && !hasPaywalls;
  const isLoading = !isFetched || !isDefaultPremiumPaywallFetched || !currentPublication || !postTheme;

  const handleSelectPaywall = useCallback(
    (paywallId: string) => {
      const paywall = paywalls.find((p: Paywall) => p.id === paywallId);

      editor
        .chain()
        .setNodeSelection(getPos())
        .updateAttributes('paywallBreak', {
          data: {
            isDefault: false,
            ...paywall,
          },
        })
        .run();
    },
    [editor, getPos, paywalls]
  );

  useEffect(() => {
    // Ensures all data is loaded before we determine if we should use the fallback
    if (!isDataSet && utilizeFallback && currentPublication) {
      const paywallData = {
        image_url: defaultPremiumPaywall?.image_url,
        name: defaultPremiumPaywall?.name,
        title: defaultPremiumPaywall?.title,
        description: defaultPremiumPaywall?.description,
        benefits: defaultPremiumPaywall?.benefits,
        subheader: defaultPremiumPaywall?.subheader,
        cta_text: defaultPremiumPaywall?.cta_text,
        style: defaultPremiumPaywall?.style,
      };

      editor.commands.updateAttributes('paywallBreak', {
        data: {
          isDefault: false,
          ...paywallData,
        },
      });

      // Set the data as set so we don't run this again
      setIsDataSet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataSet, utilizeFallback, isDefaultPremiumPaywallFetched, currentPublication]);

  return (
    <NodeViewWrapper
      data-drag-handle
      ref={wrapperRef}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isDefault ? (
            <SearchList
              tabIndex={-1}
              items={paywalls.map((paywall: Paywall) => ({
                value: paywall.id,
                label: paywall.name,
              }))}
              hasMore={false}
              searchable
              searchPlaceholder="Search for a paywall break"
              onSelect={(item: PaywallItem) => handleSelectPaywall(item.value)}
            />
          ) : (
            <PaywallElement
              theme={postTheme}
              element={{
                type: 'paywall_break',
                attrs: {
                  id: node.attrs.id,
                  data: node.attrs.data,
                },
                content: [{ id: 'text', text: '' }],
              }}
            >
              {null}
            </PaywallElement>
          )}
        </>
      )}
    </NodeViewWrapper>
  );
};

export default PaywallBreakView;
