interface Props {
  className?: string;
}

const PublicationIcon = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    className={className}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M9.75 1.125H3.375C2.97718 1.125 2.59564 1.28304 2.31434 1.56434C2.03304 1.84564 1.875 2.22718 1.875 2.625V10.5C1.875 10.5995 1.91451 10.6948 1.98484 10.7652C2.05516 10.8355 2.15054 10.875 2.25 10.875H9C9.09946 10.875 9.19484 10.8355 9.26517 10.7652C9.33549 10.6948 9.375 10.5995 9.375 10.5C9.375 10.4005 9.33549 10.3052 9.26517 10.2348C9.19484 10.1645 9.09946 10.125 9 10.125H2.625C2.625 9.92609 2.70402 9.73532 2.84467 9.59467C2.98532 9.45402 3.17609 9.375 3.375 9.375H9.75C9.84946 9.375 9.94484 9.33549 10.0152 9.26517C10.0855 9.19484 10.125 9.09946 10.125 9V1.5C10.125 1.40054 10.0855 1.30516 10.0152 1.23483C9.94484 1.16451 9.84946 1.125 9.75 1.125ZM9.375 8.625H3.375C3.11163 8.62464 2.85286 8.69402 2.625 8.82609V2.625C2.625 2.42609 2.70402 2.23532 2.84467 2.09467C2.98532 1.95402 3.17609 1.875 3.375 1.875H9.375V8.625Z"
      fill="#7C3AED"
    />
  </svg>
);

export default PublicationIcon;
