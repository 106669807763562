import { FC } from 'react';
import { PricingCardsElement, TPricingCardsAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

export const PricingCardsView: FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <PricingCardsElement
      element={{
        type: 'pricingCards',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TPricingCardsAttributes,
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {null}
    </PricingCardsElement>
  );
};
