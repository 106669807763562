import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';

import { usePostContext } from '../../PostContext';

const SubtitleTooltipMenu = () => {
  const { onChange } = usePostContext();

  const deleteSubtitle = () => {
    onChange({ web_subtitle: '' });
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Button $variant="quaternary" $leftSlot={<Icon name="Trash" />} onClick={deleteSubtitle} />
    </div>
  );
};

export default SubtitleTooltipMenu;
