import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';

export const SurveyWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['survey']}>
      <SectionRenderer title="Container">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
      <SectionRenderer title="Inputs">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Accent" editor={editor} activeNodeResult={activeNodeResult} property="accentColor" />
      </SectionRenderer>
    </RenderCondition>
  );
};
