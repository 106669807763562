import { Input, Switch, Textarea } from '@/components/Form';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { StepErrors } from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Create/Steps/types';

import BodyContainer from '../../components/BodyContainer';
import FormRow from '../../components/FormRow';

interface Props {
  endpoint: Partial<WebhookEndpoint>;
  errors: StepErrors;
  onChange: (newEndpoint: Partial<WebhookEndpoint>) => void;
}

const DetailsForm = ({ endpoint, errors, onChange }: Props) => {
  const { url, description, disabled } = endpoint;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...endpoint,
        [field]: value,
      });
    }
  };

  return (
    <BodyContainer>
      <Input
        type="text"
        name="url"
        label="URL"
        id="create-endpoint-url"
        labelText="Endpoint URL"
        value={url}
        helperText="Choose the location where you want the webhook to be sent to"
        placeholder="https://example.com/webhook"
        onChange={(e) => handleChange('url', e.target.value)}
        required
        errorText={errors?.url}
      />
      <Textarea
        name="description"
        labelText="Description"
        helperText="Explain a description of the endpoint to help you identify it later."
        placeholderText="Premium subscriber updates sent to Zapier..."
        value={description}
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <FormRow label="Webhook Status" helperText="Disable this to stop receiving webhooks at the endpoint url above.">
        <Switch
          name="enabled"
          checked={!disabled}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('disabled', !checked)}
        />
      </FormRow>
    </BodyContainer>
  );
};

export default DetailsForm;
