import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SocialsView } from './views/SocialsView';

export const Socials = Node.create<{}, {}>({
  name: 'socials',

  group: 'block',

  addAttributes() {
    return {
      data: {
        default: {},
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  parseHTML() {
    return [{ tag: `div[data-type=${this.name}]` }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SocialsView);
  },
});
