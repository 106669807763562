import { useCallback } from 'react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

export const NewTabSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = useActiveNode(editor);

  const updateAlignment = useCallback(
    (value: string) => {
      if (!value) return;
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        // this one seems more promising because it does not
        // override the other attributes.
        tr.setNodeAttribute(activeNodePos - 1, 'align', value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Align
      </Text>
      <div className="grow bg-wb-tertiary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="left"
          value={activeNodeAttributes?.alignment}
          onValueChange={updateAlignment}
        >
          <ToggleGroupItem value="left" className="grow">
            <Text variant="secondary" size="2xs" weight="medium">
              YES
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="center" className="grow">
            <Text variant="secondary" size="2xs" weight="medium">
              NO
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
