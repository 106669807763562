import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ClockIcon, GiftIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { useCurrentUser } from '@/context/current-user-context';
import useClaimOffer from '@/hooks/useClaimOffer';
import useEligibleDowngradeOffers from '@/hooks/useDowngrades/useEligibleDowngradeOffers';
import { useCreatePausePlan } from '@/hooks/usePausePlan';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Organization } from '@/interfaces/organization';
import { IUser } from '@/interfaces/user';
import CardHeader from '@/routes/settings/publication/components/CardHeader';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import OfferCard from '../components/OfferCard';

interface Props {
  organization?: Organization;
  user?: IUser;
  downgradeId: string;
  step: number;
  previousStep: number;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const OfferAndPause = (props: Props) => {
  const { user, downgradeId, step, previousStep, onPreviousStep, onNextStep, onCancel, isLoading, organization } =
    props;
  const { currentUser } = useCurrentUser();
  const claimOffer = useClaimOffer();
  const pausePlan = useCreatePausePlan();
  const currentPublicationId = useCurrentPublicationId();
  const { data: publication } = usePublication(currentPublicationId);
  const { data: offers } = useEligibleDowngradeOffers({ organizationId: publication?.organization_id, downgradeId });
  const navigate = useNavigate();

  useEffect(() => {
    if (offers?.claim_discount === false && offers?.pause_plan === false) {
      if (step < previousStep) {
        onPreviousStep();
      } else {
        onNextStep();
      }
    }
  }, [offers, step, previousStep, onPreviousStep, onNextStep]);

  const onHandleNextStep = () => {
    onNextStep();
  };

  const onClaimOffer = () => {
    claimOffer.mutate(
      {
        organizationId: organization?.id,
        downgradeId,
      },
      {
        onSuccess: () => {
          navigate('/settings/billing?offer_applied=true');
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
          });
          analytics.track('Downgrade Offer Claimed', {
            downgradeOfferPercent: 25,
            downgradeOfferLength: 3,
          });
        },
        onError: () => {
          toast.error('Unable to claim offer. Please try again.');
        },
      }
    );
  };

  const onPausePlan = () => {
    pausePlan.mutate(
      {
        organizationId: organization?.id,
        downgradeId,
      },
      {
        onSuccess: () => {
          navigate('/settings/billing');
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
          });
          analytics.group(publication?.organization_id, {
            name: organization?.name,
            object_type_id: 3,
          });
          analytics.track('Pause Offer Claimed', {
            downgradePauseLength: 30,
          });
        },
        onError: () => {
          toast.error('Unable to pause plan. Please try again.');
        },
      }
    );
  };

  const ClaimOffer = (
    <OfferCard
      title="25% off for 3 months"
      subtitle="Save on the next 3 months of your current plan."
      onClick={onClaimOffer}
      buttonText="Claim Offer"
      subText="Upon accepting, the offer will be applied to your bill for the next 3 months."
      Icon={GiftIcon}
      ImageSrc="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/downgrade/discount_offer.png"
      ImageAlt="Claim discount 3 months beehiiv plan"
    />
  );

  const PausePlan = (
    <OfferCard
      title="Pause for up to 30 days"
      subtitle="Upon your next billing date, your publication(s) will be moved to our free 'Launch' tier for up to 30 days, or until you decide to unpause."
      onClick={onPausePlan}
      buttonText="Pause Plan"
      subText="Upon accepting, you will be redirected to review the changes on pausing. Your plan will not be paused yet."
      Icon={ClockIcon}
      ImageSrc="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/downgrade/pause_plan.png"
      ImageAlt="Pause beehiiv plan"
    />
  );

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-6 justify-between min-h-[80vh]">
      <div className="flex flex-col gap-10">
        <CardHeader
          title="Claim exclusive savings or pause your plan"
          description=" We'd hate to lose you as a customer. If pricing is an issue or you need to take a break from your newsletter, check out one of the options below."
        />
        <div className="flex flex-col gap-4">
          {offers?.claim_discount && ClaimOffer}
          {offers?.pause_plan && PausePlan}
        </div>
      </div>
      <div className="flex flex-col gap-y-2 gap-x-2 justify-end sm:flex-row sm:justify-between">
        <Button variant="flush" Icon={ArrowLeftIcon} onClick={onPreviousStep}>
          Back
        </Button>

        <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>

          <Button variant="primary" loading={isLoading} onClick={onHandleNextStep}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OfferAndPause;
