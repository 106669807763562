import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CheckIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Input, SimpleSelect } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { PublicationLogo } from '@/components/PublicationLogo';
import useDisbursementApprove from '@/hooks/useAdNetwork/internal/useDisbursementApprove';
import useDisbursementUpdate from '@/hooks/useAdNetwork/internal/useDisbursementUpdate';
import useOpportunityStats from '@/hooks/useAdNetwork/internal/useOpportunityStats';
import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';
import {
  AdNetworkDisbursementPayoutAdjustmentReason,
  AdNetworkDisbursementPayoutAdjustmentReasonLabelHash,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';

interface Props {
  disbursement: AdNetworkDisbursement;
  modalOpen: boolean;
  onClose: () => void;
}

const DisbursementApproveModal = ({ disbursement, modalOpen, onClose }: Props) => {
  const { opportunity } = disbursement;
  const { publication, advertiser, campaign } = opportunity;
  const [isApproving, setIsApproving] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [payoutAdjustmentReasonError, setPayoutAdjustmentReasonError] = useState<string | null>(null);
  const [approvedAmountCents, setApprovedAmountCents] = useState(disbursement.approved_amount_cents);
  const [payoutAdjustmentReason, setPayoutAdjustmentReason] =
    useState<AdNetworkDisbursementPayoutAdjustmentReason | null>(disbursement.payout_adjustment_reason);
  const { data: stats } = useOpportunityStats({ id: opportunity.id });
  const needsAdjustmentReason = approvedAmountCents !== disbursement.amount_cents;
  const hasAdjustedAmount = disbursement.approved_amount_cents !== disbursement.amount_cents;
  const modalId = `approve-disbursement-${disbursement.id}`;
  const queryClient = useQueryClient();
  const updateDisbursement = useDisbursementUpdate({
    id: disbursement.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['ad_network', 'internal', 'disbursements']);
    },
  });
  const numberFormatter = new Intl.NumberFormat();

  const isCpc = opportunity.payout_model === AdNetworkPayoutModel.CPC;
  const payoutRate = isCpc ? opportunity.payout_per_click : opportunity.payout_per_mille;

  const eventName = isCpc ? 'Clicks' : 'Opens';
  const eligibleEvents = isCpc ? stats?.total_unique_eligible_clicked : stats?.total_unique_eligible_opened;
  const approvedEvents = isCpc ? disbursement.approved_clicks : disbursement.approved_opens;
  const hasAdjustedEvents = eligibleEvents !== approvedEvents;

  const payoutAdjustmentReasonOptions = [
    ...Object.keys(AdNetworkDisbursementPayoutAdjustmentReasonLabelHash).map((key) => ({
      label: AdNetworkDisbursementPayoutAdjustmentReasonLabelHash[key as AdNetworkDisbursementPayoutAdjustmentReason],
      value: key,
    })),
  ] as Option[];

  const handleModalClose = () => {
    onClose();
    setConfirmApprove(false);
    setIsEditing(false);
    setApprovedAmountCents(disbursement.approved_amount_cents);
    setPayoutAdjustmentReason(disbursement.payout_adjustment_reason);
    setPayoutAdjustmentReasonError(null);
  };
  const onApproveSuccess = () => {
    handleModalClose();
    setIsApproving(false);
    queryClient.invalidateQueries(['ad_network', 'internal', 'disbursements']);
  };
  const disbursementApproval = useDisbursementApprove({ id: disbursement.id, onSuccess: onApproveSuccess });
  const handleApproveDisbursement = () => {
    setIsApproving(true);
    disbursementApproval.mutateAsync();
  };
  const handleApprovedAmountChange = (value: number) => {
    if (value === disbursement.amount_cents && !hasAdjustedEvents) {
      setPayoutAdjustmentReason(null);
    }

    setApprovedAmountCents(value);
  };
  const handleDoneEditing = () => {
    if (!payoutAdjustmentReason && needsAdjustmentReason) {
      setPayoutAdjustmentReasonError('Please select a reason for the adjustment');
      return;
    }

    setIsEditing(false);
    updateDisbursement.mutateAsync({
      approved_amount_cents: approvedAmountCents,
      payout_adjustment_reason: payoutAdjustmentReason,
    });
  };
  const handleAdjustmentReasonSelect = (_name: string, value: string) => {
    setPayoutAdjustmentReason(value as AdNetworkDisbursementPayoutAdjustmentReason);
    setPayoutAdjustmentReasonError(null);
  };

  return (
    <ActionModal
      resourceId={disbursement.id}
      isOpen={modalOpen}
      isWorking={isApproving}
      onClose={handleModalClose}
      onProceed={handleApproveDisbursement}
      headerText="Approve Disbursement"
      actionText="Approve"
      disabled={!confirmApprove || isEditing}
      bodyId={modalId}
    >
      <div className="space-y-4">
        <div className="text-sm">
          <p className="text-xl mb-4">Publication</p>
          <div className="flex flex-row items-center">
            <PublicationLogo
              publication={{ logo_url: publication.logo.url, name: publication.name }}
              size="sm"
              className="mr-2"
            />
            <div>
              <p>{publication.name}</p>
              <p className="text-xs text-gray-500">{publication.owner_email}</p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <p className="text-xl mb-4">Advertiser</p>
          <div className="flex flex-col">
            <div className="mr-2">
              <img src={advertiser.logo.url} alt={advertiser.name} className="border border-gray-100 rounded h-8" />
            </div>
            <div>
              <p>{advertiser.name}</p>
              <p className="text-xs text-gray-500">{campaign.name}</p>
            </div>
          </div>
        </div>
        {!isEditing && (
          <div className="text-sm">
            <div className="flex flex-row items-center justify-between mb-4">
              <p className="text-xl">Payout</p>
              <Button
                variant="primary-inverse"
                size="xs"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit
                <PencilSquareIcon className="w-4 h-4 ml-2" />
              </Button>
            </div>
            <div className="space-y-2">
              <div className="flex flex-row items-center justify-between">
                <p>Publisher <span className="uppercase">{opportunity.payout_model}</span></p>
                <p>{payoutRate}</p>
              </div>

              <div className="flex flex-row items-center justify-between">
                <p>{hasAdjustedEvents ? `Adjusted ${eventName}` : eventName}</p>
                {hasAdjustedEvents ? (
                  <p className="space-x-2">
                    <span className="line-through">
                      {numberFormatter.format(eligibleEvents || 0)}
                    </span>
                    <span>{numberFormatter.format(approvedEvents || 0)}</span>
                  </p>
                ) : (
                  <p>{numberFormatter.format(approvedEvents || 0)}</p>
                )}
              </div>

              <div className="flex flex-row items-center justify-between">
                <p>{hasAdjustedAmount ? 'Adjusted Payout' : 'Total Payout'}</p>
                {hasAdjustedAmount ? (
                  <p className="space-x-2">
                    <span className="line-through">{disbursement.amount}</span>
                    <span>{disbursement.approved_amount}</span>
                  </p>
                ) : (
                  <p>{disbursement.amount}</p>
                )}
              </div>

              {disbursement.payout_adjustment_reason && (
                <div className="flex flex-row items-center justify-between">
                  <p>Adjustment Reason</p>
                  <p>{AdNetworkDisbursementPayoutAdjustmentReasonLabelHash[disbursement.payout_adjustment_reason]}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {isEditing && (
          <div className="text-sm">
            <div className="flex flex-row items-center justify-between">
              <p className="text-xl mb-4">Payout</p>
              <button
                type="button"
                className="text-primary-400 hover:text-primary-700 hover:cursor-pointer"
                onClick={handleDoneEditing}
              >
                Done
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex flex-row items-center justify-between">
                <p>Publisher <span className="uppercase">{opportunity.payout_model}</span></p>
                <p>{payoutRate}</p>
              </div>
              <div className="flex flex-row items-center justify-between">
                <p>{hasAdjustedEvents ? `Adjusted ${eventName}` : eventName}</p>
                {hasAdjustedEvents ? (
                  <p className="space-x-2">
                    <span className="line-through">
                      {numberFormatter.format(eligibleEvents || 0)}
                    </span>
                    <span>{numberFormatter.format(approvedEvents || 0)}</span>
                  </p>
                ) : (
                  <p>{numberFormatter.format(approvedEvents || 0)}</p>
                )}
              </div>
              <div className="flex flex-row items-center justify-between">
                <p>Adjusted Payout</p>
                <CurrencyInput
                  name="approved_amount_cents"
                  placeholder="Enter an amount"
                  value={approvedAmountCents}
                  onChange={handleApprovedAmountChange}
                  labelText="Adjust Payout Amount"
                  required
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <p>Adjustment Reason</p>
                <SimpleSelect
                  name="payout_adjustment_reason"
                  value={payoutAdjustmentReason?.toString() || ''}
                  onSelect={handleAdjustmentReasonSelect}
                  options={payoutAdjustmentReasonOptions}
                  placeholderText="Select a reason"
                  className="w-1/2"
                  errorText={payoutAdjustmentReasonError?.toString()}
                  disabled={!needsAdjustmentReason}
                />
              </div>
            </div>
          </div>
        )}
        <p>Are you sure you want to approve this disbursement?</p>
        <div>
          <p className="text-gray-500">Type &quot;{disbursement.approved_amount}&quot; below to continue.</p>
          <div className="flex flex-row items-center">
            <Input
              className="w-1/2"
              name="confirm-delete"
              placeholderText={disbursement.approved_amount}
              onChange={(e) => {
                setConfirmApprove(
                  e.target.value.trim().replace('$', '') === disbursement.approved_amount.replace('$', '')
                );
              }}
            />
            {confirmApprove ? (
              <CheckIcon className="text-green-500 w-5 h-5 ml-2" />
            ) : (
              <XMarkIcon className="text-red-500 w-5 h-5 ml-2" />
            )}
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default DisbursementApproveModal;
