import React from 'react';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useImports } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Import } from '@/interfaces/import';
import { Button } from '@/ui/Button';

const HistoricalImports: React.FunctionComponent = () => {
  const currentPublicationId = useCurrentPublicationId();
  const useImportsQuery = useImports(currentPublicationId);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = useImportsQuery;
  const historicalImports = data?.pages.flatMap((page) => page.imports) || [];
  const totalHistoricalImportCount = data?.pages[0]?.pagination?.total;
  const showingHistoricalImportsCount = historicalImports?.length || 0;

  const statusColor = (historicalImport: Import) => {
    const importAndValidationStatus = historicalImport.import_and_validation_status;
    if (importAndValidationStatus === 'failed') {
      return 'bg-red-100';
    }
    if (importAndValidationStatus === 'completed') {
      return 'bg-green-100';
    }
    return 'bg-gray-100';
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center pb-6">
        <div className="flex flex-col gap-1 w-full">
          <div className="flex items-center">
            <Badge>
              Showing {showingHistoricalImportsCount} of {totalHistoricalImportCount}
            </Badge>
          </div>
        </div>

        <div className="w-full md:w-96 pt-4 md:pt-0 relative">
          {(isLoading || isFetching) && (
            <div className="hidden md:block absolute -left-6 top-2.5">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      {showingHistoricalImportsCount > 0 && (
        <div className="mb-6">
          <div className="rounded-lg border border-gray-200 max-h-72 overflow-y-auto">
            <div className="bg-gray-50 px-6 space-x-6 grid grid-cols-12 border-b border-gray-200 sticky top-0">
              <div className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider col-span-4">Date</div>
              <div className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider col-span-5">Status</div>
              <div className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider col-span-3">Accepted</div>
            </div>
            <div className="bg-white divide-y divide-y-gray-200">
              {historicalImports.map((historicalImport) => (
                <div className="px-6 space-x-6 grid grid-cols-12" key={historicalImport.id}>
                  <div className="py-3 text-sm text-gray-900 col-span-4">
                    {moment(historicalImport.created_at).format('lll')}
                  </div>
                  <div className="py-3 text-xs text-gray-900 col-span-5">
                    <div
                      className={`${statusColor(
                        historicalImport
                      )} border shadow-sm inline-flex items-center rounded-md text-xs font-medium text-gray-800 border-gray-200 px-2.5 py-0.5`}
                    >
                      {historicalImport.import_and_validation_status}
                    </div>
                    &nbsp;
                    {historicalImport.failure_reason && <span>{`(${historicalImport.failure_reason})`}</span>}
                  </div>
                  <div className="py-3 text-sm text-gray-900 col-span-3">
                    {historicalImport.imported_subscription_count}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {hasNextPage && (
        <div className="text-center my-6">
          <div>
            <Button
              variant="primary-inverse"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default HistoricalImports;
