import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { SimpleSelect } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

interface Props {
  onSelection: (value: any) => void;
  defaultValue?: string;
}

const FormOptions = ({ onSelection, defaultValue }: Props) => {
  const [selectedForm, setSelectedForm] = useState<any>(defaultValue);
  const publicationId = useCurrentPublicationId();
  const formsData = useOptions(publicationId, 'forms', Infinity, { status: 'live' });
  const formOptions =
    formsData?.data?.options?.map((form: any) => ({
      value: form.id,
      label: form.title,
    })) || [];

  const noForms = formOptions?.length === 0;

  useEffect(() => {
    if (defaultValue) {
      setSelectedForm(defaultValue);
    }
  }, [defaultValue]);

  const handleSetSelection = (selectedValue: string) => {
    setSelectedForm(selectedValue);
    onSelection(selectedValue);
  };

  if (formsData && noForms) {
    return (
      <Link to="/forms" className="text-primary-500 underline underline-primary-500">
        No forms found. Create a form to start!
      </Link>
    );
  }

  return (
    <div className="space-y-4">
      <SimpleSelect
        name="value"
        labelText="Select a form"
        placeholderText="No Form Selected"
        value={selectedForm}
        helperText="Only forms that are live will be shown here."
        onSelect={(name: string, selectedValue: string) => handleSetSelection(selectedValue)}
        options={formOptions}
      />
    </div>
  );
};

export default FormOptions;
