import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor, JSONContent } from '@tiptap/react';

import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { Post } from '@/interfaces/post';

export type EditorContextContent = {
  editor: Editor | null;
  setEditor: (editor: Editor) => void;
  editorIsLoading: boolean;
  setEditorIsLoading: (isLoading: boolean) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  wordCount: number;
  setWordCount: (wordCount: number) => void;
  provider: TiptapCollabProvider | undefined;
  setProvider: (provider: TiptapCollabProvider | undefined) => void;
  formData: Post | undefined;
  users: EditorUser[];
  setUsers: (users: EditorUser[]) => void;
  showSidebar: boolean;
  setShowSidebar: (showSidebar: boolean) => void;
  openSidebar?: 'threads' | 'backlinks' | null;
  setOpenSidebar?: (openSidebar: 'threads' | 'backlinks' | null) => void;
  showSearchAndReplaceMenu: boolean;
  setShowSearchAndReplaceMenu: (showSearchAndReplaceMenu: boolean) => void;
  collaborationEnabled: boolean;
  showThreadsSidebar: boolean;
  setShowThreadsSidebar: (showThreads: boolean) => void;
  loadingNodes: Record<string, boolean>;
  setLoadingNodes: Dispatch<SetStateAction<Record<string, boolean>>>;

  threads: TCollabThread[] | null;
  activeThreadId: string | null;
  unresolvedThreadsCount: number;
  setActiveThreadId: (threadId: string | null) => void;
  selectThread: (threadId: string) => void;
  unselectThread: () => void;
  createThread: (content: JSONContent) => void;
  highlightThread: (threadId: string) => void;
  removeHighlightThread: (threadId: string) => void;
};

export const EditorContext = createContext<EditorContextContent>({
  formData: undefined,

  editor: null,
  setEditor: () => {},
  editorIsLoading: false,
  setEditorIsLoading: () => {},
  isSaving: false,
  setIsSaving: () => {},
  unsavedChanges: false,
  setUnsavedChanges: () => {},
  wordCount: 0,
  setWordCount: () => {},
  provider: undefined,
  setProvider: () => {},
  users: [],
  setUsers: () => {},
  showSidebar: true,
  setShowSidebar: () => {},
  openSidebar: null,
  setOpenSidebar: () => {},
  showSearchAndReplaceMenu: false,
  setShowSearchAndReplaceMenu: () => {},
  collaborationEnabled: true,
  showThreadsSidebar: false,
  loadingNodes: {},
  setLoadingNodes: () => {},

  // threads
  activeThreadId: null,
  threads: [],
  unresolvedThreadsCount: 0,

  createThread: () => {},
  highlightThread: () => {},
  removeHighlightThread: () => {},
  selectThread: () => {},
  setActiveThreadId: () => {},
  setShowThreadsSidebar: () => {},
  unselectThread: () => {},
});

export const useEditorContext = () => useContext(EditorContext);
