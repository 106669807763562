import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import ActionModal from '@/components/ActionModal';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onProceed: () => void;
  domain: CustomDomain;
  type: CustomDomainTypes;
}

const DomainConfigModal = ({ isOpen, isLoading, onClose, onProceed, domain, type }: Props) => {
  const forEmail = type === CustomDomainTypes.EMAIL;
  let records = forEmail ? domain.email_authenticated_domains : domain.web_authenticated_domains;
  if (forEmail && domain.branded_link_added && domain.branded_link_pending) {
    records = records.concat(domain.authenticated_branded_links);
  }

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={isLoading}
      onClose={onClose}
      onProceed={onProceed}
      resourceId={domain.id}
      headerText="Verify Domain Configuration"
      actionText="Verify Setup"
      modalSize="md"
    >
      <div className="space-y-4 text-sm text-gray-500">
        <p>
          Follow the instructions below, in order to setup{' '}
          <code className="bg-gray-100 px-2 py-1 rounded-sm">{domain.domain}</code>.
        </p>
        <div>
          <p className="font-medium">Create DNS records</p>
          <p>
            Go to your DNS provider and create <strong>all</strong> of the following records with the specified
            values. Once done, click &quot;Verify Setup&quot; below to check your {type} configuration. We
            additionally will check on your behalf over the next 24 hours.
          </p>
        </div>
        <div>
          {forEmail && (
            <p>
              These records will validate that you own this domain so that our email provider can generate the
              corresponding SPF and DKIM for secure and verified sending.
            </p>
          )}
          {records.map((record) => (
            <DnsRecordDisplay key={record.name} record={record} className="my-8" />
          ))}
        </div>
      </div>
    </ActionModal>
  );
};

export default DomainConfigModal;
