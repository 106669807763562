import { useState } from 'react';
import toast from 'react-hot-toast';

import { Input, Textarea } from '@/components/Form';
import HelperText from '@/components/Form/HelperText';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import useUpdateCurrentUser from '@/hooks/useUsers/useUpdateUser';
import BodyContainer from '@/routes/settings/publication/components/BodyContainer';
import CardHeader from '@/routes/settings/publication/components/CardHeader';
import FileUploader from '@/ui/FileUploader';

const PersonalInfoForm = () => {
  const [savingAttribute, setSavingAttribute] = useState<string | null>(null);

  const { currentUser } = useCurrentUser();
  const { mutateAsync: updateUser, isLoading } = useUpdateCurrentUser(() => {
    toast.success('Profile updated!');
  });

  const handleUpdateProfilePicture = async (logo: any) => {
    const formData = new FormData();
    formData.append('profile_picture', logo);

    updateUser(formData);
  };

  const handleChangeInAttribute = (name: string) => async (value: any) => {
    try {
      setSavingAttribute(name);
      updateUser({
        [name]: value,
      });
    } catch (error: any) {
      // do nothing
    }
    setSavingAttribute(null);
  };

  return (
    <BodyContainer>
      <CardHeader
        title="Your Personal Info"
        description="All your account specific details and author profile information"
      />
      <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-6">
        <div className="flex flex-col gap-y-0.5">
          <FileUploader onSave={handleUpdateProfilePicture} isBusy={isLoading}>
            <div className="w-44 h-44">
              {currentUser?.profile_picture?.thumb?.url ? (
                <img src={currentUser?.profile_picture?.thumb?.url} alt="" className="w-full h-full object-cover" />
              ) : null}
            </div>
          </FileUploader>
          <Typography token="font-light/text/xs">Recommended: 800x800 px</Typography>
        </div>
        <TypographyStack>
          <Typography token="font-medium/text/sm">Profile Picture</Typography>
          <Typography token="font-normal/text/sm" colorWeight="500">
            This will be the avatar that appears on your author profile and within the posts you write
          </Typography>
        </TypographyStack>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 gap-x-4 gap-y-4">
        <Input
          labelText="First Name"
          name="first_name"
          defaultValue={currentUser?.first_name}
          onBlur={(e) => handleChangeInAttribute('first_name')(e.target.value)}
          disabled={savingAttribute === 'first_name'}
        />
        <Input
          labelText="Last Name"
          name="last_name"
          defaultValue={currentUser?.last_name}
          onBlur={(e) => handleChangeInAttribute('last_name')(e.target.value)}
          disabled={savingAttribute === 'last_name'}
        />
      </div>
      <Textarea
        name="bio"
        labelText="Bio"
        helperText={
          <HelperText>
            Recommended: <strong>100</strong> characters.
          </HelperText>
        }
        onBlur={(e) => handleChangeInAttribute('bio')(e.target.value)}
        placeholderText="Brief description for your author profile bio."
        defaultValue={currentUser?.bio}
        disabled={savingAttribute === 'bio'}
      />
    </BodyContainer>
  );
};

export default PersonalInfoForm;
