import { useCallback, useEffect, useState } from 'react';
import { CaretDown, LinkBreak, LinkSimple } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../UI/DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

const getDimensionType = (dimensionType: 'fixed' | 'relative' | 'fill' | 'fit') => {
  switch (dimensionType) {
    case 'fixed':
      return 'px';
    case 'relative':
    case 'fill':
      return '%';
    default:
      return '';
  }
};

type DimensionSettingsProps = AttributeSettingProps & {
  properties?: {
    height: string;
    width: string;
  };
};

const DEFAULT_DIMENSION_PROPS = {
  height: 'height',
  width: 'width',
};

const parseCssValue = (value: string) => {
  if (!value) return 0;
  if (typeof value === 'number') return value;
  if (value === 'auto') {
    return 0;
  }
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};

export const DimensionSettings = ({
  editor,
  activeNodeResult,
  properties = DEFAULT_DIMENSION_PROPS,
}: DimensionSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const [height, setHeight] = useState<number>(parseCssValue(activeNodeAttributes[properties.height]));
  const [width, setWidth] = useState<number>(parseCssValue(activeNodeAttributes[properties.width]));

  const [heightDimensionType, setHeightDimensionType] = useState<'fixed' | 'relative' | 'fill' | 'fit'>(
    activeNodeAttributes[properties.height] === undefined ? 'fit' : 'fixed'
  );
  const [widthDimensionType, setWidthDimensionType] = useState<'fixed' | 'relative' | 'fill' | 'fit'>(
    activeNodeAttributes[properties.width] === undefined ? 'fit' : 'fixed'
  );

  const [aspectRatio, setAspectRatio] = useState<number>(-1);
  const [aspectRatioLock, setAspectRatioLock] = useState(false);

  useEffect(() => {
    let heightValue = '';
    let widthValue = '';

    if (heightDimensionType === 'fill') {
      heightValue = '100%';
    }
    if (widthDimensionType === 'fill') {
      widthValue = '100%';
    }
    if (heightDimensionType === 'fit') {
      heightValue = 'auto';
    }
    if (widthDimensionType === 'fit') {
      widthValue = 'auto';
    }
    if (heightDimensionType === 'fixed') {
      heightValue = `${height}px`;
    }
    if (widthDimensionType === 'fixed') {
      widthValue = `${width}px`;
    }

    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, properties.height, heightValue);
      tr.setNodeAttribute(activeNodePos, properties.width, widthValue);
      return true;
    });
  }, [height, width, activeNodePos, properties, editor, heightDimensionType, widthDimensionType]);

  useEffect(() => {
    let calculatedAspectRatio = width / height;
    if (Number.isNaN(calculatedAspectRatio)) {
      // division by 0
      calculatedAspectRatio = 1;
    }
    setAspectRatio(calculatedAspectRatio);
  }, [aspectRatioLock, width, height]);

  const options: Record<string, string> = {
    fixed: 'Fixed',
    relative: 'Rel',
    fill: 'Fill',
    fit: 'Fit',
  };

  const handleHeightDimensionTypeChange = useCallback(
    (dimensionType: 'fixed' | 'relative' | 'fill' | 'fit') => {
      setHeightDimensionType(dimensionType);
      if (aspectRatioLock) {
        setWidthDimensionType(dimensionType);
      }
    },
    [aspectRatioLock]
  );

  const handleWidthDimensionTypeChange = useCallback(
    (dimensionType: 'fixed' | 'relative' | 'fill' | 'fit') => {
      setWidthDimensionType(dimensionType);
      if (aspectRatioLock) {
        setHeightDimensionType(dimensionType);
      }
    },
    [aspectRatioLock]
  );

  const handleWidthChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setWidth(0);
        return;
      }

      setWidth(parseInt(e.target.value, 10));
      if (aspectRatioLock) {
        setHeight(parseInt(e.target.value, 10) / aspectRatio);
      }
    },
    [aspectRatioLock, aspectRatio]
  );

  const handleHeightChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setHeight(0);
        return;
      }

      setHeight(parseInt(e.target.value, 10));
      if (aspectRatioLock) {
        setWidth(parseInt(e.target.value, 10) * aspectRatio);
      }
    },
    [aspectRatioLock, aspectRatio]
  );

  const AspectRatioLockIcon = aspectRatioLock ? LinkSimple : LinkBreak;

  return (
    <>
      <div className="relative flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          Width
        </Text>

        <div className="absolute top-[20%] left-[28%] h-[70px] flex flex-col items-center justify-center">
          <AspectRatioLockIcon
            size={14}
            className="cursor-pointer data-[active=true]:text-wb-accent z-10"
            data-active={aspectRatioLock}
            onClick={() => setAspectRatioLock(!aspectRatioLock)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="70"
            viewBox="0 0 12 70"
            className="absolute top-0 left-0"
          >
            <path
              d="M 5.75 22 L 5.75 18.75 C 5.75 17.093 7.093 15.75 8.75 15.75 L 11 15.75"
              fill="transparent"
              strokeWidth="1.75"
              stroke="#dddddd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M 5.75 47.75 L 5.75 51 C 5.75 52.657 7.093 54 8.75 54 L 11 54"
              fill="transparent"
              strokeWidth="1.75"
              stroke="#dddddd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="grow flex gap-2 min-w-0">
          <SimpleInputWrapper>
            {widthDimensionType === 'fit' && (
              <Text size="2xs" weight="medium" className="w-full">
                Auto
              </Text>
            )}
            {widthDimensionType === 'fill' && <SimpleInput type="number" value={100} disabled />}
            {(widthDimensionType === 'fixed' || widthDimensionType === 'relative') && (
              <SimpleInput type="number" value={width} onChange={handleWidthChange} />
            )}
            <Text size="2xs" variant="secondary" weight="medium">
              {getDimensionType(widthDimensionType)}
            </Text>
          </SimpleInputWrapper>
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="cursor-pointer">
              <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
                <div className="flex items-center gap-1">
                  <Text size="2xs" weight="medium">
                    {options[widthDimensionType]}
                  </Text>
                </div>
                <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
              {Object.keys(options).map((optionKey) => (
                <DropdownMenuItem
                  key={optionKey}
                  onSelect={() => {
                    handleWidthDimensionTypeChange(optionKey as 'fixed' | 'relative' | 'fill' | 'fit');
                  }}
                >
                  {options[optionKey]}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          Height
        </Text>

        <div className="grow flex gap-2 min-w-0">
          <SimpleInputWrapper>
            {heightDimensionType === 'fit' && (
              <Text size="2xs" weight="medium" className="w-full">
                Auto
              </Text>
            )}
            {heightDimensionType === 'fill' && <SimpleInput type="number" value={100} disabled />}
            {(heightDimensionType === 'fixed' || heightDimensionType === 'relative') && (
              <SimpleInput type="number" value={height} onChange={handleHeightChange} />
            )}
            <Text size="2xs" variant="secondary" weight="medium">
              {getDimensionType(heightDimensionType)}
            </Text>
          </SimpleInputWrapper>
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="cursor-pointer">
              <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
                <div className="flex items-center gap-1">
                  <Text size="2xs" weight="medium">
                    {options[heightDimensionType]}
                  </Text>
                </div>
                <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
              {Object.keys(options).map((optionKey) => (
                <DropdownMenuItem
                  key={optionKey}
                  onSelect={() => {
                    handleHeightDimensionTypeChange(optionKey as 'fixed' | 'relative' | 'fill' | 'fit');
                  }}
                >
                  {options[optionKey]}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </>
  );
};
