import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/outline';

import { BackLink } from '@/components/BackLink';
import Badge from '@/components/Badge';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import SplitButton from '@/components/SplitButton';
import UrlCopy from '@/components/UrlCopy';
import { useUpdateForm } from '@/hooks/useForms';
import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Form, FormStatus } from '@/interfaces/form';

import NavContainer from '../NavContainer';
import Summary from '../Show/Summary';

interface Props {
  form: Form;
  formData: any;
  isNewLayout?: boolean;
}

const FormsHeader = ({ form, formData, isNewLayout }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formId = form.id;
  const { data: currentPublication } = useCurrentPublication();

  const isLive = form?.status === FormStatus.LIVE;
  const isDraft = form?.status === FormStatus.DRAFT;
  const isArchived = form?.status === FormStatus.ARCHIVED;
  const isPostSubscribeForm = currentPublication?.post_subscribe_form_id === formId;
  const isPostUnsubscribeForm = currentPublication?.post_unsubscribe_form_id === formId;

  const publicationId = useCurrentPublicationId();
  const { mutate: updatePublication } = useUpdatePublication(publicationId, () => {
    toast.success('Saved!');
  });

  const updateForm = useUpdateForm({
    formId: formId || '',
    onSuccess: () => {
      toast.success('Survey saved!');
      queryClient.invalidateQueries(['forms', formId]);
    },
  });

  const splitButtonOptions = [];

  if (isLive && !isPostUnsubscribeForm) {
    splitButtonOptions.push({
      label: isPostSubscribeForm ? 'Disable as Subscribe Survey' : 'Enable as Subscribe Survey',
      helperText: isPostSubscribeForm
        ? 'Users will no longer be redirected to this survey after subscribing'
        : 'Users will be redirected to this survey after subscribing',
      onClick: () => {
        updatePublication({
          post_subscribe_form_id: isPostSubscribeForm ? null : formId,
        });
      },
    });
  }

  if (isLive && !isPostSubscribeForm) {
    splitButtonOptions.push({
      label: isPostUnsubscribeForm ? 'Disable as Unsubscribe Survey' : 'Enable as Unsubscribe Survey',
      helperText: isPostUnsubscribeForm
        ? 'Users will no longer be redirected to this survey after unsubscribing'
        : 'Users will be redirected to this survey after unsubscribing',
      onClick: () => {
        updatePublication({
          post_unsubscribe_form_id: isPostUnsubscribeForm ? null : formId,
        });
      },
    });
  }

  if (!isPostSubscribeForm && !isPostUnsubscribeForm && !isNewLayout) {
    if (isArchived) {
      splitButtonOptions.push({
        label: 'Unarchive',
        helperText: 'This survey will be available to users',
        onClick: () => {
          updateForm.mutate({ ...form, status: FormStatus.LIVE });
        },
      });
    }

    if (isLive) {
      splitButtonOptions.push({
        label: 'View Settings',
        helperText: 'View the settings and fields for this survey',
        onClick: () => {
          navigate(`/forms/${formId}/edit`);
        },
      });

      splitButtonOptions.push({
        label: 'Archive',
        helperText: 'This survey will no longer be available to users',
        onClick: () => {
          updateForm.mutate({ ...form, status: FormStatus.ARCHIVED });
        },
      });
    }

    if (isDraft && !isNewLayout) {
      splitButtonOptions.push({
        label: 'Edit',
        helperText: 'Edit this survey',
        onClick: () => {
          navigate(`/forms/${formId}/edit`);
        },
      });
    }
  }

  const renderedSplitButton = (
    <div className="flex space-x-2 justify-end">
      {splitButtonOptions.length > 0 ? <SplitButton variant="primary" buttons={splitButtonOptions} /> : null}
    </div>
  );

  if (isNewLayout) {
    return (
      <div className="flex flex-col gap-y-3">
        <div className="flex justify-end gap-x-2">
          {form?.url && isLive && <UrlCopy url={form.url} tooltip="Share this form" />}
          {renderedSplitButton}
        </div>
        <div className="space-y-8">
          <Summary form={formData} />
        </div>
      </div>
    );
  }

  return (
    <>
      {isNewLayout ? (
        renderedSplitButton
      ) : (
        <>
          <BackLink to="/forms">Exit</BackLink>
          <div className="mb-2">
            <div className="flex space-x-2 items-center">
              <Badge type={isLive ? 'success' : 'information'}>
                <span className="text-xs capitalize">{form?.status}</span>
              </Badge>
              {isPostSubscribeForm && (
                <div className="flex space-x-0.5 items-center">
                  <UserPlusIcon className="text-blue-400 h-4 w-4" />
                  <span className="font-bold p-1 text-xs text-blue-400">Subscribe Survey</span>
                </div>
              )}
              {isPostUnsubscribeForm && (
                <div className="flex space-x-0.5 items-center">
                  <UserMinusIcon className="text-purple-400 h-4 w-4" />
                  <span className="font-bold p-1 text-xs text-purple-400">Unsubscribe Survey</span>
                </div>
              )}
            </div>
          </div>
          <PageHeading title={form?.title || ''} description={form?.description || ''}>
            {renderedSplitButton}
          </PageHeading>
        </>
      )}

      {form?.url && isLive && (
        <div className="flex justify-end mb-4">
          <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
            <a
              href={form.url}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
            >
              <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1 underline underline-primary-500" />
              <span>Share this form</span>
            </a>
          </div>
        </div>
      )}

      <div className="space-y-8">
        <Summary form={formData} />

        {formId && <NavContainer formId={formId} isNewLayout={isNewLayout} />}
      </div>
    </>
  );
};

export default FormsHeader;
