import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BackgroundSettings } from '../BackgroundSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SizingSettings } from '../SizingSettings';
import { SliderSettings } from '../SliderSettings';
import { VerticalAlignmentSettings } from '../VerticalAlignmentSettings';

export const SectionSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['section']}>
      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} />
      </SectionRenderer>

      <SectionRenderer title="Typography">
        <ColorSettings
          title="Text"
          property="color"
          editor={editor}
          activeNodeResult={activeNodeResult}
          initialColor="#FFFFFF"
        />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <SizingSettings editor={editor} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} title="Text Align" />
        <VerticalAlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>

      <SectionRenderer title="Sizing">
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="innerWidthMax"
          title="Width"
          min={0}
          max={1280}
          unit="px"
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
