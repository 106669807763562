import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useCopyToClipboard, usePostPreview } from '@/hooks';
import { usePostTargets, usePostTargetsDisplay } from '@/hooks/usePostTargets';
import { Post, PostPlatform } from '@/interfaces/post';
import { PostTargetAction, PostTargetPlatform } from '@/interfaces/post_target';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { Iframe } from '@/ui/Iframe';

import NavContainer from '../NavContainer';

import AttributeRow from './AttributeRow';
import AudienceBadges from './AudienceBadges';

interface RouteParams {
  postId: string;
}

type AttributeProps = {
  label: string;
  value?: string;
};

type DateProps = {
  label: string;
  value?: string;
  fallback?: string;
  format: string;
};

interface URLSearchParamsExtended extends URLSearchParams {
  size: number;
}

const Attribute = ({ label, value }: AttributeProps) => {
  if (!value) return null;

  return (
    <div>
      <p className="text-xs font-semibold text-gray-500">{label}</p>
      <p className="text-gray-900 font-semibold">{value}</p>
    </div>
  );
};

const DateAttribute = (props: DateProps) => {
  const { label, value, fallback, format } = props;

  if (!value && !fallback) return null;

  const valueOrFallback = value ? moment(value).format(format) : fallback;

  return <Attribute label={label} value={valueOrFallback} />;
};

const imageUrl = 'https://media.beehiiv.com/static_assets/post-launch-modal-background.png';

const FacebookLogo = () => (
  <img
    src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/Facebook+icon.svg"
    alt="Facebook Logo"
    className="h-5 w-5"
  />
);

const XLogo = () => (
  <img
    src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/Twitter+icon.svg"
    alt="X Logo"
    className="h-5 w-5"
  />
);

const LinkedinLogo = () => (
  <img
    src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/LinkedIn+icon.svg"
    alt="LinkedIn Logo"
    className="h-5 w-5"
  />
);

type ModalTitle = 'Your post is live! Spread the news' | 'Your post is scheduled!';

enum ModalUrl {
  PostUrl = 'post',
  Subscribe = 'subscribe',
}

const PostOverview = () => {
  const copy = useCopyToClipboard();
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { postId: id } = useParams() as unknown as RouteParams;
  const { data: webPostTargetsData } = usePostTargets(id, PostTargetPlatform.WEB);
  const { data: emailPostTargetsData } = usePostTargets(id, PostTargetPlatform.EMAIL);
  const emailAudiencesDisplay = usePostTargetsDisplay(id, PostTargetPlatform.EMAIL);
  const webAudiencesDisplay = usePostTargetsDisplay(id, PostTargetPlatform.WEB);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<ModalTitle>('Your post is live! Spread the news');
  const [modalBody, setModalBody] = useState('');
  const [modalUrl, setModalUrl] = useState<ModalUrl>(ModalUrl.PostUrl);
  const { data, isLoading: isLoadingPostPreview, isError: isErrorPostPreview } = usePostPreview(id);
  const { html } = data || {};

  const includedEmailPostTargets = useMemo(
    () => emailPostTargetsData?.post_targets.filter((target) => target.action === PostTargetAction.INCLUDE) || [],
    [emailPostTargetsData]
  );
  const excludedEmailPostTargets = useMemo(
    () => emailPostTargetsData?.post_targets.filter((target) => target.action === PostTargetAction.EXCLUDE) || [],
    [emailPostTargetsData]
  );
  const includedWebPostTargets = useMemo(
    () => webPostTargetsData?.post_targets.filter((target) => target.action === PostTargetAction.INCLUDE) || [],
    [webPostTargetsData]
  );

  const excludedWebPostTargets = useMemo(
    () => webPostTargetsData?.post_targets.filter((target) => target.action === PostTargetAction.EXCLUDE) || [],
    [webPostTargetsData]
  );
  const shareToFacebook = (post: Post) => {
    const shareUrl = modalUrl === 'subscribe' ? `${post.url.split('/p')[0]}/subscribe` : post.url;
    const baseUrl = 'https://www.facebook.com/sharer/sharer.php';
    const facebookUrl = `${baseUrl}?u=${shareUrl}`;
    window.open(facebookUrl);
  };

  const shareToX = (post: Post) => {
    const shareUrl = modalUrl === 'subscribe' ? `${post.url.split('/p')[0]}/subscribe` : post.url;
    const baseUrl = 'https://twitter.com/intent/tweet';
    let twitterUrl = `${baseUrl}?url=${shareUrl}`;
    if (modalUrl !== 'subscribe') {
      twitterUrl += `&text=${post.meta_default_title || post.email_subject_line}`;
    }
    window.open(twitterUrl);
  };

  const shareToLinkedIn = (post: Post) => {
    const shareUrl = modalUrl === 'subscribe' ? `${post.url.split('/p')[0]}/subscribe` : post.url;
    const baseUrl = 'https://linkedin.com/shareArticle';
    let linkedInUrl = `${baseUrl}?url=${shareUrl}&mini=true`;
    if (modalUrl !== 'subscribe') {
      linkedInUrl += `&title=${post.meta_default_title || post.email_subject_line}`;
    }
    window.open(linkedInUrl);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) as URLSearchParamsExtended;
    if (params.get('launch') === 'true') {
      setShowModal(true);
      setModalTitle('Your post is live! Spread the news');
      setModalUrl(ModalUrl.PostUrl);
      params.delete('launch');
      window.history.replaceState({}, '', `${window.location.pathname}${params.size > 0 ? `?${params}` : ''}`);
    }
    if (params.get('scheduled') === 'true') {
      setShowModal(true);
      setModalTitle('Your post is scheduled!');
      setModalBody('Spread the news and give a sneak peek to your subscribers.');
      setModalUrl(ModalUrl.Subscribe);
      params.delete('scheduled');
      window.history.replaceState({}, '', `${window.location.pathname}${params.size > 0 ? `?${params}` : ''}`);
    }
  }, []);

  if (isV2) {
    return (
      <NavContainer postId={id}>
        {({ post }) => (
          <Card density="none">
            <ActionModal
              buttonType="primary"
              buttonShade="dark"
              modalSize="sm"
              actionText="Done"
              cancelText="Close"
              isOpen={showModal}
              onProceed={() => setShowModal(false)}
              onClose={() => setShowModal(false)}
              isWorking={false}
              resourceId="launch-post"
              padding="p-0"
            >
              <img src={imageUrl} alt="Post Launch Modal" className="object-cover rounded-t-lg" />
              <div className="p-6 flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <Typography token="font-semibold/text/2xl" colorWeight="900">
                    {modalTitle}
                  </Typography>
                  {modalBody.length > 0 && (
                    <Typography token="font-normal/text/sm" colorWeight="600">
                      {modalBody}
                    </Typography>
                  )}
                </div>
                <Input
                  name="post_url"
                  value={modalUrl === ModalUrl.Subscribe ? `${post.url.split('/p/')[0]}/subscribe` : post.url}
                  className="w-full py-2"
                  onClick={() =>
                    copy({ text: modalUrl === ModalUrl.Subscribe ? `${post.url.split('/p/')[0]}/subscribe` : post.url })
                  }
                  trailingButton={
                    <Button
                      Icon={LinkIcon}
                      variant="info"
                      shade="light"
                      className="rounded-l-none border-feedback-info-200 border"
                      onClick={() =>
                        copy({
                          text: modalUrl === ModalUrl.Subscribe ? `${post.url.split('/p/')[0]}/subscribe` : post.url,
                        })
                      }
                    >
                      Copy
                    </Button>
                  }
                  readOnly
                />
                <div className="grid grid-flow-col auto-cols-max gap-3 w-full">
                  <Button
                    size="xs"
                    type="button"
                    variant="primary-inverse"
                    Icon={FacebookLogo}
                    onClick={() => shareToFacebook(post)}
                  >
                    Share to Facebook
                  </Button>
                  <Button size="sm" type="button" variant="primary-inverse" Icon={XLogo} onClick={() => shareToX(post)}>
                    Share to X
                  </Button>
                  <Button
                    size="xs"
                    type="button"
                    variant="primary-inverse"
                    Icon={LinkedinLogo}
                    onClick={() => shareToLinkedIn(post)}
                  >
                    Share to LinkedIn
                  </Button>
                </div>
                <Typography token="font-normal/text/sm" colorWeight="700">
                  While your post gets all the love, you can learn about{' '}
                  <span className="underline cursor-pointer">
                    <Typography token="font-medium/text/sm" color="secondary" colorWeight="600">
                      <a
                        href="https://support.beehiiv.com/hc/en-us/articles/24862562076183-Where-to-view-post-analytics"
                        target="_blank"
                        rel="noreferrer"
                      >
                        post metrics
                      </a>
                    </Typography>
                  </span>
                </Typography>
              </div>
            </ActionModal>
            <div className="col-span-1">
              <div className="flex flex-col gap-y-4 p-6">
                {(post.platform === PostPlatform.BOTH || post.platform === PostPlatform.EMAIL) && (
                  <AttributeRow
                    label="Subject"
                    value={
                      <Typography token="font-medium/text/sm" colorWeight="700">
                        {post.email_subject_line || 'Subject Line'}
                      </Typography>
                    }
                  />
                )}
                {post.platform !== PostPlatform.WEB && (
                  <AttributeRow
                    label="Preview Text"
                    value={
                      <Typography token="font-medium/text/sm" colorWeight="700">
                        {post.email_preview_text || 'Preview Text'}
                      </Typography>
                    }
                  />
                )}
                {(post.platform === PostPlatform.BOTH || post.platform === PostPlatform.WEB) && (
                  <AttributeRow
                    label="Web Audience"
                    value={
                      <AudienceBadges
                        includedAudiences={includedWebPostTargets}
                        excludedAudiences={excludedWebPostTargets}
                      />
                    }
                  />
                )}
                {(post.platform === PostPlatform.BOTH || post.platform === PostPlatform.EMAIL) && (
                  <AttributeRow
                    label="Email Audience"
                    value={
                      <AudienceBadges
                        includedAudiences={includedEmailPostTargets}
                        excludedAudiences={excludedEmailPostTargets}
                      />
                    }
                  />
                )}
              </div>
            </div>
            <div className="border-t pb-6">
              <LoadingBox isLoading={isLoadingPostPreview} isError={isErrorPostPreview}>
                {html ? (
                  <Iframe
                    title="preview"
                    className="w-full overflow-hidden"
                    html={html}
                    shouldResizeHeight
                    minHeight={750}
                  />
                ) : null}
              </LoadingBox>
            </div>
          </Card>
        )}
      </NavContainer>
    );
  }

  return (
    <NavContainer postId={id}>
      {({ post }) => (
        <div className="mt-5 mb-12 grid grid-cols-4 gap-4">
          <div className="col-span-3">
            {html ? (
              <iframe
                title="preview"
                className="w-full h-full rounded overflow-hidden"
                srcDoc={html}
                style={{
                  minHeight: '750px',
                }}
              />
            ) : (
              <div className="rounded flex items-center justify-center animate-pulse bg-gray-200 h-full">
                <LoadingSpinner />
              </div>
            )}
          </div>
          <div className="col-span-1">
            <div className="grid grid-cols-1 gap-6">
              <Attribute label="Subject Line" value={post.email_subject_line} />
              <Attribute label="Preview Text" value={post.email_preview_text} />
              <DateAttribute
                label="Publish Time"
                value={post.scheduled_at}
                fallback="Not set"
                format="MMM Do, YYYY LT"
              />
              <DateAttribute
                label="Displayed Date"
                value={post.override_scheduled_at}
                fallback="Not set"
                format="MMM Do, YYYY"
              />
              <Attribute
                label="Delivery"
                value={post.platform && post.platform === PostPlatform.BOTH ? 'Email & Web' : post.platform}
              />
              {(post.platform === PostPlatform.BOTH || post.platform === PostPlatform.WEB) && (
                <Attribute label="Web Audience" value={webAudiencesDisplay} />
              )}
              {(post.platform === PostPlatform.BOTH || post.platform === PostPlatform.EMAIL) && (
                <Attribute label="Email Audience" value={emailAudiencesDisplay} />
              )}
              <Attribute label="Hidden from feed" value={post.hide_from_feed ? 'Yes' : 'No'} />
            </div>
          </div>
        </div>
      )}
    </NavContainer>
  );
};

export default PostOverview;
