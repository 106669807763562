import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePublication } from '@/hooks/usePublications';
import api from '@/services/swarm';

type TransferPayload = {
  receiverId: string;
};

const useUpdateWebTemplate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = usePublication(currentPublicationId);

  return useMutation(
    ({ receiverId }: TransferPayload) =>
      api.patch(`/publications/${currentPublicationId}/transfers`, {
        publication_id: currentPublicationId,
        receiver_id: receiverId,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['organization', currentPublication?.organization_id, 'publications']);
        queryClient.invalidateQueries(['publications', currentPublicationId]);
        onSuccess?.();
      },
    }
  );
};

export default useUpdateWebTemplate;
