import { ChangeEvent, FC, useEffect, useState } from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  name: string;
  labelText?: string;
  checked: boolean;
  onChange: (checked: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
  size?: 'md' | 'lg';
  disabled?: boolean;
  helperText?: string;
  indeterminate?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary';
  overrideColor?: string;
  id?: string;
}

const COLOR_MAP = Object.freeze({
  primary: 'text-action-primary-900',
  secondary: 'text-action-secondary-600',
  tertiary: 'text-action-tertiary-600',
});

const Checkbox: FC<Props> = (props: Props) => {
  const {
    className,
    name,
    labelText,
    checked,
    onChange,
    size,
    disabled,
    helperText,
    indeterminate = false,
    color = 'secondary',
    overrideColor,
    id,
  } = props;
  const isLarge = size === 'lg';

  const [isChecked, setIsChecked] = useState(checked);

  // TODO: Clean up the onChange logic to be completely passed in from parent
  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleChange = (event?: ChangeEvent<HTMLInputElement> | undefined) => {
    setIsChecked(!isChecked);
    onChange(!isChecked, event);
  };

  return (
    <div className={cx(className)}>
      <div className="flex space-x-3">
        <input
          type="checkbox"
          ref={(input: HTMLInputElement) => {
            if (input) {
              // eslint-disable-next-line no-param-reassign
              input.indeterminate = indeterminate;
            }
          }}
          className={cx(
            `mt-1 focus:ring-transparent h-4.5 w-4.5 border-gray-300 rounded-sm disabled:cursor-not-allowed`,
            !overrideColor && COLOR_MAP[color],
            overrideColor,
            { '!bg-surface-100': disabled }
          )}
          id={id || name}
          name={name}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        {labelText && (
          <label
            htmlFor={name}
            className={cx(
              disabled ? 'text-gray-500 cursor-not-allowed' : 'text-gray-700',
              isLarge ? 'text-lg font-regular' : 'text-sm font-normal'
            )}
          >
            {labelText}
          </label>
        )}
      </div>
      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
    </div>
  );
};
Checkbox.defaultProps = {
  className: undefined,
  size: 'md',
};
export default Checkbox;
