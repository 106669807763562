import { useState } from 'react';
import { offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Editor } from '@tiptap/core';

import { useHighlighting } from '@/components/TiptapEditor/components/menus/Text/hooks';

import { ColorPicker } from '../../../UI/ColorPicker';
import { Text } from '../../../UI/Text';

export const HighlightSettings = ({ editor }: { editor: Editor }) => {
  const { color } = editor.getAttributes('highlight');

  const highlighting = useHighlighting(editor as any);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
    middleware: [offset(20)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div ref={refs.setReference} className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Highlight
        </Text>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm">
          <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer" {...getReferenceProps()}>
            <div className="flex items-center gap-1">
              <div className="w-4 h-4 rounded-md" style={{ backgroundColor: color }} />
              <Text size="2xs" weight="medium">
                {color ? color.toUpperCase() : 'None'}
              </Text>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          ref={refs.setFloating}
          className="select-none bg-wb-primary w-[255px] max-h-[500px] overflow-y-auto p-3 rounded-lg shadow-xl flex flex-col gap-4"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <ColorPicker
            selectedColor={color}
            onSetColor={(c) => (c ? highlighting.onHighlightSelect(c) : highlighting.onHighlightClear())}
          />
        </div>
      )}
    </>
  );
};
