import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Typography } from '@/components/Typography';
import useDeleteSvixEndpoint from '@/hooks/webhooks/useDeleteSvixEndpoint';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import ErrorRate from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Shared/ErrorRate';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import pluralize from '@/utils/pluralize';

interface Props {
  endpoint: WebhookEndpoint;
  publicationId: string;
}

const EndpointListItem = ({ endpoint, publicationId }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const eventTypesTooltip = endpoint.event_types.join(', ');

  const { mutate: deleteEndpoint, isLoading } = useDeleteSvixEndpoint(publicationId);

  const handleClickDelete = () => {
    queryClient.invalidateQueries(['publications', publicationId, 'webhooks', 'endpoints'], { exact: true });
    deleteEndpoint(endpoint.id);
  };

  return (
    <>
      <ActionModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        headerText="Delete Endpoint"
        descriptionText="Deleting this endpoint will permanently remove it and stop all webhook deliveries. This action cannot be undone."
        actionText="Delete"
        buttonType="danger"
        buttonShade="dark"
        onProceed={handleClickDelete}
        resourceId={endpoint.id || ''}
        isWorking={isLoading}
        modalMessageType="danger"
      />
      <div className="flex flex-row justify-between p-3 border border-surface-200 bg-surface-50 rounded-md">
        <div className="flex flex-col gap-y-2 flex-grow">
          <Typography token="font-medium/text/sm">{endpoint.description || 'No description'}</Typography>
          <Typography token="font-medium/text/sm" colorWeight="500">
            {endpoint.url}
          </Typography>
          <div className="flex flex-row space-x-1 items-center">
            <Badge type="info_blue" className="flex flex-row gap-x-1 items-center" tooltip={eventTypesTooltip}>
              {pluralize('Event type', endpoint.event_types.length)}
            </Badge>
            <ErrorRate endpointId={endpoint.id} messageText="Errors" />
          </div>
        </div>
        <div className="flex flex-row gap-x-6">
          <Badge type={endpoint.disabled ? 'alert' : 'success'} className="w-fit h-fit">
            {endpoint.disabled ? 'Disabled' : 'Active'}
          </Badge>
          <EllipsisDropdown
            options={[
              {
                label: 'Edit',
                onClick: () =>
                  navigate(
                    appendSettingsPublicationId(`/settings/publication/webhooks/${endpoint.id}/edit`, publicationId)
                  ),
              },
              {
                label: 'View details',
                onClick: () =>
                  navigate(
                    appendSettingsPublicationId(`/settings/publication/webhooks/${endpoint.id}/show`, publicationId)
                  ),
              },
              {
                label: 'Delete',
                onClick: () => setShowDeleteModal(true),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default EndpointListItem;
