import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Poll } from '../../interfaces/poll';
import api from '../../services/swarm';

export default function usePoll(id?: string, keepPreviousData: boolean = true, retry?: boolean) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPoll = () =>
    api
      .get(`/polls/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<Poll>(['publications', currentPublicationId, 'polls', id], fetchPoll, {
    keepPreviousData,
    enabled: !!id,
    retry,
  });
}