import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { ButtonElement } from '@shared/dream-components';
import { getText, NodeViewProps } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper, useReactNodeView } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';

import { SearchList, SearchListItemProps } from '@/components/TiptapEditor/components/ui/SearchList';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';

interface Preset {
  text: string;
  url: string;
}

export const ButtonView: FC<NodeViewProps> = ({ editor, node, getPos }) => {
  const { onDragStart } = useReactNodeView();

  const text = getText(node);
  const isNew = node.attrs.isCustom === undefined && !node.attrs.href?.length && !text?.length;

  const { publicationId } = usePublicationContext();
  const { postId } = useParams<'postId'>() as { postId: string };
  const [isLoading, setIsLoading] = useState(isNew);
  const [presets, setPresets] = useState<Preset[]>([]);

  useEffect(() => {
    if (isNew) {
      API.getPresetButtons({
        publicationId,
        postId,
      })
        .then((res: AxiosResponse<{ options: Preset[] }>) => {
          const customPreset = [
            {
              text: 'Custom',
              url: '',
            },
          ];

          const p = [...customPreset, ...(res.data?.options || [])];

          setPresets(p);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';

          toast.error(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isNew, publicationId, postId]);

  const handleSelect = useCallback(
    (item: SearchListItemProps) => {
      const focusPos = getPos() + 1;

      const content = item.label === 'Custom' ? '' : item.label;
      const href = item.value;

      const chain = editor
        .chain()
        .focus(focusPos)
        .updateAttributes('button', { href, isCustom: item.label === 'Custom' });

      if (content) {
        chain.insertContent(content).run();
      } else {
        chain.run();
      }
    },
    [editor, getPos]
  );

  if (isNew) {
    return (
      <NodeViewWrapper
        data-drag-handle
        {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
      >
        <SearchList
          tabIndex={-1}
          items={presets.map((preset) => {
            return {
              value: preset.url,
              label: preset.text,
            };
          })}
          hasMore={isLoading}
          searchable
          searchPlaceholder="Search a button preset"
          onSelect={(item: SearchListItemProps) => handleSelect(item)}
        />
      </NodeViewWrapper>
    );
  }

  return (
    <ButtonElement
      element={{
        type: 'button',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent />
    </ButtonElement>
  );
};
