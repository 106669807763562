import BodyContainer from '@/routes/settings/publication/components/BodyContainer';
import CardHeader from '@/routes/settings/publication/components/CardHeader';
import { Card } from '@/ui/Card';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';

import { usePlanPrice } from '../../_hooks';

import CancelDowngradeModal from './CancelDowngradeModal';
import CustomPlanDowngradeModal from './CustomPlanDowngradeModal';
import DowngradeModal from './DowngradeModal';

const CancellationSection: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data: planPrice } = usePlanPrice({ organizationId, publicationId });
  const { data: upcomingPlanPrice } = usePlanPrice({ organizationId, publicationId, upcoming: true });

  if (!planPrice || !upcomingPlanPrice || planPrice?.plan_name === PLAN.LAUNCH) {
    return null;
  }

  const intervalText = (interval: string) => `${interval}ly`;

  const isPendingDowngrade = planPrice.id !== upcomingPlanPrice.id;

  const sectionDescription = isPendingDowngrade
    ? `You are currently scheduled to be downgraded to the ${intervalText(upcomingPlanPrice.interval)} ${capitalize(
        upcomingPlanPrice.plan_name
      )} at the end of your current billing period.`
    : 'Your current plan will remain active until the end of your current billing period.';

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Cancellation Actions"
          description={`Please note, these actions are irreversible and may take a few minutes to complete. ${sectionDescription}`}
        />
        {!isPendingDowngrade &&
          (planPrice.plan_name === PLAN.GROW ||
            planPrice.plan_name === PLAN.SCALE ||
            planPrice.plan_name === PLAN.MAX) && (
            <DowngradeModal organizationId={organizationId} publicationId={publicationId} planPrice={planPrice} />
          )}
        {!isPendingDowngrade && planPrice.plan_name === PLAN.CUSTOM && <CustomPlanDowngradeModal />}
        {isPendingDowngrade && (
          <CancelDowngradeModal
            organizationId={organizationId}
            publicationId={publicationId}
            currentPlanPrice={planPrice}
            upcomingPlanPrice={upcomingPlanPrice}
          />
        )}
      </BodyContainer>
    </Card>
  );
};

export default CancellationSection;
