import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useOpportunityUpdate from '@/hooks/useAdNetwork/publisher/useOpportunityUpdate';
import { AdNetworkOpportunity, AdNetworkOpportunityStatus } from '@/interfaces/ad_network/publisher/opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  opportunity: AdNetworkOpportunity;
  isExitIntent?: boolean;
}

const RejectionWorkflow = ({ isOpen, onClose, opportunity, isExitIntent = false }: Props) => {
  const [rejectReason, setRejectReason] = useState('');
  const [rejectionReasonComment, setRejectionReasonComment] = useState('');
  const { mutateAsync, isLoading } = useOpportunityUpdate({ id: opportunity.id });
  const navigate = useNavigate();

  const handleReject = () => {
    mutateAsync({
      status: AdNetworkOpportunityStatus.REJECTED,
      rejection_reason: rejectReason,
      rejection_reason_comment: rejectionReasonComment,
    }).then(() => {
      toast('Placement rejected');

      navigate('/monetize/ads/pending');
    });
  };

  const options = useMemo(() => {
    return [
      { value: 'advertiser_not_a_good_fit', label: 'The advertiser is not a good fit for my audience' },
      { value: 'payout_too_low', label: 'The payout is too low' },
      { value: 'dislike_ad_copy', label: 'I dislike the ad copy' },
      {
        value: 'existing_relationship_with_advertiser',
        label: 'I have an existing relationship with the advertiser',
      },
      { value: 'not_interested_in_advertising', label: 'I am not interested in advertising' },
      { value: 'no_available_inventory', label: 'I have no available inventory' },
      ...(opportunity.payout_model === AdNetworkPayoutModel.CPC
        ? [{ value: 'no_cpc_deals', label: 'I do not participate in CPC deals' }]
        : []),
      ...(opportunity.payout_model === AdNetworkPayoutModel.CPM
        ? [{ value: 'no_cpm_deals', label: 'I do not participate in CPM deals' }]
        : []),
      { value: 'unsure_how_to_add_ads', label: 'I am unsure how to add ads to my newsletter' },
    ]
      .sort(() => Math.random() - 0.5)
      .concat([{ value: 'other', label: 'Another reason (please specify below)' }]);
  }, []);

  const title = isExitIntent ? 'Did you forget to reply?' : 'Help us understand your choice';
  const description = isExitIntent
    ? `This opportunity expires ${moment(opportunity.deadline).fromNow()}, reply soon to monetize with this ad.`
    : "Let us know why this ad isn't a fit to help us serve you better ads";

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      resourceId={opportunity.id}
      modalSize="lg"
      isWorking={isLoading}
      onProceed={() => handleReject()}
      actionText="Submit"
      disabled={!rejectReason || (rejectReason === 'other' && !rejectionReasonComment)}
    >
      <div className="space-y-6">
        <div className="flex space-x-4">
          <div className="w-10 h-10 bg-feedback-info-50 rounded-full relative flex flex-shrink-0">
            <InformationCircleIcon className="absolute top-1/2 right-1/2 transform -translate-y-1/2 translate-x-1/2 w-6 h-6 text-feedback-info-600" />
          </div>
          <div className="flex flex-col">
            <Typography token="font-medium/text/lg" colorWeight="900">
              {title}
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700">
              {description}
            </Typography>
          </div>
        </div>
        <div className="space-y-6">
          {isExitIntent && (
            <div>
              <Typography token="font-normal/text/sm" colorWeight="700">
                If this ad is not a good fit for you, help us serve you betters ads by telling us why.
              </Typography>
            </div>
          )}
          <div className="space-y-2">
            <Typography token="font-medium/text/sm" colorWeight="900">
              Reason for not accepting this ad
            </Typography>
            <div className="flex flex-col space-y-2">
              {options.map((option) => (
                <div className="flex items-center space-x-3" key={option.value}>
                  <input
                    type="radio"
                    id={`rejectReason-${option.value}`}
                    name="opportunity[rejection_reason]"
                    value={option.value}
                    onChange={(e) => setRejectReason(e.target.value)}
                    className="text-feedback-info-600 focus:ring-feedback-info-500"
                  />
                  <label htmlFor={`rejectReason-${option.value}`}>
                    <Typography token="font-normal/text/sm" colorWeight="700" as="span">
                      {option.label}
                    </Typography>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <Textarea
              name="opportunity[rejection_reason_comment]"
              value={rejectionReasonComment}
              onChange={(e) => setRejectionReasonComment(e.target.value)}
              labelText="Is there anything else you'd like to add?"
              rows={5}
              placeholderText="Tell us more"
              showOptional={rejectReason !== 'other'}
              required={rejectReason === 'other'}
            />
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default RejectionWorkflow;
