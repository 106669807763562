import Banner from '@/components/Banner';
import { SimpleSelect } from '@/components/Form';
import { ContentImport, ContentImportPlatform } from '@/interfaces/content_import';

import BodyContainer from '../../components/BodyContainer';
import { StepErrors } from '../new/types';

const options = [
  { value: 'substack', label: 'Substack' },
  { value: 'wordpress', label: 'Wordpress' },
  { value: 'mailchimp', label: 'Mailchimp' },
  { value: 'ghost', label: 'Ghost' },
  { value: 'other', label: 'Other' },
];

interface Props {
  contentImport: Partial<ContentImport>;
  handleChangePlatform: (newPlatform: ContentImportPlatform) => void;
  errors: StepErrors;
}

const PlatformSelection: React.FunctionComponent<Props> = ({ contentImport, errors, handleChangePlatform }: Props) => (
  <BodyContainer>
    <Banner
      isScreenWide={false}
      variant="information"
      title="By importing content, you agree to the following:"
      bodyText={
        <ul className="mt-2 space-y-2 list-disc">
          <li>You verify that you are the rightful owner of this content.</li>
          <li>
            You acknowledge that by falsely claiming other&apos;s content as your own, you will be in direct
            violation of beehiiv&apos;s terms of service which can lead to immediate termination of service.
          </li>
        </ul>
      }
    />

    <SimpleSelect
      name="platform"
      labelText="Import from"
      className="space-y-2 max-w-xs"
      value={contentImport.platform}
      options={options}
      onSelect={(_name, value: ContentImportPlatform) => {
        handleChangePlatform(value);
      }}
      errorText={errors?.platform}
      helperText="Choose the location where your content currently lives"
    />
  </BodyContainer>
);

export default PlatformSelection;
