import { Typography } from '@/components/Typography';

type Props = {
  payload: any;
};

const MessagePayload = ({ payload }: Props) => {
  return (
    <div className="space-y-4">
      <Typography token="font-medium/text/base">Payload</Typography>
      <div className="bg-gray-100 rounded-md p-6">
        <pre>
          <code className="language-json whitespace-pre-wrap text-normal">{JSON.stringify(payload, null, 2)}</code>
        </pre>
      </div>
    </div>
  );
};

export default MessagePayload;
