import { FC, useState } from 'react';
import { ContainerElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';
import { cx } from 'class-variance-authority';

import { Text } from '../../../../UI/Text';

export const ContainerView: FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  const [isDragOver, setIsDragOver] = useState(false);
  const hasContent = node.content.size > 0;

  return (
    <ContainerElement
      element={{
        type: 'container',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {hasContent ? (
        <NodeViewContent data-node-view-display-contents />
      ) : (
        <div className="w-full h-full select-none">
          <div
            className={cx('absolute w-full h-full top-0 left-0 transition-all duration-300 pointer-events-none', {
              'bg-blue-50': isDragOver,
            })}
            style={{ zIndex: 1 }}
          />

          <div
            className="w-full h-full flex items-center justify-center"
            style={{ zIndex: 2 }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragOver(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragOver(false);
            }}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <Text size="md" weight="semibold">
                Drag and drop elements to start
              </Text>
            </div>
          </div>
        </div>
      )}
    </ContainerElement>
  );
};
