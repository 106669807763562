import { useCallback, useEffect, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';

import { Button } from '../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { AttributeDropdown } from '../helpers/AttributeDropdown';
import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { getTRBLValue } from '../utils/getTRBLValue';
import { parseCssValue } from '../utils/parseCssValue';

import { ColorSettings } from './ColorSettings';

type BorderWidth = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderRadius = BorderWidth;

type Border = {
  color: string;
  style: string;
  width: BorderWidth;
  radius: BorderRadius;
};

type BorderSettingsProps = AttributeSettingProps & {
  properties?: {
    color: string;
    style: string;
    width: string;
    radius: string;
  };
};

const DEFAULT_MAPPING = {
  color: 'borderColor',
  style: 'borderStyle',
  width: 'borderWidth',
  radius: 'borderRadius',
};

const DEFAULT_COLOR = '#000000FF';

const getStringValue = (value: BorderWidth | BorderRadius, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

const DEFAULT_BORDER_WIDTH = '1px';

const DEFAULT_BORDER_RADIUS = '0px';

const BorderSettings = ({ editor, activeNodeResult, properties = DEFAULT_MAPPING }: BorderSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [border, setBorder] = useState<Border>({
    color: '#000000FF',
    style: 'none',
    radius: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    width: {
      top: 1,
      right: 1,
      bottom: 1,
      left: 1,
    },
  });

  useEffect(() => {
    if (!activeNodeAttributes) return;

    const borderRadius = getTRBLValue(activeNodeAttributes[properties.radius] || DEFAULT_BORDER_RADIUS);
    const borderWidth = getTRBLValue(activeNodeAttributes[properties.width] || DEFAULT_BORDER_WIDTH);

    setBorder({
      color: activeNodeAttributes[properties.color],
      style: activeNodeAttributes[properties.style],
      width: {
        top: parseCssValue(borderWidth.top).value,
        right: parseCssValue(borderWidth.right).value,
        bottom: parseCssValue(borderWidth.bottom).value,
        left: parseCssValue(borderWidth.left).value,
      },
      radius: {
        top: parseCssValue(borderRadius.top).value,
        right: parseCssValue(borderRadius.right).value,
        bottom: parseCssValue(borderRadius.bottom).value,
        left: parseCssValue(borderRadius.left).value,
      },
    });
  }, [properties, activeNodeAttributes]);
  const isNone = border.style === 'none';

  const handleUpdateBorder = useCallback(
    (incomingBorder: Border) => {
      if (!activeNodePos) return;

      if (incomingBorder.style === 'none') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, properties.style, 'none');
          return true;
        });
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, properties.width, getStringValue(incomingBorder.width, 'px'));
          tr.setNodeAttribute(activeNodePos, properties.color, incomingBorder.color);
          tr.setNodeAttribute(activeNodePos, properties.style, incomingBorder.style);
          tr.setNodeAttribute(activeNodePos, properties.radius, getStringValue(incomingBorder.radius, 'px'));
          return true;
        });
      }
    },
    [editor, activeNodePos, properties]
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Border
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  <div className="w-4 h-4 rounded-md" style={{ backgroundColor: border.color }} />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {border.style}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatedBorder = { ...border, style: 'none' };
                    handleUpdateBorder(updatedBorder);
                    setBorder(updatedBorder);
                    setIsPopoverOpen(false);
                  }}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[255px]" align="start" side="left" sideOffset={20}>
        <div className="max-h-[500px] overflow-y-auto flex flex-col gap-2">
          <Text size="sm" weight="semibold">
            Border
          </Text>

          <ColorSettings
            editor={editor}
            title="Color"
            property="border"
            initialColor={border.color}
            activeNodeResult={activeNodeResult}
            onOverrideSetColor={(value: string | null) => {
              const updatedBorder = { ...border, color: value || DEFAULT_COLOR };
              handleUpdateBorder(updatedBorder);
              setBorder(updatedBorder);
            }}
          />
          <AttributeDropdown
            title="Style"
            defaultValue="Solid"
            options={[
              {
                label: 'Solid',
                onSelect: () => {
                  const updatedBorder = { ...border, style: 'solid' };
                  handleUpdateBorder(updatedBorder);
                  setBorder(updatedBorder);
                },
              },
              {
                label: 'Dashed',
                onSelect: () => {
                  const updatedBorder = { ...border, style: 'dashed' };
                  handleUpdateBorder(updatedBorder);
                  setBorder(updatedBorder);
                },
              },
              {
                label: 'Dotted',
                onSelect: () => {
                  const updatedBorder = { ...border, style: 'dotted' };
                  handleUpdateBorder(updatedBorder);
                  setBorder(updatedBorder);
                },
              },
            ]}
          />
          <BoxModelToggle
            title="Width"
            values={border.width}
            onUpdate={(position, value) => {
              if (position === 'all') {
                const updatedBorder = {
                  ...border,
                  width: { ...border.width, top: value, right: value, bottom: value, left: value },
                };
                handleUpdateBorder(updatedBorder);
                setBorder(updatedBorder);
              } else {
                const updatedBorder = { ...border, width: { ...border.width, [position]: value } };
                handleUpdateBorder(updatedBorder);
                setBorder(updatedBorder);
              }
            }}
            suffixes={{
              top: 't',
              right: 'r',
              bottom: 'b',
              left: 'l',
            }}
          />

          <BoxModelToggle
            title="Radius"
            values={border.radius}
            suffixes={{
              top: 'tl',
              right: 'tr',
              bottom: 'br',
              left: 'bl',
            }}
            onUpdate={(position, value) => {
              if (position === 'all') {
                const updatedBorder = {
                  ...border,
                  radius: { ...border.radius, top: value, right: value, bottom: value, left: value },
                };
                handleUpdateBorder(updatedBorder);
                setBorder(updatedBorder);
              } else {
                const updatedBorder = { ...border, radius: { ...border.radius, [position]: value } };
                handleUpdateBorder(updatedBorder);
                setBorder(updatedBorder);
              }
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default BorderSettings;
