import { useCallback, useMemo, useState } from 'react';
import { Clock } from '@phosphor-icons/react';
import { PostElement, TPostAttributes } from '@shared/dream-components';

import { Button } from '../../../../UI/Button';
import { Textarea } from '../../../../UI/Textarea';
import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {
  property: string;
};

const getCardStructure = (initialStructure: any, incomingStructure: string): any => {
  if (!incomingStructure) return initialStructure;

  try {
    const parsedStructure = JSON.parse(incomingStructure);
    return parsedStructure as any;
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return {};
  }
};

export const StructureSettings = ({ editor, activeNodeResult, property }: Props) => {
  const { activeNodePos, activeNode } = activeNodeResult;
  const textValue = 'structure';
  const [structure, setStructure] = useState('');

  const cardStructure = useMemo(() => {
    return getCardStructure(activeNode?.attrs?.cardStructure, structure);
  }, [activeNode?.attrs?.cardStructure, structure]);

  const handleUpdate = useCallback(
    () => {
      if (!activeNodeResult) return;

      try {
        console.log('cardStructure', typeof cardStructure);
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'cardStructure', cardStructure);
          return true;
        });
        // Now you can work with `nodeObject` as an object
      } catch (error) {
        console.error('Error parsing JSON string:', error);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title="Structure" text={textValue} popoverTitle="Structure" Icon={Clock} className="w-full">
      <div className="flex gap-8">
        <div className="transform shadow-lg p-4 rounded-lg w-[300px]">
          <PostElement
            element={{
              type: 'post',
              attrs: {
                ...(activeNode?.attrs as TPostAttributes),
                cardStructure: cardStructure as any,
                data: {
                  // We only need one post to display the structure
                  posts: activeNode?.attrs?.data?.posts.slice(0, 1),
                },
              },
            }}
          >
            {null}
          </PostElement>
        </div>

        <div className="flex flex-col gap-2 w-[300px]">
          <Textarea
            className="h-full"
            rows={18}
            value={structure}
            onChange={(e) => setStructure(e.target.value)}
            placeholder="Structure"
          />
          <Button variant="primary" onClick={handleUpdate}>
            Apply
          </Button>
        </div>
      </div>
    </PopoverHelper>
  );
};
