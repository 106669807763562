import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { WebTheme } from '@/interfaces/web_template';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';
import { usePublication } from '../usePublications';

type WebTemplatePayload = {
  theme?: string;
  feature_post_ids?: string[];
  category_ids?: string[];
  multi_pub_ids?: string[];
  author_ids?: string[];
  enable_featured_post_badges?: boolean;
  enable_recommended_reading?: boolean;
  enable_customize_category_ids?: boolean;
  enable_upgrade_page_post_signup?: boolean;
  enable_multi_pub_post_signup?: boolean;
  enable_recommendations_page_post_signup?: boolean;
  enable_subscription_passwords?: boolean;
  custom_signup_redirect_url?: string | null;
  enable_comments?: boolean;
  enable_recaptcha?: boolean;
  signup_flow_messaging?: string;
  logged_subscribe_form?: boolean;
  external_upgrade_page_href?: string;
  web_theme_preview?: WebTheme;
  comments_ordering?: string;
  notifications_enabled?: boolean;
  pwa_enabled?: boolean;
};

const useUpdateWebTemplate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = usePublication(currentPublicationId);

  return useMutation(
    (webTemplate: WebTemplatePayload) =>
      api.patch(`/web_template/${currentPublication?.web_template_id}`, {
        publication_id: currentPublicationId,
        web_template: webTemplate,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['web_template', currentPublicationId]);
        if (onSuccess) onSuccess();
      },
    }
  );
};

export default useUpdateWebTemplate;
