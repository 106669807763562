import { FC } from 'react';
import { SurveyElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import SurveySelectionModal from '../modal/SurveySelectionModal';

export const SurveyView: FC<NodeViewProps> = ({ editor, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <SurveyElement
      element={{
        type: 'survey',
        content: [{ text: '', id: 'text' }],
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          data: node.attrs.data,
        },
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
      }}
    >
      <div className="absolute top-0 left-0 group hover:backdrop-blur-sm hover:bg-white/50 h-full w-full transition-all duration-300 flex items-center justify-center">
        <div className="group-hover:block hidden">
          <SurveySelectionModal editor={editor} node={node} />
        </div>
      </div>
    </SurveyElement>
  );
};
