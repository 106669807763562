/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */

import { FC, Fragment, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { EllipsisDropdownOption } from '../../interfaces/general';
import IconButton from '../IconHelpers/IconButton';

interface Props {
  options?: EllipsisDropdownOption[];
  portalMountedId?: string;
  children?: React.ReactNode;
}

const EllipsisDropdown: FC<Props> = (props: Props) => {
  const popperElRef = useRef(null);
  const targetElement = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement?.current, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [14, 8],
        },
      },
    ],
  });

  const { options, portalMountedId, children } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div className="flex items-center" ref={targetElement}>
            <Menu.Button>
              {children ?? (
                <IconButton backgroundColor="bg-transparent">
                  <EllipsisVerticalIcon className="w-3 h-3" aria-hidden="true" />
                </IconButton>
              )}
            </Menu.Button>
          </div>

          <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
            <div ref={popperElRef} className="z-10" style={styles.popper} {...attributes.popper}>
              <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items className="origin-top-right z-10 absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {options?.map((option) => (
                      <Menu.Item key={option.label}>
                        {({ active }) => (
                          <button
                            key={option.label}
                            onClick={() => option.onClick()}
                            className={cx(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm text-left w-full',
                              option.labelClass
                            )}
                          >
                            {option.label}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
};

EllipsisDropdown.defaultProps = {
  options: [],
};

export default EllipsisDropdown;
