import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Card } from '@/ui/Card';

import PageContainer from '../components/PageContainer';
import { usePublicationSettings } from '../context';

import ContentAccessForm from './ContentAccessForm';
import GeneralInfoForm from './GeneralInfoForm';
import UTMAnalyticsForm from './UTMAnalyticsForm';

const PublicationGeneralSettings: React.FC = () => {
  const { settings, currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card id="basic" showCopyIdButton>
            <GeneralInfoForm publication={publication} />
          </Card>
          <Card id="content-access" showCopyIdButton>
            <ContentAccessForm publication={publication} />
          </Card>
          {settings?.utm_params ? (
            <Card id="analytics" showCopyIdButton>
              <UTMAnalyticsForm publication={publication} />
            </Card>
          ) : null}
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationGeneralSettings;
