import { File } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';


export const LinkToSettings = () => {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;

  // TODO: implement links & internal links
  // const contentValue = selectedContent?.attrs && 'href' in selectedContent.attrs && selectedContent.attrs.href || '';


  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Link to
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <div className="w-full justify-between flex items-center gap-2 p-2">
          <div className="flex items-center gap-1">
            <File size={16} weight="bold" className="text-wb-secondary" />
            <Text size="2xs" weight="medium">
              Home
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
