import { useState } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import Card from '@/components/Card';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SearchInput from '@/components/SearchInput';
import { useAsyncExport } from '@/hooks';
import { useLedgers } from '@/hooks/admin_reports/useLedgers';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Ledger } from '@/interfaces/admin_reports/ledger';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import LedgerRow from './LedgerRow';

const Ledgers = () => {
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = useLedgers({
    search,
    startDate,
    endDate,
  });

  const currentPublicationId = useCurrentPublicationId();
  const ledgers = data?.pages.flatMap((page) => page.ledgers) || [];
  const totalLedgersCount = data?.pages[0]?.pagination?.total;
  const showingLedgersCount = ledgers?.length || 0;

  const { startExport, isExporting } = useAsyncExport(currentPublicationId, {
    exportType: 'accounting_ledger',
    resourceType: 'publication',
    resourceId: currentPublicationId,
    filters: {
      search,
      start_date: startDate?.toISOString() || '',
      end_date: endDate?.toISOString() || '',
    },
  });

  const handleStartDateChange = (e: any) => {
    const date = moment.utc(e.target.valueAsDate).startOf('day').toDate();
    setStartDate(date);
  };

  const handleEndDateChange = (e: any) => {
    const date = moment.utc(e.target.valueAsDate).endOf('day').toDate();
    setEndDate(date);
  };

  return (
    <div className="mx-12 mt-8 mb-12">
      <PageHeading
        title="Boost Ledgers"
        description="See the balance for publication ledgers, or download a detailed report."
      >
        <div className="flex items-center space-x-4">
          <SearchInput onSearch={(val) => setSearch(val)} block placeholder="Search Publication" shouldDebounce />
        </div>
      </PageHeading>

      <div className="mb-8 border-b border-gray-200" />

      <div className="flex flex-row justify-between w-full items-end mb-1">
        <span className="w-full text-xs font-semibold text-gray-600">{`Showing ${showingLedgersCount} of ${totalLedgersCount} results`}</span>
        <div className="flex flex-row space-x-2 items-end">
          <div className="flex flex-col space-y-2 text-sm text-gray-500 mb-1">
            <span>Start Date</span>
            <input
              name="start_date"
              type="date"
              className="border border-gray-300 rounded-md"
              onChange={handleStartDateChange}
            />
          </div>
          <div className="flex flex-col space-y-2 text-sm text-gray-500 mb-1">
            <span>End Date</span>
            <input
              name="end_date"
              type="date"
              className="border border-gray-300 rounded-md"
              onChange={handleEndDateChange}
            />
          </div>
          <Button
            Icon={ArrowDownTrayIcon}
            loading={isExporting}
            variant="primary"
            onClick={startExport}
            className="text-sm text-gray-500 mb-1"
          >
            <span className="my-1">Download CSV</span>
          </Button>
        </div>
      </div>
      {(isLoading || isFetching) && (
        <div className="hidden md:block">
          <LoadingSpinner />
        </div>
      )}
      <Card noPadding>
        <div className="w-full max-w-2/3">
          <div className="border-t border-b border-gray-100 text-sm text-gray-500 bg-gray-50 grid grid-cols-3 gap-4">
            <div className="px-4 py-1 font-normal text-left">Publication Name</div>
            <div className="px-4 py-1 font-normal text-right invisible sm:visible">Total Balance</div>
            <div className="px-4 py-1 font-normal text-right">Account Kind</div>
          </div>
        </div>
        <ul className="divide-y rounded">
          {ledgers.map((ledger: Ledger) => (
            <LedgerRow ledger={ledger} />
          ))}
        </ul>
        {hasNextPage && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Ledgers;
