import { useSearchParams } from 'react-router-dom';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { EmptyCard, ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { ExternalEmbed } from '@/interfaces/external_embed';
import { Card } from '@/ui/Card';

interface Props {
  externalEmbeds?: ExternalEmbed[];
}

const List = ({ externalEmbeds }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!externalEmbeds || externalEmbeds.length === 0) {
    return <EmptyCard title="No Subscribe Forms" />;
  }

  const openEditForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    setSearchParams(searchParams);
  };

  const showViewExternalEmbedForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    searchParams.set('view', 'true');
    setSearchParams(searchParams);
  };

  return (
    <Card>
      <Items>
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Name</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Description</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>&nbsp;</ItemHeader>
        </ItemHeaders>
        <ItemsBody>
          {externalEmbeds.map((externalEmbed) => (
            <ItemRow key={externalEmbed.id}>
              <ItemColumn align={AlignType.LEFT} className="sm:w-56">
                <Typography weight="medium" size="sm">
                  {externalEmbed.name}
                </Typography>
              </ItemColumn>
              <ItemColumn align={AlignType.LEFT} className="sm:max-w-lg">
                <Typography size="sm" colorWeight="700">
                  {externalEmbed.description}
                </Typography>
              </ItemColumn>
              <ItemColumn align={AlignType.LEFT} className="space-x-1 space-y-1">
                <EllipsisDropdown
                  options={[
                    {
                      label: 'View',
                      onClick: () => showViewExternalEmbedForm(externalEmbed.id),
                    },
                    {
                      label: 'Edit',
                      onClick: () => openEditForm(externalEmbed.id),
                    },
                  ]}
                />
              </ItemColumn>
            </ItemRow>
          ))}
        </ItemsBody>
      </Items>
    </Card>
  );
};

export default List;
