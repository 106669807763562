import {
  CaretRight,
  Code,
  Image,
  ListBullets,
  ListNumbers,
  Quotes,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextT,
} from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

type BlockTypeItem = {
  type: string;
  level?: number;
  name: string;
  icon: React.ElementType;
  handleChange?: (editor: Editor) => void;
};

const textBlockTypes: Array<BlockTypeItem> = [
  {
    type: 'heading',
    level: 1,
    name: 'Heading 1',
    icon: TextHOne,
    handleChange: (editor: Editor) => {
      editor.chain().focus().setHeading({ level: 1 }).run();
    },
  },
  {
    type: 'heading',
    level: 2,
    name: 'Heading 2',
    icon: TextHTwo,
    handleChange: (editor: Editor) => {
      editor.chain().focus().setHeading({ level: 2 }).run();
    },
  },
  {
    type: 'heading',
    level: 3,
    name: 'Heading 3',
    icon: TextHThree,
    handleChange: (editor: Editor) => {
      editor.chain().focus().setHeading({ level: 3 }).run();
    },
  },
  // TODO: add heading 4, 5, 6
  {
    type: 'paragraph',
    name: 'Paragraph',
    icon: TextT,
    handleChange: (editor: Editor) => {
      editor.chain().focus().setParagraph().run();
    },
  },
  {
    type: 'bulletList',
    name: 'Bullet List',
    icon: ListBullets,
    handleChange: (editor: Editor) => {
      // TODO: add bullet list
      editor.chain().focus().run();
    },
  },
  {
    type: 'orderedList',
    name: 'Ordered List',
    icon: ListNumbers,
    handleChange: (editor: Editor) => {
      // TODO: add ordered list
      editor.chain().focus().run();
    },
  },
];

const richBlockTypes: Array<BlockTypeItem> = [
  {
    type: 'blockquote',
    name: 'Quote',
    icon: Quotes,
  },
  {
    type: 'codeBlock',
    name: 'Code Block',
    icon: Code,
  },
];

const mediaTypes: Array<BlockTypeItem> = [
  {
    type: 'image',
    name: 'Image',
    icon: Image,
  },
];

const blockTypes = {
  text: textBlockTypes,
  richBlock: richBlockTypes,
  media: mediaTypes,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

export const BlockTypeSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodeAttributes } = useActiveNode(editor);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Type
        </Text>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <TextT className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  {formatActiveNodeType(
                    activeNodeType && activeNodeType.charAt(0).toUpperCase() + activeNodeType.slice(1)
                  )}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Block Types
            </Text>
          </div>

          <div className="flex flex-col gap-1.5">
            <Text variant="secondary" size="2xs" weight="medium">
              Text
            </Text>
            {/* list of text types */}
            <div className="flex flex-col gap-2">
              {blockTypes.text.map((blockType) => {
                let isSelected = blockType.type === activeNodeType;
                if (blockType.type === 'heading') {
                  isSelected = blockType.level === activeNodeAttributes?.level;
                }

                return (
                  <div
                    key={blockType.name}
                    className={`flex items-center gap-2 px-3 py-2 rounded-lg cursor-pointer border ${isSelected
                        ? 'bg-wb-button-accent-soft text-wb-accent border-wb-accent-soft'
                        : 'bg-wb-secondary border-transparent'
                      }`}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (!activeNodeType || !activeNodeAttributes) return;
                      if (e.key === 'Enter' || e.key === ' ') {
                        blockType.handleChange?.(editor);
                      }
                    }}
                    onClick={() => {
                      if (!activeNodeType || !activeNodeAttributes) return;
                      blockType.handleChange?.(editor);
                    }}
                  >
                    <blockType.icon format="outline" weight="bold" />
                    <Text size="2xs" weight="medium">
                      {blockType.name}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-1.5">
            <Text variant="secondary" size="2xs" weight="medium">
              Rich Blocks
            </Text>
            {/* list of text types */}
            <div className="flex flex-col gap-2">
              {blockTypes.richBlock.map((blockType) => {
                const isSelected = blockType.type === activeNodeType;
                return (
                  <div
                    key={blockType.name}
                    className={`flex items-center gap-2 px-3 py-2 rounded-lg cursor-pointer border ${isSelected
                        ? 'bg-wb-button-accent-soft text-wb-accent  border-wb-accent-soft'
                        : 'bg-wb-secondary border-transparent'
                      }`}
                  >
                    <blockType.icon format="outline" weight="bold" />
                    <Text size="2xs" weight="medium">
                      {blockType.name}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-1.5">
            <Text variant="secondary" size="2xs" weight="medium">
              Media
            </Text>
            {/* list of text types */}
            <div className="flex flex-col gap-2">
              {blockTypes.media.map((blockType) => {
                const isSelected = blockType.type === activeNodeType;
                return (
                  <div
                    key={blockType.name}
                    className={`flex items-center gap-2 px-3 py-2 rounded-lg cursor-pointer border ${isSelected
                        ? 'bg-wb-button-accent-soft text-wb-accent border-wb-accent-soft'
                        : 'bg-wb-secondary border-transparent'
                      }`}
                  >
                    <blockType.icon format="outline" weight="bold" />
                    <Text size="2xs" weight="medium">
                      {blockType.name}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
