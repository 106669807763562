import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import { RadioSelect } from '@/components/Form';
import usePremiumGiftOptions from '@/hooks/usePremiumGiftOptions';
import { PremiumGift } from '@/interfaces/premium_gift';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { RadioOption } from '../Form/RadioSelect';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  premiumGifts: PremiumGift[];
  publicationId: string;
  subscriberIds?: string[];
  segmentId?: string;
}

const PremiumGiftModal = (props: Props) => {
  const { isOpen, onClose, premiumGifts, publicationId, subscriberIds, segmentId } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const premiumGiftOptions = usePremiumGiftOptions<RadioOption>(premiumGifts);
  const [premiumGiftId, setPremiumGiftId] = useState(premiumGiftOptions[0]?.value || '');
  const premiumGiftsExist = premiumGifts.length > 0;
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsProcessing(true);
    api
      .post('/subscriptions/premium_gifts', {
        publication_id: publicationId,
        premium_gift: {
          id: premiumGiftId,
          subscriber_ids: subscriberIds,
          segment_id: segmentId,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        onClose();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handlePremiumGiftSelect = (selected: any) => {
    setPremiumGiftId(selected);
  };

  const renderPremiumGiftOptions = (
    <RadioSelect
      className="space-y-2"
      value={premiumGiftId}
      onSelect={(selected) => handlePremiumGiftSelect(selected)}
      options={premiumGiftOptions}
    />
  );

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleSubmit}
      onClose={onClose}
      resourceId={premiumGiftId}
      isWorking={isProcessing}
      headerText="Choose a Paid Subscription Gift to send"
      actionText="Send Gift"
      disabled={!premiumGiftsExist}
    >
      {premiumGiftsExist ? (
        renderPremiumGiftOptions
      ) : (
        <div>
          <p>No Premium Subscription Gifts Created</p>
          <div className="my-8 flex align-items-center justify-center">
            <Button variant="primary" onClick={() => navigate('/settings/publication/premium#premium-gifts')}>
              Create Gift
            </Button>
          </div>
        </div>
      )}
    </ActionModal>
  );
};

export default PremiumGiftModal;
