import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import ActionModal from '@/components/ActionModal';
import { RadioSelect, Switch, Textarea } from '@/components/Form';
import CountryMultiSelect from '@/components/Form/CountryMultiSelect';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import useCreateBoostOffer from '@/hooks/boosts/grow/useCreateBoostOffer';
import useFunds from '@/hooks/boosts/grow/useFunds';
import useUpdateBoostOffer from '@/hooks/boosts/grow/useUpdateBoostOffer';
import { BoostOffer, BoostOfferStatus, VerificationMode } from '@/interfaces/boosts/grow/boost_offer';
import { BoostOffer as MonetizeBoostOfferType } from '@/interfaces/boosts/monetize/boost_offer';
import { BoostOfferCard } from '@/pages/Monetize/Boosts/Shared/BoostOfferCard';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';
import { FormStepsWidget } from '@/ui/FormStepsWidget';
import analytics from '@/utils/analytics';

import currencyFormatter from '../utils/currencyFormatter';

interface Props {
  boostOffer?: BoostOffer;
  portalMountedId?: string;
}

const verificationModeOptions: Array<{
  name: string;
  value: VerificationMode;
  description: string;
}> = [
  {
    name: 'Standard (Recommended)',
    value: VerificationMode.STANDARD,
    description:
      'Our proprietary verification process that yields the highest quality subscribers and may take 10 - 17 days.',
  },
  {
    name: 'Relaxed',
    value: VerificationMode.RELAXED,
    description:
      'Our faster verification process that has fewer quality checks on subscribers but can take as little as 48 hours.',
  },
];

enum FormSteps {
  CONFIGURE = 1,
  QUALITY_CONTROLS = 2,
  EMAIL_BOOSTS = 3,
}

const BoostOfferForm = ({ boostOffer, portalMountedId }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { settings } = useSettings();
  const { hasBalance } = useFunds();

  const maxMaxSpendCents = 20000 * 100; // $20,000 max. Completely arbitrary.
  const minMaxSpendCents = 20000; // $200 min. Completely arbitrary.

  const [currentPublicationId] = useCurrentPublicationState();
  const [showConfirmDuplicateModal, setShowConfirmDuplicateModal] = useState(false);
  const [showReviewPendingApplicationsModal, setShowReviewPendingApplicationsModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<FormSteps>(FormSteps.CONFIGURE);
  const [costPerLeadCents, setCostPerLeadCents] = useState(boostOffer?.cost_per_lead_cents || 250);
  const [maxSpendCents, setMaxSpendCents] = useState(boostOffer?.max_spend_cents || 0);
  const [description, setDescription] = useState(boostOffer?.description || '');
  const [eligibleCountries, setEligibleCountries] = useState(
    boostOffer?.eligible_countries ? [...boostOffer.eligible_countries] : []
  );
  const [verificationMode, setVerificationMode] = useState(boostOffer?.verification_mode || VerificationMode.STANDARD);
  const [removeRejected, setRemoveRejected] = useState<boolean>(
    boostOffer?.remove_rejected === undefined ? true : boostOffer.remove_rejected
  );
  const [isSaving, setIsSaving] = useState(false);
  const boostsPlusEnabled = settings?.boosts_plus;
  const hasBoostInvites = settings?.boosts_invites === true;
  const [boostsPlusToggled, setBoostsPlusToggled] = useState(maxSpendCents !== 0);

  const [shouldResendInvites, setShouldResendInvites] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const { mutateAsync: updateBoostOffer } = useUpdateBoostOffer({
    id: boostOffer?.id || '',
  });
  const { mutateAsync: createBoostOffer } = useCreateBoostOffer({ resendBoostInvitations: shouldResendInvites });

  const payloadPerLead = currencyFormatter.format(((costPerLeadCents || 0) * 0.8) / 100);
  const maxPayoutCents = maxSpendCents * 0.8;
  const maxPayout = currencyFormatter.format(maxPayoutCents / 100);

  const isValid = () => {
    if (!boostsPlusToggled) {
      return true;
    }

    return !(
      Number.isNaN(maxSpendCents) ||
      Number(maxSpendCents) < minMaxSpendCents ||
      Number(maxSpendCents) > maxMaxSpendCents
    );
  };

  const isConfigureStepValid = !!costPerLeadCents;
  const isVerificationStepValid = !!verificationMode;

  const onCreate = () => {
    analytics.track('Boost Offer Created', {
      publication_id: currentPublicationId,
      cost_per_lead_cents: costPerLeadCents,
      max_spend_cents: maxSpendCents,
      description,
      name: `boost-offer-cpa-${costPerLeadCents}`,
      verification_mode: verificationMode,
      remove_rejected: removeRejected,
      status: BoostOfferStatus.LIVE,
      eligible_countries: eligibleCountries,
    });

    return createBoostOffer({
      cost_per_lead_cents: costPerLeadCents,
      max_spend_cents: maxSpendCents,
      description,
      name: `boost-offer-cpa-${costPerLeadCents}`,
      verification_mode: verificationMode,
      remove_rejected: removeRejected,
      status: BoostOfferStatus.LIVE,
      eligible_countries: eligibleCountries,
    });
  };

  const onDuplicate = () => {
    return createBoostOffer({
      cost_per_lead_cents: costPerLeadCents,
      max_spend_cents: maxSpendCents,
      description,
      name: `boost-offer-cpa-${costPerLeadCents}`,
      verification_mode: verificationMode,
      remove_rejected: removeRejected,
      status: BoostOfferStatus.LIVE,
      eligible_countries: eligibleCountries,
    });
  };

  const saveBoostOffer = async (shouldDuplicate: boolean) => {
    setIsSaving(true);

    analytics.track('Boost Offer Published');

    analytics.track('Boost Offer Edited', {
      publication_id: currentPublicationId,
      cost_per_lead_cents: costPerLeadCents,
      max_spend_cents: maxSpendCents,
      description,
      name: `boost-offer-cpa-${costPerLeadCents}`,
      verification_mode: verificationMode,
      remove_rejected: removeRejected,
      status: BoostOfferStatus.LIVE,
      eligible_countries: eligibleCountries,
    });

    if (shouldDuplicate) {
      // Only applicable in the new interface
      try {
        await onDuplicate();
        await queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_offer', boostOffer?.id]);
        await queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'primary_offer']);
        await queryClient.invalidateQueries([
          currentPublicationId,
          'boosts',
          'grow',
          'boost_offers',
          BoostOfferStatus.DRAFT,
        ]);
        toast.success('Offer created successfully');
      } catch (error: any) {
        // do nothing, the toast is already shown via the hook
      }
    } else if (!boostOffer) {
      try {
        await onCreate();
        toast.success('Offer created successfully');
      } catch (error: any) {
        // do nothing, the toast is already shown via the hook
      }
    } else {
      try {
        await updateBoostOffer({
          cost_per_lead_cents: costPerLeadCents,
          max_spend_cents: maxSpendCents,
          description,
          verification_mode: verificationMode,
          remove_rejected: removeRejected,
          eligible_countries: eligibleCountries,
        });
        toast.success('Offer updated successfully');
      } catch (error: any) {
        // do nothing, the toast is already shown via the hook
      }
    }

    navigate(-1);

    setIsSaving(false);
  };

  const handleSubmit = () => {
    // Show confirmation modal letting the user know
    // that a new offer will be created since they
    // have changed the CPA or eligible countries
    const hasEligibleCountriesChanged =
      eligibleCountries.length !== boostOffer?.eligible_countries?.length ||
      !isEqual(eligibleCountries, boostOffer?.eligible_countries);
    if (
      boostOffer &&
      (boostOffer.cost_per_lead_cents !== costPerLeadCents || hasEligibleCountriesChanged) &&
      boostOffer.status === BoostOfferStatus.LIVE
    ) {
      if (boostOffer.pending_application_count > 0) {
        setShowReviewPendingApplicationsModal(true);
      } else {
        setShowConfirmDuplicateModal(true);
      }
      return;
    }

    saveBoostOffer(false);
  };

  const handleMaxSpendCentsChanged = (val: number) => {
    setMaxSpendCents(val);
  };

  const handleBoostsPlusToggledChange = (checked: boolean) => {
    if (!checked) {
      setMaxSpendCents(0);
    }

    setBoostsPlusToggled(checked);
  };

  const handleCancel = () => navigate(-1);

  const handleClickPreviousStep = () => {
    switch (currentStep) {
      case FormSteps.CONFIGURE:
        break;
      case FormSteps.QUALITY_CONTROLS:
        setCurrentStep(FormSteps.CONFIGURE);
        break;
      case FormSteps.EMAIL_BOOSTS:
        setCurrentStep(FormSteps.QUALITY_CONTROLS);
        break;
      default:
    }
  };

  const handleClickNextStep = () => {
    switch (currentStep) {
      case FormSteps.CONFIGURE:
        setCurrentStep(FormSteps.QUALITY_CONTROLS);
        break;
      case FormSteps.QUALITY_CONTROLS:
        setCurrentStep(FormSteps.EMAIL_BOOSTS);
        break;
      default:
    }
  };

  const sampleBoostOffer = {
    id: '1',
    payout_per_lead_cents: costPerLeadCents,
    payout_per_lead: payloadPerLead,
    max_payout_cents: maxSpendCents,
    max_payout: maxPayout,
    description,
    boost_agreement_status: null,
    eligible_countries: eligibleCountries,
    boosted_publication: {
      id: 'id',
      name: currentPublication?.name,
      hostname: currentPublication?.hostname,
      url: currentPublication?.url,
      logo_url: currentPublication?.logo.url,
      description: currentPublication?.description || null,
      tags: currentPublication?.tags as any,
    },
  } as MonetizeBoostOfferType;

  const submitLabel = boostOffer ? 'Save' : 'Create';

  const progressSteps = [
    {
      label: 'Configure offer details',
      isCurrent: currentStep === FormSteps.CONFIGURE,
      wasCompleted: currentStep > 1,
    },
    {
      label: 'Set quality controls',
      isCurrent: currentStep === FormSteps.QUALITY_CONTROLS,
      wasCompleted: currentStep > 2,
    },
  ];

  if (boostsPlusEnabled) {
    progressSteps.push({
      label: 'Allow Email Boosts (Recommended)',
      isCurrent: currentStep === FormSteps.EMAIL_BOOSTS,
      wasCompleted: false,
    });
  }

  const handleClickCancelInConfirmModal = () => setShowConfirmDuplicateModal(false);

  const handleClickCancelInReviewApplicationsModal = () => setShowReviewPendingApplicationsModal(false);

  const handleClickConfirmInConfirmModal = () => {
    saveBoostOffer(true);
  };

  const handleClickConfirmInReviewApplicationsModal = () => {
    setShowReviewPendingApplicationsModal(false);
    setShowConfirmDuplicateModal(true);
  };

  const handleChangeInEligibleCountries = (countryCodes: string[]) => {
    setEligibleCountries(countryCodes);
  };

  return (
    <>
      <ActionModal
        isOpen={showConfirmDuplicateModal}
        onClose={handleClickCancelInConfirmModal}
        resourceId="confirm-duplication"
        headerText="New Offer"
        onProceed={handleClickConfirmInConfirmModal}
        isWorking={isSaving}
      >
        <div className="flex flex-col gap-y-2">
          <Typography token="font-medium/text/sm">
            Changing the CPA or Geolocation criteria will disable the current live Offer and create a new one,
            here&apos;s what this means:
          </Typography>
          <ul className="list-disc ml-4">
            <li>
              <Typography token="font-normal/text/sm">
                Your current live Offer will be disabled and the status will be changed to &lsquo;active&rsquo;
              </Typography>
            </li>
            <li>
              <Typography token="font-normal/text/sm">
                All Publications boosting your current live Offer will continue to boost you at the current CPA
              </Typography>
            </li>
            <li>
              <Typography token="font-normal/text/sm">
                Any new Publications applying to boost you in the marketplace will see the new CPA
              </Typography>
            </li>
          </ul>
        </div>
        {hasBoostInvites && (
          <div className="flex w-full justify-end">
            <Switch
              name="resend_invites_toggle"
              labelText="Resend Invitations"
              checked={shouldResendInvites}
              size="small"
              onChange={(_, checked: boolean) => setShouldResendInvites(checked)}
            />
          </div>
        )}
      </ActionModal>
      <ActionModal
        isOpen={showReviewPendingApplicationsModal}
        onClose={handleClickCancelInReviewApplicationsModal}
        resourceId="confirm-review-applications"
        headerText="Pending Applications"
        onProceed={handleClickConfirmInReviewApplicationsModal}
        isWorking={isSaving}
        actionText="I understand"
      >
        <div className="flex flex-col gap-y-2">
          <Typography token="font-medium/text/sm">
            You have pending applications for your current offer, changing the offer will reject those applications.
          </Typography>
          <Typography token="font-normal/text/sm">
            You can{' '}
            <Link to="/grow/boosts?tab=applications">
              <strong>review</strong>
            </Link>{' '}
            the applications before proceeding.
          </Typography>
        </div>
      </ActionModal>
      <div className="grid gap-6 grid-cols-5">
        <div className="lg:col-span-3 col-span-5">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <FormStep
              isOpen={currentStep === FormSteps.CONFIGURE}
              stepNumber={1}
              stepTitle="Configure offer details"
              footer={
                <>
                  <Button type="button" variant="primary-inverse" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleClickNextStep}
                    disabled={!isConfigureStepValid}
                  >
                    Continue
                  </Button>
                </>
              }
            >
              <CurrencyInput
                name="cost_per_lead"
                id="cost_per_lead"
                placeholder="$2.75"
                value={costPerLeadCents}
                onChange={(val) => setCostPerLeadCents(Number(val))}
                labelText="Cost per acquisition (CPA)"
                helperText="The price you are willing to pay to acquire a new subscriber via a Web Boost. You can't change this after publishing. "
                minCents={50}
                maxCents={50 * 100}
                portalMountedId={portalMountedId}
              />
              <Textarea
                name="description"
                labelText="Target Audience"
                value={description}
                helperText="This is displayed to  other beehiiv users when viewing available offers in the marketplace."
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormStep>

            <FormStep
              isOpen={currentStep === FormSteps.QUALITY_CONTROLS}
              stepNumber={2}
              stepTitle="Set quality controls"
              footer={
                <>
                  <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep}>
                    Back
                  </Button>
                  <Button
                    type="button"
                    loading={isSaving}
                    variant="primary"
                    onClick={boostsPlusEnabled ? handleClickNextStep : handleSubmit}
                    disabled={!isVerificationStepValid || (boostsPlusToggled && !hasBalance)}
                  >
                    {boostsPlusEnabled ? 'Continue' : submitLabel}
                  </Button>
                </>
              }
            >
              <RadioSelect
                value={verificationMode}
                onSelect={(val) => setVerificationMode(val as VerificationMode)}
                options={verificationModeOptions}
                className="mt-1"
                labelText="Verification mode"
              />

              <Switch
                name="remove_rejected_switch"
                labelText="Auto clean Boost verification denied subscribers (Optional)"
                helperText={
                  removeRejected
                    ? 'Toggle off to keep rejected subscribers on your list'
                    : 'Toggle on to remove rejected subscribers from your list automatically'
                }
                checked={!!removeRejected}
                variant="primary"
                size="large"
                onChange={(_, checked: boolean) => setRemoveRejected(checked)}
              />

              <CountryMultiSelect
                defaultSelection={eligibleCountries}
                onChange={handleChangeInEligibleCountries}
                labelText="Geo-location criteria (Optional)"
                helperText="Select the countries from which Boosted subscribers are eligible for payouts"
              />
            </FormStep>

            {boostsPlusEnabled && (
              <FormStep
                isOpen={currentStep === FormSteps.EMAIL_BOOSTS}
                stepNumber={3}
                stepTitle="Allow Email Boosts (optional)"
                footer={
                  <>
                    <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep}>
                      Back
                    </Button>
                    <Button
                      type="button"
                      loading={isSaving}
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={!hasBalance || !isValid()}
                    >
                      {submitLabel}
                    </Button>
                  </>
                }
              >
                <Switch
                  name="boosts_plus_toggled"
                  labelText="Enable Email Boost channel"
                  helperText="Toggle on to set this channel up so users can request to add your boost to their post once you've approved their Web Boost application."
                  checked={boostsPlusToggled}
                  variant="primary"
                  size="large"
                  onChange={(_, checked: boolean) => handleBoostsPlusToggledChange(checked)}
                />

                {boostsPlusToggled && (
                  <CurrencyInput
                    name="max_spend_cents"
                    id="max_spend_cents"
                    placeholder="$1000.00"
                    value={maxSpendCents}
                    onChange={(val) => handleMaxSpendCentsChanged(Number(val))}
                    labelText="Max Spend"
                    helperText="This is the maximum you will pay per Email Boost send. These funds will be pulled into escrow for the 72 hour email send window, after which any unused funds will be returned to your account."
                    minCents={minMaxSpendCents}
                    maxCents={maxMaxSpendCents}
                  />
                )}
              </FormStep>
            )}
          </form>
        </div>
        <div className="col-span-5 lg:col-span-2 space-y-4 hidden sm:block">
          <FormStepsWidget steps={progressSteps} />
          {currentPublication && <BoostOfferCard boostOffer={sampleBoostOffer} isPreview />}
        </div>
      </div>
    </>
  );
};

export default BoostOfferForm;
