import { AuthorsElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

import AuthorsSelectionModal from '../modal/AuthorsSelectionModal';

export const AuthorsView = ({ editor, node }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  return (
    <AuthorsElement
      element={{
        type: 'authors',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <div className="absolute top-0 left-0 group hover:backdrop-blur-sm hover:bg-white/50 h-full w-full transition-all duration-300 flex items-center justify-center">
        <div className="group-hover:block hidden">
          <AuthorsSelectionModal editor={editor} node={node} />
        </div>
      </div>
    </AuthorsElement>
  );
};
