import { Editor } from '@tiptap/core';

import { AttributeDropdown } from '../helpers/AttributeDropdown';

interface Props {
  editor: Editor;
  activeNodePos?: number;
}

const Orientation = ({ editor, activeNodePos }: Props) => {
  const handleUpdate = (value: string) => {
    if (!activeNodePos) return;

    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'orientation', value);
      return true;
    });
  };

  return (
    <AttributeDropdown
      title="Orientation"
      defaultValue="horizontal"
      options={[
        {
          label: 'Horizontal',
          onSelect: () => handleUpdate('horizontal'),
        },
        {
          label: 'Vertical',
          onSelect: () => handleUpdate('vertical'),
        },
      ]}
    />
  );
};

export default Orientation;
