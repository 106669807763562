import moment from 'moment-mini';

import Badge, { BadgeProps } from '@/components/Badge';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { ContentImport, ContentImportStatus } from '@/interfaces/content_import';

const contentImportBadgeType: { [K in ContentImportStatus]: BadgeProps['type'] } = {
  complete: 'success',
  failed: 'alert',
  pending: 'information',
  processing: 'info_blue',
};

interface ListItemProps {
  contentImport: ContentImport;
}

const ListItem: React.FunctionComponent<ListItemProps> = ({ contentImport }) => {
  const {
    status,
    platform,
    created_at: createdAt,
    created_by_user: user,
    num_posts_created: numPostsCreated,
    num_posts_failed: numPostsFailed,
  } = contentImport;
  const badgeType = contentImportBadgeType[status];

  return (
    <ItemRow>
      <ItemColumn className="w-24" align={AlignType.MIDDLE}>
        <Typography size="sm" colorWeight="700" as="div" className="capitalize">
          {platform}
        </Typography>
      </ItemColumn>
      <ItemColumn align={AlignType.MIDDLE}>
        <Typography size="sm" colorWeight="700" as="div" className="truncate">
          {user.name}
        </Typography>
      </ItemColumn>
      <ItemColumn className="w-24" align={AlignType.TOP}>
        <Badge className="capitalize" type={badgeType}>
          {status}
        </Badge>
      </ItemColumn>
      <ItemColumn className="w-28" align={AlignType.TOP}>
        <Typography size="sm" colorWeight="700" as="div">
          {moment(createdAt).format('MMM D, YYYY')}
        </Typography>
      </ItemColumn>
      <ItemColumn className="w-28" align={AlignType.TOP}>
        <Typography size="sm" colorWeight="700" as="div" className="truncate">
          {numPostsCreated || '-'}
        </Typography>
      </ItemColumn>
      <ItemColumn className="w-24" align={AlignType.TOP}>
        <Typography size="sm" colorWeight="700" as="div" className="truncate">
          {numPostsFailed || '-'}
        </Typography>
      </ItemColumn>
    </ItemRow>
  );
};

export default ListItem;
