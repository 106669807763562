import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Bars3BottomLeftIcon } from '@heroicons/react/20/solid';
import { cx } from 'class-variance-authority';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import Badge from '@/components/Badge';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { Typography } from '@/components/Typography';
import { useCurrentPublication } from '@/hooks';
import useGuestAuthors from '@/hooks/useGuestAuthors';
import useUsers from '@/hooks/useUsers';
import { Post, PostStatus } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

const DEFAULTS = {
  THUMBNAIL_URL: 'https://media.beehiiv.com/static_assets/publish_on_beehiiv.png',
};

interface Props {
  post: Post;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
}

const PostMeta = ({ post, setPostEditorStep }: Props) => {
  const { data: publication } = useCurrentPublication();
  const [iconColor, setIconColor] = useState('text-action-secondary-600');
  const [postSent, setPostSent] = useState(false);
  const { data: users } = useUsers();
  const { data: guestAuthors } = useGuestAuthors();

  const authors = users?.filter((user) => post?.user_ids?.includes(user.id)) || [];
  const currentGuestAuthors =
    guestAuthors?.guest_authors
      .filter((guestUser) => post?.guest_author_ids.includes(guestUser.id))
      ?.map((guestAuthor) => guestAuthor.name) || [];
  const currentAuthors = authors?.map((author) => author.name);
  const allAuthors = [...currentAuthors, ...currentGuestAuthors];
  const stringifiedAuthors = allAuthors.join(', ');

  useEffect(() => {
    if (post?.status === PostStatus.PUBLISHED || post?.status === PostStatus.ARCHIVED) {
      setIconColor('text-feedback-success-500');
      setPostSent(true);
    } else if (post?.status === PostStatus.DRAFT) {
      setIconColor('text-action-secondary-600');
    } else {
      setIconColor('text-feedback-info-500');
    }
  }, [post?.status]);

  let publicationThumbnail = DEFAULTS.THUMBNAIL_URL;
  if (
    typeof publication?.thumbnail.url === 'string' &&
    !publication?.thumbnail.url.endsWith('defaults/thumbnail.png')
  ) {
    publicationThumbnail = publication?.thumbnail?.url;
  }
  const seoPreviewImage = post?.thumbnail?.url || publicationThumbnail;

  return (
    <div className="relative w-2/3 mx-auto max-w-[40rem]">
      <AccordionCard
        cardPaddingClass="p-6"
        title={
          <span className="flex flex-row gap-3">
            <Bars3BottomLeftIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-1">
                <Typography token="font-normal/text/base" colorWeight="500">
                  Post Title:{' '}
                  <Typography token="font-medium/text/base" colorWeight="900">
                    {post.web_title}
                  </Typography>
                </Typography>
              </div>
            </div>
          </span>
        }
        subText=""
        topBorder={false}
        footer={
          <div
            className={cx(
              'flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50',
              postSent ? 'hidden' : ''
            )}
          >
            <ButtonGroup>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.COMPOSE)}
                type="button"
                variant="primary-inverse"
                size="sm"
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        }
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm">Thumbnail</Typography>
              {!post.display_thumbnail_on_web && <Badge>Hidden on top in web</Badge>}
            </div>
            <img src={seoPreviewImage} alt="Thumbnail" className="w-full max-w-[216px]" />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm">Title</Typography>
              {!post.display_title_in_email && <Badge>Hidden in email</Badge>}
            </div>
            <Typography token="font-normal/text/sm" colorWeight="500">
              {post.web_title}
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm">Subtitle</Typography>
              {!post.display_subtitle_in_email && <Badge>Hidden in email</Badge>}
            </div>
            <Typography token="font-normal/text/sm" colorWeight="500">
              {post.web_subtitle || '-'}
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm">Authors</Typography>
              {!post.display_byline_in_email && <Badge>Hidden in email</Badge>}
            </div>
            <Typography token="font-normal/text/sm" colorWeight="500">
              {stringifiedAuthors}
            </Typography>
          </div>
        </div>
      </AccordionCard>
    </div>
  );
};

export default PostMeta;
