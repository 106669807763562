import { useMemo, useState } from 'react';

import Modal from '@/components/Modal';
import TabNavigation from '@/components/TabNavigation';
import { useCurrentPublication } from '@/hooks';

import Polls from './Polls';
import PremiumData from './PremiumData';
import SendAnalytics from './SendAnalytics';

export interface AutomationStepExpandedMetric {
  type: 'send_analytics' | 'polls' | 'premium_data';
  automationStepId: string;
  resourceId: string;
}

interface Props {
  expandedMetrics: AutomationStepExpandedMetric[];
  isOpen: boolean;
  onClose: () => void;
}

const ExpandedMetricsModal: React.FC<Props> = ({ isOpen, onClose, expandedMetrics }) => {
  const [activeMetric, setActiveMetric] = useState<AutomationStepExpandedMetric | undefined>(expandedMetrics[0]);
  const expandedMetricTypes = useMemo(() => expandedMetrics.map((m) => m.type as string), [expandedMetrics]);
  const { data: currentPublication } = useCurrentPublication();

  const tabs = useMemo(
    () =>
      [
        {
          name: 'send_analytics',
          label: 'Send Analytics',
          selected: activeMetric?.type === 'send_analytics',
          onSelect: () => setActiveMetric(expandedMetrics.find((m) => m.type === 'send_analytics')),
        },
        {
          name: 'polls',
          label: 'Polls',
          selected: activeMetric?.type === 'polls',
          onSelect: () => setActiveMetric(expandedMetrics.find((m) => m.type === 'polls')),
        },
        {
          name: 'premium_data',
          label: 'Paid Subscriptions Data',
          selected: activeMetric?.type === 'premium_data',
          onSelect: () => setActiveMetric(expandedMetrics.find((m) => m.type === 'premium_data')),
          disabled: !currentPublication?.is_premium_enabled,
          disableWith: 'You must have a paid tier active',
        },
      ].filter((tab) => expandedMetricTypes.includes(tab.name)),
    [activeMetric?.type, expandedMetricTypes, expandedMetrics, currentPublication]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-11/12" customStyle={{ minHeight: '90vh' }}>
      <div className="p-4">
        <TabNavigation tabs={tabs} className="border-b border-gray-200" />
        {activeMetric?.type === 'send_analytics' && <SendAnalytics emailMessageId={activeMetric.resourceId} />}
        {activeMetric?.type === 'polls' && <Polls emailMessageId={activeMetric.resourceId} />}
        {activeMetric?.type === 'premium_data' && <PremiumData emailMessageId={activeMetric.resourceId} />}
      </div>
    </Modal>
  );
};

export default ExpandedMetricsModal;
