import { useCallback, useEffect, useState } from 'react';

import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

type Props = AttributeSettingProps & {
  title: string;
  property: string;
  onOverrideSetColor?: (value: string | null) => void;
  initialColor?: string | null;

  // if true, will set the color as a mark style instead of a node attribute
  isMarkStyle?: boolean;
};

const DEFAULT_COLOR = '#000000FF';

export const ColorSettings = ({
  editor,
  title,
  property,
  activeNodeResult,
  onOverrideSetColor,
  initialColor,
  isMarkStyle = false,
}: Props) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [color, setColor] = useState<string>(initialColor || DEFAULT_COLOR);

  const onSetColor = useCallback(
    (value: string | null) => {
      if (isMarkStyle) {
        const isLinkActive = editor.isActive('link');

        if (isLinkActive) {
          editor.chain().focus().extendMarkRange('link').updateAttributes('link', { color: value }).run();
          return;
        }

        if (value) {
          editor?.chain().focus().setColor(value).run();
        } else {
          editor?.chain().focus().unsetColor().run();
        }
        return;
      }
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property, isMarkStyle]
  );

  useEffect(() => {
    if (isMarkStyle) {
      setColor(editor.getAttributes('textStyle').color || DEFAULT_COLOR);
      return;
    }
    setColor(activeNodeAttributes[property]);
  }, [activeNodePos, editor]);

  const onReset = useCallback(() => {
    setColor(DEFAULT_COLOR);
    onSetColor(null);
  }, [onSetColor]);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start">
            <div className="w-4 h-4 rounded-md" style={{ backgroundColor: color }} />
            <Text size="2xs" weight="medium" className="">
              {color?.toUpperCase()}
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <ColorPicker
            key={activeNodeAttributes.id}
            selectedColor={color}
            onSetColor={(value: string | null) => {
              setColor(value || DEFAULT_COLOR);

              // Used as an escape hatch to prevent this from submitting if it's consumed by a parent component like in BorderSettings
              if (onOverrideSetColor) {
                onOverrideSetColor(value);
              } else {
                onSetColor(value);
              }
            }}
            onReset={onReset}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
