import { useEffect, useState } from 'react';

import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { Switch } from '../UI/Switch';
import { Text } from '../UI/Text';

interface Props {
  title: string;
  description: string;
  usesDreamBuilderSite?: boolean;
}

const SiteToggle = ({ title, description, usesDreamBuilderSite = false }: Props) => {
  const [isActive, setIsActive] = useState(usesDreamBuilderSite);

  useEffect(() => {
    setIsActive(usesDreamBuilderSite);
  }, [usesDreamBuilderSite]);

  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const handleToggle = () => {
    publicationMutation.mutate({ uses_dream_builder_site: !isActive });
    setIsActive(!isActive);
  };

  return (
    <div className="flex gap-2 border border-wb-primary rounded-lg p-4 w-full">
      <div className="flex w-full justify-between gap-2">
        <div>
          <div className="flex items-center space-x-2">
            <Text size="md" weight="semibold" variant="primary" as="h4">
              {title}
            </Text>
            <span
              className={`px-2 py-0.5 text-xs font-medium rounded-full mt-0.5 ${
                isActive ? 'bg-wb-success text-white' : 'bg-gray-100 text-gray-800'
              }`}
            >
              {isActive ? 'Live' : 'Disabled'}
            </span>
          </div>
          <Text size="sm" weight="medium" variant="secondary" as="p">
            {description}
          </Text>
        </div>

        <Switch checked={isActive} onCheckedChange={handleToggle} />
      </div>
    </div>
  );
};

export default SiteToggle;
