import { useMemo } from 'react';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';

import { ActiveNodeResult } from '../types';

export const useActiveNode = (editor: Editor): ActiveNodeResult => {
  return useMemo(() => {
    const { selection } = editor.state;
    let activeNodeType = '';
    let activeNodePos = -1;
    let activeNodeAttributes: Record<string, any> = {};
    let activeNode: ProseMirrorNode | null = null;

    if (selection instanceof NodeSelection) {
      activeNode = selection.node;
      activeNodeType = activeNode.type.name;
      activeNodeAttributes = activeNode.attrs;
      activeNodePos = selection.$anchor.pos;
    } else {
      // leaf node, select the parent
      const parentNode = selection.$anchor.parent;
      activeNode = parentNode;
      activeNodeType = parentNode.type.name;
      activeNodeAttributes = parentNode.attrs;
      activeNodePos = selection.$head.start(selection.$head.depth) - 1;
    }

    return {
      activeNode,
      activeNodeType,
      activeNodePos,
      activeNodeAttributes,
    };
  }, [editor.state]);
};
