import { forwardRef, memo, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import { CanvasPositionContext, CanvasPositionDispatchContext } from './CanvasContext';

const CANVAS_ID = 'canvas-container';
const CANVAS_CONTENT_ID = 'canvas-content';

const areEqual = (prevProps: object, nextProps: object) => isEqual(prevProps, nextProps);

export const ZoomControl = memo(() => {
  const { scale } = useContext(CanvasPositionContext);
  const { setScale } = useContext(CanvasPositionDispatchContext);

  const multiple = 0.1;
  const roundToNextMultipleOf = useCallback(
    (n: number, multipleOf: number) => Math.ceil(n / multipleOf) * multipleOf,
    []
  );

  return (
    <>
      <button
        type="button"
        className="flex items-center justify-center w-8 h-8 p-1 border-none bg-transparent group hover:bg-background cursor-pointer rounded-lg"
        onClick={() => setScale((prev) => roundToNextMultipleOf(prev + multiple, multiple))}
        aria-label="Zoom in"
      >
        +
        {/* <Plus
            size={16}
            className="cursor-pointer text-secondary group-hover:text-primary"
          /> */}
      </button>
      <div className="w-[40px] text-center">
        {/* <Text size="sm" variant="secondary" className="block">
            {Math.round(scale * 100)}%
          </Text> */}
        <p>{Math.round(scale * 100)}%</p>
      </div>
      <button
        type="button"
        className="flex items-center justify-center w-8 h-8 p-1 border-none bg-transparent group hover:bg-background cursor-pointer rounded-lg"
        onClick={() => setScale((prev) => roundToNextMultipleOf(prev - multiple, multiple))}
        aria-label="Zoom out"
      >
        -{/* <Minus size={16} className="text-secondary group-hover:text-primary" /> */}
      </button>
    </>
  );
});

ZoomControl.displayName = 'ZoomControl';

export const CenterCanvas = memo(() => {
  const { setX, setY, setScale } = useContext(CanvasPositionDispatchContext);
  const { canvasRef } = useContext(CanvasPositionContext);

  const onCenterCanvas = () => {
    setScale(0.6);
    const canvasElement = canvasRef?.current;
    const canvasHeight = canvasElement?.offsetHeight || 0;
    setY(0.3 * canvasHeight);
    setX(0);
  };

  return (
    // <Button
    //   variant="secondary"
    //   icon={<CrosshairSimple size={20} />}
    //   onClick={onCenterCanvas}
    // />
    <button type="button" onClick={onCenterCanvas}>
      Center
    </button>
  );
});

CenterCanvas.displayName = 'CenterCanvas';

export const CanvasRoot = forwardRef<HTMLElement, PropsWithChildren<{ defaultScale?: number }>>(
  ({ children, defaultScale }) => {
    // prevent navigating back on scroll
    useEffect(() => {
      document.documentElement.style.overscrollBehaviorX = 'none';
      document.body.style.overscrollBehaviorX = 'none';
    }, []);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [scale, setScale] = useState(defaultScale || 1);

    // Because we use transform-origin: center and scaled down to defaultScale, we need to offset the initial x position back to the left
    const [transformX, setTransformX] = useState(0);
    const [transformY, setTransformY] = useState(0);

    return (
      <CanvasPositionContext.Provider
        value={useMemo(
          () => ({
            x: transformX,
            y: transformY,
            scale,
          }),
          [transformX, transformY, scale]
        )}
      >
        <CanvasPositionDispatchContext.Provider
          value={useMemo(
            () => ({
              setX: setTransformX,
              setY: setTransformY,
              setScale,
            }),
            [setTransformX, setTransformY, setScale]
          )}
        >
          {children}
        </CanvasPositionDispatchContext.Provider>
      </CanvasPositionContext.Provider>
    );
  }
);

CanvasRoot.displayName = 'CanvasRoot';

export const CanvasScrollArea = memo(({ children, className }: PropsWithChildren<{ className: string }>) => {
  // const [gestureRef] = useScrollableAreaGesture();
  return (
    <main
      // ref={gestureRef}
      className={classNames(className)}
      id={CANVAS_ID}
    >
      {children}
    </main>
  );
});

CanvasScrollArea.displayName = 'CanvasScrollArea';

export const CanvasContent = memo(({ children }: PropsWithChildren<Record<string, any>>) => {
  const { x, y, scale } = useContext(CanvasPositionContext);

  return (
    <div
      id={CANVAS_CONTENT_ID}
      style={{
        WebkitTransformOrigin: 'top center',
        transformOrigin: 'top center',
        WebkitTransform: `translate(${x}px, ${y}px) scale(${scale})`,
        transform: `translate(${x}px, ${y}px) scale(${scale})`,
      }}
    >
      {children}
    </div>
  );
}, areEqual);

CanvasContent.displayName = 'CanvasContent';
