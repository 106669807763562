export interface DnsRecord {
  type: string;
  name: string;
  value: string;
}

export enum CustomDomainTypes {
  WEB = 'web',
  EMAIL = 'email',
  REDIRECT = 'redirect',
  BRANDED_LINK = 'branded_link',
}

export interface CustomDomain {
  id: string;
  domain: string;
  email_username: string;
  verified: boolean;
  unused: boolean;
  root_domain: string;
  verification_record: DnsRecord;
  web_enabled: boolean;
  web_redirect_enabled: boolean;
  email_enabled: boolean;
  web_verification_pending: boolean;
  web_redirect_verification_pending: boolean;
  web_authenticated_domains: DnsRecord[];
  email_verification_pending: boolean;
  email_authenticated_domains: DnsRecord[];
  branded_link_added: boolean;
  branded_link_verified: boolean;
  branded_link_pending: boolean;
  branded_link_cloudflare_pending: boolean;
  branded_link_cloudflare_verified: boolean;
  authenticated_branded_links: DnsRecord[];
  cloudflare_verified_authenticated_branded_link: DnsRecord;
  warming_up: boolean;
}
