import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { usePage } from '@/hooks/usePages';
import usePageVersion from '@/hooks/usePageVersion/usePageVersion';
import useUpdatePageVersion from '@/hooks/usePageVersion/useUpdatePageVersion';
import useCreateSiteVersion from '@/hooks/useSiteVersion/useCreateSiteVersion';
import { Page } from '@/interfaces/dream_builder/page';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { findPageVersionFromRoute } from '@/routes/website/_utils/findPageVersionFromRoute';
import { getAllRoutes } from '@/routes/website/_utils/getAllRoutes';

import { useWebsiteContext } from './website-context';

interface IDreamEditorContext {
  page?: Page;
  content: PageVersion['content'];
  setContent: (content: PageVersion['content']) => void;
  onSave: () => void;
  changesMade: boolean;
  setChangesMade: (changesMade: boolean) => void;
  isSaveLoading: boolean;
  onSaveToVersionHistory: (autoSave: boolean, versionName?: string) => Promise<void>;
  isSaveVersionLoading: boolean;
  previewPageVersion: PageVersion | undefined;
}

const DreamEditorContext = createContext<IDreamEditorContext | undefined>(undefined);

DreamEditorContext.displayName = 'DreamEditorContext';

export const DreamEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const { previewSiteVersion } = useWebsiteContext();
  const [content, setContent] = useState<PageVersion['content']>({});
  const [changesMade, setChangesMade] = useState(false);

  const { pageId } = useParams();
  const { data: page } = usePage({ pageId: pageId as string });

  const allRoutes =
    previewSiteVersion?.routes && previewSiteVersion?.default_routes
      ? getAllRoutes(previewSiteVersion.routes, previewSiteVersion.default_routes)
      : undefined;
  const pageVersionId = allRoutes ? findPageVersionFromRoute(allRoutes, pageId as string) : undefined;

  const { data: previewPageVersion } = usePageVersion({
    pageId: pageId as string,
    pageVersionId: pageVersionId as string,
    enabled: !!pageVersionId,
  });

  useEffect(() => {
    if (page?.draft_page_version) {
      setContent(page?.draft_page_version.content);
    }
  }, [page?.draft_page_version]);

  const {
    mutate: updatePageVersion,
    mutateAsync: updatePageVersionAsync,
    isLoading: isSaveLoading,
  } = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutateAsync: createSiteVersionAsync } = useCreateSiteVersion({});

  const onSave = useCallback(() => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    if (!changesMade) return; // don't save if no changes
    updatePageVersion({ content });
    setChangesMade(false);
  }, [updatePageVersion, content, changesMade, previewSiteVersion]);

  const onSaveToVersionHistory = useCallback(
    async (autoSave: boolean = false, versionName?: string): Promise<void> => {
      if (previewSiteVersion) return; // don't save if previewing other versions

      await updatePageVersionAsync({ content });
      setChangesMade(false);
      await createSiteVersionAsync({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updatePageVersionAsync, createSiteVersionAsync, content, previewSiteVersion]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        onSave();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSave]);

  // Auto save Version History every 10 minutes
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        onSaveToVersionHistory(true);
      }
    }, 60000); // 10 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSaveToVersionHistory]);

  // Save on CMD + S
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        onSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSave]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(onSave, !!changesMade);

  const value = useMemo(
    () => ({
      content,
      setContent,
      changesMade,
      setChangesMade,
      onSave,
      page,
      isSaveLoading,
      onSaveToVersionHistory,
      previewPageVersion,
      isSaveVersionLoading,
    }),
    [
      content,
      changesMade,
      onSave,
      page,
      isSaveLoading,
      onSaveToVersionHistory,
      isSaveVersionLoading,
      previewPageVersion,
    ]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useDreamEditorContext = () => {
  const context = useContext(DreamEditorContext);
  if (!context) {
    throw new Error('useDreamEditorContext must be used within a DreamEditorProvider');
  }
  return context;
};
