import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AuthorsView } from './views/AuthorsView';

export const Authors = Node.create<{}, {}>({
  name: 'authors',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
      },
      orientation: {
        default: 'horizontal',
        parseHTML: (element) => element.getAttribute('data-orientation'),
        renderHTML: (attributes) => ({ 'data-orientation': attributes.orientation }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      borderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      borderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      cardBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-card-border-radius'),
        renderHTML: (attributes) => ({ 'data-card-border-radius': attributes.cardBorderRadius }),
      },
      cardBorderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-card-border-width'),
        renderHTML: (attributes) => ({ 'data-card-border-width': attributes.cardBorderWidth }),
      },
      cardBorderColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-card-border-color'),
        renderHTML: (attributes) => ({ 'data-card-border-color': attributes.cardBorderColor }),
      },
      cardBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-card-border-style'),
        renderHTML: (attributes) => ({ 'data-card-border-style': attributes.cardBorderStyle }),
      },
      cardBackgroundColor: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-background-color'),
        renderHTML: (attributes) => ({ 'data-card-background-color': attributes.cardBackgroundColor }),
      },
      cardPadding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      cardHeight: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-card-height'),
        renderHTML: (attributes) => ({ 'data-card-height': attributes.cardHeight }),
      },
      cardWidth: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-card-width'),
        renderHTML: (attributes) => ({ 'data-card-width': attributes.cardWidth }),
      },
      imageBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-image-border-radius'),
        renderHTML: (attributes) => ({ 'data-image-border-radius': attributes.imageBorderRadius }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      variant: {
        default: 'vertical',
        parseHTML: (element) => element.getAttribute('data-variant'),
        renderHTML: (attributes) => ({ 'data-variant': attributes.variant }),
      },
      data: {
        default: {
          authors: [],
        },
        parseHTML: (element) => element.getAttribute('data-data'),
        renderHTML: (attributes) => ({ 'data-data': attributes.data }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes }];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AuthorsView);
  },
});
