import { useState } from 'react';

import { useSettings } from '@/context/settings-context';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { CsvColumn } from '@/interfaces/csv_column';
import { PublicationSubscriberTagReference, PublicationSubscriberTagSelectItem } from '@/interfaces/subscriber_tag';
import { SubscriberImportType } from '@/pages/Segments/types';

import { ImportState } from './types';

export const useImportSubscriberList = () => {
  const [currentImportSubscriberStep, setCurrentImportSubscriberStep] = useState<ImportState>(ImportState.INITIAL);

  const onMappingStart = () => setCurrentImportSubscriberStep(ImportState.MAPPING);
  const onImportClear = () => setCurrentImportSubscriberStep(ImportState.INITIAL);
  const onImportConfirm = () => setCurrentImportSubscriberStep(ImportState.CONFIRMING);

  const [columnMapping, setColumnMapping] = useState<{ [key: number]: any }>({}); // Column mapping form state
  const [columnMappingErrors, setColumnMappingErrors] = useState(false); // Mapping errors
  const [csvColumns, setCsvColumns] = useState<CsvColumn[]>([]); // Mapping columns returned by the Backend
  const [file, setFile] = useState<File | null>(null);
  const [isAddingToAutomations, setIsAddingToAutomations] = useState(false);
  const [selectedAutomations, setSelectedAutomations] = useState<string[]>([]);

  const [commaSeparatedEmails, setCommaSeparatedEmails] = useState<string>('');
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [skipEmailValidation, setSkipEmailValidation] = useState(false);
  const [importType, setImportType] = useState<SubscriberImportType>(SubscriberImportType.CSV);

  const currentPublicationId = useCurrentPublicationId();
  const { name: publicationName, send_welcome_email: publicationWelcomeEmail } =
    usePublication(currentPublicationId)?.data || {};

  const { settings } = useSettings();
  const taggingEnabled = settings?.subscriber_tagging;
  const [isAddingSubscriberTags, setIsAddingSubscriberTags] = useState(false);
  const [selectedSubscriberTags, setSelectedSubscriberTags] = useState<PublicationSubscriberTagReference[]>([]);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommaSeparatedEmails(e.target.value);
  };

  let modalHeaderText = '';
  if (currentImportSubscriberStep === ImportState.CONFIRMING) {
    modalHeaderText = 'Confirm Import';
  } else if (currentImportSubscriberStep === ImportState.MAPPING) {
    modalHeaderText = 'Choose Columns to Import';
  }

  const isSystemAdmin = settings?.system_admin || false;

  const makeFormData = (segmentId?: string) => {
    const formData = new FormData();

    if (file) {
      // Is there a file selected?
      formData.append('csv', file);
    } else {
      // Append pasted CSV values
      formData.append('comma_separated_values', commaSeparatedEmails);
    }

    selectedAutomations.forEach((automationId) => {
      formData.append('automation_ids[]', automationId);
    });

    if (isSystemAdmin) {
      formData.append('skip_email_validation', skipEmailValidation.toString());
    }

    if (segmentId) {
      formData.append('segment_id', segmentId);
    }
    formData.append('send_welcome_email', sendWelcomeEmail.toString());
    formData.append('publication_id', currentPublicationId);
    formData.append('column_mapping', JSON.stringify(columnMapping));
    formData.append('step', currentImportSubscriberStep.toString());

    selectedSubscriberTags.forEach((tag) => {
      formData.append('publication_subscriber_tag_ids[]', tag.id);
    });

    return formData;
  };

  const handleImportTypeChange = (_name: string, value: SubscriberImportType) => {
    if (value === SubscriberImportType.Manual) {
      setFile(null);
    } else {
      setCommaSeparatedEmails('');
    }

    setImportType(value);
  };

  const handleCancelImport = () => {
    onImportClear();
    setColumnMapping({});
    setCommaSeparatedEmails('');
    setColumnMappingErrors(false);
    setCsvColumns([]);
  };

  const handleAddToAutomations = (value: boolean) => {
    if (!value) {
      setSelectedAutomations([]);
    }

    setIsAddingToAutomations(value);
  };

  const handleAddSubscriberTags = (value: boolean) => {
    if (!value) {
      setSelectedSubscriberTags([]);
    }

    setIsAddingSubscriberTags(value);
  };

  const handleSelectAutomation = (automationId: string) =>
    setSelectedAutomations([...selectedAutomations, automationId]);
  const handleDeselectAutomation = (automationId: string) => {
    const newAutomations = [...selectedAutomations];
    const indexOfDeselectedValue = newAutomations.indexOf(automationId);

    if (indexOfDeselectedValue !== -1) {
      newAutomations.splice(indexOfDeselectedValue, 1);
      setSelectedAutomations(newAutomations);
    }
  };
  const handleDeselectAllAutomations = () => {
    setSelectedAutomations([]);
  };

  const subscriberTagSelected = (tag: PublicationSubscriberTagSelectItem) =>
    setSelectedSubscriberTags([...selectedSubscriberTags, { id: tag.value } as PublicationSubscriberTagReference]);

  const subscriberTagDeselected = (tagId: string) => {
    const tags = [...selectedSubscriberTags].filter((tag: PublicationSubscriberTagReference) => tag.id !== tagId);
    setSelectedSubscriberTags(tags);
  };

  return {
    modalHeaderText,
    canImport: file !== null || commaSeparatedEmails !== '',
    showMappingModal: currentImportSubscriberStep === ImportState.MAPPING,
    showConfirmModal: currentImportSubscriberStep === ImportState.CONFIRMING,
    showModal: currentImportSubscriberStep !== ImportState.INITIAL,
    currentImportSubscriberStep,
    setCurrentImportSubscriberStep,
    onMappingStart,
    onImportClear,
    onImportConfirm,
    file,
    setFile,
    isAddingToAutomations,
    onAddToAutomationsChange: handleAddToAutomations,
    selectedAutomations,
    onSelectAutomation: handleSelectAutomation,
    onDeselectAutomation: handleDeselectAutomation,
    onDeselectAllAutomations: handleDeselectAllAutomations,
    commaSeparatedEmails,
    setCommaSeparatedEmails,
    handleEmailInputChange,
    sendWelcomeEmail,
    setSendWelcomeEmail,
    skipEmailValidation,
    setSkipEmailValidation,
    publicationName,
    currentPublicationId,
    publicationWelcomeEmail: publicationWelcomeEmail || false,
    isSystemAdmin,
    csvColumns,
    setCsvColumns,
    columnMapping,
    setColumnMapping,
    columnMappingErrors,
    setColumnMappingErrors,
    makeFormData,
    importType,
    setImportType,
    handleImportTypeChange,
    handleCancelImport,
    handleSubscriberTagSelect: subscriberTagSelected,
    handleSubscriberTagDeselect: subscriberTagDeselected,
    onAddSubscriberTagsChange: handleAddSubscriberTags,
    isAddingSubscriberTags,
    selectedSubscriberTags,
    taggingEnabled,
  };
};
