import { useCallback, useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import { Editor } from '@tiptap/react';

import { ImageBlock } from '../extensions';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';

import 'react-resizable/css/styles.css';

export const SelectionBox = ({ editor }: { editor: Editor }) => {
  const { activeNodePos, activeNodeType } = useActiveNode(editor);
  const activeNodeRect = editor.storage.activeNode?.activeNodeRect;

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const [outerWrapperStyle, setOuterWrapperStyle] = useState({});
  const [style, setStyle] = useState({});

  const isInitialized = useRef(false);

  useEffect(() => {
    isInitialized.current = false;
  }, [activeNodePos]);

  useEffect(() => {
    if (isInitialized.current) return;

    if (!activeNodeRect) return;
    isInitialized.current = true;

    setHeight(activeNodeRect.height);
    setWidth(activeNodeRect.width);
  }, [activeNodeRect, activeNodePos]);

  useEffect(() => {
    const rect = activeNodeRect;
    const parentRect = editor.view.dom.getBoundingClientRect();

    if (rect && parentRect) {
      const calculatedWidth = rect.right - rect.left;
      const calculatedHeight = rect.bottom - rect.top;

      setOuterWrapperStyle({
        position: 'absolute',
        left: rect.left - parentRect.left,
        top: rect.top - parentRect.top,
        pointerEvents: 'none',
      });

      setStyle({
        position: 'relative',
        width: calculatedWidth,
        height: calculatedHeight,
        border: `1px solid #7C3AED`, // 66 is opacity
        boxSizing: 'border-box',
      });
    }
  }, [activeNodeRect, editor]);

  const handleResizeStop = useCallback(
    (e: any, data: any) => {
      if (!activeNodePos) return;

      // set width & height attribute
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'height', data.size.height);
        tr.setNodeAttribute(activeNodePos, 'width', data.size.width);
        return true;
      });
    },
    [editor, activeNodePos]
  );

  const handleResize = useCallback(
    (e: any, data: any) => {
      requestAnimationFrame(() => {
        if (!activeNodePos) return;

        // Round the width and height to the nearest 5 pixels
        const roundedWidth = Math.round(data.size.width / 5) * 5;
        const roundedHeight = Math.round(data.size.height / 5) * 5;

        // set width & height
        setWidth(roundedWidth);
        setHeight(roundedHeight);

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'height', roundedHeight);
          tr.setNodeAttribute(activeNodePos, 'width', roundedWidth);
          return true;
        });
      });
    },
    [activeNodePos, editor]
  );

  if (activeNodeType !== ImageBlock.name)
    return (
      <div style={outerWrapperStyle}>
        <div style={style} />
      </div>
    );

  return (
    <div style={outerWrapperStyle}>
      <div className="absolute -bottom-4 left-1/2 -translate-x-1/2 text-xs text-gray-500">
        {height}px x {width}px
      </div>
      <Resizable
        handle={(handleAxis, ref) => (
          <span
            className={`react-resizable-handle react-resizable-handle-${handleAxis} pointer-events-auto`}
            ref={ref}
          />
        )}
        height={height}
        width={width}
        onResizeStop={handleResizeStop}
        onResize={handleResize}
        resizeHandles={['se', 'sw']}
      >
        <div style={style} />
      </Resizable>
    </div>
  );
};
