import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import Banner from '@/components/Banner';
import { Input, Switch } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useCreateSplitTest, useDeleteSplitTest, useSplitTest } from '@/hooks';
import { PostSendStatus, PostSocialShare } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { usePostContext } from '../PostContext';

import SplitTestForm, { DEFAULTS } from './SplitTestForm';

const Email = (): JSX.Element => {
  const { settings } = useSettings();
  const maxVariants = (settings?.max_split_test_options || 1) - 1; // Subtract 1 for the control.
  const organizationSplitTestingEnabled = maxVariants >= DEFAULTS.MIN_VARIANTS && settings?.split_test_customization;
  const {
    formData,
    onChange,
    emailSubjectEdited,
    setEmailSubjectEdited,
    emailPreviewTextEdited,
    setEmailPreviewTextEdited,
  } = usePostContext();
  const splitTestObject = useSplitTest(formData?.id || '').data;
  const [audienceSizeWarningDisplayed, setAudienceSizeWarningDisplayed] = useState(false);
  const [splitTesting, setSplitTesting] = useState(false);
  const [splitTestOptions, setSplitTestOptions] = useState(splitTestObject?.split_test_options || []);
  const [emailHeaderSocials, setEmailHeaderSocials] = useState(false);
  const [emailHeaderEngagement, setEmailHeaderEngagement] = useState(false);
  const [showCustomLiveUrl, setShowCustomLiveUrl] = useState(!!formData?.custom_live_url);
  const postSent = formData?.send_status === PostSendStatus.SENT || formData?.send_status === PostSendStatus.SENDING;

  useEffect(() => {
    if (formData?.custom_live_url) {
      setShowCustomLiveUrl(true);
    }
  }, [formData?.custom_live_url]);

  useEffect(() => {
    if (formData?.social_share === PostSocialShare.TOP) {
      setEmailHeaderEngagement(false);
      setEmailHeaderSocials(true);
    }

    if (formData?.social_share === PostSocialShare.WITH_COMMENT_AND_LIKES) {
      setEmailHeaderEngagement(true);
      setEmailHeaderSocials(true);
    }

    if (formData?.social_share === PostSocialShare.COMMENTS_AND_LIKES_ONLY) {
      setEmailHeaderEngagement(true);
      setEmailHeaderSocials(false);
    }

    if (formData?.social_share === PostSocialShare.NONE) {
      setEmailHeaderEngagement(false);
      setEmailHeaderSocials(false);
    }
  }, [formData?.social_share]);

  useEffect(() => {
    if (formData?.split_test && organizationSplitTestingEnabled) {
      setSplitTesting(true);
    }
  }, [formData?.split_test, organizationSplitTestingEnabled]);

  useEffect(() => {
    const calcOptions = () => {
      const optionCount = splitTestOptions.length;

      return optionCount < DEFAULTS.MIN_VARIANTS ? DEFAULTS.MIN_VARIANTS : optionCount;
    };

    if (
      splitTestObject &&
      (splitTestObject?.population_size || 0) / calcOptions() < DEFAULTS.MIN_SAMPLE_SIZE_PER_VARIANT
    ) {
      setAudienceSizeWarningDisplayed(true);
    }
  }, [splitTestOptions, splitTestObject]);

  useEffect(() => {
    if (!emailSubjectEdited && (!formData?.email_subject_line || formData?.email_subject_line?.length === 0)) {
      onChange({ email_subject_line: formData?.web_title });
    }

    if (
      !emailPreviewTextEdited &&
      (!formData?.email_preview_text || formData?.email_preview_text?.length === 0) &&
      formData?.web_subtitle !== ''
    ) {
      onChange({ email_preview_text: formData?.web_subtitle });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleDeleteSuccess = () => {
    console.log('Split test deleted');
  };

  const createSplitTestMutation = useCreateSplitTest(
    formData?.id || '',
    () => {},
    () => {}
  );

  const deleteSplitTestMutation = useDeleteSplitTest(formData?.id || '', handleDeleteSuccess, () => {});

  const handleSplitTestToggle = (checked: boolean) => {
    setSplitTesting(checked);

    if (checked) {
      createSplitTestMutation.mutate();
    } else {
      deleteSplitTestMutation.mutate();
    }
  };

  const handleSetParentSplitTestOptions = (options: any) => {
    setSplitTestOptions(options);
  };

  const handleSocialShareChange = (name: string, checked: boolean) => {
    if (name === 'email-header-socials') {
      if (checked && emailHeaderEngagement) {
        onChange({ social_share: PostSocialShare.WITH_COMMENT_AND_LIKES });
      }
      if (checked && !emailHeaderEngagement) {
        onChange({ social_share: PostSocialShare.TOP });
      }
      if (!checked && !emailHeaderEngagement) {
        onChange({ social_share: PostSocialShare.NONE });
      }
      if (!checked && emailHeaderEngagement) {
        onChange({ social_share: PostSocialShare.COMMENTS_AND_LIKES_ONLY });
      }
    }

    if (name === 'email-header-engagement') {
      if (checked && emailHeaderSocials) {
        onChange({ social_share: PostSocialShare.WITH_COMMENT_AND_LIKES });
      }
      if (checked && !emailHeaderSocials) {
        onChange({ social_share: PostSocialShare.COMMENTS_AND_LIKES_ONLY });
      }
      if (!checked && !emailHeaderSocials) {
        onChange({ social_share: PostSocialShare.NONE });
      }
      if (!checked && emailHeaderSocials) {
        onChange({ social_share: PostSocialShare.TOP });
      }
    }
  };

  const handleCustomLiveUrlToggle = (name: string, checked: boolean) => {
    setShowCustomLiveUrl(checked);
    if (!checked) {
      onChange({ custom_live_url: '' });
    }
  };

  const syncSubject = () => {
    onChange({ email_subject_line: formData?.web_title });
    setEmailSubjectEdited(false);
  };

  const syncPreviewText = () => {
    onChange({ email_preview_text: formData?.web_subtitle });
    setEmailPreviewTextEdited(false);
  };

  const emailSubjectSynced = formData?.email_subject_line === formData?.web_title;
  const emailPreviewTextSynced = formData?.email_preview_text === formData?.web_subtitle;

  if (!formData) {
    return <div />;
  }

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6 overflow-auto">
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 rounded-lg max-w-[40rem]">
        <Typography token="font-medium/text/base">Sending details</Typography>
        {splitTesting && audienceSizeWarningDisplayed && (
          <Banner
            title="Selected audience may be too small for the number of options"
            variant="information"
            isScreenWide={false}
          />
        )}
        <div className="flex flex-col gap-1">
          <div className="flex flex-row justify-between">
            <div className="flex">
              <Typography token="font-medium/text/sm" className="py-2">
                Subject line*
              </Typography>
              <Button
                disabled={emailSubjectSynced}
                onClick={syncSubject}
                type="button"
                variant="flush"
                className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                  'text-surface-300': emailSubjectSynced,
                  'text-surface-500': !emailSubjectSynced,
                })}
                data-tip
                data-for="resync-email-subject-tooltip"
              >
                <ArrowPathIcon
                  className={cx('w-4 h-4 absolute', {
                    'text-surface-300': emailSubjectSynced,
                    'text-surface-500': !emailSubjectSynced,
                  })}
                />
              </Button>
              <ReactTooltip id="resync-email-subject-tooltip" effect="solid" place="top">
                {emailSubjectSynced ? 'Synced' : 'Resync'} with post title
              </ReactTooltip>
            </div>
            {organizationSplitTestingEnabled && (
              <div className="flex gap-2">
                <Typography className="py-2" token="font-medium/text/sm">
                  A/B test
                </Typography>
                <Switch
                  checked={splitTesting}
                  onChange={(name, e) => handleSplitTestToggle(e)}
                  variant="primary"
                  name="ab-test"
                  showIcons={false}
                  disabled={postSent}
                />
              </div>
            )}
          </div>
          <SplitTestForm
            post={formData}
            splitTesting={splitTesting}
            splitTestObject={splitTestObject}
            setParentSplitTestOptions={handleSetParentSplitTestOptions}
            setSplitTesting={setSplitTesting}
          >
            <div className="flex flex-col gap-2">
              <div className="flex flex-row space-x-2">
                <div className="m-auto h-3 w-3 p-[0.5835rem] flex flex-col justify-center items-center text-gray-500 border border-surface-200 rounded bg-surface-100">
                  <Typography token="font-normal/text/xs" className="leading-4" colorWeight="900" color="primary">
                    A
                  </Typography>
                  <div />
                </div>
                <Input
                  name="subject_line"
                  value={formData.email_subject_line || ''}
                  onChange={(e) => onChange({ email_subject_line: e.target.value })}
                  onKeyUp={() => {
                    setEmailSubjectEdited(true);
                  }}
                  required
                  className="w-full"
                  disabled={formData.locked_fields.email_subject_line || postSent}
                />
              </div>
              <Typography token="font-light/text/xs" className="ml-8">
                {formData.locked_fields.email_subject_line
                  ? 'You can no longer change the subject line'
                  : 'This will be same as post title unless modified'}
              </Typography>
            </div>
          </SplitTestForm>
          {!splitTesting && (
            <div>
              <Input
                name="email-subject-line"
                value={formData.email_subject_line || ''}
                onKeyUp={() => {
                  setEmailSubjectEdited(true);
                }}
                onChange={(e) => onChange({ email_subject_line: e.target.value })}
                disabled={formData.locked_fields.email_subject_line || postSent}
              />
              <Typography token="font-light/text/xs">This will be same as post title unless modified</Typography>
            </div>
          )}
        </div>
        <div>
          <div className="flex">
            <Typography token="font-medium/text/sm" className="py-2">
              Preview text
            </Typography>
            <Button
              disabled={emailPreviewTextSynced}
              onClick={syncPreviewText}
              type="button"
              variant="flush"
              className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                'text-surface-300': emailPreviewTextSynced,
                'text-surface-500': !emailPreviewTextSynced,
              })}
              data-tip
              data-for="resync-email-preview-text-tooltip"
            >
              <ArrowPathIcon
                className={cx('w-4 h-4 absolute', {
                  'text-surface-300': emailPreviewTextSynced,
                  'text-surface-500': !emailPreviewTextSynced,
                })}
              />
            </Button>
            <ReactTooltip id="resync-email-preview-text-tooltip" effect="solid" place="top">
              {emailPreviewTextSynced ? 'Synced' : 'Resync'} with post subtitle
            </ReactTooltip>
          </div>
          <Input
            name="preview-text"
            placeholder="Enter preview text"
            value={formData.email_preview_text || ''}
            className="py-2"
            onChange={(e) => onChange({ email_preview_text: e.target.value })}
            onKeyUp={() => {
              setEmailPreviewTextEdited(true);
            }}
            disabled={formData.locked_fields.email_preview_text || postSent}
          />

          <Typography token="font-light/text/xs">This will be same as post subtitle unless modified</Typography>
        </div>
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 rounded-lg max-w-[40rem]">
        <TypographyStack>
          <Typography token="font-medium/text/base">Email header buttons</Typography>
          <Typography token="font-normal/text/sm">
            These buttons in the email let readers share, like, and comment on this post
          </Typography>
        </TypographyStack>
        <Switch
          name="email-header-socials"
          fullWidth
          checked={emailHeaderSocials}
          onChange={(name, e) => handleSocialShareChange(name, e)}
          prefixLabelText="Socials"
          variant="primary"
          showIcons={false}
          disabled={postSent}
        />
        <Switch
          name="email-header-engagement"
          fullWidth
          checked={emailHeaderEngagement}
          onChange={(name, e) => handleSocialShareChange(name, e)}
          prefixLabelText="Likes & Comments"
          variant="primary"
          disabled={postSent}
          showIcons={false}
        />
      </Card>
      <div className="w-full md:w-2/3 mx-auto max-w-[40rem]">
        <AccordionCard
          title="Advanced settings"
          marginTop="mt-6"
          padding="px-4 pb-4 pt-0"
          titleSize="base"
          titleWeight="medium"
          subText=""
          topBorder={false}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-row gap-3 items-center">
                <Typography token="font-medium/text/sm">Custom Read Online URL</Typography>
              </div>
              <Switch
                name="show-custom-live-url"
                checked={showCustomLiveUrl}
                onChange={handleCustomLiveUrlToggle}
                variant="primary"
                showIcons={false}
                disabled={postSent}
              />
            </div>
            <Typography token="font-normal/text/sm" className="max-w-md">
              By default, the &apos;Read Online&apos; link will lead to the web version of your post. You can choose to
              override this and redirect to a different site instead.
            </Typography>
            {showCustomLiveUrl && (
              <Input
                name="read-online-url"
                value={formData.custom_live_url}
                placeholder="Enter URL"
                onChange={(e) => onChange({ custom_live_url: e.target.value })}
                disabled={postSent}
              />
            )}
          </div>
        </AccordionCard>
      </div>
    </div>
  );
};

export default Email;
