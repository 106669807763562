import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  ArrowLeft,
  ArrowRight,
  ArrowsClockwise,
  ArrowsOutLineHorizontal,
  ArrowsOutLineVertical,
  MagnifyingGlass,
  X,
} from '@phosphor-icons/react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useInfinitePosts from '@/hooks/useDreamBuilder/useInfinitePosts';
import { useInfiniteScrollSiteTemplates } from '@/hooks/useSiteTemplates';
//  { usePosts } from '@/hooks';
import { PostPreview } from '@/interfaces/post';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Input } from '../../../../UI/Input';
import InputWrapper from '../../../../UI/InputWrapper';
import { Label } from '../../../../UI/Label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../../UI/Select';
import { Text } from '../../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../../UI/ToggleGroup';
import { SECTION_CATEGORIES } from '../../../Templates/constants';

const PostsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  setIsModalOpen,
}: Partial<NodeViewProps> & {
  isOpen: boolean;
  onClose: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const [search, setSearch] = useState('');
  const [selectedPosts, setSelectedPosts] = useState<PostPreview[] | any[]>([]);
  const [numberOfPosts, setNumberOfPosts] = useState(3);
  const [orientation, setOrientation] = useState<'vertical' | 'horizontal'>('vertical');
  const [selectedLayout, setSelectedLayout] = useState<any>(null);
  const [step, setStep] = useState(1);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);

  const { data: siteTemplatesData } = useInfiniteScrollSiteTemplates({ enabled: isOpen, category: 'posts' });
  const siteTemplates = siteTemplatesData?.pages.flatMap((page) => page.site_templates) || [];
  const hasSiteTemplates = siteTemplates?.length > 0;

  const step1Title = 'Select posts display';
  const step2Title = 'Select posts';
  const layoutTitle = 'Select a layout';
  const stepTitle = step === 1 ? step1Title : step2Title;
  const title = isLayoutOpen ? layoutTitle : stepTitle;

  useEffect(() => {
    if (hasSiteTemplates) {
      setSelectedLayout(siteTemplates[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSiteTemplates]);

  const { data } = useInfinitePosts({
    enabled: isOpen,
  });
  const posts = data?.pages.flatMap((page) => page.posts) || [];

  const selectedPostsIds = useMemo(() => selectedPosts.map((post) => post.id), [selectedPosts]);
  const isSubmitDisabled = selectedPostsIds.length !== numberOfPosts;
  const postsToSelect = numberOfPosts - selectedPostsIds.length;

  const handleSubmit = () => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      orientation,
      cardStructure: selectedLayout?.content?.attrs?.cardStructure,
      data: {
        posts: selectedPosts,
      },
    };

    editor?.chain().focus().updateAttributes('post', updatedAttributes).run();

    toast.success('Posts selected');
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {isLayoutOpen && (
          <div className="flex flex-col w-full gap-4">
            <div className="grid grid-cols-2 gap-4">
              {siteTemplates?.map((template) => {
                const isSelected = selectedLayout?.id === template.id;

                return (
                  <Label
                    htmlFor={template.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md h-72 focus:outline-none relative justify-center items-center',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="scale-50 w-full max-w-[300px]">
                      <PostElement
                        element={{
                          type: 'post',
                          attrs: {
                            ...(node?.attrs as TPostAttributes),
                            cardStructure: template?.content?.attrs?.cardStructure,
                            data: {
                              posts: template.content?.attrs?.data?.posts.slice(0, 1),
                            },
                          },
                        }}
                      >
                        {null}
                      </PostElement>
                    </div>
                    <Checkbox
                      id={template.id}
                      className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                      checked={Boolean(isSelected)}
                      onCheckedChange={() => setSelectedLayout(template)}
                    />

                    <Text
                      weight="semibold"
                      variant="primary"
                      size="sm"
                      as="span"
                      className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
                    >
                      {template.name}
                    </Text>
                  </Label>
                );
              })}
            </div>
          </div>
        )}

        {/** PREFERENCES SECTION */}
        {step === 1 && !isLayoutOpen && (
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <InputWrapper name="layout" labelText="Layout" className="w-full">
                <div>
                  <Button
                    variant="outlined"
                    LeftIcon={ArrowsClockwise}
                    RightIcon={ArrowRight}
                    onClick={() => setIsLayoutOpen(true)}
                  >
                    Change layout
                  </Button>
                </div>
              </InputWrapper>

              <InputWrapper name="orientation" labelText="Orientation" className="w-[150px]">
                <div className="grow bg-wb-secondary rounded-lg shadow-sm">
                  <ToggleGroup
                    className="p-[2px]"
                    type="single"
                    defaultValue="vertical"
                    value={orientation}
                    onValueChange={(value) => setOrientation(value as 'vertical' | 'horizontal')}
                  >
                    <ToggleGroupItem value="vertical" className="grow">
                      <ArrowsOutLineVertical weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
                    </ToggleGroupItem>
                    <ToggleGroupItem value="horizontal" className="grow">
                      <ArrowsOutLineHorizontal weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
              </InputWrapper>

              <InputWrapper name="category" labelText="Category" className="w-full">
                <Select defaultValue="">
                  <SelectTrigger className="w-full" id="by_status">
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {SECTION_CATEGORIES.map((sectionCategory) => (
                        <SelectItem key={sectionCategory.value} value={sectionCategory.value}>
                          {sectionCategory.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </InputWrapper>

              <Input
                name="name"
                labelText="Number of posts to display"
                type="number"
                min={1}
                max={12}
                placeholder="New Template"
                value={numberOfPosts}
                onChange={(e) => setNumberOfPosts(Number(e.target.value))}
              />
            </div>

            <div className="flex flex-col gap-2 justify-start items-start h-56 border">
              <Text size="sm" weight="semibold" variant="secondary" as="p">
                preview
              </Text>

              <div className="w-full h-full flex flex-start p-4 justify-start scale-50">
                <PostElement
                  element={{
                    type: 'post',
                    attrs: {
                      ...(node?.attrs as TPostAttributes),
                      orientation,
                      ...(selectedLayout?.content?.attrs?.cardStructure && {
                        cardStructure: selectedLayout?.content?.attrs?.cardStructure,
                      }),
                      data: {
                        posts: node?.attrs?.data?.posts.slice(0, 3),
                      },
                    },
                  }}
                >
                  {null}
                </PostElement>
              </div>
            </div>
          </div>
        )}

        {step === 2 && !isLayoutOpen && (
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-2">
              {selectedPosts.map((post) => (
                <button
                  type="button"
                  key={post.id}
                  className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                  onClick={() => setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id))}
                >
                  <Text
                    weight="regular"
                    variant="accent"
                    size="3xs"
                    as="span"
                    className="line-clamp-1 max-w-[150px] truncate"
                  >
                    {post.web_title}
                  </Text>
                  <X className="w-3 h-3" />
                </button>
              ))}
            </div>

            <Input
              name="name"
              className="w-full"
              LeftIcon={MagnifyingGlass}
              type="text"
              placeholder="Search posts"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}

        {/** SEARCH SECTION */}
        {step === 2 && !isLayoutOpen && (
          <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
            <div className="flex flex-col gap-4">
              <Text size="sm" weight="semibold" variant="secondary" as="p">
                Results
              </Text>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
                {posts.map((post) => {
                  const isSelected = selectedPostsIds.includes(post.id);
                  const isDisabled = !isSelected && selectedPostsIds.length >= numberOfPosts;

                  return (
                    <Label
                      key={post.id}
                      htmlFor={post.id}
                      className={cn(
                        'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                        isSelected ? 'border-wb-accent border-2' : 'border-wb-primary',
                        isDisabled ? 'opacity-50 pointer-events-none' : 'opacity-100'
                      )}
                    >
                      <div className="w-full h-36 overflow-hidden">
                        <img
                          src={post.image_url}
                          alt={post.web_title}
                          className="w-full h-full object-cover aspect-video"
                        />
                      </div>
                      <div className="flex justify-between items-center gap-2 p-3">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {post.web_title}
                          </Text>
                          <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
                            {post.web_subtitle}
                          </Text>
                        </div>
                        <Checkbox
                          id={post.id}
                          className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                          checked={Boolean(isSelected)}
                          onCheckedChange={() => {
                            if (isSelected) {
                              setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id));
                            } else {
                              setSelectedPosts([...selectedPosts, post]);
                            }
                          }}
                        />
                      </div>
                    </Label>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {!isLayoutOpen && (
          <div>
            {step === 1 ? (
              <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
                <div className="flex gap-2 w-full justify-end">
                  <Button variant="outlined" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => setStep(2)}>
                    Next
                  </Button>
                </div>
              </DialogFooter>
            ) : (
              <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
                <div className="flex w-full items-center">
                  {postsToSelect > 0 && (
                    <Text size="sm" weight="semibold" variant="secondary" as="p">
                      Select {postsToSelect} more post(s)
                    </Text>
                  )}
                </div>

                <div className="flex gap-2 w-full justify-end">
                  <Button variant="outlined" onClick={() => setStep(1)}>
                    Previous
                  </Button>
                  <Button variant="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
                    Select posts
                  </Button>
                </div>
              </DialogFooter>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PostsSelectionModal;
