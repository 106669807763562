import toast from 'react-hot-toast';

import { Input, Switch } from '@/components/Form';
import { Publication } from '@/interfaces/publication';
import { isValidUtmParam } from '@/pages/Settings/Pages/Publication/General/isValidUtmParam';
import { utmParamValidationFailMessage } from '@/pages/Settings/Pages/Publication/General/utmParamValidationFailMessage';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import FormRow from '../components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const UTMAnalyticsForm = ({ publication }: Props) => {
  const { handleChangeInAttribute, savingAttribute } = usePublicationSettings();

  return (
    <BodyContainer>
      <CardHeader title="UTM Analytics" description="Manage the UTM parameters added to links in your posts" />
      <Input
        labelText="UTM Source"
        helperText="Default source is your publication domain"
        placeholder="creator-spotlight.beehiiv.com"
        name="utm_source"
        defaultValue={publication.utm_source}
        onBlur={(e) => {
          if (!isValidUtmParam(e.target.value)) {
            toast.error(utmParamValidationFailMessage('Source') || 'Something went wrong');
            return;
          }
          handleChangeInAttribute('utm_source')(e.target.value);
        }}
        disabled={savingAttribute === 'utm_source'}
      />
      <Input
        labelText="UTM Medium"
        helperText="Default mediums are “newsletter” for emails and “referral” for web posts"
        placeholder="Enter information here"
        name="utm_medium"
        defaultValue={publication.utm_medium}
        onBlur={(e) => {
          if (!isValidUtmParam(e.target.value)) {
            toast.error(utmParamValidationFailMessage('Medium') || 'Something went wrong');
            return;
          }

          handleChangeInAttribute('utm_medium')(e.target.value);
        }}
        disabled={savingAttribute === 'utm_medium'}
      />
      <Input
        labelText="UTM Campaign"
        helperText="Default campaign is a URL friendly version of your post name"
        placeholder="my-awesome-post"
        name="utm_campaign"
        defaultValue={publication.utm_campaign}
        onBlur={(e) => {
          if (!isValidUtmParam(e.target.value)) {
            toast.error(utmParamValidationFailMessage('Campaign') || 'Something went wrong');
            return;
          }

          handleChangeInAttribute('utm_campaign')(e.target.value);
        }}
        disabled={savingAttribute === 'utm_campaign'}
      />
      <FormRow
        label="Automatic UTM Tagging"
        helperText="Enable to automatically add UTM parameters to links in your emails that don't already have them."
      >
        <Switch
          variant="primary"
          checked={publication.utm_params_enabled}
          name="utm_params_enabled"
          onChange={(_name: string, value: boolean) => {
            handleChangeInAttribute(_name)(value);
          }}
          disabled={savingAttribute === 'utm_params_enabled'}
        />
      </FormRow>
    </BodyContainer>
  );
};

export default UTMAnalyticsForm;
