import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  publicationId: string;
  organizationId: string;
  onSuccess?: () => void;
}

const useCreatePlanPriceUpgrade = ({ organizationId, publicationId, onSuccess }: Props) => {
  return useMutation(() => api.post(`/organizations/${organizationId}/plan_price_upgrade`, {
        publication_id: publicationId,
      }).then((res) => res.data), {
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
    },
  });
};

export default useCreatePlanPriceUpgrade;
