import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import {
  BanknotesIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckIcon,
  ClockIcon,
  DocumentChartBarIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { BellIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import moment from 'moment-mini';

import { Switch } from '@/components/Form';
import { useCurrentPublication } from '@/hooks';
import useNotificationReadStatusUpdate from '@/hooks/useNotificationReadStatusUpdate';
import useNotifications from '@/hooks/useNotifications';
import useNotificationsMarkAllAsRead from '@/hooks/useNotificationsMarkAllAsRead';
import useGenerateOrchidAdminToken, { Params } from '@/hooks/useUsers/useGenerateOrchidAdminToken';
import {
  isAdNetworkOpportunityAcceptedNotification,
  isAdNetworkOpportunityCanceledNotification,
  isAdNetworkOpportunityExpiredNotification,
  isAdNetworkOpportunityPendingNotification,
  isAdNetworkOpportunityRejectedNotification,
  isAdNetworkReportCreatedNotification,
  Notification,
  Notifications,
} from '@/interfaces/notification';
import { Button } from '@/ui/Button';

interface Props {
  notifications: Notifications;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  tab: string;
  onTabChange: (newTab: string) => void;
}

interface NotificationItemsProps {
  notifications: Notifications;
}

const NotificationIcon = ({ notification }: { notification: Notification }) => {
  if (isAdNetworkOpportunityPendingNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-primary-50 rounded-full relative">
        <BanknotesIcon className="w-5 h-5 text-primary-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityAcceptedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-green-50 rounded-full relative">
        <CheckIcon className="w-5 h-5 text-green-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityRejectedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-50 rounded-full relative">
        <XMarkIcon className="w-5 h-5 text-gray-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityExpiredNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <ClockIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityCanceledNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <InformationCircleIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkReportCreatedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-feedback-info-50 rounded-full relative">
        <DocumentChartBarIcon className="w-5 h-5 text-feedback-info-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  return (
    <div className="w-8 h-8 bg-gray-100 rounded-full relative">
      <ChatBubbleBottomCenterTextIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
  );
};

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const { mutateAsync: markAsRead } = useNotificationReadStatusUpdate({ notification });
  const navigate = useNavigate();

  const { data: currentPublication } = useCurrentPublication();
  const { handleRedirectToOrchid } = useGenerateOrchidAdminToken();

  const handleClick = async () => {
    markAsRead({ read: true });
    if (notification.url.includes('https') || notification.url.includes('http')) {
      const pubHostname = currentPublication?.hostname;
      if (pubHostname && notification.url.includes(pubHostname)) {
        try {
          const url = new URL(notification.url);
          const path = url.pathname;
          const params = url.searchParams;
          const paramsObject: Params = {};
          params.forEach((value: string, key: string) => {
            paramsObject[key] = value;
          });
          handleRedirectToOrchid(path, paramsObject);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      } else {
        window.location.href = notification.url;
      }
    } else {
      navigate(notification.url);
    }
  };

  return (
    <li className="hover:bg-gray-50 first:rounded-t-md last:rounded-b-md relative">
      <Menu.Item>
        <button type="button" onClick={handleClick} className="flex flex-row space-x-3 py-3 px-3 w-full">
          <div className="w-8 h-8">
            <NotificationIcon notification={notification} />
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <p className="text-left pt-[1px]">
              <ReactMarkdown className="text-gray-600 text-base">{notification.message}</ReactMarkdown>
            </p>
            <div className="flex flex-row space-x-2">
              {!notification.read_at && notification.created_at && (
                <div className="my-auto w-2 h-2 bg-[#be185d] rounded-full" />
              )}
              <p className="text-xs my-auto font-regular text-gray-600">{moment(notification.created_at).fromNow()}</p>
            </div>
          </div>
        </button>
      </Menu.Item>
    </li>
  );
};

const NotificationItems = ({ notifications }: NotificationItemsProps) => {
  return (
    <ul className="mt-1 flex flex-col w-full">
      {notifications.map((notification: any, index: number) => (
        <NotificationItem key={notification?.id || index} notification={notification} />
      ))}
    </ul>
  );
};

const NotificationsModal = ({ notifications, hasNextPage, fetchNextPage, tab, onTabChange }: Props) => {
  const { mutateAsync: markAllAsRead } = useNotificationsMarkAllAsRead();

  const noNotifications = notifications.length === 0;

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex flex-col px-2.5 py-2">
        <div className="w-full flex flex-row justify-between items-between">
          <p className="my-auto font-medium text-gray-900 text-base infline-flex items-center">Notifications</p>
          <div className="flex my-auto flex-row space-x-3">
            <div className="my-auto mt-0.5">
              <Menu.Item>
                <Link className="my-auto" to="/settings/notifications">
                  <Cog6ToothIcon className="w-5 h-5 text-surface-400 my-auto hover:text-surface-600 duration-200" />
                </Link>
              </Menu.Item>
            </div>
            <div className="my-auto flex flex-row space-x-2">
              <p className="text-sm font-medium text-gray-900 my-auto">Unread</p>
              <Switch
                className="my-auto"
                onChange={() => onTabChange(tab === 'Unread' ? 'Read' : 'Unread')}
                checked={tab === 'Unread'}
                name="Unread"
                variant="notification"
                showIcons={false}
              />
            </div>
          </div>
        </div>
        <hr className="mt-3 mb-4 w-full" />
        <button
          disabled={tab === 'Read' || noNotifications}
          onClick={() => markAllAsRead()}
          type="button"
          className={`border w-full rounded text-sm font-medium hover:bg-gray-50 py-1.5 ${
            (tab === 'Read' || noNotifications) && 'opacity-40'
          }`}
        >
          Mark all as read
        </button>
      </div>
      {noNotifications ? (
        <div className="mt-3 h-full flex flex-col justify-center items-center rounded-md text-center space-y-2.5 p-12">
          <BellIcon className="w-6 h-6 mx-auto text-gray-400" />
          <p className="text-center mt-1.5 text-sm text-gray-500">No activity yet, check back later.</p>
          <Menu.Item>
            <Button to="/settings/notifications" size="xs" variant="primary-inverse">
              Configure notifications
            </Button>
          </Menu.Item>
        </div>
      ) : (
        <NotificationItems notifications={notifications} />
      )}
      {hasNextPage && (
        <div className="flex text-center py-2">
          <button type="button" onClick={fetchNextPage} className="w-full text-sm text-gray-500">
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default function Loader() {
  const [tab, setTab] = useState<string>('Unread');
  const { data, isSuccess, hasNextPage, fetchNextPage } = useNotifications(tab === 'Unread' ? { unread: true } : {});

  if (!isSuccess) return null;

  const notifications = data.pages.flatMap((page) => page.notifications);

  return (
    <NotificationsModal
      tab={tab}
      onTabChange={setTab}
      notifications={notifications}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
}
