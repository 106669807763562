import { useMemo } from 'react';

import { ColorPicker } from '@/routes/website/_components/UI/ColorPicker';
import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

type Props = {
  title: string;
  property: 'text_color' | 'background';
};

const DEFAULT_COLOR = '#000000FF';
const DEFAULT_BACKGROUND = '#FFFFFFFF';
const DEFAULT_BUTTON_BACKGROUND = '#8A24FFFF';
const DEFAULT_BUTTON_TEXT_COLOR = '#FFFFFFFF';

export const ColorSettings = ({
  title,
  property,
}: Props) => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();


  const defaultColor = useMemo(() => {
    if (!selectedContent) return DEFAULT_COLOR;
    if (property === 'background' && selectedContent.type === 'navbar_item' && selectedContent.attrs?.type === 'button') {
      return DEFAULT_BUTTON_BACKGROUND;
    } if (property === 'text_color' && selectedContent.type === 'navbar_item' && selectedContent.attrs?.type === 'button') {
      return DEFAULT_BUTTON_TEXT_COLOR;
    }
    if (property === 'background') return DEFAULT_BACKGROUND;
    return DEFAULT_COLOR;
  }, [property, selectedContent]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  const contentValue = selectedContent?.attrs && property in selectedContent.attrs ? (selectedContent.attrs as any)[property] : defaultColor;

  const onSetColor = (value: string | null) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: value });
  }

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start">
            <div className="w-4 h-4 rounded-md" style={{ backgroundColor: contentValue }} />
            <Text size="2xs" weight="medium" className="">
              {contentValue?.toUpperCase()}
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <ColorPicker
            selectedColor={contentValue}
            onSetColor={(value: string | null) => {
              onSetColor(value);
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
