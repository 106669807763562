import SectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { LabelSettings } from "./LabelSettings";
import { TypeSettings } from "./TypeSettings";

export function NavItemContentSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;

  return <SectionRenderer title="Navigation Item" >
    <LabelSettings />
    <TypeSettings />
  </SectionRenderer>;
}
