import cx from 'classnames';

import { Option } from '@/interfaces/general';

import Tooltip from '../Tooltip';

import Input from './Input';

interface Props {
  containerClassName?: string;
  className?: string;
  required?: boolean;
  label?: string;
  name: string;
  helperText?: string;
  inputType?: React.HTMLProps<HTMLInputElement>['type'];
  inputValue: string;
  selectedOption: string;
  selectOptions: Option[];
  onChangeInput: (newValue: string) => void;
  onChangeSelect: (newValue: string) => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

const InputWithSelect = ({
  containerClassName,
  className,
  required = false,
  label,
  name,
  helperText,
  inputType = 'text',
  inputValue,
  selectedOption,
  selectOptions,
  onChangeInput,
  onChangeSelect,
  disabled = false,
  disabledTooltip,
}: Props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeInput(e.target.value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeSelect(e.target.value);
  };

  return (
    <Tooltip
      tooltipClass='text-center'
      id={`input-with-select-${name}-tooltip`}
      text={disabled && disabledTooltip ? disabledTooltip : ''}
      showIcon={false}
      autoWidth
      isEnabled={disabled}
    >
      <div className={containerClassName}>
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-gray-700">
            {label}
            {required ? ' *' : ''}
          </label>
        )}

        <div className={cx(className, 'flex justify-between items-center border rounded-md px-1', label && 'mt-1')}>
          <Input
            required={required}
            type={inputType}
            name={`${name}_input`}
            value={inputValue}
            onChange={handleInputChange}
            inputClassOverride="appearance-none block w-full px-2 py-1 border-none focus:ring-0 sm:text-sm h-full"
            disabled={disabled}
          />

          <select
            className="pl-0 pr-8 py-2 border-none focus:ring-0 focus:underline sm:text-sm text-gray-400 lowercase"
            name={`${name}_select`}
            value={selectedOption}
            onChange={handleSelectChange}
            disabled={disabled}
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value} className="mr-0 text-right">
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
      </div>
    </Tooltip>
  );
};

export default InputWithSelect;
