import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BackgroundSettings } from '../BackgroundSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SizingSettings } from '../SizingSettings';
import { VerticalAlignmentSettings } from '../VerticalAlignmentSettings';

export const ContainerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['container']}>
      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <SizingSettings editor={editor} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} title="Text Align" />
        <VerticalAlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
    </RenderCondition>
  );
};
