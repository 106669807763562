import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';

const useUpdateOrganization = (organizationId: string | undefined, publicationId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (organization: any) =>
      api.patch(`/organizations/${organizationId}`, {
        publication_id: publicationId,
        organization,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Workspace successfully updated!');
        queryClient.invalidateQueries(['organizations', organizationId]);

        onSuccess?.();
      },
    }
  );
};

export default useUpdateOrganization;
