import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { JSONContent } from '@tiptap/core';

import { CommentEditor } from '@/components/CommentEditor';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { useCurrentUser } from '@/context/current-user-context';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { ThreadCard } from '../components';
import { useFocusedThreads } from '../hooks';

enum FilterByType {
  All = 'all',
  Open = 'open',
  Resolved = 'resolved',
}

const FilterByTypeOptions = [FilterByType.All, FilterByType.Open, FilterByType.Resolved];

enum FilterByPerson {
  Anyone = 'anyone',
  Yours = 'yours',
}

const FilterByPersonOptions = [FilterByPerson.Anyone, FilterByPerson.Yours];

export const ThreadsSidebar = () => {
  const { currentUser } = useCurrentUser();

  const userId = useMemo(() => currentUser?.id, [currentUser]);

  const { editor, threads, selectThread, highlightThread, removeHighlightThread } = useEditorContext();

  const [isComposing, setComposing] = useState(false);

  const { activeThreadId, activeThreadIdObj } = useFocusedThreads(editor);

  const threadIdTextContentMap = editor?.storage.commentsKit.threadIdTextContentMap || {};

  const createThread = useCallback(
    (content: JSONContent) => {
      if (!editor || !userId) return null;

      const chain = editor.chain();

      return chain
        .createUnreferencedThread({
          content,
          data: {
            authorId: userId,
          },
          commentData: {
            authorId: userId,
          },
        })
        .focus()
        .run();
    },
    [editor, userId]
  );

  const handleSubmit = useCallback(
    (values: { html: string; json: JSONContent }) => {
      if (!editor) return;

      createThread(values.json);

      const { from, to } = editor.state.selection;

      setTimeout(() => {
        editor
          .chain()
          .setTextSelection({ from: from + 1, to })
          .run();

        editor.commands.setTextSelection({ from, to });
      });
    },
    [createThread, editor]
  );

  const [filterByType, setFilterByType] = useState<FilterByType>(FilterByType.All);

  const [filterByPerson, setFilterByPerson] = useState<FilterByPerson>(FilterByPerson.Anyone);

  const filteredThreads = useMemo(() => {
    if (!threads) return [];

    return threads
      .filter((t) => {
        if (filterByType === FilterByType.All) return true;
        if (filterByType === FilterByType.Open) return !t.resolvedAt;
        if (filterByType === FilterByType.Resolved) return !!t.resolvedAt;
        return false;
      })
      .filter((t) => {
        if (filterByPerson === FilterByPerson.Anyone) return true;
        if (filterByPerson === FilterByPerson.Yours) return t.data.authorId === userId;
        return false;
      })
      .filter(Boolean);
  }, [threads, filterByType, filterByPerson, userId]);

  const threadCardRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (activeThreadId) {
      const threadCard = threadCardRefs.current[activeThreadId];

      if (threadCard) {
        threadCard.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [activeThreadId]);

  if (!editor) return null;

  return (
    <div className="w-[25%] min-w-[360px] h-full border-l border-l-surface-100 flex-col pt-4 sticky top-0 hidden lg:flex">
      <section className="flex justify-between px-6 border-b border-surface-100 pb-3">
        <div>Comments</div>

        <Tooltip title="Filter">
          <Tippy
            interactive
            placement="bottom-end"
            trigger="click"
            offset={[0, 8]}
            animation="shift-toward-subtle"
            content={
              <div className="p-2 bg-black flex flex-col gap-2 text-white text-opacity-80 rounded-[10px] w-40">
                <div className="flex flex-col">
                  {FilterByTypeOptions.map((option) => (
                    <button
                      key={option}
                      type="button"
                      className="px-2 py-1 hover:bg-white hover:bg-opacity-20 rounded-md capitalize flex justify-between items-center"
                      onClick={() => setFilterByType(option)}
                    >
                      {option}
                      {filterByType === option && <Icon name="Check" />}
                    </button>
                  ))}
                </div>

                <span className="border-b border-white border-opacity-30" />

                <div className="flex flex-col">
                  {FilterByPersonOptions.map((option) => (
                    <button
                      key={option}
                      type="button"
                      className="px-2 py-1 hover:bg-white hover:bg-opacity-20 rounded-md capitalize flex justify-between items-center"
                      onClick={() => setFilterByPerson(option)}
                    >
                      {option}
                      {filterByPerson === option && <Icon name="Check" />}
                    </button>
                  ))}
                </div>
              </div>
            }
            appendTo={document.body}
          >
            <button
              type="button"
              className="flex justify-center items-center p-1.5 hover:bg-gray-300 hover:bg-opacity-60 rounded-md"
            >
              <Icon name="Sort" />
            </button>
          </Tippy>
        </Tooltip>
      </section>

      <section className="flex flex-1 flex-col overflow-y-auto pb-16">
        {!threads?.length && (
          <div className="px-6 flex flex-col gap-2 mt-4">
            <div className="text-gray-600 text-opacity-60">
              Share your thoughts, ask a question, or give feedback. Go to any block’s options to leave a comment.
            </div>

            {isComposing ? (
              <div className="bg-white w-full border border-primary-500 rounded-md shadow">
                <CommentEditor className="max-h-[8rem]" autoFocus onSubmit={handleSubmit} />
              </div>
            ) : (
              <button
                type="button"
                className="py-2 px-3 rounded-md bg-white shadow-sm border border-gray-200 w-fit"
                onClick={() => setComposing(true)}
              >
                Add a comment
              </button>
            )}
          </div>
        )}

        {!!threads?.length &&
          filteredThreads.map((thread) => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              onClick={selectThread}
              onMouseEnter={highlightThread}
              onMouseLeave={removeHighlightThread}
              active={activeThreadIdObj[thread.id]}
              inFloatingList
              inSidebar
              referenceText={threadIdTextContentMap[thread.id]}
              refCallback={(r) => {
                threadCardRefs.current[thread.id] = r;
              }}
            />
          ))}
      </section>
    </div>
  );
};
