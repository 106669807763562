import { FC } from 'react';
import { SignupElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

export const SignupView: FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <SignupElement
      element={{
        type: 'signup',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
      }}
    >
      {/* So signup can easily be selected, try removing and see what happens */}
      <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
    </SignupElement>
  );
};
