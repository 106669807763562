
import RemovableSectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/RemovableSectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { LinkToSettings } from "./LinkToSettings";
import { NewTabSettings } from "./NewTabSettings";

export function NavItemLinkSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;
  const itemType = selectedContent?.attrs && 'type' in selectedContent.attrs && selectedContent.attrs.type || '';
  if (itemType !== 'link' && itemType !== 'button') return null;


  return <RemovableSectionRenderer title="Link" >
    <LinkToSettings />
    <NewTabSettings />
  </RemovableSectionRenderer>;
}
