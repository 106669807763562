import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { RssFeed } from '../../interfaces/rss_feed';
import api from '../../services/swarm';

export default function useRssFeeds() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<RssFeed[]>([currentPublicationId, 'rss_feeds'], () =>
    api
      .get(`/rss_feeds`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data)
  );
}
