import { BellAlertIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useNotificationSettings from '@/pages/Settings/Pages/Notifications/useNotificationSettings';
import { SettingsContainer, SettingsGroup, SettingsLoader } from '@/ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '@/ui/Settings/SwitchDescriptionInput';

const WebBuilderNotifications = () => {
  const currentPublicationId = useCurrentPublicationId();
  const webTemplateMutation = useUpdateWebTemplate();
  const { data: webTemplate } = useWebTemplate();
  const { settings } = useNotificationSettings(currentPublicationId);
  const commentsSetting = settings?.find((setting) => setting.code === 'comments');
  const globalNotificationUrl = `${window.location.origin}/settings/notifications`;

  return (
    <SettingsContainer>
      <SettingsGroup title="Notifications" description="Manage notifications on your publication site.">
        {!settings || !webTemplate ? (
          <SettingsLoader />
        ) : (
          <>
            {settings && !commentsSetting?.value && (
              <div className="p-4 bg-[#EEF2FF] rounded-lg border border-[#C7D2FE]">
                <div className="flex flex-row space-x-3">
                  <div>
                    <InformationCircleIcon className="h-5 w-5 mb-auto text-[#6366F1]" />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Typography token="font-medium/text/sm" className="text-[#312E81]">
                      Enable global notifications, before enabling for your website.
                    </Typography>
                    <ul className="flex flex-col space-y-2 pl-4 list-disc">
                      <li className="font-normal text-sm text-[#4338CA]">
                        Go to your{' '}
                        <a href={globalNotificationUrl} target="_blank" rel="noreferrer" className="underline">
                          global notifications
                        </a>
                        .
                      </li>
                      <li className="font-normal text-sm text-[#4338CA]">Toggle on &quot;Post Comments&quot;.</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <SwitchDescriptionInput
              title="Enable Notifications"
              name="enable_notifications"
              value={webTemplate?.notifications_enabled || false}
              onSave={(val: boolean) => webTemplateMutation.mutateAsync({ notifications_enabled: val })}
              disabled={!commentsSetting?.value}
              description={
                <div className="space-y-2">
                  <p>
                    Notify subscribers when events occur through a notification bell icon at the top, right-hand corner
                    of your navbar. This may help increase community engagement.*
                  </p>
                  <p className="italic">
                    *Please note: this feature only supports notifications for new comments at the moment.
                  </p>
                </div>
              }
              Icon={BellAlertIcon}
              toastSuccessMessage="Saved!"
            />
          </>
        )}
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderNotifications;
