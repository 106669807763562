import React from 'react';

import { FileSelect } from '@/components/Form';

import BodyContainer from '../../components/BodyContainer';
import { StepProps } from '../new/types';

const SubstackPremiumContentUpload: React.FunctionComponent<StepProps> = ({ contentImport, updateContentImport }: StepProps) => (
  <BodyContainer>
    <FileSelect
      name="file"
      labelText="Upload Exported Publication Content"
      helperText="If you have premium content hidden behind a paywall, you will need to upload your exported content as it wont be pulled from your publication URL"
      onFileSelect={(file: File) => { updateContentImport({ file }) }}
      file={contentImport.file}
      accept=".zip"
    />
  </BodyContainer>
);

export default SubstackPremiumContentUpload;
