import { getColumnsElementRenderHTML } from '@shared/dream-components';
import { Node } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

export enum ColumnLayout {
  Custom = 'custom',
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoColumn = 'two-column',
}

export const COLUMN_DEFAULTS = {
  padding: '20px 0px 20px 0px', // top right bottom left
  paddingRoot: '20px 30px 20px 30px',
  paddingMobile: '5px 0px 5px 0px',
  paddingRootMobile: '5px 30px 5px 30px',
  margin: '0px 0px 0px 0px',
  gap: '30px',
  gapMobile: '16px',
  gapMobileVertical: '10px',
  borderRadius: '0px 0px 0px 0px',
  boxShadow: 'none',
};

export const Columns = Node.create({
  name: 'columns',

  group: 'block',

  content: 'column column+',

  defining: true,

  isolating: true,

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      layout: {
        default: ColumnLayout.Custom,
      },
      stackOnMobile: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-stack-on-mobile'),
        renderHTML: (attributes) => ({
          'data-stack-on-mobile': attributes.stackOnMobile,
        }),
      },
      width: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `width: ${attributes.width}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
          style: `max-width: ${attributes.width_max || '100%'}`,
        }),
      },
      padding: {
        default: '20px 0px 20px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
          style: `--padding: ${attributes.padding || '20px 0px 20px 0px'};--padding-mobile: ${
            attributes.padding
          };--padding-root: ${attributes.padding || '20px 30px 20px 30px'};--padding-root-mobile: ${
            attributes.padding || '5px 30px 5px 30px'
          }`,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
          style: `margin: ${attributes.margin}`,
        }),
      },
      gap: {
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
          style: `--gap: ${attributes.gap || '30px'};--gap-mobile: ${attributes.gap || '16px'};--gap-mobile-vertical: ${
            attributes.gap || '10px'
          }`,
        }),
      },
      borderRadius: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
          style: `--border-radius: ${attributes.borderRadius}`,
        }),
      },
      boxShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-box-shadow'),
        renderHTML: (attributes) => ({
          'data-box-shadow': attributes.boxShadow,
          style: `--box-shadow: ${attributes.boxShadow}`,
        }),
      },
    };
  },

  addCommands() {
    return {
      setColumns:
        () =>
        ({ commands }) =>
          commands.insertContent(
            `<div data-type="columns"><div data-type="column"><p></p></div><div data-type="column"><p></p></div></div>`
          ),
      setColumnCount:
        (count) =>
        ({ dispatch, tr, editor }) => {
          const { selection } = editor.state;

          let activeNode;
          let activePos;
          if (selection instanceof NodeSelection) {
            activeNode = selection.node;
            activePos = selection.$anchor.pos;
          } else {
            // leaf node, select the parent
            const parentNode = selection.$anchor.parent;
            activeNode = parentNode;
            activePos = selection.$head.start(selection.$head.depth) - 1;
          }

          const isColumns = activeNode.type.name === 'columns';

          if (!activeNode || !dispatch || !isColumns || count === 1) {
            // we don't want to remove columns, should we unwrap??
            return true;
          }

          const columnsNode = activeNode;
          const columnsPos = activePos;
          const columnsCount = columnsNode.childCount;

          if (columnsCount < count) {
            // adding columns
            const newColumnNode = editor.schema.nodes.column.createAndFill();

            if (newColumnNode) {
              tr.insert(columnsPos + columnsNode.nodeSize - 1, newColumnNode);
            }
          } else {
            // Remove column
            const lastColumnNode = columnsNode.child(columnsCount - 1);

            tr.delete(
              columnsPos + columnsNode.nodeSize - lastColumnNode.nodeSize - 1,
              columnsPos + columnsNode.nodeSize - 1
            );
          }
          return true;
        },
      addDreamColumnAfter:
        (pos) =>
        ({ tr, editor }) => {
          const emptyContainerNode = editor.schema.nodes.container.createAndFill();
          const newColumnNode = editor.schema.nodes.column.createAndFill(null, emptyContainerNode);

          if (newColumnNode) {
            tr.insert(pos, newColumnNode);
          }
          return true;
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="columns"]',
      },
    ];
  },

  renderHTML({ node }) {
    // TODO: fix typing error
    return getColumnsElementRenderHTML({ node: node as any });
  },
});

export default Columns;
