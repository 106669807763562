import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import Banner from '@/components/Banner';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { PostStatus } from '@/interfaces/post';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { usePostContext } from '../PostContext';

import EmailSettings from './EmailSettings';
import Header from './Header';
import PostMeta from './PostMeta';
import PublishSettings from './PublishSettings';
import ScheduleSettings from './ScheduleSettings';
import TestEmailModal from './TestEmailModal';

const Review = () => {
  const navigate = useNavigate();
  const { formData: post, setPostEditorStep, onChange, setShowScheduleModal } = usePostContext();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showTestEmailModal, setShowTestEmailModal] = useState(true);
  const { data: postDeliveryData } = usePostDeliveryData(post?.id || '');
  const [bannerDismissed, setBannerDismissed] = useState(false);

  useEffect(() => {
    if (post?.scheduled_at) setSelectedTime(moment(post.scheduled_at).toISOString());
  }, [post]);

  useEffect(() => {
    if (document.cookie.includes('hideTestEmailBanner=true')) {
      setShowTestEmailModal(false);
    }
  }, []);

  if (!post) return null;
  if (!setPostEditorStep) return null;

  const openUnpublishModal = () => {
    setShowUnpublishModal(true);
  };

  const closeTestEmailModal = ({ showAgain }: { showAgain: boolean }) => {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // expire in 1 day

    if (!showAgain) {
      date.setTime(date.getTime() + 8760 * 60 * 60 * 1000); // expire in 1 year
    }

    document.cookie = `hideTestEmailBanner=true;expires=${date.toUTCString()};path=/`;

    setShowTestEmailModal(false);
  };

  const noAudience = !postDeliveryData?.for_email && !postDeliveryData?.for_web;
  const emailAudienceWithZeroSubscribers =
    postDeliveryData?.for_email && !postDeliveryData?.for_web && postDeliveryData?.target_subscriptions === 0;
  const emailAndWebAudienceWithZeroEmailSubscribers =
    postDeliveryData?.for_email && postDeliveryData?.for_web && postDeliveryData?.target_subscriptions === 0;

  const renderPublishButton = () => {
    if (post.status === PostStatus.DRAFT) {
      return (
        <div className="w-2/3 mx-auto max-w-[40rem]">
          <div className="flex flex-row justify-end">
            <Button
              size="base"
              variant="primary"
              onClick={() => setShowScheduleModal(true)}
              disabled={noAudience || emailAudienceWithZeroSubscribers}
            >
              Schedule
            </Button>
          </div>
        </div>
      );
    }
    if (post.status === PostStatus.SCHEDULED) {
      return (
        <div className="w-2/3 mx-auto max-w-[40rem]">
          <div className="flex flex-row justify-end">
            <Button
              size="base"
              variant="primary-inverse"
              className="!text-feedback-danger-600"
              onClick={openUnpublishModal}
            >
              Unschedule Post
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6 overflow-auto">
      <Header post={post} />
      {noAudience && (
        <div className="relative w-2/3 mx-auto max-w-[40rem]">
          <Banner
            className="!w-full"
            variant="warning"
            title={
              <div>
                <p className="font-medium">No audience selected</p>
                <p className="font-normal mt-2">
                  You need to select an audience before you can publish. Please choose an audience to continue.
                </p>
                <div className="flex mt-2">
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      setPostEditorStep(PostEditorSteps.AUDIENCE);
                    }}
                  >
                    Edit audience
                  </button>
                </div>
              </div>
            }
          />
        </div>
      )}
      {emailAudienceWithZeroSubscribers && (
        <div className="relative w-2/3 mx-auto max-w-[40rem]">
          <Banner
            className="!w-full"
            variant="warning"
            title={
              <div>
                <p className="font-medium">No recipients for email</p>
                <p className="font-normal mt-2">
                  It looks like you have selected 0 email recipients for this post. To publish via email, please select
                  email recipients.
                </p>
                <div className="flex mt-2">
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      setPostEditorStep(PostEditorSteps.AUDIENCE);
                    }}
                  >
                    Edit audience
                  </button>
                </div>
              </div>
            }
          />
        </div>
      )}
      {emailAndWebAudienceWithZeroEmailSubscribers && !bannerDismissed && (
        <div className="relative w-2/3 mx-auto max-w-[40rem]">
          <Banner
            className="!w-full"
            title={
              <div>
                <p className="font-medium">No recipients for email</p>
                <p className="font-normal mt-2">
                  It looks like you have selected 0 email recipients for this post. To publish via email, please select
                  email recipients. Otherwise, it will be published to web only.
                </p>
                <div className="flex mt-2">
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      setPostEditorStep(PostEditorSteps.AUDIENCE);
                    }}
                  >
                    Edit audience
                  </button>
                  <button
                    type="button"
                    className="underline ml-4"
                    onClick={() => {
                      setBannerDismissed(true);
                    }}
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            }
          />
        </div>
      )}
      <PublishSettings post={post} setPostEditorStep={setPostEditorStep} />
      {postDeliveryData?.for_web && <PostMeta post={post} setPostEditorStep={setPostEditorStep} />}
      {postDeliveryData?.for_email && <EmailSettings post={post} setPostEditorStep={setPostEditorStep} />}
      <ScheduleSettings post={post} selectedTime={selectedTime} setSelectedTime={setSelectedTime} onChange={onChange} />
      {renderPublishButton()}
      <ActionModal
        modalMessageType="danger"
        buttonType="danger"
        buttonShade="dark"
        actionText="Unschedule"
        isOpen={showUnpublishModal}
        onClose={() => setShowUnpublishModal(false)}
        onProceed={() => {
          api.patch(`/posts/${post.id}/transition`, { status: PostStatus.DRAFT }).then(() => {
            analytics.track('Unscheduled Post');
            toast.success('Post successfully unscheduled');
            navigate(`/posts/${post.id}`);
          });
        }}
        descriptionText="Unscheduling will revert this post to a draft state. It won't be sent via email or accessible on web. You'll need to publish or reschedule it when ready."
        headerText="Unschedule Post"
        isWorking={false}
        disabled={false}
        resourceId="123"
      >
        <p />
      </ActionModal>
      <TestEmailModal onClose={closeTestEmailModal} show={showTestEmailModal} />
    </div>
  );
};

export default Review;
