import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCopyToClipboard } from '@/hooks';
import { useCreateApiKey, useUpdateApiKey } from '@/hooks/useIntegrations';
import { ApiKey } from '@/interfaces/api_key';

interface Props {
  apiKey?: ApiKey;
  organizationId: string;
  publicationId: string;
  isOpen: boolean;
  onClose: () => void;
}

const KeyForm = ({ organizationId, publicationId, isOpen, onClose, apiKey }: Props) => {
  const copy = useCopyToClipboard();
  const [errors, setErrors] = useState<{
    name?: boolean;
  }>({});
  const [apiKeyName, setApiKeyName] = useState(apiKey?.name || '');
  const [token, setToken] = useState(null);

  const handleClose = () => {
    onClose();
    setApiKeyName('');
    setErrors({});
    setToken(null);
  };

  const handleCopyToken = () => {
    if (token) {
      copy({ text: token });
    }
    handleClose();
  };

  const { mutate: createKey, isLoading: isLoadingCreateKey } = useCreateApiKey(
    {
      onSuccess: (response) => {
        setToken(response.data.token);
      },
    },
    publicationId
  );

  const { mutate: updateKey, isLoading: isLoadingUpdateKey } = useUpdateApiKey(
    {
      keyId: apiKey?.id,
      onSuccess: handleClose,
    },
    publicationId
  );

  const handleClickCreateOrUpdate = () => {
    if (!apiKeyName) {
      setErrors({ name: true });
      return;
    }

    if (apiKey) {
      updateKey({
        name: apiKeyName,
      });
    } else {
      createKey({
        name: apiKeyName,
      });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const actionText = token ? "I've saved the key" : apiKey ? 'Update Key' : 'Create New Key';
  // eslint-disable-next-line no-nested-ternary
  const headerText = token ? 'Copy API Key' : apiKey ? 'Update API Key' : 'New API Key';
  const descriptionText = token ? (
    <TypographyStack gap="4">
      <Typography token="font-normal/text/sm">This is your unique access code, essential for integration.</Typography>
      <Typography token="font-medium/text/sm">
        Please note: this key will not be retrievable after you leave this page.
      </Typography>
    </TypographyStack>
  ) : (
    !apiKey &&
    "Create new API keys in seconds. They're your gateway to our platform's full potential. Use them to build powerful integrations and automate your workflows"
  );

  return (
    <ActionModal
      resourceId={organizationId}
      headerText={headerText}
      descriptionText={descriptionText}
      isOpen={isOpen}
      onClose={handleClose}
      onProceed={token ? handleCopyToken : handleClickCreateOrUpdate}
      disabled={token ? false : isLoadingCreateKey || isLoadingUpdateKey}
      isWorking={token ? false : isLoadingCreateKey || isLoadingUpdateKey}
      actionText={actionText}
      cancelText="Cancel"
      hideCancelButton={!!token}
      modalMessageType="info"
    >
      <div className="py-6">
        {token ? (
          <Input
            className="w-full"
            name="name"
            labelText="Unique API Key"
            placeholder=""
            helperText="Copy this key down and ensure you store it securely before navigating away"
            disabled
            value={token}
            onChange={() => {}}
          />
        ) : (
          <Input
            labelText="Name"
            required
            placeholder="My API Key"
            helperText="This is so you can identify the use case of the API Key easily"
            errorText={errors.name ? 'Please enter a name' : undefined}
            defaultValue={apiKeyName}
            name="name"
            onBlur={(e) => {
              setApiKeyName(e.currentTarget.value);
            }}
          />
        )}
      </div>
    </ActionModal>
  );
};

export default KeyForm;
