import { useState } from "react";
import toast from "react-hot-toast";

import { Input, SimpleSelect } from "@/components/Form";
import { MultiActionModal, Step } from "@/components/MultiActionModal";
import { Typography, TypographyStack } from "@/components/Typography";
import { useCurrentUser } from "@/context/current-user-context";
import { useTeam } from "@/hooks/usePublicationDashboard";
import { usePublication } from "@/hooks/usePublications";

import useTransferPublication from "../../_hooks/useTransferPublication";

export interface TransferPublicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
}

const TransferPublicationModal = ({ isOpen, onClose, publicationId }: TransferPublicationModalProps) => {
  const CONFIRM_TEXT = 'CONFIRM';
  const publication = usePublication(publicationId)?.data;
  const [receiverId, setReceiverId] = useState<string>('');
  const [confirmInput, setConfirmInput] = useState<string>('');
  const confirmed = confirmInput === CONFIRM_TEXT;
  const teamQuery = useTeam({publicationId});
  const { team } = teamQuery?.data || {};
  const { currentUser } = useCurrentUser();
  const admins = team?.filter((member) => ( // Show only admins that are not the current user
    (member.role.name === 'admin' && member.user.id !== currentUser?.id)
  ))?.filter((admin, index, self) => ( // Remove duplicates
    self.findIndex((a) => a.user.id === admin.user.id) === index
  ));

  const adminOptions = admins?.map((admin) => (
    {
      value: admin.user.id,
      label: `${admin.user.name} <${admin.user.email}>`
    }
  ));
  const receiver = adminOptions?.find((admin) => admin.value === receiverId)?.label;
  const onSubmitSuccess = () => {
    toast.success('Publication ownership transferred successfully');
    window.location.href = '/settings'; // Avoid using navigate here to force a full page reload for permission changes
  }

  const transferPublication = useTransferPublication(onSubmitSuccess);
  const handleSubmit = () => {
    transferPublication.mutate({receiverId});
  }

  const handleClose = () => {
    setReceiverId('');
    onClose();
  }

  const steps = [
    {
      title: 'Transfer Ownership',
      actions: [
        {
          actionText: 'Cancel',
          nextStep: -1, // negative steps trigger the modal to close
          variant: 'primary-inverse',
        },
        {
          actionText: 'Continue',
          nextStep: 1,
          variant: 'primary',
        }
      ],
      children: (
        <TypographyStack className="space-y-6">
          <Typography as="p">
            Transferring ownership of your publication, <Typography weight="semibold">{publication?.name}</Typography>, will include everything within beehiiv.
          </Typography>
          <Typography as="p">
            <Typography weight="semibold">Some items included in the transfer:</Typography>
            <ul className="list-disc pl-6">
              <li>Settings</li>
              <li>Publication members</li>
              <li>Content</li>
              <li>Templates</li>
              <li>Domain settings</li>
              <li>Automations</li>
              <li>Segments</li>
            </ul>
          </Typography>
          <Typography weight="semibold">Some items NOT included in the transfer:</Typography>
          <ul className="list-disc pl-6">
            <li>Ownership of connected Stripe accounts</li>
            <li>Connected 3rd party tracking such as GTM</li>
            <li>Accounts being sent beehiiv Webhooks</li>
            <li>Integration accounts like Make or Zapier</li>
            <li>Custom Domain Ownership</li>
          </ul>
          <Typography as="p">
            Please transfer ownership of associated Stripe accounts within Stripe. Pending payouts will not be interrupted.
          </Typography>
          <Typography as="p">
            Transferring ownership will not break any existing connections to third party apps via API or Webhook.
          </Typography>
        </TypographyStack>
      ),
    },
    {
      title: 'Transfer Ownership',
      actions: [
        {
          actionText: 'Cancel',
          nextStep: -1, // negative steps trigger the modal to close
          variant: 'primary-inverse',
        },
        {
          actionText: 'Back',
          nextStep: 0,
          variant: 'primary-inverse',
        },
        {
          actionText: 'Transfer',
          onClick: handleSubmit,
          variant: 'primary',
          isWorking: transferPublication.isLoading,
          disabled: !receiverId || !confirmed || transferPublication.isLoading,
        },
      ],
      children: (
        <TypographyStack className="space-y-6">
          {adminOptions?.length === 0 ? (
            <Typography as="p">
              There are no Admin users in your publication or workspace. You must have at least one Admin user to transfer ownership.
            </Typography>
          ) : (
            <>
              <Typography as="p">
                Choose the Admin user you would like to transfer ownership of your publication to.
              </Typography>
              <SimpleSelect
                labelText="Choose Receiver"
                name="receiverId"
                options={adminOptions || []}
                value={receiverId}
                onSelect={(_, value) => setReceiverId(value)}
              />
              {receiver && (
                <>
                  <Typography as="p">
                    You are transferring ownership of your publication, <Typography weight="semibold">{publication?.name}</Typography>, to <Typography weight="semibold">{receiver}</Typography>.
                  </Typography>
                  <Typography as="p">
                    Please type <Typography colorWeight="500" className="italic">{CONFIRM_TEXT}</Typography> to confirm that you would like to proceed.
                  </Typography>
                  <Input
                    name="confirmTransfer"
                    placeholderText={CONFIRM_TEXT}
                    value={confirmInput}
                    onChange={(e) => setConfirmInput(e.target.value)}
                  />
                </>
              )}
              <div className="m-8" />
            </>
          )}
        </TypographyStack>
      ),
    },
  ] as Step[];

  return (
    <MultiActionModal isOpen={isOpen} onClose={handleClose} resourceId={publicationId} modalSize="md" steps={steps} />
  )
};

export default TransferPublicationModal;
