import { useEffect, useState } from 'react';

import Badge from '@/components/Badge';
import { Select } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import UrlCopy from '@/components/UrlCopy';
import { useOrganizationPublications } from '@/hooks/usePublications';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Option } from '@/interfaces/general';

import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';

interface Props {
  organizationId: string;
}

const PublicationIdCard = ({ organizationId }: Props) => {
  const {
    data: organizationPublications,
    isLoading,
    isError,
    isFetched,
  } = useOrganizationPublications({ organizationId });
  const [selectedPublicationId, setSelectedPublicationId] = useState<string>();
  const { data: settings } = usePublicationSettings(selectedPublicationId);

  const publicationOptions: Option[] =
    organizationPublications?.map((publication) => ({
      label: publication.name,
      value: publication.id,
    })) || [];

  useEffect(() => {
    if (organizationPublications && organizationPublications.length > 0) {
      setSelectedPublicationId(organizationPublications[0].id);
    }
  }, [isFetched, organizationPublications]);

  const showV1 = settings?.api_v1;
  const showV2 = settings?.api_v2;

  const renderableIds: {
    badgeText: string;
    id: string;
  }[] = [];

  if (showV1) {
    renderableIds.push({
      badgeText: 'API V1',
      id: selectedPublicationId || '',
    });
  }

  if (showV2) {
    renderableIds.push({
      badgeText: 'API V2',
      id: `pub_${selectedPublicationId}`,
    });
  }

  return (
    <LoadingBox isError={isError} isLoading={isLoading}>
      <BodyContainer>
        <CardHeader
          title="Publication ID"
          description="They are identifiers we assign to your subscribed publications. You can use them to retrieve specific articles and media. Integrate them into your apps for seamless content delivery"
        />
        <Select
          name="selected_publication_id"
          options={publicationOptions}
          value={selectedPublicationId}
          onSelect={(_, value: string) => setSelectedPublicationId(value)}
        />
        {renderableIds.map((renderableId) => (
          <div className="flex flex-col p-4 bg-surface-50 border border-surface-200 rounded-md gap-y-2">
            <Badge type="success" className="w-fit">
              {renderableId.badgeText}
            </Badge>
            <UrlCopy
              url={renderableId.id}
              labelText="Publication ID"
              helperText="The following ID is used to identify your publication when you’re using our API"
            />
          </div>
        ))}
      </BodyContainer>
    </LoadingBox>
  );
};

export default PublicationIdCard;
