import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { Poll, PollListStyle, PollOrientation, PollStyle, PollType } from '../../../interfaces/poll';
import api from '../../../services/swarm';
import { Divider } from '../../Divider';
import { Input } from '../../Form';
import Stack from '../../Stack';

interface Props {
  publicationId: string;
  pollId?: string;
  onCancel: () => void;
  onSuccess: (pollId: string) => void;
  slideOverBodyId: string;
}

export type PollDetails = {
  name: string;
  question: string;
  description?: string;
  redirect_url?: string;
  choices: string[];
  list_style: PollListStyle;
  orientation: PollOrientation;
  poll_type: PollType;
  poll_style: PollStyle;
  live_results_enabled: boolean;
  notes: string;
  trivia_answer: string;
  extended_feedback_enabled: boolean;
};

const TEXT_MAX_LENGTH = 255;

const ExternalRSSFeedForm: React.FC<Props> = ({ onCancel, onSuccess, publicationId }) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const submitMutation = useMutation<Poll>(
    () => {
      return api
        .post(`/publications/${publicationId}/external_rss_feeds`, {
          external_rss_feed: {
            name,
            url,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        if (queryClient) queryClient.setQueryData(['polls', res.id], res);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submitMutation.mutateAsync().then((data) => {
      analytics.track('Created an External RSS Feed');
      toast.success('RSS Feed added successfully');
      onSuccess(data.id);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={8} direction="col">
        <Input
          name="name"
          labelText="Name"
          type="text"
          value={name}
          required
          placeholder="Untitled RSS Feed"
          helperText='Choose a name for the feed (e.g., "New York Times Sports").'
          onChange={(e) => setName(e.target.value)}
          maxLength={TEXT_MAX_LENGTH}
        />
        <Input
          name="url"
          labelText="RSS Feed URL"
          type="url"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          helperText="Specify a URL to fetch the RSS feed from."
        />
        <Divider> </Divider>
        <div className="flex items-center">
          <Button type="button" className="mx-2" variant="primary-inverse" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" loading={submitMutation.isLoading} disabled={false}>
            Save
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default ExternalRSSFeedForm;
