const tiers = [
  {
    id: 'b5f0b28f-2ea3-4695-ac3d-6e77b87185b4',
    description: 'Here is my silver tier for this publication\n',
    status: 'active',
    name: 'Silver Tier',
    publication_id: '22994d49-72f0-46de-b9eb-51f3054d770e',
    is_default: false,
    redirect_url: '',
    prices: [
      {
        id: '59a2947f-6489-4b68-8cfd-a84e45d05940',
        cta: 'By Today',
        currency: 'usd',
        amount_cents: 500,
        enabled: true,
        interval: 'one_time',
        interval_display: '',
        denominator: 'once',
        features: [],
      },
      {
        id: 'ab6b7731-309e-444a-b593-897fb97b9c6a',
        cta: 'Get it today',
        currency: 'usd',
        amount_cents: 10000,
        enabled: true,
        interval: 'year',
        interval_display: '',
        denominator: 'year',
        features: ['benefit 1', 'benefit 2', 'benefit 3'],
      },
      {
        id: 'b4a99adf-3f34-4fa3-bedf-97eca56c3ece',
        cta: 'Purchase Now',
        currency: 'usd',
        amount_cents: 4000,
        enabled: true,
        interval: 'month',
        interval_display: '',
        denominator: 'month',
        features: ['benefit 1', 'benefit 2'],
      },
      {
        id: 'c4390d98-cd72-49d7-b6db-b25f40840516',
        cta: '',
        currency: 'usd',
        amount_cents: 0,
        enabled: false,
        interval: 'donation',
        interval_display: '',
        denominator: 'once',
        features: [],
      },
      {
        id: 'e2e7445d-800b-4ed1-ba9a-1cfd91f7aefe',
        cta: '',
        currency: 'usd',
        amount_cents: 0,
        enabled: false,
        interval: 'one_time',
        interval_display: '',
        denominator: 'once',
        features: [],
      },
    ],
  },
  {
    id: '3d2acad9-5a3a-4cee-8f5c-f0ebb90ae4bf',
    description: 'Some gold tier data goes here',
    status: 'active',
    name: 'Gold Tier',
    publication_id: '22994d49-72f0-46de-b9eb-51f3054d770e',
    is_default: false,
    redirect_url: '',
    prices: [
      {
        id: '46519411-9ce6-4f9a-9d92-d59f4436f6ac',
        cta: "Seriously it's free",
        currency: 'usd',
        amount_cents: 120000,
        enabled: true,
        interval: 'one_time',
        interval_display: '',
        denominator: 'once',
        features: [],
      },
      {
        id: '17b8bdef-759f-4bb9-bb2a-1bdab1615a23',
        cta: 'Buy Later',
        currency: 'usd',
        amount_cents: 100000,
        enabled: true,
        interval: 'year',
        interval_display: '',
        denominator: 'year',
        features: ['Howdy', 'Another benefit', 'something else'],
      },
      {
        id: 'f8a8054f-6094-47ad-b28e-931e6568a875',
        cta: 'Buy Now',
        currency: 'usd',
        amount_cents: 3200,
        enabled: true,
        interval: 'month',
        interval_display: '',
        denominator: 'month',
        features: ['klkdajlkasjdlkjasddsa', 'alkdjlkadsjlkajlkdsa', 'lkdjalkajsd'],
      },
      {
        id: '535be418-1974-48c6-8722-4b9cc397b885',
        cta: '',
        currency: 'usd',
        amount_cents: 0,
        enabled: false,
        interval: 'donation',
        interval_display: '',
        denominator: 'once',
        features: [],
      },
    ],
  },
];

export { tiers };
