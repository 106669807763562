/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";

import ActionModal from "@/components/ActionModal";
import IconButton from "@/components/IconHelpers/IconButton";
import Modal from "@/components/Modal";
import AnswerLinks from "@/components/SupportTicketModal/AnswerLinks";
import Text from "@/components/Text";
import { Typography } from "@/components/Typography";
import ZendeskTicketForm from "@/components/ZendeskTicketForm";
import { useCurrentPublicationState } from "@/context/current-publication-context";
import { useSettings } from '@/context/settings-context';
import { useSupportTicketModalParam } from "@/hooks";
import { ZendeskTicket } from "@/interfaces/zendesk/ticket";
import api from "@/services/swarm";

type Props = {
  open: boolean;
  onClose: () => void;
  isFreePlan: boolean;
  isBanned: boolean;
  bypassConfirm: boolean;
};

const SupportTicketModal = ({open, onClose, isFreePlan, isBanned, bypassConfirm}: Props) => {
  const navigate = useNavigate();
  const { settings } = useSettings();

  const [currentPublicationId] = useCurrentPublicationState();

  const modalParamActive = useSupportTicketModalParam();

  const [confirmed, setConfirmed] = useState(modalParamActive);
  const [submitted, setSubmitted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const disallowTicketCheck = isFreePlan && !settings?.free_support && !isBanned;

  const removeSupportTicketModalParam = useCallback(() => {
    setConfirmed(false);
    searchParams.delete('new_support_ticket');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const createTicket = useMutation((formData: FormData) => api.post('/zendesk/tickets', formData), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('Support ticket created successfully');
      setSubmitted(true);
    },
  });

  const onZendeskTicketFormSubmit = async (data: ZendeskTicket) => {
    /*
      Due to needing to upload an image
      we have to use multipart form instead of JSON
    */
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);

    formData.append('ticket[priority]', data.priority.toString());
    formData.append('ticket[reason]', data.reason.toString());
    formData.append('ticket[subject]', data.subject);
    formData.append('ticket[body]', data.body);
    if (data.attachment) formData.append('ticket[attachment]', data.attachment);

    await createTicket.mutateAsync(formData);
  };

  const closeForm = () => {
    onClose();
    removeSupportTicketModalParam();
  };

  useEffect(() => {
    setConfirmed(bypassConfirm);
  }, [bypassConfirm]);

  useEffect(() => {
    if (modalParamActive && disallowTicketCheck) {
      removeSupportTicketModalParam();
      navigate('/help');
    }
  }, [modalParamActive, disallowTicketCheck, navigate, removeSupportTicketModalParam]);

  return (
    <>
      {/* This modal is a "are you sure you want to submit a ticket" */}
      <ActionModal
        isOpen={open && !confirmed && !disallowTicketCheck}
        onClose={onClose}
        resourceId=""
        headerText="File a Support Ticket"
        onProceed={() => {
          setConfirmed(true);
          setSubmitted(false);
          onClose();
        }}
        isWorking={false}
      >
        <Typography token="font-normal/text/sm" colorWeight="600">
          <Typography token="font-semibold/text/sm" colorWeight="700">Expected wait time:</Typography>{' '}
          24 - 48 hours, depending on your plan.
          <div className="mt-1">
            <AnswerLinks />
          </div>
        </Typography>
      </ActionModal>
      {/* This modal actually has the form to create the ticket */}
      <Modal
        isOpen={confirmed && !disallowTicketCheck}
        includeCloseButton={false}
        onClose={closeForm}
        className="bg-white rounded-t-lg py-6 px-4 space-y-3 max-w-lg w-full z-50"
      >
        {submitted ? (
          <>
            <div className="flex justify-between mb-4">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                Support Ticket Created
              </Text>
              <div>
                <button
                  className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                  type="button"
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <Text size="sm" className="text-gray-600">
              <p className="mb-4">
                Your support request has been created successfully and you will receive a confirmation email shortly. A
                member of our support staff will respond to your request within 24 - 48 hours, depending on your plan
                tier.
              </p>
              <AnswerLinks />
            </Text>
          </>
        ) : (
          <>
            <div className="flex justify-between mb-6">
              <Typography token="font-bold/text/lg" className="w-fit">
                New Support Ticket
              </Typography>
              <div>
                <IconButton
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
            <ZendeskTicketForm onSubmit={onZendeskTicketFormSubmit} onCancel={closeForm} isBanned={isBanned} />
          </>
        )}
      </Modal>
    </>
  );
}

export default SupportTicketModal;
