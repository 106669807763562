import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

interface Variables {
  paypalEmail: string;
}

interface ReturnMessage {
  message: string;
  resource?: {
    paypal_email: string;
  },
  error?: string;
}

interface MutationErrorResponse {
  response: {
    data: {
      error: string;
      message: string;
    }
  }
}

export default function useUpdatePartnerPaypalEmail() {
  const queryClient = useQueryClient();
  const updatePartnerPaypalEmail = ({
    paypalEmail,
  }: Variables): Promise<ReturnMessage> =>
    api
      .patch('/partner_update', {
        affiliate_update: {
          paypal_email: paypalEmail,
        },
      })
      .then((res: { data: ReturnMessage }) => res.data);

  return useMutation<ReturnMessage, MutationErrorResponse, Variables>(
    updatePartnerPaypalEmail,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['partner_update'], { exact: true });
        toast.success(`PayPal Email Successfully Set to ${data.resource?.paypal_email}`)
      },
      onError: (error) => {
        const { error: errorText, message } = error.response.data;
        toast.error(`${errorText}: ${message}`);
      }
    }
  )
}
