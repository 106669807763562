import { FC } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import tw from 'twin.macro';

import MarkDownHelperText from '@/pages/DesignLab/components/MardownHelperText';

import { Typography } from '../Typography';

interface Props {
  className?: string;
  name: string;
  defaultValue?: string;
  value?: string;
  labelText?: string;
  inputClassOverride?: string;
  errorText?: string;
  helperText?: string | React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  placeholderText?: string;
  readOnly?: boolean;
  maxLength?: number;
  cols?: number;
  rows?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  showOptional?: boolean;
  supportsMarkdown?: boolean;
  disabled?: boolean;
}

const StyledTextArea = styled.textarea`
  &::placeholder {
    ${tw`font-light text-sm`}
  }
`;

const Textarea: FC<Props> = (props: Props) => {
  const {
    className,
    errorText,
    name,
    labelText,
    defaultValue,
    value,
    onChange,
    required,
    readOnly,
    placeholderText,
    helperText,
    maxLength,
    cols,
    rows,
    onBlur,
    showOptional = false,
    inputClassOverride,
    supportsMarkdown = false,
    disabled = false,
  } = props;
  const hasError = !!errorText;

  return (
    <div className={className}>
      <div className="flex justify-between">
        <label htmlFor={name} className="flex items-center gap-1 text-sm font-medium text-gray-700">
          {labelText}
          {required ? ' *' : ''}
          {supportsMarkdown && (
            <Typography token="font-light/text/xs" colorWeight="400" as="span">
              <MarkDownHelperText />
            </Typography>
          )}
        </label>
        {showOptional && (
          <Typography token="font-normal/text/sm" colorWeight="500">
            Optional
          </Typography>
        )}
      </div>
      <div className="mt-2">
        <StyledTextArea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholderText}
          required={required}
          readOnly={readOnly}
          onBlur={onBlur}
          className={cx(
            inputClassOverride ||
              'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
            hasError ? 'border-red-300' : 'border-gray-300'
          )}
          maxLength={maxLength}
          cols={cols}
          rows={rows}
          disabled={disabled}
        >
          {defaultValue}
        </StyledTextArea>
        {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
        {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
      </div>
    </div>
  );
};

Textarea.defaultProps = {
  className: undefined,
  onChange: () => {},
  required: false,
  readOnly: false,
  placeholderText: undefined,
  errorText: undefined,
  helperText: undefined,
};

export default Textarea;
