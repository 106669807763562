import { mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { Button as TiptapButton } from '@/components/TiptapEditor/extensions';

import { ButtonView } from './views/ButtonView';

export const Button = TiptapButton.extend({
  addAttributes() {
    return {
      margin: {
        default: '4px 30px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
        }),
      },
      border_radius: {
        default: '0.5rem',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
        }),
      },
      size: {
        default: 'normal',
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({
          'data-alignment': attributes.alignment,
        }),
      },
      href: {
        default: null,
        parseHTML: (element) => element.getAttribute('href'),
        renderHTML: (attributes) => ({
          href: attributes.href,
        }),
      },
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('target'),
        renderHTML: (attributes) => ({
          target: attributes.target,
        }),
      },
      isCustom: {
        default: undefined,
      },
      customBackgroundColor: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-custom-background-color'),
        renderHTML: (attributes) => ({
          'data-custom-background-color': attributes.customColor,
        }),
      },
      customTextColor: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-custom-text-color'),
        renderHTML: (attributes) => ({
          'data-custom-text-color': attributes.customColor,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['button', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonView);
  },
});

export default Button;
