import { FC, useEffect, useRef, useState } from 'react';
import { ArrowRight, Plus } from '@phosphor-icons/react';
import { SectionElement } from '@shared/dream-components';
import { Node as PMNode } from '@tiptap/pm/model';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';
import cx from 'classnames';

import { Button } from '../../../../UI/Button';
import { Text } from '../../../../UI/Text';
import AddSectionModal from '../modal';

import WorkCard from './WorkCard';

type DragOverArea = 'left' | 'right' | 'center';
const DropArea = ({ area }: { area: DragOverArea }) => {
  const isLeft = area === 'left';
  const isRight = area === 'right';
  return (
    <div
      className={cx(
        'absolute w-1/3 h-full top-0 transition-all duration-300 bg-blue-100',
        isLeft && 'left-0',
        isRight && 'right-0'
      )}
    />
  );
};

export const SectionView: FC<NodeViewProps> = ({ node, editor, getPos }) => {
  const { onDragStart } = useReactNodeView();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [dragOverArea, setDragOverArea] = useState<DragOverArea>('center');
  const justUpdatedRef = useRef<boolean>(false);
  const firstChild = node.content.toJSON()?.[0];
  const isColumns = firstChild?.type === 'columns';
  const isCenter = dragOverArea === 'center';
  const hasNoContent = node.content.size === 0;
  const showHoverModal = !isCenter && !isColumns && !hasNoContent;

  const onMouseEnter = () => {
    setIsOver(true);
  };

  const onMouseLeave = () => {
    setIsOver(false);
  };

  useEffect(() => {
    const handleUpdate = () => {
      if (isColumns) return;
      if (isCenter) return;
      if (justUpdatedRef.current) {
        justUpdatedRef.current = false;
        return;
      }

      const nodePos = getPos();
      let sectionNode: PMNode | undefined;

      editor.state.doc.descendants((des, pos) => {
        if (sectionNode) return false;

        if (pos === nodePos) {
          sectionNode = des;
        }

        return false;
      });

      const json = sectionNode?.toJSON();
      let leftColContent: any;
      let rightColContent: any;

      if (dragOverArea === 'right') {
        leftColContent = json.content.slice(1, json.content.length);
        rightColContent = json.content.slice(0, 1);
      } else if (dragOverArea === 'left') {
        leftColContent = json.content.slice(0, 1);
        rightColContent = json.content.splice(1, json.content.length);
      }
      justUpdatedRef.current = true;

      editor
        .chain()
        .setNodeSelection(getPos())
        .command(({ tr }) => {
          const newNode = editor.schema.nodes.section.create(
            node.attrs,
            PMNode.fromJSON(editor.schema, {
              type: 'columns',
              content: [
                {
                  type: 'column',
                  content: leftColContent,
                },
                {
                  type: 'column',
                  content: rightColContent,
                },
              ],
            })
          );

          tr.replaceSelectionWith(newNode);

          return true;
        })
        .run();
    };

    editor.on('update', handleUpdate);

    // eslint-disable-next-line consistent-return
    return () => {
      editor.off('update', handleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, dragOverArea]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const mouseX = e.clientX;
    const windowWidth = window.innerWidth;

    // Use 10% of the page width for left and right areas
    const leftAreaEnd = windowWidth * 0.2;
    const rightAreaStart = windowWidth * 0.8;

    if (mouseX < leftAreaEnd) {
      if (dragOverArea !== 'left') {
        setDragOverArea('left');
      }
    } else if (mouseX >= leftAreaEnd && mouseX < rightAreaStart) {
      if (dragOverArea !== 'center') {
        setDragOverArea('center');
      }
    } else if (mouseX >= rightAreaStart) {
      setDragOverArea('right');
    }
  };

  const handleDragEnd = () => {
    setDragOverArea('center');
  };

  return (
    <SectionElement
      element={{
        type: 'section',
        content: node.attrs.content,
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          padding: hasNoContent ? '0' : node.attrs.padding,
          innerWidthMax: hasNoContent ? '100dvw' : node.attrs.innerWidthMax,
        },
      }}
      onDragOver={handleDragOver}
      onDragStart={onDragStart}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragEnd={handleDragEnd}
      attributes={{
        'data-node-view-wrapper': '',
      }}
    >
      {showHoverModal && <DropArea area={dragOverArea} />}
      {hasNoContent ? (
        <div className="w-full h-full select-none">
          <div
            className={cx('absolute w-full h-full top-0 left-0 transition-all duration-300 pointer-events-none', {
              'bg-blue-50': isDragOver,
            })}
            style={{ zIndex: 1 }}
          />

          <div className="flex flex-col items-center gap-[10px] absolute w-full top-1/2 transform -translate-y-1/2">
            <div className="flex gap-4 self-stretch items-start">
              <WorkCard label="Hero Section" img="/images/hero.png" />
              <WorkCard label="Features Section" img="/images/features.png" />
              <WorkCard label="Testimonials Section" img="/images/testimonials.png" />
              <WorkCard label="Footer Section" img="/images/footer.png" />
            </div>
            <div className="flex gap-4 self-stretch items-center">
              <WorkCard label="Hero Section" img="/images/hero.png" />
              <WorkCard label="Features Section" img="/images/features.png" />
              <WorkCard label="Testimonials Section" img="/images/testimonials.png" />
              <WorkCard label="Footer Section" img="/images/footer.png" />
            </div>
          </div>

          <div
            className="relative w-full h-full flex items-center justify-center backdrop-blur-[1px] bg-gradient-to-b from-white/100 from-50% to-white/40 to-90%"
            style={{ zIndex: 2 }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragOver(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragOver(false);
            }}
          >
            <div className="flex flex-col items-center justify-center gap-2" contentEditable={false}>
              <Text size="xl" weight="semibold" variant="secondary">
                Add a Section
              </Text>
              <div className="flex flex-col">
                <AddSectionModal
                  actionButton={
                    <Button variant="outlined" RightIcon={ArrowRight} className="shadow-md">
                      Browse Templates
                    </Button>
                  }
                  editor={editor}
                  insertPos={{
                    from: getPos(),
                    to: getPos() + node.nodeSize,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isOver && (
            <div className="absolute w-full flex items-center justify-center -bottom-3 left-1/2 transform -translate-x-1/2 duration-300">
              <hr className="w-full absolute top-1/2 border-t-2 border-indigo-400" />
              <AddSectionModal
                actionButton={
                  <button
                    type="button"
                    className="text-sm flex gap-2 items-center rounded-md bg-indigo-400 text-white px-2 py-1 z-10 cursor-pointer"
                  >
                    <Plus /> Add section
                  </button>
                }
                insertPos={getPos() + node.nodeSize}
                editor={editor}
                multipleSelectionsAllowed
              />
            </div>
          )}
          <NodeViewContent data-node-view-display-contents />
        </>
      )}
    </SectionElement>
  );
};
