import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import { useSupportModalContext } from '@/context/support-modal-context';
import { Button } from '@/ui/Button';

const openLink = (href: string) => {
  const linkWindow = window.open();
  if (linkWindow) {
    linkWindow.opener = null; // so there is no reference to the parent window
    linkWindow.location = href;
  }
};

const LockedAccountView = () => {
  const { setIsSupportModalOpen } = useSupportModalContext();

  return (
    <div className="w-full px-4 md:px-6 h-vh my-14">
      <Card className="h-full">
        <div className="w-full h-full flex flex-col justify-center">
          <div className="flex flex-col gap-y-8 items-center">
            <div className="flex flex-col gap-y-6 items-center">
              <div className="flex flex-col gap-y-3 items-center">
                <div className="flex flex-col gap-y-4 items-center">
                  <div className="w-10 h-10 flex items-center justify-center bg-feedback-warning-50 rounded-full">
                    <ExclamationTriangleIcon className="w-5 h-5 text-feedback-warning-600" />
                  </div>
                  <div className="w-72 text-center">
                    <Typography token="font-medium/text/lg">
                      Account is locked due to potential violation of{' '}
                      <a href="https://www.beehiiv.com/tou" className="underline">
                        Terms of Use
                      </a>
                      .
                    </Typography>
                  </div>
                </div>
                <div className="w-full sm:w-96 text-center">
                  <Typography token="font-normal/text/sm" colorWeight="700">
                    If you believe this was a mistake, please contact our support team immediately to resolve.
                  </Typography>
                </div>
              </div>
              <Button onClick={() => setIsSupportModalOpen(true)}>Contact Support</Button>
            </div>

            <div className="flex flex-col gap-y-6 items-center">
              <div className="w-full sm:w-96 text-center">
                <Typography token="font-normal/text/sm" colorWeight="700">
                  Otherwise, if you are in search of a new home, here are a few alternative options you can explore:
                </Typography>
              </div>
              <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-3">
                <Button
                  type="button"
                  variant="primary-inverse"
                  size="xs"
                  onClick={() => openLink('https://ghost.org/')}
                >
                  Ghost
                </Button>
                <Button
                  type="button"
                  rel="noopener noreferrer nofollow"
                  variant="primary-inverse"
                  size="xs"
                  onClick={() => openLink('https://www.curatedletters.com/')}
                >
                  CuratedLetters
                </Button>
                <Button
                  type="button"
                  variant="primary-inverse"
                  size="xs"
                  onClick={() => openLink('https://convertkit.com')}
                >
                  ConvertKit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LockedAccountView;
