import { PropsWithChildren } from 'react';

import { AccordionContent, AccordionItem, AccordionTrigger } from '../../_components/UI/Accordion';
import { Text } from '../../_components/UI/Text';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  isAccordionItem?: boolean;
}>;

const SettingsItem = ({ title, description, children, isAccordionItem }: Props) => {
  const trigger = <div className="flex flex-col space-y-2 max-w-[620px] items-start justify-center text-left">
    <Text size="md" weight="medium" variant="primary" as="h4">
      {title}
    </Text>
    <Text size="sm" weight="medium" variant="secondary" as="p">
      {description}
    </Text>
  </div>

  if (isAccordionItem) {
    return (
      <AccordionItem value={title} className="flex flex-col px-6 last:border-b-0">
        <AccordionTrigger>{trigger}</AccordionTrigger>
        <AccordionContent>
          {children}
        </AccordionContent>
      </AccordionItem>
    )
  }

  return (
    <div className="flex flex-col space-y-4 py-6">
      {trigger}
      {children}
    </div>
  );
};

export default SettingsItem;
