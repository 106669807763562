import { useNavigate } from 'react-router-dom';
import { CloudArrowUpIcon } from '@heroicons/react/24/solid';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { usePublication } from '@/hooks/usePublications';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import PageContainer from '../components/PageContainer';
import { usePublicationSettings } from '../context';

import HistoricalImports from './HistoricalImports';

const PublicationContentImportSettings: React.FC = () => {
  const navigate = useNavigate();
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card>
            <BodyContainer>
              <CardHeader
                title="Content Import"
                description={
                  <Typography token="font-normal/text/sm" colorWeight="500">
                    <span>Import already-existing content from wherever your publication is currently hosted.</span>
                    {' '}
                    <Typography token="font-medium/text/sm" colorWeight="500" color="secondary">
                      <a href="https://www.beehiiv.com/support/article/14492963813143-how-to-migrate-or-import-previous-content-to-beehiiv" rel="nofollow noreferrer noopener">Learn more about content imports</a>
                    </Typography>
                  </Typography>
                }
              />

              <div>
                <Button
                  variant="primary"
                  type="submit"
                  Icon={CloudArrowUpIcon}
                  onClick={() => navigate(
                    appendSettingsPublicationId('/settings/publication/content_import/new', currentPublicationId)
                  )}
                >
                  Start Import
                </Button>
              </div>
            </BodyContainer>
          </Card>

          <HistoricalImports />
        </PageContainer>
      ) : null
      }
    </LoadingBox >
  );
};

export default PublicationContentImportSettings;
