import { useLocation, useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserOrganization, CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';
import { SHOW_CREATE_PUBLICATION_GET_PARAM } from '@/routes/settings/_layout';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

import GroupLink from './GroupLink';
import NavLink from './NavLink';
import PublicationNav from './PublicationNav';

interface Props {
  organization: CurrentUserOrganization;
  currentPublicationId: string | null;
  isOpen: boolean;
  onLinkClick: (value: boolean) => void;
}

const WorkspaceNav = ({ organization, currentPublicationId, isOpen, onLinkClick }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, publications } = organization;
  const currentPublication = publications.find((pub) => pub.id === currentPublicationId);
  const permissions = currentPublication?.permissions || {};

  const navLinks = [
    {
      label: 'Overview',
      to: '/settings/workspace/overview',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/company', 'read'),
    },
    {
      label: 'Team',
      to: '/settings/workspace/team',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Billing & Plan',
      to: '/settings/workspace/billing_and_plan',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/billing', 'read'),
    },
    {
      label: 'API',
      to: '/settings/workspace/api',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/integrations', 'read'),
    },
  ];

  return (
    <div className="flex flex-col gap-y-2">
      <GroupLink
        to={appendSettingsPublicationId(`/settings/publication/general`, organization.publications[0].id)}
        label={name}
        isSelected={isOpen}
        onClick={() => onLinkClick(true)}
      />
      {isOpen ? (
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            {publications.map((publication: CurrentUserPublication) => (
              <PublicationNav
                key={publication.id}
                publication={publication}
                isOpen={currentPublicationId === publication.id}
                onLinkClick={() => onLinkClick(false)}
              />
            ))}
          </div>
          <Button
            type="button"
            size="xs"
            Icon={PlusIcon}
            className="w-full"
            onClick={() =>
              navigate(
                `${appendSettingsWorkspaceId(
                  location.pathname,
                  organization.id,
                  currentPublicationId || organization.publications[0].id
                )}&${SHOW_CREATE_PUBLICATION_GET_PARAM}=true`
              )
            }
          >
            New Publication
          </Button>
          <nav className="w-full flex flex-col  gap-y-1">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={appendSettingsWorkspaceId(link.to, organization.id, organization.publications[0].id)}
                label={link.label}
                isSelected={location.pathname === link.to}
                isDisabled={link.isDisabled}
                onClick={() => onLinkClick(false)}
              />
            ))}
          </nav>
        </div>
      ) : null}
    </div>
  );
};

export default WorkspaceNav;
