import { useState } from 'react';
import { UseMutationResult } from 'react-query';
import moment from 'moment-mini';

import { Input, RadioSelect, Textarea } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { useCurrentTimeZone } from '@/hooks';
import { useCampaignOptions } from '@/hooks/useAdNetwork/internal';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/internal/opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Button } from '@/ui/Button';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  opportunity?: AdNetworkOpportunity;
}

const Form = ({ onSubmitMutation, opportunity }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [payoutModel, setPayoutModel] = useState(opportunity?.payout_model || AdNetworkPayoutModel.CPC);
  const [payoutPerMilleCents, setPayoutPerMilleCents] = useState(opportunity?.payout_per_mille_cents || 0);
  const [payoutPerClickCents, setPayoutPerClickCents] = useState(opportunity?.payout_per_click_cents || 0);
  const [deadline, setDeadline] = useState(opportunity?.deadline || moment().add(2, 'days').endOf('day').toISOString());
  const [campaignId, setCampaignId] = useState(opportunity?.campaign?.id);
  const [messageToPublisher, setMessageToPublisher] = useState(opportunity?.message_to_publisher || '');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    formData.set('opportunity[payout_model]', payoutModel);
    formData.set('opportunity[payout_per_mille_cents]', payoutPerMilleCents.toString());
    formData.set('opportunity[payout_per_click_cents]', payoutPerClickCents.toString());
    formData.set('opportunity[deadline]', String(deadline));
    formData.set('opportunity[campaign_id]', String(campaignId));
    formData.set('opportunity[message_to_publisher]', messageToPublisher);

    mutateAsync(formData);
  };

  const { data: options, isSuccess } = useCampaignOptions();

  const handleSetDeadline = (date: string) => {
    setDeadline(moment(date).endOf('day').toISOString());
  };

  const timeZone = useCurrentTimeZone();

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-4">
        <form className="divide-y" onSubmit={onSubmit}>
          <div className="space-y-6 pb-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Campaign</h2>
              <p className="text-sm text-gray-500">Select the campaign you&apos;d like to provision.</p>
            </div>
            {isSuccess && (
              <select
                value={campaignId}
                onChange={(e) => setCampaignId(e.target.value)}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                required
              >
                <option value="" disabled selected>
                  Select a campaign
                </option>
                {Object.keys(options).map((key) => {
                  return (
                    <optgroup label={key}>
                      {options[key].map((option) => {
                        return <option value={option.value}>{option.label}</option>;
                      })}
                    </optgroup>
                  );
                })}
              </select>
            )}
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Payment</h2>
              <p className="text-sm text-gray-500">Specify the terms for how you will pay the publisher.</p>
            </div>
            <RadioSelect
              value={payoutModel}
              onSelect={(val) => setPayoutModel(val as AdNetworkPayoutModel)}
              options={[
                {
                  name: 'CPC',
                  description: 'Pay publishers for each click they generate.',
                  value: AdNetworkPayoutModel.CPC,
                },
                {
                  name: 'CPM',
                  description: 'Pay publishers for every 1,000 unique opens they generate.',
                  value: AdNetworkPayoutModel.CPM,
                },
              ]}
            />
            {payoutModel === AdNetworkPayoutModel.CPC ? (
              <CurrencyInput
                value={payoutPerClickCents}
                onChange={(val) => setPayoutPerClickCents(val || 0)}
                name="opportunity[payout_per_click_cents]"
                id="payout_per_click_cents"
                labelText="Payout Per Click"
                key="payout_per_click_cents"
              />
            ) : (
              <CurrencyInput
                value={payoutPerMilleCents}
                onChange={(val) => setPayoutPerMilleCents(val || 0)}
                name="opportunity[payout_per_mille_cents]"
                id="payout_per_mille_cents"
                labelText="Payout Per Mille"
                key="payout_per_mille_cents"
              />
            )}
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Timeframe</h2>
              <p className="text-sm text-gray-500">Specify the terms for when a publisher needs to run the campaign.</p>
            </div>
            <Input
              value={moment(deadline).format('YYYY-MM-DD')}
              type="date"
              onChange={(e) => handleSetDeadline(e.target.value)}
              name="opportunity[deadline]"
              labelText="Deadline"
              helperText={`The date the publisher needs to respond by. (End of day, ${timeZone})`}
            />
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Message to publisher</h2>
              <p className="text-sm text-gray-500">Highlight any important details about this opportunity.</p>
            </div>
            <Textarea
              value={messageToPublisher}
              onChange={(e) => setMessageToPublisher(e.target.value)}
              name="opportunity[message_to_publisher]"
              labelText="Message"
              rows={5}
            />
          </div>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
