import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { type OrderBy, useTeamMembers } from '@/hooks/useTeamMembers';
import { Organization } from '@/interfaces/organization';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';

import MemberListItem from './MemberListItem';
import NewMember from './new';

interface Props {
  organization: Organization;
  publicationId: string;
}

const List = ({ organization, publicationId }: Props) => {
  const [newInvitePanelOpen, setNewInvitePanelOpen] = useState<boolean>(false);

  const { data: settings } = usePublicationSettings(publicationId);
  const canInviteMembers = settings?.multiple_team_members;

  const { search, setSearch, direction, orderBy, handleClickOrderButton } = useResourceListFilters<string, string>(
    '',
    'user'
  );

  const {
    data: teamMembersData,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useTeamMembers({ organizationId: organization.id, search, orderBy: orderBy as OrderBy, direction });
  const teamMembers = teamMembersData?.pages.flatMap((page) => page.team_members).filter(Boolean) || [];
  const noResults = false;
  const totalCount = teamMembersData?.pages[0]?.pagination?.total;
  const showingCount = teamMembers?.length || 0;

  const queryClient = useQueryClient();
  const invalidateTeamMembers = () => {
    queryClient.invalidateQueries([organization.id, 'team_members', search, orderBy]);
  };

  useEffect(() => {
    refetch();
  }, [orderBy, search, direction, refetch]);

  return (
    <>
      <NewMember
        title="Invite New User"
        isOpen={newInvitePanelOpen}
        onClose={() => {
          setNewInvitePanelOpen(false);
          invalidateTeamMembers();
        }}
        organization={organization}
      />
      <BodyContainer>
        <CardHeader
          title="Workspace Team"
          description="View and manage your team members user roles, permissions, and access levels across your publications and within your workspace"
        />
        <div className="flex flex-col w-full gap-y-2 sm:justify-end sm:flex-row sm:gap-x-2">
          <ResourceListFilters search={search} setSearch={setSearch} searchPlaceholder="Search users..." />
          {canInviteMembers && (
            <Button size="xs" onClick={() => setNewInvitePanelOpen(true)}>
              Invite New User
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-y-2">
          <ResourceListSearchText showingCount={showingCount} totalCount={totalCount || 0} />
          {noResults ? (
            <EmptyCard title="No Team Members" />
          ) : (
            <LoadingBox isLoading={isLoading} isError={isError}>
              <Items>
                <ItemHeaders>
                  <ItemHeader
                    align={AlignType.LEFT}
                    onClick={handleClickOrderButton}
                    orderBy="user"
                    isSorting={orderBy === 'user'}
                    currentDirection={direction}
                  >
                    User
                  </ItemHeader>
                  <ItemHeader>&nbsp;</ItemHeader>
                  <ItemHeader
                    align={AlignType.LEFT}
                    onClick={handleClickOrderButton}
                    orderBy="permission"
                    isSorting={orderBy === 'permission'}
                    currentDirection={direction}
                  >
                    Permission
                  </ItemHeader>
                  <ItemHeader
                    align={AlignType.LEFT}
                    onClick={handleClickOrderButton}
                    orderBy="role"
                    isSorting={orderBy === 'role'}
                    currentDirection={direction}
                  >
                    Role
                  </ItemHeader>
                  <ItemHeader
                    align={AlignType.LEFT}
                    onClick={handleClickOrderButton}
                    orderBy="publication"
                    isSorting={orderBy === 'publication'}
                    currentDirection={direction}
                  >
                    Publication
                  </ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>&nbsp;</ItemHeader>
                </ItemHeaders>
                <ItemsBody>
                  {teamMembers &&
                    teamMembers.map((member) => (
                      <MemberListItem
                        onCancelInvite={invalidateTeamMembers}
                        onResendInvite={invalidateTeamMembers}
                        onUpdate={invalidateTeamMembers}
                        key={member.id}
                        member={member}
                      />
                    ))}
                </ItemsBody>
              </Items>
            </LoadingBox>
          )}
          {hasNextPage && (
            <div className="flex justify-start mt-8 w-fit">
              <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
            </div>
          )}
        </div>
      </BodyContainer>
    </>
  );
};

export default List;
