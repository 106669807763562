import { useCallback, useEffect, useState } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

const CONTAINER_RESIZE_BOUNDARY = {
  width: 10,
  height: 10,
};

export const ColumnHandle = ({ editor }: { editor: Editor }) => {
  const { storage } = editor;
  const { hoverSide, columnNode } = storage.column;
  const [rect, setRect] = useState({ x: -999, y: -999, width: 0, height: 0 });
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!columnNode) {
      setRect({ x: -999, y: -999, width: 0, height: 0 });
      return;
    }

    const { x, width, height, top: columnTop } = columnNode.getBoundingClientRect();
    const { top: parentTop } = editor.view.dom.getBoundingClientRect();

    if (hoverSide === 'right') {
      setRect({
        x: x + width - CONTAINER_RESIZE_BOUNDARY.width,
        y: columnTop - parentTop,
        width: CONTAINER_RESIZE_BOUNDARY.width,
        height,
      });
    } else if (hoverSide === 'left') {
      setRect({ x, y: columnTop - parentTop, width: CONTAINER_RESIZE_BOUNDARY.width, height });
    }
  }, [columnNode, hoverSide, editor]);

  const handleAddColumn = useCallback(() => {
    if (!columnNode) return;

    const pos = editor.view.posAtDOM(columnNode, 0);
    const node = editor.state.doc.nodeAt(pos);
    if (node) {
      editor.commands.addDreamColumnAfter(hoverSide === 'right' ? pos + node.nodeSize + 1 : pos - 1);
    }
  }, [editor, columnNode, hoverSide]);

  return (
    <div
      className={`absolute top-[${rect.y}px] left-[${rect.x}px] h-[${rect.height}px] w-[${rect.width}px] flex flex-col items-center justify-center`}
      onMouseOver={() => {
        setIsFocused(true);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onMouseLeave={() => {
        setIsFocused(false);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <div
        className="p-0.5 bg-indigo-400 rounded-full h-1/2 relative transition-opacity duration-300 ease-[cubic-bezier(0.4, 0, 0.2, 1)]"
        style={{ opacity: isFocused ? 1 : 0.1 }}
      >
        <div
          className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 p-0.5 bg-indigo-400 rounded-full cursor-pointer transition-opacity duration-300 ease-[cubic-bezier(0.4, 0, 0.2, 1)] text-white"
          style={{ opacity: isFocused ? 1 : 0.1 }}
          onClick={handleAddColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleAddColumn();
            }
          }}
          role="none"
        >
          <PlusCircle weight="bold" size={12} />
        </div>
      </div>
    </div>
  );
};
