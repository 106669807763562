import { useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { FieldSet, InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel/styled';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

import { RSSEntryLayoutPanel } from './RSSEntryLayoutPanel';
import { RSSEntryOrganizerPanel } from './RSSEntryOrganizerPanel';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const RSSBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);
  const { publicationId } = usePublicationContext();
  const { id: externalRssFeedId, data, settings } = editor.getAttributes('rss');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [organizeContentModalOpen, setOrganizeContentModalOpen] = useState(false);
  const [ctaText, setCtaText] = useState(settings?.ctaText || 'Read More');
  const [entriesToShow, setEntriesToShow] = useState(settings?.entriesToShow || 6);

  useEffect(() => {
    if (settings) {
      setCtaText(settings.ctaText);
      setEntriesToShow(settings.entriesToShow);
    }
  }, [settings]);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-rss');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('rss');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteNode('rss').run();
  }, [editor]);

  const clearSelection = useCallback(() => {
    editor.chain().updateAttributes('rss', { id: undefined, data: {} }).run();
  }, [editor]);

  const onChangeEntriesToShow = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEntriesToShow(Number(e.target.value));
  }, []);

  const onUpdateEntriesToShow = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, entriesToShow: entriesToShow || 1 };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, entriesToShow]);

  const onChangeCtaText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedValue = e.target.value;
    if (updatedValue === '') {
      updatedValue = 'Read More';
    }
    setCtaText(updatedValue);
  }, []);

  const onUpdateCtaText = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, ctaText };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, ctaText]);

  const onChangeToggleDisplayTitle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayTitle: !currentSettings.displayTitle };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleLinkToArticle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayCta: !currentSettings.displayCta };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayAuthor = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayAuthor: !currentSettings.displayAuthor };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayContent = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayContent: !currentSettings.displayContent };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayDescription = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayDescription: !currentSettings.displayDescription };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplaySubtitle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displaySubtitle: !currentSettings.displaySubtitle };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayCategories = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayCategories: !currentSettings.displayCategories };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayThumbnail = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayThumbnail: !currentSettings.displayThumbnail };
    editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`rssBlock-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar ref={menuRef} shouldShowContent={shouldShow()}>
        {externalRssFeedId && (
          <>
            <Tippy
              offset={[0, 8]}
              placement="bottom-start"
              reference={menuRef.current}
              visible={isSettingsOpen && menuRef.current !== null}
              interactive
              onClickOutside={() => {
                if (!organizeContentModalOpen) {
                  onUpdateEntriesToShow();
                  onUpdateCtaText();
                  setIsSettingsOpen(false);
                }
              }}
              render={(attrs) =>
                isSettingsOpen && (
                  <Panel style={{ width: '250px' }} {...attrs}>
                    <FieldSet>
                      <InputField
                        label="Articles to Show"
                        value={entriesToShow}
                        type="number"
                        onChange={onChangeEntriesToShow}
                        onBlur={onUpdateEntriesToShow}
                        onSuspend={onUpdateEntriesToShow}
                      />
                      <RSSEntryLayoutPanel editor={editor} />
                      <RSSEntryOrganizerPanel
                        editor={editor}
                        data={data}
                        settings={settings}
                        setOrganizeContentModalOpen={setOrganizeContentModalOpen}
                        organizeContentModalOpen={organizeContentModalOpen}
                      />
                      <Divider />
                      <ToggleWrapper
                        icon={<Icon name="Heading" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayTitle}
                            onChange={onChangeToggleDisplayTitle}
                          />
                        }
                      >
                        Show Title
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Subscript" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displaySubtitle}
                            onChange={onChangeToggleDisplaySubtitle}
                          />
                        }
                      >
                        Show Subtitle
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Image" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayThumbnail}
                            onChange={onChangeToggleDisplayThumbnail}
                          />
                        }
                      >
                        Show Thumbnail
                      </ToggleWrapper>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        editor.getAttributes('rss').settings.displayTitle &&
                        editor.getAttributes('rss').settings.displayThumbnail ? (
                          <ToggleWrapper
                            icon={<Icon name="Image" />}
                            switchEl={
                              <ToggleSwitch
                                isChecked={editor.getAttributes('rss').settings.displayTitleAboveThumbnail}
                                onChange={() => {
                                  const currentSettings = editor.getAttributes('rss').settings;
                                  const newSettings = {
                                    ...currentSettings,
                                    displayTitleAboveThumbnail: !currentSettings.displayTitleAboveThumbnail,
                                  };
                                  editor.chain().focus().updateAttributes('rss', { settings: newSettings }).run();
                                }}
                              />
                            }
                          >
                            Title Above Thumbnail
                          </ToggleWrapper>
                        ) : null
                      }
                      <ToggleWrapper
                        icon={<Icon name="Tag" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayCategories}
                            onChange={onChangeToggleDisplayCategories}
                          />
                        }
                      >
                        Show Categories
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Person" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayAuthor}
                            onChange={onChangeToggleDisplayAuthor}
                          />
                        }
                      >
                        Show Author
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="SizeSmall" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayDescription}
                            onChange={onChangeToggleDisplayDescription}
                          />
                        }
                      >
                        Show Description
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="SizeLarge" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayContent}
                            onChange={onChangeToggleDisplayContent}
                          />
                        }
                      >
                        Show Full Article
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="External" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayCta}
                            onChange={onChangeToggleLinkToArticle}
                          />
                        }
                      >
                        Show CTA
                      </ToggleWrapper>
                      {editor.getAttributes('rss').settings.displayCta && (
                        <InputField
                          label="CTA Text"
                          value={ctaText}
                          defaultValue="View Article"
                          onChange={onChangeCtaText}
                          className="w-full"
                          onBlur={onUpdateCtaText}
                        />
                      )}
                    </FieldSet>
                  </Panel>
                )
              }
            >
              <Tooltip title="Settings">
                <Button
                  $leftSlot={<Icon name="Settings" />}
                  onClick={() => {
                    setIsSettingsOpen(!isSettingsOpen);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                />
              </Tooltip>
            </Tippy>
            <Tooltip title="Refresh Content">
              <Button
                $leftSlot={<Icon name="Refresh" />}
                onClick={() => {
                  API.getExternalRssFeed({ publicationId, externalRssFeedId }).then((response) => {
                    editor.chain().focus().updateAttributes('rss', { data: response.data }).run();
                  });
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Select New RSS Feed">
              <Button
                $leftSlot={<Icon name="Return" />}
                onClick={() => {
                  clearSelection();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
          </>
        )}
        <Tooltip title="Remove Content">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={removeNode}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default RSSBlockMenu;
