import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  isWide?: boolean;
}>;

const PageContainer = ({ children, isWide = false }: Props) => (
  <div
    className={classNames(
      'py-6 px-4 sm:mx-4 sm:my-12 flex flex-col gap-y-6 w-full',
      isWide ? 'md:max-w-[945px]' : 'md:max-w-[640px]'
    )}
  >
    {children}
  </div>
);

export default PageContainer;
