import { useEffect, useState } from 'react';
import { Cog8ToothIcon, PlusIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../../../components/ActionModal';
import { Switch } from '../../../../../components/Form';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { PercentSliderField, SliderField } from '../../../../../components/SliderField';
import { UpgradeIntent } from '../../../../../components/UpgradeIntent';
import { useSettings } from '../../../../../context/settings-context';
import {
  useCreateSplitTest,
  useCustomDomains,
  useDeleteSplitTest,
  useNewSplitTestOption,
  useSplitTest,
  useUpdateSplitTest,
} from '../../../../../hooks';
import { CustomDomain } from '../../../../../interfaces/custom_domain';
import { Post, PostStatus } from '../../../../../interfaces/post';
import { SplitTestOption } from '../../../../../interfaces/split_test_option';
import { IntentAction } from '../../../../../interfaces/upgrades';
import { PLAN } from '../../../../../utils/plans';

import OptionForm from './OptionForm';

export enum DEFAULTS {
  MIN_VARIANTS = 1,
  MIN_SAMPLE_SIZE_PER_VARIANT = 200,
  MIN_DURATION = 300, // 5 minutes
  MAX_DURATION = 14_400, // 4 hours
  SUGGESTED_DURATION = 10_800, // 3 hours
  MIN_SAMPLE_PCT = 1,
  MAX_SAMPLE_PCT = 100,
}

interface Props {
  post: Post;
  splitTesting: boolean;
  setSplitTesting: (splitTesting: boolean) => void;
  children: any;
}

const SplitTestForm = ({ post, splitTesting, setSplitTesting, children }: Props) => {
  const { settings } = useSettings();
  const maxVariants = (settings?.max_split_test_options || 1) - 1; // Subtract 1 for the control.
  const { data: customDomains } = useCustomDomains();
  const isEmailDomainWarmingUp = customDomains?.find((d: CustomDomain) => d.email_enabled)?.warming_up;
  const globalSplitTestingEnabled = String(window.env.REACT_APP_SPLIT_TESTING_ENABLED) === 'true';
  const organizationSplitTestingEnabled = maxVariants >= DEFAULTS.MIN_VARIANTS;
  const [splitTestOptions, setSplitTestOptions] = useState<SplitTestOption[]>([]);
  const splitTestObject = useSplitTest(post.id).data;
  const [newOption, setNewOption] = useState({} as SplitTestOption);
  const [splitTestError, setSplitTestError] = useState('');
  const [splitTestUpgradeIsOpen, setSplitTestUpgradeIsOpen] = useState(false);
  const [customizeSplitTestUpgradeIsOpen, setCustomizeSplitTestUpgradeIsOpen] = useState(false);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
  const [splitTestDuration, setSplitTestDuration] = useState(splitTestObject?.duration || DEFAULTS.SUGGESTED_DURATION);
  const [splitTestSamplePct, setSplitTestSamplePct] = useState(splitTestObject?.sample_pct || 10);
  const locked = post.status !== PostStatus.DRAFT;
  const calcOptions = () => {
    let optionCount = splitTestOptions.length;
    if (newOption?.split_test_id) {
      optionCount += 1;
    }

    return optionCount < DEFAULTS.MIN_VARIANTS ? DEFAULTS.MIN_VARIANTS : optionCount;
  };

  const baseWarning = () => {
    if ((splitTestObject?.population_size || 0) / calcOptions() < DEFAULTS.MIN_SAMPLE_SIZE_PER_VARIANT) {
      return 'The selected audience may be too small for the number of options.';
    }
    return '';
  };

  const durationWarning = () => {
    if (!!splitTestDuration && splitTestDuration < DEFAULTS.SUGGESTED_DURATION) {
      return 'May be too short to get statistically significant results';
    }
    return '';
  };

  const samplePctWarning = () => {
    if (!splitTestSamplePct) {
      return '';
    }

    const perVariant = ((splitTestObject?.population_size || 0) * (splitTestSamplePct / 100)) / calcOptions();
    if (perVariant < DEFAULTS.MIN_SAMPLE_SIZE_PER_VARIANT) {
      return 'May be too low to get statistically significant results';
    }
    return '';
  };

  const createSplitTestMutation = useCreateSplitTest(
    post.id,
    () => {
      setSplitTestError('');
    },
    () => {}
  );

  const handleDeleteSuccess = () => {
    setSplitTestError('');
    setNewOption({} as SplitTestOption);
  };
  const deleteSplitTestMutation = useDeleteSplitTest(post.id, handleDeleteSuccess, () => {});

  // New, but not saved.
  const newSplitTestOptionMutation = useNewSplitTestOption(post.id, (data: any) => {
    setNewOption(data);
  });

  const updateSplitTestMutation = useUpdateSplitTest(
    post.id,
    () => {
      setSplitTestError('');
    },
    () => {},
    () => {
      setIsConfigurationOpen(false);
    }
  );

  const handleSplitTestUpdate = () => {
    if (settings?.split_test_customization) {
      if (splitTesting) {
        updateSplitTestMutation.mutate({ duration: splitTestDuration, sample_pct: splitTestSamplePct });
      }
    } else {
      setCustomizeSplitTestUpgradeIsOpen(true);
    }
  };

  const handleAddOption = () => {
    if (splitTestOptions.length < maxVariants) {
      newSplitTestOptionMutation.mutate();
    }
  };

  const handleSplitTestToggle = (checked: boolean) => {
    setSplitTesting(checked);

    if (!organizationSplitTestingEnabled) {
      setSplitTestUpgradeIsOpen(true);
    } else if (checked) {
      createSplitTestMutation.mutate();
    } else {
      deleteSplitTestMutation.mutate();
    }
  };

  const handleUpsellClose = () => {
    setSplitTesting(false);
    setSplitTestUpgradeIsOpen(false);
  };

  useEffect(() => {
    if (splitTestObject) {
      setSplitTestDuration(splitTestObject.duration);
      setSplitTestSamplePct(splitTestObject.sample_pct);
    }
    if (splitTestObject?.id) {
      setSplitTestOptions(splitTestObject.split_test_options);
      if (splitTestObject.split_test_options.length < DEFAULTS.MIN_VARIANTS) newSplitTestOptionMutation.mutate();
    } else {
      setSplitTestOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTestObject]);

  const actionsHtml = () => {
    if (!splitTesting || createSplitTestMutation.isLoading) {
      return null;
    }

    return (
      <div className="flex flex-row my-4">
        {!locked && !newOption?.split_test_id && splitTestOptions.length < maxVariants && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="flex items-center text-gray-500 hover:text-primary-500" onClick={handleAddOption}>
            <PlusIcon className="h-4 w-4 border-gray-300 rounded-sm cursor-pointer" />
            <span className="ml-2 cursor-pointer text-sm font-medium">Add subject</span>
          </div>
        )}
      </div>
    );
  };

  const optionsLabel = (index: number) => {
    switch (index) {
      case 0:
        return 'Subject Line B';
      case 1:
        return 'Subject Line C';
      case 2:
        return 'Subject Line D';
      case 3:
        return 'Subject Line E';
      default:
        return 'Subject Line';
    }
  };

  const optionsHtml = () => {
    if (!splitTesting) {
      return null;
    }
    if (createSplitTestMutation.isLoading) {
      return (
        <div className="flex items-center">
          <LoadingSpinner className="mr-2" />
          <p className="text-gray-400 text-sm">Loading...</p>
        </div>
      );
    }
    return (
      <div className="space-y-3 mt-3">
        {splitTestOptions.map((option, index) => {
          if (option.control && !locked) {
            // Since we're now creating the control option when the split test begins,
            // this should never happen, but just in case.
            return null;
          }

          return (
            <OptionForm
              labelText={optionsLabel(index)}
              postId={post.id}
              splitTestOption={option}
              deletable={splitTestOptions.length > DEFAULTS.MIN_VARIANTS}
              locked={locked}
            />
          );
        })}
        {/* If the user requested a new option, we render it specially. */}
        {newOption?.split_test_id && (
          <OptionForm
            labelText={optionsLabel(splitTestOptions.length)}
            postId={post.id}
            splitTestOption={newOption}
            deletable
            onSave={() => {
              setNewOption({} as SplitTestOption);
            }}
            onDelete={() => {
              setNewOption({} as SplitTestOption);
            }}
          />
        )}
      </div>
    );
  };

  const handleDurationChange = (value: number) => {
    setSplitTestDuration(value);
  };

  const handleSamplePctChange = (value: number) => {
    setSplitTestSamplePct(value);
  };

  const handleConfigurationClose = () => {
    setIsConfigurationOpen(false);
    if (splitTestObject) {
      setSplitTestDuration(splitTestObject.duration);
      setSplitTestSamplePct(splitTestObject.sample_pct);
    }
  };

  const configurationHtml = () => {
    if (!splitTesting) {
      return <div />;
    }

    if (!locked) {
      return (
        <div aria-hidden="true" onClick={() => setIsConfigurationOpen(true)}>
          <Cog8ToothIcon className="w-6 h-6 text-gray-500 hover:text-primary-500 cursor-pointer" />
        </div>
      );
    }
    return (
      <div aria-hidden="true">
        <Cog8ToothIcon className="w-6 h-6 text-gray-500 cursor-not-allowed" />
      </div>
    );
  };

  const configurationModal = () => {
    const modalId = 'split-test-configuration-modal';
    return (
      <ActionModal
        isOpen={isConfigurationOpen}
        isWorking={updateSplitTestMutation.isLoading}
        onClose={handleConfigurationClose}
        onProceed={handleSplitTestUpdate}
        resourceId={post.id}
        headerText="A/B Test Settings"
        actionText={settings?.split_test_customization ? 'Save' : 'Upgrade to Edit'}
        bodyId={modalId}
        overflow="visible"
      >
        <div className="flex flex-col mt-8 space-y-4">
          <SliderField
            label="Duration"
            min={DEFAULTS.MIN_DURATION / 60}
            max={DEFAULTS.MAX_DURATION / 60}
            name="duration"
            value={splitTestDuration / 60}
            onChange={(value) => handleDurationChange(value * 60)}
            valueSuffix="minutes"
            warningText={durationWarning()}
            tooltip="This is the time during which the A/B experiment will run. If publishing to the web, your post will be accessible immediately."
            portalMountedId={modalId}
            disabled={!settings?.split_test_customization}
          />
          <PercentSliderField
            label="Sample Size"
            min={DEFAULTS.MIN_SAMPLE_PCT}
            max={DEFAULTS.MAX_SAMPLE_PCT}
            name="sample_pct"
            value={splitTestSamplePct}
            valueSuffix="subscribers"
            onChange={(value) => handleSamplePctChange(value)}
            warningText={samplePctWarning()}
            tooltip="This is the percentage of your list that will receive either version A or version B. The remainder of the list will receive the winning version at the conclusion of the test duration."
            disabled={!settings?.split_test_customization}
            totalNumber={splitTestObject?.population_size}
            portalMountedId={modalId}
          />
        </div>
      </ActionModal>
    );
  };

  if (!globalSplitTestingEnabled && !settings?.split_test_override_enabled) {
    return null;
  }
  const splitTestingDisabled = isEmailDomainWarmingUp || locked;

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Switch
              labelText={`Create A/B Test${splitTestingDisabled ? ' (Unavailable)' : ''}`}
              name="create_split_test"
              checked={splitTesting}
              disabled={splitTestingDisabled}
              onChange={(name, checked) => {
                handleSplitTestToggle(checked);
              }}
            />
          </div>
          {!splitTestingDisabled && (
            <p className="mt-2 text-xs text-gray-500">
              Test multiple subject lines to maximize engagement with your subscribers.{' '}
              <a
                target="_blank"
                className="text-blue-500 whitespace-nowrap"
                href="https://youtu.be/7Ph7HeBA5NE"
                rel="noreferrer"
              >
                Learn more
              </a>
            </p>
          )}
          {isEmailDomainWarmingUp && (
            <p className="mt-2 text-xs text-gray-500">
              A/B testing is unavailable until your email domain has finished Smart Warming.
            </p>
          )}
          {locked && <p className="mt-2 text-xs text-gray-500">You can no longer change the A/B Test</p>}
          {splitTesting && <p className="mt-2 text-xs text-yellow-600">{baseWarning()}</p>}
          {createSplitTestMutation.isError && <p className="mt-2 text-xs text-red-500">{splitTestError}</p>}
        </div>
        {configurationHtml()}
      </div>
      <div className="flex flex-row">
        {organizationSplitTestingEnabled && splitTesting && !createSplitTestMutation.isError && (
          <div className="px-4 pt-4 pb-2 rounded-lg bg-gray-100 w-full">
            {children}
            {optionsHtml()}

            {actionsHtml()}
          </div>
        )}
      </div>
      {configurationModal()}
      <UpgradeIntent
        isOpen={splitTestUpgradeIsOpen}
        intentAction={IntentAction.USE_SPLIT_TEST}
        onClose={handleUpsellClose}
        preselectedPlan={PLAN.GROW}
      />
      <UpgradeIntent
        isOpen={customizeSplitTestUpgradeIsOpen}
        intentAction={IntentAction.USE_SPLIT_TEST_CUSTOMIZATION}
        onClose={() => {
          setCustomizeSplitTestUpgradeIsOpen(false);
        }}
        preselectedPlan={PLAN.SCALE}
      />
    </>
  );
};

export default SplitTestForm;
