import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { RssView } from './RssView';

type EntryLayout = '1-col' | '2-col' | '3-col' | '4-col';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    rss: {
      setRss: () => ReturnType;
      setRSSEntryLayout: (entryLayout: EntryLayout) => ReturnType;
      setRSSData: (data: any) => ReturnType;
      openOrganizeContentModal: () => ReturnType;
      closeOrganizeContentModal: () => ReturnType;
    };
  }
}

export interface RssOptions {
  HTMLAttributes: Record<string, any>;
  enabled: boolean;
}

export const RSS = Node.create<RssOptions>({
  name: 'rss',

  atom: true,

  group: 'block',

  selectable: true,

  highlight: false,

  draggable: true,

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      settings: {
        default: {
          entriesToShow: 5,
          displayTitleAboveThumbnail: true,
          displayTitle: true,
          displayCta: true,
          displayAuthor: false,
          displayContent: false,
          displayDescription: false,
          displaySubtitle: false,
          displayCategories: false,
          displayThumbnail: false,
        },
      },
      entryLayout: {
        default: '1-col',
      },
      data: {
        default: {},
      },
    };
  },

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'node-rss',
      },
      enabled: false,
    };
  },

  addCommands() {
    return {
      setRss:
        () =>
        ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
          });
        },
      setRSSEntryLayout:
        (entryLayout: string) =>
        ({ commands }: any) => {
          return commands.updateAttributes(this.name, { entryLayout });
        },
      setRSSData:
        (data: any) =>
        ({ commands }: any) => {
          return commands.updateAttributes(this.name, { data });
        },
    } as any;
  },

  addNodeView() {
    return ReactNodeViewRenderer(RssView);
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: `node-${this.name}` })];
  },
});

export default RSS;
