import { Editor, Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { NodeRangeSelection } from '@tiptap-pro/extension-node-range';

export interface HoverOptions {
  /**
   * The class name that should be added to the focused node.
   * @default 'has-focus'
   * @example 'is-focused'
   */
  className: string;

  /**
   * The mode by which the focused node is determined.
   * - All: All nodes are marked as focused.
   * - Deepest: Only the deepest node is marked as focused.
   * - Shallowest: Only the shallowest node is marked as focused.
   *
   * @default 'all'
   * @example 'deepest'
   * @example 'shallowest'
   */
  mode: 'all' | 'deepest' | 'shallowest';
}

type HoverStorage = {
  hoverTimeout: NodeJS.Timeout | null;
  rect: DOMRect | null;
  node: Element | null;
  nodeSelection: NodeRangeSelection | null;
};

const handleDropFromInsertPanel = (editor: Editor, view: EditorView, event: DragEvent) => {
  const jsonString = event.dataTransfer?.getData('text/plain');

  try {
    const json = JSON.parse(jsonString || '');
    const pos = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })?.pos;

    if (typeof pos === 'number' && pos >= 0 && json.type === 'block' && json.block) {
      editor.commands.insertContentAt(pos, json.block);

      return true;
    }
  } catch {
    // no op
  }

  return false;
};

/**
 * This extension allows you to add a class to the focused node.
 * @see https://www.tiptap.dev/api/extensions/focus
 */
export const Hover = Extension.create<HoverOptions, HoverStorage>({
  name: 'hover',

  addOptions() {
    return {
      className: 'dream-hovering',
      mode: 'all',
    };
  },

  addStorage() {
    return {
      hoverTimeout: null,
      rect: null,
      node: null,
      nodeSelection: null,
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: new PluginKey('hover'),
        props: {
          handleDrop(view, event) {
            return handleDropFromInsertPanel(editor, view, event);
          },
          handleDOMEvents: {
            mouseover: (view, event) => {
              try {
                if (!editor) return;
                const target = event.target as HTMLElement;

                const nodeDom = target.closest('[data-node-view-wrapper]');
                if (!nodeDom) return;

                const pos = view.posAtDOM(nodeDom, 0);
                if (pos === null) return;

                const resolvedPos = view.state.doc.resolve(pos);
                let node = resolvedPos.nodeAfter || resolvedPos.nodeBefore;
                if (node && node.type.name === 'text') {
                  node = resolvedPos.parent;
                }

                if (nodeDom) {
                  this.storage.node = nodeDom;
                  this.storage.rect = nodeDom.getBoundingClientRect();
                  this.storage.nodeSelection = NodeRangeSelection.create(
                    view.state.doc,
                    pos,
                    pos + ((node?.nodeSize || 0) - 1 || 0)
                  );
                }

                view.dispatch(view.state.tr);
              } catch (e) {
                console.error('Error:', e);
              }
            },
          },
        },
      }),
    ];
  },
});
