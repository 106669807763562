import { ArrowTopRightOnSquareIcon, CursorArrowRaysIcon } from '@heroicons/react/20/solid';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Card } from '@/ui/Card';

import { OpportunityBadge } from '../OpportunityBadge';

import Calculator from './Calculator';

interface Props {
  opportunity: AdNetworkOpportunity;
}

const OpportunityDetails = ({ opportunity }: Props) => {
  const { payout_model: payoutModel, payout_per_click: payoutPerClick, payout_per_mille: payoutPerMille } = opportunity;

  const payoutRate = payoutModel === AdNetworkPayoutModel.CPC ? `${payoutPerClick} CPC` : `${payoutPerMille} CPM`;
  const payoutRateDescription =
    payoutModel === AdNetworkPayoutModel.CPC
      ? `This ad pays ${payoutPerClick} per unique click within a single send.`
      : `This ad pays ${payoutPerMille} per 1,000 unique opens within a single send.`;
  const payoutRateTooltip = {
    [AdNetworkPayoutModel.CPC]: `You will get paid ${payoutPerClick} for every subscriber that clicks this ad within a single send.`,
    [AdNetworkPayoutModel.CPM]: `You will earn ${payoutPerMille} for every 1,000 subscribers who open your email. If the number of opens is less than 1,000, your payout will be prorated. For example, if you have 500 opens, you will receive half of the ${payoutPerMille} rate.`,
  }[payoutModel];

  const showCalculator = opportunity.estimated_opens > 100;

  return (
    <Card density="none">
      {/* Advertiser Details */}
      <div className="flex border-b border-surface-200 p-6 space-y-6">
        <div className="space-y-6">
          <OpportunityBadge opportunity={opportunity} />
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Typography as="p" token="font-medium/text/base">
                {opportunity.advertiser.name}
              </Typography>
              <a href={opportunity.advertiser.url} target="_blank" rel="noreferrer noopener">
                <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-500" />
              </a>
            </div>
            <Typography as="p" token="font-normal/text/sm" colorWeight="500">
              {opportunity.advertiser.description}
            </Typography>
          </div>
        </div>
      </div>
      {/* CPC */}
      <div className="flex border-b border-surface-200 p-6 space-y-6">
        <div className="space-y-6">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-3">
              <div className="p-1 bg-action-secondary-50 rounded">
                <CursorArrowRaysIcon className="w-4 h-4 text-action-secondary-600" />
              </div>
              <Typography as="p" token="font-medium/text/base">
                {payoutRate}
              </Typography>
              <Tooltip id="payout_explanation" text={payoutRateTooltip} />
            </div>
            <Typography as="p" token="font-normal/text/sm" colorWeight="500">
              {payoutRateDescription}
            </Typography>
          </div>
        </div>
      </div>
      {/* Estimated Payout */}
      {showCalculator && <Calculator opportunity={opportunity} />}
      {/* Message */}
      {(opportunity.message_to_publisher || opportunity.campaign.default_message_to_publisher) && (
        <div className="p-6">
          <div className="space-y-6">
            <Typography as="p" token="font-normal/text/sm">
              {opportunity.message_to_publisher || opportunity.campaign.default_message_to_publisher}
            </Typography>
            <Typography as="p" token="font-normal/text/sm" className="text-gray-700">
              The beehiiv Team
            </Typography>
          </div>
        </div>
      )}
    </Card>
  );
};

export default OpportunityDetails;
