import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import PostPoll from '@/components/_domain/PostPoll';
import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { useEmailMessage } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

const Polls = ({ emailMessageId }: { emailMessageId: string }) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: emailMessage, isLoading, isError } = useEmailMessage({ emailMessageId });

  const noPolls = emailMessage?.post_poll_ids.length === 0;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {noPolls ? (
        <div className="text-center mt-2 rounded py-8 px-2 flex flex-col space-y-4">
          <Text size="sm">This email does not currently include any polls.</Text>
          <Link to="/polls" target="_blank">
            <Button variant="primary-inverse" onClick={() => {}} Icon={PlusIcon}>
              Create one
            </Button>
          </Link>
        </div>
      ) : (
        <div className="space-y-4">
          {emailMessage?.post_poll_ids.map((postPollId) => (
            <PostPoll publicationId={currentPublicationId} postPollId={postPollId} />
          ))}
        </div>
      )}
    </LoadingBox>
  );
};

export default Polls;
