import { useCallback } from 'react';

import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';

import { AttributeSettingProps } from '../types';

type SimpleTextSettingsProps = AttributeSettingProps & {
  title: string;
  property: string;
};

export const SimpleTextSettings = ({ editor, activeNodeResult, property, title }: SimpleTextSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, e.target.value);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <SimpleInputWrapper className="h-[32px]">
        <SimpleInput
          key={`${activeNodePos}-button-text`}
          type="text"
          className="text-xs"
          defaultValue={activeNodeAttributes[property]}
          onChange={handleChange}
        />
      </SimpleInputWrapper>
    </div>
  );
};
