/* eslint-disable no-nested-ternary */
import { BanknotesIcon, CalendarDaysIcon, ClockIcon, IdentificationIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import moment from 'moment-mini';

import { AdNetworkOpportunityStatus } from '@/interfaces/ad_network/internal/opportunity';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';

interface Props {
  opportunity: AdNetworkOpportunity;
}

const Opportunity = ({ opportunity }: Props) => {
  const {
    payout_model: payoutModel,
    payout_per_click: payoutPerClick,
    payout_per_mille: payoutPerMille,
  } = opportunity;

  const payoutModelLabel = payoutModel === AdNetworkPayoutModel.CPC ? "Payout per click" : "Payout per 1k opens";
  const payoutRate = payoutModel === AdNetworkPayoutModel.CPC ? `${payoutPerClick}/click` : `${payoutPerMille}/1k opens`;

  return (
    <div className="bg-white rounded-md border">
      <div className="lg:col-start-3 lg:row-end-1 rounded-2xl bg-white">
        <h2 className="sr-only">Summary</h2>
        <div className="rounded-lg">
          <dl className="flex flex-wrap">
            <div className="grid grid-cols-2 p-6 gap-6">
              <div className="flex items-center">
                <figure>
                  <img
                    className="rounded-full h-8 object-contain"
                    src={opportunity.advertiser.logo_url}
                    alt={opportunity.advertiser.name}
                  />
                </figure>
              </div>
            </div>
            <div className="flex w-full flex-none gap-x-4 border-t border-gray-100 px-6 pt-6">
              <dt className="flex-none">
                <span className="sr-only">Advertiser</span>
                <IdentificationIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
              </dt>
              <dd className="text-sm leading-6 text-gray-900 space-y-2">
                <div>
                  <p className="font-medium">{opportunity.advertiser.name}</p>
                  <p className="text-gray-500 line-clamp-2">{opportunity.advertiser.description}</p>
                </div>
                <p className="text-gray-500">{opportunity.advertiser.hostname}</p>
              </dd>
            </div>
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Campaign Window</span>
                <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
              </dt>
              <dd className="text-sm leading-6 text-gray-500">
                {opportunity.status === AdNetworkOpportunityStatus.PENDING ? (
                  <>
                    <time dateTime={opportunity.campaign.window_start_date}>
                      {moment(opportunity.campaign.window_start_date).format('MMMM Do, YYYY')}
                    </time>
                    {' — '}
                    <time dateTime={opportunity.campaign.window_end_date}>
                      {moment(opportunity.campaign.window_end_date).format('MMMM Do, YYYY')}
                    </time>
                  </>
                ) : opportunity.selected_date ? (
                  <time dateTime={opportunity.selected_date}>
                    {moment(opportunity.selected_date).format('MMMM Do, YYYY')}
                  </time>
                ) : (
                  <span>No date selected</span>
                )}
              </dd>
            </div>
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">{payoutModelLabel}</span>
                <BanknotesIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
              </dt>
              <dd className="text-sm leading-6 text-gray-500">
                {payoutRate}{' '}
                {opportunity.estimated_payout_cents >= 1000 ? (
                  <span>(est. payout {opportunity.estimated_payout})</span>
                ) : (
                  '(unlimited earnings)'
                )}
              </dd>
            </div>

            {opportunity.status === AdNetworkOpportunityStatus.PENDING &&
              moment(opportunity.campaign.window_end_date).isAfter(moment()) && (
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Expiration</span>
                    <ClockIcon
                      className={cx(
                        'h-6 w-5',
                        moment().add(3, 'day').isAfter(moment(opportunity.deadline))
                          ? 'text-feedback-warning-400'
                          : 'text-gray-400'
                      )}
                      aria-hidden="true"
                    />
                  </dt>
                  <dd
                    className={cx(
                      'text-sm leading-6',
                      moment().add(3, 'day').isAfter(moment(opportunity.deadline))
                        ? 'text-feedback-warning-500'
                        : 'text-gray-500'
                    )}
                  >
                    Expires {moment(opportunity.deadline).fromNow()}
                  </dd>
                </div>
              )}
          </dl>
          <div className="mt-6 border-t border-gray-100 px-6 py-6 text-sm font-semibold text-gray-900 leading-6">
            View details
            {opportunity.status === AdNetworkOpportunityStatus.PENDING && <> &amp; respond </>}
            <span aria-hidden="true">&rarr;</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opportunity;
