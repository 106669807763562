import { FC, useCallback } from 'react';
import { JSONContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';

import { ImageUploader } from './ImageUploader';

export const ImageUpload: FC<NodeViewProps> = ({ getPos, editor, node }) => {
  const onUpload = useCallback(
    (payload: OnMediaPayload) => {
      const { media: asset } = payload;

      const from = getPos();
      const to = from + node.nodeSize;

      const jsonContent: JSONContent = {
        type: 'imageBlock',
        attrs: {
          id: asset.id,
          title: asset.title,
          captionUrl: asset.source_link || '',
          alt: asset.alt,
        },
      };

      editor.chain().insertContentAt({ from, to }, jsonContent).focus().run();
    },
    [editor, getPos, node]
  );

  return (
    <NodeViewWrapper>
      <div
        data-drag-handle
        {...{ inert: editor.isEditable ? undefined : '' }}
        className="m-0 p-0"
        contentEditable={false}
      >
        <ImageUploader onUpload={onUpload} />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
