import { useCallback, useRef } from 'react';
import { Menu } from '@headlessui/react';
import { JSONContent } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import moment from 'moment-mini';

import { suggestions } from '@/components/CommentEditor/suggestions';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Avatar } from '@/components/UI/Avatar';
import { useCurrentUser } from '@/context/current-user-context';
import { useUser } from '@/hooks';

// import './ThreadCard.css';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

export type CommentProps = {
  id: string;
  isFirst?: boolean;
  authorId: string;
  content: JSONContent;
  createdAt: number;
  onDelete: (id: string) => void;
};

export const Comment = ({ createdAt, authorId, content, id, isFirst, onDelete }: CommentProps) => {
  const {data: author, isLoading: isAuthorLoading} = useUser(authorId);
  const { currentUser } = useCurrentUser();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const getWrapperRef = useCallback(() => wrapperRef.current || document.body, []);

  const previewEditor = useEditor({
    content,
    extensions: [
      CommentDocument,
      Text,
      Paragraph,
      Bold,
      Italic,
      Mention.configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(getWrapperRef),
      }),
    ],
    editable: false,
    editorProps: {
      attributes: {
        class: 'comment-preview',
      },
    },
  });

  const handleDeleteClick = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <div className="flex items-start" ref={wrapperRef}>
      <div className="flex-none mr-2">
        <Avatar.Wrapper isLoading={isAuthorLoading && !author} fallback={author?.name}>
          {author?.profile_picture?.url ? (
            <Avatar.Image src={author.profile_picture?.url} alt={author.name} />
          ) : null}
        </Avatar.Wrapper>
      </div>
      <div>
        <div className="flex items-center justify-start gap-1">
          {isAuthorLoading && !author ? (
            <div className="h-[1em] text-xs w-12 animate-pulse bg-neutral-200 rounded-md" />
          ) : null}
          {author ? (
            <div className="text-xs leading-[1.1] font-semibold text-black">{author.name}</div>
          ) : null}
          <div className="text-xs leading-[1.1] font-semibold text-neutral-400">{moment(createdAt).fromNow()}</div>
        </div>
        <div className="mt-1 text-sm">
          <EditorContent editor={previewEditor} />
        </div>
      </div>
      {currentUser?.id === authorId && !isFirst && (
        <div className="flex-none ml-auto">
          <Menu as="div" className="relative">
            <Menu.Button className="w-[1.875rem] h-[1.875rem] flex items-center justify-center hover:bg-neutral-50">
              <Icon name="DotsVertical" className="w-3.5 h-3.5" />
            </Menu.Button>
            <Menu.Items className="absolute right-0 w-48 p-2 text-white rounded-lg shadow-sm bg-surface-800 top-full z-10">
              <Menu.Item>
                <button
                  className="flex items-center w-full gap-2 p-1 text-sm font-medium bg-transparent rounded hover:bg-white hover:bg-opacity-10 text-neutral-200 hover:text-white"
                  type="button"
                  onClick={handleDeleteClick}
                >
                  <Icon name="Trash" className="w-4 h-4 text-white" />
                  Delete comment
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      )}
    </div>
  );
};
