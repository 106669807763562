import { useSearchParams } from "react-router-dom";

import analytics from "@/utils/analytics";

import useFetchUpgradeAnalytics from "./useFetchUpgradeAnalytics";

const useAnalyticsTracking = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get('success');
  const signature = searchParams.get('signature');

  const { data, isSuccess } = useFetchUpgradeAnalytics({ shouldFetch: success === 'true', signature });

  if (success === 'true' && isSuccess) {
    analytics.track('Plan Update', {
      email: data.email,
      userId: data.userId,
      organizationId: data.organizationId,
      updateDetails: {
        firstUpgrade: data.updateDetails.firstUpgrade,
        type: data.updateDetails.type,
        method: data.updateDetails.method,
        lastPlan: data.updateDetails.lastPlan,
        lastPlanId: data.updateDetails.lastPlanId,
        currentPlan: data.updateDetails.currentPlan,
        currentPlanId: data.updateDetails.currentPlanId,
        frequency: data.updateDetails.frequency,
        price: data.updateDetails.price,
        discount: data.updateDetails.discount,
        revenue: data.updateDetails.revenue,
        coupon: data.updateDetails.coupon,
        churnType: data.updateDetails.churnType,
        renewalDate: data.updateDetails.renewalDate,
      },
    });
    searchParams.delete('success');
    searchParams.delete('signature');
    setSearchParams(searchParams);
  }
}

export default useAnalyticsTracking;
