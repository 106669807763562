import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { DocumentPlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCopyToClipboard } from '@/hooks';
import { useRssFeeds } from '@/hooks/useRssFeeds';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { Section } from '../../../Components';
import NotEditing from '../../../Components/NotEditing';

const RSSFeed = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const rssFeedsQuery = useRssFeeds();
  const { data, isLoading, isFetching, isError } = rssFeedsQuery;
  const copy = useCopyToClipboard();

  const hasRssFeed = !!data?.length;

  const disableMutation = useMutation(
    ({ id }: { id: string }) =>
      api
        .delete(`/rss_feeds/${id}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('RSS feed deleted');
        rssFeedsQuery.refetch();
      },
    }
  );

  const enableMutation = useMutation(
    () =>
      api
        .post(`/rss_feeds`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        analytics.track('Generated an RSS Feed');
        toast.success('RSS feed generated');
        rssFeedsQuery.refetch();
      },
    }
  );

  const feeds = data || [];

  return (
    <Section
      title="RSS Feed"
      description="An RSS feed allows you to sync posts with a custom website. For example, if you are using Wordpress (or
              similar) you can use a plugin to pull in posts and display them on your website."
      scrollToId="rss_feed"
      isLoading={isFetching}
      hasDivider={false}
    >
      <LoadingBox isLoading={isLoading} isError={isError}>
        {hasRssFeed ? (
          <>
            {feeds.map((feed) => (
              <div key={feed.id}>
                <div className="flex justify-between items-center">
                  <NotEditing
                    label="RSS Feed URL"
                    value={feed.url}
                    helperText={`Last updated ${moment(feed.updated_at).fromNow()}`}
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() => copy({ text: feed.url })}
                      className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded hover:bg-gray-100"
                    >
                      <span className="font-semibold text-sm">Copy</span>
                    </button>
                  </div>
                </div>

                <div className="col-span-4 mt-6">
                  <Button variant="danger" onClick={() => disableMutation.mutate({ id: feed.id })}>
                    <span className="flex items-center">
                      {disableMutation.isLoading ? (
                        <>
                          Deleting... <LoadingSpinner color="white" className="ml-2" size="sm" />
                        </>
                      ) : (
                        <>
                          <TrashIcon className="w-4 h-4 mr-2 text-red-500" />
                          Delete Feed
                        </>
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Button variant="primary" onClick={() => enableMutation.mutate()}>
            <span className="flex items-center">
              {enableMutation.isLoading ? (
                <>
                  Generating... <LoadingSpinner color="white" className="ml-2" size="sm" />
                </>
              ) : (
                <>
                  <DocumentPlusIcon className="w-4 h-4 mr-2" /> Generate Feed
                </>
              )}
            </span>
          </Button>
        )}
      </LoadingBox>
    </Section>
  );
};

export default RSSFeed;
