import Downgrade from '@/pages/Settings/Pages/Downgrade';
import PageContainer from '@/routes/settings/publication/components/PageContainer';

const WorkspaceBillingAndPlanDowngrade = () => {
  return (
    <PageContainer>
      <Downgrade />
    </PageContainer>
  );
};

export default WorkspaceBillingAndPlanDowngrade;
