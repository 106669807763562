import React from 'react';
import { Info } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

import { Text } from './Text';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const Callout: React.FC<Props> = ({ title, children, className }) => {
  return (
    <div className={cn('border border-wb-hr p-4 rounded-lg w-full max-w-xl flex space-x-2', className)}>
      <div>
        <Info size={20} className=" text-wb-accent" />
      </div>
      <div className="flex flex-col">
        <Text size="xs" weight="semibold" variant="primary" as="span">
          {title}
        </Text>
        <Text size="xs" weight="medium" variant="secondary" as="span">
          {children}
        </Text>
      </div>
    </div>
  );
};

export default Callout;
