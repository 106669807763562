import Badge, { BadgeProps } from '@/components/Badge';
import BoostAgreementStatus from '@/interfaces/boosts/grow/boost_agreement_status';

const badgeAttributesByType: {
  [key in BoostAgreementStatus]: {
    type: BadgeProps['type'];
    label: string;
  };
} = {
  [BoostAgreementStatus.ACTIVE]: {
    type: 'success',
    label: 'Live',
  },
  [BoostAgreementStatus.PENDING]: {
    type: 'warning',
    label: 'Pending',
  },
  [BoostAgreementStatus.REJECTED]: {
    type: 'information',
    label: 'Rejected',
  },
  [BoostAgreementStatus.INACTIVE]: {
    type: 'warning',
    label: 'Inactive',
  },
  [BoostAgreementStatus.ARCHIVED]: {
    type: 'information',
    label: 'Archived',
  },
  [BoostAgreementStatus.PAUSED]: {
    type: 'warning',
    label: 'Paused',
  },
  [BoostAgreementStatus.FROZEN]: {
    type: 'warning',
    label: 'Paused',
  },
};

interface Props {
  status: BoostAgreementStatus;
}

const BoostAgreementStatusBadge = ({ status }: Props) => (
  <Badge className="capitalize" type={badgeAttributesByType[status].type}>
    {badgeAttributesByType[status].label}
  </Badge>
);

export default BoostAgreementStatusBadge;
