import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Paywall } from '@/interfaces/paywall';

import api from '../../services/swarm';

interface Props {
  paywallId: string;
  defaultPremiumPaywall: boolean;
}

export default function usePaywall({ paywallId, defaultPremiumPaywall }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPaywall = () =>
    api
      .get(`/paywalls/${paywallId}`, {
        params: {
          publication_id: currentPublicationId,
          default_premium_paywall: defaultPremiumPaywall,
        },
      })
      .then((res) => res.data);

  return useQuery<Paywall>({
    queryKey: [defaultPremiumPaywall, 'publications', currentPublicationId, 'paywall', paywallId],
    queryFn: fetchPaywall,
  });
}
