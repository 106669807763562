import { useState } from 'react';
import { ArchiveBoxXMarkIcon, EnvelopeIcon, UserCircleIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import Badge, { BadgeProps } from '@/components/Badge';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCancelInvite, useSendInvite } from '@/hooks/useInvites';
import { useCurrentOrganizationId, useOrganization } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { TeamMember } from '@/interfaces/team_member';
import { Button } from '@/ui/Button';

import { ASSIGNABLE_LEVEL_NAMES } from './constants';
import EditMember from './edit';

interface Props {
  member: TeamMember;
  onResendInvite: () => void;
  onCancelInvite: () => void;
  onUpdate: () => void;
}

const BADGE_TYPE_BY_STATUS: Record<TeamMember['status'], BadgeProps['type']> = {
  pending: 'warning',
  active: 'information',
  declined: 'alert',
  expired: 'alert',
};

const MemberListItem = ({ member, onResendInvite, onCancelInvite, onUpdate }: Props) => {
  const organizationId = useCurrentOrganizationId();
  const publicationId = useCurrentPublicationId();
  const { data: organizationData } = useOrganization(organizationId, publicationId);
  const { organization } = organizationData || {};
  const [confirmResendInvite, setConfirmResendInvite] = useState<boolean>(false);
  const [confirmCancelInvite, setConfirmCancelInvite] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const resendMutation = useSendInvite(organizationId);
  const handleResendInvite = async () => {
    await resendMutation.mutateAsync({
      invite: {
        invited_email: member.email,
        role_id: String(member.role_id),
        invitable_type: member.assignable_type,
        invitable_id: member.assignable_id,
      },
      publicationId,
    });
    setConfirmResendInvite(false);
    onResendInvite();
  };

  const cancelMutation = useCancelInvite(organizationId, publicationId, String(member.id));
  const handleCancelInvite = async () => {
    const today = new Date();
    await cancelMutation.mutateAsync({
      invitable_type: member.assignable_type,
      invitable_id: member.assignable_id,
      declined_at: today.toISOString(),
    });
    setConfirmCancelInvite(false);
    onCancelInvite();
  };

  const handleCloseEditForm = () => {
    setIsEditing(false);
    onUpdate();
  };

  return (
    <>
      <ActionModal
        isOpen={confirmResendInvite}
        onClose={() => setConfirmResendInvite(false)}
        onProceed={handleResendInvite}
        resourceId={`resend-invite-${member.id}`}
        disabled={resendMutation.isLoading}
        isWorking={resendMutation.isLoading}
        headerText="Resend Invite"
        descriptionText="Are you sure you want to resend this team invite? You can always cancel it later."
        actionText="Resend"
        modalMessageType="info"
      />
      <ActionModal
        isOpen={confirmCancelInvite}
        onClose={() => setConfirmCancelInvite(false)}
        onProceed={handleCancelInvite}
        resourceId={`cancel-invite-${member.id}`}
        disabled={cancelMutation.isLoading}
        isWorking={cancelMutation.isLoading}
        headerText="Cancel Invite"
        descriptionText="Are you sure you want to cancel this invite? While this action cannot be undone, you can always send a new invite."
        actionText="Cancel"
        modalMessageType="danger"
        buttonShade="light"
      />
      <EditMember
        isOpen={isEditing}
        onClose={handleCloseEditForm}
        name={member.name}
        email={member.email}
        assignmentId={parseInt(member.id, 10)}
        assignmentUserId={member.user_id || undefined}
        imageUrl={member.profile_picture_url || ''}
        currentRoleOption={{
          object_id: member.assignable_id,
          object_type: member.assignable_type,
          role_id: parseInt(member.role_id, 10),
          name: member.role_name,
        }}
        roleOptions={organization.role_options || []}
        publicationId={publicationId}
      />
      <ItemRow>
        <ItemColumn align={AlignType.LEFT}>
          <div className="flex flex-col sm:flex-row items-center">
            <div className="w-10 h-10 overflow-hidden rounded-full box-content mr-4 flex items-center justify-center bg-surface-100">
              {member.profile_picture_url ? (
                <img src={member.profile_picture_url} className="object-cover" alt="Member Profile" />
              ) : (
                <UserCircleIcon className="h-5 w-5" />
              )}
            </div>
            <TypographyStack gap="1" className="px-4">
              {member.name ? <Typography token="font-medium/text/xs">{member.name}</Typography> : null}
              <Typography token="font-light/text/xs" colorWeight="500">
                {member.email}
              </Typography>
            </TypographyStack>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          {member.source === 'invite' ? (
            <div className="flex flex-row gap-x-2 items-center">
              <div className="px-4">
                <Badge className="h-fit capitalize" type={BADGE_TYPE_BY_STATUS[member.status]}>
                  {member.status}
                </Badge>
              </div>
              {member.status === 'expired' ? (
                <Button
                  variant="primary-inverse"
                  size="xs"
                  onClick={() => setConfirmResendInvite(true)}
                  Icon={EnvelopeIcon}
                  className="mx-4"
                >
                  Resent Invite
                </Button>
              ) : null}
              {member.status === 'pending' ? (
                <Button
                  variant="danger"
                  shade="light"
                  size="xs"
                  onClick={() => setConfirmCancelInvite(true)}
                  Icon={ArchiveBoxXMarkIcon}
                  className="mx-4"
                >
                  Cancel Invite
                </Button>
              ) : null}
            </div>
          ) : null}
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Badge className="capitalize">{member.permission}</Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Badge>{ASSIGNABLE_LEVEL_NAMES[member.role_name]}</Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-normal/text/sm" colorWeight="500" className="capitalize">
            {member.publication_name}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          {member.source === 'assignment' ? (
            <EllipsisDropdown
              options={[
                {
                  label: 'Edit',
                  onClick: () => setIsEditing(true),
                },
              ]}
            />
          ) : null}
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default MemberListItem;
