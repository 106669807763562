import { CountryInput, Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useUpdateOrganization } from '@/hooks';
import { Organization } from '@/interfaces/organization';
import countryData from '@/utils/countries.json';

import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';
import FormContentPreviewContainer from '../../publication/components/FormContentPreviewContainer';
import PoweredByBeehiiv from '../../publication/components/PoweredByBeehiiv';

interface Props {
  organization: Organization;
  publicationId: string;
}

const streetAddressPlaceholder = '228 Park Ave S, #29976';
const statePlaceholder = 'New York';
const cityPlaceholder = 'New York';
const zipCodePlaceholder = '10003';
const countryNamePlaceholder = 'United States';

const AddressForm = ({ organization, publicationId }: Props) => {
  const selectedCountry = countryData.find(
    (country: { code: string; name: string }) => country.code === organization.country
  );

  const { mutate: updateOrganization } = useUpdateOrganization(organization.id, publicationId);
  return (
    <BodyContainer>
      <CardHeader
        title="Global Email Footer Address"
        description="You can override this in your specific publications by going to the publication emails tab"
      />
      <Input
        labelText="Street Address"
        placeholder="228 Park Ave S, #29976"
        name="street_address"
        defaultValue={organization.street_address}
        onBlur={(e) => {
          updateOrganization({
            street_address: e.currentTarget.value,
          });
        }}
      />
      <div className="grid grid-rows-2 grid-cols-2 gap-y-6 gap-x-4">
        <Input
          labelText="City"
          placeholder="New York"
          name="city"
          defaultValue={organization.city}
          onBlur={(e) => {
            updateOrganization({
              city: e.currentTarget.value,
            });
          }}
        />
        <Input
          labelText="State"
          placeholder="New York"
          name="state"
          defaultValue={organization.state}
          onBlur={(e) => {
            updateOrganization({
              state: e.currentTarget.value,
            });
          }}
        />
        <Input
          labelText="Postal Code"
          placeholder="10003"
          name="state"
          defaultValue={organization.zip_code}
          onBlur={(e) => {
            updateOrganization({
              zip_code: e.currentTarget.value,
            });
          }}
        />
        <CountryInput
          value={organization.country || 'US'}
          labelText="Country"
          name="country"
          onChange={(value) =>
            updateOrganization({
              country: value,
            })
          }
        />
      </div>
      <FormContentPreviewContainer className="flex flex-col gap-y-4 justify-center items-center">
        <div className="flex flex-col w-full text-center">
          <Typography token="font-medium/text/sm">{organization.street_address || streetAddressPlaceholder}</Typography>
          <Typography token="font-medium/text/sm">
            {organization.city || cityPlaceholder}, {organization.state || statePlaceholder}{' '}
            {organization.zip_code || zipCodePlaceholder}, {selectedCountry?.name || countryNamePlaceholder}
          </Typography>
        </div>
        <PoweredByBeehiiv />
      </FormContentPreviewContainer>
    </BodyContainer>
  );
};

export default AddressForm;
