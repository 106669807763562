import { SurveyElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useDebounce } from 'use-debounce';

import { usePaginatedForms } from '@/hooks/useForms';
import { Form } from '@/interfaces/form';
import FormSearch from '@/models/formSearch';

import { SelectionItem, SelectionModal, useSelectionModal } from '../../../SelectionModal';

const SurveySelectionModal = ({ editor, node }: Partial<NodeViewProps>) => {
  const { isOpen, onOpen, onClose, selections, setSelections, searchTerm, setSearchTerm } = useSelectionModal<Form>();

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const query = new FormSearch({
    search: debouncedSearchTerm,
  });
  const paginatedFormsQuery = usePaginatedForms(query);
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } = paginatedFormsQuery;
  const surveys: Form[] = data?.pages.flatMap((page) => page.forms) || [];
  const hasSurveys = surveys.length > 0;

  const handleSubmit = () => {
    const selection = selections?.[0];
    if (selection && editor) {
      const updatedAttributes = {
        ...node?.attrs,
        data: selection,
      };

      editor.chain().focus().updateAttributes('survey', updatedAttributes).run();
      onClose();
    }
  };

  return (
    <SelectionModal
      isOpen={isOpen}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      multipleSelectionsAllowed={false}
      onOpen={onOpen}
      onClose={onClose}
      title="Surveys"
      onSubmit={handleSubmit}
      showingCount={surveys.length}
      totalCount={data?.pages[0]?.pagination?.total || 0}
      selectedCount={selections.length}
      isLoading={isLoading}
      isFetching={isFetching}
      hasNextPage={Boolean(hasNextPage)}
      buttonText="Select Survey"
      placeholderText="Search surveys..."
      newItemText="New Survey"
      noResults={!isLoading && !hasSurveys}
      onLoadMore={() => {
        fetchNextPage();
      }}
    >
      {surveys?.map((survey) => {
        const isSelected = Boolean(selections.some((p) => p.id === survey.id));

        return (
          <SelectionItem
            key={survey.id}
            title={survey.title}
            description={survey.description}
            isSelected={isSelected}
            onSelect={() => {
              if (isSelected) {
                setSelections([]);
              } else {
                setSelections([survey]);
              }
            }}
          >
            <SurveyElement
              element={{
                type: 'survey',
                content: [{ text: '', id: 'text' }],
                attrs: {
                  id: survey.id,
                  data: survey,
                },
              }}
            >
              {null}
            </SurveyElement>
          </SelectionItem>
        );
      })}
    </SelectionModal>
  );
};

export default SurveySelectionModal;
