import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import styled from 'styled-components';

import IconButton from '@/components/IconHelpers/IconButton';
import { PendingOpportunitiesBanner } from '@/components/PendingOpportunitiesBanner';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { WalkthroughPortal } from '@/components/Walkthrough';
import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useWalkthroughContext, WalkthroughProvider } from '@/context/walkthrough-context';
import { useOrganization, useUser } from '@/hooks';
import useCurrentUserOrganizations from '@/hooks/useCurrentUserOrganizations';
import useFreeTrial from '@/hooks/useFreeTrial';
import { useCurrentPublication, usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';
import { IUser, UserWithRoles } from '@/interfaces/user';
import PageContainer from '@/routes/settings/publication/components/PageContainer';
import useIsMobile from '@/ui/hooks/useIsMobile';

import GlobalBanner from '../../GlobalBanner';
import MasqueradeBanner from '../../MasqueradeBanner';

import EmailConfirmationBanner from './Banners/EmailConfirmationBanner';
import SubscriberLimitBanner from './Banners/SubscriberLimitBanner';
import Navigation from './DesktopNavbar/Navigation';
import LockedAccountView from './Navbar/LockedAccountView';
import { APP_LAYOUT_BODY_CONTAINER_ID, BODY_HEIGHT_WITH_TOP_BAR, SETTINGS_BODY_CONTAINER_ID } from './constants';
import MainNav from './MainNav';
import MultiplePublicationSettings from './MultiplePublicationSettings';
import TopBar from './TopBar';

const NavFiller: any = styled.div`
  width: ${({ isWide, width }: any) => width || (isWide ? '230px' : '60px')};
  min-width: ${({ isWide }: any) => (isWide ? '230px' : '60px')};
  height: 100vh;
  transition: width 0.2s ease-in-out, min-width 0.2s ease-in-out;
  display: block;

  @media (max-width: 640px) {
    width: 0px;
    min-width: 0px;
    display: block;
  }

  @media print {
    display: none;
  }
`;

const MainNavWrapper = ({ isAdmin, largeNavOpen }: any) => {
  const { currentStep, navigationStep, handleNextStep, handlePreviousStep, handleFinishWalkthrough } =
    useWalkthroughContext();
  const isLastStep = navigationStep?.currentStep === 7;

  return (
    <WalkthroughPortal
      isActive={currentStep === navigationStep?.currentStep}
      title={navigationStep?.title || ''}
      description={navigationStep?.description || ''}
      arrowDirection="left"
      positionClassNameOverride={navigationStep?.positionClassNameOverride || ''}
      arrowPositionOverride={navigationStep?.arrowPositionOverride || ''}
      hasOverlay
      currentStep={navigationStep?.currentStep || 0}
      totalSteps={7}
      continueButton={{
        text: isLastStep ? 'Finish' : 'Next',
        onClick: () => handleNextStep(navigationStep?.nextPath),
      }}
      backButton={{
        text: 'Back',
        onClick: () => handlePreviousStep(navigationStep?.backPath),
      }}
      onClose={handleFinishWalkthrough}
    >
      <div className="z-20 print:hidden">
        <MainNav isAdmin={isAdmin} largeNavOpen={largeNavOpen} />
      </div>
    </WalkthroughPortal>
  );
};

const getMetaFields = (
  user: IUser | UserWithRoles,
  isTrialActive: boolean,
  currentPublication: Publication,
  org: Organization
) => {
  return {
    user_id: user.id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    channel: 'ada-in-app',
    hashed_email: user.ada_token,
    currently_in_trial: isTrialActive,
    has_premium_newsletter: currentPublication.is_premium_enabled,
    plan_name: currentPublication.plan_name,
    organization_id: currentPublication.organization_id,
    publication_id: currentPublication.id,
    publication_name: currentPublication.name,
    organization_name: org.name,
  };
};

const toggleAdaChatButton = (adaWidgetEnabled: boolean | undefined) => {
  if (adaWidgetEnabled === true) {
    document.querySelector('#nav-ada-chat-button')?.classList.add('!block');
  } else {
    document.querySelector('#nav-ada-chat-button')?.classList.remove('!block');
  }
};

const resetMetaFields = async (
  user: IUser | UserWithRoles,
  isTrialActive: boolean,
  currentPublication: Publication | undefined,
  org: Organization,
  adaWidgetEnabled: boolean | undefined
) => {
  try {
    if (user && currentPublication && org && adaWidgetEnabled) {
      await window.adaEmbed.setMetaFields(getMetaFields(user, isTrialActive, currentPublication, org));
      toggleAdaChatButton(adaWidgetEnabled);
    }
  } catch {
    // console.error('Error resetting Ada Embed:', error);
  }
};

const AppLayout = () => {
  const location = useLocation();
  const { isSettingsPages, hasTopPadding, hasFullHeight, disableView, largeNavOpen } = useAppLayout();

  const [isMobileSettingsNavOpen, setIsMobileSettingsNavOpen] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const userQuery = useUser(currentUser?.id);
  const user = userQuery?.data;
  const { data: org } = useOrganization(currentPublication?.organization_id, currentPublication?.id || '');
  const { isTrialActive } = useFreeTrial();

  const isNotifications = location.pathname.startsWith('/notifications');
  const isSysAdmin = settings?.system_admin;
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;
  const shouldRenderSettingsV2 = Boolean(hasPublicationSettingsV2 && isSettingsPages);
  const isOrgOrPubAdmin = user?.roles.some((role) => role.name === 'admin');
  const isAdmin = isSysAdmin || isOrgOrPubAdmin;

  // For settings v2
  const publicationIdInUrl = useCurrentPublicationId();
  const { data: publicationFromUrl } = usePublication(publicationIdInUrl);
  const {
    data: organizationsData,
    isLoading: isLoadingWorkspaces
  } = useCurrentUserOrganizations(currentUser?.id || '', shouldRenderSettingsV2);
  const organizations = organizationsData?.organizations || [];

  const defaultTitle = currentPublication ? `${currentPublication.name} - beehiiv` : 'beehiiv';

  const isMobile = useIsMobile();

  useEffect(() => {
    // This  useEffect gets call to often to be able to close the chat on pub switch so i think the best we can do is update the metaFields
    const startAdaEmbed = async () => {
      try {
        if (user && currentPublication && org && settings?.ada_widget) {
          await window.adaEmbed.start({
            handle: 'beehiiv',
            metaFields: getMetaFields(user, isTrialActive, currentPublication, org.organization),
          });
        }

        toggleAdaChatButton(settings?.ada_widget);
      } catch {
        resetMetaFields(
          user as UserWithRoles,
          isTrialActive,
          currentPublication,
          org.organization,
          settings?.ada_widget
        );
      }
    };

    startAdaEmbed();
  }, [user, isTrialActive, currentPublication, org, settings?.ada_widget]);

  const canShowTopBar = !isSettingsPages && settings?.navigation;

  if (shouldRenderSettingsV2) {
    return (
      <WalkthroughProvider>
        <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />

        {publicationFromUrl ? (
          <SubscriberLimitBanner
            key={`${publicationFromUrl.organization_id}-${publicationFromUrl.id}`}
            organization_id={publicationFromUrl.organization_id}
            publicationId={publicationFromUrl.id}
          />
        ) : null}
        <MasqueradeBanner org={org?.organization} />
        <EmailConfirmationBanner />
        <GlobalBanner />
        <PendingOpportunitiesBanner />

        {/* Main */}
        <div
          id={APP_LAYOUT_BODY_CONTAINER_ID}
          className={classNames('w-screen h-screen max-w-screen max-h-screen overflow-hidden flex flex-row')}
        >
          <div
            className={classNames('flex flex-col', isMobileSettingsNavOpen ? 'w-full bg-[#111827]/20 fixed z-50' : '')}
          >
            {!isMobile ? (
              <div className="w-full h-[56px] border-b border-surface-200">
                <div className="h-full w-[61px] border-r border-surface-200">&nbsp;</div>
              </div>
            ) : null}
            {isMobile && isMobileSettingsNavOpen ? (
              <div className="w-[290px] bg-white border-b border-surface-200">
                <div className="flex items-center justify-center grow-0 shrink-0 w-[50px] h-[50px] visible sm:hidden">
                  <IconButton onClick={() => setIsMobileSettingsNavOpen(!isMobileSettingsNavOpen)}>
                    <Bars3Icon />
                  </IconButton>
                </div>
              </div>
            ) : null}
            {isMobile ? (
              <div
                className={classNames(
                  isMobileSettingsNavOpen ? 'block' : 'hidden sm:block',
                  'flex flex-row h-[calc(100vh_-_54px)]'
                )}
              >
                <Navigation largeNavOpen={false} showPublicationDropdown={false} className="flex w-[60px] bg-white" />
                <MultiplePublicationSettings
                  organizations={organizations}
                  onLinkClick={() => setIsMobileSettingsNavOpen(false)}
                  isLoading={isLoadingWorkspaces}
                />
              </div>
            ) : (
              <div className="flex flex-row h-[calc(100vh_-_54px)]">
                <Navigation largeNavOpen={false} showPublicationDropdown={false} className="flex w-[60px] bg-white" />
                <MultiplePublicationSettings
                  organizations={organizations}
                  onLinkClick={() => setIsMobileSettingsNavOpen(false)}
                  isLoading={isLoadingWorkspaces}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col w-full">
            {isMobile ? (
              <div className="flex flex-row border-b border-surface-200 overflow-hidden">
                {!isMobileSettingsNavOpen && (
                  <div className="flex items-center justify-center grow-0 shrink-0 w-[50px] h-[50px] block sm:hidden">
                    <IconButton onClick={() => setIsMobileSettingsNavOpen(!isMobileSettingsNavOpen)}>
                      <Bars3Icon />
                    </IconButton>
                  </div>
                )}
                <TopBar largeNavOpen={largeNavOpen} />
              </div>
            ) : (
              <TopBar largeNavOpen={largeNavOpen} className="border-b border-surface-200" />
            )}
            <div
              className="w-full h-screen overflow-y-scroll overflow-x-hidden bg-surface-50"
              id={SETTINGS_BODY_CONTAINER_ID}
            >
              {disableView ? <LockedAccountView /> : (
                <SkeletonLoader
                  isLoading={isLoadingWorkspaces}
                  skeletons={
                    <div className="flex flex-row w-full justify-center">
                      <PageContainer>
                        <Skeleton className="h-44" />
                        <Skeleton className="h-44" />
                      </PageContainer>
                    </div>
                  }
                >
                  <Outlet />
                </SkeletonLoader>
              )}
            </div>
          </div>
        </div>
      </WalkthroughProvider>
    );
  }

  return (
    <WalkthroughProvider>
      <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />

      {currentPublication && (
        <SubscriberLimitBanner
          organization_id={currentPublication.organization_id}
          publicationId={currentPublication.id}
        />
      )}
      <MasqueradeBanner org={org?.organization} />
      <EmailConfirmationBanner />
      <GlobalBanner />
      <PendingOpportunitiesBanner />

      {/* Main */}
      <div
        className={classNames(
          'h-full flex flex-col min-h-screen relative',
          isSettingsPages ? 'bg-white' : 'bg-gray-100'
        )}
      >
        <MainNavWrapper isAdmin={isAdmin} largeNavOpen={largeNavOpen} />
        <div className="flex max-w-screen">
          <NavFiller isWide={isSettingsPages ? true : largeNavOpen} />

          {disableView ? (
            <LockedAccountView />
          ) : (
            <div
              id={APP_LAYOUT_BODY_CONTAINER_ID}
              className={classNames(
                'w-full overflow-auto h-screen max-h-screen relative',
                isSettingsPages || isNotifications ? 'bg-white' : 'bg-surface-50'
              )}
            >
              {canShowTopBar && (
                <div className="sticky sm:block hidden top-0 z-50 bg-white">
                  <TopBar largeNavOpen={largeNavOpen} />
                </div>
              )}
              <div
                className={classNames({
                  'py-6': hasTopPadding,
                  [BODY_HEIGHT_WITH_TOP_BAR]: hasFullHeight,
                })}
              >
                <Outlet />
              </div>
            </div>
          )}
        </div>
      </div>
    </WalkthroughProvider>
  );
};

export default AppLayout;
