import { useInfiniteQuery } from 'react-query';

import { Pagination } from '@/interfaces/general';
import { TeamMember } from '@/interfaces/team_member';
import api from '@/services/swarm';

interface ApiResponse {
  team_members: TeamMember[];
  pagination: Pagination;
}

export type OrderBy = 'user' | 'permission' | 'role' | 'publication' | 'created_at';

interface Props {
  organizationId?: string;
  search?: string;
  orderBy?: OrderBy;
  direction?: 'asc' | 'desc';
}

const useTeamMembers = ({ organizationId, search, orderBy, direction = 'desc' }: Props = {}) => {
  return useInfiniteQuery<ApiResponse>(
    [organizationId, 'team_members', search, orderBy],
    ({ pageParam = 1 }) =>
      api
        .get(`organizations/${organizationId}/team_members`, {
          params: {
            page: pageParam,
            per_page: 10,
            q: search,
            order: orderBy,
            direction,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useTeamMembers;
