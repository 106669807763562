import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { IUser } from '@/interfaces/user';
import User from '@/models/user';
import api from '@/services/swarm';

interface IUserContext {
  currentUser?: User;
  isLoading: boolean;
  reloadUser: () => Promise<void>;
}

const CurrentUserContext = createContext<IUserContext | undefined>(undefined);

CurrentUserContext.displayName = 'CurrentUserContext';

const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User>();

  function fetchCurrentUser(): Promise<IUser> {
    return api.get('/current_user').then((res) => res.data);
  }

  const reloadUser = useCallback(async () => {
    const user = await fetchCurrentUser();
    setCurrentUser(new User(user));
  }, []);

  useEffect(() => {
    reloadUser();
  }, [reloadUser]);

  const contextProviderProps = useMemo(() => (
    {reloadUser, currentUser, isLoading: !currentUser}
  ), [currentUser, reloadUser]);

  return (
    <CurrentUserContext.Provider value={contextProviderProps}>
      {children}
    </CurrentUserContext.Provider>
  );
};

function useCurrentUser() {
  const context = React.useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error(`useCurrentUser must be used within a CurrentUserProvider`);
  }
  return context;
}

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
