import { PropsWithChildren, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, GearSix, SidebarSimple } from '@phosphor-icons/react';

import { DeviceGate } from '@/ui/DeviceGate';

import { cn } from '../../_utils/cn';
import { PublishDropDown } from '../PublishDropDown';
import { SideNav } from '../SideNav';
import { Button } from '../UI/Button';
import { Tabs } from '../UI/Tabs';

import { BackButton } from './BackButton';
import { LogoDropDown } from './LogoDropDown';
import { NavbarTitle } from './NavbarTitle';
import { TopNav } from './TopNav';

type LogoDropdownProps = {
  actionText?: string;
  backPath?: string;
};

type Props = PropsWithChildren<{
  sidenavChildren?: React.ReactNode;
  logoDropdownProps?: LogoDropdownProps;
  showBackButton?: boolean;
  backPath?: string;
  isSidebarResizable?: boolean;
  leftChildren?: React.ReactNode;
  rightSideChildren?: React.ReactNode;
  titleType?: 'publication_name' | 'page_name';
  mainClassName?: string;
  rightChildren?: React.ReactNode;
}>;

export const Layout = ({
  children,
  sidenavChildren,
  backPath,
  logoDropdownProps,
  showBackButton,
  isSidebarResizable,
  leftChildren,
  rightSideChildren,
  titleType = 'publication_name',
  mainClassName,
  rightChildren,
}: Props) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();

  return (
    <DeviceGate reason="The feature is currently not supported on mobile devices">
      <div className="h-screen w-screen flex flex-col">
        <TopNav>
          {/* center */}
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center">
            <NavbarTitle type={titleType} />
          </div>

          {/* left */}
          <div className="relative flex justify-start gap-6">
            {showBackButton && (
              <BackButton
                onClick={() => {
                  navigate(backPath || '/website_builder_v2');
                }}
              />
            )}
            <div className="flex items-center gap-1.5">
              {logoDropdownProps && (
                <LogoDropDown actionText={logoDropdownProps.actionText} backPath={logoDropdownProps.backPath} />
              )}
              {isSidebarResizable && (
                <Button variant="ghost" Icon={SidebarSimple} onClick={() => setIsSidebarCollapsed((prev) => !prev)} />
              )}
            </div>
            {leftChildren}
          </div>

          {/* right */}
          <div className="relative flex justify-end gap-2">
            {rightChildren}
            <Link to="/website_builder_v2/settings">
              <Button variant="secondary" Icon={GearSix} iconWeight="fill" className="h-full" />
            </Link>
            <Button variant="secondary" Icon={Eye} iconWeight="fill" />
            <PublishDropDown />
          </div>
        </TopNav>

        <div className="bg-wb-primary flex-grow flex overflow-hidden relative">
          <Tabs defaultValue="pages" asChild>
            <SideNav
              className={isSidebarResizable ? 'border-r border-wb-primary' : ''}
              isResizable={isSidebarResizable}
              isCollapsed={isSidebarCollapsed}
            >
              {sidenavChildren}
            </SideNav>
          </Tabs>
          <div className={cn('grow h-full overflow-y-auto p-8', mainClassName)}>{children}</div>
          {rightSideChildren}
        </div>
      </div>
    </DeviceGate>
  );
};
