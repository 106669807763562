import { useState } from 'react';
import { MagnifyingGlass, PlusCircle } from '@phosphor-icons/react';

import { cn } from '../../../_utils/cn';
import { Button } from '../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '../../UI/Dialog';
import { Input } from '../../UI/Input';
import { Text } from '../../UI/Text';

const LoadingGrid = () => {
  return (
    <>
      <li className="animate-pulse bg-gray-200 rounded-md h-56 w-full" />
      <li className="animate-pulse bg-gray-200 rounded-md h-56 w-full" />
      <li className="animate-pulse bg-gray-200 rounded-md h-56 w-full" />
    </>
  );
};

interface ReturnProps<T> {
  isOpen: boolean;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onOpen: () => void;
  onClose: () => void;
  selections: T[];
  setSelections: (selections: T[]) => void;
}

const useSelectionModal = <T,>(): ReturnProps<T> => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selections, setSelections] = useState<T[]>([]);

  const onOpen = () => setIsOpen(true);
  const onClose = () => {
    setIsOpen(false);
    setSelections([]);
    setSearchTerm('');
  };

  return {
    isOpen,
    searchTerm,
    setSearchTerm,
    onOpen,
    onClose,
    selections,
    setSelections,
  };
};

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  title: string;
  children: React.ReactNode;
  onSubmit: () => void;
  showingCount: number;
  totalCount: number;
  selectedCount: number;
  onLoadMore: () => void;
  isLoading: boolean;
  hasNextPage: boolean;
  isFetching: boolean;
  buttonText: string;
  newItemText?: string;
  placeholderText?: string;
  noResultsText?: string;
  noResults?: boolean;
  multipleSelectionsAllowed?: boolean;
}

const SelectionModal = ({
  isOpen,
  onOpen,
  onClose,
  title,
  searchTerm,
  setSearchTerm,
  children,
  onSubmit,
  showingCount = 0,
  totalCount = 0,
  selectedCount = 0,
  onLoadMore,
  isLoading,
  hasNextPage,
  isFetching,
  buttonText = 'Select Data',
  placeholderText = 'Search',
  newItemText = 'Create New',
  noResultsText = 'No results found',
  noResults = false,
  multipleSelectionsAllowed = false,
}: Props) => {
  const multipleSelectionsAllowedText = selectedCount > 0 ? `Add (${selectedCount})` : 'Add';
  const addText = multipleSelectionsAllowed ? multipleSelectionsAllowedText : 'Add';

  return (
    <Dialog
      defaultOpen={false}
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="outlined" onClick={onOpen} className="shadow">
          {buttonText}
        </Button>
      </DialogTrigger>
      <DialogContent className="rounded-lg shadow-md w-11/12 max-w-4xl h-[80vh] flex flex-col p-0">
        <div className="grow w-full flex flex-col min-h-0">
          <div className="flex justify-between flex-col w-full p-4 space-y-4">
            <Text as="h4" weight="semibold">
              {title}
            </Text>
          </div>

          <div className="grow flex flex-col gap-4 p-4 border-t overflow-y-scroll">
            <div className="flex justify-between items-center gap-4">
              <Input
                LeftIcon={MagnifyingGlass}
                className="w-full"
                placeholder={placeholderText}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Button
                LeftIcon={PlusCircle}
                variant="accent-soft"
                className="whitespace-nowrap"
                onClick={() => {
                  window.open(`${window.location.origin}/forms`, '_blank');
                }}
              >
                {newItemText}
              </Button>
            </div>

            {noResults ? (
              <div className="flex items-center justify-center h-full grow">
                <Text className="text-center -mt-8" weight="semibold" size="md" variant="secondary">
                  {noResultsText}
                </Text>
              </div>
            ) : (
              <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto h-full grow">
                {isLoading ? <LoadingGrid /> : children}
              </ul>
            )}
          </div>
        </div>

        <DialogFooter>
          <div className="flex justify-between items-center border-t w-full p-4">
            <span className="text-sm text-gray-500">
              Showing {showingCount} of {totalCount} results
            </span>
            {hasNextPage && (
              <Button variant="ghost" onClick={onLoadMore}>
                {isFetching ? 'Loading...' : 'Load more'}
              </Button>
            )}

            <div className="flex gap-2">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onSubmit} isDisabled={selectedCount === 0}>
                {addText}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface SelectionItemProps {
  title: string;
  description: string;
  isSelected: boolean;
  onSelect: () => void;
  children: React.ReactNode;
}

const SelectionItem = ({ title, description, isSelected, onSelect, children }: SelectionItemProps) => {
  return (
    <li
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      className={cn(
        'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md overflow-hidden h-56 focus:outline-none',
        isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
      )}
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === '') {
          onSelect();
        }
      }}
    >
      <div className="flex grow overflow-hidden">
        <div className="w-full h-full scale-[0.3] transform origin-center flex items-center justify-center">
          {children}
        </div>
      </div>

      <div className="p-4 bg-wb-secondary w-full">
        <Text as="h4" weight="semibold" size="sm">
          {title}
        </Text>
        <Text as="p" variant="secondary" weight="medium" size="xs" className="line-clamp-1 text-ellipsis">
          {description}
        </Text>
      </div>
    </li>
  );
};

export { SelectionItem, SelectionModal, useSelectionModal };
