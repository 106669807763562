import SectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { BlurBackgroundOptions } from "./BlurBackgroundOptions";
import { StickyOptions } from "./StickyOptions";
import { StyleOptions } from "./StyleOptions";

export function NavMenuStyleSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_menu" && selectedContent.type !== "navbar_menu_list") return null;

  return <SectionRenderer title="Styling">
    <StyleOptions />
    <StickyOptions />
    <BlurBackgroundOptions />
  </SectionRenderer>;
}
