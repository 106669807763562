import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachInstruction, extractInstruction, Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { CaretDown, CaretRight, Icon, Image, Stack, TextH, TextT } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { Text } from '../../UI/Text';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';

type TreeContextType = {
  editor: Editor;
  debugMode?: boolean;
};

const TreeContext = createContext<TreeContextType>({
  editor: {} as unknown as Editor,
  debugMode: false,
});

type TNodeItemProps = {
  node: Node;
  nodePos: number;
  defaultIsOpen?: boolean;
};

const Icons: Record<string, Icon> = {
  paragraph: TextT,
  heading: TextH,
  columns: Stack,
  imageBlock: Image,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

const TNodeItem = ({ node, nodePos, defaultIsOpen = false }: TNodeItemProps) => {
  const { editor, debugMode } = useContext(TreeContext);
  const {
    activeNodePos,
    activeNodeAttributes: { id },
  } = useActiveNode(editor);

  const childNodes = useMemo(() => {
    const newChildNodes: TNodeItemProps[] = [];

    node.descendants((n, pos) => {
      if (!n.type.isBlock) return false;

      newChildNodes.push({
        node: n,
        nodePos: pos + nodePos + 1,
      });

      return false; // do not dive into the children of these nodes
    });

    return newChildNodes;
  }, [node, nodePos]);

  const handleClick = useCallback(() => {
    if (!editor) return;

    editor.chain().setNodeSelection(nodePos).scrollIntoView().run();
  }, [editor, nodePos]);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isDirtyOpen, setIsDirtyOpen] = useState(defaultIsOpen);

  useEffect(() => {
    // user already touched this, do not mess with it.
    if (isDirtyOpen || nodePos === 0 || !node) return;
    setIsOpen(activeNodePos > nodePos && activeNodePos <= nodePos + node.nodeSize);
    setIsDirtyOpen(activeNodePos > nodePos && activeNodePos <= nodePos + node.nodeSize);
  }, [nodePos, activeNodePos, node, isDirtyOpen]);

  const isNodeActive = id === node.attrs.id;

  const { text } = node;

  const dragItem = useRef<HTMLDivElement>(null);

  const [isOver, setIsOver] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);

  useEffect(() => {
    if (!dragItem.current) return () => {};

    const data = {
      id: node.attrs.id,
    };

    return combine(
      draggable({
        element: dragItem.current,
        getInitialData() {
          const slice = editor.state.doc.slice(nodePos, nodePos + node.nodeSize, true);
          editor.view.dragging = {
            slice,
            move: true,
          };
          return data;
        },
      }),
      dropTargetForElements({
        element: dragItem.current,
        getData: ({ input, element }) => {
          // this will 'attach' the instruction to your `data` object
          return attachInstruction(data, {
            input,
            element,
            currentLevel: 2,
            indentPerLevel: 20,
            mode: 'standard',
          });
        },
        onDragEnter() {
          setIsOver(true);
        },
        onDragLeave() {
          setIsOver(false);
        },
        onDrag({ self, source }) {
          if (self.data.id === source.data.id) return;

          setInstruction(extractInstruction(self.data));
        },
        onDrop() {
          // handle dropping
          setIsOver(false);
        },
      })
    );
  }, [editor, nodePos, node]);

  const IconComp = Icons[node.type.name] || Stack;

  return (
    <div className="select-none">
      {isOver && instruction?.type === 'reorder-above' && <div className="w-full bg-wb-accent h-0.5" />}
      <div
        ref={dragItem}
        className={`flex items-center gap-1 cursor-pointer border rounded-md p-1.5 ${
          isNodeActive ? 'bg-wb-button-accent-soft border-wb-accent-soft' : 'hover:bg-wb-secondary border-transparent'
        }`}
        onClick={handleClick}
        role="none"
      >
        {childNodes.length > 0 && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen((o) => !o);
              setIsDirtyOpen((o) => !o);
            }}
            className="text-wb-primary-soft"
          >
            {isOpen ? <CaretDown /> : <CaretRight />}
          </button>
        )}
        {<IconComp className="text-wb-accent" weight="bold" /> || <Stack className="text-wb-accent" weight="bold" />}
        <Text size="xs" className="text-inherit">
          {formatActiveNodeType(node.type.name)}
        </Text>{' '}
        {childNodes.length === 0 && text && <Text>{`(${text})`}</Text>}
      </div>
      {debugMode && `${nodePos},${activeNodePos}`}
      <div style={{ height: isOpen ? undefined : '0px', overflow: 'hidden' }}>
        {childNodes.map(({ node: childNode, nodePos: np }) => (
          <div key={childNode.attrs.id} style={{ marginLeft: '20px' }}>
            <TNodeItem node={childNode} nodePos={np} />
          </div>
        ))}
      </div>
      {isOver && instruction?.type === 'reorder-below' && <div className="w-full bg-wb-accent h-0.5" />}
    </div>
  );
};

export const LayersPanel = ({ editor }: { editor: Editor }) => {
  const contextValue = useMemo(() => ({ editor, debugMode: false }), [editor]);

  const [directRootChildren, setDirectRootChildren] = useState<TNodeItemProps[]>([]);

  const updateDirectRootChildren = useCallback((e: Editor) => {
    const childNodes: TNodeItemProps[] = [];
    e.state.doc.nodesBetween(0, e.state.doc.content.size, (n, pos) => {
      if (!n.type.isBlock) return false;

      childNodes.push({
        node: n,
        nodePos: pos,
      });

      return false; // do not dive into the children of these nodes
    });

    setDirectRootChildren(childNodes);
  }, []);

  useEffect(() => {
    const handleUpdate = ({ editor: e }: { editor: Editor }) => {
      updateDirectRootChildren(e);
    };

    updateDirectRootChildren(editor);

    editor.on('update', handleUpdate);

    return () => {
      editor.off('update', handleUpdate);
    };
  }, [editor, updateDirectRootChildren]);

  return (
    <TreeContext.Provider value={contextValue}>
      <div className="flex flex-col gap-2">
        <Text size="xs" variant="secondary" weight="semibold" className="p-2">
          Layers
        </Text>
        <div>
          {directRootChildren.map(({ node: chNode, nodePos: np }) => (
            <TNodeItem key={`${np}-${chNode.attrs.id}`} node={chNode} nodePos={np} />
          ))}
        </div>
      </div>
    </TreeContext.Provider>
  );
};
