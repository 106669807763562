import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { usePostClicksExport } from '@/hooks';
import { PostClick } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import PostClickContainer from './PostClickContainer';
import PostClicksContainer from './PostClicksContainer';

interface ClicksTableProps {
  postId: string;
}

const formatter = new Intl.NumberFormat('en');

const WebClicksTable = ({ postId }: ClicksTableProps) => {
  const navigate = useNavigate();

  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;
  const { mutateAsync, isLoading: isExporting } = usePostClicksExport({ useApiary: canUseApiary, platform: 'web' });

  return (
    <PostClicksContainer postId={postId} platform="web">
      {(clicks, totalClicks, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage) => {
        if (isLoading) {
          return (
            <div className="flex items-center">
              <LoadingSpinner className="mr-2" />
              <p className="text-gray-400 text-sm">Loading clicks...</p>
            </div>
          );
        }

        const hasNoResults = clicks.length === 0;

        return (
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center">
              <ResourceListSearchText showingCount={clicks.length} totalCount={totalClicks || 0} />
              <Button size="xs" onClick={() => mutateAsync()} disabled={isExporting || hasNoResults}>
                {isExporting ? 'Exporting...' : 'Export CSV'}
              </Button>
            </div>

            <Items>
              <ItemHeaders isSticky>
                <ItemHeader>URL</ItemHeader>
                <ItemHeader>Unique Clicks</ItemHeader>
                <ItemHeader>Total Clicks</ItemHeader>
                <ItemHeader isSticky>&nbsp;</ItemHeader>
              </ItemHeaders>

              <ItemsBody>
                {clicks.map((click: PostClick) => {
                  return (
                    <PostClickContainer postId={postId} postClick={click}>
                      {(stats) => (
                        <ItemRow key={click.id}>
                          <ItemColumn align={AlignType.LEFT}>
                            <a
                              href={click.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-80 overflow-hidden block truncate text-action-secondary-600 w-44 md:w-[600px]"
                              title={click.url}
                            >
                              <Typography
                                token="font-normal/text/sm"
                                color="secondary"
                                colorWeight="600"
                                className="w-full"
                              >
                                {click.url}
                              </Typography>
                            </a>
                          </ItemColumn>
                          <ItemColumn>
                            <Typography token="font-normal/text/sm" colorWeight="700">
                              {formatter.format(stats.total_unique_web_clicked)}
                            </Typography>
                          </ItemColumn>
                          <ItemColumn>
                            <Typography token="font-normal/text/sm" colorWeight="700">
                              {formatter.format(stats.total_web_clicked)}
                            </Typography>
                          </ItemColumn>
                          <ItemColumn>
                            <Button
                              onClick={() => navigate(`/posts/${postId}/analytics/${click.id}`)}
                              Icon={ChevronRightIcon}
                              iconRight
                              size="xs"
                              variant="flush"
                            >
                              View Details
                            </Button>
                          </ItemColumn>
                        </ItemRow>
                      )}
                    </PostClickContainer>
                  );
                })}
                {hasNoResults ? (
                  <ItemRow>
                    <ItemColumn colSpan={4} align={AlignType.CENTER}>
                      <Typography token="font-normal/text/sm" colorWeight="700">
                        No Clicks yet!
                      </Typography>
                    </ItemColumn>
                  </ItemRow>
                ) : null}
              </ItemsBody>
            </Items>
            {hasNextPage && fetchNextPage && typeof isFetchingNextPage !== 'undefined' && (
              <div className="flex justify-start mt-8 w-fit">
                <LoadMoreButton isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} />
              </div>
            )}
          </div>
        );
      }}
    </PostClicksContainer>
  );
};

export default WebClicksTable;
