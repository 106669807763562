import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SignupView } from './views/SignupView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    signup: {
      setSignup: () => ReturnType;
    };
  }
}

export const Signup = Node.create<{}, {}>({
  name: 'signup',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
        parseHTML: (element) => element.getAttribute('data-id') || '',
      },
      borderRadius: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || '0px',
      },
      borderWidth: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '0px',
      },
      borderColor: {
        default: '',
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || '#000000',
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => JSON.parse(element.getAttribute('data-padding') || '{}'),
      },
      backgroundColor: {
        default: undefined,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || undefined,
      },
      height: {
        default: 50,
        parseHTML: (element) => ({ 'data-height': parseInt(element.getAttribute('data-height') || '50', 10) }),
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
      },
      width: {
        default: null,
        parseHTML: (element) => ({ 'data-width': parseInt(element.getAttribute('data-width') || '50', 10) }),
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
      },
      inputPlaceholder: {
        default: 'Email',
        parseHTML: (element) => element.getAttribute('data-input-placeholder') || 'Email',
        renderHTML: (attributes) => ({ 'data-input-placeholder': attributes.inputPlaceholder }),
      },
      inputBorderColor: {
        default: '#000',
        parseHTML: (element) => element.getAttribute('data-input-border-color') || '#000',
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-input-border-radius') || '0px',
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-input-border-style') || 'none',
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputPlaceholderColor: {
        default: '#000',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color') || '#000',
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      inputTextColor: {
        default: '#000',
        parseHTML: (element) => element.getAttribute('data-input-text-color') || '#000',
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputBackgroundColor: {
        default: '#fff',
        parseHTML: (element) => element.getAttribute('data-input-background-color') || '#fff',
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputPadding: {
        default: '10px 20px',
        parseHTML: (element) => element.getAttribute('data-input-padding') || '0px',
        renderHTML: (attributes) => ({ 'data-input-padding': attributes.inputPadding }),
      },
      buttonBackgroundColor: {
        default: '#000',
        parseHTML: (element) => element.getAttribute('data-button-background-color') || '#000',
        renderHTML: (attributes) => ({ 'data-button-background-color': attributes.buttonBackgroundColor }),
      },
      buttonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-button-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
      },
      buttonBorderColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-button-border-color') || '#000000FF',
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
      },
      buttonBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-button-border-style') || 'none',
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
      },
      buttonBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-button-border-radius') || '0px',
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
      },
      buttonTextColor: {
        default: '#fff',
        parseHTML: (element) => element.getAttribute('data-button-text-color') || '#fff',
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      buttonText: {
        default: 'Subscribe',
        parseHTML: (element) => element.getAttribute('data-button-text') || 'Subscribe',
        renderHTML: (attributes) => ({ 'data-button-text': attributes.buttonText }),
      },
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setSignup:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SignupView);
  },
});

export default Signup;
