import { useState } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import { useSettings } from '@/context/settings-context';
import useDeleteStripeConnectAccount from '@/hooks/boosts/monetize/useDeleteStripeConnectAccount';
import { StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import BodyContainer from '@/routes/settings/publication/components/BodyContainer';
import CardHeader from '@/routes/settings/publication/components/CardHeader';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import analytics from '@/utils/analytics';

interface Props {
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const Disconnect = ({ boostsAccountType, isLoading }: Props) => {
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState<boolean>(false);
  const currentPublicationId = useCurrentPublicationId();
  const disconnectAccount = useDeleteStripeConnectAccount(currentPublicationId);
  const isExpress = boostsAccountType === StripeAccountType.EXPRESS;
  const buttonText = isExpress ? 'Delete Stripe Express Account' : 'Disconnect Stripe Standard Account';
  const titleText = isExpress ? 'Delete Stripe Account' : 'Disconnect Stripe Account';
  const descriptionText = isExpress
    ? 'Delete and disconnect your payment express stripe account from your publication.'
    : 'Disconnect your payment stripe account from your publication.';
  const { settings } = useSettings();

  const closeDisconnectModal = () => {
    setIsDisconnectModalOpen(false);
    setIsDisconnecting(false);
  };

  const disconnectBoostsAccount = () => {
    analytics.track('Disconnected stripe');
    setIsDisconnecting(true);
    disconnectAccount.mutate();
    setIsDisconnecting(false);
    closeDisconnectModal();
  };

  const handleDisconnectClick = () => {
    setIsDisconnectModalOpen(true);
  };

  if (!boostsAccountType || !settings?.wallet) return null;

  return (
    <>
      <ActionModal
        isOpen={isDisconnectModalOpen}
        isWorking={isDisconnecting}
        onClose={() => closeDisconnectModal()}
        onProceed={() => disconnectBoostsAccount()}
        resourceId={currentPublicationId}
        modalMessageType="danger"
        headerText="Disconnect Stripe Account"
        descriptionText="You will no longer be able to withdraw any funds or apply to any monetization opportunities from beehiiv until you have reconnected a Stripe Express Account."
        actionText={buttonText}
        buttonType="danger"
        buttonShade="dark"
      />
      <Card>
        <BodyContainer>
          <CardHeader title={titleText} description={descriptionText} />
          <Button
            variant="danger"
            shade="dark"
            type="submit"
            size="xs"
            loading={isDisconnectModalOpen}
            disabled={isDisconnectModalOpen || isLoading}
            onClick={() => handleDisconnectClick()}
            className="w-fit"
            Icon={ArrowUpRightIcon}
            iconRight
          >
            {buttonText}
          </Button>
        </BodyContainer>
      </Card>
    </>
  );
};

export default Disconnect;
