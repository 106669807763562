const imageUrl1 = 'https://media.beehiiv.com/static_assets/site_builder/authors/oliver_buchanan.png';
const imageUrl2 = 'https://media.beehiiv.com/static_assets/site_builder/authors/kyra_singh.png';
const imageUrl3 = 'https://media.beehiiv.com/static_assets/site_builder/authors/natalia_mirescu.png';
const imageUrl4 = 'https://media.beehiiv.com/static_assets/site_builder/authors/liam_mckenzie.png';
const imageUrl5 = 'https://media.beehiiv.com/static_assets/site_builder/authors/catalina_vidal.png';
const imageUrl6 = 'https://media.beehiiv.com/static_assets/site_builder/authors/allessandro_rossi.png';

const OLIVER_BUCHANNON = {
  id: 'author123',
  name: 'Oliver Buchannon',
  profile_picture: {
    thumb: imageUrl1,
    url: imageUrl1,
    landscape: imageUrl1,
  },
  twitter_handle: 'oliver_buchannon',
};

const KYRA_SINGH = {
  name: 'Kyra Singh',
  profile_picture: {
    thumb: imageUrl2,
    url: imageUrl2,
    landscape: imageUrl2,
  },
  twitter_handle: 'kyra_singh',
  id: 'author2',
};

const NATALIA_MIRESCU = {
  name: 'Natalia Mirescu',
  profile_picture: {
    thumb: imageUrl3,
    url: imageUrl3,
    landscape: imageUrl3,
  },
  twitter_handle: 'natalia_mirescu',
  id: 'author3',
};

const LIAM_MCKENZIE = {
  name: 'Liam McKenzie',
  profile_picture: {
    thumb: imageUrl4,
    url: imageUrl4,
    landscape: imageUrl4,
  },
  twitter_handle: 'liam_mckenzie',
  id: 'author4',
};

const CATALINA_VIDAL = {
  name: 'Catlina Vidal',
  profile_picture: {
    thumb: imageUrl5,
    url: imageUrl5,
    landscape: imageUrl5,
  },
  twitter_handle: 'catalina_vidal',
  id: 'author5',
};

const ALESSANDRO_ROSSI = {
  name: 'Alessandro Rossi',
  profile_picture: {
    thumb: imageUrl6,
    url: imageUrl6,
    landscape: imageUrl6,
  },
  twitter_handle: 'alessandro_rossi',
  id: 'author6',
};

const authors = [ALESSANDRO_ROSSI, CATALINA_VIDAL, KYRA_SINGH, LIAM_MCKENZIE, NATALIA_MIRESCU, OLIVER_BUCHANNON];

const selectThreeRandomAuthors = ({ count = 3 }: { count: number }) => {
  return authors.sort(() => 0.5 - Math.random()).slice(0, count);
};

export {
  ALESSANDRO_ROSSI,
  authors,
  CATALINA_VIDAL,
  KYRA_SINGH,
  LIAM_MCKENZIE,
  NATALIA_MIRESCU,
  OLIVER_BUCHANNON,
  selectThreeRandomAuthors,
};
