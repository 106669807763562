import React, { useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { useClickOutside } from '@/hooks/useUsers/useClickOutside';

import { Option } from '../../interfaces/general';
import Badge from '../Badge';
import { LoadingSpinner } from '../LoadingSpinner';
import { Typography } from '../Typography';

interface Props {
  badgeClassName?: string;
  badgeTextClassName?: string;
  badgeLabelClassName?: string;
  badgeSubtitleClassName?: string;
  disabled?: boolean;
  dropdownClassnames?: string;
  emptyLabel?: string;
  helperText?: string | React.ReactNode;
  labelClassName?: string;
  labelText?: string;
  name: string;
  onClear?: (name: string) => void;
  onDeselect: (name: string, value: string) => void;
  onDeselectAll: (name: string) => void;
  onSearch?: () => Promise<Option[]>;
  onSearchQueryChange?: (query: string) => void;
  onSelect: (name: string, value: string) => void;
  badgeColor?: 'warning' | 'alert' | 'success' | 'information' | 'info_blue' | 'tertiary';
  placeholderText?: string;
  required?: boolean;
  showClearAll?: boolean;
  showSelectedOption?: boolean;
  shouldCloseOnSelection?: boolean;
  showValuesWhenMenuOpen?: boolean;
  selectedPreview?: string[];
  values: string[] | Option[];
  errorText?: string;
  staticOptions?: Option[];
}

const PreviewSelected = ({
  children,
  badgeColor = 'success',
  onRemoveItem,
}: {
  children: string;
  badgeColor: 'warning' | 'alert' | 'success' | 'information' | 'info_blue' | 'tertiary';
  onRemoveItem?: () => void;
}) => (
  <li>
    <Badge type={badgeColor} className="truncate rounded">
      {children}{' '}
      <button type="button" onClick={onRemoveItem} className="opacity-60 hover:opacity-100">
        <XMarkIcon className="ml-1 h-3.5 w-3.5 text-inherit" />
      </button>
    </Badge>
  </li>
);

const Multiselect = ({
  disabled = false,
  dropdownClassnames,
  emptyLabel,
  helperText,
  labelClassName,
  badgeColor = 'success',
  labelText,
  name,
  onClear,
  onDeselect,
  onDeselectAll,
  onSearch,
  onSearchQueryChange,
  onSelect,
  placeholderText,
  required = false,
  showClearAll = true,
  showSelectedOption = true,
  shouldCloseOnSelection = true,
  showValuesWhenMenuOpen = false,
  selectedPreview = [],
  values,
  errorText,
  staticOptions,
  badgeClassName,
  badgeTextClassName,
  badgeLabelClassName,
  badgeSubtitleClassName,
}: Props) => {
  const [options, setOptions] = useState<Option[]>();
  const optionValues = values.map((value) => (typeof value === 'string' ? value : value.value));
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownEl = useRef<any>();
  const inputEl = useRef<any>();

  // Load options when search query changes
  useEffect(() => {
    setIsLoading(true);
    if (!staticOptions && onSearch) {
      onSearch()
        .then((loadedOptions: Option[]) => {
          if (loadedOptions) {
            setOptions(loadedOptions);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setOptions(staticOptions);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, staticOptions]);

  useClickOutside(() => setMenuOpen(false), dropdownEl);

  const updateSearchQuery = (query: string) => {
    onSearchQueryChange?.(query);
    setSearchQuery(query);
  };

  const onSelectOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    onSelect(name, newValue);
  };

  const onReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClear) {
      onClear(name);
    }

    updateSearchQuery('');
    onDeselectAll(name);
    inputEl.current.value = '';
  };

  const onMenuClick = (e: any) => {
    // This is so we don't falsely unfocus
    if (e?.type === 'keydown' && e?.key !== 'return') {
      return;
    }

    if (menuOpen) {
      setMenuOpen(false);
    } else {
      if (inputEl) {
        inputEl?.current?.focus();
      }
      setIsLoading(true);

      if (!staticOptions && onSearch) {
        onSearch()
          .then((loadedOptions: Option[]) => {
            if (loadedOptions) {
              setOptions(loadedOptions);
            }
          })
          .finally(() => {
            setIsLoading(false);
            setMenuOpen(true);
          });
      } else {
        setIsLoading(false);
        setMenuOpen(true);
      }
    }
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Tab' && options) {
      onSelectOption(options[0].value);
    }
  };

  const onKeyDownControl = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Backspace' && name) {
      if (onClear) {
        onClear(name);
      }

      updateSearchQuery('');
    }
  };

  const onOptionPress = (option: Option) => {
    if (shouldCloseOnSelection) {
      setMenuOpen(false);
    }

    if (!optionValues.includes(option.value)) {
      onSelectOption(option.value);
    } else {
      onDeselect(name, option.value);
    }
  };

  const handleSearchQueryChange = debounce((e) => updateSearchQuery(e.target.value), 500);

  const renderOption = (option: Option, tabIndex: number) => {
    const selected = optionValues.includes(option.value);

    if (selected && !showSelectedOption) {
      return null;
    }

    return (
      <>
        <li
          role="option"
          tabIndex={tabIndex}
          aria-selected={selected}
          key={option.value}
          className="text-gray-800 hover:text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-3 pr-9"
          onClick={() => onOptionPress(option)}
          onKeyDown={() => onOptionPress(option)}
        >
          <div className="flex">
            <span className={cx(selected ? 'font-semibold' : 'font-normal', 'truncate')}>{option.label}</span>
            {selected && (
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800 hover:text-gray-900">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
          </div>
        </li>
        {option.subItems?.map((subItem) => {
          const selectedSubItem = optionValues.includes(subItem.value);
          const selectedParentItem = optionValues.includes(option.value);
          const selectedItem = selectedSubItem || selectedParentItem;

          return (
            <li
              role="option"
              tabIndex={tabIndex}
              aria-selected={selectedItem}
              key={subItem.value}
              className={cx(
                'text-gray-80 select-none relative py-2 pl-6 pr-6',
                selectedParentItem
                  ? 'bg-gray-50 cursor-not-allowed'
                  : ' hover:text-gray-900 hover:bg-gray-100 cursor-pointer'
              )}
              onClick={() => !selectedParentItem && onOptionPress(subItem)}
              onKeyDown={() => !selectedParentItem && onOptionPress(subItem)}
              aria-disabled={selectedParentItem}
            >
              <div className="flex">
                <span className={cx(selectedItem ? 'font-semibold' : 'font-normal', 'truncate')}>{subItem.label}</span>
                {selectedItem && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800 hover:text-gray-900">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </>
    );
  };

  const renderOptions = () => {
    if (!options || options.length === 0) {
      return emptyLabel ? <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div> : null;
    }

    return <ul role="listbox">{options.map((option, index) => renderOption(option, index))}</ul>;
  };

  return (
    <div className="h-fit flex flex-col gap-2">
      {labelText && (
        <div>
          <label htmlFor={name} className={cx('block text-sm font-medium', labelClassName || 'text-gray-700')}>
            {labelText}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      <div className={cx('relative w-full h-auto')} ref={dropdownEl}>
        {selectedPreview.length > 0 ? (
          <ul className="flex flex-row gap-1 pb-2 flex-wrap">
            {selectedPreview.map((value) => (
              <PreviewSelected
                key={value}
                badgeColor={badgeColor}
                onRemoveItem={() => {
                  if (!disabled) {
                    onDeselect(name, value);
                  }
                }}
              >
                {value}
              </PreviewSelected>
            ))}
          </ul>
        ) : null}

        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownControl}
          className={cx(
            'h-fit border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 text-left cursor-default relative text-sm',
            disabled ? 'bg-surface-100 cursor-not-allowed' : 'bg-white hover:border-primary-500',
            menuOpen ? 'outline-none border-surface-600' : ''
          )}
        >
          <div className={cx('w-full h-full', disabled && 'pointer-events-none opacity-60')}>
            <div role="button" className="h-full" tabIndex={0} onKeyDown={onKeyDownControl} onClick={onMenuClick}>
              <input
                type="text"
                ref={inputEl}
                name={name}
                className={cx(
                  'text-sm w-full py-0 px-0 focus:outline-none focus:ring-transparent focus:border-transparent appearance-none block border-0 placeholder-gray-400 h-full',
                  disabled ? 'cursor-not-allowed bg-surface-100' : 'cursor-pointer bg-white'
                )}
                style={{
                  lineHeight: '18px',
                }}
                placeholder={placeholderText}
                onChange={handleSearchQueryChange}
                onKeyDown={onKeyDownInput}
                onClick={onMenuClick}
              />

              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                {isLoading && <LoadingSpinner className="mr-1" />}
                {showClearAll && values.length > 0 && (
                  <button name="reset" type="button" onClick={onReset}>
                    <XMarkIcon className="h-5 w-5 text-gray-400 mr-1" aria-hidden="true" />
                  </button>
                )}
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
        {menuOpen && (
          <div
            data-type="options"
            className={cx(
              'absolute z-10 mt-1 pt-2 w-full bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm',
              dropdownClassnames
            )}
          >
            {isLoading && <div className="text-center py-4">Loading...</div>}
            {!isLoading && renderOptions()}
          </div>
        )}
      </div>
      {(!menuOpen || (menuOpen && showValuesWhenMenuOpen)) && values.length > 0 && (
        <div className="flex flex-row flex-wrap gap-2">
          {values.map((value) => {
            const currentOption = options?.find((option) => {
              const { value: optionValue } = option;
              if (typeof value === 'string') {
                return optionValue === value || option.subItems?.find((subItem) => subItem.value === value);
              }

              return option.value === value.value || option.subItems?.find((subItem) => subItem.value === value.value);
            });
            const currentSubItem = currentOption?.subItems?.find((subItem) =>
              typeof value === 'string' ? subItem.value === value : subItem.value === value.value
            );
            const foobar = typeof value === 'string' ? { label: value, subtitle: '' } : value;
            const { label, subtitle } = currentSubItem || currentOption || foobar;
            const optionValue = typeof value === 'string' ? value : value.value;

            return (
              (!currentSubItem || !optionValues.find((checkValue) => currentOption?.value === checkValue)) && (
                <span className="flex flex-col gap-2">
                  {values.length === 1 && (
                    <Typography token="font-normal/text/xs" colorWeight="500">
                      {currentOption?.onlySelectionHelpText}
                    </Typography>
                  )}
                  <Badge
                    key={optionValue}
                    size="md"
                    className={badgeClassName}
                    onDismiss={disabled ? undefined : () => onDeselect(name, optionValue)}
                    alwaysShowDismiss
                    dismissClassName={cx('!mr-0', badgeLabelClassName)}
                  >
                    <div className={cx('flex-row gap-2 py-2 px-3 items-center', badgeTextClassName)}>
                      <Typography token="font-medium/text/xs" colorWeight="700" className={badgeLabelClassName}>
                        {label}
                      </Typography>
                      {subtitle && (
                        <Typography
                          token="font-normal/text/xs"
                          colorWeight="500"
                          className={cx('ml-2', badgeSubtitleClassName)}
                        >
                          {subtitle}
                        </Typography>
                      )}
                    </div>
                  </Badge>
                </span>
              )
            );
          })}
        </div>
      )}

      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
      {errorText && <p className="mt-2 text-xs text-feedback-danger-500">{errorText}</p>}
    </div>
  );
};

export default Multiselect;
