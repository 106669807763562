import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

const Teaser = () => {
  const { data: settings } = usePublicationSettings();
  const navigate = useNavigate();
  const intentAction = IntentAction.USE_WEBHOOKS;
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction });

  useEffect(() => {
    if (settings?.webhooks) {
      toast.success('webhooks are now enabled.');
      navigate('/settings/integrations/webhooks');
    }
  }, [settings, navigate]);

  const cards = [
    {
      heading: 'Real-time Updates',
      description: "Optimize your ability to react to your subscriber's actions with real-time updates.",
      imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/webhooks/real-time.jpg',
    },
    {
      heading: 'Custom Integrations',
      description:
        'Integrate with your own custom backend or third party tools like Make to build a fully custom experience.',
      imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/webhooks/integrations.jpg',
    },
  ];

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title="Webhooks"
      description="Webhooks are a powerful way for beehiiv to communicate important events in real-time."
      cards={cards || []}
      learnMoreUrl="https://developers.beehiiv.com/docs/webhooks/84qbkdalj3bo2-overview"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
