import React from 'react';

import { ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';

import { useExportDataContext } from './context';
import ListItem from './ListItem';

const HistoricalExports: React.FC = () => {
  const { dataExports } = useExportDataContext();
  const description = dataExports.length > 0 ? undefined : 'No historical exports available';

  return (
    <BodyContainer>
      <CardHeader title="Historical Exports" description={description} />

      {dataExports.length > 0 ? (
        <div className="overflow-hidden overflow-y-scroll max-h-96 border rounded-md border-gray-200 relative">
          <Items>
            {(isInViewport) => (
              <>
                <ItemHeaders isSticky={isInViewport} canIntersectWithTopBar={false}>
                  <ItemHeader align={AlignType.LEFT}>Export Date</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Type</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Export Link</ItemHeader>
                </ItemHeaders>

                <ItemsBody>
                  {dataExports.map((dataExport) => (
                    <ListItem key={dataExport.id} dataExport={dataExport} />
                  ))}
                </ItemsBody>
              </>
            )}
          </Items>
        </div>
      ) : null}
    </BodyContainer>
  );
}

export default HistoricalExports;
