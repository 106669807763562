import { PropsWithChildren, useRef } from 'react';
import { PencilIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { LoadingSpinner } from '@/components/LoadingSpinner';

type Props = PropsWithChildren<{
  onSave: Function;
  isBusy: boolean;
}>;

const FileUploader = ({ onSave, children, isBusy = false }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleImageClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      // setImage(e.target.files[0]);

      if (onSave) {
        onSave(e.target.files[0]);
      }
    }
  };

  const inputId = generateUuid();

  return (
    <div className="flex justify-between items-center border border-surface-200 rounded-md w-fit h-fit">
      <input
        name={`file-uploader-file-input-${inputId}`}
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        className="hidden"
        disabled={isBusy}
      />

      <div className="inline-block rounded-sm relative">
        <button
          type="button"
          onClick={handleImageClick}
          className="flex relative w-auto h-auto bg-black bg-opacity-0 group rounded-md overflow-hidden border-0"
          disabled={isBusy}
        >
          {children}
          <span
            className={classNames(
              'flex items-center justify-center text-white absolute top-0 left-0 w-full h-full',
              isBusy
                ? 'visible bg-opacity-50 bg-surface-100'
                : 'invisible group-hover:visible group-hover:bg-black group-hover:bg-opacity-50'
            )}
          >
            {isBusy ? <LoadingSpinner /> : <PencilIcon className="h-4 w-4" />}
          </span>
        </button>
      </div>
    </div>
  );
};

export default FileUploader;
