import { useEffect, useState } from 'react';
import { GlobeHemisphereEast } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';

import { ImageInput } from '../../_components/UI/ImageInput';
import { Text } from '../../_components/UI/Text';

import { FBIcon } from './FBIcon';
import SettingsInput from './SettingsInput';
import SettingsItem from './SettingsItem';

export function FBSettings({
  data,
  onUpdate,
  isAccordionItem = false,
  isPage = false,
  onUpdateImage,
}: {
  data: SiteVersion | PageVersion | undefined;
  onUpdate: (key: string, value: string | undefined | null) => void;
  isAccordionItem?: boolean;
  isPage?: boolean;
  onUpdateImage: (file: string | File | any) => void;
}) {
  const currentPublication = useCurrentPublication();

  const [fbTitle, setFBTitle] = useState<string>(data?.metadata?.facebook_title || '');
  const [fbDescription, setFBDescription] = useState<string>(data?.metadata?.facebook_description || '');
  const fbImageUrl = data?.metadata?.facebook_image_url;

  useEffect(() => {
    setFBTitle(data?.metadata?.facebook_title || '');
    setFBDescription(data?.metadata?.facebook_description || '');
  }, [data]);

  const defaultTitle = data?.metadata?.meta_title || currentPublication?.data?.name || '';
  const defaultDescription = data?.metadata?.meta_description || `A website for ${currentPublication?.data?.name}`;

  return (
    <SettingsItem
      title="Facebook (Meta) Card"
      description={`Customize how your ${isPage ? 'page' : 'website'} looks like when shared on Facebook (Meta).`}
      isAccordionItem={isAccordionItem}
    >
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">
          <ImageInput
            labelText="Image"
            value={data?.metadata?.facebook_image_url || null}
            onSave={(file) => onUpdateImage(file)}
            width={100}
            height={100}
            onRemove={() => {
              onUpdate('facebook_image_url', null);
            }}
          />

          <SettingsInput
            name="facebook_title"
            labelText="Title"
            type="input"
            placeholder={defaultTitle}
            value={fbTitle}
            onSave={async (value: string) => {
              if (value === fbTitle) return;
              onUpdate('facebook_title', value);
            }}
            onChangeMutateValue={(val: string) => {
              setFBTitle(val);
              return val;
            }}
          />

          <SettingsInput
            name="facebook_description"
            labelText="Description"
            type="textarea"
            placeholder={defaultDescription}
            value={fbDescription}
            onSave={async (value: string) => {
              if (value === fbDescription) return;
              onUpdate('facebook_description', value);
            }}
            onChangeMutateValue={(val: string) => {
              setFBDescription(val);
              return val;
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Facebook (Meta) Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md flex flex-col gap-4 mb-2">
            <div className="flex items-center gap-2">
              <FBIcon />
              <div className="flex flex-col flex-1">
                <Text size="xs" weight="bold">
                  Name
                </Text>
                <div className="flex items-center gap-1 py-0.5 px-1.5 w-fit rounded-md bg-wb-tertiary">
                  <GlobeHemisphereEast size={12} weight="fill" />
                  <Text size="2xs" weight="bold" className="text-[11px] tracking-tight">
                    Public
                  </Text>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="w-full h-3 bg-wb-secondary rounded-full" />
              <div className="w-3/4 h-3 bg-wb-secondary rounded-full" />
            </div>

            <div className="flex flex-col bg-wb-secondary rounded-2xl border border-wb-border overflow-hidden">
              {fbImageUrl && <img src={fbImageUrl} className="w-full h-[180px] object-cover" alt="X Card Preview" />}
              <div className="w-full p-3 flex flex-col">
                <Text size="2xs" variant="secondary" className="uppercase">
                  {currentPublication?.data?.hostname}
                </Text>
                <Text size="sm" weight="bold">
                  {fbTitle || defaultTitle}
                </Text>
                <Text size="xs" variant="secondary">
                  {fbDescription || defaultDescription}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsItem>
  );
}
