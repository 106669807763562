import { useNavigate } from 'react-router-dom';

import { useDisableRefetchOnFocus, useOptInEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import useUpdateOptInEmail from '@/routes/opt_in_email/_hooks/useUpdateOptInEmail';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../../components/BodyContainer';
import EditEmailForm from '../../components/EditEmailForm';

interface Props {
  publication: Publication;
}

const EditForm = ({ publication }: Props) => {
  useDisableRefetchOnFocus();

  const navigate = useNavigate();
  const { data: optInEmail } = useOptInEmail(publication.id);

  const { mutate: updateOptInEmail, isLoading } = useUpdateOptInEmail(publication.id, optInEmail?.id || '');

  return (
    <BodyContainer>
      <EditEmailForm
        currentPublicationId={publication.id}
        emailMessage={optInEmail}
        isLoading={isLoading}
        onUpdate={updateOptInEmail}
        onCancel={() => {
          navigate(appendSettingsPublicationId('/settings/publication/emails/opt_in_email', publication.id));
        }}
      />
    </BodyContainer>
  );
};

export default EditForm;
