import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import { badgeAttributesByType } from '@/components/_domain/Automations/utils';
import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import OptionsDropdown from '@/components/OptionsDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePermissions } from '@/context/permissions-context';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import { Automation, AutomationState } from '@/interfaces/automations/automation';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import api from '@/services/swarm';
import pluralize from '@/utils/pluralize';

interface Props {
  automation: Automation;
}

const ListItem = ({ automation }: Props) => {
  const navigate = useNavigate();
  const [publicationId] = useCurrentPublicationState();
  const { checkPermissions } = usePermissions();
  const canSeeOverview = checkPermissions('views/automations/overview', 'read')
  const url = `/automations/${automation.id}`;
  const listItemUrl = `${url}/${automation.state === AutomationState.RUNNING && canSeeOverview ? 'overview' : 'workflow'}`;
  const formatter = Intl.NumberFormat('en');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const fetchResourcePermissions = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: "Api::V2::AutomationPolicy",
  });
  const recordPermissions = fetchResourcePermissions.data || [];
  const canCreate = fetchResourcePermissions.isSuccess && !recordPermissions.includes('create')
  const canDelete = fetchResourcePermissions.isSuccess && !recordPermissions.includes('delete')
  const canUpdate = fetchResourcePermissions.isSuccess && !recordPermissions.includes('update')

  const options = [
    [
      {
        label: 'View Overview',
        onClick: () => {
          navigate(`${url}/overview`);
        },
        disabled: !canSeeOverview,
        disabledTooltip: NO_PERMISSION_MESSAGE,
      },
      {
        label: 'Edit Workflow',
        onClick: () => {
          navigate(`${url}/workflow`);
        },
        disabled: canUpdate,
        disabledTooltip: NO_PERMISSION_MESSAGE,
      },
      {
        label: 'Duplicate',
        onClick: () => {
          api
            .post(`/automations/${automation.id}/duplicate`, {
              publication_id: publicationId,
            })
            .then(() => {
              toast.success('Automation duplicated');
              queryClient.invalidateQueries(['automations', currentPublicationId]);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.error || 'Something went wrong');
            });
        },
        disabled: canCreate,
        disabledTooltip: NO_PERMISSION_MESSAGE
      },
      {
        label: 'Delete',
        color: 'red',
        onClick: () => setShowDeleteModal(true),
        disabled: canDelete,
        disabledTooltip: NO_PERMISSION_MESSAGE,
      },
    ],
  ];

  const deleteAutomation = useMutation(
    () =>
      api
        .delete(`/automations/${automation.id}`, {
          data: { publication_id: currentPublicationId },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['automations', currentPublicationId]);
        toast.success('Automation successfully deleted');
        setShowDeleteModal(false);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
        setShowDeleteModal(false);
      },
    }
  );

  return (
    <>
      <ActionModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        isWorking={deleteAutomation.isLoading}
        onProceed={() => deleteAutomation.mutateAsync()}
        resourceId={automation.id}
        headerText={`Delete ${automation.name}?`}
      >
        Deleting this automation cannot be undone and means that subscribers currently going through the flow will stop
        immediately. Do you wish to continue?
      </ActionModal>

      <ItemRow>
        <ItemColumn align={AlignType.TOP}>
          <div className="w-40 md:w-64">
            <TypographyStack>
              <Typography weight="medium" colorWeight="900">
                <div className="truncate" title={automation.name}>
                  <Link to={listItemUrl} className="hover:text-gray-900">
                    {automation.name}
                  </Link>
                </div>
              </Typography>
              <Typography size="xs" colorWeight="500">
                <time>Created {moment(automation.created_at).format('LLL')}</time>
              </Typography>
            </TypographyStack>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.TOP}>
          <div className="truncate max-w-xs">
            <Typography size="xs" colorWeight="500">
              {automation.description || '-'}
            </Typography>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Badge className="capitalize" type={badgeAttributesByType[automation.state].type}>
            {badgeAttributesByType[automation.state].icon}
            {automation.state}
          </Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography weight="medium" size="sm" colorWeight="700">
            {formatter.format(automation.stats.running_count)}{' '}
            {pluralize('subscriber', automation.stats.running_count, undefined, true)}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography weight="medium" size="sm" colorWeight="700">
            {formatter.format(automation.stats.completed_count)}{' '}
            {pluralize('subscriber', automation.stats.completed_count, undefined, true)}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography size="sm" colorWeight="700">
            <time title={moment(automation.updated_at).format('LLL')}>{moment(automation.updated_at).fromNow()}</time>
          </Typography>
        </ItemColumn>
        <ItemColumn isSticky className="w-full h-full flex items-center">
          <OptionsDropdown options={options} />
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default ListItem;
