import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowUpRightIcon, BanknotesIcon, ChartBarIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { EmptyCard } from '@/components/ResourceList';
import TabNavigation from '@/components/TabNavigation';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, usePost } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { PostStatus } from '@/interfaces/post';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

import { TierChangesGraph, TierChangeStatsByResource } from '../../../components/_domain/PremiumData';
import NavContainer from '../NavContainer';

interface RouteParams {
  postId: string;
}

enum TabType {
  UPGRADES = 'upgrades',
  DOWNGRADES = 'downgrades',
}

const PostPremiumData = () => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { postId } = useParams() as unknown as RouteParams;
  const { data: post } = usePost({ id: postId });
  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const navigate = useNavigate();

  useEffect(() => {
    if (isV2) {
      return;
    }

    if (currentPublication && !currentPublication.is_premium_enabled) {
      toast.error('You must enable a premium tier');
      navigate(`/posts/${postId}/analytics`);
    }

    if (post && post.status === PostStatus.DRAFT) {
      toast.error('Data only available for published posts');
      navigate(`/posts/${postId}/analytics`);
    }
  }, [currentPublication, isV2, navigate, post, postId]);

  const hasPremiumEnabled = currentPublication?.is_premium_enabled === true;
  const isPostLive = post?.status === PostStatus.PUBLISHED;
  const showEmptyState = !hasPremiumEnabled || !isPostLive;

  const [searchParams] = useSearchParams();
  const activeTabParam = (searchParams.get('tab') as TabType) || TabType.UPGRADES;

  const tabs = [
    {
      name: TabType.UPGRADES,
      label: 'Upgrades',
      selected: activeTabParam === TabType.UPGRADES,
      targetRoute: `/posts/${postId}/premium_data?tab=${TabType.UPGRADES}`,
    },
    {
      name: TabType.DOWNGRADES,
      label: 'Downgrades',
      selected: activeTabParam === TabType.DOWNGRADES,
      targetRoute: `/posts/${postId}/premium_data?tab=${TabType.DOWNGRADES}`,
    },
  ];

  return (
    <NavContainer postId={postId}>
      {() => {
        if (isV2) {
          return (
            <Card>
              <div className="flex flex-col gap-y-6">
                <div className="flex justify-between items-center">
                  <Typography token="font-medium/text/lg">Paid Subscriptions Data</Typography>

                  <div className="flex w-fit">
                    <TabNavigation tabs={tabs} variant="tertiary" size="sm" />
                  </div>
                </div>
                {!isPostLive ? (
                  <EmptyCard
                    title="No Data Available"
                    description="Metrics are only available for published posts."
                    primaryIcon={ChartBarIcon}
                  />
                ) : null}
                {!hasPremiumEnabled ? (
                  <EmptyCard
                    title="No Data Available"
                    description="Enable paid subscriptions to include in future posts."
                    primaryIcon={BanknotesIcon}
                    primaryActionLabel="Enable Paid Subscriptions"
                    primaryActionIcon={ArrowUpRightIcon}
                    iconRight
                    onPrimaryActionClick={() => navigate('/settings/publication/premium')}
                  />
                ) : null}
                {!showEmptyState ? (
                  <TierChangesGraph
                    publicationId={currentPublicationId}
                    resourceType={TierChangeEventResourceType.POST}
                    resourceId={postId}
                    filterByEvent={activeTabParam === TabType.UPGRADES ? 'Upgrades' : 'Downgrades'}
                  />
                ) : null}
              </div>
            </Card>
          );
        }

        return (
          <Card>
            <section>
              <div className="w-full">
                <TierChangeStatsByResource
                  publicationId={currentPublicationId}
                  resourceType={TierChangeEventResourceType.POST}
                  resourceId={postId}
                />
                <TierChangesGraph
                  publicationId={currentPublicationId}
                  resourceType={TierChangeEventResourceType.POST}
                  resourceId={postId}
                />
              </div>
            </section>
          </Card>
        );
      }}
    </NavContainer>
  );
};

export default PostPremiumData;
