import { useCallback } from 'react';
import { TextAlignCenter, TextAlignLeft, TextAlignRight } from '@phosphor-icons/react';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

export const getDefaultAlignment = () => {
  return 'left';
};

type AlignmentSettingsProps = AttributeSettingProps & {
  property?: string;
  title?: string;
};

export const AlignmentSettings = ({
  editor,
  activeNodeResult,
  property = 'textAlign',
  title = 'Align',
}: AlignmentSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const updateAlignment = useCallback(
    (value: string) => {
      if (activeNodePos === undefined || !activeNodeType) return;
      if (!value) {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, property, null);
          return true;
        });
        return;
      }

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="left"
          value={activeNodeAttributes?.[property]}
          onValueChange={updateAlignment}
        >
          <ToggleGroupItem value="left" className="grow">
            <TextAlignLeft weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <ToggleGroupItem value="center" className="grow">
            <TextAlignCenter weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <ToggleGroupItem value="right" className="grow">
            <TextAlignRight weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
