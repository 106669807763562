import { ClockCounterClockwise, Layout as LayoutIcon, Plus } from '@phosphor-icons/react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Frame, { useFrame } from 'react-frame-component';
import { useNavigate, useParams } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { DreamEditorProvider, useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import { ContentTree } from '../_components/ContentTree';
import { ContentTreeProvider } from '../_components/ContentTree/context';
import {
  ATTRIBUTES_PANEL_ID,
  LAYERS_PANEL_ID,
  SIDE_INSERT_PANEL_ID,
  VERSION_HISTORY_PANEL_ID,
} from '../_components/DreamEditor/constants';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import { NavSection } from '../_components/SideNav/NavSection';
import { NavSectionTitle } from '../_components/SideNav/NavSectionTitle';
import { SaveIndicator } from '../_components/SideNav/SaveIndicator';
import { Button, CloseButton } from '../_components/UI/Button';
import { TabsContent, TabsList, TabsTrigger } from '../_components/UI/Tabs';
import { Text } from '../_components/UI/Text';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';

import { Editor } from './_components/Editor';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

const IFRAME_CONTENT_WIDTH = 1024; // px

const IframeWrapper: React.FC<IframeWrapperProps> = ({ children, width, height, iframeProps }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [initialContent, setInitialContent] = useState('');

  useEffect(() => {
    fetch('/canvas/index._html')
      .then((d) => d.text())
      .then(setInitialContent);
  }, []);

  if (!initialContent) return <div>loading..</div>;
  const scale = width / IFRAME_CONTENT_WIDTH;

  return (
    <Frame
      ref={iframeRef}
      initialContent={initialContent}
      {...iframeProps}
      head={
        <style>
          {`
          .ProseMirror {
            outline: none !important;
          }
          .ProseMirror:focus {
            outline: none !important;
            box-shadow: none !important;
          }
          div[data-node-view-display-contents], div[data-node-view-display-contents] > div[data-node-view-content-react] {
            display: contents;
          }
        `}
        </style>
      }
      title="Page Editor Content"
      mountTarget="body"
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: IFRAME_CONTENT_WIDTH,
        height: height / scale,
        border: 'none',
        outline: 'none',
        ...iframeProps?.style,
      }}
    >
      <StyleManager>{children}</StyleManager>
    </Frame>
  );
};

const PageEditorPage = () => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();
  const navigate = useNavigate();
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { pageId } = useParams();
  const { page, onSave } = useDreamEditorContext();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const pageRoute = pageId ? pageRouteGetter?.getPageRouteFromID(pageId) : undefined;
  const homePageId = pageRouteGetter?.getHomePageID();

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion, onSave]);

  useEffect(() => {
    if (pageId && homePageId && !pageRoute) {
      navigate(`/website_builder_v2/page/${homePageId}`);
    }
  }, [pageId, navigate, homePageId, pageRoute]);

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/website_builder_v2',
      }}
      mainClassName="bg-wb-secondary"
      isSidebarResizable
      leftChildren={
        <>
          <ActionMenu
            Icon={Plus}
            text="Insert"
            isActive={isInsertPanelOpen}
            onClick={() => {
              setIsInsertPanelOpen((prev) => !prev);
            }}
          />
          <ActionMenu Icon={LayoutIcon} text="Templates" onClick={() => {}} />
        </>
      }
      rightChildren={
        <Button
          variant="secondary"
          Icon={ClockCounterClockwise}
          iconWeight="bold"
          onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
        />
      }
      sidenavChildren={
        <>
          <TabsList className="p-2" defaultValue="layers">
            <TabsTrigger value="layers">
              <Text weight="medium" size="2xs" className="leading-0">
                Layers
              </Text>
            </TabsTrigger>
            <TabsTrigger value="pages">
              <Text weight="medium" size="2xs" className="leading-0">
                Pages
              </Text>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="layers" id={LAYERS_PANEL_ID} />
          <TabsContent value="pages" asChild>
            <>
              <div className="flex flex-col flex-1 gap-6">
                <NavSection>
                  <NavSectionTitle title="Pages" />
                  {pagesRoutes && (
                    <ContentTreeProvider
                      isAllowDnD={!previewSiteVersion?.id}
                      isShowOptions={!previewSiteVersion?.id}
                      isShowAddPage={!previewSiteVersion?.id}
                      pageLinkPrefix="/website_builder_v2/page"
                      dndDisabledErrorMessage={
                        previewSiteVersion?.id ? "You can't rearrange pages in preview mode." : ''
                      }
                    >
                      <ContentTree route={pagesRoutes} parentPath={[]} slug="" />
                    </ContentTreeProvider>
                  )}
                </NavSection>
                <NavSection>
                  <NavSectionTitle title="Default Pages" />
                  {defaultRoutes && (
                    <ContentTreeProvider
                      dndDisabledErrorMessage={
                        previewSiteVersion?.id
                          ? "You can't rearrange pages in preview mode."
                          : "You can't re-arrange default pages"
                      }
                      isAllowDnD={false}
                      isShowOptions={!previewSiteVersion?.id}
                      isShowAddPage={!previewSiteVersion?.id}
                      pageLinkPrefix="/website_builder_v2/page"
                    >
                      <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
                    </ContentTreeProvider>
                  )}
                </NavSection>
              </div>
              <NavSection>
                <SaveIndicator />
              </NavSection>
            </>
          </TabsContent>
          <div
            id={SIDE_INSERT_PANEL_ID}
            className={cn(
              'absolute top-0 left-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
              isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
            )}
          />
        </>
      }
      rightSideChildren={
        <>
          <div
            className="w-[250px] min-w-[250px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-scroll"
            id={ATTRIBUTES_PANEL_ID}
          />
          <div
            id={VERSION_HISTORY_PANEL_ID}
            className={cn(
              'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
              isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
            )}
          >
            <CloseButton
              onClose={() => setIsVersionHistoryPanelOpen(false)}
              className="-translate-x-1/2 translate-y-1/2"
            />
          </div>
        </>
      }
      titleType="page_name"
    >
      <div ref={editorContainerRef} className="w-full h-full shadow-md bg-wb-primary">
        <IframeWrapper width={editorRect.width || 0} height={editorRect.height || 0}>
          {page ? <Editor /> : null}
        </IframeWrapper>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <DreamEditorProvider>
      <PageEditorPage />
    </DreamEditorProvider>
  </WebsiteProvider>
);
