import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDownTrayIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import CTACard from '@/components/CTACard';
import ExportImage from '@/components/ExportImage';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import * as DropdownMenu from '@/components/UI/Dropdown';
import { usePermissions } from '@/context/permissions-context';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { TimePeriod } from '@/interfaces/time_period';
import { timePeriodLabels } from '@/utils/timePeriods';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';
import getTimePeriodStartDate from '../utils/getTimePeriodDays';

interface ActiveSubscriberChartData {
  Month: String | null;
  Subscribers: Number;
}

const generateFallbackChartData = (timePeriod: TimePeriod, subscriberCount: number): ActiveSubscriberChartData[] => {
  const startDate = getTimePeriodStartDate(timePeriod);
  const endDate = moment();

  return [
    {
      Month: startDate,
      Subscribers: subscriberCount,
    },
    {
      Month: endDate.format('DD MMM YYYY'),
      Subscribers: subscriberCount,
    },
  ];
};

const ActiveSubscribersChart = () => {
  const [exportChartOpen, setExportChartOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { period, isSubscriptionDataLoading, activeSubscriptionsChart, subscriptionKPIs } = usePageContext<PageProps>();
  const { checkPermissions } = usePermissions();
  const canDownloadChart = checkPermissions('views/dashboard/subscription_chart', 'create');

  const memoizedData: ActiveSubscriberChartData[] = useMemo(() => {
    if (activeSubscriptionsChart) {
      return activeSubscriptionsChart.map((item: any) => {
        return {
          Month: item.name,
          Subscribers: item.data.count,
        };
      });
    }

    return [];
  }, [activeSubscriptionsChart]);

  const isAllTime = period === TimePeriod.ALL_TIME;
  const hasNoData = memoizedData.length === 0;
  const noResults = hasNoData && !isSubscriptionDataLoading;

  const chartData = noResults && !isAllTime ? generateFallbackChartData(period, 0) : memoizedData;
  const noResultsFolowUpCheck = chartData.length === 0;
  const firstValue = subscriptionKPIs?.active_subscriptions?.previous_period as number;
  const lastValue = subscriptionKPIs?.active_subscriptions?.period as number;

  const showCTACard = lastValue === 0 && !isSubscriptionDataLoading;

  const generateCsvForChartData = () => {
    const csvHeader = 'data:text/csv;charset=utf-8,';
    const headerRow = 'Date,Subscribers\n';
    const dataRows = chartData.map((dataPoint) => `${dataPoint.Month},${dataPoint.Subscribers}\n`).join('');
    const csvContent = `${csvHeader}${headerRow}${dataRows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Active Subscribers ${timePeriodLabels[period]} - ${moment().format('MMM DD')}.csv`);
    document.body.appendChild(link);

    link.click();
  };

  if (showCTACard) {
    return (
      <CTACard
        variant="secondary"
        title="You have no active subscribers"
        description="Would you like to start by importing a list?"
        ctaText="Import List"
        onActionClick={() => navigate('/settings/publication/import_subscribers')}
        ButtonIcon={PlusIcon}
        Icon={PlusCircleIcon}
      />
    );
  }

  const chartDataActions = () => {
    return(
      <DropdownMenu.Root>
        <DropdownMenu.Button>
          <ArrowDownTrayIcon className="h-4 w-4 text-gray-500" />
        </DropdownMenu.Button>
        <DropdownMenu.Content className="z-10 -ml-36">
          <DropdownMenu.Item
            disabled={!canDownloadChart}
            tooltipText={!canDownloadChart ? NO_PERMISSION_MESSAGE : undefined}
            onClick={() => {setExportChartOpen(true)}}
          >
            Download Chart
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            disabled={!canDownloadChart}
            tooltipText={!canDownloadChart ? NO_PERMISSION_MESSAGE : undefined}
            onClick={() => {generateCsvForChartData()}}
          >
            Download CSV
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    )
  };

  return (
    <ChartCard
      title="Active Subscribers"
      description="Shows all active subscribers"
      actionChildren={chartDataActions()}
      isLoading={isSubscriptionDataLoading}
      noResultsText="No active subscribers within the chosen time range."
      noResults={noResultsFolowUpCheck && !isSubscriptionDataLoading}
    >
      <AreaChart
        data={chartData}
        categories={['Subscribers']}
        index="Month"
        startEndOnly
        showGridLines={false}
        allowDecimals={false}
        minValue={firstValue || 0}
        maxValue={lastValue || 0}
        colors={['pink']}
        valueFormatter={(number: number) => getLocaleString(number)}
      />
      <ExportImage
        isOpen={exportChartOpen}
        setIsOpen={setExportChartOpen}
        title="Active Subscribers"
        description="Shows all active subscribers"
        firstValue={firstValue}
        lastValue={lastValue}
        chartData={chartData}
        categories={['Subscribers']}
        index="Month"
        colors={['pink']}
        valueFormatter={(number: number) => getLocaleString(number)}
      />
    </ChartCard>
  );
};

export default ActiveSubscribersChart;
