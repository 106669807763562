import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

import { Input, Switch } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { Publication } from '@/interfaces/publication';
import analytics from '@/utils/analytics';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import FormContentPreviewContainer from '../components/FormContentPreviewContainer';
import FormRow from '../components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const SendingDetailsForm = ({ publication }: Props) => {
  const { handleChangeInAttribute, savingAttribute } = usePublicationSettings();
  const { currentUser } = useCurrentUser();

  return (
    <BodyContainer>
      <CardHeader title="Email Sending Details" description="Configure your email sending information" />
      <FormRow label="Use Post’s Author Name as Sender">
        <Switch
          variant="primary"
          checked={publication.display_authors_as_post_email_sender_name}
          name="display_authors_as_post_email_sender_name"
          onChange={(_name: string, value: boolean) => {
            analytics.track('Use author as sender name for posts');
            handleChangeInAttribute('display_authors_as_post_email_sender_name')(value);
          }}
          disabled={savingAttribute === 'display_authors_as_post_email_sender_name'}
        />
      </FormRow>
      <Input
        labelText="Sender Name"
        name="email_sender_name"
        disabled={publication.display_authors_as_post_email_sender_name || savingAttribute === 'email_sender_name'}
        defaultValue={publication.email_sender_name}
        onBlur={(e) => handleChangeInAttribute('email_sender_name')(e.target.value)}
      />
      {!publication.display_authors_as_post_email_sender_name ? (
        <FormContentPreviewContainer className="flex flex-row gap-x-6 items-center">
          <Typography token="font-normal/text/sm" colorWeight="500">
            From:{' '}
          </Typography>
          <span>
            <Typography token="font-medium/text/sm">{publication.email_sender_name} </Typography>
            <Typography token="font-normal/text/sm" colorWeight="500">
              {`<${publication.email_reply_to_address || publication.owner_email}>`}
            </Typography>
          </span>
        </FormContentPreviewContainer>
      ) : null}
      <Input
        labelText="Reply To Address"
        name="email_reply_to_address"
        disabled={savingAttribute === 'email_reply_to_address'}
        placeholder={publication.owner_email}
        defaultValue={publication.email_reply_to_address}
        onBlur={(e) => {
          const newValue = e.target.value;
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
          });
          analytics.group(publication.id, {
            email_reply_to_address: newValue,
            object_type_id: 1,
          });

          analytics.track('Set up Reply to Address');
          handleChangeInAttribute('email_reply_to_address')(newValue);
        }}
      />
      <FormContentPreviewContainer className="flex flex-row gap-x-6 items-center">
        <span className="inline-flex gap-x-2 items-center">
          <ArrowUturnLeftIcon className="w-4 h-4 text-surface-500" />
          <Typography token="font-normal/text/sm" colorWeight="500">
            Reply-to:{' '}
          </Typography>
        </span>
        <span>
          <Typography token="font-medium/text/sm">{publication.email_sender_name} </Typography>
          <Typography token="font-normal/text/sm" colorWeight="500">
            {`<${publication.email_reply_to_address || publication.owner_email}>`}
          </Typography>
        </span>
      </FormContentPreviewContainer>
    </BodyContainer>
  );
};

export default SendingDetailsForm;
