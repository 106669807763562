import { useEffect, useMemo, useState } from 'react';

import { Typography } from '@/components/Typography';
import { usePostTargets } from '@/hooks/usePostTargets';
import { useTiers } from '@/hooks/useTiers';
import { PostTargetPlatform } from '@/interfaces/post_target';
import { Tier } from '@/interfaces/tier';
import { Card } from '@/ui/Card';
import { capitalize } from '@/utils';

import { usePostContext } from '../PostContext';

import EmailAudience from './EmailAudience';
import WebAudience from './WebAudience';

const Audience = () => {
  const [paywallBreakPriceId, setPaywallBreakPriceId] = useState<string>('');
  const [paidTiers, setPaidTiers] = useState<Tier[]>([]);

  // Hooks
  const { formData, onChange } = usePostContext();
  const { data: webPostTargets, isLoading: webPostSendTargetsLoading } = usePostTargets(
    formData?.id || '',
    PostTargetPlatform.WEB
  );
  const { data: emailPostTargets, isLoading: emailPostSendTargetsLoading } = usePostTargets(
    formData?.id || '',
    PostTargetPlatform.EMAIL
  );
  const { data: tiers = [] } = useTiers({ includeActiveSubscriptionsCount: true });

  // Memoized values
  const emailPostSendTargets = useMemo(() => emailPostTargets?.post_targets || [], [emailPostTargets]);
  const webPostSendTargets = useMemo(() => webPostTargets?.post_targets || [], [webPostTargets]);

  // Effects
  useEffect(() => {
    setPaywallBreakPriceId(formData?.paywall_break_price_id || '');
  }, [formData]);

  useEffect(() => {
    setPaidTiers(tiers);
  }, [tiers]);

  useEffect(() => {
    if (paywallBreakPriceId === '') {
      const tierIds = webPostSendTargets.map((pst) => pst.tier_id);
      const priceIds = tiers.filter((t) => tierIds.includes(t.id)).flatMap((t) => t.prices.map((p) => p.id));
      const initialPrice = priceIds[0];

      if (initialPrice) {
        onChange({ paywall_break_price_id: initialPrice });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paywallBreakPriceId]);

  if (!formData) {
    return null;
  }

  let targetNames;

  if (emailPostSendTargets.length > 0 && webPostSendTargets.length === 0) {
    targetNames = `${capitalize(PostTargetPlatform.EMAIL)} Only`;
  } else if (emailPostSendTargets.length === 0 && webPostSendTargets.length > 0) {
    targetNames = `${capitalize(PostTargetPlatform.WEB)} Only`;
  } else if (emailPostSendTargets.length > 0 && emailPostSendTargets.length > 0) {
    targetNames = `${capitalize(PostTargetPlatform.EMAIL)} and ${capitalize(PostTargetPlatform.WEB)}`;
  } else {
    targetNames = 'None';
  }

  targetNames = targetNames && targetNames.charAt(0).toUpperCase() + targetNames.slice(1);

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6 overflow-auto">
      <div className="w-full md:w-2/3 mx-auto flex flex-col max-w-[40rem]">
        <Card className="w-full mx-auto flex rounded-lg max-w-[40rem] border-surface-200 rounded-b-none bg-surface-50">
          <Typography token="font-normal/text/base" colorWeight="500">
            Publish to
          </Typography>
          <Typography token="font-medium/text/base" colorWeight="900" className="ml-1">
            {targetNames}
          </Typography>
        </Card>
        <EmailAudience
          display
          emailPostSendTargetsLoading={emailPostSendTargetsLoading}
          emailPostSendTargets={emailPostSendTargets}
          formData={formData}
          paidTiers={paidTiers}
        />
        <WebAudience
          display
          formData={formData}
          paidTiers={paidTiers}
          webPostSendTargetsLoading={webPostSendTargetsLoading}
          webPostSendTargets={webPostSendTargets}
        />
      </div>
    </div>
  );
};

export default Audience;
