import { FC } from 'react';

import { Select } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { optionDataToOptions } from '@/utils';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const StatusInput: FC<Props> = ({ value, onChange }) => {
  const publicationId = useCurrentPublicationId();
  const statusData = useOptions(publicationId, 'subscription_statuses', Infinity);
  const statusOptions = optionDataToOptions(statusData);

  return (
    <Select
      className="w-full"
      name="value"
      placeholderText="Select Status"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={statusOptions}
    />
  );
};

export default StatusInput;
