import React, { CSSProperties, DragEventHandler, useRef } from 'react';
import { DotsSix } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { NodeRangeSelection } from '@tiptap-pro/extension-node-range';

import colors from '@/components/TiptapEditor/lib/colors';

const defaultHiddenStyle = {
  position: 'absolute',
  left: -1000,
  top: -1000,
  width: 0,
  height: 0,
} as React.CSSProperties;

// DragHandle is not used yet in the new editor. This code is
// still under heavy development
export const DragHandle = ({ editor }: { editor: Editor }) => {
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const selection = editor.storage.hover.nodeSelection as NodeRangeSelection | null;
  const node = editor.storage.hover.node as Element | null;

  const handleDragStart: DragEventHandler<HTMLDivElement> = (e) => {
    const { view } = editor;
    // console.log('handleDragStart', node);

    if (!selection) return;

    // Get the selected content
    const slice = selection.content();

    // Inform ProseMirror of the drag operation
    view.dragging = { slice, move: true };

    // Prevent duplication of the node when dropped by dispatching the transaction
    const tr = editor.state.tr.setSelection(selection);
    editor.view.dispatch(tr);

    // Create and style the drag preview container
    const dragPreviewContainer = document.createElement('div');
    dragPreviewContainer.style.position = 'absolute';
    dragPreviewContainer.style.left = '0';
    dragPreviewContainer.style.top = '0';
    dragPreviewContainer.style.padding = '4px';
    dragPreviewContainer.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
    dragPreviewContainer.style.maxHeight = '100px';
    dragPreviewContainer.style.maxWidth = '700';
    dragPreviewContainer.style.overflow = 'hidden';
    dragPreviewContainer.style.pointerEvents = 'none';
    dragPreviewContainer.style.zIndex = '9999';

    // Clone the node and apply necessary styles
    if (node) {
      const clonedNode = node.cloneNode(true) as HTMLElement;
      clonedNode.style.pointerEvents = 'none';
      clonedNode.style.userSelect = 'none';
      clonedNode.style.transformOrigin = 'top left';
      dragPreviewContainer.appendChild(clonedNode);
    } else {
      // Fallback content if node is not available
      dragPreviewContainer.textContent = 'Dragging...';
    }

    // Append the preview to the document body
    document.body.appendChild(dragPreviewContainer);

    // Set the custom drag image with adjusted offset
    e.dataTransfer.setDragImage(dragPreviewContainer, 0, 0);

    // Remove the preview element after a short delay
    setTimeout(() => {
      document.body.removeChild(dragPreviewContainer);
    }, 0);
  };

  let style = {} as CSSProperties;

  if (node) {
    const rect = node?.getBoundingClientRect();
    const parentRect = editor.view.dom.getBoundingClientRect();
    const nodeWidth = rect ? rect.right - rect.left : 0;

    if (parentRect) {
      style = {
        position: 'absolute',
        top: rect.top - parentRect.top,
        left: rect.left + nodeWidth / 2 - parentRect.left,
        transform: 'translateX(-50%)',
        cursor: 'grab',
        userSelect: 'none',
        pointerEvents: 'all',
        color: `${colors.pink[7]}`,
      };
    }
  } else {
    style = defaultHiddenStyle;
  }

  return (
    <div ref={dragHandleRef} style={{ cursor: 'grab', ...style }} draggable onDragStart={handleDragStart}>
      <DotsSix size={20} weight="bold" />
    </div>
  );
};
