import { PropsWithChildren, Suspense, useCallback, useEffect, useState } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import useIsSettingPage from '@/hooks/useIsSettingPage';
import useIsWorkspaceSettingPage from '@/hooks/useIsWorkspaceSettingPage';
import { SETTINGS_ORGANIZATION_ID_PARAM_NAME } from '@/hooks/useOrganization/useCurrentOrganizationId';
import { usePublication } from '@/hooks/usePublications';
import { SETTINGS_PUBLICATION_ID_PARAM_NAME } from '@/hooks/usePublications/useCurrentPublicationId';
import Loading from '@/pages/Loading';

type Props = PropsWithChildren<{
  hasSettingsV2: boolean;
}>;

/*

  This component makes sure that the query parameters required to render
  settings v2 always exist in the URL. The required query parameters are the
  following;
  
  1. s_publication_id - Required for all pages in namespace `/settings/*`
  2. s_organization_id - Only required for pages in namespace `/settings/workspace/*`

  Scenario: A user lands on `https://app.beehiiv.com/settings/publication/general`
            and the url does not have the query param `s_publication_id`
  
  Solution: This component will assure that the said parameter is appended in the
            url based on user's currently selected publication id
*/

export default function EnsureSettingsQueryParams({ children, hasSettingsV2 }: Props) {
  const [isLoading, setIsLoading] = useState(hasSettingsV2);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const publicationIdInUrl = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);
  const organizationIdInUrl = searchParams.get(SETTINGS_ORGANIZATION_ID_PARAM_NAME);

  const [currentPublicationId] = useCurrentPublicationState();
  const isASettingsPage = useIsSettingPage();
  const isAWorkspaceSettingPage = useIsWorkspaceSettingPage();
  const shouldFetchPublication = isAWorkspaceSettingPage && !organizationIdInUrl;
  const { data: publication, isSuccess } = usePublication(currentPublicationId, shouldFetchPublication);

  const redirectIfRequired = useCallback(() => {
    if (!hasSettingsV2) {
      setIsLoading(false);
      return;
    }

    if (!isASettingsPage) {
      setIsLoading(false);
      return;
    }

    if (isASettingsPage && !isAWorkspaceSettingPage && publicationIdInUrl) {
      setIsLoading(false);
      return;
    }

    if (isASettingsPage && isAWorkspaceSettingPage && publicationIdInUrl && organizationIdInUrl) {
      setIsLoading(false);
      return;
    }

    const redirectUrl = new URL(window.location.pathname, window.location.origin);

    redirectUrl.searchParams.set(SETTINGS_PUBLICATION_ID_PARAM_NAME, currentPublicationId);

    if (isAWorkspaceSettingPage && !organizationIdInUrl) {
      redirectUrl.searchParams.set(SETTINGS_ORGANIZATION_ID_PARAM_NAME, publication?.organization_id || '');
    }

    window.location.href = redirectUrl.toString();
  }, [
    currentPublicationId,
    hasSettingsV2,
    isASettingsPage,
    isAWorkspaceSettingPage,
    organizationIdInUrl,
    publication?.organization_id,
    publicationIdInUrl,
  ]);

  useEffect(() => {
    if (!shouldFetchPublication) {
      redirectIfRequired();
    } else if (isSuccess) {
      redirectIfRequired();
    }
  }, [redirectIfRequired, isSuccess, shouldFetchPublication]);

  return <Suspense fallback={<Loading />}>{!isLoading ? children : null}</Suspense>;
}
