import { mergeAttributes } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { BacklinkView } from './views/BacklinkView';
import { BACKLINK_TYPE } from './const';
import { backlinkSuggestions } from './suggestions';
import { TBacklinkAttrs } from './types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    backlink: {
      /**
       * Set the backlink
       */
      setBacklink: (attrs: TBacklinkAttrs) => ReturnType;
    };
  }
}

export const Backlink = Mention.extend({
  name: BACKLINK_TYPE,

  draggable: true,

  selectable: true,

  addOptions() {
    return {
      ...this.parent?.(),
      suggestion: backlinkSuggestions(() => document.body),
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({ 'data-target': attributes.target }),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({ 'data-title': attributes.title }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${BACKLINK_TYPE}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setBacklink:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs,
            })
            .run();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(BacklinkView);
  },
});

export default Backlink;
