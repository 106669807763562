import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { CustomField } from '../interfaces/custom_field';
import { Pagination } from '../interfaces/general';
import api from '../services/swarm';

interface ApiResponse {
  customFields: CustomField[];
  pagination: Pagination;
}

export default function useCustomFields({
  search,
  perPage = 10,
  all = false,
  includeSubscriberPreferences = false,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  includeSubscriberPreferences?: boolean;
}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchCustomFields = ({ pageParam = 1 }) =>
    api
      .get(`/custom_fields`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          include_subscriber_preferences: includeSubscriberPreferences,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'customFields', search], fetchCustomFields, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const customFields = data.pages.flatMap((page) => page.customFields);

      customFields.forEach((customFieldPreview: any) => {
        queryClient.setQueryData(['customFields', customFieldPreview?.id], {
          id: customFieldPreview?.id,
          name: customFieldPreview?.name,
          description: customFieldPreview?.description,
        });
      });
    },
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
  });
}
