import { FC, Fragment, useRef } from 'react';
import toast from 'react-hot-toast';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';

import Input from '@/components/Form/Input';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { ContentTag } from '@/interfaces/content_tag';
import api from '@/services/swarm';
import { Button, ButtonProps } from '@/ui/Button';

import { useContentTagsContext } from '../ContentTagsProvider';

export interface Props {
  isOpen: boolean;
  isWorking?: boolean;
  onClose: () => void;
  onCreate: (newContentTag: ContentTag) => void;
  modalHeightClassName?: string;
  disabled?: boolean;
  buttonType?: ButtonProps['variant'];
}

const CreateContentTagModal: FC<Props> = (props: Props) => {
  const { refetchContentTags } = useContentTagsContext();

  const {
    isOpen,
    isWorking,
    onClose,
    onCreate,
    modalHeightClassName,
    disabled = false,
    buttonType = 'primary',
  } = props;
  const cancelRef = useRef(null);
  const [publicationId] = useCurrentPublicationState();

  const handleContentTagSave = async (formData: FormData) => {
    try {
      const response = await api.post<ContentTag>('/content_tags', {
        publication_id: publicationId,
        content_tag: {
          display: formData.get('content_tag[display]'),
          description: formData.get('content_tag[description]'),
        },
      });

      onCreate(response.data);
      refetchContentTags();
      onClose();
      toast.success('Content Tag was created and selected!');
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    handleContentTagSave(formData);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <section className="modal z-50 fixed inset-0 w-screen overflow-auto">
            <div className="flex min-h-full justify-center p-4 items-center">
              <Dialog.Panel
                className="model-guts flex flex-col mx-auto bg-white rounded-lg text-left shadow-xl w-full md:max-w-xl"
                id="add-guest-author-modal-panel"
              >
                <form onSubmit={handleSubmit}>
                  <input type="hidden" value={publicationId} name="publication_id" />
                  <section className="p-6 overflow-y-auto">
                    <div className={cx('flex flex-col sm:flex-row gap-4', modalHeightClassName)}>
                      <div className="w-full flex flex-col">
                        <div className="w-full flex flex-row space-x-4">
                          <div className="flex flex-col">
                            <p className="mb-1 text-lg font-semibold">New Content Tag</p>
                          </div>
                        </div>
                        <div className="mt-10">
                          <Input
                            labelText="Display Name"
                            type="text"
                            label="Display Name*"
                            name="content_tag[display]"
                            placeholder="Display Name"
                            required
                          />
                          <div className="mt-6">
                            <Input
                              labelText="Description"
                              type="text"
                              label="Description"
                              name="content_tag[description]"
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="flex justify-between items-center bg-[#F9FAFB] rounded-b-lg py-4 px-6 mt-auto">
                    <div className="flex items-center gap-3 ml-auto">
                      <Button ref={cancelRef} type="button" variant="primary-inverse" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button variant={buttonType} type="submit" disabled={disabled} loading={isWorking}>
                        Save Content Tag
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </section>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateContentTagModal;
