import LoadingBox from '@/components/LoadingBox';
import { useOrganization } from '@/hooks';
import { useCurrentOrganizationId } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Card } from '@/ui/Card';

import PageContainer from '../../publication/components/PageContainer';

import AddressForm from './AddressForm';
import DeleteWorkspaceForm from './DeleteWorkspaceForm';
import OverviewForm from './OverviewForm';
import PublicationsList from './PublicationsList';

const WorkspaceOverview: React.FC = () => {
  const currentOrganizationId = useCurrentOrganizationId();
  const currentPublicationId = useCurrentPublicationId();
  const {
    data: organizationData,
    isLoading: isLoadingOrgnanization,
    isError: isErrorOrgnanization,
  } = useOrganization(currentOrganizationId, currentPublicationId);
  const { organization } = organizationData || {};

  return (
    <LoadingBox isLoading={isLoadingOrgnanization} isError={isErrorOrgnanization}>
      {organization ? (
        <PageContainer key={`${organization.id}-${currentPublicationId}`}>
          <Card>
            <OverviewForm organization={organization} publicationId={currentPublicationId} />
          </Card>
          <Card>
            <AddressForm organization={organization} publicationId={currentPublicationId} />
          </Card>
          <Card>
            <DeleteWorkspaceForm organization={organization} publicationId={currentPublicationId} />
          </Card>
          <Card>
            <PublicationsList organization={organization} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default WorkspaceOverview;
