import React from 'react';

import { Input } from '@/components/Form';

import BodyContainer from '../../components/BodyContainer';
import { StepProps } from '../new/types';

const WordpressUrlForm: React.FunctionComponent<StepProps> = ({ contentImport, updateContentImport, errors }: StepProps) => (
  <BodyContainer>
    <Input
      leadingText="https://"
      placeholderText="publication.wordpress.com"
      name="url"
      defaultValue={contentImport.url}
      onBlur={(e) => updateContentImport({ url: e.target.value })}
      helperText="Input the blog URL that will be used to pull content and information during the import process."
      errorText={errors?.url}
    />
  </BodyContainer>
);

export default WordpressUrlForm;
