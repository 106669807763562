import { useEffect, useState } from 'react';
import { Link as LinkIcon } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';

import { ImageInput } from '../../_components/UI/ImageInput';
import { Text } from '../../_components/UI/Text';

import SettingsInput from './SettingsInput';
import SettingsItem from './SettingsItem';
import { XIcon } from './XIcon';

export function XSettings({
  data,
  onUpdate,
  isAccordionItem = false,
  isPage = false,
  onUpdateImage,
}: {
  data: SiteVersion | PageVersion | undefined;
  onUpdate: (key: string, value: string | undefined | null) => void;
  isAccordionItem?: boolean;
  isPage?: boolean;
  onUpdateImage: (file: string | File | any) => void;
}) {
  const currentPublication = useCurrentPublication();

  const [xTitle, setXTitle] = useState<string>(data?.metadata?.x_title || '');
  const [xDescription, setXDescription] = useState<string>(data?.metadata?.x_description || '');
  const [xUsername, setXUsername] = useState<string>(data?.metadata?.x_username || '');
  const xImageUrl = data?.metadata?.x_image_url;

  useEffect(() => {
    setXTitle(data?.metadata?.x_title || '');
    setXDescription(data?.metadata?.x_description || '');
    setXUsername(data?.metadata?.x_username || '');
  }, [data]);

  const defaultTitle = data?.metadata?.meta_title || currentPublication?.data?.name || '';
  const defaultDescription = data?.metadata?.meta_description || `A website for ${currentPublication?.data?.name}`;
  const defaultUsername = '@username';

  return (
    <SettingsItem
      title="Twitter (X) Card"
      description={`Customize how your ${isPage ? 'page' : 'website'} looks like when shared on Twitter (X).`}
      isAccordionItem={isAccordionItem}
    >
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">
          <ImageInput
            labelText="Image"
            value={data?.metadata?.x_image_url || null}
            onSave={(file) => {
              onUpdateImage(file);
            }}
            width={100}
            height={100}
            onRemove={() => {
              onUpdate('x_image_url', null);
            }}
          />

          <SettingsInput
            name="x_title"
            labelText="Title"
            type="input"
            placeholder={defaultTitle}
            value={xTitle}
            onSave={async (value: string) => {
              if (value === xTitle) return;
              onUpdate('x_title', value);
            }}
            onChangeMutateValue={(val: string) => {
              setXTitle(val);
              return val;
            }}
          />

          <SettingsInput
            name="x_description"
            labelText="Description"
            type="textarea"
            placeholder={defaultDescription}
            value={xDescription}
            onSave={async (value: string) => {
              if (value === xDescription) return;
              onUpdate('x_description', value);
            }}
            onChangeMutateValue={(val: string) => {
              setXDescription(val);
              return val;
            }}
          />

          <SettingsInput
            name="x_username"
            labelText="Username"
            type="input"
            placeholder={defaultUsername}
            value={xUsername}
            onSave={async (value: string) => {
              if (value === xUsername) return;
              onUpdate('x_username', value);
            }}
            onChangeMutateValue={(val: string) => {
              setXUsername(val);
              return val;
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Twitter (X) Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md flex flex-col gap-4 mb-2">
            <div className="flex items-center gap-2">
              <div className="bg-wb-surface rounded-full w-8 h-8" />
              <div className="flex flex-col flex-1">
                <Text size="xs" weight="bold">
                  Name
                </Text>
                <Text size="2xs" variant="secondary" weight="regular">
                  {xUsername || defaultUsername}
                </Text>
              </div>
              <XIcon />
            </div>

            <div className="flex flex-col gap-2">
              <div className="w-full h-3 bg-wb-secondary rounded-full" />
              <div className="w-3/4 h-3 bg-wb-secondary rounded-full" />
            </div>

            <div className="flex flex-col bg-wb-background rounded-2xl border border-wb-border overflow-hidden">
              {xImageUrl && <img src={xImageUrl} className="w-full h-[180px] object-cover" alt="X Card Preview" />}
              <div className="w-full p-3 flex flex-col">
                <Text size="sm">{xTitle || defaultTitle}</Text>
                <Text size="sm" variant="secondary">
                  {xDescription || defaultDescription}
                </Text>
                <div className="flex items-center gap-1">
                  <LinkIcon size={14} weight="bold" className="text-wb-secondary" />
                  <Text size="sm" variant="secondary">
                    {currentPublication?.data?.hostname}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Text size="sm" weight="bold">
              How do I refresh this card?
            </Text>
            <Text size="sm" variant="secondary">
              Twitter(X) crawls your site to update Twitter Cards on a weekly basis. It might take a while for changes
              to be reflected.{' '}
              <a
                href="https://developer.x.com/en/docs/twitter-for-websites/cards/guides/troubleshooting-cards#refreshing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-wb-accent"
              >
                Click here
              </a>{' '}
              for more information.
            </Text>
          </div>
        </div>
      </div>
    </SettingsItem>
  );
}
