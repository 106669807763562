import { useCallback } from 'react';

import { Switch } from '../../../UI/Switch';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

type BooleanSettingsProps = AttributeSettingProps & {
  property?: string;
  title?: string;
};

export const BooleanSettings = ({
  editor,
  activeNodeResult,
  property = 'enabled',
  title = 'Enabled',
}: BooleanSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const handleBooleanChange = useCallback(
    (value: boolean) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow flex items-center">
        <Switch
          checked={activeNodeAttributes?.[property] || false}
          onCheckedChange={(value) => handleBooleanChange(value)}
        />
      </div>
    </div>
  );
};
