import { PropsWithChildren } from 'react';

import { Button } from '../../../UI/Button';
import { Text } from '../../../UI/Text';

type Props = PropsWithChildren<{
  title: string;
}>;

const RemovableSectionRenderer = ({ title, children }: Props) => {
  return (
    <div className="flex flex-col">
      <div className='flex items-center justify-between py-6'>
        <Text size="xs" weight="semibold">
          {title}
        </Text>
        <Button variant='ghost' size="sm">
          <Text size="xs" weight="semibold" variant='accent'>
            Remove
          </Text>
        </Button>
      </div>
      <div className='flex flex-col gap-4 pb-6'>
        {children}
      </div>
    </div>
  );
};

export default RemovableSectionRenderer;
