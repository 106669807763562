import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { Instance as TippyInstance } from 'tippy.js';

import { BACKLINK_TYPE } from '../const';

import { BacklinkSuggestionsModal } from './BacklinkSuggestionsModal';

export const backlinkSuggestions = (container: () => HTMLElement): Omit<SuggestionOptions<any>, 'editor'> => ({
  char: '[[',
  pluginKey: new PluginKey('backlinkSuggestions'),
  allowSpaces: true,
  decorationClass: 'backlink-suggestion-pending',
  items: () => {
    return [];
  },
  command: ({ editor, range, props }) => {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const { nodeAfter } = editor.view.state.selection.$to;
    const overrideSpace = nodeAfter?.text?.startsWith(' ');

    if (overrideSpace) {
      // eslint-disable-next-line no-param-reassign
      range.to += 1;
    }

    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: BACKLINK_TYPE,
          attrs: props,
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();

    window.getSelection()?.collapseToEnd();
  },
  render() {
    let component: ReactRenderer | null = null;
    let popup: TippyInstance[] | null = null;

    return {
      onStart: (props) => {
        component = new ReactRenderer(BacklinkSuggestionsModal, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect || !component.element) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as () => DOMRect,
          sticky: true,
          appendTo: container,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);

        if (!props.clientRect || !popup) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as () => DOMRect,
        });
      },

      onExit() {
        if (popup) {
          popup[0].destroy();
        }

        component?.destroy();
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();

          return true;
        }

        return false;
      },
    };
  },
});
