import { Fragment, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { Button } from '@/ui/Button';

import { NavItem } from './NavItem';

interface Link {
  title: string;
  to: string;
  disabled?: boolean;
}

interface Props {
  id: string;
  navMenuOpen: string;
  setNavMenuOpen: (id: string) => void;
  forceOpen?: boolean;
  Icon: React.ElementType;
  BoldIcon: React.ElementType;
  title: string;
  largeNavOpen: boolean;
  links: Link[];
  disabled?: boolean;
}

interface NavDropdownProps {
  links: Link[];
  isOpen: boolean;
  setNavMenuOpen: (id: string) => void;
}

const NavDropdown = ({ links, isOpen, setNavMenuOpen }: NavDropdownProps) => {
  const navigate = useNavigate();

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={cx(
          'z-50 w-40 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          'left-10 top-10'
        )}
      >
        <div className="flex p-2 flex-col space-y-1">
          {links.map((link) => (
            <Menu.Item key={link.title}>
              {link.disabled ? (
                <Tooltip
                  tooltipClass="text-center"
                  id={`small-nav-${link.title}-tooltip`}
                  text={NO_PERMISSION_MESSAGE}
                  showIcon={false}
                  autoWidth={false}
                  placement="right"
                  isEnabled={link.disabled}
                >
                  <div className="px-3 py-2 flex transition ease-in-out flex-col hover:bg-surface-100 rounded-lg duration-300 cursor-not-allowed">
                    <Typography token="font-medium/text/sm" colorWeight="400" className="text-start">
                      {link.title}
                    </Typography>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  onClick={() => {
                    setNavMenuOpen('');
                    navigate(link.to);
                  }}
                  type="button"
                  variant="flush"
                  size="sm"
                  className="text-start"
                >
                  <span className="w-full text-start">{link.title}</span>
                </Button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  );
};

const NavMenu = ({
  id,
  navMenuOpen,
  setNavMenuOpen,
  Icon,
  BoldIcon,
  title,
  largeNavOpen,
  links,
  forceOpen,
  disabled = false,
}: Props) => {
  const { pathname } = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const isActive = links.some((link) => pathname.includes(link.to));
  const isNavMenuOpen = navMenuOpen === id || forceOpen;
  const textColor = disabled ? 'text-surface-400' : 'text-surface-700';

  useClickOutside(() => {
    if (isNavMenuOpen && !largeNavOpen) {
      setNavMenuOpen('');
    }
  }, menuRef);

  const onClick = () => {
    if (isNavMenuOpen) {
      setNavMenuOpen('');
    } else {
      setNavMenuOpen(id);
    }
  };

  return (
    <Menu ref={menuRef} as="div" className="relative my-auto h-fit flex flex-col text-left">
      <Menu.Item>
        <Tooltip
          tooltipClass="text-center"
          id={`large-nav-${title}-tooltip`}
          text={NO_PERMISSION_MESSAGE}
          showIcon={false}
          autoWidth={false}
          placement="right"
          isEnabled={disabled}
        >
          <button
            type="button"
            data-tip
            data-for={`nav-tooltip-${title}`}
            onClick={onClick}
            className={cx(
              'p-2 flex transition ease-in-out flex-col hover:bg-surface-100 rounded-lg duration-300',
              !largeNavOpen && 'aspect-1 flex flex-col justify-center items-center',
              !largeNavOpen && isActive && 'border border-surface-200 bg-surface-100',
              largeNavOpen ? 'w-full' : 'w-fit mx-auto'
            )}
            disabled={disabled}
          >
            <div className={cx('flex flex-row justify-between', largeNavOpen && 'w-full')}>
              <div className="flex flex-row w-full space-x-2">
                {isActive ? (
                  <BoldIcon className={cx('h-4 my-auto w-4', textColor)} />
                ) : (
                  <Icon className={cx('h-4 my-auto w-4', textColor)} />
                )}
                {largeNavOpen && (
                  <Typography token="font-medium/text/sm" colorWeight={disabled ? '400' : '900'} className="my-auto">
                    {title}
                  </Typography>
                )}
              </div>
              {largeNavOpen && (
                <ChevronDownIcon
                  className={cx('h-4 w-4 my-auto duration-300 rotate-0', textColor, isNavMenuOpen && 'rotate-180')}
                />
              )}
            </div>
          </button>
        </Tooltip>
      </Menu.Item>
      {isNavMenuOpen && largeNavOpen ? (
        <div className="w-full flex flex-col">
          {links.map((link) => (
            <NavItem title={link.title} to={link.to} key={link.title} disabled={link.disabled} />
          ))}
        </div>
      ) : null}
      {!largeNavOpen && <NavDropdown links={links} isOpen={Boolean(isNavMenuOpen)} setNavMenuOpen={setNavMenuOpen} />}
      {!largeNavOpen && !disabled && (
        <ReactTooltip id={`nav-tooltip-${title}`} effect="solid" place="right">
          {title}
        </ReactTooltip>
      )}
    </Menu>
  );
};

export default NavMenu;
