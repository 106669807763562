import cx from 'classnames';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { getColor } from '@/components/DesignTokens';
import { PublicationLogo } from '@/components/PublicationLogo';
import { Typography, TypographyStack } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { BoostAgreement } from '@/interfaces/boosts/monetize/boost_agreement';
import { BoostPublication } from '@/interfaces/boosts/monetize/boost_publication';

interface Props {
  firstBoostAgreement: BoostAgreement;
  publication: BoostPublication;
  affiliateLink: string;
}

const PublicationCard = ({ firstBoostAgreement, publication, affiliateLink }: Props) => {
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen || false;

  return (
    <div className={cx('flex flex-col rounded-lg border overflow-hidden', getColor('surface/200', 'border'))}>
      <div className="flex flex-col gap-y-4 justify-between bg-white p-5">
        <div className="flex gap-x-4">
          <PublicationLogo size="lg" publication={publication} />
          <div className="flex flex-col">
            <TypographyStack>
              <Typography token="font-semibold/text/2xl" colorWeight="900">
                {publication.name}
              </Typography>
            </TypographyStack>
          </div>
        </div>
        {publication.description && (
          <div className="flex flex-col sm:flex-row gap-x-1">
            <Typography token="font-medium/text/sm" colorWeight="700">
              Target Audience:
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700">
              {publication.description}
            </Typography>
          </div>
        )}
        {publication.tags.length > 0 && (
          <div className={cx('flex gap-x-2')}>
            {publication.tags.map((tag) => (
              <Badge type="tertiary">{tag.name}</Badge>
            ))}
          </div>
        )}
        <a href={publication.url} target="_blank" rel="noreferrer">
          <Typography token="font-normal/text/sm" colorWeight="600" color="tertiary">
            {publication.hostname}
          </Typography>
        </a>
      </div>
      <div className={cx('flex justify-between items-center px-5 py-3.5', getColor('surface/100', 'background'))}>
        <Typography token="font-normal/text/xs">
          Partner since{' '}
          <time dateTime={firstBoostAgreement.created_at as string}>
            {moment(firstBoostAgreement.created_at).fromNow()}
          </time>{' '}
        </Typography>
        {!boostsFrozen && (
          <UrlCopy
            labelText="Direct Link"
            url={affiliateLink}
            tooltip="This link goes directly to your partner's subscribe page."
          />
        )}
      </div>
    </div>
  );
};

export default PublicationCard;
