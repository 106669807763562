import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';

import { DataExport, DataExportType } from '@/interfaces/data_export';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface DataExportsIndexResponse {
  exports: DataExport[];
}

interface DataExportsCreateResponse {
  export: DataExport;
}

interface ExportDataContextProps {
  areSubscribersExporting: boolean;
  arePostsExporting: boolean;
  disabled: boolean;
  dataExports: DataExport[];
  createExport: (type: DataExportType) => void;
}

const ExportDataContext = createContext<ExportDataContextProps | undefined>(undefined);

interface ExportDataProviderProps {
  publicationId: string;
  children: React.ReactNode;
}

export const ExportDataProvider: React.FC<ExportDataProviderProps> = ({ children, publicationId }) => {
  const [areSubscribersExporting, setAreSubscribersExporting] = useState(false);
  const [arePostsExporting, setArePostsExporting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dataExports, addDataExport] = useReducer(
    (oldExports: DataExport[], newExport: DataExport) =>
      // Prepend dataExport to the dataExports array and filter the dataExports array for duplicate IDs.
      [newExport, ...oldExports].filter(
        (dataExport, index, unsortedExports) => unsortedExports.map((dEx) => dEx.id).indexOf(dataExport.id) === index
      ),
    []
  );

  useEffect(() => {
    api
      .get<DataExportsIndexResponse>(`/publications/${publicationId}/export_data`)
      .then((response) => response.data.exports.map((dataExport) => addDataExport(dataExport)))
      .catch(() => toast.error('Sorry, we ran into a problem trying to get your recent exports.'));
  }, [publicationId]);

  const createExport = React.useCallback((type: DataExportType) => {
    if (type === 'posts') {
      analytics.track('Export Posts');
      setArePostsExporting(true);
    } else if (type === 'subscribers') {
      analytics.track('Export Subscribers (Full)');
      setAreSubscribersExporting(true);
    } else {
      analytics.track('Export Subscribers (Quick)');
    }

    const params = {
      export: { type },
    };

    setDisabled(true);

    api
      .post<DataExportsCreateResponse>(`/publications/${publicationId}/export_data`, params)
      .then((response) => {
        addDataExport(response.data.export);
        toast.success("You'll get an email when your export is ready.");
      })
      .catch((errPayload) => {
        toast.error(errPayload?.response?.data?.error || 'Sorry, we ran into a problem trying to create your export.');
      })
      .finally(() => {
        setDisabled(false);
        setAreSubscribersExporting(false);
        setArePostsExporting(false);
      });
  }, [publicationId]);

  const contextValue = React.useMemo(() => ({
    areSubscribersExporting,
    arePostsExporting,
    disabled,
    dataExports,
    createExport
  }), [areSubscribersExporting, arePostsExporting, disabled, dataExports, createExport]);

  return (
    <ExportDataContext.Provider value={contextValue}>
      {children}
    </ExportDataContext.Provider>
  );
};

export const useExportDataContext = () => {
  const context = useContext(ExportDataContext);
  if (!context) {
    throw new Error('useExportDataContext must be used within an ExportDataProvider');
  }
  return context;
};
