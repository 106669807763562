import { useMemo } from 'react';

import { SimpleSelect } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';

interface PollSelectProps {
  id: string;
  onChange: (name: string, value: string) => void;
  className?: string;
}

const PollSelect = ({ id, onChange, className }: PollSelectProps) => {
  const publicationId = useCurrentPublicationId();
  const polls = useOptions(publicationId, 'polls', 10000, { all_results: true });
  const { data: pollsData } = polls;

  const selectOptions = useMemo(() => {
    const options: Option[] = [{ label: 'Any Poll', value: 'any' }];

    if (pollsData?.options?.length > 0) {
      options.push({
        label: 'Polls',
        value: '',
        isOptGroup: true,
      });

      pollsData.options.forEach((option: { id: string; name: string }) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    return options;
  }, [pollsData]);

  return (
    <SimpleSelect
      name={`poll_id_${id}`}
      value={id}
      onSelect={onChange}
      options={selectOptions}
      placeholderText="Select a Poll"
      className={className}
    />
  );
};

export default PollSelect;
