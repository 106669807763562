import { useState } from 'react';

import { Switch } from '@/components/Form';
import { useApproveAllSubscribers } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';
import RequireApprovalModal from '@/pages/Settings/Pages/Publication/General/RequireApprovalModal';
import analytics from '@/utils/analytics';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import FormRow from '../components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const ContentAccessForm = ({ publication }: Props) => {
  const { settings, handleChangeInAttribute, savingAttribute } = usePublicationSettings();
  const [isRequireApprovalModalOpen, setIsRequireApprovalModalOpen] = useState(false);

  const approveAllMutation = useApproveAllSubscribers(publication?.id || '');

  const handleSubscriberApprovalModal = (type: string) => () => {
    switch (type) {
      case 'close':
        setIsRequireApprovalModalOpen(false);
        break;
      case 'proceed':
        approveAllMutation.mutateAsync().then(() => {
          handleChangeInAttribute('require_subscriber_approval')(false);
          setIsRequireApprovalModalOpen(false);
        });

        break;
      default:
    }
  };

  const handleSubscriberApprovalChange = (val: boolean) => {
    if (publication?.require_subscriber_approval) {
      setIsRequireApprovalModalOpen(true);

      return Promise.reject();
    }

    analytics.track('Require Subscriber Approval Toggled on');

    return handleChangeInAttribute('require_subscriber_approval')(val);
  };

  return (
    <BodyContainer>
      <RequireApprovalModal
        publication={publication}
        isOpen={isRequireApprovalModalOpen}
        handleOnClose={handleSubscriberApprovalModal('close')}
        handleOnProceed={handleSubscriberApprovalModal('proceed')}
      />
      <CardHeader
        title="Content Access"
        description="Control who views your published posts and how your site and emails look"
      />
      <FormRow
        label="Private Publication"
        helperText="Enable this to restrict access to your site and content. Only logged-in subscribers can view your posts. Non-subscribers will see gated content."
      >
        <Switch
          variant="primary"
          checked={publication.private}
          name="private"
          onChange={(_name: string, value: boolean) => {
            handleChangeInAttribute(_name)(value);
          }}
          disabled={savingAttribute === 'private'}
        />
      </FormRow>
      <FormRow
        label="Require Subscriber Approval"
        helperText="Enable to let visitors request access. Review and approve/deny subscription requests in the subscribers page. Control who can view your content."
      >
        <Switch
          variant="primary"
          checked={publication.require_subscriber_approval || false}
          name="require_subscriber_approval"
          onChange={(_name: string, value: boolean) => handleSubscriberApprovalChange(value)}
          disabled={savingAttribute === 'require_subscriber_approval'}
        />
      </FormRow>
      <FormRow
        label="Private Branding"
        helperText="Toggle on to remove beehiiv branding from your emails and website. Fully customize the look and feel to match your brand."
      >
        <Switch
          variant="primary"
          checked={!!publication.white_labeled}
          name="white_labeled_at"
          disabledText="Only Enterprise and Max customers can remove beehiiv branding from email and web."
          disabled={!settings?.white_labeling || savingAttribute === 'white_labeled_at'}
          onChange={(_name: string, value: boolean) =>
            handleChangeInAttribute(_name)(value ? new Date().toISOString() : null)
          }
        />
      </FormRow>
    </BodyContainer>
  );
};

export default ContentAccessForm;
