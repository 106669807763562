import { useQueryClient } from 'react-query';

import { Input } from '@/components/Form';
import { useCurrentUser } from '@/context/current-user-context';
import { useUpdateOrganization } from '@/hooks';
import { Organization } from '@/interfaces/organization';

import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';

interface Props {
  organization: Organization;
  publicationId: string;
}

const OverviewForm = ({ organization, publicationId }: Props) => {
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();
  const { mutate: updateOrganization } = useUpdateOrganization(organization.id, publicationId, () => {
    queryClient.invalidateQueries([currentUser?.id, 'organizations']);
  });

  return (
    <BodyContainer>
      <CardHeader
        title="Workspace Overview"
        description="Manage the workspace settings including your workspace name, email address footer, and all your publications"
      />
      <Input
        labelText="Workspace Name"
        helperText="Your workspace name is not currently displayed to your subscribers or on your publication’s websites"
        placeholder="John's Hiiv"
        name="name"
        defaultValue={organization.name}
        onBlur={(e) => {
          updateOrganization({
            name: e.currentTarget.value,
          });
        }}
      />
    </BodyContainer>
  );
};

export default OverviewForm;
