import { getSectionElementRenderHTML } from '@shared/dream-components';
import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SectionView } from './views/SectionView';

export enum SectionLayout {
  Custom = 'custom',
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoSection = 'two-section',
}

export const SECTION_DEFAULTS = {
  padding: '20px 0px 20px 0px', // top right bottom left
  paddingRoot: '20px 30px 20px 30px',
  paddingMobile: '5px 0px 5px 0px',
  paddingRootMobile: '5px 30px 5px 30px',
  margin: '0px 0px 0px 0px',
  gap: '30px',
  gapMobile: '16px',
  gapMobileVertical: '10px',
  borderRadius: '0px 0px 0px 0px',
  boxShadow: 'none',
};

export const Section = Node.create({
  name: 'section',

  group: 'section',

  content: 'block*',

  defining: true,

  isolating: true,

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      layout: {
        default: SectionLayout.Custom,
      },
      textAlign: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({
          'data-text-align': attributes.textAlign,
        }),
      },
      align: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      backgroundColor: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
        }),
      },
      color: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => ({
          'data-color': attributes.color,
        }),
      },
      backgroundImage: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-background-image'),
        renderHTML: (attributes) => ({
          'data-background-image': attributes.backgroundImage,
        }),
      },
      backgroundDarken: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-background-darken'),
        renderHTML: (attributes) => ({
          'data-background-darken': attributes.backgroundDarken,
        }),
      },
      verticalAlign: {
        default: 'align_center',
        parseHTML: (element) => element.getAttribute('data-vertical-align'),
        renderHTML: (attributes) => ({
          'data-vertical-align': attributes.verticalAlign,
        }),
      },
      stackOnMobile: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-stack-on-mobile'),
        renderHTML: (attributes) => ({
          'data-stack-on-mobile': attributes.stackOnMobile,
        }),
      },
      width: {
        default: '100dvh',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `width: ${attributes.width}`,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
          style: `height: ${attributes.height}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
          style: `max-width: ${attributes.width_max || '100%'}`,
        }),
      },
      innerWidth: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-inner-width'),
        renderHTML: (attributes) => ({
          'data-inner-width': attributes.innerWidth,
          style: `width: ${attributes.innerWidth || '100%'}`,
        }),
      },
      innerWidthMax: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-inner-width-max'),
        renderHTML: (attributes) => ({
          'data-inner-width-max': attributes.innerWidthMax,
        }),
      },
      innerHeight: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-inner-height'),
        renderHTML: (attributes) => ({
          'data-inner-height': attributes.innerHeight,
          style: `height: ${attributes.innerHeight || '100%'}`,
        }),
      },
      innerHeightMax: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-inner-height-max'),
        renderHTML: (attributes) => ({
          'data-inner-height-max': attributes.innerHeightMax,
        }),
      },
      padding: {
        default: '40px 0px 40px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
          style: `--padding: ${attributes.padding || '40px 0px 40px 0px'};--padding-mobile: ${
            attributes.padding
          };--padding-root: ${attributes.padding || '40px 30px 40px 30px'};--padding-root-mobile: ${
            attributes.padding || '5px 30px 5px 30px'
          }`,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
          style: `margin: ${attributes.margin}`,
        }),
      },
      gap: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
          style: `--gap: ${attributes.gap || '30px'};--gap-mobile: ${attributes.gap || '16px'};--gap-mobile-vertical: ${
            attributes.gap || '10px'
          }`,
        }),
      },
      borderRadius: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
          style: `--border-radius: ${attributes.borderRadius}`,
        }),
      },
      boxShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-box-shadow'),
        renderHTML: (attributes) => ({
          'data-box-shadow': attributes.boxShadow,
          style: `--box-shadow: ${attributes.boxShadow}`,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node }) {
    return getSectionElementRenderHTML({ node: node as any });
  },

  addNodeView() {
    return ReactNodeViewRenderer(SectionView);
  },
});

export default Section;
