import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Checkbox, SimpleSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { usePaywalls } from '@/hooks/usePaywalls';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { useCreatePostTarget, useDeletePostTarget } from '@/hooks/usePostTargets';
import { usePublication } from '@/hooks/usePublications';
import usePublicationSubscriberCounts from '@/hooks/usePublications/usePublicationSubscriberCounts';
import { Option } from '@/interfaces/general';
import { Paywall } from '@/interfaces/paywall';
import { Post } from '@/interfaces/post';
import {
  PostTarget,
  PostTargetAction,
  PostTargetPlatform,
  PostTargetReceiverType,
  PostTargetTier,
} from '@/interfaces/post_target';
import { Tier } from '@/interfaces/tier';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { usePostContext } from '../PostContext';

interface Props {
  display: boolean;
  formData: Post;
  paidTiers: Tier[];
  webPostSendTargetsLoading: boolean;
  webPostSendTargets: PostTarget[];
}

const WebAudience = ({ display, formData, paidTiers, webPostSendTargets, webPostSendTargetsLoading }: Props) => {
  const { data: publication } = usePublication(formData?.publication_id || '');
  const { mutateAsync: createWebPostTarget } = useCreatePostTarget(formData?.id || '', PostTargetPlatform.WEB);
  const { mutateAsync: deletePostTarget } = useDeletePostTarget(formData?.id || '');
  const { data: paywallsData } = usePaywalls();
  const paywalls = paywallsData?.pages.flatMap((page) => page.paywalls) || [];
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [initialTargetsLoaded, setInitialTargetsLoaded] = useState(false);
  const premiumSubscriptionsEnabled = publication?.is_premium_enabled;
  const { data: subscriberCounts } = usePublicationSubscriberCounts(formData?.publication_id || '');
  const numActiveFreeSubscribers = subscriberCounts?.num_active_free_subscribers || 0;
  const numActivePaidSubscribers = subscriberCounts?.num_active_paid_subscribers || 0;
  const { data: postDeliveryData } = usePostDeliveryData(formData?.id);
  const { tiersEdited, setTiersEdited, onChange } = usePostContext();
  const navigate = useNavigate();

  const paywallOptions = paywalls.map((paywall: Paywall) => ({
    label: paywall.name,
    value: paywall.id,
  }));

  const onPaywallSelect = (paywallName: string, paywallId: string) => {
    onChange({ paywall_id: paywallId });
  };

  const options: Option<PostTargetTier>[] = [
    {
      label: 'All Free Subscribers',
      value: PostTargetTier.FREE,
      subtitle: `${numActiveFreeSubscribers} subscribers`,
    },
  ];

  if (premiumSubscriptionsEnabled) {
    options.push({
      label: 'All Paid Subscribers',
      value: PostTargetTier.PREMIUM,
      subtitle: `${numActivePaidSubscribers} subscribers`,
      subItems: paidTiers
        .filter((tier) => !tier.is_default)
        .map((tier) => ({
          label: `All ${tier.name}`,
          value: tier.id,
          subtitle: `${Number(tier.active_subscriptions_count?.toLocaleString()) || 0} Subscribers`,
        })),
    });
  }

  const onWebAudienceChange = (checked: boolean, event?: ChangeEvent<HTMLInputElement> | undefined) => {
    setTiersEdited(true);

    if (checked) {
      const newItems = [...checkedItems, event?.target.name || ''];
      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if all paid tiers are checked and premium is not checked, check premium
      if (allPaidTiersChecked && !newItems.includes(PostTargetTier.PREMIUM)) {
        newItems.push(PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is checked and not all paid tiers are checked, check all paid tiers
      if (newItems.includes(PostTargetTier.PREMIUM)) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            if (!newItems.includes(tier.id)) {
              newItems.push(tier.id);
            }
          });
      }

      setCheckedItems(newItems);
    } else {
      let newItems = checkedItems.filter((item) => item !== event?.target.name);
      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if not all paid tiers are checked and "All paid subscribers" is checked, uncheck "All paid subscribers"
      if (!allPaidTiersChecked && newItems.includes(PostTargetTier.PREMIUM)) {
        newItems = newItems.filter((item) => item !== PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is unchecked, uncheck all paid tiers
      if (event?.target.name === PostTargetTier.PREMIUM) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            newItems = newItems.filter((item) => item !== tier.id);
          });
      }

      setCheckedItems(newItems);
    }
  };

  const createAndDestroyPostTargets = async () => {
    const allMutations: any[] = [];
    const idsToDelete: Set<string> = new Set();
    const idsToCreate: Set<string> = new Set();

    // loop through all webPostSendTargets and delete the ones that are not checked
    webPostSendTargets.forEach((target) => {
      // if "All paid subscribers" is unchecked and a paid tier is checked, delete the target for the paid tier
      if (
        !checkedItems.includes(PostTargetTier.PREMIUM) &&
        target.tier === PostTargetTier.PREMIUM &&
        target.receiver_type === PostTargetReceiverType.PUBLICATION
      ) {
        idsToDelete.add(target.id);
      }

      if (!checkedItems.includes(target.tier_id)) {
        allMutations.push(deletePostTarget(target.id));
      }
    });

    // loop through all checkedItems and webPostSendTargets for each tier if one does not exist
    checkedItems.forEach((item) => {
      const isPaid = paidTiers.some((tier) => tier.id === item);
      // if "All paid subscribers" is checked, skip creating a target if the it's a paid tier, it's unecessary.
      const skipItem = isPaid && checkedItems.includes(PostTargetTier.PREMIUM);

      if (!skipItem) {
        idsToCreate.add(item);
      }
    });

    idsToCreate.forEach((id) => {
      const doesNotAlreadyExist = webPostSendTargets
        .filter((target) => target.receiver_type === PostTargetReceiverType.PUBLICATION)
        .every((target) => target.tier_id !== id);
      if (doesNotAlreadyExist) {
        allMutations.push(
          createWebPostTarget({
            params: {
              action: PostTargetAction.INCLUDE,
              receiver_type: PostTargetReceiverType.PUBLICATION,
              receiver_id: formData?.publication_id || '',
              tier_id: id,
            },
          })
        );
      }
    });

    idsToDelete.forEach((id) => {
      const targetExists = webPostSendTargets.some((target) => target.tier_id === id);
      if (targetExists) {
        allMutations.push(deletePostTarget(id));
      }
    });

    try {
      await Promise.all(allMutations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (initialTargetsLoaded) {
      createAndDestroyPostTargets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  useEffect(() => {
    // this should run only once when the webPostSendTargets are loaded
    if (!initialTargetsLoaded && !webPostSendTargetsLoading) {
      const newItems = webPostSendTargets
        .filter((target) => target.receiver_type === PostTargetReceiverType.PUBLICATION)
        .map((target) => target.tier_id);

      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if all paid tiers are checked and premium is not checked, check premium
      if (allPaidTiersChecked && !newItems.includes(PostTargetTier.PREMIUM)) {
        newItems.push(PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is checked and not all paid tiers are checked, check all paid tiers
      if (newItems.includes(PostTargetTier.PREMIUM)) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            if (!newItems.includes(tier.id)) {
              newItems.push(tier.id);
            }
          });
      }

      if (newItems.length === 0 && !postDeliveryData?.for_email && !postDeliveryData?.for_web && !tiersEdited) {
        newItems.push(PostTargetTier.FREE);

        createWebPostTarget({
          params: {
            action: PostTargetAction.INCLUDE,
            receiver_type: PostTargetReceiverType.PUBLICATION,
            receiver_id: formData?.publication_id || '',
            tier_id: PostTargetTier.FREE as string,
          },
        });
      }

      setCheckedItems(newItems);
      setTimeout(() => {
        setInitialTargetsLoaded(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webPostSendTargetsLoading]);

  const flattenedOptions = options.flatMap((option) => {
    if (option.subItems) {
      return [option, ...option.subItems];
    }
    return option;
  });

  return (
    <div className={display ? '' : 'hidden'}>
      <Card className="w-full mx-auto flex flex-col gap-6 rounded-lg rounded-t-none max-w-[40rem]">
        <Typography token="font-medium/text/base">Web Audience</Typography>
        <Typography token="font-medium/text/sm">Default audiences</Typography>
        <div className="flex flex-col gap-3">
          {flattenedOptions.map((option) => {
            const isChecked = checkedItems.includes(option.value as string);

            return (
              <div
                className={cx('flex w-full', {
                  'pl-4': option.value !== PostTargetTier.FREE && option.value !== PostTargetTier.PREMIUM,
                })}
              >
                <Checkbox
                  id={`web-audience-${option.value}`}
                  key={option.value}
                  name={option.value as string}
                  checked={isChecked}
                  onChange={onWebAudienceChange}
                  color="tertiary"
                />
                <label
                  htmlFor={`web-audience-${option.value}`}
                  className="text-gray-700 text-sm font-normal w-full flex ml-2 mt-1"
                >
                  <Typography token="font-medium/text/sm" colorWeight="700">
                    {option.label}
                  </Typography>
                  <Typography token="font-normal/text/sm" colorWeight="600" className="ml-1">
                    ({option.subtitle})
                  </Typography>
                </label>
              </div>
            );
          })}
          {webPostSendTargets.some(
            (target) => target.tier === PostTargetTier.PREMIUM && target.action === PostTargetAction.INCLUDE
          ) &&
            paywallOptions.length > 0 && (
              <>
                <Typography token="font-medium/text/sm" className="mt-4">
                  Paywall displayed to free subscribers
                </Typography>
                <SimpleSelect
                  options={paywallOptions}
                  name="paywall"
                  onSelect={onPaywallSelect}
                  value={formData?.paywall_id}
                />
                <Button
                  onClick={() => navigate('/settings/publication/premium')}
                  type="button"
                  variant="flush"
                  size="sm"
                  className="w-fit !p-0 !bg-0 hover:!bg-white"
                >
                  <Typography
                    token="font-medium/text/xs"
                    color="secondary"
                    colorWeight="600"
                    className="underline cursor-pointer"
                  >
                    Manage tiers, plans, and paywalls
                  </Typography>
                </Button>
              </>
            )}
        </div>
      </Card>
    </div>
  );
};

export default WebAudience;
