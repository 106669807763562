import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToolbarProps } from '@/components/TiptapEditor/components/ui/Toolbar/types';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { RSSData, RSSSettings } from '@/interfaces/external_rss_feed';

import { RSSEntryOrganizerModal } from './RSSEntryOrganizerModal';
import Styled from './RSSEntryOrganizerPanel.styled';

const buttonProps = {
  $variant: 'primary-inverse',
  $size: 'base',
  $showTooltip: true,
  $isIconButton: true,
};

interface RSSEntryOrganizerPanelProps extends ToolbarProps {
  data: RSSData;
  settings: RSSSettings;
  organizeContentModalOpen: boolean;
  setOrganizeContentModalOpen: (open: boolean) => void;
}

export const RSSEntryOrganizerPanel = ({
  editor,
  data,
  settings,
  organizeContentModalOpen,
  setOrganizeContentModalOpen,
}: RSSEntryOrganizerPanelProps) => {
  if (!editor || !data) {
    return null;
  }

  return (
    <PanelSection>
      {data && (
        <RSSEntryOrganizerModal
          editor={editor}
          data={data}
          settings={settings}
          organizeContentModalOpen={organizeContentModalOpen}
          setOrganizeContentModalOpen={setOrganizeContentModalOpen}
        />
      )}
      <Styled.ButtonWrapper>
        <Tooltip title="Reorganize Content">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            onClick={() => setOrganizeContentModalOpen(true)}
            $leftSlot={<Icon name="DragMenu" />}
            $isIconButton={false}
          >
            Reorganize Content
          </Button>
        </Tooltip>
      </Styled.ButtonWrapper>
    </PanelSection>
  );
};

export default RSSEntryOrganizerPanel;
