import React, { useState } from 'react';

import { Input, Switch } from '@/components/Form';
import RichContent from '@/components/RichContent';
import { useDisableRefetchOnFocus } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import useFetchPreview from '../_hooks/useFetchPreview';
import useUpdateWelcomeEmail from '../_hooks/useUpdateWelcomeEmail';
import { useWelcomeEmailContext } from '../_layout';

const WelcomeEmailEdit: React.FunctionComponent = () => {
  useDisableRefetchOnFocus();

  const currentPublicationId = useCurrentPublicationId();
  const { welcomeEmail } = useWelcomeEmailContext();

  const [active, setActive] = useState(welcomeEmail.active);
  const [subjectLine, setSubjectLine] = useState(welcomeEmail.subject_line || undefined);
  const [previewText, setPreviewText] = useState(welcomeEmail.preview_text || undefined);
  const [tiptapState, setTiptapState] = useState(welcomeEmail.tiptap_state);

  const updateMutation = useUpdateWelcomeEmail(currentPublicationId, welcomeEmail.id);
  const onFetchPreview = useFetchPreview(currentPublicationId, welcomeEmail.id, tiptapState);

  if (!currentPublicationId) {
    return null;
  }

  return (
    <div className="mx-auto">
      <div className="bg-white rounded-lg mt-12 py-6 px-4 sm:p-6">
        <div className="flex flex-col lg:flex-row">
          <div className="flex-grow space-y-6">
            <Switch
              name="active"
              labelText="Send welcome email to new subscribers?"
              checked={active}
              onChange={(_name: string, value: boolean) => setActive(value)}
            />
            <Input
              name="subject_line"
              labelText="Subject Line"
              value={subjectLine}
              onChange={(e) => setSubjectLine(e.target.value)}
            />
            <Input
              name="preview_text"
              labelText="Preview Text"
              value={previewText}
              onChange={(e) => setPreviewText(e.target.value)}
            />
            <RichContent
              name="content"
              labelText="Content"
              publicationId={currentPublicationId}
              initialValue={welcomeEmail.tiptap_state}
              onChange={setTiptapState}
              onFetchPreview={onFetchPreview}
              allowPolls
            />
            <div className="flex justify-end">
              <Button
                loading={updateMutation.isLoading}
                onClick={() => {
                  updateMutation.mutate({
                    subject_line: subjectLine,
                    preview_text: previewText,
                    tiptap_state: tiptapState,
                    active,
                  });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeEmailEdit;
