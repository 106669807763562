import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { Paywall } from '@/interfaces/paywall';
import api from '@/services/swarm';

interface ApiResponse {
  paywalls: Paywall[];
  pagination: Pagination;
}

const usePaywalls = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['paywalls', currentPublicationId],
    ({ pageParam = 1 }) =>
      api
        .get(`/paywalls`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default usePaywalls;
