import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Switch } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Publication } from '@/interfaces/publication';
import useNotificationSettings from '@/pages/Settings/Pages/Notifications/useNotificationSettings';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import BodyContainer from '../../settings/publication/components/BodyContainer';
import CardHeader from '../../settings/publication/components/CardHeader';
import FormRow from '../../settings/publication/components/FormRow';

export interface NotificationSetting {
  code: string;
  description: string;
  id: string | null;
  name: string;
  setting_type: string;
  value: string | boolean | number;
  value_origin: string;
  roles: string[];
  plans: string[];
}

interface Props {
  settings: NotificationSetting[];
  publication: Publication;
  refetch: () => void;
}

interface Variables {
  code: string;
  value: string;
}

const Notifications = ({ settings, refetch, publication }: Props) => {
  const [isSavingCode, setIsSavingCode] = useState<string | null>(null);

  const updateSetting = ({ code, value }: Variables): Promise<any> => {
    return api
      .patch(`/notification_settings/${code}`, {
        notification_setting: {
          setting_code: code,
          value,
        },
        publication_id: publication.id,
      })
      .then((res) => res.data);
  };

  const { mutateAsync: update, isLoading: isSaving } = useMutation(updateSetting, {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSettled: () => {
      refetch();
    },
  });

  const { mutateAsync: resetAll, isLoading: isRestoring } = useMutation(
    () => {
      return api
        .delete(`/notification_settings/reset_all`, {
          params: { publication_id: publication.id },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        toast.success('Settings reset');
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSettled: () => {
        refetch();
      },
    }
  );

  return (
    <BodyContainer>
      <CardHeader
        title="Your Notification Preferences"
        description={
          <Typography token="font-normal/text/sm" colorWeight="500">
            Configure your profile notification settings for{' '}
            <Typography token="font-medium/text/sm" colorWeight="600" color="secondary">
              {publication.name}
            </Typography>
          </Typography>
        }
        cta={
          settings.some((setting) => setting.value_origin === 'override') && (
            <Button size="xs" disabled={isRestoring} onClick={() => resetAll()}>
              Restore defaults
            </Button>
          )
        }
      />
      <ul className="space-y-6">
        {settings.map((setting) => {
          return (
            <FormRow key={setting.code} label={setting.name} helperText={setting.description}>
              {setting.setting_type === 'on_off' && (
                <Switch
                  variant="primary"
                  checked={setting.value === 'true' || setting.value === true}
                  onChange={(_name, checked) => {
                    setIsSavingCode(setting.code);
                    update({ code: setting.code, value: checked ? 'true' : 'false' });
                  }}
                  name={`settings[${setting.code}]`}
                  disabled={isSaving && isSavingCode === setting.code}
                />
              )}
            </FormRow>
          );
        })}
      </ul>
    </BodyContainer>
  );
};

export default function Loader() {
  const currentPublicationId = useCurrentPublicationId();
  const { isSuccess, isPublicationSuccess, publication, refetch, settings } =
    useNotificationSettings(currentPublicationId);

  if (!isSuccess || !isPublicationSuccess || !settings || !publication) return null;

  return <Notifications settings={settings} publication={publication} refetch={refetch} />;
}
