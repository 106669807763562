import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import Content from './components/Content';
import Navigation from './components/Navigation';
import { MediaLibraryProvider } from './context/MediaLibraryContext';
import { MediaLibraryComponentProps, MediaLibraryId, MediaLibraryTabState } from './MediaLibrary.types';

const MediaLibrary = ({ onMediaSelect, publicationId, isOpen, onClose }: MediaLibraryComponentProps) => {
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen) {
      closeRef.current?.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className="fixed inset-0 z-[100000]">
      <button
        type="button"
        ref={closeRef}
        className="absolute inset-0 z-10 text-transparent bg-gray-300 select-none bg-opacity-80"
        onClick={onClose}
      >
        Close media library
      </button>
      <div className="absolute inset-0 z-20 flex items-center justify-center px-6 pointer-events-none">
        <div className="w-full max-w-6xl overflow-y-auto bg-white rounded pointer-events-auto h-5/6">
          <MediaLibraryProvider
            onClose={onClose}
            publicationId={publicationId}
            onMediaSelect={onMediaSelect}
            initialLibraryId={MediaLibraryId.CURRENTPOST}
            initialTab={MediaLibraryTabState.BEEHIIV}
          >
            <div className="h-full">
              <div className="w-full">
                <Navigation />
              </div>
              <div className="w-full">
                <Content />
              </div>
            </div>
          </MediaLibraryProvider>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default MediaLibrary;
