import { useEffect, useState } from 'react';
import { CaretRight, TextT, X } from '@phosphor-icons/react';

import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Search } from '@/routes/website/_components/UI/Search';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../../NavbarContext';

import { getFonts } from './getFonts';

const DEFAULT_FONT_FAMILY = 'Inter';

export const FontSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();


  const [fonts, setFonts] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  useEffect(() => {
    getFonts().then((w) => setFonts(w.items.map((i) => i.family)));
  }, []);


  const filteredFonts = search ? fonts.filter((font) => font.toLowerCase().includes(search.toLowerCase())) : fonts;

  const handleSetFontFamily = (fontFamily: string) => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { font_family: fontFamily });
    setIsOpen(false);
  }

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;


  const contentValue: string = selectedContent?.attrs && 'font_family' in selectedContent.attrs && selectedContent.attrs.font_family as string || '';

  return (<Popover open={isOpen} onOpenChange={setIsOpen}>
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Font
      </Text>

      <PopoverTrigger asChild>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <TextT className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium">
                {contentValue || DEFAULT_FONT_FAMILY}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>

      </PopoverTrigger>
    </div>

    <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
      <div
        className="bg-wb-primary w-full max-h-[400px] min-h-0 p-3 rounded-lg shadow-xl flex flex-col gap-2"
      >
        <div className="flex items-center justify-between gap-2">
          <Text size="sm" weight="semibold">
            Fonts
          </Text>

          <div
            className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
            onClick={() => setIsOpen(false)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsOpen(false);
              }
            }}
          >
            <X className="cursor-pointer" />
          </div>
        </div>

        <Search placeholder="Search font..." value={search} onChange={(e) => setSearch(e.target.value)} />

        <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
          {filteredFonts.map((font) => (
            <div
              key={font}
              className={`flex items-center px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${font === contentValue
                ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                : 'bg-wb-primary border-transparent'
                }`}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleSetFontFamily(font);
                }
              }}
              onClick={() => {
                handleSetFontFamily(font);
              }}
            >
              <Text size="sm" weight="medium">
                {font}
              </Text>
            </div>
          ))}
        </div>

      </div>
    </PopoverContent>
  </Popover>
  );
};
