/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import cx from 'classnames';

import MarkDownHelperText from '@/pages/DesignLab/components/MardownHelperText';

import { Typography } from '../Typography';

import ErrorText from './ErrorText';
import HelperText from './HelperText';

export interface Props extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  name: string;
  value?: string;
  labelText?: string;
  errorText?: string;
  helperText?: string | React.ReactNode;
  placeholderText?: string;
  readOnly?: boolean;
  type?: string;
  inputClassOverride?: string;
  inputClassName?: string;
  variant?: 'primary' | 'secondary';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  supportsMarkdown?: boolean;
  readOnlyText?: string;
  leadingText?: string;
  trailingButton?: React.ReactNode;
}

const CLASS_MAP = Object.freeze({
  primary:
    'appearance-none block w-full px-3 py-2 border hover:border-surface-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-transparent focus:border-surface-900 sm:text-sm h-full disabled:cursor-not-allowed',
  secondary:
    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-full disabled:cursor-not-allowed',
});

const Input = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    errorText,
    name,
    labelText,
    helperText,
    value,
    placeholderText,
    inputClassOverride,
    inputClassName,
    variant = 'secondary',
    required = false,
    disabled = false,
    readOnly = false,
    type = 'text',
    supportsMarkdown = false,
    readOnlyText,
    leadingText,
    trailingButton,
    ...rest
  } = props;

  const hasError = !!errorText;

  return (
    <div className={className}>
      {labelText && (
        <label htmlFor={name} className="flex items-center gap-1 text-sm font-medium text-gray-700">
          {labelText}
          {required ? ' *' : ''}
          {supportsMarkdown && (
            <Typography token="font-light/text/xs" colorWeight="400" as="span">
              <MarkDownHelperText />
            </Typography>
          )}
        </label>
      )}
      <div className={cx(labelText ? 'mt-1' : '', readOnlyText && 'flex flex-row w-full')}>
        {readOnlyText && (
          <div className="h-full bg-[#F9FAFB] text-xs py-2.5 px-2 w-full border border-gray-300 border-r-0 rounded-l-lg">
            <Typography token="font-normal/text/sm">
              <span className="text-gray-600">{readOnlyText}</span>
            </Typography>
          </div>
        )}
        <div className="flex items-center w-full">
          {leadingText && (
            <span className="inline-flex items-center px-3 py-2 border rounded-l-md border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm whitespace-nowrap">
              {leadingText}
            </span>
          )}
          <input
            ref={ref}
            name={name}
            type={type}
            value={value}
            placeholder={placeholderText}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            className={cx(
              'flex-grow',
              inputClassOverride || CLASS_MAP[variant],
              inputClassName,
              hasError ? 'border-feedback-danger-500' : 'border-gray-300',
              disabled ? 'bg-gray-200 opacity-60' : 'opacity-100',
              leadingText ? 'rounded-r-md rounded-l-none' : 'rounded-md', // Adjust border radius if leadingText is present
              trailingButton && 'rounded-r-none',
              readOnlyText && 'rounded-l-none'
            )}
            style={{
              height: 'inherit',
            }}
            {...rest}
          />
          {trailingButton}
        </div>
        {helperText && <HelperText className="mt-2">{helperText}</HelperText>}
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </div>
    </div>
  );
});

export default Input;
