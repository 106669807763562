import toast from 'react-hot-toast';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';
import { capitalize, currencyFormatter } from '@/utils';

import { useCreatePlanPriceUpgrade, usePlanPriceUpgrade } from '../../_hooks';

interface Props {
  organizationId: string;
  publicationId: string;
  planPrice: PlanPrice;
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeTierModal: React.FC<Props> = ({ organizationId, publicationId, planPrice, isOpen, onClose }) => {
  const { data, isLoading, isError } = usePlanPriceUpgrade({ organizationId, publicationId });

  const { active_subscriptions: activeSubscriptions, organization_max_subscriptions: maxSubscriptions } = planPrice;

  const upgradeMutation = useCreatePlanPriceUpgrade({
    publicationId,
    organizationId,
    onSuccess: () => {
      toast.success('Plan tier upgraded!');
      setTimeout(() => window.location.reload(), 500);
    },
  });

  const usagePercentage = Math.min(100, (activeSubscriptions / maxSubscriptions) * 100);

  const nextTierDescription = usagePercentage >= 100 ? 'the correct tier for your current usage' : 'the next tier';

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      resourceId={organizationId}
      headerText="Confirm your updates"
      onProceed={() => upgradeMutation.mutate()}
      isWorking={upgradeMutation.isLoading}
    >
      <LoadingBox isLoading={isLoading} isError={isError}>
        {data && (
          <TypographyStack className="pt-4" gap="2">
            <Typography as="div" className="pb-4">
              Your current tier of the <b>{capitalize(planPrice.plan_name)}</b> Plan allows for up to{' '}
              {planPrice.max_subscriptions.toLocaleString()} subscriptions. Upgrading to {nextTierDescription} will
              allow for up to <b>{data.new_max_subscriptions.toLocaleString()}</b> subscriptions.
            </Typography>

            <div className="bg-surface-50 border border-surface-200 rounded p-4 space-y-6">
              <div className="flex justify-between">
                <Typography as="div" token="font-normal/text/sm">
                  What you&apos;ll pay {data.new_interval}ly starting
                  <br />
                  {data.next_billing_date ? moment(data.next_billing_date).format('LL') : 'on your next billing cycle'}
                </Typography>
                <Typography as="div" token="font-normal/text/sm">
                  {currencyFormatter(data.new_unit_amount / 100, 'USD', 2, 2)}
                </Typography>
              </div>
              <hr />
              <div className="flex justify-between">
                <Typography as="div" token="font-normal/text/sm">
                  Amount due today
                  <br />
                  Prorated for this billing cycle
                </Typography>
                <Typography as="div" token="font-bold/text/sm">
                  {currencyFormatter(data.amount_due / 100, 'USD', 2, 2)}
                </Typography>
              </div>
            </div>

            <Typography token="font-normal/text/xs">
              Clicking Confirm will charge your card on file for the prorated amount of{' '}
              {currencyFormatter(data.amount_due / 100, 'USD', 2, 2)} and immediately upgrade you to the next tier of
              this plan.
            </Typography>
          </TypographyStack>
        )}
      </LoadingBox>
    </ActionModal>
  );
};

export default UpgradeTierModal;
