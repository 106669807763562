import truncate from 'lodash.truncate';

import Badge from '@/components/Badge';
import { PostTarget, PostTargetTier } from '@/interfaces/post_target';

interface Props {
  excludedAudiences: PostTarget[];
  includedAudiences: PostTarget[];
  truncateText?: boolean;
}

type BadgeClassNameType = 'included_segment' | 'excluded_segment' | 'premium_tier' | 'free_tier';

const BADGE_CLASSNAMES: Record<BadgeClassNameType, string> = {
  free_tier: 'w-fit h-fit rounded-full whitespace-nowrap bg-surface-200',
  premium_tier: 'w-fit h-fit rounded-full whitespace-nowrap	bg-surface-700 text-surface-50',
  excluded_segment: 'w-fit h-fit rounded-full whitespace-nowrap bg-feedback-warning-200 text-feedback-warning-900',
  included_segment: 'w-fit h-fit rounded-full whitespace-nowrap bg-action-tertiary-200 text-surface-900',
};

const getBadgeClasNameType = (tier: PostTargetTier, isSegment: boolean, isIncluded: boolean): BadgeClassNameType => {
  // eslint-disable-next-line no-nested-ternary
  return isSegment
    ? `${isIncluded ? 'included' : 'excluded'}_segment`
    : tier === 'premium'
    ? 'premium_tier'
    : 'free_tier';
};

const decorateReceiverDisplayName = (value: string, type: string, included: boolean, truncateText: boolean) => {
  const valueToDisplay = truncateText ? truncate(value, { length: 30 }) : value;
  return type === 'Segment' ? `${included ? 'Include' : 'Exclude'} (${valueToDisplay})` : valueToDisplay;
}


const AudienceBadges = ({ excludedAudiences, includedAudiences, truncateText = false }: Props) => {
  return (
    <div className="flex gap-x-2 flex-wrap gap-y-2">
      {includedAudiences?.map((audience) => {
        return (
          <Badge
            key={audience.tier}
            type="flush"
            className={
              BADGE_CLASSNAMES[getBadgeClasNameType(audience.tier, audience.receiver_type === 'Segment', true)]
            }
          >
            {decorateReceiverDisplayName(audience.receiver_display_name, audience.receiver_type, true, truncateText)}
          </Badge>
        );
      })}
      {excludedAudiences?.map((audience) => (
        <Badge
          key={audience.tier}
          className={BADGE_CLASSNAMES[getBadgeClasNameType(audience.tier, audience.receiver_type === 'Segment', false)]}
          type="flush"
        >
          {decorateReceiverDisplayName(audience.receiver_display_name, audience.receiver_type, false, truncateText)}
        </Badge>
      ))}
    </div>
  );
};

export default AudienceBadges;
