import { useQuery } from 'react-query';

import { Permissions } from '@/interfaces/permissions';
import api from '@/services/swarm';

export interface CurrentUserPublication {
  id: string;
  name: string;
  permissions: Permissions;
}

export interface CurrentUserOrganization {
  id: string;
  name: string;
  publications: CurrentUserPublication[];
}

const useCurrentUserOrganizations = (currentUserId: string, enabled: boolean) => {
  return useQuery<{ organizations: CurrentUserOrganization[] }>(
    [currentUserId, 'organizations'],
    () => api.get(`/current_user/organizations`).then((res) => res.data),
    { enabled }
  );
};

export default useCurrentUserOrganizations;
