/* eslint-disable jsx-a11y/media-has-caption */

import { FC } from 'react';
import { SpacerElement } from '@shared/dream-components';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

export const SpacerView: FC<NodeViewProps> = ({ node }) => {
  return (
    <NodeViewWrapper>
      <SpacerElement
        element={{
          type: 'spacer',
          attrs: { id: node.attrs.id, content: [{ id: 'text', text: '' }] },
        }}
      >
        {null}
      </SpacerElement>
    </NodeViewWrapper>
  );
};
