import { Typography, TypographyStack } from '@/components/Typography';
import { SubscriberImportType } from '@/pages/Segments/types';
import { Card } from '@/ui/Card';

import CardHeader from '../../components/CardHeader';

interface Props {
  importType: SubscriberImportType;
  sendWelcomeEmail: boolean;
  skipEmailValidation: boolean;
  addedToAutomation: boolean;
  assignedSubscriberTags: boolean;
}

const Summary = ({
  importType,
  sendWelcomeEmail,
  skipEmailValidation,
  addedToAutomation,
  assignedSubscriberTags,
}: Props) => {
  return (
    <Card className="flex flex-col gap-y-6">
      <CardHeader title="Summary" description="Import subscribers to be added to your in app subscriber list" />
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Imported Method
        </Typography>
        <Typography token="font-medium/text/sm" as="time">
          {importType === SubscriberImportType.Manual ? 'Manual Input' : 'CSV Upload'}
        </Typography>
      </TypographyStack>
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Advanced Configuration (Optional)
        </Typography>
        {sendWelcomeEmail && (
          <Typography token="font-medium/text/sm" as="time">
            Send Welcome Email
          </Typography>
        )}
        {skipEmailValidation && (
          <Typography token="font-medium/text/sm" as="time">
            Skip Email Validation
          </Typography>
        )}
        {addedToAutomation && (
          <Typography token="font-medium/text/sm" as="time">
            Added to Automations
          </Typography>
        )}
        {assignedSubscriberTags && (
          <Typography token="font-medium/text/sm" as="time">
            Assigned Subscriber Tags
          </Typography>
        )}
        {!skipEmailValidation && !addedToAutomation && !assignedSubscriberTags && (
          <Typography token="font-medium/text/sm" as="time">
            -
          </Typography>
        )}
      </TypographyStack>
    </Card>
  );
};

export default Summary;
