import { useSettings } from '@/context/settings-context';
import { PLAN } from '@/utils/plans';

const usePlanInfo = ({ planName }: { planName?: string }) => {
  const { settings } = useSettings();

  const topPlan = settings?.tiered_pricing ? 'Max' : 'Scale';
  const belowScalePlan = settings?.tiered_pricing ? 'Launch' : 'Grow';

  if (planName === PLAN.LAUNCH) {
    return {
      description: 'The most powerful and generous free plan in the industry.',
      features: [
        'Beautiful custom emails designed to stand out in the inbox',
        'Basic website to collect emails and host content with no code needed',
        'Detailed campaign and performance analytics about your content',
        'Industry leading editor; collaborative editing, custom integrations, and more',
        'Access to our large and sprawling Recommendation Network of newsletters',
        'Custom fields, subscriber tagging, a detailed events feed, and more',
        'Comments and likes on your content',
      ],
    };
  }

  if (planName === PLAN.GROW) {
    return {
      description: 'Everything that you need to take your newsletter to the next level.',
      features: [
        <>
          Everything on the <b>Launch</b> plan, plus...
        </>,
        'Custom domains for your website and for sending emails',
        'Audience polls and survey forms to get to know your audience',
        'Access to our exclusive newsletter community',
        'API integrations',
        'Multiple teammates',
      ],
    };
  }

  if (planName === PLAN.SCALE) {
    return {
      description: "Everything you'd ever need to build, monetize, and grow your newsletter.",
      features: [
        <>
          Everything on the <b>{belowScalePlan}</b> plan, plus...
        </>,
        'Advanced website with widgets, custom pages, etc with no code needed',
        'Fully integrated Referral Program, Magic Links, and the Boost Network',
        'Paid Subscriptions and beehiiv Ad Network',
        'Custom audience campaigns, automated email journeys, transactional emails, and more',
        'Polls, surveys, segmentation, and 3D Analytics reports',
        'API and webhooks integrations',
      ],
    };
  }

  if (planName === PLAN.MAX) {
    return {
      description: 'Your brand front and center, backed by all the firepower beehiiv has to offer.',
      features: [
        <>
          Everything on the <b>Scale</b> plan, plus...
        </>,
        'Remove beehiiv branding so your brand is the only thing your readers ever see',
        'Priority support',
        'Up to 10 publications',
        'NewsletterXP course so you can learn from the other pros of the newsletter industry',
      ],
    };
  }

  if (planName === PLAN.CUSTOM) {
    return {
      description: "A custom plan built to fit your growing business's needs.",
      features: [
        <>
          Everything on the <b>{topPlan}</b> plan, plus...
        </>,
        'More subscribers and publications',
        'Dedicated account manager',
        '250 monthly AI credits',
        'Early feature access',
      ],
    };
  }

  return {
    description: '',
    features: [],
  };
};

export default usePlanInfo;
