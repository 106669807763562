/* eslint-disable react/jsx-props-no-spreading */
// Spread operators needed for dndkit dependency

import React, { ChangeEventHandler, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

import { Input } from '../Form';
import { Icon } from '../TiptapEditor/components/ui/Icon';

import DragIcon from './DragIcon';

interface Props {
  className?: string;
  listItem: any;
  text: string | React.ReactNode;
  onRemoveItem?: (item: any) => void;
  actionChild?: React.ReactNode;
  children?: React.ReactNode;
  editableText?: boolean;
  onTextChange?: ChangeEventHandler<any>;
  onReset?: () => void;
}

const SortableListItem = ({
  listItem,
  text,
  onRemoveItem,
  actionChild,
  className,
  children,
  editableText,
  onTextChange,
  onReset,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: listItem.id });
  const [isEditing, setIsEditing] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={cx('bg-surface-100 shadow-sm border border-surface-200 rounded flex flex-col p-2', className)}
      ref={setNodeRef}
      style={style}
    >
      <div className="flex justify-between items-center">
        <div
          className={cx(
            'flex space-x-2 items-center flex-grow overflow-hidden',
            isDragging ? 'cursor-grabbing' : 'cursor-grab'
          )}
          {...(!isEditing ? attributes : {})}
          {...(!isEditing ? listeners : {})}
        >
          {isEditing && onTextChange ? (
            <Input
              name="title"
              value={typeof text === 'string' ? text : text?.toString()}
              onChange={onTextChange}
              onBlur={() => setIsEditing(false)}
              onSuspend={() => setIsEditing(false)}
              type="text"
              className="w-3/4"
            />
          ) : (
            <>
              <div className="flex w-3.5 h-3.5">
                <DragIcon />
              </div>
              {typeof text === 'string' ? <Typography token="font-semibold/text/xs">{text}</Typography> : text}
            </>
          )}
        </div>
        {editableText && onTextChange && <Icon name="Edit" onClick={() => setIsEditing(!isEditing)} />}

        {onReset && (
          <button
            type="button"
            aria-label="reset"
            onClick={onReset}
            className="p-0.5 rounded-full hover:bg-gray-200 h-fit"
          >
            <Icon name="Reset" />
          </button>
        )}

        {actionChild ||
          (onRemoveItem && (
            <button
              type="button"
              aria-label="close"
              onClick={() => {
                onRemoveItem?.(listItem);
              }}
              className="p-0.5 rounded-full hover:bg-gray-200 h-fit"
            >
              <XMarkIcon className="h-3.5 w-3.5 text-surface-400" />
            </button>
          ))}
      </div>

      {children && <div className="pl-5 pt-2 w-full">{children}</div>}
    </div>
  );
};

export default SortableListItem;
