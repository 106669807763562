import { CaretRight } from '@phosphor-icons/react';
import { ElementType, PropsWithChildren } from 'react';

import { cn } from '../../../../_utils/cn';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';

type Props = PropsWithChildren<{
  title: string;
  text: string;
  popoverTitle: string;
  Icon: ElementType;
  className?: string;
}>;

interface PopoverItemProps {
  isSelected: boolean;
  onSelect: () => void;
  value: string;
}

const PopoverItem = ({ isSelected, onSelect, value }: PopoverItemProps) => {
  return (
    <div
      className={`flex items-center gap-2 px-3 py-2 rounded-lg cursor-pointer border ${
        isSelected
          ? 'bg-wb-button-accent-soft text-wb-accent border-wb-accent-soft'
          : 'bg-wb-secondary border-transparent'
      }`}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onSelect();
        }
      }}
      onClick={() => {
        onSelect();
      }}
    >
      <Text size="2xs" weight="medium">
        {value}
      </Text>
    </div>
  );
};

const PopoverHelper = ({ title, text, popoverTitle, children, Icon, className }: Props) => {
  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Icon className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium" className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {text}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className={cn('w-[255px] p-0', className)} align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              {popoverTitle}
            </Text>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="flex flex-col gap-2">{children}</div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export { PopoverHelper, PopoverItem };
