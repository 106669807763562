import { Link } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';

import { MarkAllAsRead } from '@/components/Notifications/MarkAllAsRead';
import { NotificationItems } from '@/components/Notifications/NotificationItems';
import { UnreadCountBadge } from '@/components/Notifications/UnreadCountBadge';
import useNotifications from '@/hooks/useNotifications';
import { Notifications } from '@/interfaces/notification';

interface Props {
  notifications: Notifications;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

const NotificationsPage = ({ notifications, hasNextPage, fetchNextPage }: Props) => {
  return (
    <div className="space-y-4 max-w-xl mx-auto ">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-xl inline-flex items-center">
          Notifications
          <UnreadCountBadge />
        </h1>
        <div className="space-x-2 flex items-center">
          <div className="flex items-center">
            <MarkAllAsRead />
          </div>
          <div className="flex items-center">
            <Link to="/settings/notifications">
              <Cog6ToothIcon className="w-6 h-6 text-gray-300 hover:text-gray-500 duration-200" />
            </Link>
          </div>
        </div>
      </div>
      <NotificationItems notifications={notifications} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
    </div>
  );
};

export default function Loader() {
  const { data, isSuccess, hasNextPage, fetchNextPage } = useNotifications({});

  if (!isSuccess) return null;

  const notifications = data.pages.flatMap((page) => page.notifications);

  return <NotificationsPage notifications={notifications} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />;
}
