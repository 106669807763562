import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { useDeleteOrganization } from '@/hooks/useOrganization';
import { Organization } from '@/interfaces/organization';
import { Button } from '@/ui/Button';

import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';

interface Props {
  organization: Organization;
  publicationId: string;
}

const DeleteWorkspaceForm = ({ organization, publicationId }: Props) => {
  const { mutate: deleteOrganization, isLoading } = useDeleteOrganization(organization.id, publicationId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClickDelete = () => {
    deleteOrganization();
  };

  return (
    <>
      <ActionModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        headerText="Delete Workspace"
        descriptionText="After the workspace is deleted, you will be logged out. Are you sure you want to continue?"
        actionText="Delete"
        buttonType="danger"
        buttonShade="dark"
        onProceed={handleClickDelete}
        resourceId={organization.id}
        isWorking={isLoading}
        modalMessageType="danger"
      />
      <BodyContainer>
        <CardHeader
          title="Delete Workspace"
          description="You may delete your workspace at any time. This will delete all of your publications as well as all of your team members. This action is irreversible and can not be undone. "
        />
        <Button className="w-fit" variant="danger" shade="dark" type="button" onClick={() => setShowDeleteModal(true)}>
          Delete Workspace
        </Button>
      </BodyContainer>
    </>
  );
};

export default DeleteWorkspaceForm;
