import { useCallback, useMemo } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';

import { Spinner } from '@/components/TiptapEditor/components/ui/Spinner';
import * as Sheet from '@/components/UI/Sheet';
import { usePosts } from '@/hooks';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import PostSearch from '@/models/postSearch';

export const BacklinkSuggestionsModal = (props: SuggestionProps) => {
  const { query, command } = props;

  const debouncedQuery = useDebouncedValue<string>(query, 400);

  const postSearchQuery = useMemo(
    () =>
      new PostSearch({
        search: debouncedQuery,
      }),
    [debouncedQuery]
  );

  const { data, isFetching } = usePosts(postSearchQuery);

  const posts = useMemo(() => data?.pages.flatMap((page) => page.posts) || [], [data]);

  const handleSelect = useCallback(
    (id: string) => {
      const option = posts?.find((p) => p.id === id);

      if (option?.id) {
        command({ title: option.title, target: option.id });
      }
    },
    [command, posts]
  );

  const loadingSpinner = isFetching ? (
    <div className="flex items-center justify-center h-8">
      <Spinner />
    </div>
  ) : null;

  const noResults =
    !isFetching && !posts?.length ? (
      <div className="flex items-center justify-center h-8 text-sm font-medium leading-none">No results</div>
    ) : null;

  const results =
    !isFetching && posts?.length ? (
      <Sheet.ScrollList className="max-h-52">
        {posts.map((p) => (
          <Sheet.Item className="flex items-center gap-2" type="button" key={p.id} onClick={() => handleSelect(p.id)}>
            <span className="flex-1 leading-5">{p.title}</span>
          </Sheet.Item>
        ))}
      </Sheet.ScrollList>
    ) : null;

  return (
    <Sheet.Surface className="w-64">
      {loadingSpinner}
      {noResults}
      {results}
    </Sheet.Surface>
  );
};
