import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { useCurrentPublication } from '@/hooks';
import { useCreatePaywall, useUpdatePaywall } from '@/hooks/usePaywalls';
import usePostTheme from '@/hooks/usePostTheme';
import { Paywall, PaywallStyle } from '@/interfaces/paywall';
import { Button } from '@/ui/Button';

import FormPanel from './FormPanel';
import PreviewPanel from './PreviewPanel';

interface Props {
  onSuccess: () => void;
  paywall?: Paywall;
}

const FormParent = ({ onSuccess, paywall }: Props) => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { data: postTheme } = usePostTheme();
  const queryKey = ['paywalls', currentPublication?.id];

  const [mounted, setMounted] = useState(false);
  const [name, setName] = useState(paywall?.name || 'New Paywall');
  const [offerId, setOfferId] = useState(paywall?.offer_id || '');
  const [imageUrl, setImageUrl] = useState<File | string>(paywall?.image_url || '');
  const [title, setTitle] = useState(paywall?.title || '');
  const [subheader, setSubheader] = useState(paywall?.subheader || '');
  const [description, setDescription] = useState(paywall?.description || '');
  const [cta, setCta] = useState(paywall?.cta_text || '');
  const [features, setFeatures] = useState(paywall?.benefits || []);
  const [style, setStyle] = useState<PaywallStyle>(
    paywall?.style || {
      show_image: true,
      background_color: '#FFFFFF',
      text_color: '#000000',
      button_background_color: '#000000',
      button_text_color: '#FFFFFF',
      container_shadow: '0 0 #00000000',
    }
  );

  useEffect(() => {
    if (!paywall && !mounted && currentPublication && postTheme) {
      setImageUrl(currentPublication?.logo?.url || '');
      setTitle(`Subscribe to our premium content to read the rest.`);
      setDescription(`Become a paying subscriber to get access to this post and other subscriber-only content.`);
      setCta('Upgrade');
      setMounted(true);
      // To keep this a little more user friendly, I decided to only pull in the primary color from the buttons to start
      // User can then make any adjustments they need.
      setStyle({
        ...style,
        show_image: true,
        background_color: '#FFFFFF',
        text_color: '#2A2A2A',
        button_background_color: postTheme?.button_background_color || '#2A2A2A',
        button_text_color: postTheme?.button_text_color || '#FFFFFF',
        container_shadow: '0 0 #00000000',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paywall, mounted, currentPublication, postTheme]);

  const createPaywall = useCreatePaywall({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onSuccess();
    },
  });

  const handleCreatePaywall = () => {
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('paywall[name]', name);
    formData.append('paywall[title]', title);
    formData.append('paywall[description]', description);
    formData.append('paywall[cta_text]', cta);
    formData.append('paywall[subheader]', subheader);
    formData.append('paywall[benefits]', JSON.stringify(features));
    formData.append('paywall[style]', JSON.stringify(style));
    if (paywall?.offer_id) {
      formData.append('paywall[offer_id]', paywall.offer_id);
    }

    if (imageUrl instanceof File) {
      formData.append('paywall[image]', imageUrl);
    }

    createPaywall.mutate(formData);
  };

  const updatePaywall = useUpdatePaywall({
    paywallId: paywall?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onSuccess();
    },
  });

  const handleEditSubmit = () => {
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('paywall[name]', name);
    formData.append('paywall[title]', title);
    formData.append('paywall[description]', description);
    formData.append('paywall[cta_text]', cta);
    formData.append('paywall[subheader]', subheader);
    formData.append('paywall[benefits]', JSON.stringify(features));
    formData.append('paywall[style]', JSON.stringify(style));
    if (offerId) {
      formData.append('paywall[offer_id]', offerId);
    }

    if (imageUrl instanceof File) {
      formData.append('paywall[image]', imageUrl);
    }

    updatePaywall.mutate(formData);
  };

  // On success rellay just clears the state,
  // Which is why it is being used in the exit button here.
  // This will clear state and close the modal.
  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:divide-x divide-gray-200 w-full h-full rounded-lg">
      <div className="w-full lg:w-2/3 p-4">
        <div className="flex items-center gap-4 mb-4">
          <Button type="button" Icon={ArrowLeftIcon} variant="primary-inverse" size="xs" onClick={onSuccess}>
            Exit
          </Button>
          <Typography token="font-medium/text/sm">{name}</Typography>
        </div>

        <PreviewPanel
          title={title}
          description={description}
          cta_text={cta}
          benefits={features}
          subheader={subheader}
          theme={postTheme}
          style={style}
          image_url={imageUrl instanceof File ? URL.createObjectURL(imageUrl) : imageUrl}
        />
      </div>
      <div className="w-full lg:w-1/3 mt-4 lg:mt-0">
        <FormPanel
          name={name}
          setName={setName}
          subheader={subheader}
          setSubheader={setSubheader}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          cta={cta}
          setCta={setCta}
          features={features}
          setFeatures={setFeatures}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          offerId={offerId}
          setOfferId={setOfferId}
          style={style}
          setStyle={setStyle}
          onSubmit={paywall ? handleEditSubmit : handleCreatePaywall}
          isSaving={createPaywall.isLoading || updatePaywall.isLoading}
          isEditing={!!paywall}
        />
      </div>
    </div>
  );
};

export default FormParent;
