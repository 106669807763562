import { v4 as uuidv4 } from 'uuid';

export const navbarContent = {
  type: 'navbar_menu',
  attrs: {
    id: uuidv4(),
    width: 'auto',
  },
  content: [

    {
      type: 'navbar_menu_list',
      attrs: {
        id: uuidv4(),
      },

      content: [
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'left',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'link',
                label: 'Item 4',
                href: '/item-4',
              }
            }
          ]
        },
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'middle',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'link',
                label: 'Item 5',
                href: '/item-5',
              }
            }
          ]
        },
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'right',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'link',
                label: 'Item 6',
                href: '/item-6',
              }
            }
          ]
        }]
    },
    {
      type: 'navbar_menu_list',
      attrs: {
        id: uuidv4(),
        justify: 'center',
      },
      content: [
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'left',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'button',
                label: 'Item 1',
                href: '/item-1',
              }
            },
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'dropdown',
                label: 'Dropdown 1',
              },
              content: [
                {
                  type: 'navbar_dropdown',
                  attrs: {
                    id: uuidv4(),
                    label: 'Dropdown 1',
                    style: 'card',
                  }
                }
              ]
            },
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'dropdown',
                label: 'Dropdown 2',
              },
              content: [
                {
                  type: 'navbar_dropdown',
                  attrs: {
                    id: uuidv4(),
                    label: 'Dropdown 2',
                    style: 'card',
                  }
                }
              ]
            }
          ]
        },
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'middle',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'link',
                label: 'Item 2',
                href: '/item-2',
              }
            }
          ]
        },
        {
          type: 'navbar_menu_list_group',
          attrs: {
            id: uuidv4(),
            type: 'right',
          },
          content: [
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'link',
                label: 'Item 3',
                href: '/item-3',
              }
            },
            {
              type: 'navbar_item',
              attrs: {
                id: uuidv4(),
                type: 'dropdown',
                label: 'Dropdown 3',
              },
              content: [
                {
                  type: 'navbar_dropdown',
                  attrs: {
                    id: uuidv4(),
                    label: 'Dropdown 3',
                    style: 'card',
                  }
                }
              ]
            }
          ]
        }]
    },
  ]
}
