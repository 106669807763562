import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { LockClosedIcon } from '@heroicons/react/20/solid';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { usePoll } from '../../../hooks';
import { Poll, PollListStyle, PollOrientation, PollStyle, PollType } from '../../../interfaces/poll';
import Preview from '../../../pages/Polls/Preview';
import api from '../../../services/swarm';
import { Divider } from '../../Divider';
import { Input, SimpleSelect, Switch, Textarea } from '../../Form';
import Stack from '../../Stack';

import PollOptionsForm from './PollOptionsForm';

interface Props {
  publicationId: string;
  pollId?: string;
  onCancel: () => void;
  onSuccess: (pollId: string) => void;
  slideOverBodyId: string;
}

export type PollDetails = {
  name: string;
  question: string;
  description?: string;
  redirect_url?: string;
  choices: string[];
  list_style: PollListStyle;
  orientation: PollOrientation;
  poll_type: PollType;
  poll_style: PollStyle;
  live_results_enabled: boolean;
  notes: string;
  trivia_answer: string;
  extended_feedback_enabled: boolean;
};

const POLL_CHOICE_MIN = 2;
const TEXT_MAX_LENGTH = 255;

const PollForm: React.FC<Props> = ({ pollId, onCancel, onSuccess, publicationId }) => {
  const queryClient = useQueryClient();
  const { data: poll } = usePoll(pollId);
  const [name, setName] = useState(poll?.name || '');
  const [question, setQuestion] = useState(poll?.question || '');
  const [description, setDescription] = useState(poll?.description || '');
  const [notes, setNotes] = useState(poll?.notes || '');
  const [choices, setChoices] = useState(poll?.poll_choices.map((c) => c.label) || Array(POLL_CHOICE_MIN).fill(''));
  const [redirectUrl, setRedirectUrl] = useState(poll?.redirect_url || '');
  const [listStyle, setListStyle] = useState(poll?.list_style || PollListStyle.DISC);
  const [orientation, setOrientation] = useState(poll?.orientation || PollOrientation.VERTICAL);
  const [pollType, setPollType] = useState(poll?.poll_type || PollType.VOTING);
  const [pollStyle, setPollStyle] = useState(poll?.poll_style || PollStyle.DEFAULT);
  const [triviaAnswer, setTriviaAnswer] = useState(poll?.trivia_answer || '');
  const [extendedFeedbackAndLiveResultsEnabled, setExtendedFeedbackAndLiveResultsEnabled] = useState<boolean>(
    poll?.live_results_enabled || poll?.extended_feedback_enabled || true
  );

  const { settings } = useSettings();

  const handleAddChoice = useCallback(() => {
    setChoices((prevChoices) => [...prevChoices, '']);
  }, []);

  useEffect(() => {
    if (choices.length < POLL_CHOICE_MIN) handleAddChoice();
  }, [choices, handleAddChoice]);

  useEffect(() => {
    if (poll) {
      setName(poll.name);
      setQuestion(poll.question);
      setNotes(poll.notes);
      setDescription(poll.description || '');
      setChoices(poll.poll_choices.map((c) => c.label));
      setRedirectUrl(poll.redirect_url || '');
      setListStyle(poll.list_style || PollListStyle.DISC);
      setOrientation(poll.orientation);
      setPollType(poll.poll_type);
      setPollStyle(poll.poll_style || PollStyle.DEFAULT);
      setTriviaAnswer(poll.trivia_answer);
      setExtendedFeedbackAndLiveResultsEnabled(poll.live_results_enabled || poll.extended_feedback_enabled);
    }
  }, [poll]);

  useEffect(() => {
    if (triviaAnswer && !choices.includes(triviaAnswer)) {
      setTriviaAnswer('');
    }
  }, [choices, triviaAnswer]);

  useEffect(() => {
    if (triviaAnswer && pollType !== PollType.TRIVIA) {
      setTriviaAnswer('');
    }
  }, [pollType, triviaAnswer]);

  const pollDetails: PollDetails = {
    name,
    question,
    description,
    choices: choices.filter((choice) => choice).filter((choice, index, array) => array.indexOf(choice) === index),
    redirect_url: redirectUrl,
    list_style: listStyle,
    orientation,
    poll_type: pollType,
    poll_style: pollStyle,
    live_results_enabled: extendedFeedbackAndLiveResultsEnabled,
    notes,
    trivia_answer: triviaAnswer,
    extended_feedback_enabled: extendedFeedbackAndLiveResultsEnabled,
  };

  const submitMutation = useMutation<Poll>(
    () => {
      if (poll) {
        return api
          .patch(`/polls/${poll.id}`, {
            poll: { ...pollDetails },
            publication_id: publicationId,
          })
          .then((res) => res.data);
      }

      return api
        .post('/polls', {
          poll: { ...pollDetails },
          publication_id: publicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        if (queryClient) queryClient.setQueryData(['polls', res.id], res);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submitMutation.mutateAsync().then((data) => {
      analytics.track('Created a Poll');
      toast.success('Poll saved successfully');
      onSuccess(data.id);
    });
  };

  const isLocked = poll?.is_locked;

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center justify-between">
        {isLocked ? (
          <p className="flex text-xs items-center text-gray-500">This poll is locked and can no longer be edited.</p>
        ) : (
          <p className="flex text-xs items-center text-gray-500">Note: Polls are not live until published.</p>
        )}
      </div>

      <Stack gap={8} direction="col">
        <Input
          name="name"
          labelText="Name"
          type="text"
          value={name}
          required
          placeholder="Untitled poll"
          helperText='Choose a name for the poll (e.g., "Satisfaction Survey").'
          onChange={(e) => setName(e.target.value)}
          maxLength={TEXT_MAX_LENGTH}
        />
        {settings?.polls_v2 && (
          <>
            <Textarea
              name="notes"
              labelText="Description"
              value={notes}
              placeholderText="Type a description (optional)…"
              onChange={(e) => setNotes(e.target.value)}
              helperText="Add a description for this poll."
              maxLength={TEXT_MAX_LENGTH}
            />
            <SimpleSelect
              name="poll_type"
              value={pollType}
              onSelect={(_name, value) => {
                setPollType(value as PollType);
              }}
              options={Object.values(PollType).map((type) => ({
                label: capitalize(type),
                value: type,
              }))}
              labelText="Poll type"
            />
          </>
        )}
        <Input
          name="redirect_url"
          labelText="Redirect URL"
          type="url"
          onChange={(e) => setRedirectUrl(e.target.value)}
          value={redirectUrl}
          helperText="Specify a URL to redirect to after voting, or leave blank to have it return back to the post."
        />
        <Switch
          checked={extendedFeedbackAndLiveResultsEnabled}
          name="extended_feedback_and_live_results_enabled"
          labelText="Show results and collect additional feedback"
          helperText="Displays poll results (% response for voting, % correct/incorrect for trivia) and collect additional feedback after a response before redirecting the subscriber."
          onChange={(_name: string, value: boolean) => {
            setExtendedFeedbackAndLiveResultsEnabled(value);
          }}
        />
        <Divider> </Divider>
        {settings?.polls_v2 ? (
          <SimpleSelect
            name="poll_style"
            value={pollStyle}
            onSelect={(_name, value) => {
              setPollStyle(value as PollStyle);
            }}
            options={Object.values(PollStyle).map((style) => ({
              label: capitalize(style.replace(/_/g, ' ')),
              value: style,
            }))}
            labelText="Poll style"
          />
        ) : (
          <>
            <SimpleSelect
              name="orientation"
              value={orientation}
              onSelect={(_name, value) => {
                setOrientation(value as PollOrientation);
              }}
              options={Object.values(PollOrientation).map((dir) => ({
                label: capitalize(dir),
                value: dir,
              }))}
              labelText="Orientation"
            />
            {orientation === PollOrientation.VERTICAL && (
              <SimpleSelect
                name="list_style"
                value={listStyle}
                onSelect={(_name, value) => {
                  setListStyle(value as PollListStyle);
                }}
                options={Object.values(PollListStyle).map((style) => ({
                  label: capitalize(style),
                  value: style,
                }))}
                labelText="List style"
              />
            )}
          </>
        )}
        <PollOptionsForm
          question={question}
          setQuestion={setQuestion}
          description={description}
          setDescription={setDescription}
          choices={choices}
          setChoices={setChoices}
          labelText="Poll Options"
        />
        {settings?.polls_v2 && pollType === 'trivia' && (
          <SimpleSelect
            name="trivia_answer"
            value={triviaAnswer}
            onSelect={(_name, value) => {
              setTriviaAnswer(value as string);
            }}
            options={choices.map((choice) => ({
              label: choice,
              value: choice,
            }))}
            labelText="Poll Correct Answer"
          />
        )}
        <Divider> </Divider>
        <Preview pollDetails={pollDetails} />
        <div className="flex items-center">
          <Button type="button" className="mx-2" variant="primary-inverse" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" loading={submitMutation.isLoading} disabled={isLocked}>
            {isLocked && <LockClosedIcon className="w-4 h-4 mr-2 text-gray-600" />}
            Save
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default PollForm;
