import { Link, useNavigate } from 'react-router-dom';
import { HandRaisedIcon, SparklesIcon } from '@heroicons/react/24/outline';

import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useUpgradePage } from '@/hooks/useUpgradePages';
import { useUpdateWebTemplate, useUpdateWebTemplatePages, useWebTemplate } from '@/hooks/useWebTemplates';
import { Endpoints } from '@/hooks/useWebTemplates/useUpdateWebTemplatePages';
import { Button } from '@/ui/Button';

import { usePremiumSettings } from '../../../../../../hooks/usePublications';
import { HelperTextWrapper, Section } from '../../../../Components';
import InputForm from '../../../../Components/InputForm';
import useUpgradeForPages from '../hooks/useUpgradeForPages';

const UpgradePageSection = () => {
  const navigate = useNavigate();
  const { isOpen, handleClose, intentAction, preselectedPlan, isPagesEnabled, handleOpen } = useUpgradeForPages();
  const { data: premiumSettings } = usePremiumSettings();
  const premiumDisabled = premiumSettings?.premium_enabled === false;

  const webTemplateMutation = useUpdateWebTemplate();
  const { data: webTemplate } = useWebTemplate();

  const upgradePageQuery = useUpgradePage({ status: 'draft' });
  const { data: upgradePage } = upgradePageQuery;
  const upgradePagesMutation = useUpdateWebTemplatePages({
    endpoint: Endpoints.UPDATE_UPGRADE_PAGES,
  });

  const updateUpgradePages = async (updateValue: any) => {
    upgradePagesMutation.mutateAsync({
      upgrade_page: {
        seo_section: {
          ...upgradePage?.seo_section,
          ...updateValue,
        },
      },
    });
  };

  const handleOnDesignClick = () => {
    if (isPagesEnabled) {
      navigate('/website_builder?page=upgrade_page');
    } else {
      handleOpen();
    }
  };

  return (
    <Section
      title="Upgrade Page"
      description="Customize your upgrade page."
      scrollToId="upgrade-page"
      isLoading={!upgradePage}
      hasDivider
    >
      <UpgradeIntent
        isOpen={isOpen}
        onClose={handleClose}
        intentAction={intentAction}
        preselectedPlan={preselectedPlan}
      />
      <InputForm
        label="External Upgrade Page"
        placeholder="https://www.my-pricing-page.com/"
        helperText="If this is set, users will be redirected to an external upgrade page from your site instead of the beehiiv-supported page."
        value={webTemplate?.external_upgrade_page_href || ''}
        onSave={(val: string) => webTemplateMutation.mutateAsync({ external_upgrade_page_href: val })}
        isSaving={webTemplateMutation.isLoading}
        toastSuccessMessage="Saved!"
      />
      <InputForm
        label="Meta Title"
        placeholder="Meta Title"
        value={upgradePage?.seo_section?.title || ''}
        onSave={(updateTitle: string) => updateUpgradePages({ title: updateTitle })}
        isSaving={upgradePagesMutation.isLoading}
        toastSuccessMessage="Saved!"
      />
      <InputForm
        label="Meta Description"
        placeholder="Meta Description"
        value={upgradePage?.seo_section?.description || ''}
        onSave={(updateDescription: string) => updateUpgradePages({ description: updateDescription })}
        isSaving={upgradePagesMutation.isLoading}
        toastSuccessMessage="Saved!"
      />

      <HelperTextWrapper
        helperText="*Basic design features are avilable in general, this feature is only available on scale plans"
        isTextRendered={!isPagesEnabled}
      >
        <div>
          <Button
            Icon={SparklesIcon}
            onClick={handleOnDesignClick}
            disabled={premiumDisabled}
            disableWith="Design Upgrade Page (unavailable)"
          >
            Design Upgrade Page
          </Button>
        </div>
      </HelperTextWrapper>
      {premiumDisabled && (
        <div className="bg-blue-50 p-4 rounded space-y-2">
          <div className="flex space-x-1 items-center">
            <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-500" />
            <span className="font-bold text-xs text-blue-500">Helping Hand:</span>
          </div>
          <p className="text-xs text-blue-600">
            Before you can customize your Upgrade Page, you need to enable{' '}
            <Link to="/settings/publication/premium" className="underline hover:text-blue-800">
              Paid Subscriptions
            </Link>{' '}
            for your publication.
          </p>
        </div>
      )}
    </Section>
  );
};

export default UpgradePageSection;
