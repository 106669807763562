import { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';

import Badge from '@/components/Badge';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useCreateSecondFactor from '@/hooks/useUsers/useCreateSecondFactor';
import useSecondFactor from '@/hooks/useUsers/useSecondFactor';
import useUpdateSecondFactor from '@/hooks/useUsers/useUpdateSecondFactor';
import FormRow from '@/routes/settings/publication/components/FormRow';
import { Button } from '@/ui/Button';

const TwoFactorSection = () => {
  const secondFactorRequest = useSecondFactor();
  const { data: secondFactor } = secondFactorRequest || {};
  const hasCreatedSecondFactor = typeof secondFactor === 'object' && Object.keys(secondFactor).length > 0;
  const secondFactorActive = secondFactor?.enabled;
  const secondFactorPending = secondFactor?.enabled === false;
  const createSecondFactorMutation = useCreateSecondFactor();
  const updateSecondFactorMutation = useUpdateSecondFactor();
  const [otp, setOtp] = useState<string>('');

  const handleSecondFactorCreate = async () => {
    createSecondFactorMutation.mutate();
  };

  const handleSecondFactorUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    updateSecondFactorMutation.mutate({
      otp,
      enabled: true,
    });
  };

  return (
    <>
      <FormRow
        label="Two-Factor Authentication"
        helperText="Incorporating 2FA protects your account with more security by requiring a one-time code along with your password when you log in"
      >
        {!hasCreatedSecondFactor ? (
          <Button
            size="xs"
            className="w-fit h-fit"
            onClick={() => {
              handleSecondFactorCreate();
            }}
            disabled={createSecondFactorMutation.isLoading}
          >
            Get Started
          </Button>
        ) : null}
        {hasCreatedSecondFactor && secondFactorPending ? (
          <Badge className="w-fit h-fit" type="warning">
            Pending
          </Badge>
        ) : null}
        {hasCreatedSecondFactor && secondFactorActive ? (
          <Badge className="w-fit h-fit" type="success">
            Connected
          </Badge>
        ) : null}
      </FormRow>
      {secondFactorPending ? (
        <div className="flex flex-col gap-y-4 w-full">
          <Typography token="font-normal/text/sm" colorWeight="700">
            Use an authenticator app to scan the QR code
          </Typography>
          <QRCodeSVG value={secondFactor.provisioning_uri} />
          <form onSubmit={handleSecondFactorUpdate}>
            <div className="flex flex-row gap-x-2 items-end">
              <Input
                name="otp"
                labelText="Verify the code from the app"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="flex-grow"
              />
              <Button type="submit" className="w-fit h-fit" loading={updateSecondFactorMutation.isLoading}>
                {updateSecondFactorMutation.isLoading ? 'Verifying...' : 'Verify'}
              </Button>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default TwoFactorSection;
