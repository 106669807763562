import { useState } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import { useSettings } from '@/context/settings-context';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCreateConnectAccountLoginLink from '@/hooks/stripe/useCreateConnectAccountLoginLink';
import { Button } from '@/ui/Button';

interface Props {
  publicationId: string;
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
}

const Manage = ({ publicationId, boostsAccountStatus, boostsAccountType }: Props) => {
  const { settings } = useSettings();
  const shouldRender =
    settings?.wallet &&
    boostsAccountType === StripeAccountType.EXPRESS &&
    boostsAccountStatus === StripeAccountStatus.ACTIVE;

  const [isViewing, setIsViewing] = useState<boolean>(false);
  const { mutateAsync: createLoginLink } = useCreateConnectAccountLoginLink(publicationId, {
    onSuccess: (res) => {
      window.open(`${res.url}`, '_blank');
    },
  });

  const manageExpressAccount = () => {
    setIsViewing(true);
    createLoginLink();
    setIsViewing(false);
  };

  if (!shouldRender) return null;

  return (
    <Button
      Icon={ArrowUpRightIcon}
      iconRight
      type="submit"
      loading={isViewing}
      disabled={isViewing}
      onClick={() => manageExpressAccount()}
      className="w-fit"
      size="xs"
    >
      View Stripe Express Account
    </Button>
  );
};

export default Manage;
