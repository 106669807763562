import { CountryInput, Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Publication } from '@/interfaces/publication';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import FormContentPreviewContainer from '../components/FormContentPreviewContainer';
import PoweredByBeehiiv from '../components/PoweredByBeehiiv';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const streetAddressPlaceholder = '228 Park Ave S, #29976';
const statePlaceholder = 'New York';
const cityPlaceholder = 'New York';
const zipCodePlaceholder = '10003';
const countryPlaceholder = 'US';
const countryNamePlaceholder = 'United States';

const getAddressWithDefaultValues = (address?: Publication['address']) => {
  return {
    id: address?.id,
    street_address: address?.street_address || streetAddressPlaceholder,
    city: address?.city || cityPlaceholder,
    state: address?.state || statePlaceholder,
    country: address?.country || countryPlaceholder,
    zip_code: address?.zip_code || zipCodePlaceholder,
  };
};

const FooterDetailsForm = ({ publication }: Props) => {
  const { handleChangeInAttribute, savingAttribute, savingSubAttribute } = usePublicationSettings();

  const copyrightTextValue = publication.copyright_text || `${publication.copyright_text || publication.name}.`;
  const copyrightLeadingText = `© ${new Date().getFullYear()}`;

  return (
    <BodyContainer>
      <CardHeader title="Email Footer Details" description="Configure your email footer information" />
      <Input
        leadingText={copyrightLeadingText}
        labelText="Copyright Text"
        name="copyright_text"
        placeholder={publication.name}
        defaultValue={copyrightTextValue}
        onBlur={(e) => handleChangeInAttribute('copyright_text')(e.target.value)}
        disabled={savingAttribute === 'copyright_text'}
      />
      <FormContentPreviewContainer className="text-center">
        <Typography token="font-normal/text/sm" colorWeight="500">
          {copyrightLeadingText}{' '}
        </Typography>
        <Typography token="font-medium/text/sm">{copyrightTextValue} </Typography>
      </FormContentPreviewContainer>
      <Input
        labelText="Street Address"
        name="street_address"
        placeholder={streetAddressPlaceholder}
        defaultValue={publication.address?.street_address || streetAddressPlaceholder}
        onBlur={(e) =>
          handleChangeInAttribute(
            'address_attributes',
            'street_address'
          )({
            ...getAddressWithDefaultValues(publication.address),
            street_address: e.target.value,
          })
        }
        disabled={savingSubAttribute === 'street_address'}
      />
      <div className="grid grid-rows-1 grid-cols-1 sm:grid-rows-2 sm:grid-cols-2 gap-y-6 gap-x-4">
        <Input
          labelText="City"
          name="city"
          placeholder={cityPlaceholder}
          defaultValue={publication.address?.city || cityPlaceholder}
          onBlur={(e) =>
            handleChangeInAttribute(
              'address_attributes',
              'city'
            )({
              ...getAddressWithDefaultValues(publication.address),
              city: e.target.value,
            })
          }
          disabled={savingSubAttribute === 'city'}
        />
        <Input
          labelText="State"
          name="state"
          placeholder={statePlaceholder}
          defaultValue={publication.address?.state || statePlaceholder}
          onBlur={(e) =>
            handleChangeInAttribute(
              'address_attributes',
              'state'
            )({
              ...getAddressWithDefaultValues(publication.address),
              state: e.target.value,
            })
          }
          disabled={savingSubAttribute === 'state'}
        />
        <Input
          labelText="Postal Code"
          name="zip_code"
          placeholder={zipCodePlaceholder}
          defaultValue={publication.address?.zip_code || zipCodePlaceholder}
          onBlur={(e) =>
            handleChangeInAttribute(
              'address_attributes',
              'zip_code'
            )({
              ...getAddressWithDefaultValues(publication.address),
              zip_code: e.target.value,
            })
          }
          disabled={savingSubAttribute === 'zip_code'}
        />
        <CountryInput
          value={publication.address?.country || countryPlaceholder}
          labelText="Country"
          name="country"
          onChange={(value) =>
            handleChangeInAttribute(
              'address_attributes',
              'country'
            )({
              ...getAddressWithDefaultValues(publication.address),
              country: value,
            })
          }
          isDisabled={savingSubAttribute === 'country'}
        />
      </div>
      <FormContentPreviewContainer className="flex flex-col gap-y-4 justify-center items-center">
        <div className="flex flex-col w-full text-center">
          <Typography token="font-medium/text/sm">
            {publication.address?.street_address || streetAddressPlaceholder}
          </Typography>
          <Typography token="font-medium/text/sm">
            {publication.address?.city || cityPlaceholder}, {publication.address?.state || statePlaceholder}{' '}
            {publication.address?.zip_code || zipCodePlaceholder},{' '}
            {publication.address?.country_name || countryNamePlaceholder}
          </Typography>
        </div>
        <PoweredByBeehiiv />
      </FormContentPreviewContainer>
    </BodyContainer>
  );
};

export default FooterDetailsForm;
