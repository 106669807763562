import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { PageVersion } from '@/interfaces/dream_builder/page_version';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  pageId: string;
  id: string;
  onSuccess?: () => void;
}

const useUpdatePageVersion = ({ pageId, id, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (pageVersion: Partial<PageVersion>) =>
      api.patch(`/pages/${pageId}/page_versions/${id}`, {
        publication_id: currentPublicationId,
        page_version: pageVersion,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
      },
    }
  );
};

export default useUpdatePageVersion;
