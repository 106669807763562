import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { PriceInterval } from '@/interfaces/tier';

import TierForm from '../../_components/TierForm';
import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import { useUpdateTier } from '../../_hooks';

const TierConfigurationExisting = () => {
  const {
    tierName,
    previewText,
    monthlyEnabled,
    monthlyIntervalDisplay,
    monthlyCents,
    monthlyCta,
    monthlyBenefits,
    annualEnabled,
    annualIntervalDisplay,
    annualCents,
    annualCta,
    annualBenefits,
    oneTimeEnabled,
    oneTimeIntervalDisplay,
    oneTimeCents,
    oneTimeCta,
    oneTimeBenefits,
    donationEnabled,
    donationIntervalDisplay,
    donationCta,
    donationBenefits,
    currency,
    monthlyPriceId,
    annualPriceId,
    oneTimePriceId,
    donationPriceId,
    redirectUrl,
    isSaving,
    setIsSaving,
    setErrors,
  } = useTierConfigurationContext();

  const { tierId } = useParams() as { tierId: string };
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const { mutate } = useUpdateTier({
    id: tierId,
    onSuccess: () => {
      setIsSaving(false);
      if (hasSettingsV2) {
        navigate('/monetize/subscriptions/paid_tiers');
      } else {
        navigate('/settings/publication/premium');
      }
    },
    onError: (errors) => {
      setErrors(errors);
      setIsSaving(false);
    },
  });

  const onSave = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    mutate({
      name: tierName,
      description: previewText,
      redirect_url: redirectUrl,
      prices_attributes: [
        {
          ...(monthlyPriceId ? { id: monthlyPriceId } : { interval: PriceInterval.MONTH }),
          interval_display: monthlyIntervalDisplay,
          amount_cents: monthlyCents,
          cta: monthlyCta,
          features: monthlyBenefits,
          enabled: monthlyEnabled && !donationEnabled,
          currency,
        },
        {
          ...(annualPriceId ? { id: annualPriceId } : { interval: PriceInterval.YEAR }),
          interval_display: annualIntervalDisplay,
          amount_cents: annualCents,
          cta: annualCta,
          features: annualBenefits,
          enabled: annualEnabled && !donationEnabled,
          currency,
        },
        ...(settings?.one_time_payments
          ? [
              {
                ...(oneTimePriceId ? { id: oneTimePriceId } : { interval: PriceInterval.ONE_TIME }),
                interval_display: oneTimeIntervalDisplay,
                amount_cents: oneTimeCents,
                cta: oneTimeCta,
                features: oneTimeBenefits,
                enabled: oneTimeEnabled && !donationEnabled,
                currency,
              },
            ]
          : []),
        ...(settings?.donation_payments
          ? [
              {
                ...(donationPriceId ? { id: donationPriceId } : { interval: PriceInterval.DONATION }),
                interval_display: donationIntervalDisplay,
                amount_cents: 0,
                cta: donationCta,
                features: donationBenefits,
                enabled: donationEnabled,
                currency,
              },
            ]
          : []),
      ],
    });
  };

  return <TierForm isSaving={isSaving} onSave={onSave} />;
};

export default TierConfigurationExisting;
