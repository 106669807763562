import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { Typography } from '@/components/Typography';

import PublicationIcon from './PublicationIcon';

interface Props {
  label: string;
  to: string;
  isSelected: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  showPlaceholderIcon?: boolean;
  showBorder?: boolean;
}

const GroupLink = ({
  label,
  to,
  isSelected,
  className,
  onClick,
  showPlaceholderIcon = false,
  showBorder = true,
}: Props) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classNames(
        'flex flex-row justify-between p-2 items-center rounded-md',
        isSelected && showBorder ? 'bg-surface-50 border border-surface-100' : 'hover:bg-surface-50',
        className
      )}
    >
      <div className="flex flex-row gap-x-2">
        {showPlaceholderIcon ? (
          <span className="mt-0.5 w-4 h-4 p-0.5 bg-action-tertiary-50 rounded-sm flex grow-0 shrink-0">
            <PublicationIcon />
          </span>
        ) : null}
        <Typography token="font-medium/text/sm">{label}</Typography>
      </div>
      <div className="flex grow-0	shrink-0">
        {isSelected ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
      </div>
    </Link>
  );
};

export default GroupLink;
