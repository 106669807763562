import { AlignCenterHorizontal, AlignLeft, AlignRight, CaretRight } from "@phosphor-icons/react"
import { JustifyType } from "@shared/dream-components";

import { Popover, PopoverContent, PopoverTrigger } from "@/routes/website/_components/UI/Popover"
import { Text } from "@/routes/website/_components/UI/Text"

import { useNavbarContext } from "../../../NavbarContext";
import { formatCamelCase } from "../../../utils";
import { OptionsWithPreview } from "../../ui/OptionsWithPreview";

export const AlignmentSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_menu_list") return null;

  const contentValue = selectedContent.attrs?.justify || 'start';

  const Icon = {
    start: AlignLeft,
    center: AlignCenterHorizontal,
    end: AlignRight,
  }[contentValue];

  const handleAlignmentChange = (justify: JustifyType) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { justify });
  }

  return <Popover>
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Align
      </Text>

      <PopoverTrigger asChild>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Icon className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium">
                {formatCamelCase(contentValue)}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </PopoverTrigger>
    </div>
    <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
      <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
        <Text size="sm" weight="semibold">
          Align
        </Text>
        <div className="flex flex-col gap-2">

          {
            (['start', 'center', 'end'] as JustifyType[]).map(justify => (
              <OptionsWithPreview key={justify} label={formatCamelCase(justify)} selected={justify === contentValue} onClick={() => handleAlignmentChange(justify)}>
                {/* preview */}
                <div className="flex items-center bg-wb-primary rounded-md p-2 w-full gap-1 shadow-wb-md">
                  <div className="h-2 w-3 rounded-full bg-wb-accent" />
                  <div className={`flex items-center justify-${justify} flex-1 gap-1`}>
                    <div className="h-2 w-3 rounded-full bg-wb-surface" />
                    <div className="h-2 w-3 rounded-full bg-wb-surface" />
                  </div>
                  <div className="h-2 w-3 rounded-full bg-wb-surface" />
                </div>
              </OptionsWithPreview>
            ))
          }
        </div>
      </div>
    </PopoverContent>
  </Popover >
}
