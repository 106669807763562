import { Fragment, ReactNode } from 'react';

import usePostEmailClickStats from '@/hooks/usePostEmailClickStats';
import usePostSendAndOpenStats from '@/hooks/usePostSendAndOpenStats';
import usePostWebStats from '@/hooks/usePostWebStats';
import usePublicationTotalClickedStats from '@/hooks/usePublicationTotalClickedStats';
import usePublicationTotalDeliveredStats from '@/hooks/usePublicationTotalDeliveredStats';
import usePublicationTotalOpenedStats from '@/hooks/usePublicationTotalOpenedStats';
import { PostStats } from '@/interfaces/post';
import { clickRate, errorRate, openRate } from '@/utils/engagement/calculations';

interface Props {
  postId: string;
  children: (stats: PostStats, isLoading: boolean, isError: boolean) => ReactNode; // todo: make stats a union of new types
}

const PostStatsContainer = ({ postId, children }: Props) => {
  const {
    data: postWebStatsData,
    isLoading: isLoadingPostWebStats,
    isError: isErrorPostWebStats,
    isSuccess: isSuccessPostWebStats,
  } = usePostWebStats({ id: postId, staleTime: undefined });
  const {
    data: postEmailClickStatsData,
    isLoading: isLoadingPostEmailClickStats,
    isError: isErrorPostEmailClickStats,
    isSuccess: isSuccessPostEmailClickStats,
  } = usePostEmailClickStats({ id: postId, staleTime: undefined });
  const {
    data: postSendAndOpenStatsData,
    isLoading: isLoadingPostSendAndOpenStats,
    isError: isErrorPostSendAndOpenStats,
    isSuccess: isSuccessPostSendAndOpenStats,
  } = usePostSendAndOpenStats({ id: postId, staleTime: undefined });
  const {
    data: publicationTotalDeliveredData,
    isLoading: isLoadingPublicationTotalDeliveredStats,
    isError: isErrorPublicationTotalDeliveredStats,
    isSuccess: isSuccessPublicationTotalDeliveredStats,
  } = usePublicationTotalDeliveredStats({});
  const {
    data: publicationTotalClickedData,
    isLoading: isLoadingPublicationTotalClickedStats,
    isError: isErrorPublicationTotalClickedStats,
    isSuccess: isSuccessPublicationTotalClickedStats,
  } = usePublicationTotalClickedStats({});
  const {
    data: publicationTotalOpenedData,
    isLoading: isLoadingPublicationTotalOpenedStats,
    isError: isErrorPublicationTotalOpenedStats,
    isSuccess: isSuccessPublicationTotalOpenedStats,
  } = usePublicationTotalOpenedStats({});

  if (
    !isSuccessPublicationTotalDeliveredStats ||
    !isSuccessPublicationTotalClickedStats ||
    !isSuccessPublicationTotalOpenedStats ||
    !isSuccessPostSendAndOpenStats ||
    !isSuccessPostEmailClickStats ||
    !isSuccessPostWebStats
  )
    return null;

  return (
    <Fragment key={`post-stats-container-${postId}`}>
      {children(
        {
          total_sent: postSendAndOpenStatsData.total_sent,
          total_opened: postSendAndOpenStatsData.total_opened,
          total_unique_opened: postSendAndOpenStatsData.total_unique_opened,
          total_email_clicked: postEmailClickStatsData.total_verified_clicked,
          total_unique_email_clicked: postEmailClickStatsData.total_unique_verified_clicked,
          open_rate: openRate({
            totalUniqueOpened: postSendAndOpenStatsData.total_unique_opened,
            totalDelivered: postSendAndOpenStatsData.total_delivered,
          }),
          all_time_open_rate: openRate({
            totalUniqueOpened: publicationTotalOpenedData.total_opened,
            totalDelivered: publicationTotalDeliveredData.total_delivered,
          }),
          click_rate: clickRate({
            totalViewed: postSendAndOpenStatsData.total_unique_opened,
            totalClicked: postEmailClickStatsData.total_unique_verified_clicked,
          }),
          click_rate_by_total_delivered: clickRate({
            totalClicked: postEmailClickStatsData.total_unique_verified_clicked,
            totalViewed: postSendAndOpenStatsData.total_delivered,
          }),
          click_rate_by_total_opened: clickRate({
            totalClicked: postEmailClickStatsData.total_unique_verified_clicked,
            totalViewed: postSendAndOpenStatsData.total_opened,
          }),
          all_time_click_rate: clickRate({
            totalClicked: publicationTotalClickedData.total_clicked,
            totalViewed: publicationTotalOpenedData.total_opened,
          }),
          total_web_clicked: postWebStatsData.total_web_clicked,
          total_unique_web_clicked: postWebStatsData.total_unique_web_clicked,
          total_web_viewed: postWebStatsData.total_web_viewed,
          total_unsubscribes: postSendAndOpenStatsData.total_unsubscribes,
          total_spam_reported: postSendAndOpenStatsData.total_spam_reported,
          error_rate: errorRate({
            totalErrored: postSendAndOpenStatsData.total_errored,
            totalSent: postSendAndOpenStatsData.total_sent,
          }),
          total_delivered: postSendAndOpenStatsData.total_delivered,
          total_errored: postSendAndOpenStatsData.total_errored,
        },
        isLoadingPostWebStats ||
          isLoadingPostEmailClickStats ||
          isLoadingPostSendAndOpenStats ||
          isLoadingPublicationTotalDeliveredStats ||
          isLoadingPublicationTotalClickedStats ||
          isLoadingPublicationTotalOpenedStats,
        isErrorPostWebStats ||
          isErrorPostEmailClickStats ||
          isErrorPostSendAndOpenStats ||
          isErrorPublicationTotalDeliveredStats ||
          isErrorPublicationTotalClickedStats ||
          isErrorPublicationTotalOpenedStats
      )}
    </Fragment>
  );
};

export default PostStatsContainer;
