import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TrashIcon } from '@heroicons/react/24/outline';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SplitTestOption } from '@/interfaces/split_test_option';
import api from '@/services/swarm';

interface Props {
  labelText: string;
  postId: string;
  splitTestOption: SplitTestOption;
  deletable: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  locked?: boolean;
}

interface KeyUpHandlerEvent extends React.KeyboardEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

const UPDATE_DELAY = 400;

const OptionForm = ({ labelText, postId, splitTestOption, deletable, onSave, onDelete, locked }: Props) => {
  const updateTimeout = useRef<number>();
  const [publicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(splitTestOption.value);
  const name = splitTestOption?.id ? `split_test_option_${splitTestOption.id}` : 'new_split_test_option';

  const updateSplitTestOption = useMutation(
    (newValue: string) => {
      return api
        .patch(`/posts/${postId}/split_test_option`, {
          publication_id: publicationId,
          split_test_option: {
            id: splitTestOption.id,
            value: newValue,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['split_test', postId]);

        if (onSave) {
          onSave();
        }
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );

  const deleteSplitTestOption = useMutation(
    () => {
      return api
        .delete(`/posts/${postId}/split_test_option`, {
          data: {
            publication_id: publicationId,
            split_test_option: {
              id: splitTestOption.id,
            },
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['split_test', postId]);
      },
    }
  );

  const keyUpHandler = (event: KeyUpHandlerEvent) => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }

    updateTimeout.current = window.setTimeout(() => {
      if (event.target.value !== splitTestOption.value) {
        updateSplitTestOption.mutate(event.target.value);
      }
    }, UPDATE_DELAY);
  };

  const handleRemoveOption = () => {
    if (onDelete) onDelete();
    deleteSplitTestOption.mutate();
  };

  return (
    <div className="flex flex-row space-x-2">
      <div className="m-auto h-3 w-3 p-[0.5835rem] flex justify-center items-center text-gray-500 border border-action-secondary-200 rounded bg-action-secondary-50">
        <Typography token="font-normal/text/xs" className="leading-4" color="secondary" colorWeight="900">
          {labelText}
        </Typography>
      </div>
      <div className="w-full flex flex-row">
        <Input
          className="w-full my-auto split-test-option-input"
          placeholder={`Enter subject line ${labelText}`}
          onKeyUp={keyUpHandler}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          name={name}
          required
          value={value}
          // errorText={error}
          disabled={locked}
        />
        {deletable && !locked && (
          <div aria-hidden="true" className="ml-1 my-auto cursor-pointer" onClick={handleRemoveOption}>
            <TrashIcon className="w-5 h-5 text-gray-500 hover:text-primary-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionForm;
