import { Breadcrumbs } from '@/components/Breadcrumbs';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

interface Props {
  currentPublicationId: string;
  endpoint?: WebhookEndpoint;
  messageAttempt?: WebhookMessageAttempt;
  showEdit?: boolean;
}

const Header = ({ currentPublicationId, endpoint, messageAttempt, showEdit }: Props) => {
  return (
    <Breadcrumbs>
      <Breadcrumbs.Home />
      <Breadcrumbs.Item to={appendSettingsPublicationId('/settings/publication/webhooks', currentPublicationId)}>
        All Endpoints
      </Breadcrumbs.Item>
      {endpoint && (
        <>
          <Breadcrumbs.Item
            to={appendSettingsPublicationId(`/settings/publication/webhooks/${endpoint.id}/show`, currentPublicationId)}
          >
            {endpoint.description}
          </Breadcrumbs.Item>
          {showEdit && (
            <Breadcrumbs.Item
              to={appendSettingsPublicationId(
                `/settings/publication/webhooks/${endpoint.id}/edit`,
                currentPublicationId
              )}
            >
              Edit
            </Breadcrumbs.Item>
          )}
        </>
      )}
      {messageAttempt?.id && endpoint?.id && (
        <Breadcrumbs.Item
          to={appendSettingsPublicationId(
            `/settings/publication/webhooks/${endpoint.id}/messages/${messageAttempt.id}`,
            currentPublicationId
          )}
        >
          {messageAttempt.id}
        </Breadcrumbs.Item>
      )}
    </Breadcrumbs>
  );
};

export default Header;
