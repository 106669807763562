import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Card } from '@/ui/Card';

import PageContainer from '../components/PageContainer';
import { usePublicationSettings } from '../context';

import Endpoints from './Endpoints';

const PublicationWebhookSettings: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card id="endpoints" showCopyIdButton>
            <Endpoints publication={publication} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationWebhookSettings;
