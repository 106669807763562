import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import ImportSubscriberModalContent from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/ImportSubscriberModalContent';
import { ImportState } from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/types';
import { useImportSubscriberList } from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/useImportSubscriberList';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import TwoColumnPageContainer from '../../components/TwoColumnPageContainer';

import ImportSubscriberListForm from './ImportSubscriberListForm';
import Summary from './Summary';

const ImportForm = () => {
  const {
    modalHeaderText,
    isSystemAdmin,
    file,
    setFile,
    sendWelcomeEmail,
    setSendWelcomeEmail,
    skipEmailValidation,
    setSkipEmailValidation,
    currentPublicationId,
    commaSeparatedEmails,
    handleEmailInputChange,
    publicationName,
    publicationWelcomeEmail,
    csvColumns,
    setCsvColumns,
    setColumnMapping,
    columnMappingErrors,
    setColumnMappingErrors,
    onImportClear,
    onImportConfirm,
    onMappingStart,
    showMappingModal,
    makeFormData,
    currentImportSubscriberStep: currentImportStep,
    isAddingToAutomations,
    onAddToAutomationsChange,
    selectedAutomations,
    onSelectAutomation,
    onDeselectAutomation,
    onDeselectAllAutomations,
    importType,
    handleImportTypeChange,
    handleSubscriberTagSelect,
    handleSubscriberTagDeselect,
    onAddSubscriberTagsChange,
    isAddingSubscriberTags,
    selectedSubscriberTags,
    taggingEnabled,
  } = useImportSubscriberList();

  const [isWorking, setIsWorking] = useState(false);
  const queryClient = useQueryClient();
  const modalId = 'import-subscriber-list-modal';
  const navigate = useNavigate();

  const clearModal = () => {
    setIsWorking(false);
    setColumnMappingErrors(false);
    onImportClear();
  };

  const clearPage = () => {
    // Clear the query cache for the current publication so the import appears in the list.
    queryClient.invalidateQueries([currentPublicationId, 'imports']);
    navigate(appendSettingsPublicationId('/settings/publication/subscribers_import', currentPublicationId));
  };

  const handleSubmit = async () => {
    setIsWorking(true);

    try {
      const response = await api.post('/subscription_imports', makeFormData());
      if (response.data.columns) {
        analytics.track('Imported Subscribers');
        setCsvColumns(response.data.columns);
        setIsWorking(false);
        onMappingStart();
      } else {
        clearPage();
        toast.success('Import completed!');
      }
    } catch (error: any) {
      clearModal();
      toast.error('There was an error importing your subscribers');
    }
  };

  return (
    <>
      <ActionModal
        isOpen={currentImportStep !== ImportState.INITIAL}
        onClose={clearModal}
        onProceed={handleSubmit}
        resourceId={currentPublicationId}
        isWorking={isWorking}
        headerText={modalHeaderText}
        actionText="Import"
        modalSize="xl"
        bodyId={modalId}
        overflow="visible"
        disabled={columnMappingErrors}
      >
        <ImportSubscriberModalContent
          publicationName={publicationName}
          showMappingModal={showMappingModal}
          csvColumns={csvColumns}
          setColumnMappingErrors={setColumnMappingErrors}
          setColumnMapping={setColumnMapping}
        />
      </ActionModal>

      <TwoColumnPageContainer
        rhsColumn={
          <Summary
            importType={importType}
            sendWelcomeEmail={sendWelcomeEmail}
            skipEmailValidation={skipEmailValidation}
            addedToAutomation={selectedAutomations.length > 0}
            assignedSubscriberTags={selectedSubscriberTags.length > 0}
          />
        }
      >
        <ImportSubscriberListForm
          selectedImportType={importType}
          onImportTypeChange={handleImportTypeChange}
          onFileChange={setFile}
          selectedFile={file}
          commaSeparatedEmails={commaSeparatedEmails}
          onCommaSeparatedEmailsChange={handleEmailInputChange}
          shouldSendWelcomeEmail={sendWelcomeEmail}
          shouldDisableWelcomeEmail={!publicationWelcomeEmail}
          onSendWelcomeEmailChange={setSendWelcomeEmail}
          canSkipEmailValidation={isSystemAdmin}
          shouldSkipEmailValidation={skipEmailValidation}
          onSkipEmailValidationChange={setSkipEmailValidation}
          isAddingToAutomations={isAddingToAutomations}
          onAddToAutomationsChange={onAddToAutomationsChange}
          canEnrollToAutomations
          selectedAutomations={selectedAutomations}
          onSelectAutomation={onSelectAutomation}
          onDeselectAutomation={onDeselectAutomation}
          onDeselectAllAutomations={onDeselectAllAutomations}
          taggingEnabled={taggingEnabled}
          handleSubscriberTagSelect={handleSubscriberTagSelect}
          handleSubscriberTagDeselect={handleSubscriberTagDeselect}
          onAddSubscriberTagsChange={onAddSubscriberTagsChange}
          isAddingSubscriberTags={isAddingSubscriberTags}
          selectedSubscriberTags={selectedSubscriberTags}
          onImportConfirm={onImportConfirm}
        />
      </TwoColumnPageContainer>
    </>
  );
};

export default ImportForm;
