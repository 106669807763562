import { FC } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import Tooltip from '../Tooltip';

const COLORS = {
  flush: 'border border-transparent',
  warning: 'bg-yellow-100 text-yellow-800 border-yellow-200 border border-gray-300 rounded-md',
  alert: 'bg-red-100 text-red-800 border-red-200 border border-gray-300 rounded-md',
  success: 'bg-green-100 text-green-800 border-green-200 border border-gray-300 rounded-md',
  information: 'bg-gray-100 text-gray-800 border-gray-200 border border-gray-300 rounded-md',
  info_blue: 'bg-blue-100 text-blue-800 border-blue-200 border border-gray-300 rounded-md',
  tertiary: 'bg-action-tertiary-50 text-action-tertiary-900 border border-action-tertiary-200 rounded-md',
};

export interface BadgeProps {
  type?: 'warning' | 'alert' | 'success' | 'information' | 'info_blue' | 'tertiary' | 'flush';
  size?: 'sm' | 'md';
  className?: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  alwaysShowDismiss?: boolean;
  dismissClassName?: string;
  tooltip?: string;
}

const Badge: FC<BadgeProps> = ({
  dismissClassName,
  type = 'information',
  size = 'md',
  children,
  className = '',
  onDismiss,
  alwaysShowDismiss = false,
  tooltip,
}: BadgeProps) => {
  if (!children) return null;

  const colorClasses = COLORS[type] ?? 'bg-gray-100 text-gray-800';

  return (
    <span
      className={cx(
        'shadow-xs inline-flex gap-x-2 items-center text-xs font-medium relative group',
        className,
        colorClasses,
        { 'h-4.5 px-1.5 text-xs': size === 'sm' },
        { 'h-5.5 px-2.5 py-0.5': size === 'md' }
      )}
    >
      {tooltip ? (
        <Tooltip id={`badge-tooltip-${btoa(tooltip)}`} text={tooltip} showIcon={false}>
          <div className="flex flex-row gap-x-1 items-center">
            <InformationCircleIcon className="w-3 h-3" />
            {children}
          </div>
        </Tooltip>
      ) : (
        children
      )}
      {onDismiss && (
        <XMarkIcon
          onClick={onDismiss}
          className={cx(
            alwaysShowDismiss ? 'w-4 opacity-100' : 'w-0 group-hover:w-4 opacity-0 group-hover:opacity-100',
            'cursor-pointer h-4 transition-opacity duration-200 mr-3',
            dismissClassName
          )}
        />
      )}
    </span>
  );
};

export default Badge;
