import { getWidthStyle, paragraphStyles } from '@shared/dream-components';
import ParagraphExtension from '@tiptap/extension-paragraph';
import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';

import { ParagraphView } from './views/ParagraphView';

export const PARAGRAPH_DEFAULTS = {
  padding: '4px 0px 4px 0px', // top right bottom left
  paddingRoot: '4px 30px 4px 30px', // top right bottom left
  margin: '0px 0px 0px 0px', // top right bottom left
};

export const Paragraph = ParagraphExtension.extend({
  addAttributes() {
    return {
      textAlign: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({
          'data-text-align': attributes.textAlign,
        }),
      },
      padding: {
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
          style: `--padding: ${attributes.padding || PARAGRAPH_DEFAULTS.padding};--padding-root: ${
            attributes.padding || PARAGRAPH_DEFAULTS.paddingRoot
          }`,
        }),
      },
      margin: {
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => {
          const [marginTop, marginRight, marginBottom, marginLeft] = (attributes.margin ?? PARAGRAPH_DEFAULTS.margin)
            .toString()
            .split(' ');
          return {
            'data-margin': attributes.margin,
            style: `--margin: ${
              attributes.margin || PARAGRAPH_DEFAULTS.margin
            }; --margin-top: ${marginTop}; --margin-right: ${marginRight}; --margin-bottom: ${marginBottom}; --margin-left: ${marginLeft}`,
          };
        },
      },
      width: {
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `--width: ${attributes.width || getWidthStyle(attributes.width, attributes.width_max).width}`,
        }),
      },
      width_max: {
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
          style: `--max-width: ${
            attributes.width_max || getWidthStyle(attributes.width, attributes.width_max).maxWidth
          }`,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(HTMLAttributes, { class: paragraphStyles.paragraphElement }), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphView);
  },
});

export default Paragraph;
