import Grid from '@/components/Grid';

import { useSubscriberContext } from '../../_context/subscriberContext';

import MetricCard from './MetricCard';

const Metrics = () => {
  const { send_stats: sendStats } = useSubscriberContext();

  return (
    <Grid gap={4} className="grid-cols-4">
      <MetricCard label="Total Emails Delivered" value={sendStats.total_delivered.toLocaleString()} />
      <MetricCard label="Total Emails Opened" value={sendStats.total_unique_opened.toLocaleString()} />
      <MetricCard label="Open Rate" value={`${sendStats.open_rate.toFixed(1)}%`} />
      <MetricCard label="Click Rate" value={`${sendStats.click_rate.toFixed(1)}%`} />
    </Grid>
  );
};

export default Metrics;
