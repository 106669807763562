import { useQuery } from 'react-query';

import { Settings } from '@/interfaces/setting';
import api from '@/services/swarm';

const usePublicationSettings = (publicationId?: string | null) =>
  useQuery<Settings>(
    ['publications', publicationId, 'settings'],
    () =>
      api
        .get('/settings', {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 1 minute
      enabled: !!publicationId,
    }
  );

export default usePublicationSettings;
