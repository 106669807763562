import { useEffect } from 'react';
import { File } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../UI/Text';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

export const LinkToSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = useActiveNode(editor);
  useEffect(() => {
    console.log(activeNodeType);
    console.log(activeNodePos);
    console.log(editor);
    console.log(activeNodeAttributes);
  }, [activeNodeAttributes, activeNodePos, activeNodeType, editor]);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Link to
      </Text>
      <div className="grow bg-wb-tertiary rounded-lg shadow-sm">
        <div className="w-full justify-between flex items-center gap-2 p-2">
          <div className="flex items-center gap-1">
            <File format="outline" weight="bold" />
            <Text size="2xs" weight="medium">
              Home
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
