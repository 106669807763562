import React from 'react';

import { Typography } from '@/components/Typography';

import BodyContainer from '../../components/BodyContainer';
import { StepProps } from '../new/types';

const WordpressInstructions: React.FunctionComponent<StepProps> = () => (
  <BodyContainer>
    <Typography token="font-normal/text/sm" colorWeight="500">
      Ensure that your RSS feed includes entire posts instead of just excerpts. This can be done in Wordpress by going
      to <b>Settings</b> &gt; <b>Reading</b> &gt; <b>For each post in a feed, include</b> and selecting <b>Full Text</b>
      . Make sure to click Save.
    </Typography>
  </BodyContainer>
);

export default WordpressInstructions;
