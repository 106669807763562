// Define the extension for Tiptap

import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DividerView } from './views/DividerView';

export const Divider = Node.create({
  name: 'divider',
  group: 'block',
  atom: true,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ['hr'];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DividerView);
  },
});
