import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { CustomDomainTypes } from '@/interfaces/custom_domain';
import api from '@/services/swarm';

interface Props {
  type: CustomDomainTypes;
  onSuccess?: () => void;
}

const useCustomDomainConfigurationVerification = ({ type, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
    type,
  };

  return useMutation(
    (customDomainId: string) => api.post(`/custom_domains/${customDomainId}/configuration_verifications`, params),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['custom_domains', currentPublicationId]);
        toast.success('DNS Configuration Verified');

        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export default useCustomDomainConfigurationVerification;
