import { TextT, Trash } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../UI/Text';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ImageAltSettings = ({ editor }: { editor: Editor }) => {
  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Alt Text
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <div className="w-full justify-between flex items-center gap-2 p-2">
          <div className="flex items-center gap-1">
            <TextT className="text-wb-secondary" weight="bold" />
            <Text size="2xs" weight="medium">
              Image
            </Text>
          </div>
          <Trash className="text-wb-secondary" weight="bold" />
        </div>
      </div>
    </div>
  );
};
