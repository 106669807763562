import { useQuery } from 'react-query';

import api from '../../services/swarm';

const useTrialStatus = (publicationId: string) => {
  const fetchTrialStatus = () => api.get(`/publications/${publicationId}/trial_status`).then((res) => res.data);

  return useQuery(['publication-trial-status', publicationId], fetchTrialStatus, {
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(publicationId),
  });
};

export default useTrialStatus;
