import { ReactNodeViewRenderer } from '@tiptap/react';

import { ImageBlock as BeehiivImageBlock } from '@/components/TiptapEditor/extensions';

import { ImageBlockView } from './views/ImageView';

export const ImageBlock = BeehiivImageBlock.extend({
  content: '',

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlockView, {
      className: 'w-fit',
    });
  },
});
