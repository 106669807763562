import { useEffect, useState } from "react";
import { CaretDown } from "@phosphor-icons/react"

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/routes/website/_components/UI/DropdownMenu"
import { SimpleInput, SimpleInputWrapper } from "@/routes/website/_components/UI/Input";
import { Text } from "@/routes/website/_components/UI/Text"

import { useNavbarContext } from "../../../NavbarContext";
import { getParent } from "../../../utils";

const parseCssValue = (value: string) => {
  if (!value) return 0;
  if (value === 'auto') {
    return 0;
  }
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};
export const WidthSettings = () => {
  const { content, selectedContent: rawSelectedContent, onUpdateNodeAttributes } = useNavbarContext();
  const selectedContent = rawSelectedContent?.type === 'navbar_menu_list' && content ? getParent(content, rawSelectedContent) : rawSelectedContent;

  const [width, setWidth] = useState<string | number>(0);
  const [widthDimensionType, setWidthDimensionType] = useState<'fixed' | 'fill' | 'fit'>('fill');

  const contentValue = selectedContent?.attrs && 'width' in selectedContent.attrs ? selectedContent.attrs.width : '100%';

  useEffect(() => {
    setWidthDimensionType(() => {
      if (contentValue === '100%') {
        return 'fill';
      } if (contentValue === 'auto') {
        return 'fit';
      }
      return 'fixed';
    });
    setWidth(parseCssValue(contentValue as string));
  }, [contentValue]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_menu" && selectedContent.type !== "navbar_menu_list") return null;


  const options: Record<string, string> = {
    fixed: 'Fixed',
    fill: 'Fill',
    fit: 'Fit',
  };


  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setWidth(0);
      return;
    }
    setWidth(parseInt(e.target.value, 10));
  }

  const onUpdateWidthInput = (type: 'fit' | 'fill' | 'fixed', fixedWidth: string | number) => {
    if (!selectedContent.attrs?.id) return;
    if (type === 'fit') {
      onUpdateNodeAttributes(selectedContent.attrs.id, { width: 'auto' });
    } else if (type === 'fill') {
      onUpdateNodeAttributes(selectedContent.attrs.id, { width: '100%' });
    } else {
      onUpdateNodeAttributes(selectedContent.attrs.id, { width: `${fixedWidth}px` });
    }
  }

  const handleWidthDimensionTypeChange = (type: 'fill' | 'fixed' | 'fit') => {
    if (!selectedContent.attrs?.id) return;
    onUpdateWidthInput(type, 700)
  }


  return <div className="flex items-center justify-stretch gap-2">
    <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
      Width
    </Text>


    <div className="grow flex gap-2 min-w-0 w-[120px]">
      <SimpleInputWrapper>
        {widthDimensionType === 'fill' && <SimpleInput type="text" value="100%" disabled />}
        {widthDimensionType === 'fit' && <SimpleInput type="text" value="auto" disabled />}
        {(widthDimensionType === 'fixed') && <SimpleInput type="number" value={width} onChange={handleWidthChange} onBlur={() => onUpdateWidthInput('fixed', width)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onUpdateWidthInput('fixed', width);
            }
          }}
        />}
      </SimpleInputWrapper>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="cursor-pointer">
          <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {options[widthDimensionType]}
              </Text>
            </div>
            <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
          {Object.keys(options).map((optionKey) => (
            <DropdownMenuItem
              key={optionKey}
              onSelect={() => {
                handleWidthDimensionTypeChange(optionKey as 'fixed' | 'fill' | 'fit');
              }}
            >
              {options[optionKey]}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </div>
}
