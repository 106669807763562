import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Backlink as IBacklink } from '@/interfaces/backlink';

import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

interface Props {
  backlink: IBacklink;
}

export const Backlink = ({ backlink }: Props) => {
  const url = useMemo(() => {
    if (backlink.postToShow?.status === 'draft') {
      return backlink.postToShow.draft_url;
    }

    return backlink.postToShow?.url;
  }, [backlink.postToShow]);

  return (
    <div className="flex items-center gap-2">
      <p className="text-gray-500">{backlink.postToShow?.web_title}</p>

      <Tooltip title="Edit">
        <Link
          to={`/posts/${backlink.postToShow?.id}/edit`}
          target="_blank"
          rel="noreferrer noopener nofollow"
          className="flex justify-center items-center"
        >
          <Icon name="Edit" className="w-4 h-4 text-gray-500" />
        </Link>
      </Tooltip>

      <Tooltip title={backlink.postToShow?.status === 'draft' ? 'View draft' : 'View published'}>
        <a href={url} target="_blank" rel="noreferrer noopener nofollow" className="flex justify-center items-center">
          <Icon name="External" className="w-4 h-4 text-gray-500" />
        </a>
      </Tooltip>
    </div>
  );
};
