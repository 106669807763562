import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useSettings from '@/hooks/usePublications/usePublicationSettings';
import { Settings } from '@/interfaces/setting';

interface ContextValue {
  currentPublicationId: string;
  settings?: Settings;
  handleChangeInAttribute: (attributeName: string, subAttributeName?: string) => (value: any) => void;
  isUpdatingPublication: boolean;
  savingAttribute: string | null;
  savingSubAttribute: string | null;
  setSavingAttribute: (value: string | null) => void;
}

const PublicationSettingsContext = createContext<ContextValue | undefined>(undefined);

const PublicationSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = useSettings(currentPublicationId);
  const [publicationIdInState, setPublicationIdInState] = useState(currentPublicationId);
  const [savingAttribute, setSavingAttribute] = useState<string | null>(null);
  const [savingSubAttribute, setSavingSubAttribute] = useState<string | null>(null);
  const { mutateAsync: updatePublication, isLoading: isUpdatingPublication } = useUpdatePublication(
    publicationIdInState,
    () => toast.success(`Saved!`)
  );
  const handleChangeInAttribute = useCallback(
    (attributeName: string, subAttributeName?: string) => async (value: any) => {
      try {
        setSavingAttribute(attributeName);
        if (subAttributeName) {
          setSavingSubAttribute(subAttributeName);
        }
        await updatePublication({
          [attributeName]: value,
        });
      } catch (error: any) {
        // do nothing
      }
      setSavingAttribute(null);
      setSavingSubAttribute(null);
    },
    [updatePublication]
  );

  useEffect(() => {
    if (currentPublicationId !== publicationIdInState) {
      setPublicationIdInState(currentPublicationId);
    }
  }, [currentPublicationId, publicationIdInState, setPublicationIdInState]);

  const contextProviderProps = useMemo(
    () => ({
      currentPublicationId: publicationIdInState,
      settings,
      handleChangeInAttribute,
      isUpdatingPublication,
      savingAttribute,
      savingSubAttribute,
      setSavingAttribute,
    }),
    [
      settings,
      publicationIdInState,
      handleChangeInAttribute,
      isUpdatingPublication,
      savingAttribute,
      savingSubAttribute,
      setSavingAttribute,
    ]
  );

  return (
    <PublicationSettingsContext.Provider value={contextProviderProps}>{children}</PublicationSettingsContext.Provider>
  );
};

function usePublicationSettings() {
  const context = React.useContext(PublicationSettingsContext);
  if (context === undefined) {
    throw new Error(`usePublicationSettings must be used within a PublicationSettingsProvider`);
  }
  return context;
}

export { PublicationSettingsContext, PublicationSettingsProvider, usePublicationSettings };
