/* eslint-disable */

import { ArrowPathIcon, ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { Typography } from '@/components/Typography';

import { Button } from '@/ui/Button';
import { MediaLibrary } from '../MediaLibrary';
import { OnMediaPayload } from '../MediaLibrary/MediaLibrary.types';

interface Props {
  className?: string;
  name: string;
  labelText?: string;
  helperText?: string;
  dimensionSuggestion?: string;
  required?: boolean;
  infoBubble?: boolean;
  isRounded?: boolean;
  onFileSelect?: (file: File) => void;
  onMediaSelect?: (payload: OnMediaPayload) => void;
  onFileClear: () => void;
  file?: File | string | null;
  type?: 'square' | 'landscape';
  orientation?: 'row' | 'col';
  fullWidth?: boolean;
  placeholderText?: string;
  publicationId?: string;
}

const ImageSelect: FC<Props> = (props: Props) => {
  const {
    className,
    name,
    labelText,
    helperText,
    dimensionSuggestion,
    required,
    infoBubble,
    orientation,
    isRounded,
    onFileSelect,
    onMediaSelect,
    onFileClear,
    file,
    type,
    fullWidth,
    placeholderText = 'browse or upload',
    publicationId,
  } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e && e.target.files && e.target.files.length > 0 && onFileSelect) {
      onFileSelect(e.target.files[0]);
    }
  };

  const onButtonClick = (e: any) => {
    e.preventDefault();

    if (publicationId) {
      setMediaLibraryOpen(true);
      return;
    }

    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onRemoveClick = (e: any) => {
    e.preventDefault();

    onFileClear();
  };

  const handleMediaSelection = (payload: OnMediaPayload) => {
    if (onMediaSelect) {
      onMediaSelect(payload);
    }
  };

  let fileUrl = null;
  if (file) {
    fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file);
  }

  if (fileUrl) {
    return (
      <div className={className}>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex flex-row gap-2">
            {labelText}
            {required ? ' *' : ''}
            {infoBubble && <InformationCircleIcon className="h-5 w-5 text-surface-300" />}
          </div>
        </label>
        <div className={cx('relative rounded-lg overflow-hidden border-gray-300 h-44', fullWidth ? 'w-full' : 'w-44')}>
          <div className="relative">
            <img src={fileUrl} alt="" className="w-full h-44 object-cover" />
          </div>
          <div className="flex space-x-2 w-full h-full justify-end align-baseline pt-2 absolute top-0 left-0 bg-gray-900/[.2] pr-3 opacity-0 hover:opacity-100 transition-opacity">
            <Button
              variant="primary"
              type="button"
              onClick={onButtonClick}
              className="mt-auto mb-3"
              Icon={ArrowPathIcon}
            />
            <Button variant="primary" type="button" onClick={onRemoveClick} className="mt-auto mb-3" Icon={TrashIcon} />
          </div>
        </div>
        <input name={name} ref={fileInput} type="file" onChange={handleFileInput} className="hidden" />
        <div className="pt-2">
          {dimensionSuggestion && <p className="text-xs text-gray-500">{dimensionSuggestion}</p>}
        </div>
        {publicationId && (
          <MediaLibrary
            onClose={() => setMediaLibraryOpen(false)}
            onMediaSelect={async (payload) => {
              await handleMediaSelection(payload);
              setMediaLibraryOpen(false);
            }}
            isOpen={mediaLibraryOpen}
            publicationId={publicationId}
          />
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        <div className="flex flex-row gap-2">
          {labelText}
          {required ? ' *' : ''}
          {infoBubble && <InformationCircleIcon className="h-5 w-5 text-surface-300" />}
        </div>
      </label>
      <div
        className={`flex items-center relative h-44 ${fullWidth ? 'w-full' : 'w-44'} justify-center rounded-lg ${
          orientation === 'col' ? 'flex-col space-y-4' : ''
        } border border-dashed rounded border-gray-300`}
      >
        <button
          className="absolute top-0 left-0 bg-black opacity-0 hover:opacity-5 z-10 h-full w-full transition-all"
          onClick={onButtonClick}
        />
        <div className="flex flex-col items-center">
          <span className={`inline-block overflow-hidden ${isRounded ? 'rounded-full' : 'rounded-none'}`}>
            {fileUrl ? (
              <img src={fileUrl} alt="" className="w-full h-44 object-cover" />
            ) : (
              <div className="h-full w-full flex flex-col items-center justify-center space-y-3">
                <div className="bg-surface-100 p-2 rounded-full">
                  <ArrowUpTrayIcon className="h-3 w-3 text-surface-900" />
                </div>
                <Typography size="sm" weight="normal" className="underline" colorWeight="500">
                  {placeholderText}
                </Typography>
              </div>
            )}
          </span>
          <input name={name} ref={fileInput} type="file" onChange={handleFileInput} className="hidden" />
        </div>
      </div>
      <div className="pt-2">
        {dimensionSuggestion && <p className="text-xs text-gray-500 text-left">{dimensionSuggestion}</p>}
      </div>

      {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
      {publicationId && (
        <MediaLibrary
          onClose={() => setMediaLibraryOpen(false)}
          onMediaSelect={async (payload) => {
            await handleMediaSelection(payload);
            setMediaLibraryOpen(false);
          }}
          isOpen={mediaLibraryOpen}
          publicationId={publicationId}
        />
      )}
    </div>
  );
};

ImageSelect.defaultProps = {
  type: 'square',
  orientation: 'row',
  isRounded: false,
  className: undefined,
  required: false,
  helperText: undefined,
  dimensionSuggestion: undefined,
};

export default ImageSelect;
