import { useCurrentPublication } from '@/hooks';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { useTutorial } from '@/hooks/useTutorials';
import { TutorialType } from '@/interfaces/tutorial';
import { Section } from '@/pages/Settings/Components';

import List from './Endpoints/List';
import GetStarted from './GetStarted';
import Teaser from './Teaser';

const Endpoints = () => {
  const { data: currentPublication, isLoading: isLoadingCurrentPublication } = useCurrentPublication();
  const svixConfigured = !isLoadingCurrentPublication && currentPublication?.svix_application_id;
  const { data: settings } = usePublicationSettings(currentPublication?.id);
  const tutorial = useTutorial(TutorialType.WEBHOOKS);

  if (!settings?.webhooks) {
    return <Teaser />;
  }

  return (
    <div>
      <Section size="4xl" title="Webhooks" description="Configure Webhooks" scrollToId="Webhooks" tutorial={tutorial}>
        {svixConfigured ? <List /> : <GetStarted svixConfigured={!!svixConfigured} />}
      </Section>
    </div>
  );
};

export default Endpoints;
