import { PageRoute } from "@/interfaces/dream_builder/page_route"

export const getAllRoutes = (routes: PageRoute, defaultRoutes: PageRoute) => {
  return {
    page_id: routes.page_id,
    page_version_id: routes.page_version_id,
    children: {
      ...routes?.children,
      ...defaultRoutes?.children,
    },
    children_keys: [
      ...(routes?.children_keys || []),
      ...(defaultRoutes?.children_keys || []),
    ],
  }
}

