import { Grid } from '@tremor/react';

import ClicksTable from '@/components/_domain/EmailMessage/ClicksTable';
import { useEmailMessageStats, useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  publication: Publication;
}

const formatter = new Intl.NumberFormat('en');

const Analytics = ({ publication }: Props) => {
  const { data: welcomeEmail } = useWelcomeEmail(publication.id);
  const { data: emailMessageStats } = useEmailMessageStats(publication.id, welcomeEmail?.id);

  const {
    total_sent: totalSent = 0,
    total_opened: totalOpens = 0,
    total_unique_opened: totalUniqueOpens = 0,
    total_delivered: totalDelivered = 0,
    total_clicked: totalClicks = 0,
    total_unique_clicked: totalUniqueClicks = 0,
    total_spam_reported: totalSpamReports = 0,
    total_unsubscribes: totalUnsubscribes = 0,
    open_rate: openRate = 0,
    click_rate: clickRate = 0,
    unsubscribe_rate: unsubscribeRate = 0,
  } = emailMessageStats || {};

  const deliveryRate = totalSent > 0 ? `${((totalDelivered / totalSent) * 100).toFixed(2)}%` : '0%';

  return (
    <>
      <Grid numCols={1} numColsSm={2} className="gap-4">
        <MetricCard
          variant="secondary"
          label="Recipients"
          value={formatter.format(totalSent)}
          complements={[
            { label: 'Delivered', value: formatter.format(totalDelivered) },
            { label: 'Delivery Rate', value: deliveryRate },
          ]}
        />
        <MetricCard
          variant="secondary"
          label="Open Rate"
          value={`${openRate}%`}
          complements={[
            { label: 'Unique Opens', value: formatter.format(totalUniqueOpens) },
            { label: 'Total Opens', value: formatter.format(totalOpens) },
          ]}
        />
        <MetricCard
          variant="secondary"
          label="Click Rate"
          value={`${clickRate}%`}
          complements={[
            { label: 'Unique Clicks', value: formatter.format(totalUniqueClicks) },
            { label: 'Total Clicks', value: formatter.format(totalClicks) },
          ]}
        />
        <MetricCard
          variant="secondary"
          label="Unsubscribe Rate"
          value={unsubscribeRate}
          complements={[
            { label: 'Unsubscribes', value: formatter.format(totalUnsubscribes) },
            { label: 'Spam Reports', value: formatter.format(totalSpamReports) },
          ]}
        />
      </Grid>
      <ClicksTable publicationId={publication.id} emailMessageId={welcomeEmail?.id} />
    </>
  );
};

export default Analytics;
