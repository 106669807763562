import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import ActionsDropdown, { ActionOption } from '@/components/ActionsDropdown/ActionsDropdown';
import Tooltip from '@/components/Tooltip';
import { useUpdateBoostSend } from '@/hooks/boosts/monetize/useBoostSends';
import useUpdateBoostAgreement from '@/hooks/boosts/monetize/useUpdateBoostAgreement';
import useBoostAgreementCanReactivate from '@/hooks/boosts/useBoostAgreementCanReactivate';
import {
  BoostAgreement,
  BoostAgreementStatus,
  BoostAgreementStatusTransitions,
  BoostAgreementTransitionVerboseLabels,
} from '@/interfaces/boosts/monetize/boost_agreement';
import {
  BoostSendStatus,
  BoostSendStatusTransitions,
  BoostSendTransitionLabels,
  BoostSendTransitionVerboseLabels,
} from '@/interfaces/boosts/monetize/boost_send';

interface Props {
  boostAgreement: BoostAgreement;
  onAgreementUpdateSuccess: () => void;
  onSendUpdateSuccess: () => void;
  onCopyAffiliateLink: () => void;
}
const AgreementAndSendActions = ({
  boostAgreement,
  onAgreementUpdateSuccess,
  onSendUpdateSuccess,
  onCopyAffiliateLink,
}: Props) => {
  const [requestedAction, setRequestedAction] = useState<BoostSendStatus | null>(null);

  const activeOrPendingBoostSend = boostAgreement.boost_sends.find((boostSend) =>
    [BoostSendStatus.ACCEPTED, BoostSendStatus.PENDING].includes(boostSend.status)
  );

  const canReactivateQuery = useBoostAgreementCanReactivate(boostAgreement?.id);
  const canReactivate = canReactivateQuery.data?.can_reactivate ?? false;

  const { mutateAsync: updateBoostAgreement } = useUpdateBoostAgreement({
    onSuccess: () => {
      onAgreementUpdateSuccess();
      toast.success('Boost agreement updated.');
    },
  });

  const { mutateAsync: updateBoostSend, isLoading: updateLoading } = useUpdateBoostSend({
    id: activeOrPendingBoostSend?.id || '',
    onSuccess: () => {
      onSendUpdateSuccess();
      toast.success('Send request updated.');
    },
  });

  if (!boostAgreement) return null;

  const availableAgreementTransitions = BoostAgreementStatusTransitions[boostAgreement?.status || ''] || [];
  const availableSendTransitions = activeOrPendingBoostSend?.status
    ? BoostSendStatusTransitions[activeOrPendingBoostSend.status]
    : [];

  const options: ActionOption[] = [
    ...availableSendTransitions.map((transition: BoostSendStatus) => ({
      name: BoostSendTransitionVerboseLabels[transition],
      isVisible: () => true,
      onClick: () => {
        setRequestedAction(transition);
      },
    })),
    ...availableAgreementTransitions.map((transition: BoostAgreementStatus) => ({
      name: BoostAgreementTransitionVerboseLabels[transition],
      isVisible: () => (
        (transition === BoostAgreementStatus.ACTIVE && boostAgreement?.status === BoostAgreementStatus.INACTIVE)
        ? canReactivate
        : true
      ),
      onClick: (boost: any) => updateBoostAgreement({ boostAgreementId: boost.id, transition_to: transition }),
    })),
    {
      name: 'Copy Direct Link',
      isVisible: () => true,
      onClick: onCopyAffiliateLink,
    },
  ];

  const handleProceed = () => {
    updateBoostSend({ status: requestedAction as BoostSendStatus });
    setRequestedAction(null);
  };

  if (options.length === 0) {
    return null;
  }

  const areActionsDisabled = boostAgreement.is_reported === true;

  return (
    <>
      <Tooltip
        tooltipClass="text-center"
        id={`disabled-boost-actions-tooltip-${boostAgreement.id}`}
        text="You have been reported by the Publication"
        showIcon={false}
        autoWidth={false}
        isEnabled={areActionsDisabled}
      >
        <ActionsDropdown disabled={areActionsDisabled} actions={[options]} node={boostAgreement} />
      </Tooltip>
      {activeOrPendingBoostSend && (
        <ActionModal
          isOpen={!!requestedAction}
          onClose={() => setRequestedAction(null)}
          onProceed={() => handleProceed()}
          resourceId={activeOrPendingBoostSend.id}
          headerText={BoostSendTransitionLabels[requestedAction || '']}
          isWorking={updateLoading}
          actionText="Confirm"
        >
          <p className="text-sm text-gray-500">
            Are you sure you would like to {BoostSendTransitionLabels[requestedAction || '']} this send?
          </p>
        </ActionModal>
      )}
    </>
  );
};

export default AgreementAndSendActions;
