const LS_KEY = 'wb-fonts';
const WEBFONTS_URL = 'https://typedream-dev.sfo3.cdn.digitaloceanspaces.com/webfonts.json';

type Font = {
  category: string;
  family: string;
  files: Record<string, string>;
  // many other fields we don't care about at this moment
};

type WebFont = {
  items: Array<Font>;
};

export const getFonts = async () => {
  // check if fonts available in localStorage for now.
  if (typeof localStorage === 'undefined') {
    throw Error('localStorage not found. Did you try to run this on the server?');
  }
  const storedFonts = localStorage.getItem(LS_KEY);
  if (storedFonts) {
    return JSON.parse(storedFonts) as WebFont;
  }

  // fetch from server, we might want pagination on this.
  const fonts = await fetch(WEBFONTS_URL).then((d) => d.json());

  return fonts as WebFont;
};
