import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import Input from '@/components/Form/Input';
import { Typography, TypographyStack } from '@/components/Typography';
import { useDeleteApiKey } from '@/hooks/useIntegrations';
import { ApiKey } from '@/interfaces/api_key';

import KeyForm from './KeyForm';

interface Props {
  organizationId: string;
  publicationId: string;
  apiKey: ApiKey;
}

const ApiKeyItem = ({ apiKey, organizationId, publicationId }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [confirmDeleteCharacters, setConfirmDeleteCharacters] = useState<string>('');

  const handleClickEdit = () => {
    setShowEditForm(true);
  };

  const handleClickDelete = () => {
    setShowDeleteModal(true);
  };

  const { mutate: deleteApiKey, isLoading: isDeleting } = useDeleteApiKey({ keyId: apiKey.id }, publicationId);

  return (
    <>
      <ActionModal
        resourceId={`delete-modal-${apiKey.id}`}
        headerText="Delete Your API Key?"
        descriptionText={
          <TypographyStack gap="3">
            <Typography token="font-normal/text/sm">This action cannot be undone.</Typography>
            <Typography token="font-normal/text/sm">
              Deleting this API key will immediately revoke access for any applications or integrations using it as well
              as stop its ability to access your beehiiv workspace.
            </Typography>

            <TypographyStack>
              <Typography token="font-medium/text/sm">Before proceeding, ensure that:</Typography>
              <ul className="list-disc list-inside">
                <li>
                  <Typography token="font-normal/text/sm">You have updated any systems relying on this key</Typography>
                </li>
                <li>
                  <Typography token="font-normal/text/sm">
                    You have a backup plan for restoring access if needed
                  </Typography>
                </li>
              </ul>
            </TypographyStack>
          </TypographyStack>
        }
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onProceed={() => deleteApiKey()}
        disabled={isDeleting || confirmDeleteCharacters !== apiKey.name}
        isWorking={isDeleting}
        actionText="Delete"
        cancelText="Cancel"
        modalMessageType="danger"
        buttonType="danger"
        buttonShade="dark"
      >
        <div className="py-6">
          <Input
            labelText="API Key Name"
            required
            placeholder="My API key"
            helperText="To confirm deletion, please enter the name of the API Key"
            name="name"
            value={confirmDeleteCharacters || ''}
            onChange={(e) => {
              setConfirmDeleteCharacters(e.currentTarget.value);
            }}
          />
        </div>
      </ActionModal>
      <KeyForm
        key={apiKey.id}
        organizationId={organizationId}
        publicationId={publicationId}
        apiKey={apiKey}
        isOpen={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <div className="flex flex-row justify-between p-3 border border-surface-200 bg-surface-50 rounded-md">
        <Typography className="flex flex-grow" token="font-medium/text/sm">
          {apiKey.name}
        </Typography>
        <EllipsisDropdown
          options={[
            { label: 'Edit', onClick: handleClickEdit },
            { label: 'Delete', onClick: handleClickDelete },
          ]}
        />
      </div>
    </>
  );
};

export default ApiKeyItem;
