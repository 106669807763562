import { TiptapCollabProvider } from '@hocuspocus/provider';
import { KeyboardShortcutCommand } from '@tiptap/core';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { DocumentColors } from '@tiptap-pro/extension-document-colors';

import { Shortcuts } from '@/components/TiptapEditor/extensions/Shortcuts';
import { colors } from '@/components/TiptapEditor/lib/colors';
import { Settings } from '@/interfaces/setting';
import { emojis } from '@/utils/emojis';
import SpamLinks from '@/utils/spam-links.json';

import {
  ActiveNode,
  AdvertisementOpportunity,
  AdvertisementOpportunityLogo,
  AiImage,
  AiWriter,
  Anchor,
  Audio,
  Authors,
  BlockquoteFigure,
  Boost,
  BulletList,
  Button,
  CharacterCount,
  CodeBlockLowlight,
  Collaboration,
  Color,
  Column,
  Columns,
  CommentsKit,
  Container,
  Divider,
  Document,
  Dropcursor,
  EmbedInput,
  Emoji,
  emojiSuggestion,
  Figcaption,
  FileAttachment,
  Focus,
  FontFamily,
  FontSize,
  FontWeight,
  FootnotesKit,
  GenericEmbed,
  GenericEmbedDescription,
  GenericEmbedHost,
  GenericEmbedTitle,
  Heading,
  Highlight,
  HorizontalRule,
  Hover,
  HtmlSnippet,
  Image,
  ImageBlock,
  ImageUpload,
  Link,
  ListItem,
  MergeTags,
  OrderedList,
  Paragraph,
  PasteFromGoogleDoc,
  PaywallBreak,
  Placeholder,
  Poll,
  Post,
  PricingCards,
  Quote,
  QuoteCaption,
  Recommendation,
  ReferralProgram,
  SearchAndReplace,
  Section,
  ServiceEmbed,
  Signup,
  SlashCommand,
  Socials,
  Spacer,
  StarterKit,
  SubscriberBreak,
  Subscript,
  Superscript,
  Survey,
  Table,
  TableCell,
  TableHeader,
  TableOfContents,
  TableRow,
  TextAlign,
  TextStyle,
  Typography,
  Underline,
} from '.';

interface ExtensionKitProps {
  usesCollaboration?: boolean;
  provider?: TiptapCollabProvider | null;
  userId?: string;
  userName?: string;
  userColor?: string;
  publicationId?: string;
  settings?: Settings;
  onToggleUpgradeIntentModal?: Function;
  allowPolls?: boolean;
  allowAds?: boolean;
  useCursors?: boolean;
  additionalShortcuts?: Record<string, KeyboardShortcutCommand>;
  openThreadsSidebar?: () => void;
}

// 50,000 = ~6250 words (avg length of 8 chars/word)
export const FALL_BACK_CHARACTER_LIMIT = 50_000;

export const ExtensionKit = ({
  additionalShortcuts = {},
  allowAds = false,
  allowPolls = false,
  onToggleUpgradeIntentModal,
  provider,
  publicationId,
  settings,
  useCursors = true,
  userColor,
  userId,
  userName,
  usesCollaboration,
  openThreadsSidebar,
}: ExtensionKitProps) => [
  Document,
  ...(usesCollaboration
    ? [
        Collaboration.configure({ document: provider?.document }),
        useCursors
          ? CollaborationCursor.configure({
              provider,
              user: { name: userName, color: userColor },
            })
          : null,
      ]
    : []),
  Columns,
  Container,
  ActiveNode,
  AiWriter.configure({
    authorId: userId,
    authorName: userName,
    enabled: settings && settings.ai_editor_requests > 0,
    usesCollaboration,
  }),
  AiImage.configure({
    authorId: userId,
    authorName: userName,
    enabled: settings && settings.ai_editor_requests > 0,
    usesCollaboration,
  }),
  TableOfContents,
  PricingCards,
  Anchor.configure({
    types: [
      Paragraph.name,
      BulletList.name,
      OrderedList.name,
      Button.name,
      ImageBlock.name,
      Columns.name,
      HtmlSnippet.name,
      GenericEmbed.name,
      ServiceEmbed.name,
      CodeBlockLowlight.name,
      BlockquoteFigure.name,
      PaywallBreak.name,
      ReferralProgram.name,
      Heading.name,
      Recommendation.name,
      Poll.name,
      Boost.name,
      AdvertisementOpportunityLogo.name,
      Table.name,
      Socials.name,
      Section.name,
      Signup.name,
      Authors.name,
      Survey.name,
      Post.name,
      BulletList.name,
      ListItem.name,
      OrderedList.name,
      Divider.name,
      Column.name,
      PricingCards.name,
      BlockquoteFigure.name,
      Container.name,
      // TODO: Add every new node here, if you want it to be anchor-able
    ],
  }),
  Column,
  Survey,
  DocumentColors.configure({
    colorTypes: [
      {
        name: 'textStyle',
        schemaType: 'mark',
        colorAttribute: 'color',
      },
      {
        name: 'highlight',
        schemaType: 'mark',
        colorAttribute: 'color',
      },
      {
        name: 'link',
        schemaType: 'mark',
        colorAttribute: 'color',
      },
      {
        name: 'section',
        schemaType: 'node',
        colorAttribute: 'backgroundColor',
      },
      {
        name: 'section',
        schemaType: 'node',
        colorAttribute: 'color',
      },
      {
        name: 'section',
        schemaType: 'node',
        colorAttribute: 'borderColor',
      },
      {
        name: 'button',
        schemaType: 'node',
        colorAttribute: 'customTextColor',
      },
      {
        name: 'button',
        schemaType: 'node',
        colorAttribute: 'customBackgroundColor',
      },
    ],
  }),
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HtmlSnippet.configure({
    enabled: !!settings?.custom_html_blocks,
  }),
  GenericEmbedTitle, // Keep this before StarterKit
  GenericEmbedDescription,
  GenericEmbedHost,
  GenericEmbed.configure({
    clientId: provider?.document?.clientID,
  }),
  ServiceEmbed,
  HorizontalRule,
  EmbedInput,
  Paragraph,
  BulletList,
  OrderedList,
  Spacer,
  Signup,
  Socials,
  ListItem,
  Hover,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    codeBlock: false,
    paragraph: false,
    listItem: false,
    orderedList: false,
    bulletList: false,
    ...(usesCollaboration ? { history: false } : {}),
  }),
  CodeBlockLowlight,
  Button,
  PaywallBreak.configure({
    enabled: !!settings?.premium_subscriptions,
  }),
  ...(allowPolls
    ? [
        Poll.configure({
          publicationId,
          enabled: !!settings?.polls,
        }),
      ]
    : []),
  Recommendation.configure({ publicationId }),
  ...(allowAds ? [AdvertisementOpportunity.configure({ publicationId })] : []),
  ...(allowAds ? [AdvertisementOpportunityLogo.configure({ publicationId })] : []),
  Boost.configure({ publicationId }),
  ReferralProgram.configure({
    publicationId,
    enabled: !!settings?.referral_program,
  }),
  TextStyle,
  FontSize,
  FontWeight,
  FontFamily,
  MergeTags,
  Color,
  Link.configure({
    openOnClick: false,
    validate: (link) => {
      const includesSpam = SpamLinks.url_includes.find((url) => link.includes(url as string));
      const exactSpam = SpamLinks.blocked_urls.find((url) => url === link);

      return !(includesSpam || exactSpam);
    },
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  CharacterCount.configure({
    limit: settings?.max_tip_tap_character_limit || FALL_BACK_CHARACTER_LIMIT,
  }),
  Image,
  ImageBlock.configure({
    publicationId,
    clientId: provider?.document?.clientID,
  }),
  ImageUpload.configure({
    publicationId,
    clientId: provider?.document?.clientID,
  }),
  Emoji.configure({
    emojis,
    enableEmoticons: true,
    suggestion: emojiSuggestion,
  }),
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  SubscriberBreak,
  Subscript,
  Superscript,
  Section,
  Table.configure({
    resizable: false,
    lastColumnResizable: false,
    HTMLAttributes: {
      class: 'table-fixed',
    },
  }),
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: true,
    placeholder: () => '',
    ignoredNodes: ['footnotesNode', 'footnoteItem'],
  }),
  SlashCommand.configure({
    settings,
    onToggleUpgradeIntentModal,
    allowPolls,
    allowAds,
  }),
  Authors,
  Post,
  Focus,
  PasteFromGoogleDoc,
  Figcaption,
  BlockquoteFigure,
  Quote,
  QuoteCaption,
  Dropcursor.configure({
    color: colors.pink[5],
    width: 2,
    class: 'ProseMirror-dropcursor',
  }),
  Shortcuts.configure({ additionalShortcuts }),
  SearchAndReplace,
  FootnotesKit,
  CommentsKit.configure({
    provider,
    openThreadsSidebar,
  }),
  FileAttachment.configure({
    publicationId,
    userId,
  }),
  Audio.configure({
    userId,
    publicationId,
  }),
  Divider,
];

export default ExtensionKit;
