import { PropsWithChildren, ReactNode } from 'react';

import HelperText from '@/components/Form/HelperText';
import { Typography, TypographyStack } from '@/components/Typography';

type Props = PropsWithChildren<{
  label: string;
  helperText?: string;
  tip?: string;
  cta?: ReactNode;
}>;

const FormRow = ({ label, helperText, tip, children, cta }: Props) => (
  <div className="flex flex-row gap-x-2 justify-between">
    <div className="flex flex-col gap-y-3">
      <TypographyStack>
        <Typography token="font-medium/text/sm">{label}</Typography>
        {helperText ? <HelperText className="sm:w-96">{helperText}</HelperText> : null}
        {tip ? <Typography token="font-light/text/xs">{tip}</Typography> : null}
      </TypographyStack>
      {cta ? <span>{cta}</span> : null}
    </div>
    {children}
  </div>
);

export default FormRow;
