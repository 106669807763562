import React from 'react';
import { Editor } from '@tiptap/react';

export const HoverBox = ({ editor }: { editor: Editor }) => {
  const node = editor.storage.hover.node as Element | null;

  const createStyle = (element: Element | null, color: string): React.CSSProperties => {
    if (!element) return {};

    if (element.tagName === 'SECTION') return {};

    const rect = element.getBoundingClientRect();
    const parentRect = editor.view.dom.getBoundingClientRect();

    return {
      position: 'absolute',
      opacity: 1,
      left: rect.left - parentRect.left,
      top: rect.top - parentRect.top,
      width: rect.width,
      height: rect.height,
      border: `1px solid ${color}`,
      boxSizing: 'border-box',
      pointerEvents: 'none',
      transition: 'opacity 0.03s ease-out',
    };
  };

  // TODO: use wb-accent once tailwind config is available
  // inside iframe
  const nodeStyle = createStyle(node, '#7C3AED');

  return <div style={nodeStyle} />;
};
