import React from 'react';
import { DividerElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

export const DividerView: React.FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <DividerElement
      element={{
        type: 'divider',
        content: [{ id: 'empty-children', text: '' }],
        attrs: {
          id: node.attrs.id,
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {null}
    </DividerElement>
  );
};
