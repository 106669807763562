export const survey = {
  id: '4297e314-9e43-4164-b080-2f469ac2b458',
  title: 'Test Survey Data',
  description: 'Some test description',
  cta_text: 'Submit',
  thank_you_message: 'Thank you for your submission.',
  status: 'live',
  url: 'http://7umhl09dfk57.localhiiv.com:3002/forms/4297e314-9e43-4164-b080-2f469ac2b458',
  form_response_count: 0,
  form_questions: [
    {
      id: '10085aaf-18d6-446e-bc06-c3c7b2b87a08',
      order: 1,
      prompt: 'First Name',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'free_form',
      custom_field: {
        id: '519ffa4f-c049-4432-9108-e5ccb752c51b',
        kind: 'string',
      },
      form_question_options: [],
    },
    {
      id: 'adfeb17b-8ff0-482d-9bdc-f50ea6576ffd',
      order: 2,
      prompt: 'Are you tall',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'free_form',
      custom_field: {
        id: '3cd58958-57ac-4677-9f87-ce4e3a58cef1',
        kind: 'boolean',
      },
      form_question_options: [],
    },
    {
      id: 'c34234d7-2c6c-4cf5-9072-143f8208a053',
      order: 3,
      prompt: 'Random new questiion',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'free_form',
      custom_field: {
        id: '6eeb931a-3131-42b2-ab27-1db00a4afb54',
        kind: 'datetime',
      },
      form_question_options: [],
    },
    {
      id: 'a72cda71-452d-429b-8f23-942092a5755c',
      order: 4,
      prompt: 'Whats the color',
      required: false,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'multiple_choice',
      custom_field: {
        id: '593b66ba-401f-43a4-97a1-9d7ae50ed477',
        kind: 'string',
      },
      form_question_options: [
        {
          id: 'af4b7320-10eb-4cf4-9a6b-677d58691fde',
          value: 'Blue ',
          order: 1,
        },
        {
          id: '0fa33bc8-f872-4fd8-8cea-4ff1b2547ab2',
          value: 'Orange',
          order: 2,
        },
        {
          id: 'e6a433d6-c520-4689-aba0-86e6d39f064c',
          value: 'Gold',
          order: 3,
        },
      ],
    },
    {
      id: '189e96f5-e7b3-4074-ba6d-3217983385b8',
      order: 5,
      prompt: 'Whats the best solution',
      required: false,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'dropdown',
      custom_field: {
        id: '2ab18ae3-fe66-423a-b6c6-d643510b9acf',
        kind: 'string',
      },
      form_question_options: [
        {
          id: 'e674bc33-5366-4a7d-a4b8-409aead8aa47',
          value: 'solution 1',
          order: 1,
        },
        {
          id: 'ad6ec848-4878-494b-b493-8472756593e0',
          value: 'solution 2',
          order: 2,
        },
        {
          id: '72134f5c-6dca-4a26-8734-17094953a9ef',
          value: 'solution 2',
          order: 3,
        },
      ],
    },
    {
      id: '302bdf8e-5352-478e-be0e-6694af7e9d04',
      order: 6,
      prompt: 'Some long response',
      required: false,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 400,
      min_character_limit: null,
      question_type: 'long_text',
      custom_field: {
        id: 'e63d67ca-740b-4d05-bac5-5aeb342d1845',
        kind: 'string',
      },
      form_question_options: [],
    },
  ],
  created_at: '2024-09-13T15:56:48.774Z',
  most_recent_response: null,
};
