import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Page } from '@/interfaces/dream_builder/page';

import api from '../../services/swarm';

interface Props {
  pageId: string;
}

export default function usePage({ pageId }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPage = () =>
    api
      .get(`/pages/${pageId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<Page>({
    queryKey: ['publications', currentPublicationId, 'pages', pageId],
    queryFn: fetchPage,
  });
}
