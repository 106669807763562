import { useState } from 'react';

import OneLineForm from '@/components/Form/OneLineForm';
import usePartnerDetails from '@/hooks/usePartner';
import useUpdatePartnerPaypalEmail from '@/hooks/useUpdatePartnerPaypalEmail';
import { Button } from '@/ui/Button';

const PartnerProgramStartHere = ({loadedPaypalEmail}: {loadedPaypalEmail: string}) => {
  const [showPaypalEdit, setShowPaypalEdit] = useState<boolean>(false);
  const [paypalEmail, setPaypalEmail] = useState<string>(loadedPaypalEmail);
  const paypalBlockImage = 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner-program-image.png';

  const { mutateAsync } = useUpdatePartnerPaypalEmail();

  const handleSubmit = (value: string) => {
    mutateAsync({ paypalEmail: value })
      .then(() => {
        setPaypalEmail(value);
        setShowPaypalEdit(false);    
      })
      .catch(() => {});
  };

  const paypalAddressUpdateForm = (
    <OneLineForm
      onSubmit={handleSubmit}
      value={paypalEmail}
    />
  );

  const paypalAddressEdit = {
    id: 3,
    title: 'Submit your PayPal email',
    description: 'Add your PayPal payout email below if it is different from your beehiiv account email',
    imageUrl: paypalBlockImage,
    buttonCTA: '',
    redirectUrl: '',
    passedFunction: () => {},
    extraHtml: paypalAddressUpdateForm,
  };

  const paypalAddressShow = {
    id: 3,
    title: 'Receive your commissions',
    description: `Payments will be sent to this email address: ${paypalEmail}`,
    imageUrl: paypalBlockImage,
    buttonCTA: 'Update PayPal Email',
    redirectUrl: '',
    passedFunction: () => setShowPaypalEdit(true),
    extraHtml: '',
  };

  const resources = [
    {
      id: 1,
      title: 'Overview',
      description: 'Share your partner link with your contacts and followers',
      redirectUrl: 'https://www.youtube.com/watch?v=TZCeDnea51E',
      buttonCTA: 'Watch Video',
      imageUrl: 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partnerprogram.png',
      passedFunction: () => {},
      extraHtml: '',
    },
    {
      id: 2,
      title: 'Share',
      description: '15 ways to promote beehiiv as a partner',
      buttonCTA: 'Read Article',
      redirectUrl: 'https://blog.beehiiv.com/p/beehiiv-partner-program-promotion',
      imageUrl: 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner-program-image-2.png',
      passedFunction: () => {},
      extraHtml: '',
    },
    (showPaypalEdit || !paypalEmail) ? paypalAddressEdit : paypalAddressShow,
  ];

  return (
    <div className="p-6 w-full flex flex-col space-y-6 bg-white border rounded-lg">
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 w-full">
        {resources.map((resource) => {
          return (
            <div className="p-4 bg-gray-50 border w-full lg:w-1/3 rounded-lg flex flex-col space-y-3.5">
              <img
                alt={`${resource.title}`}
                className="object-cover aspect-video rounded flex-shrink-0"
                src={resource.imageUrl}
              />
              <div className="flex flex-row space-x-2">
                <div className="p-1.5 bg-violet-500 rounded-full w-6 h-6 text-sm flex flex-col justify-center items-center text-white font-medium">
                  {resource.id}
                </div>
                <p className="my-auto text-gray-900 font-medium">{resource.title}</p>
              </div>
              <p className="font-regular text-gray-600 text-sm flex-grow">{resource.description}</p>
              {resource.buttonCTA && (
                <div className="flex justify-end mt-auto">
                  <Button
                    onClick={resource.redirectUrl ? () => window.open(resource.redirectUrl, '_blank') : resource.passedFunction}
                    type="button"
                    size="xs"
                    className="w-fit"
                    variant="secondary"
                  >
                    {resource.buttonCTA}
                  </Button>
                </div>
              )}
              {resource.extraHtml}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Loader = () => {
  const { data, isLoading } = usePartnerDetails();

  if (isLoading) return null;

  return (
    <PartnerProgramStartHere loadedPaypalEmail={data.details.paypal_email} />
  )
}

export default Loader;
