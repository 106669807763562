import { FC, useEffect, useState } from 'react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import PostsSelectionModal from '../modal/PostsSelectionModal';

export const PostView: FC<NodeViewProps> = ({ node, editor }) => {
  const { onDragStart } = useReactNodeView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleCloseModal = () => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
    };

    editor?.chain().focus().updateAttributes('post', updatedAttributes).run();
    setIsModalOpen(false);
  };

  return (
    <PostElement
      element={{
        type: 'post',
        attrs: {
          ...(node.attrs as TPostAttributes),
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {null}
      {insertedFromSidebar && (
        <PostsSelectionModal
          node={node}
          editor={editor}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </PostElement>
  );
};
