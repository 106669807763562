import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavbarSerializableNode } from '@shared/dream-components'

import { navbarContent } from './navbar_test_content';
import { getNodeByID, getParent, getParentOrientation } from "./utils";

interface Size {
  width: number;
  height: number;
  orientation: 'horizontal' | 'vertical'
}

export type NavbarContextType = {
  content: NavbarSerializableNode | undefined;
  setContent: (content: NavbarSerializableNode) => void;
  selectedContent: NavbarSerializableNode | null | undefined;
  selectedNodeEl: HTMLElement | undefined;
  hoveredContent: NavbarSerializableNode | null | undefined;
  hoverNodeEl: HTMLElement | undefined;
  onSelectNode: (el: HTMLElement, nodeID: string) => void;
  onHoverNode: (el: HTMLElement, nodeID: string) => void;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  onDragStart: () => void;
  onDrag: (event: React.DragEvent) => void;
  isDragging: boolean;
  dragPreviewSize: Size | undefined;
  setDragPreviewSize: (size: Size | undefined) => void;
  onUpdateNodeAttributes: (nodeID: string, attrs: Record<string, any>) => void;
};

const updateNodeContent = (content: NavbarSerializableNode, parentNode: NavbarSerializableNode, newParentContent: NavbarSerializableNode[]): NavbarSerializableNode => {

  // If the node is the root content, update its content
  if (content === parentNode) {
    return {
      ...content,
      content: newParentContent
    } as NavbarSerializableNode;
  }

  // Helper function to recursively update the content
  const updateContent = (currentNode: NavbarSerializableNode): NavbarSerializableNode => {
    if (currentNode === parentNode) {
      return {
        ...currentNode,
        content: newParentContent
      } as NavbarSerializableNode;
    }

    if ('content' in currentNode && Array.isArray(currentNode.content)) {
      return {
        ...currentNode,
        content: currentNode.content.map(child => updateContent(child))
      } as NavbarSerializableNode;
    }

    return currentNode;
  };

  // Start the recursive update from the root content
  return updateContent(content);
}

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove(array: any[], from: number, to: number): any[] {
  const newArray = array.slice();
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0]
  );

  return newArray;
}

const updateNodeAttributes = (content: NavbarSerializableNode, nodeID: string, attrs: Record<string, string>): NavbarSerializableNode => {
  if (content.attrs?.id === nodeID) {
    return {
      ...content,
      attrs: {
        ...content.attrs,
        ...attrs
      }
    } as NavbarSerializableNode;
  }

  if ('content' in content && Array.isArray(content.content)) {
    return {
      ...content,
      content: content.content.map(child => updateNodeAttributes(child, nodeID, attrs))
    } as NavbarSerializableNode;
  }

  return content;
}


export const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

export const NavbarProvider = ({ children, iframeRef, editorContainerRef }: { children: React.ReactNode, iframeRef: React.RefObject<HTMLIFrameElement>, editorContainerRef: React.RefObject<HTMLDivElement> }) => {
  const [content, setContent] = useState<NavbarSerializableNode | undefined>(undefined);
  const [selectedContentID, setSelectedContentID] = useState<string | undefined>(undefined);
  const [hoveredContentID, setHoveredContentID] = useState<string | undefined>(undefined);
  const [hoverNodeEl, setHoverNodeEl] = useState<HTMLElement | undefined>(undefined);
  const [selectedNodeEl, setSelectedNodeEl] = useState<HTMLElement | undefined>(undefined);

  const [isDragging, setIsDragging] = useState(false);
  const [dragPreviewSize, setDragPreviewSize] = useState<Size | undefined>(undefined);

  const originalSelectedNodeStyles = useRef<CSSStyleDeclaration | undefined>(undefined);

  const [dragOverData, setDragOverData] = useState<{ parentID: string, index: number } | undefined>(undefined);

  useEffect(() => {
    setContent(navbarContent as NavbarSerializableNode);
  }, []);

  const onSelectNode = (el: HTMLElement, nodeID: string) => {
    setSelectedNodeEl(el);
    setSelectedContentID(nodeID);
  };

  const onHoverNode = useCallback((el: HTMLElement, nodeID: string) => {
    setHoverNodeEl(el);
    setHoveredContentID(nodeID);
  }, []);

  const selectedContent = content && selectedContentID ? getNodeByID(content, selectedContentID) : undefined;

  const hoveredContent = useMemo(() => {
    if (!content || !hoveredContentID) return undefined;
    return getNodeByID(content, hoveredContentID);
  }, [content, hoveredContentID]);


  const onReset = useCallback((resetSelectedNode: boolean) => {
    setIsDragging(false);
    setDragPreviewSize(undefined);

    // 1. Remove any 'data-hover-parent' attribute from elements
    const elementsWithHoverParent = iframeRef.current?.contentDocument?.querySelectorAll('[data-hover-parent]');
    elementsWithHoverParent?.forEach((element) => {
      element.removeAttribute('data-hover-parent');
      element.classList.remove('outline-dashed', 'outline-1', 'outline-violet-400')
    });

    if (resetSelectedNode) {
      setSelectedNodeEl(undefined);
      setSelectedContentID(undefined);
      setHoverNodeEl(undefined);
      setHoveredContentID(undefined);
    }

  }, [iframeRef])


  const onDragStart = useCallback(() => {
    if (selectedNodeEl) {
      setIsDragging(true);
      // Create a placeholder node
      const placeholderNode = document.createElement('div');
      placeholderNode.id = 'drag-placeholder-node';
      // Set placeholder dimensions to match the selected node using its bounding rectangle
      const selectedRect = selectedNodeEl.getBoundingClientRect();
      placeholderNode.style.width = `${selectedRect.width}px`;
      placeholderNode.style.height = `${selectedRect.height}px`;
      placeholderNode.style.pointerEvents = 'none';
      placeholderNode.style.backgroundColor = '#ede9fe';

      const placeholderContainer = document.createElement('div');
      placeholderContainer.id = 'drag-placeholder';
      placeholderContainer.appendChild(placeholderNode);



      let selectedNodeParent = selectedNodeEl.parentNode as HTMLElement;
      let completeSelectedNode = selectedNodeEl;
      originalSelectedNodeStyles.current = { ...completeSelectedNode.style };
      if (selectedNodeParent && !Array.from(selectedNodeParent.classList).some(className => className.startsWith('dream-'))) {
        completeSelectedNode = selectedNodeParent;
        selectedNodeParent = selectedNodeParent.parentNode as HTMLElement;
      }
      if (completeSelectedNode && selectedNodeParent) {
        // Insert the placeholder where the original node was
        selectedNodeParent.insertBefore(placeholderContainer, completeSelectedNode);
      }

      // Hide the original selected node
      completeSelectedNode.style.display = 'none';
      placeholderContainer.appendChild(completeSelectedNode);
    }
  }, [selectedNodeEl]);


  const onDrop = useCallback(() => {
    onReset(false)
    //  Pull the selected node out of the placeholder container
    const placeholderContainer = iframeRef?.current?.contentDocument?.getElementById('drag-placeholder');
    if (placeholderContainer) {
      // Get the child of placeholderContainer that is not the placeholder node
      const completeSelectedNode = Array.from(placeholderContainer.children).find(child => child.id !== 'drag-placeholder-node') as HTMLElement;
      if (completeSelectedNode) {
        // Pull the selected node out of the placeholder container
        placeholderContainer.parentNode?.insertBefore(completeSelectedNode, placeholderContainer);
        // Reset the display style to its original value
        if (originalSelectedNodeStyles.current?.display) {
          completeSelectedNode.style.display = originalSelectedNodeStyles.current.display;
        } else {
          completeSelectedNode.style.removeProperty('display');
        }
        // Remove the placeholder container
        placeholderContainer.remove();
      }
      placeholderContainer.remove();
    }



    if (selectedNodeEl && content && selectedContent) {
      setContent(prev => {
        if (!prev) return prev;

        const originalParent = getParent(prev, selectedContent);
        const hoverParent = dragOverData ? getNodeByID(prev, dragOverData.parentID) : null;

        if (!originalParent || !('content' in originalParent) || !originalParent.content) return prev;
        const originalIndex = originalParent?.content?.findIndex(child => child.attrs?.id === selectedContent.attrs?.id) ?? -1;

        if (!hoverParent || !dragOverData) {
          // replace content back to where it was
          return prev;
        }

        if (originalParent.attrs?.id === hoverParent.attrs?.id) {

          const newContent = arrayMove(originalParent.content, originalIndex, dragOverData.index)

          return updateNodeContent(prev, originalParent, newContent);
        }

        if (!hoverParent || !('content' in hoverParent) || !hoverParent.content) return prev;
        const newHoverParentContent = [
          ...hoverParent.content.slice(0, dragOverData.index),
          selectedContent,
          ...hoverParent.content.slice(dragOverData.index)
        ]

        const originalParentContent = (originalParent.content as NavbarSerializableNode[]).filter(item => item.attrs?.id !== selectedContent.attrs?.id)

        const updateContentWithHover = updateNodeContent(prev, hoverParent, newHoverParentContent)
        const updateContentWithOriginal = updateNodeContent(updateContentWithHover, originalParent, originalParentContent)
        return updateContentWithOriginal;
      });
    }
  }, [content, dragOverData, iframeRef, onReset, selectedContent, selectedNodeEl])

  const onDragMove = useCallback((event: React.DragEvent) => {
    if (event.clientX < 0 || event.clientY < 0) return;
    if (!content) return;
    if (!selectedContent) return;
    if (!selectedNodeEl) return;

    const placeholderContainer = iframeRef?.current?.contentDocument?.getElementById('drag-placeholder');
    if (!placeholderContainer) return;

    let currentNode = placeholderContainer;
    let parentNode = placeholderContainer?.parentNode as HTMLElement;


    if (parentNode && !Array.from(parentNode.classList).some(className => className.startsWith('dream-'))) {
      // Some components are wrapped with <div> from radix and so the real parent are the one above it.
      currentNode = parentNode;
      parentNode = parentNode.parentNode as HTMLElement;
    }

    if (!parentNode) return;

    const parentClass = Array.from(parentNode.classList).find(className => className.startsWith('dream-'));
    const eligibleDropParents = iframeRef?.current?.contentDocument?.querySelectorAll(`[class*="${parentClass}"]`);

    if (!eligibleDropParents) return;
    // Get the current mouse position and drag preview container dimensions
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // Calculate the effective area for hovering
    let hoverLeft = mouseX;
    let hoverRight = mouseX;
    let hoverTop = mouseY;
    let hoverBottom = mouseY;

    if (dragPreviewSize) {
      const { width, height, orientation } = dragPreviewSize;
      if (orientation === 'horizontal') {
        hoverLeft = mouseX - width / 2;
        hoverRight = mouseX + width / 2;
        hoverTop = mouseY - height - 10;
        hoverBottom = mouseY;
      } else {
        hoverLeft = mouseX - 10;
        hoverRight = mouseX + width;
        hoverTop = mouseY - height / 2;
        hoverBottom = mouseY + height / 2;
      }
    }

    // Find the eligible drop parent that the cursor or drag preview is over
    const hoveredParent = Array.from(eligibleDropParents).find((element) => {
      const rect = (element as HTMLElement).getBoundingClientRect();
      return (
        hoverLeft < rect.right &&
        hoverRight > rect.left &&
        hoverTop < rect.bottom &&
        hoverBottom > rect.top
      );
    });

    // Update hoveredParent style to light purple
    // Reset all parents' background color to default
    eligibleDropParents.forEach((parent) => {
      (parent as HTMLElement).removeAttribute('data-hover-parent');
      (parent as HTMLElement).classList.remove('outline-dashed', 'outline-1', 'outline-violet-400')
    });

    // Update the hovered parent's background color
    if (hoveredParent) {
      (hoveredParent as HTMLElement).setAttribute('data-hover-parent', 'true');
      (hoveredParent as HTMLElement).classList.add('outline-dashed', 'outline-1', 'outline-violet-400');
    }


    // If we found a new parent to drop into, move the placeholder
    if (hoveredParent && hoveredParent !== parentNode) {
      hoveredParent.appendChild(currentNode);
      parentNode = hoveredParent as HTMLElement;
    }
    const siblings = parentNode.children;

    if (!siblings) return;
    const siblingBefore = currentNode.previousElementSibling;
    const siblingAfter = currentNode.nextElementSibling;
    const parentContent = getParent(content, selectedContent)
    const parentOrientation = parentContent ? getParentOrientation(parentContent) : "vertical"

    if (parentOrientation === 'horizontal') {

      if (siblingBefore && event.clientX < siblingBefore.getBoundingClientRect().right) {
        siblingBefore.insertAdjacentElement('beforebegin', currentNode);
      } else if (siblingAfter && event.clientX > siblingAfter.getBoundingClientRect().left) {
        siblingAfter.insertAdjacentElement('afterend', currentNode);
      }

    } else {
      // eslint-disable-next-line no-lonely-if
      if (siblingBefore && event.clientY < siblingBefore.getBoundingClientRect().bottom) {
        siblingBefore.insertAdjacentElement('beforebegin', placeholderContainer);
      } else if (siblingAfter && event.clientY > siblingAfter.getBoundingClientRect().top) {
        siblingAfter.insertAdjacentElement('afterend', placeholderContainer);
      }
    }

    setDragOverData({
      parentID: parentNode.getAttribute('data-element-id') || '',
      index: Array.from(parentNode.children).indexOf(placeholderContainer)
    })

    // if (selectedNodeEl) {
    //   selectedNodeEl.style.display = 'none';
    // }
  }, [content, selectedContent, selectedNodeEl, iframeRef, dragPreviewSize]);



  const onDrag = useCallback((event: React.DragEvent) => {
    if (isDragging) {
      onDragMove(event);
    }
  }, [onDragMove, isDragging]);


  useEffect(() => {
    const iframe = iframeRef.current;

    const handleDragOver = (e: DragEvent): void => {
      e.preventDefault();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move'; // or 'copy', 'link', 'none'
      }
      iframe?.contentDocument?.body?.classList?.add("cursor-grabbing")
    };

    const handleDrop = (e: DragEvent): void => {
      e.preventDefault();
      onDrop();
    };

    const handleClickOutside = (): void => {
      onReset(true)
    }

    const addEventListeners = (): void => {
      if (iframe?.contentDocument) {
        iframe.contentDocument.addEventListener('dragover', handleDragOver);
        iframe.contentDocument.addEventListener('drop', handleDrop);
        iframe.contentDocument.addEventListener('click', handleClickOutside)
      }
    };

    // Add listeners to the main document
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    // If the iframe is already loaded, add event listeners immediately
    if (iframe?.contentDocument?.readyState === 'complete') {
      addEventListeners();
    } else {
      // Otherwise, wait for the iframe to load
      iframe?.addEventListener('load', addEventListeners);
    }

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
      if (iframe?.contentDocument) {
        iframe.contentDocument.removeEventListener('dragover', handleDragOver);
        iframe.contentDocument.removeEventListener('drop', handleDrop);
        iframe.contentDocument.removeEventListener('click', handleClickOutside)
      }
      iframe?.removeEventListener('load', addEventListeners);
    };
  }, [iframeRef, onDrop, onReset]);

  const onUpdateNodeAttributes = useCallback((nodeID: string, attrs: Record<string, string>) => {
    if (!content) return;
    setContent(prev => {
      if (!prev) return prev;
      return updateNodeAttributes(prev, nodeID, attrs)
    })
  }, [content])


  const contextValue = useMemo(() => ({
    content,
    setContent,
    selectedContent,
    selectedNodeEl,
    onSelectNode,
    hoverNodeEl,
    hoveredContent,
    onHoverNode,
    iframeRef,
    editorContainerRef,
    onDragStart,
    onDrag,
    isDragging,
    dragPreviewSize,
    setDragPreviewSize,
    onUpdateNodeAttributes
  }), [content, selectedContent, selectedNodeEl, iframeRef, editorContainerRef, onDragStart, onDrag, isDragging, dragPreviewSize, hoveredContent, hoverNodeEl, onHoverNode, onUpdateNodeAttributes]);

  return (
    <NavbarContext.Provider value={contextValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => {
  const context = React.useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavbarContext must be used within a NavbarProvider');
  }
  return context;
};
