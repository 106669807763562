import { useCallback, useState } from 'react';
import { ArrowsIn, CornersOut } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

export const SizingSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodePos } = useActiveNode(editor);

  const [toggleValue, setToggleValue] = useState('max');

  const handleToggleChange = useCallback(
    (value: string) => {
      setToggleValue(value);

      editor.commands.command(({ tr }) => {
        // this one seems more promising because it does not
        // override the other attributes.
        tr.setNodeAttribute(activeNodePos, 'width_max', value === 'max' ? '100%' : null);
        if (value === 'reset') {
          tr.setNodeAttribute(
            activeNodePos,
            'width',
            '700px' // 700 px default
          );
        }
        return true;
      });
    },
    [activeNodePos, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Sizing
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="max"
          value={toggleValue}
          onValueChange={handleToggleChange}
        >
          <ToggleGroupItem value="max" className="grow">
            <CornersOut weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <div className="w-px h-6 border border-wb-border" />
          <ToggleGroupItem value="reset" className="grow">
            <ArrowsIn weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
