import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
// import { DimensionSettings } from '../DimensionSettings';
import OrientationSettings from '../OrientationSettings';
import { PaddingSettings } from '../PaddingSettings';

export const AuthorsWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['authors']}>
      <SectionRenderer title="Container">
        {/* Layout of the authors */}
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings title="Color" property="backgroundColor" editor={editor} activeNodeResult={activeNodeResult} />
        <OrientationSettings editor={editor} activeNodePos={activeNodeResult.activeNodePos} />
      </SectionRenderer>

      <SectionRenderer title="Card">
        {/* TODO: dimension settings makes things attribute panel crash: max depth exceeded */}
        {/* <DimensionSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{ height: 'cardHeight', width: 'cardWidth' }}
        /> */}
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            width: 'cardBorderWidth',
            style: 'cardBorderStyle',
            radius: 'cardBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ColorSettings
          title="Color"
          property="cardBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
