import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { usePremiumSettings } from '@/hooks/usePublications';
import { Button } from '@/ui/Button';

import { SimpleSelect } from '../../../components/Form';
import { usePost, useUpdatePost } from '../../../hooks';
import { CommentSectionState } from '../../../interfaces/comment';
import ActionNeededSection from '../CommentReports/CommentReportSections/ActionNeededSection';

import useRedirectUserToOrchid from './hooks/useLogUserIntoOrchid';

const COMMENT_SECTION_OPTIONS = [
  {
    value: CommentSectionState.DEFAULT,
    label: 'All subscribers', // For right now, if the global settings is disabled then this won't show in the UI anyways, so it's okay to hardcode as Enabled here.
  },
  {
    value: CommentSectionState.PREMIUM,
    label: 'Paid subscribers only',
  },
  {
    value: CommentSectionState.DISABLED,
    label: 'Disabled: Hide comments section',
  },
  {
    value: CommentSectionState.LOCKED,
    label: 'Locked: Show comments section, but disable new comments',
  },
];

interface Props {
  postId: string;
}

const Comments = ({ postId }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { data: premiumSettings } = usePremiumSettings();
  const isPremiumEnabled = premiumSettings?.premium_enabled;
  const permissionOptions = COMMENT_SECTION_OPTIONS.filter((option) => {
    if (option.value === CommentSectionState.PREMIUM) {
      return isPremiumEnabled;
    }

    return true;
  });

  const postQuery = usePost({ id: postId });
  const { data: post } = postQuery;
  const [selection, setSelection] = useState(post?.comments_state);

  // When a user is coming from a comment notification email we want to log them in as an admin and redirect them to the post.
  const { handleManuallyLogInUserToOrchid } = useRedirectUserToOrchid({
    postUrl: post?.url || '',
  });

  const updatePost = useUpdatePost({
    postId: postId || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  useEffect(() => {
    if (post?.comments_state) {
      setSelection(post?.comments_state);
    }
  }, [post]);

  if (isV2) {
    return (
      <Grid numCols={1} numColsSm={2} className="gap-x-10">
        <ActionNeededSection />
        <div className="flex flex-col gap-y-6">
          <SimpleSelect
            name="comments_state"
            labelText="Permissions"
            helperText="Manage who can comment on this post"
            value={selection || CommentSectionState.DEFAULT}
            onSelect={(_name: string, value: any) => {
              setSelection(value);
              updatePost.mutate({
                comments_state: value,
              });
            }}
            options={permissionOptions}
          />
          <div className="w-full flex justify-end">
            <Button
              type="button"
              variant="primary-inverse"
              onClick={handleManuallyLogInUserToOrchid}
              Icon={ArrowUpRightIcon}
              iconRight
              className="w-fit"
            >
              Log in and comment as admin
            </Button>
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <div className="space-y-4 col-span-1 lg:col-span-4">
      <Button
        type="button"
        variant="primary-inverse"
        onClick={handleManuallyLogInUserToOrchid}
        Icon={ArrowRightIcon}
        iconRight
      >
        Log in and comment as admin from site
      </Button>
      <div className="w-full max-w-md">
        <SimpleSelect
          name="comments_state"
          labelText="Permissions"
          helperText="Choose who can comment on this post."
          value={selection || CommentSectionState.DEFAULT}
          onSelect={(_name: string, value: any) => {
            setSelection(value);
            updatePost.mutate({
              comments_state: value,
            });
          }}
          options={permissionOptions}
        />
      </div>
      <ActionNeededSection />
    </div>
  );
};

export default Comments;
