import { ReactNodeViewRenderer } from '@tiptap/react';

import { Heading as TiptapHeading } from '@/components/TiptapEditor/extensions/Heading';

import { HeadingView } from './views/HeadingView';

export const Heading = TiptapHeading.extend({
  addNodeView() {
    return ReactNodeViewRenderer(HeadingView);
  },
});
