import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useUser } from '@/hooks';
import { useDeletePublication, useOrganizationPublications } from '@/hooks/usePublications';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';
import TransferPublicationModal from '@/pages/Settings/Pages/Company/Publications/_components/TransferPublicationModal/TransferPublicationModal';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { SHOW_CREATE_PUBLICATION_GET_PARAM } from '../../_layout';
import BodyContainer from '../../publication/components/BodyContainer';
import CardHeader from '../../publication/components/CardHeader';

interface Props {
  organization: Organization;
}

const PublicationsList = ({ organization }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const publicationsQuery = useOrganizationPublications({ organizationId: organization.id });
  const { data: publications } = publicationsQuery || {};
  const { currentUser } = useCurrentUser();
  const { settings } = useSettings();
  const userQuery = useUser(currentUser?.id);
  const { data: user } = userQuery;

  const navigate = useNavigate();
  const [isDeletingPublicationId, setIsDeletingPublicationId] = useState('');
  const [isTransferringPublicationId, setIsTransferringPublicationId] = useState('');
  const cardId = 'publications-card';
  const isOrgOwner = user && organization && user?.id === organization?.owner_id;

  const isAdmin = () => {
    const isSysAdmin = settings?.system_admin;
    const isOrgAdmin = user?.roles.some(
      (role: any) => role.name === 'admin' && role.assignable_type === 'Organization'
    );

    return isSysAdmin || isOrgAdmin;
  };

  const actionCheck = () => {
    const canAddManyPublications = settings && settings?.max_publications > 1;

    return isAdmin() && canAddManyPublications;
  };

  const isActionAvailable = actionCheck();

  const handleEditPublication = (publicationId: string) => {
    navigate(appendSettingsPublicationId(`/settings/publication/general`, publicationId));
  };

  const handleModalClose = () => {
    setIsDeletingPublicationId('');
  };

  const { mutate: deletePublicationMutation, isLoading: isDeleting } = useDeletePublication({
    onSuccess: handleModalClose,
  });

  const handleDeleteRequest = (pubId: string) => {
    if (!isDeletingPublicationId) {
      setIsDeletingPublicationId(pubId);
    }
  };

  const handleDeletePublication = () => {
    deletePublicationMutation(isDeletingPublicationId);
  };

  const handleOpenCreatePublicationForm = () => {
    searchParams.set(SHOW_CREATE_PUBLICATION_GET_PARAM, 'true');
    setSearchParams(searchParams);
  };

  return (
    <BodyContainer>
      <CardHeader
        title="All Publications"
        cta={
          isActionAvailable ? (
            <Button variant="primary-inverse" size="xs" onClick={handleOpenCreatePublicationForm} Icon={PlusIcon}>
              New Publication
            </Button>
          ) : null
        }
      />
      <div className="flex flex-col gap-y-4">
        {publications?.map((publication: Publication) => (
          <div
            className="flex flex-row justify-between p-3 bg-surface-50 border border-surface-200 rounded-md"
            key={publication.id}
          >
            <div className="flex flex-row flex-grow gap-x-2">
              <div className={classNames('h-10 w-10 rounded overflow-hidden border bg-action-tertiary-50 rounded-sm')}>
                {publication.logo.thumb.url ? (
                  <img className="h-10 w-1-" src={publication.logo.thumb.url} alt="" />
                ) : (
                  `&nbsp;`
                )}
              </div>
              <TypographyStack>
                <Typography token="font-medium/text/sm">{publication.name}</Typography>
                <Typography
                  token="font-normal/text/sm"
                  colorWeight="600"
                  className={classNames(!!publication.description && 'italic')}
                >
                  {publication.description || 'No description'}
                </Typography>
              </TypographyStack>
            </div>
            <EllipsisDropdown
              portalMountedId={cardId}
              options={[
                {
                  label: 'View Site',
                  onClick: () => window.open(publication.url, '_blank'),
                },
                {
                  label: 'Edit',
                  onClick: () => {
                    handleEditPublication(publication.id);
                  },
                },
                ...(isOrgOwner
                  ? [
                      {
                        label: 'Transfer Ownership',
                        onClick: () => setIsTransferringPublicationId(publication.id),
                      },
                    ]
                  : []),
                ...(isOrgOwner && publications.length > 1
                  ? [
                      {
                        label: 'Delete',
                        onClick: () => handleDeleteRequest(publication.id),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        ))}
      </div>
      <ActionModal
        isOpen={!!isDeletingPublicationId}
        isWorking={isDeleting}
        disabled={isDeleting}
        onClose={handleModalClose}
        onProceed={handleDeletePublication}
        resourceId={isDeletingPublicationId}
        modalMessageType="danger"
        headerText="Delete Publication"
        descriptionText="Deleting this publication will also delete any Subscribers, Segments, Metrics, and any other associated data."
        actionText="Delete"
        buttonType="danger"
        buttonShade="dark"
      />
      <TransferPublicationModal
        isOpen={!!isTransferringPublicationId}
        publicationId={isTransferringPublicationId}
        onClose={() => setIsTransferringPublicationId('')}
      />
    </BodyContainer>
  );
};

export default PublicationsList;
