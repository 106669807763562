import { forwardRef } from 'react';
import { CardsThree, CaretRight, Icon as PhosphorIcon, SelectionAll, Slideshow } from '@phosphor-icons/react';

import { Search } from '../../UI/Search';
import { Text } from '../../UI/Text';
import { Tooltip } from '../../UI/Tooltip';

import { Draggable } from './components/Draggable';
import { components } from './constants';
import { ComponentKey } from './types';

const Item = forwardRef<HTMLDivElement, { id: ComponentKey }>(({ id, ...props }, ref) => {
  const IconComp = components[id].Icon;
  return (
    <Tooltip center={components[id].label} placement="bottom">
      <div ref={ref} {...props}>
        <div className="w-12 h-12 rounded-lg bg-wb-background flex items-center justify-center border border-wb-primary shadow-sm">
          <IconComp size={18} weight="bold" />
        </div>

        <Text
          as="p"
          weight="medium"
          variant="secondary"
          className="text-xs text-center text-ellipsis overflow-hidden whitespace-nowrap w-12 py-1"
        >
          {components[id].label}
        </Text>
      </div>
    </Tooltip>
  );
});

const CategorySection = ({ title, category }: { title: string; category: string }) => (
  <div className="flex flex-col gap-2">
    <Text size="xs" variant="secondary" weight="semibold">
      {title}
    </Text>
    <div className="flex gap-2 flex-wrap">
      {Object.keys(components)
        .filter((key) => components[key as ComponentKey].category === category)
        .map((key) => (
          <Draggable key={key} id={key as ComponentKey}>
            <Item id={key as ComponentKey} />
          </Draggable>
        ))}
    </div>
  </div>
);

const PublicationItem = ({ icon: Icon, label }: { icon: PhosphorIcon; label: string }) => (
  <div className="flex items-center gap-2 w-full justify-between px-2 py-3">
    <div className="flex items-center gap-2">
      <div className="shadow w-6 h-6 rounded-md bg-wb-background border border-wb-primary border-solid flex items-center justify-center">
        <Icon size={16} weight="duotone" />
      </div>
      <Text size="xs" weight="medium">
        {label}
      </Text>
    </div>
    <CaretRight size={12} weight="bold" className="text-wb-secondary" />
  </div>
);

export const InsertPanel = () => {
  return (
    <div className="flex flex-col space-y-8 p-4">
      <Search placeholder="Search content..." />

      <div>
        <Text size="xs" variant="secondary" weight="semibold">
          Publication
        </Text>
        <PublicationItem icon={Slideshow} label="Content" />
        <PublicationItem icon={CardsThree} label="Widgets" />
        <PublicationItem icon={SelectionAll} label="Sections" />
      </div>
      <hr />

      <CategorySection title="Templates" category="template" />
      <CategorySection title="Widgets" category="widget" />
      <CategorySection title="Text" category="text" />
      <CategorySection title="Layout" category="layout" />
      <CategorySection title="Lists" category="lists" />
      <CategorySection title="Media" category="media" />
    </div>
  );
};
