import { useQuery } from 'react-query';

import { TeamAssignment } from '@/interfaces/team_assignment';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

type QueryResponse = {
  team: TeamAssignment[];
};

type TeamParams = {
  publicationId?: string;
};

const useTeam = ({publicationId}: TeamParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const lookupPublicationId = publicationId || currentPublicationId;

  const fetchTeam = () =>
    api
      .get(`/team`, {
        params: {
          publication_id: lookupPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<QueryResponse>(
    ['publication-team', lookupPublicationId],
    fetchTeam, {
      retryOnMount: false,
      enabled: Boolean(lookupPublicationId),
    }
  );
};

export default useTeam;
