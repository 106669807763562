import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { usePermissions } from '@/context/permissions-context';

import { TOP_BAR_HEIGHT_CLASS_NAME } from '../constants';

import TopNavItem from './_components/TopNavItem';
import { NotificationsDropdown } from './NotificationsDropdown';
import SizingConfiguration from './SizingConfiguration';
import { UserDropdown } from './UserDropdown';

interface Props {
  largeNavOpen: boolean;
  className?: string;
}

const TopBar = ({ largeNavOpen, className }: Props) => {
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  const { currentUser } = useCurrentUser();
  const { isSettingsPages, setLargeNavOpen } = useAppLayout();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;

  return (
    <div
      className={cx(
        'screen:flex flex-col justify-between items-center print:hidden',
        TOP_BAR_HEIGHT_CLASS_NAME,
        className
      )}
    >
      <div className="w-full h-full flex flex-row justify-center items-between">
        {hasPublicationSettingsV2 && isSettingsPages ? null : (
          <SizingConfiguration largeNavOpen={largeNavOpen} setLargeNavOpen={setLargeNavOpen} />
        )}
        <div className="w-full py-2 pr-6 flex flex-row space-x-4 justify-end items-end bg-white">
          <TopNavItem
            onClick={() => navigate('/partner_program')}
            title="Share & Earn"
            disabled={!checkPermissions('views/nav/share_and_earn', 'read')}
          />
          <TopNavItem
            onClick={() => navigate('/wallet')}
            title="Wallet"
            disabled={!checkPermissions('views/nav/wallet', 'read')}
          />
          <TopNavItem onClick={() => navigate('/help')} title="Help" />
          <NotificationsDropdown />
          <UserDropdown />
        </div>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default TopBar;
