import { useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import debounce from 'lodash.debounce';
import { Placement } from 'tippy.js';

import analytics from '@/utils/analytics';

import { usePoll } from '../../../../../hooks';
import usePollOptions from '../../../../../hooks/usePolls/usePollOptions';
import Form from '../../../../_domain/PollForm';
import SlideOver from '../../../../SlideOver';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { SearchList, SearchListItemProps } from '../../../components/ui/SearchList';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

import { Styled } from './PollView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export const PollView = ({ editor, node, updateAttributes }: NodeViewWrapperProps) => {
  const { id: pollId } = node.attrs;
  const { publicationId } = usePublicationContext();

  const [search, setSearch] = useState<string | undefined>();
  const [shouldFetchPolls, setShouldFetchPolls] = useState<boolean>(!pollId);
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(node.attrs.showOptionsInitially ?? false);
  const [showEditor, setShowEditor] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data: poll, isError: pollRequestHasError, remove } = usePoll(pollId, false, false);
  const { data: options, fetchNextPage, hasNextPage } = usePollOptions({ search, enabled: shouldFetchPolls });
  const pollOptions = options?.pages.flatMap((page) => page.options) || [];

  const debounceOnSearch = useCallback(
    debounce((q: string) => setSearch(q), 200),
    []
  );

  const handleSelect = (selectedPollId: string) => {
    analytics.track('Published a Poll in a post');
    editor.chain().focus().updateAttributes('poll', { id: selectedPollId }).run();
  };

  useEffect(() => {
    if (node.attrs.showOptionsInitially) {
      updateAttributes({
        showOptionsInitially: false,
      });
    }
  }, [updateAttributes, node.attrs.showOptionsInitially]);

  useEffect(() => {
    if (!pollId) {
      remove();
    }
  }, [pollId]);

  // After fetching Poll based on id in the node
  // fetch Polls if the Poll does not exist.
  useEffect(() => {
    if (pollRequestHasError) {
      setShouldFetchPolls(true);
    }
  }, [pollRequestHasError]);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-id={pollId}
      ref={wrapperRef}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        {poll ? (
          <>
            <Styled.Headline>{poll.question}</Styled.Headline>
            <Styled.Paragraph>{poll.description}</Styled.Paragraph>
            <Styled.List $orientation={poll.orientation} $listStyle={poll.list_style}>
              {poll.poll_choices.map((choice: any) => {
                return (
                  <Styled.ListItem key={choice.id}>
                    <span>{choice.label}</span>
                  </Styled.ListItem>
                );
              })}
            </Styled.List>
          </>
        ) : (
          <Styled.SelectionContainer>
            <SlideOver
              bodyId="poll-editor-slideover"
              isOpen={showEditor}
              onClose={() => setShowEditor(false)}
              headerText="New Poll"
            >
              <Form
                publicationId={publicationId}
                onCancel={() => setShowEditor(false)}
                onSuccess={(id: string) => {
                  handleSelect(id);
                  setShowEditor(false);
                }}
                slideOverBodyId="poll-editor-slideover"
              />
            </SlideOver>
            <Tippy
              offset={[0, 8]}
              placement="bottom"
              interactive
              visible={selectionMenuOpen}
              onShow={() => {
                setSelectionMenuOpen(true);
              }}
              onHidden={() => {
                setSelectionMenuOpen(false);
              }}
              onClickOutside={() => {
                setSelectionMenuOpen(false);
              }}
              render={(attrs: TippyProps) =>
                selectionMenuOpen ? (
                  <SearchList
                    tabIndex={-1}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...attrs}
                    items={pollOptions.map((option) => {
                      return {
                        value: option.id,
                        label: option.name,
                      };
                    })}
                    hasMore={hasNextPage}
                    onLoadMore={() => fetchNextPage()}
                    searchable
                    searchPlaceholder="Search a poll"
                    onSearch={(q: string) => debounceOnSearch(q)}
                    onSelect={(item: SearchListItemProps) => handleSelect(item.value)}
                    onBack={() => setSelectionMenuOpen(false)}
                    footer={
                      <Button
                        $variant="quaternary"
                        $size="small"
                        $rightSlot={<Icon name="Plus" />}
                        $fullWidth
                        onClick={() => {
                          setShowEditor(true);
                          setSelectionMenuOpen(false);
                        }}
                      >
                        Create new poll
                      </Button>
                    }
                  />
                ) : null
              }
            >
              <Button
                $variant="secondary"
                $size="small"
                $active={selectionMenuOpen}
                $leftSlot={<Icon name="Poll" />}
                onClick={() => {
                  setSelectionMenuOpen(!selectionMenuOpen);
                }}
              >
                Select a poll
              </Button>
            </Tippy>
          </Styled.SelectionContainer>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export default PollView;
