import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { DimensionSettings } from '../DimensionSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

export const SignupWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['signup']}>
      <SectionRenderer title="Container">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings title="Color" property="backgroundColor" editor={editor} activeNodeResult={activeNodeResult} />
        <DimensionSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
      <SectionRenderer title="Button">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonText" title="Text" />
        <ColorSettings
          title="Color"
          property="buttonBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
      <SectionRenderer title="Input">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputPlaceholder"
          title="Placeholder"
        />
        <ColorSettings title="Text" property="inputTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Placeholder"
          property="inputPlaceholderColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="inputPadding" />
        <ColorSettings
          title="Color"
          property="inputBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
