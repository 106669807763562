import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { PencilIcon } from '@heroicons/react/24/outline';

import { BackLink } from '@/components/BackLink';
import LoadingBox from '@/components/LoadingBox';
import TabNavigation from '@/components/TabNavigation';
import { usePermissions } from '@/context/permissions-context';
import { useAutomation } from '@/hooks/useAutomations';
import { Automation } from '@/interfaces/automations/automation';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

import StatusBadge from './components/StatusBadge';

const AutomationView = () => {
  const { automationId } = useParams() as unknown as { automationId: string };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermissions } = usePermissions();

  const { data: automation, isLoading: isLoadingAutomation, isError: isErrorAutomation } = useAutomation(automationId);

  const isLoading = isLoadingAutomation;
  const isError = isErrorAutomation;

  const tabs = [
    {
      name: 'overview',
      label: 'Overview',
      selected: pathname === `/automations/${automation?.id}/overview`,
      onSelect: () => navigate(`/automations/${automation?.id}/overview`),
      disabled: !checkPermissions('views/automations/overview', 'read'),
      disabledTooltip: NO_PERMISSION_MESSAGE,
    },
    {
      name: 'workflow',
      label: 'Workflow',
      selected: pathname === `/automations/${automation?.id}/workflow`,
      onSelect: () => navigate(`/automations/${automation?.id}/workflow`),
    },
    {
      name: 'settings',
      label: 'Settings',
      selected: pathname === `/automations/${automation?.id}/settings`,
      onSelect: () => navigate(`/automations/${automation?.id}/settings`),
    },
  ];

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {automation && (
        <ReactFlowProvider>
          <div className="fixed top inset-x-0 bg-white z-10">
            <div className="md:flex md:justify-between items-center px-4 py-1 shadow-sm relative">
              <div className="md:w-full flex justify-between md:justify-start items-center pr-2 md:pr-0 space-x-4">
                <BackLink to="/automations">Exit</BackLink>
                <div>
                  <StatusBadge state={automation.state} />
                </div>
              </div>
              <div className="w-full flex justify-center py-2 group md:py-0">
                <div className="flex relative">
                  <div className="max-w-lg truncate">
                    <span className="text-sm font-gray-500">{automation.name}</span>
                  </div>
                  <button
                    className="absolute -right-8 h-6 w-6 flex justify-center items-center rounded border opacity-0 border-gray-200 text-gray-400 hover:bg-gray-200 hover:text-gray-600 group-hover:opacity-100 transition-all"
                    type="button"
                    onClick={() => navigate(`/automations/${automation?.id}/settings`)}
                  >
                    <PencilIcon className="h-3 w-3" />
                  </button>
                </div>
              </div>
              <div className="hidden md:flex w-full py-2 md:py-0 justify-end" />
            </div>
            <div className="py-2 md:py-0 bg-gray-50 border-b border-gray-200">
              <TabNavigation tabs={tabs} className="px-4" />
            </div>
          </div>
          <Outlet context={{ automation }} />
        </ReactFlowProvider>
      )}
    </LoadingBox>
  );
};

type ContextType = {
  automation: Automation;
};

export const useAutomationViewContent = () => useOutletContext<ContextType>();

export default AutomationView;
