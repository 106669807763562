import Badge from '@/components/Badge';
import {
  MessageAttemptStatus,
  MessageAttemptStatusString,
  WebhookMessageAttempt,
} from '@/interfaces/webhooks/message_attempt';

type StatusBadgeProps = {
  messageAttempt: WebhookMessageAttempt;
};

const badgeType = (status: MessageAttemptStatus) => {
  switch (status) {
    case MessageAttemptStatus.SUCCESS:
      return 'success';
    case MessageAttemptStatus.FAILED:
      return 'alert';
    case MessageAttemptStatus.SENDING:
      return 'warning';
    default:
      return 'information';
  }
};

const StatusBadge = ({ messageAttempt }: StatusBadgeProps) => {
  const label = MessageAttemptStatusString[messageAttempt.status];
  return (
    <Badge type={badgeType(messageAttempt.status)} className="w-fit">
      {label}
    </Badge>
  );
};

export default StatusBadge;
