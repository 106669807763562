import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import GroupLink from './GroupLink';
import NavLink from './NavLink';

interface Props {
  publication: CurrentUserPublication;
  isOpen: boolean;
  onLinkClick: () => void;
}

const PublicationNav = ({ publication, isOpen, onLinkClick }: Props) => {
  const location = useLocation();
  const { id, name, permissions } = publication;

  const navLinks = [
    {
      label: 'General Info',
      to: '/settings/publication/general',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Emails',
      to: '/settings/publication/emails',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Content Import',
      to: '/settings/publication/content_import',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Subscribers Import',
      to: '/settings/publication/subscribers_import',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Export Data',
      to: '/settings/publication/export_data',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Domain',
      to: '/settings/publication/domain',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Webhooks',
      to: '/settings/publication/webhooks',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
    {
      label: 'Payment Accounts',
      to: '/settings/publication/payment_accounts',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/payment_accounts', 'read'),
    },
    {
      label: 'RSS Send Feed',
      to: '/settings/publication/rss_send_feed',
      isDisabled: !useCheckPublicationPermissions(permissions, 'publication', 'update'),
    },
  ];

  return (
    <ul>
      <li className={classNames(isOpen && 'rounded-md bg-surface-50 border border-surface-200')}>
        <GroupLink
          to={appendSettingsPublicationId(`/settings/publication/general`, id)}
          label={name}
          isSelected={isOpen}
          showPlaceholderIcon
          showBorder={false}
        />
        {isOpen ? (
          <nav className="w-full flex flex-col gap-y-1 pr-2 pb-2">
            {navLinks.map((link) => (
              <NavLink
                to={appendSettingsPublicationId(link.to, id)}
                label={link.label}
                isSelected={location.pathname.startsWith(link.to)}
                isDisabled={link.isDisabled}
                key={link.to}
                className="ml-4"
                onClick={onLinkClick}
              />
            ))}
          </nav>
        ) : null}
      </li>
    </ul>
  );
};

export default PublicationNav;
