import { useState } from 'react';
import toast from 'react-hot-toast';

import { Switch } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useUpdatePublication } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';
import Section from '@/routes/settings/_components/Section';

import { ApplePayLogo } from '../_icons';

interface Props {
  publication: Publication;
}

const AdvancedOptionsSection: React.FC<Props> = ({ publication }) => {
  const { mutate } = useUpdatePublication(publication.id);

  const [taxIdEnabled, setTaxIdEnabled] = useState(publication.subscriber_tax_id_collection_enabled);
  const [applePayEnabled, setApplePayEnabled] = useState(publication.stripe_payment_method_domain_enabled);

  const onSave = (newTaxIdEnabled: boolean, newApplePayEnabled: boolean) => {
    mutate(
      {
        subscriber_tax_id_collection_enabled: newTaxIdEnabled,
        stripe_payment_method_domain_enabled: newApplePayEnabled,
      },
      {
        onSuccess: () => {
          toast.success('Saved!');
        },
        onError: () => {
          setApplePayEnabled(!newApplePayEnabled);
          setTaxIdEnabled(!newTaxIdEnabled);
        },
      }
    );
  };

  return (
    <Section
      title="Advanced Options"
      description="Optimize the subscriber checkout experience"
      maxWidthClassName="max-w-4xl"
      className="space-y-6"
      titleAction={
        <Typography token="font-normal/text/sm" colorWeight="500">
          Optional
        </Typography>
      }
    >
      <hr />
      <div className="flex space-x-3 max-w-3xl">
        <TypographyStack gap="4" className="lg:space-y-1">
          <div className="flex justify-between">
            <Typography token="font-medium/text/base">Collect Subscriber Tax IDs</Typography>
            <Switch
              className="items-top"
              name="tax_id"
              checked={taxIdEnabled}
              onChange={(_, checked) => {
                setTaxIdEnabled(checked);
                onSave(checked, applePayEnabled);
              }}
            />
          </div>
          <Typography className="lg:max-w-[80%]" token="font-light/text/xs">
            Enable this to optionally allow subscribers to submit a tax ID when they upgrade to a premium subscription.
            This will be saved to your connected Stripe account. Complete the{' '}
            <a href="https://docs.stripe.com/tax/set-up" target="_blank" rel="noreferrer">
              <Typography token="font-light/text/xs" color="secondary" colorWeight="600">
                Stripe Tax
              </Typography>
            </a>{' '}
            onboarding process to automatically add tax information to your invoices.
          </Typography>
        </TypographyStack>
      </div>
      <hr />
      <div className="flex space-x-3 max-w-3xl">
        <ApplePayLogo className="hidden lg:block" />
        <TypographyStack gap="4" className="lg:space-y-1">
          <div className="flex justify-between">
            <Typography token="font-medium/text/base">Enable Apple Pay</Typography>
            <Switch
              className="items-top"
              name="apple_pay"
              checked={applePayEnabled}
              onChange={(_, checked) => {
                setApplePayEnabled(checked);
                onSave(taxIdEnabled, checked);
              }}
            />
          </div>
          <Typography className="lg:max-w-[80%]" token="font-light/text/xs">
            Add this option as a payment method for your Upgrade Page. Please note, Apple Pay is browser and device
            dependent and won&apos;t show up on all devices for all users.
          </Typography>
        </TypographyStack>
      </div>
    </Section>
  );
};

export default AdvancedOptionsSection;
