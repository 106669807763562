import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { QueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ArrowUpLeftIcon, Bars3Icon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';
import isEqual from 'lodash.isequal';

import PreviewDropdown from '@/components/PreviewModalV2/PreviewDropdown';
import SlideOver from '@/components/SlideOver';
import Tooltip from '@/components/Tooltip';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { PostStatus } from '@/interfaces/post';
import { usePostContext } from '@/pages/Post/Edit/v2/PostContext';
import { usePostMetaContext } from '@/pages/Post/Edit/v2/PostMetaContext';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

import { TOP_BAR_HEIGHT_CLASS_NAME } from '../AppLayout/constants';

import { PostEditorSteps } from './constants';

interface Props {
  currentPostEditorStep: PostEditorSteps;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
  setShowUnpublishModal: Dispatch<SetStateAction<boolean>>;
  setPreviewActive: Dispatch<SetStateAction<boolean>>;
  postStatus?: PostStatus;
  stepsOrder: PostEditorSteps[];
}

const Line = () => <div className="w-5 h-[1px] bg-surface-200 my-auto" />;

const TopBar = ({
  currentPostEditorStep,
  setPostEditorStep,
  setShowUnpublishModal,
  setPreviewActive,
  postStatus,
  stepsOrder,
}: Props) => {
  const { publishUpdates, formData, unsavedChanges, setShowScheduleModal } = usePostContext();
  const { postMetaLastUpdated, setPostMetaLastUpdated } = usePostMetaContext();
  const [showPreviewDropdown, setShowPreviewDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { data: postDeliveryData } = usePostDeliveryData(formData?.id || '');

  const isPastFirstStep = stepsOrder.indexOf(currentPostEditorStep) > 0;
  const isBeforeLastStep = stepsOrder.indexOf(currentPostEditorStep) < stepsOrder.length - 1;
  const isSecondToLastStep = stepsOrder.indexOf(currentPostEditorStep) === stepsOrder.length - 2;
  const isLastStep = stepsOrder.indexOf(currentPostEditorStep) === stepsOrder.length - 1;

  const isAlreadyScheduledOrPublished =
    postStatus === PostStatus.PUBLISHED || postStatus === PostStatus.ARCHIVED || postStatus === PostStatus.SCHEDULED;

  const goToPreviousStep = () => {
    setPostEditorStep(stepsOrder[stepsOrder.indexOf(currentPostEditorStep) - 1]);
  };

  const goToNextStep = () => {
    setPostEditorStep(stepsOrder[stepsOrder.indexOf(currentPostEditorStep) + 1]);
  };

  const emailAudienceWithZeroSubscribers =
    postDeliveryData?.for_email && !postDeliveryData?.for_web && postDeliveryData?.target_subscriptions === 0;
  const noAudience = !postDeliveryData?.for_email && !postDeliveryData?.for_web;

  const disablePublishButton =
    (formData &&
      (isEqual(formData.tiptap_state, formData.draft_tiptap_state) ||
        (!formData.draft_tiptap_state && !unsavedChanges)) &&
      !postMetaLastUpdated &&
      postStatus !== PostStatus.DRAFT) ||
    noAudience ||
    emailAudienceWithZeroSubscribers;

  const queryClient = new QueryClient();

  const renderPublishButton = () => {
    if (postStatus === PostStatus.DRAFT) {
      return (
        <Button
          onClick={() => setShowScheduleModal(true)}
          size="xs"
          variant="primary"
          type="button"
          className="my-auto"
          disabled={disablePublishButton}
        >
          Schedule
        </Button>
      );
    }

    if (postStatus === PostStatus.SCHEDULED) {
      return (
        <Button
          onClick={() => setShowUnpublishModal(true)}
          size="xs"
          variant="primary-inverse"
          className="!text-feedback-danger-600 my-auto"
          type="button"
        >
          Unschedule
        </Button>
      );
    }

    if (postStatus === PostStatus.PUBLISHED || postStatus === PostStatus.ARCHIVED) {
      return (
        <Tooltip
          id="editor-update-web-tooltip"
          text={!formData?.scheduled_at ? 'No content changes to update web' : 'Update content changes to web'}
          showIcon={false}
          placement="bottom"
          autoWidth
        >
          <Button
            onClick={() => {
              publishUpdates.mutate();
              setPostMetaLastUpdated();
            }}
            size="xs"
            variant="primary"
            type="button"
            className="my-auto"
            disabled={disablePublishButton}
          >
            Update web
          </Button>
        </Tooltip>
      );
    }

    return null;
  };

  const toggleIsMobileMenuOpen = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [currentPostEditorStep]);

  const navigate = useNavigate();

  const handleExit = useCallback(() => {
    navigate(`/posts/${formData?.id}`);

    queryClient.invalidateQueries(['posts', formData?.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, formData?.id]);

  const setStepInUrlParams = useCallback((step: PostEditorSteps) => {
    const params = new URLSearchParams(window.location.search) as URLSearchParams;
    params.set('step', step.toLowerCase());
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }, []);

  const handleTabClick = (step: PostEditorSteps) => {
    setPostEditorStep(step);
    setStepInUrlParams(step);
  };

  return (
    <div
      className={cx('screen:flex flex-col justify-between items-center print:hidden', TOP_BAR_HEIGHT_CLASS_NAME)}
      data-testid="editor-top-bar"
    >
      <div className="w-full h-full sm:flex hidden flex-row justify-center items-between">
        <div className="w-full py-2 px-6 flex flex-row space-x-4 justify-between items-end bg-white">
          <div data-tip data-for="exit-editor-tooltip">
            <Button
              onClick={handleExit}
              size="xs"
              variant="primary-inverse"
              type="button"
              className="my-auto !px-2"
              Icon={ArrowUpLeftIcon}
            />
          </div>
          <ReactTooltip effect="solid" place="top" id="exit-editor-tooltip" offset={{ left: 14 }}>
            Exit
          </ReactTooltip>
          <div className="flex flex-row ml-10 lg:absolute lg:left-[calc(50%-320px)] xl:translate-x-[-50%] xl:left-1/2 xl:!ml-0 xl:!mr-0">
            <div>
              <Button
                onClick={() => handleTabClick(PostEditorSteps.COMPOSE)}
                size="xs"
                variant="flush"
                type="button"
                className={cx(
                  'my-auto hover:text-surface-500 hover:bg-transparent pl-0 pr-0',
                  currentPostEditorStep === PostEditorSteps.COMPOSE ? 'text-primary' : 'text-surface-300'
                )}
              >
                Compose
              </Button>
            </div>
            <div className="flex flex-col justify-center px-2">
              <Line />
            </div>
            <div>
              <Button
                onClick={() => handleTabClick(PostEditorSteps.AUDIENCE)}
                size="xs"
                variant="flush"
                type="button"
                className={cx(
                  'my-auto hover:text-surface-500 hover:bg-transparent pl-0 pr-0',
                  currentPostEditorStep === PostEditorSteps.AUDIENCE ? 'text-primary' : 'text-surface-300'
                )}
              >
                Audience
              </Button>
            </div>
            <div
              className={cx(
                'flex flex-col justify-center px-2',
                stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.EMAIL) ? '' : 'hidden'
              )}
            >
              <Line />
            </div>
            <div className={stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.EMAIL) ? '' : 'hidden'}>
              <Button
                onClick={() => handleTabClick(PostEditorSteps.EMAIL)}
                size="xs"
                variant="flush"
                type="button"
                className={cx(
                  'my-auto hover:text-surface-500 hover:bg-transparent pl-0 pr-0',
                  currentPostEditorStep === PostEditorSteps.EMAIL ? 'text-primary' : 'text-surface-300'
                )}
              >
                Email
              </Button>
            </div>
            <div
              className={cx(
                'flex flex-col justify-center px-2',
                stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.WEB) ? '' : 'hidden'
              )}
            >
              <Line />
            </div>
            <div className={stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.WEB) ? '' : 'hidden'}>
              <Button
                onClick={() => handleTabClick(PostEditorSteps.WEB)}
                size="xs"
                variant="flush"
                type="button"
                className={cx(
                  'my-auto hover:text-surface-500 hover:bg-transparent pl-0 pr-0',
                  currentPostEditorStep === PostEditorSteps.WEB ? 'text-primary' : 'text-surface-300'
                )}
              >
                Web
              </Button>
            </div>
            <div className="flex flex-col justify-center px-2">
              <Line />
            </div>
            <div>
              <Button
                onClick={() => handleTabClick(PostEditorSteps.REVIEW)}
                size="xs"
                variant="flush"
                type="button"
                className={cx(
                  'my-auto hover:text-surface-500 hover:bg-transparent pl-0 pr-0',
                  currentPostEditorStep === PostEditorSteps.REVIEW ? 'text-primary' : 'text-surface-300'
                )}
              >
                Review
              </Button>
            </div>
          </div>
          <div className="flex gap-6 justify-end">
            <div className="flex flex-row grow justify-end content-end gap-2">
              {isPastFirstStep && !isAlreadyScheduledOrPublished && (
                <Button
                  onClick={goToPreviousStep}
                  size="xs"
                  variant="primary-inverse"
                  type="button"
                  className="my-auto"
                >
                  Back
                </Button>
              )}
              {isBeforeLastStep && !isAlreadyScheduledOrPublished && (
                <Button onClick={goToNextStep} size="xs" variant="primary" type="button" className="my-auto">
                  {isSecondToLastStep ? 'Review' : 'Next'}
                </Button>
              )}
              {isLastStep && !isAlreadyScheduledOrPublished && renderPublishButton()}
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-row relative">
                <ButtonGroup size="xs">
                  <Button
                    type="button"
                    variant="primary-inverse"
                    Icon={ArrowsPointingOutIcon}
                    onClick={() => setPreviewActive(true)}
                  >
                    Preview
                  </Button>
                  <Button
                    type="button"
                    variant="primary-inverse"
                    onClick={() => setShowPreviewDropdown(!showPreviewDropdown)}
                  >
                    <ChevronDownIcon
                      className={`w-4 h-4 transition ease-out transform duration-100 ${
                        showPreviewDropdown && 'rotate-180'
                      }`}
                    />
                  </Button>
                </ButtonGroup>
                {formData && (
                  <PreviewDropdown
                    post={formData}
                    visible={showPreviewDropdown}
                    onClose={() => setShowPreviewDropdown(false)}
                  />
                )}
              </div>
              {isAlreadyScheduledOrPublished && renderPublishButton()}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex sm:hidden flex-row justify-center items-between">
        <div className="w-full py-2 px-6 flex flex-row space-x-4 justify-between items-center bg-white z-50">
          <div className="flex flex-row gap-3 items-center justify-between">
            <SlideOver
              isOpen={isMobileMenuOpen}
              onClose={() => {
                setIsMobileMenuOpen(false);
              }}
              position="left"
              headerText="Editor"
            >
              <div className="">
                <div>
                  <Button
                    variant="flush"
                    block
                    align="left"
                    onClick={() => setPostEditorStep(PostEditorSteps.COMPOSE)}
                    type="button"
                    className={cx(
                      '-mx-3',
                      currentPostEditorStep === PostEditorSteps.COMPOSE ? 'text-primary' : 'text-surface-300'
                    )}
                  >
                    Compose
                  </Button>
                </div>

                <div>
                  <Button
                    variant="flush"
                    block
                    align="left"
                    onClick={() => setPostEditorStep(PostEditorSteps.AUDIENCE)}
                    type="button"
                    className={cx(
                      '-mx-3',
                      currentPostEditorStep === PostEditorSteps.AUDIENCE ? 'text-primary' : 'text-surface-300'
                    )}
                  >
                    Audience
                  </Button>
                </div>
                <div>
                  <Button
                    variant="flush"
                    block
                    align="left"
                    onClick={() => setPostEditorStep(PostEditorSteps.EMAIL)}
                    type="button"
                    className={cx(
                      '-mx-3',
                      currentPostEditorStep === PostEditorSteps.EMAIL ? 'text-primary' : 'text-surface-300',
                      stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.EMAIL) ? '' : 'hidden'
                    )}
                  >
                    Email
                  </Button>
                </div>

                <div>
                  <Button
                    variant="flush"
                    block
                    align="left"
                    onClick={() => setPostEditorStep(PostEditorSteps.WEB)}
                    type="button"
                    className={cx(
                      '-mx-3',
                      currentPostEditorStep === PostEditorSteps.WEB ? 'text-primary' : 'text-surface-300',
                      stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.WEB) ? '' : 'hidden'
                    )}
                  >
                    Web
                  </Button>
                </div>
                <div>
                  <Button
                    variant="flush"
                    block
                    align="left"
                    onClick={() => setPostEditorStep(PostEditorSteps.REVIEW)}
                    type="button"
                    className={cx(
                      '-mx-3',
                      currentPostEditorStep === PostEditorSteps.REVIEW ? 'text-primary' : 'text-surface-300'
                    )}
                  >
                    Review
                  </Button>
                </div>
                <div className="my-4">
                  <Button
                    onClick={() => navigate('/posts')}
                    type="button"
                    variant="primary-inverse"
                    size="sm"
                    Icon={ArrowUpLeftIcon}
                  >
                    Exit editor
                  </Button>
                </div>
              </div>
            </SlideOver>
            <button onClick={toggleIsMobileMenuOpen} type="button" className="my-auto">
              <Bars3Icon className="w-4 h-4" />
              <span className="sr-only">Menu</span>
            </button>
            {isPastFirstStep && !isAlreadyScheduledOrPublished && (
              <Button onClick={goToPreviousStep} size="xs" variant="primary-inverse" type="button" className="my-auto">
                Back
              </Button>
            )}
          </div>
          <div className="flex gap-3 justify-between">
            <div className="flex flex-row items-center gap-3">
              {isBeforeLastStep && !isAlreadyScheduledOrPublished && (
                <Button onClick={goToNextStep} size="xs" variant="primary" type="button" className="my-auto">
                  Next
                </Button>
              )}
              {isLastStep && !isAlreadyScheduledOrPublished && renderPublishButton()}
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-row relative">
                <ButtonGroup size="xs">
                  <Button
                    type="button"
                    variant="primary-inverse"
                    Icon={ArrowsPointingOutIcon}
                    onClick={() => setPreviewActive(true)}
                  >
                    Preview
                  </Button>
                  <Button
                    type="button"
                    variant="primary-inverse"
                    onClick={() => setShowPreviewDropdown(!showPreviewDropdown)}
                  >
                    <ChevronDownIcon
                      className={`w-4 h-4 transition ease-out transform duration-100 ${
                        showPreviewDropdown && 'rotate-180'
                      }`}
                    />
                  </Button>
                </ButtonGroup>
                {formData && (
                  <PreviewDropdown
                    post={formData}
                    visible={showPreviewDropdown}
                    onClose={() => setShowPreviewDropdown(false)}
                  />
                )}
              </div>
              {isAlreadyScheduledOrPublished && renderPublishButton()}
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default TopBar;
