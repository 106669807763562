import { useMemo } from 'react';

import { Select } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import { Option } from '@/interfaces/general';
import { Publication } from '@/interfaces/publication';

interface Props {
  currentPublicationId: string;
  publicationLanguage: Publication['language'];
  onChange: (value: string) => void;
  isDisabled: boolean;
}

const SelectLanguage = ({ currentPublicationId, publicationLanguage, onChange, isDisabled }: Props) => {
  const { data: languageData } = useOptions(currentPublicationId, 'languages');
  const languageOptions: Option[] = useMemo(
    () =>
      languageData?.options.map((option: any) => {
        return { label: option[1], value: option[0] };
      }) || [],
    [languageData]
  );

  return (
    <Select
      emptyLabel="No languages found"
      labelText="Default Language"
      name="publication-language-select"
      placeholderText="Select Language"
      value={publicationLanguage}
      options={languageOptions}
      onSelect={(_, value: string) => onChange(value)}
      disabled={isDisabled}
    />
  );
};

export default SelectLanguage;
