import { useMemo } from 'react';

import { Select } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import { Publication } from '@/interfaces/publication';

interface Props {
  currentPublicationId: string;
  publicationTimezone: Publication['time_zone'];
  onChange: (value: string) => void;
  isDisabled: boolean;
}

const SelectTimezone = ({ currentPublicationId, publicationTimezone, onChange, isDisabled }: Props) => {
  const timezones = useOptions(currentPublicationId, 'timezones');
  const { data: timezoneData } = timezones;
  const timezoneOptions = useMemo(
    () =>
      timezoneData?.options.map((option: string) => {
        return { label: option, value: option };
      }) || [],
    [timezoneData]
  );
  const canUpdateTimeZone = timezoneOptions.length > 1;

  return (
    <Select
      emptyLabel="No timezones found"
      labelText="Default Timezone"
      name="publication-timezone-select"
      placeholderText="Select Timezone"
      value={publicationTimezone}
      options={timezoneOptions}
      onSelect={(_, value: string) => onChange(value)}
      disabled={isDisabled || !canUpdateTimeZone}
    />
  );
};

export default SelectTimezone;
