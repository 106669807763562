import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSubscriberPreference, useSubscriberPreferences } from '@/hooks/useSubscriberPreferences';
import {
  OrderBy,
  PublicationSubscriberPreference,
  SubscriberPreferenceDisplayType,
  SubscriberPreferenceType,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';
import { useResourceListFilters } from '@/ui/ResourceListFilters';

import FormSlideOver from '../_components/preferences/FormSlideOver';

import Header from './_components/Header';
import PreferencesList from './_components/PreferencesList';
import { DEFAULT_SUBSCRIBER_PREFERENCE, PreferencesProvider } from './context';

interface Props {
  showForm?: boolean;
}

const Preferences = ({ showForm = false }: Props) => {
  const { subscriberPreferenceId } = useParams() as unknown as { subscriberPreferenceId: string };
  const { data: subscriberPreference, isFetched: hasSubscriberPreferenceFetched } =
    useSubscriberPreference(subscriberPreferenceId);
  const [editablePreference, setEditablePreference] = useState<PublicationSubscriberPreference>({
    id: '',
    name: '',
    description: '',
    created_at: '',
    preference_type: SubscriberPreferenceType.TEXT,
    display_type: SubscriberPreferenceDisplayType.UNSTYLED,
    visibility: SubscriberPreferenceVisibility.VISIBLE,
    required: false,
    custom_field_id: '',
    sort_order: 0,
    subscriber_preference_options: [],
  });
  const { orderBy, direction, handleClickOrderButton } = useResourceListFilters<string, OrderBy>('', OrderBy.NAME);

  const [searchQuery, setSearchQuery] = useState('');
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } = useSubscriberPreferences({
    search: searchQuery,
    orderBy: orderBy as OrderBy,
    direction,
  });
  const preferencesList = data?.pages.flatMap((page) => page.subscriber_preferences) || [];
  const isListEmpty = preferencesList.length === 0;

  useEffect(() => {
    if (!subscriberPreference || !hasSubscriberPreferenceFetched) {
      return;
    }

    setEditablePreference(subscriberPreference);
  }, [hasSubscriberPreferenceFetched, subscriberPreference]);

  const resetEditablePreference = () => {
    setEditablePreference({
      ...DEFAULT_SUBSCRIBER_PREFERENCE,
    });
  };

  return (
    <PreferencesProvider
      value={{
        editablePreference,
        setEditablePreference,
        preferencesList,
        isListEmpty,
        isListLoading: isLoading,
        searchQuery,
        setSearchQuery,
        orderBy,
        handleClickOrderButton,
        direction,
        hasNextPage: hasNextPage || false,
        isFetchingNextPage,
        fetchNextPage,
        totalPreferencesCount: data?.pages[0]?.pagination?.total || 0,
      }}
    >
      <div className="flex flex-col gap-y-4">
        {!isListEmpty || searchQuery ? <Header /> : null}
        <PreferencesList />
      </div>
      <FormSlideOver isOpen={showForm} onClose={resetEditablePreference} />
    </PreferencesProvider>
  );
};

export default Preferences;
