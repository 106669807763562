import { useParams } from 'react-router-dom';
import { DotOutline } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';

import { Text } from '../UI/Text';

export const NavbarTitle = ({
  type = 'publication_name',
}: {
  type?: 'page_name' | 'publication_name';
}) => {
  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageRouteGetter?.getPageFromID(pageId);
  const pageRoute = pageRouteGetter?.getPageRouteFromID(pageId);
  const { data: currentPublication } = useCurrentPublication();
  const path = [currentPublication?.hostname, ...(pageRoute?.path || [])];

  const title = type === 'page_name' ? page?.draft_page_version?.name : currentPublication?.name;
  const subtitle = type === 'page_name' ? path?.join('/') : currentPublication?.hostname;


  return (
    <div className="flex gap-1.5 items-center text-ellipsis overflow-hidden max-w-[200px] lg:max-w-[500px]">
      <Text weight="medium" size="sm" className="truncate">
        {title}
      </Text>
      <DotOutline size={12} className="text-wb-secondary min-h-[12px] min-w-[12px]" weight="fill" />
      <Text weight="medium" variant="secondary" size="sm" className="truncate">
        {subtitle}
      </Text>
    </div>
  );
};
