const DESTINATION_TAG = {
  id: 'destination',
  display: 'Destination',
};

const HIKING_TAG = {
  id: 'hiking',
  display: 'Hiking',
};

const FOOD_AND_DRINK_TAG = {
  id: 'food-and-drink',
  display: 'Food and Drink',
};

const HISTORY_AND_CULTURE_TAG = {
  id: 'history-and-culture',
  display: 'History & Culture',
};

export { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG };
