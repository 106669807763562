import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import ActionModal from '@/components/ActionModal';
import Banner from '@/components/Banner';
import { useCustomDomainConfigurationVerification } from '@/hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  domain: CustomDomain;
}

const BrandedLinkConfigModal = ({ isOpen, onClose, domain }: Props) => {
  const verifyDomainMutation = useCustomDomainConfigurationVerification({ type: CustomDomainTypes.EMAIL });
  const verifyBrandedLinkMutation = useCustomDomainConfigurationVerification({ type: CustomDomainTypes.BRANDED_LINK, onSuccess: onClose });
  const isLoading = verifyDomainMutation.isLoading || verifyBrandedLinkMutation.isLoading;

  const elinkRecord = domain.authenticated_branded_links.find((record) => record.name.includes('elink'));
  const newOriginValue = process.env.CLOUDFLARE_CUSTOM_ORIGIN || "branded-link.beehiiv.com";

  const needsRecords = !domain.branded_link_verified;
  const needsVerification = elinkRecord && !needsRecords && domain.branded_link_cloudflare_pending;

  const handleProceed = () => {
    if (needsRecords) {
      verifyDomainMutation.mutate(domain.id);
    } else {
      verifyBrandedLinkMutation.mutate(domain.id);
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={isLoading}
      onClose={onClose}
      onProceed={handleProceed}
      resourceId={domain.id}
      headerText="Verify Branded Link Configuration"
      actionText="Verify Setup"
      modalSize="md"
    >
      <div className="space-y-4 text-sm text-gray-500">
        <p>
          Follow the instructions below, in order to setup{' '}
          <code className="bg-gray-100 px-2 py-1 rounded-sm">{domain.domain}</code> branded link.
        </p>
        {needsRecords && (
          <>
            <div>
              <p className="font-medium">1. Create DNS records</p>
              <p>
                Go to your DNS provider and create <strong>all</strong> of the following records with the specified
                values. Once done, click &quot;Verify Setup&quot; below to check your branded link configuration. We
                additionally will check on your behalf over the next 24 hours.
              </p>
            </div>
            <div>
              {domain.authenticated_branded_links.map((record) => (
                <DnsRecordDisplay key={record.name} record={record} className="my-8" />
              ))}
            </div>
            <div className="opacity-60">
              <p className="font-medium">2. Update DNS records</p>
              <p>
                After you have created and verified the DNS records, we&apos;ll have to update the DNS records one last
                time to complete the process. After you&apos;ve created the records listed above, the next steps will
                appear here.
              </p>
            </div>
          </>
        )}
        {needsVerification && (
          <>
            <div className="opacity-60">
              <p className="font-medium">1. Create DNS records</p>
              <p>DNS records verified.</p>
            </div>
            <div>
              <p className="font-medium">2. Update DNS records</p>
              <p>
                Go to your DNS provider and update the following record, replacing the current &quot;sendtrap.net&quot; value with &quot;{newOriginValue}&quot;.
                Once done, click &quot;Verify Setup&quot; to complete the process.
              </p>
            </div>
            <div>
              <DnsRecordDisplay key={elinkRecord.name} record={elinkRecord} replaceWithValue={newOriginValue} className="w-full my-8" />
            </div>
            <Banner
              title="It can take up to 24 hours for DNS changes to take effect."
              variant="information"
              isScreenWide={false}
            />
          </>
        )}
      </div>
    </ActionModal>
  );
};

export default BrandedLinkConfigModal;
