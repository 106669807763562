import Billing from '../../billing';
import PageContainer from '../../publication/components/PageContainer';

const WorkspaceBillingAndPlan = () => {
  return (
    <PageContainer>
      <Billing />
    </PageContainer>
  );
};

export default WorkspaceBillingAndPlan;
