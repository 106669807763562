import { useCallback } from 'react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToolbarProps } from '@/components/TiptapEditor/components/ui/Toolbar/types';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { IconName } from '@/components/TiptapEditor/lib/types';

import Styled from './RSSEntryLayoutPanel.styled';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

type EntryLayout = '1-col' | '2-col' | '3-col' | '4-col';

type Action = {
  entryLayout: EntryLayout;
  displayName: string;
  iconName: IconName;
};

const actions: Array<Action> = [
  {
    entryLayout: '1-col',
    displayName: '1 Column',
    iconName: 'FullWidth',
  },
  {
    entryLayout: '2-col',
    displayName: '2 Columns',
    iconName: 'TwoCol',
  },
  {
    entryLayout: '3-col',
    displayName: '3 Columns',
    iconName: 'ThreeCol',
  },
  {
    entryLayout: '4-col',
    displayName: '4 Columns',
    iconName: 'FourCol',
  },
];

const EntryLayoutButton = ({ editor, action }: { editor: Editor; action: Action }) => {
  const { entryLayout, iconName, displayName } = action;
  const isActive = editor.isActive('rss', { entryLayout });

  const onClick = useCallback(() => {
    editor.commands.setRSSEntryLayout(entryLayout);
  }, [entryLayout, editor]);

  return (
    <Tooltip title={displayName}>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        $active={isActive}
        onClick={onClick}
        $leftSlot={<Icon name={iconName} />}
      />
    </Tooltip>
  );
};

export const RSSEntryLayoutPanel = ({ editor }: ToolbarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <PanelSection>
      <PanelHeadline>Article Layout</PanelHeadline>
      <Styled.ButtonWrapper>
        {actions.map((action) => (
          <EntryLayoutButton key={action.entryLayout} editor={editor} action={action} />
        ))}
      </Styled.ButtonWrapper>
    </PanelSection>
  );
};

export default RSSEntryLayoutPanel;
