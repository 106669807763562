import React, { useMemo } from 'react';

import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import Banner from '@/components/Banner';
import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSetupBrandedLink: () => void;
  domain: CustomDomain;
  type: CustomDomainTypes;
}

const VerifiedDnsRecordsModal: React.FC<Props> = ({ isOpen, onClose, onSetupBrandedLink, domain, type }) => {
  const forEmail = type === CustomDomainTypes.EMAIL;
  const cloudflareRecord = domain.cloudflare_verified_authenticated_branded_link;
  const cloudflareVerificationPending = cloudflareRecord && domain.branded_link_cloudflare_pending;
  const dnsRecords = useMemo(() => {
    let records = forEmail ? domain.email_authenticated_domains : domain.web_authenticated_domains;

    // If the branded link has been added to SendGrid and Cloudflare, filter out the SendGrid record
    if (forEmail) {
      if (domain.branded_link_cloudflare_verified && cloudflareRecord) {
        records = records.concat([
          ...domain.authenticated_branded_links.filter((record) => record.name !== cloudflareRecord.name),
          cloudflareRecord
        ]);
      } else {
        records = records.concat(domain.authenticated_branded_links);
      }
    }
    return records;
  }, [domain, forEmail, cloudflareRecord]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-8 max-w-2xl">
        <Text size="lg" type="bold" className="text-gray-800 w-fit mb-8">
          DNS Records for {type}
        </Text>
        <div className="space-y-4 text-sm text-gray-500">
          <p>
            Below are the DNS records needed for your {type} setup. Please ensure that these DNS records remain present
            at all times.
          </p>
          <div>
            {dnsRecords.map((record) => (
              <DnsRecordDisplay key={record.name} record={record} className="my-8" />
            ))}
          </div>
        </div>
        {cloudflareVerificationPending && (
          <Banner
            title="Branded Link Configuration"
            bodyText="Your Branded Link is not yet verified. Please complete the setup."
            ctaText="Verify"
            onClick={onSetupBrandedLink}
            isScreenWide={false}
          />
        )}
      </div>
    </Modal>
  );
};

export default VerifiedDnsRecordsModal;
