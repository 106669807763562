import { FC, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { v4 as generateUuid } from 'uuid';

import { Option } from '../../interfaces/general';
import { LoadingSpinner } from '../LoadingSpinner';

interface Props {
  className?: string;
  labelClassName?: string;
  name: string;
  labelText?: string;
  placeholderText?: string;
  required?: boolean;
  value: String;
  staticValue?: String;
  onSelect: (name: string, value: string, selectedOption?: Option) => void;
  onClear?: (name: string) => void;
  onClearStatic?: (name: string) => void;
  staticOptions?: Option[];
  staticOptionsLabel?: string;
  onSelectStatic?: (name: string, value: string, selectedOption?: Option) => void;
  loadOptions: (inputValue?: string) => Promise<void | Option[]> | undefined;
  loadInitialValue?: () => Promise<void | Option> | undefined;
  disabled?: boolean;
  helperText?: string;
  dropdownClassnames?: string;
  emptyLabel?: string;
}

const TypeaheadSelect: FC<Props> = ({
  className,
  labelClassName,
  name,
  labelText,
  required,
  onSelect,
  onClear,
  onClearStatic,
  value,
  staticValue,
  staticOptions,
  onSelectStatic,
  loadOptions,
  loadInitialValue,
  disabled,
  helperText,
  placeholderText,
  dropdownClassnames,
  emptyLabel,
  staticOptionsLabel,
}: Props) => {
  const [options, setOptions] = useState<Option[]>();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options?.find((option) => option.value === value) || staticOptions?.find((option) => option.value === staticValue)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialValueFetched, setIsInitialValueFetched] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownEl = useRef<any>();
  const inputEl = useRef<any>();
  useEffect(() => {
    if (loadOptions) {
      setIsLoading(true);
      loadOptions(searchQuery)
        ?.then((loadedOptions) => {
          if (loadedOptions) {
            setOptions(loadedOptions);
            const foundValue = loadedOptions.find((option) => option.value === value);

            if (foundValue) {
              setSelectedOption(loadedOptions.find((option) => option.value === value));
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [loadOptions, searchQuery, value]);

  // This should only run once
  useEffect(() => {
    if (loadInitialValue && !isInitialValueFetched) {
      loadInitialValue()?.then((option) => {
        if (option) {
          setSelectedOption(option);
        }
      });
      setIsInitialValueFetched(true);
    }
  }, [loadInitialValue, setSelectedOption, isInitialValueFetched, setIsInitialValueFetched]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownEl.current && !dropdownEl.current.contains(event.target)) {
        if (menuOpen) {
          setMenuOpen(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownEl, menuOpen]);

  const onSelectOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    if (onSelect) {
      const optionByValue = options?.find((option) => option.value === newValue);
      onSelect(name, newValue, optionByValue);
      setSelectedOption(optionByValue);
    }

    setMenuOpen(false);
  };

  const onSelectStaticOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    if (onSelectStatic) {
      const optionByValue = staticOptions?.find((option) => option.value === newValue);
      onSelectStatic(name, newValue, optionByValue);
      setSelectedOption(optionByValue);
    }

    setMenuOpen(false);
  };

  const onReset = () => {
    setSelectedOption(undefined);

    if (onClear) {
      onClear(name);
      setSearchQuery('');
    }

    if (onClearStatic) {
      onClearStatic(name);
      setSearchQuery('');
    }

    setMenuOpen(false);
  };

  const onMenuClick = (e: any) => {
    // This is so we don't falsely unfocus
    if (e?.type === 'keydown' && e?.key !== 'return') {
      return;
    }

    if (menuOpen) {
      setMenuOpen(false);
    } else {
      if (inputEl) {
        inputEl?.current?.focus();
      }
      setMenuOpen(true);
    }
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Tab' && options) {
      onSelectOption(options[0].value);
    }
  };

  const onKeyDownControl = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Backspace' && selectedOption && name) {
      setSelectedOption(undefined);

      if (onClear) {
        onClear(name);
      }

      if (onClearStatic) {
        onClearStatic(name);
      }

      setSearchQuery('');
    }
  };

  const renderOption = (option: Option, tabIndex: number, staticOp?: boolean) => {
    const selected = option.value === value || option.value === staticValue;

    if (option.isOptGroup) {
      return (
        <div
          key={`select-group-label-${option.label}-${generateUuid()}`}
          className={cx('py-2 pl-3 pr-9 font-bold text-gray-400 text-xs', tabIndex > 0 && 'mt-1 pt-3 border-t')}
        >
          {option.label}
        </div>
      );
    }

    return (
      <li
        role="option"
        tabIndex={tabIndex}
        aria-selected={selected}
        key={option.value}
        className="text-gray-800 hover:text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-3 pr-9 rounded-lg"
        onClick={() => (staticOp && onSelectStatic ? onSelectStaticOption(option.value) : onSelectOption(option.value))}
        onKeyDown={() =>
          staticOp && onSelectStatic ? onSelectStaticOption(option.value) : onSelectOption(option.value)
        }
      >
        <div className="flex">
          <span className={cx(selected ? 'font-semibold' : 'font-normal', 'truncate')}>{option.label}</span>
          {selected && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800 hover:text-gray-900">
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </div>
      </li>
    );
  };

  const renderStaticOptions = () => {
    let staticOptionsElements;

    if (staticOptions && staticOptions.length > 0) {
      staticOptionsElements = (
        <ul role="listbox">{staticOptions.map((option, index) => renderOption(option, index, true))}</ul>
      );
    }

    return staticOptionsElements;
  };

  const renderOptions = () => {
    if (!options || options.length === 0) {
      return emptyLabel ? <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div> : null;
    }

    return <ul role="listbox">{options.map((option, index) => renderOption(option, index))}</ul>;
  };

  return (
    <div className={className}>
      {labelText && (
        <div>
          <label htmlFor={name} className={cx('block text-sm font-medium', labelClassName || 'text-gray-700')}>
            {labelText}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      <div className={cx('relative w-full h-fit', labelText ? 'mt-1' : '')} ref={dropdownEl}>
        <div
          role="button"
          aria-disabled={disabled}
          tabIndex={0}
          onKeyDown={onKeyDownControl}
          className={cx(
            'h-full bg-white border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 text-left cursor-default sm:text-sm',
            disabled ? 'bg-gray-50 cursor-not-allowed' : '',
            menuOpen ? 'outline-none ring-1 ring-primary-500 border-primary-500' : ''
          )}
        >
          <div
            className="w-full"
            style={{
              height: 'inherit',
            }}
          >
            <div
              role="button"
              aria-disabled={disabled}
              className={cx('h-full', disabled && 'cursor-not-allowed')}
              tabIndex={0}
              onKeyDown={onKeyDownControl}
              onClick={!disabled ? onMenuClick : undefined}
            >
              {selectedOption && (
                <div className={cx('w-full focus:outline-none truncate', disabled && 'text-gray-400')}>
                  {selectedOption.label}
                </div>
              )}
              {!selectedOption && (
                <input
                  disabled={disabled}
                  type="text"
                  ref={inputEl}
                  name={name}
                  className={cx(
                    'w-full py-0 px-0 focus:outline-none focus:ring-transparent focus:border-transparent appearance-none block border-0 placeholder-gray-400 sm:text-sm h-full',
                    disabled && 'cursor-not-allowed'
                  )}
                  placeholder={placeholderText}
                  onChange={debounce((e) => setSearchQuery(e.target.value), 500)}
                  onKeyDown={onKeyDownInput}
                  onClick={!disabled ? onMenuClick : undefined}
                />
              )}

              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                {isLoading && <LoadingSpinner className="mr-1" />}
                {selectedOption && !disabled && (
                  <button name="reset" type="button" onClick={onReset}>
                    <XMarkIcon className="h-5 w-5 text-gray-400 mr-1" aria-hidden="true" />
                  </button>
                )}
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
        {menuOpen && (
          <div
            data-type="options"
            className={cx(
              'absolute z-10 mt-1 py-2 px-2 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
              dropdownClassnames
            )}
          >
            {isLoading && <div className="text-center py-4">Loading...</div>}
            {staticOptions && renderStaticOptions()}
            {staticOptions && (
              <div
                key={`select-group-label-${staticOptionsLabel}-label-${generateUuid()}`}
                className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs mt-1 border-t"
              >
                {staticOptionsLabel}
              </div>
            )}
            {!isLoading && renderOptions()}
          </div>
        )}
      </div>

      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
    </div>
  );
};

export default TypeaheadSelect;
