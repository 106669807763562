import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useOrganization, useUser } from '@/hooks';
import { useDeletePublication, useOrganizationPublications, usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Section } from '@/pages/Settings/Components';
import NotEditing from '@/pages/Settings/Components/NotEditing';
import { Button } from '@/ui/Button';

import TransferPublicationModal from './_components/TransferPublicationModal/TransferPublicationModal';
import NewPublication from './NewPublication';

const Publications = () => {
  const [isNewPubOpen, setIsNewPubOpen] = useState<boolean>(false);

  const publicationId = useCurrentPublicationId();
  const currentPublication = usePublication(publicationId)?.data;
  const publicationsQuery = useOrganizationPublications({ organizationId: currentPublication?.organization_id });
  const currentOrganization = useOrganization(currentPublication?.organization_id, publicationId)?.data?.organization;
  const { data: publications } = publicationsQuery || {};
  const { currentUser } = useCurrentUser();
  const { settings } = useSettings();
  const userQuery = useUser(currentUser?.id);
  const { data: user } = userQuery;

  const navigate = useNavigate();
  const [currentPublicationId, setCurrentPublicationId] = useCurrentPublicationState();
  const [isDeletingPublicationId, setIsDeletingPublicationId] = useState('');
  const [isTransferingPublicationId, setIsTransferingPublicationId] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const cardId = 'publications-card';
  const isOrgOwner = user && currentOrganization && user?.id === currentOrganization?.owner_id;

  const isAdmin = () => {
    const isSysAdmin = settings?.system_admin;
    const isOrgAdmin = user?.roles.some(
      (role: any) => role.name === 'admin' && role.assignable_type === 'Organization'
    );

    return isSysAdmin || isOrgAdmin;
  };

  const actionCheck = () => {
    const canAddManyPublications = settings && settings?.max_publications > 1;

    return isAdmin() && canAddManyPublications;
  };

  const isActionAvailable = actionCheck();

  const handleEditPublication = (pubId: string, name: string) => {
    if (pubId !== currentPublicationId) {
      toast.success(`Switched to ${name}!`);
      setCurrentPublicationId(pubId);
    }

    navigate(`/settings/publication`);
  };

  const handleModalClose = () => {
    setIsDeletingPublicationId('');
    setConfirmDelete(false);
  };

  const { mutate: deletePublicationMutation, isLoading: isDeleting } = useDeletePublication({
    onSuccess: handleModalClose,
  });

  const handleDeleteRequest = (pubId: string) => {
    if (!isDeletingPublicationId) {
      setIsDeletingPublicationId(pubId);
    }
  };

  const handleDeletePublication = () => {
    if (currentPublicationId === isDeletingPublicationId) {
      const nextPub = publications?.find((pub: any) => pub.id !== isDeletingPublicationId);
      setCurrentPublicationId(nextPub?.id || '');
    }
    deletePublicationMutation(isDeletingPublicationId);
  };

  return (
    <Section
      title="Publications"
      description="Manage your publications below."
      scrollToId="publications"
      hasDivider={false}
      isLoading={!publications}
    >
      <div className="flex flex-col space-y-3">
        {publications?.map((publication: any) => (
          <div className="flex justify-between items-center border-b border-gray-100 pb-4">
            <NotEditing label={publication.name} value={publication.description} />
            <div className="flex items-center">
              <EllipsisDropdown
                portalMountedId={cardId}
                options={[
                  {
                    label: 'View Site',
                    onClick: () => window.open(publication.url, '_blank'),
                  },
                  {
                    label: 'Edit',
                    onClick: () => {
                      handleEditPublication(publication.id, publication.name);
                    },
                  },
                  ...(isOrgOwner
                    ? [
                        {
                          label: 'Transfer Ownership',
                          onClick: () => setIsTransferingPublicationId(publication.id),
                        },
                      ]
                    : []),
                  ...(isOrgOwner && publications.length > 1
                    ? [
                        {
                          label: 'Delete',
                          onClick: () => handleDeleteRequest(publication.id),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </div>
        ))}
        {isActionAvailable && (
          <div className="pt-4">
            <Button variant="primary-inverse" onClick={() => setIsNewPubOpen(true)}>
              Add Publication
            </Button>
          </div>
        )}
      </div>
      <NewPublication isOpen={isNewPubOpen} onClose={() => setIsNewPubOpen(false)} />
      <ActionModal
        isOpen={!!isDeletingPublicationId}
        isWorking={isDeleting}
        onClose={() => handleModalClose()}
        onProceed={() => handleDeletePublication()}
        resourceId={isDeletingPublicationId}
        headerText="Delete Publication"
        actionText="Delete"
        disabled={!confirmDelete}
        buttonType={confirmDelete ? 'danger' : 'primary-inverse'}
      >
        <div className="text-gray-500 text-sm space-y-2">
          <p>
            Deleting this publication will also delete any Subscribers, Segments, Metrics, and any other associated
            data.
          </p>
          <p>Are you sure you want to delete this publication? This action cannot be undone.</p>
          <p>Type &quot;DELETE&quot; below to continue.</p>
          <div className="flex flex-row items-center">
            <Input
              className="w-1/2"
              name="confirm-delete"
              placeholderText="DELETE"
              onChange={(e) => setConfirmDelete(e.target.value.toUpperCase().trim() === 'DELETE')}
            />
            {confirmDelete ? (
              <CheckIcon className="text-green-500 w-5 h-5 ml-2" />
            ) : (
              <XMarkIcon className="text-red-500 w-5 h-5 ml-2" />
            )}
          </div>
        </div>
      </ActionModal>
      <TransferPublicationModal
        isOpen={!!isTransferingPublicationId}
        publicationId={isTransferingPublicationId}
        onClose={() => setIsTransferingPublicationId('')}
      />
    </Section>
  );
};

export default Publications;
