export enum Color {
  SURFACE = 'surface',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  SUCCESS = 'success',
  WHITE = 'white',
}

export enum BorderStyle {
  ROUNDED = 'rounded',
}

export const TEXT_COLOR_MAP: { [color in Color]: any } = Object.freeze({
  [Color.SURFACE]: {
    '900': 'text-surface-900',
    '700': 'text-surface-700',
    '600': 'text-surface-600',
    '500': 'text-surface-500',
    '400': 'text-surface-400',
    '300': 'text-surface-300',
    '200': 'text-surface-200',
    '100': 'text-surface-100',
    '50': 'text-surface-50',
  },
  [Color.PRIMARY]: {
    '900': 'text-action-primary-900',
    '700': 'text-action-primary-700',
    '500': 'text-action-primary-500',
    '400': 'text-action-primary-400',
    '300': 'text-action-primary-300',
    '200': 'text-action-primary-200',
    '100': 'text-action-primary-100',
    '50': 'text-action-primary-50',
  },
  [Color.SECONDARY]: {
    '900': 'text-action-secondary-900',
    '700': 'text-action-secondary-700',
    '600': 'text-action-secondary-600',
    '500': 'text-action-secondary-500',
    '400': 'text-action-secondary-400',
    '300': 'text-action-secondary-300',
    '200': 'text-action-secondary-200',
    '100': 'text-action-secondary-100',
    '50': 'text-action-secondary-50',
  },
  [Color.TERTIARY]: {
    '900': 'text-action-tertiary-900',
    '700': 'text-action-tertiary-700',
    '600': 'text-action-tertiary-600',
    '500': 'text-action-tertiary-500',
    '400': 'text-action-tertiary-400',
    '300': 'text-action-tertiary-300',
    '200': 'text-action-tertiary-200',
    '100': 'text-action-tertiary-100',
    '50': 'text-action-tertiary-50',
  },
  [Color.WARNING]: {
    '900': 'text-feedback-warning-900',
    '700': 'text-feedback-warning-700',
    '600': 'text-feedback-warning-600',
    '500': 'text-feedback-warning-500',
    '400': 'text-feedback-warning-400',
    '300': 'text-feedback-warning-300',
    '200': 'text-feedback-warning-200',
    '100': 'text-feedback-warning-100',
    '50': 'text-feedback-warning-50',
  },
  [Color.DANGER]: {
    '900': 'text-feedback-danger-900',
    '700': 'text-feedback-danger-700',
    '600': 'text-feedback-danger-600',
    '500': 'text-feedback-danger-500',
    '400': 'text-feedback-danger-400',
    '300': 'text-feedback-danger-300',
    '200': 'text-feedback-danger-200',
    '100': 'text-feedback-danger-100',
    '50': 'text-feedback-danger-50',
  },
  [Color.INFO]: {
    '900': 'text-feedback-info-900',
    '700': 'text-feedback-info-700',
    '500': 'text-feedback-info-500',
    '400': 'text-feedback-info-400',
    '300': 'text-feedback-info-300',
    '200': 'text-feedback-info-200',
    '100': 'text-feedback-info-100',
    '50': 'text-feedback-info-50',
  },
  [Color.SUCCESS]: {
    '900': 'text-feedback-success-900',
    '700': 'text-feedback-success-700',
    '600': 'text-feedback-success-600',
    '500': 'text-feedback-success-500',
    '400': 'text-feedback-success-400',
    '300': 'text-feedback-success-300',
    '200': 'text-feedback-success-200',
    '100': 'text-feedback-success-100',
    '50': 'text-feedback-success-50',
  },
  [Color.WHITE]: {
    '900': 'text-white',
    '700': 'text-white',
    '500': 'text-white',
    '400': 'text-white',
    '300': 'text-white',
    '200': 'text-white',
    '100': 'text-white',
    '50': 'text-white',
  },
});

export const BACKGROUND_COLOR_MAP: { [color in Color]: any } = {
  [Color.SURFACE]: {
    '900': 'bg-surface-900',
    '700': 'bg-surface-700',
    '500': 'bg-surface-500',
    '400': 'bg-surface-400',
    '300': 'bg-surface-300',
    '200': 'bg-surface-200',
    '100': 'bg-surface-100',
    '50': 'bg-surface-50',
  },
  [Color.PRIMARY]: {
    '900': 'bg-action-primary-900',
    '700': 'bg-action-primary-700',
    '500': 'bg-action-primary-500',
    '400': 'bg-action-primary-400',
    '300': 'bg-action-primary-300',
    '200': 'bg-action-primary-200',
    '100': 'bg-action-primary-100',
    '50': 'bg-action-primary-50',
  },
  [Color.SECONDARY]: {
    '900': 'bg-action-secondary-900',
    '700': 'bg-action-secondary-700',
    '500': 'bg-action-secondary-500',
    '400': 'bg-action-secondary-400',
    '300': 'bg-action-secondary-300',
    '200': 'bg-action-secondary-200',
    '100': 'bg-action-secondary-100',
    '50': 'bg-action-secondary-50',
  },
  [Color.TERTIARY]: {
    '900': 'bg-action-tertiary-900',
    '700': 'bg-action-tertiary-700',
    '500': 'bg-action-tertiary-500',
    '400': 'bg-action-tertiary-400',
    '300': 'bg-action-tertiary-300',
    '200': 'bg-action-tertiary-200',
    '100': 'bg-action-tertiary-100',
    '50': 'bg-action-tertiary-50',
  },
  [Color.WARNING]: {
    '900': 'bg-feedback-warning-900',
    '700': 'bg-feedback-warning-700',
    '500': 'bg-feedback-warning-500',
    '400': 'bg-feedback-warning-400',
    '300': 'bg-feedback-warning-300',
    '200': 'bg-feedback-warning-200',
    '100': 'bg-feedback-warning-100',
    '50': 'bg-feedback-warning-50',
  },
  [Color.DANGER]: {
    '900': 'bg-feedback-danger-900',
    '700': 'bg-feedback-danger-700',
    '500': 'bg-feedback-danger-500',
    '400': 'bg-feedback-danger-400',
    '300': 'bg-feedback-danger-300',
    '200': 'bg-feedback-danger-200',
    '100': 'bg-feedback-danger-100',
    '50': 'bg-feedback-danger-50',
  },
  [Color.INFO]: {
    '900': 'bg-feedback-info-900',
    '700': 'bg-feedback-info-700',
    '500': 'bg-feedback-info-500',
    '400': 'bg-feedback-info-400',
    '300': 'bg-feedback-info-300',
    '200': 'bg-feedback-info-200',
    '100': 'bg-feedback-info-100',
    '50': 'bg-feedback-info-50',
  },
  [Color.SUCCESS]: {
    '900': 'bg-feedback-success-900',
    '600': 'bg-feedback-success-600',
    '700': 'bg-feedback-success-700',
    '500': 'bg-feedback-success-500',
    '400': 'bg-feedback-success-400',
    '300': 'bg-feedback-success-300',
    '200': 'bg-feedback-success-200',
    '100': 'bg-feedback-success-100',
    '50': 'bg-feedback-success-50',
  },
  [Color.WHITE]: {
    '900': 'bg-white',
    '700': 'bg-white',
    '500': 'bg-white',
    '400': 'bg-white',
    '300': 'bg-white',
    '200': 'bg-white',
    '100': 'bg-white',
    '50': 'bg-white',
  },
};
export const BORDER_COLOR_MAP: { [color in Color]: any } = {
  [Color.SURFACE]: {
    '900': 'border-surface-900',
    '700': 'border-surface-700',
    '500': 'border-surface-500',
    '400': 'border-surface-400',
    '300': 'border-surface-300',
    '200': 'border-surface-200',
    '100': 'border-surface-100',
    '50': 'border-surface-50',
  },
  [Color.PRIMARY]: {
    '900': 'border-action-primary-900',
    '700': 'border-action-primary-700',
    '500': 'border-action-primary-500',
    '400': 'border-action-primary-400',
    '300': 'border-action-primary-300',
    '200': 'border-action-primary-200',
    '100': 'border-action-primary-100',
    '50': 'border-action-primary-50',
  },
  [Color.SECONDARY]: {
    '900': 'border-action-secondary-900',
    '700': 'border-action-secondary-700',
    '500': 'border-action-secondary-500',
    '400': 'border-action-secondary-400',
    '300': 'border-action-secondary-300',
    '200': 'border-action-secondary-200',
    '100': 'border-action-secondary-100',
    '50': 'border-action-secondary-50',
  },
  [Color.TERTIARY]: {
    '900': 'border-action-tertiary-900',
    '700': 'border-action-tertiary-700',
    '500': 'border-action-tertiary-500',
    '400': 'border-action-tertiary-400',
    '300': 'border-action-tertiary-300',
    '200': 'border-action-tertiary-200',
    '100': 'border-action-tertiary-100',
    '50': 'border-action-tertiary-50',
  },
  [Color.WARNING]: {
    '900': 'border-feedback-warning-900',
    '700': 'border-feedback-warning-700',
    '500': 'border-feedback-warning-500',
    '400': 'border-feedback-warning-400',
    '300': 'border-feedback-warning-300',
    '200': 'border-feedback-warning-200',
    '100': 'border-feedback-warning-100',
    '50': 'border-feedback-warning-50',
  },
  [Color.DANGER]: {
    '900': 'border-feedback-danger-900',
    '700': 'border-feedback-danger-700',
    '500': 'border-feedback-danger-500',
    '400': 'border-feedback-danger-400',
    '300': 'border-feedback-danger-300',
    '200': 'border-feedback-danger-200',
    '100': 'border-feedback-danger-100',
    '50': 'border-feedback-danger-50',
  },
  [Color.INFO]: {
    '900': 'border-feedback-info-900',
    '700': 'border-feedback-info-700',
    '500': 'border-feedback-info-500',
    '400': 'border-feedback-info-400',
    '300': 'border-feedback-info-300',
    '200': 'border-feedback-info-200',
    '100': 'border-feedback-info-100',
    '50': 'border-feedback-info-50',
  },
  [Color.SUCCESS]: {
    '900': 'border-feedback-success-900',
    '700': 'border-feedback-success-700',
    '600': 'border-feedback-success-600',
    '500': 'border-feedback-success-500',
    '400': 'border-feedback-success-400',
    '300': 'border-feedback-success-300',
    '200': 'border-feedback-success-200',
    '100': 'border-feedback-success-100',
    '50': 'border-feedback-success-50',
  },
  [Color.WHITE]: {
    '900': 'border-white',
    '700': 'border-white',
    '500': 'border-white',
    '400': 'border-white',
    '300': 'border-white',
    '200': 'border-white',
    '100': 'border-white',
    '50': 'border-white',
  },
};

export const BORDER_RADIUS_MAP: { [color in BorderStyle]: any } = Object.freeze({
  rounded: {
    none: 'rounded-none',
    small: 'rounded-sm',
    default: 'rounded',
    medium: 'rounded-medium',
    large: 'rounded-lg',
    xl: 'rounded-xl',
    '2xl': 'rounded-2xl',
    '3xl': 'rounded-3xl',
    full: 'rounded-full',
  },
});

/*
  Example values for type (mapped to design system used in Figma)
  surface/200
  primary/300
  info/600
*/
export const getColor = (mapping: string, colorType: 'text' | 'border' | 'background') => {
  try {
    const [type, weight] = mapping.split('/');
    switch (colorType) {
      case 'text':
        return TEXT_COLOR_MAP[type as Color][weight];
      case 'border':
        return BORDER_COLOR_MAP[type as Color][weight];
      case 'background':
        return BACKGROUND_COLOR_MAP[type as Color][weight];
      default:
        return '';
    }
  } catch (error: any) {
    // do nothing

    return '';
  }
};

/*
  Example values for type (mapped to design system used in Figma)
  border/rounded/large
*/
type BorderRadiusMapping = 'border/rounded/small' | 'border/rounded/medium' | 'border/rounded/large';

export const getBorderRadius = (mapping: BorderRadiusMapping) => {
  const [, style, size] = mapping.split('/');
  try {
    return BORDER_RADIUS_MAP[style as BorderStyle][size];
  } catch (error: any) {
    // do nothing
    return '';
  }
};
