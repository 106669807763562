import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { SiteTemplate } from '@/interfaces/site_template';

import api from '../../services/swarm';

interface ApiResponse {
  site_templates: SiteTemplate[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
  category?: string;
}

export default function useInfiniteScrollSiteTemplates({ enabled = true, category }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'site_templates', 'infinite_scroll'],
    ({ pageParam = 1 }) =>
      api
        .get(`/site_templates`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
            category,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      enabled: !!currentPublicationId && enabled,
    }
  );
}
