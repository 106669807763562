import { SocialsElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import { useCurrentPublication } from '@/hooks';
import { Publication } from '@/interfaces/publication';

const getSocials = (publication: Publication | undefined) => {
  if (!publication) return {};

  return {
    twitter_url: publication.twitter_url,
    facebook_url: publication.facebook_url,
    instagram_url: publication.instagram_url,
    tiktok_url: publication.tiktok_url,
    youtube_url: publication.youtube_url,
    linkedin_url: publication.linkedin_url,
    discord_url: publication.discord_url,
    threads_url: publication.threads_url,
  };
};

export const SocialsView = ({ node }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  const { data: currentPublication } = useCurrentPublication();

  return (
    <SocialsElement
      element={{
        type: 'socials',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          data: getSocials(currentPublication),
        },
        content: [
          {
            text: '',
            id: 'text',
          },
        ],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {/* 
      So socials can easily be selected, try removing and see what happens 
      https://github.com/user-attachments/assets/89dc2b42-631d-4da3-a844-0bf23b95b3c2
      */}
      <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
    </SocialsElement>
  );
};
