import React, { Fragment, MouseEvent, ReactNode, useCallback } from "react";
import { GetNavbarElementProps, NavbarMenu, NavbarMenuDropdown, NavbarMenuItem, NavbarMenuList, NavbarMenuListGroup, NavbarSerializableNode, NavbarSerializerProps } from "@shared/dream-components";

import { useNavbarContext } from "./NavbarContext";


const componentMap = {
  navbar_menu: NavbarMenu,
  navbar_menu_list: NavbarMenuList,
  navbar_menu_list_group: NavbarMenuListGroup,
  navbar_item: NavbarMenuItem,
  navbar_dropdown: NavbarMenuDropdown,
};
interface WrapperProps {
  element: NavbarSerializableNode;
  children: ReactNode;
}

const Wrapper = ({ element, children }: WrapperProps) => {
  const { onSelectNode, onHoverNode } = useNavbarContext();

  const child = React.Children.only(children) as React.ReactElement;

  const handleMouseDown = useCallback((event: MouseEvent<HTMLElement>) => {
    // Stop the event from bubbling up to parent elements
    event.stopPropagation();
    event.preventDefault();

    // Set the clicked element as the selected node
    const targetElement = event.currentTarget as HTMLElement;
    onSelectNode(targetElement, element.attrs?.id || 'empty');
  }, [onSelectNode, element]);


  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    // Stop click events like clicking links
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleMouseOver = useCallback((event: MouseEvent<HTMLElement>) => {
    // Stop the event from bubbling up to parent elements
    event.stopPropagation();
    event.preventDefault();

    // Set the clicked element as the selected node
    // if (!element.attrs?.id) return;
    const targetElement = event.currentTarget as HTMLElement;
    onHoverNode(targetElement, element.attrs?.id || 'empty');
  }, [onHoverNode, element]);


  // Ensure there's only one child and clone it with the new props
  return React.cloneElement(child, {
    onClick: handleClick,
    onMouseDown: handleMouseDown,
    onMouseOver: handleMouseOver,
    'data-element-id': element.attrs?.id
  });
};

export function getNavbarElement({
  node,
  children,
  key,
  portalRef,
}: GetNavbarElementProps): ReactNode {
  let Comp: any;

  switch (node.type) {

    case "navbar_menu":
    case "navbar_menu_list":
    case "navbar_item":
      Comp = componentMap[node.type];
      return (
        <Wrapper key={key} element={node}>
          <Comp element={node} className={`dream-${node.type}`}>
            {children}
          </Comp>
        </Wrapper>
      );

    case "navbar_dropdown":
      Comp = componentMap[node.type];
      return (
        <Wrapper key={key} element={node}>
          <Comp element={node} className={`dream-${node.type}`} portalRef={portalRef} >
            {children}
          </Comp>
        </Wrapper>
      );


    case "navbar_menu_list_group":
      Comp = componentMap[node.type];
      return <Comp key={key} element={node} className={`dream-${node.type}`} >
        {children}
      </Comp>
    default:
      return <Fragment key={key}>{children}</Fragment>;
  }
}

const serializeNavbarNode = (
  {
    node,
    createKey = (entry) => entry[0].attrs?.id || entry[1].join(","),
    children,
    portalRef,
  }: NavbarSerializerProps,
  path = [0] // internal path tracking
): ReactNode => {
  const key = createKey([node, path]);


  const nodeChildren =
    // @ts-ignore
    node.content?.map((n, i) =>
      serializeNavbarNode(
        {
          node: n,
          createKey,
          portalRef,
        },
        [...path, i]
      )
    ) || [];

  return getNavbarElement({
    node,
    key,
    portalRef,
    children: [...nodeChildren, children],
  });
};

export function NavbarSerializer(props: NavbarSerializerProps) {
  return <>{serializeNavbarNode(props)}</>;
}
