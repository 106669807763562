import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TemplateLevel } from '@/interfaces/site_template';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

interface SiteTemplatePayload {
  category: string;
  name: string;
  description: string;
  content: any;
  available_to_public?: boolean;
  created_by_super_admin?: boolean;
  level: TemplateLevel;
}

export default function useCreateSiteTemplate({ onSuccess }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({
      name,
      description,
      content,
      level,
      category,
      available_to_public,
      created_by_super_admin,
    }: SiteTemplatePayload) =>
      api.post(`/site_templates`, {
        publication_id: currentPublicationId,
        site_template: {
          name,
          description,
          content,
          level,
          category,
          available_to_public,
          created_by_super_admin,
        },
      }),
    {
      onSuccess: () => {
        onSuccess?.();
        toast.success('Created!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
}
