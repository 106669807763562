import Banner from '@/components/Banner';
import { Checkbox, RadioSelect, SimpleSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';

import BodyContainer from '../../components/BodyContainer';
import useMailchimpConnection from '../hooks/useMailchimpConnection';
import { MailchimpImportOptions, StepProps } from '../new/types';

const MailchimpFolders: React.FunctionComponent<StepProps> = ({ contentImport, updateContentImport, errors }: StepProps) => {
  const {
    isConnected,
    options,
    folders,
    hasFolders,
    handleSelectIntegration,
    handleCheckedFolderId,
    importTypeSelection,
    setImportTypeSelection,
    isCustomizeByFolderSelected
  } = useMailchimpConnection({ contentImport, updateContentImport });

  if (!isConnected) {
    return (
      <BodyContainer>
        <Typography token="font-normal/text/sm" colorWeight="500">
          You don&apos;t have any Mailchimp accounts connected. Please connect an account to import content.
        </Typography>
      </BodyContainer>
    )
  }

  return (
    <BodyContainer>
      <SimpleSelect
        labelText="Select Mailchimp account"
        name="integration_id"
        options={options}
        value={options[0].value}
        onSelect={(name, value) => handleSelectIntegration(value)}
        errorText={errors.integrationId}
      />

      <RadioSelect
        size="md"
        value={String(importTypeSelection)}
        options={MailchimpImportOptions}
        onSelect={(value: string) => setImportTypeSelection(value)}
      />

      {isCustomizeByFolderSelected && (
        hasFolders ? (
          <>
            <Typography token="font-normal/text/sm" colorWeight="500">
              Check the folder options you would like to import campaigns from:
            </Typography>

            <div className="space-y-2">
              {folders?.map((folder) => (
                <Checkbox
                  key={folder.id}
                  labelText={folder.name}
                  name={folder.name}
                  checked={contentImport.mailchimp_folders?.includes(folder.id) || false}
                  onChange={(checked) => {
                    handleCheckedFolderId(folder.id, checked);
                  }}
                />
              ))}
            </div>
          </>
        ) : (
          <Banner
            variant="warning"
            isScreenWide={false}
            title="No folders found"
            bodyText="No folders were found in your Mailchimp account. Make sure you connected the correct account and try again."
          />
        )
      )}
    </BodyContainer>
  )
};

export default MailchimpFolders;
