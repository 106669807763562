import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { usePermissions } from '@/context/permissions-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import {
  useCustomDomainConfigurationVerification,
  useCustomDomainToggleUsage,
  useVerifiedCustomDomains,
} from '@/hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

import NotEditing from '../../../Components/NotEditing';

import BrandedLinkConfigModal from './components/BrandedLinkConfigModal';
import DomainConfigModal from './components/DomainConfigModal';
import DomainSelectionPanel from './components/DomainSelectionPanel';
import StatusBadge from './components/StatusBadge';
import VerifiedDnsRecordsModal from './components/VerifiedDnsRecordsModal';

interface SelectedDomainDetailsProps {
  domain?: CustomDomain;
  type: CustomDomainTypes;
}

const SelectedDomainDetails = ({ domain, type }: SelectedDomainDetailsProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();
  const [searchParams] = useSearchParams();
  const { checkPermissions } = usePermissions();
  const canCustomDomain = checkPermissions('domain_setting', 'update');
  const { data: customDomains } = useVerifiedCustomDomains();
  const verifyDomainSettingsMutation = useCustomDomainConfigurationVerification({ type });
  const toggleUsageMutation = useCustomDomainToggleUsage();

  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [configurationModalOpen, setConfigurationModalOpen] = useState<boolean>(searchParams.get('configuration') === type);
  const [brandedLinkModalOpen, setBrandedLinkModalOpen] = useState(false);

  const hasBrandedLinkFeatureAccess = settings?.custom_branded_links;
  const canAddBrandedLink = type === CustomDomainTypes.EMAIL && !domain?.branded_link_added;
  const hasBrandedLink = type === CustomDomainTypes.EMAIL && domain?.branded_link_added;
  const brandedLinkVerificationPending = hasBrandedLink && (!domain.branded_link_verified || domain?.branded_link_cloudflare_pending);
  const brandedLinkHelpText = () => {
    if (!hasBrandedLinkFeatureAccess) {
      return '';
    }
    if (canAddBrandedLink) {
      return 'Custom Branded Link configuration available.';
    }
    if (brandedLinkVerificationPending) {
      return 'Custom Branded Link configuration is not yet verified. Please complete the setup.';
    }
    return '';
  }

  const verifications = {
    [CustomDomainTypes.WEB]: domain?.web_verification_pending,
    [CustomDomainTypes.REDIRECT]: domain?.web_redirect_verification_pending,
    [CustomDomainTypes.EMAIL]: domain?.email_verification_pending,
    [CustomDomainTypes.BRANDED_LINK]: brandedLinkVerificationPending,
  };
  const verificationPending = verifications[type];

  const handleRemove = () => {
    if (domain) {
      toggleUsageMutation.mutate({ id: domain.id, type, enabled: false });
    }
  };

  const handleProceed = () => {
    if (!domain) return;

    verifyDomainSettingsMutation.mutate(domain.id);

    if (hasBrandedLinkFeatureAccess && canAddBrandedLink && type === CustomDomainTypes.EMAIL) {
      setConfigurationModalOpen(false);
      setBrandedLinkModalOpen(true);
    }
  }

  const handleConfigureBrandedLink = () => {
    if (domain) {
      if (!hasBrandedLink) {
        toggleUsageMutation.mutate({ id: domain.id, type: CustomDomainTypes.EMAIL, enabled: true });
      }
      setBrandedLinkModalOpen(true);
    }
  };

  if (!canCustomDomain) return null;

  if (!domain) {
    // If they don't have any verified domains to choose from, don't show anything here.
    if (customDomains && customDomains.length === 0) return null;

    return (
      <DomainSelectionPanel
        type={type}
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
      />
    );
  }

  return (
    <div className="flex justify-between">
      <BrandedLinkConfigModal
        isOpen={brandedLinkModalOpen}
        onClose={() => setBrandedLinkModalOpen(false)}
        domain={domain}
      />

      {verificationPending ? (
        <DomainConfigModal
          isOpen={configurationModalOpen}
          isLoading={verifyDomainSettingsMutation.isLoading}
          onClose={() => setConfigurationModalOpen(false)}
          domain={domain}
          type={type}
          onProceed={handleProceed}
        />
      ) : (
        <VerifiedDnsRecordsModal
          isOpen={configurationModalOpen}
          onClose={() => setConfigurationModalOpen(false)}
          onSetupBrandedLink={() => {
            setConfigurationModalOpen(false);
            setBrandedLinkModalOpen(true);
          }}
          domain={domain}
          type={type}
        />
      )}

      <NotEditing
        label={domain.domain}
        value={<StatusBadge status={verificationPending ? 'pending' : 'valid'} domain={domain} type={type} />}
        helperText={brandedLinkHelpText()}
      />
      <EllipsisDropdown
        portalMountedId={domain.id}
        options={[
          {
            label: verificationPending ? 'Verify' : 'View DNS Records',
            onClick: () => setConfigurationModalOpen(true),
          },
          ...(hasBrandedLinkFeatureAccess && (canAddBrandedLink || brandedLinkVerificationPending)
            ? [{
              label: brandedLinkVerificationPending ? 'Verify Branded Link' : 'Configure Branded Link',
              onClick: handleConfigureBrandedLink,
            }]
            : []
          ),
          ...(type === CustomDomainTypes.EMAIL && currentPublication?.on_dedicated_ip
            ? []
            : [
              {
                label: 'Remove',
                onClick: handleRemove,
              },
            ]),
        ]}
      />
    </div>
  );
};

export default SelectedDomainDetails;
