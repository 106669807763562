import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import analytics from '@/utils/analytics';

import { BillingActions } from '../../interfaces/billing';
import api from '../../services/swarm';

interface ApiResponse {
  stripe_session_url: string;
}

interface Props {
  organizationId?: string;
  publicationId: string;
  action: BillingActions;
}

const useStripeBillingSession = ({ organizationId, publicationId, action }: Props) => {
  return useMutation<ApiResponse, unknown, { plan?: string }>(
    ({ plan }) =>
      api
        .post(`/organizations/${organizationId}/stripe_sessions`, {
          publication_id: publicationId,
          stripe_session: {
            action,
            plan,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        analytics.track('Started Upgrade');
        window.location.replace(data.stripe_session_url);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useStripeBillingSession;
