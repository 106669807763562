import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useCurrentPage, useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import useUpdatePageVersion from '@/hooks/usePageVersion/useUpdatePageVersion';
import api from '@/services/swarm';

import { Accordion } from '../../_components/UI/Accordion';
import { Switch } from '../../_components/UI/Switch';
import { makeValidPath } from '../../_utils/makeValidPath';
import { renameSlugOnPath } from '../../_utils/renameSlugOnPath';
import { FBSettings } from '../_components/FBSettings';
import { MetadataSettings } from '../_components/MetadataSettings';
import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';
import { XSettings } from '../_components/XSettings';

const WebsiteSettingsPage = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { updatePagesRoutes, pagesRoutes } = useWebsiteContext();
  const { page, pageRoute } = useCurrentPage();
  const isHome = pageRoute?.path?.length === 0;
  const pageRouteGetter = useWebsitePageRouteGetter();
  const isDefaultPage = pageRouteGetter?.isDefaultPage(page?.id || '');
  const updatePageVersion = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const handleImageSave = (key: string, file: string | File | any) => {
    const formData = new FormData();
    const currentPubId = currentPublication?.id || '';
    formData.append('publication_id', currentPubId);
    formData.append(`page_version[metadata][${key}]`, file);

    toast
      .promise(api.patch(`/page_versions/${page?.draft_page_version?.id}`, formData), {
        loading: 'Saving...',
        success: 'Saved!',
        error: 'Failed to upload image',
      })
      .finally(() => {
        queryClient.invalidateQueries(['publications', currentPubId, 'pages']);
      });
  };

  const pageTitle = page?.draft_page_version?.name;
  const pageSlug = [...(pageRoute?.path || [])]?.pop() || '';

  const [slug, setSlug] = useState(pageSlug);

  useEffect(() => {
    setSlug(pageSlug);
  }, [pageSlug]);

  const pageFullURL = [currentPublication?.hostname, ...(pageRoute?.path?.slice(0, -1) || []), slug]
    .filter(Boolean)
    .join('/');

  return (
    <div>
      <SettingsSection>
        <SettingsItem title="Settings" description="Set a default title and path to access your page">
          <div className="flex flex-col lg:flex-row gap-6 w-[600px] max-w-full">
            <SettingsInput
              className="flex-1"
              name="page_title"
              labelText="Title"
              type="input"
              placeholder="My Page"
              value={pageTitle || ''}
              onSave={async (value: string) => {
                if (value === pageTitle) return;
                await updatePageVersion.mutate({
                  name: value,
                });
              }}
            />
            <SettingsInput
              className="flex-1"
              name="page_slug"
              labelText="URL"
              type="input"
              placeholder="about"
              disabled={isHome || isDefaultPage}
              value={isHome ? '/' : pageSlug}
              onChangeMutateValue={(value: string) => {
                const validVal = makeValidPath(value);
                setSlug(validVal);
                return validVal;
              }}
              onSave={(value: string) => {
                if (!value || value?.length <= 0) {
                  toast.error('URL cannot be empty');
                  setSlug(pageSlug);
                  throw new Error('URL cannot be empty');
                }
                if (value === pageSlug) return;
                if (!pagesRoutes) return;
                const newRoute = renameSlugOnPath(pagesRoutes, pageRoute?.path?.slice(0, -1) || [], pageSlug, value);

                if (!newRoute) return;

                updatePagesRoutes(newRoute);
                toast.success('Saved!');
              }}
              helperText={pageFullURL}
              labelInfo={
                isHome || isDefaultPage
                  ? `You can't change the URL of ${isHome ? 'the home page' : 'a default page'}`
                  : undefined
              }
            />
          </div>
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <SettingsItem
          title="Search Engine Indexing"
          description="By default, pages are made discoverable on the web. Turning this off will add a noindex meta tag in the header of your page to prevent this page from showing up in Google Search."
        >
          <Switch
            id="noindex_enabled"
            labelText="Discoverable on the web"
            checked={!page?.draft_page_version?.metadata?.noindex_enabled}
            onCheckedChange={(value: boolean) => {
              updatePageVersion.mutate({
                metadata: {
                  noindex_enabled: !value,
                },
              });
            }}
          />
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <SettingsItem
          title="Search Engine Optimization (SEO)"
          description="Set how your page looks like on search engines and when shared."
        >
          <Accordion type="multiple" className="border border-border border-solid rounded-lg">
            <MetadataSettings
              isAccordionItem
              data={page?.draft_page_version}
              onUpdate={(key, value) => {
                updatePageVersion.mutate({
                  metadata: {
                    [key]: value,
                  },
                });
              }}
              isPage
            />
            <XSettings
              isAccordionItem
              data={page?.draft_page_version}
              onUpdate={(key, value) => {
                updatePageVersion.mutate({
                  metadata: {
                    [key]: value,
                  },
                });
              }}
              onUpdateImage={(file) => handleImageSave('x_image_url', file)}
              isPage
            />
            <FBSettings
              isAccordionItem
              data={page?.draft_page_version}
              onUpdate={(key, value) => {
                updatePageVersion.mutate({
                  metadata: {
                    [key]: value,
                  },
                });
              }}
              onUpdateImage={(file) => handleImageSave('facebook_image_url', file)}
              isPage
            />
          </Accordion>
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsPage;
