import PostPoll from '@/components/_domain/PostPoll';
import { EmptyBlock } from '@/components/EmptyBlock';
import { Typography } from '@/components/Typography';
import { useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';

interface Props {
  publication: Publication;
}

const Polls = ({ publication }: Props) => {
  const { data: welcomeEmail, isSuccess } = useWelcomeEmail(publication.id);

  if (isSuccess && welcomeEmail?.post_poll_ids?.length === 0) {
    return (
      <EmptyBlock>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Your welcome email does not currently include any polls.
        </Typography>
      </EmptyBlock>
    );
  }

  return (
    <>
      {welcomeEmail?.post_poll_ids.map((postPollId) => (
        <PostPoll publicationId={publication.id} postPollId={postPollId} />
      ))}
    </>
  );
};

export default Polls;
