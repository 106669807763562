import { useMemo } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';
import useIntegrations from '@/hooks/useIntegrations/useIntegrations';
import { ContentImport } from '@/interfaces/content_import';
import { IntegrationProvider } from '@/interfaces/integration';
import { Card } from '@/ui/Card';

import CardHeader from '../../components/CardHeader';

interface Props {
  contentImport: Partial<ContentImport>;
}

const Summary = ({ contentImport }: Props) => {
  const hasUrl = ['substack', 'wordpress'].includes(contentImport.platform || '');
  const hasSubstackPremiumContentUpload = contentImport.platform === 'substack';
  const hasGhostApiSettings = contentImport.platform === 'ghost';

  const hasMailchimpSettings = contentImport.platform === 'mailchimp';
  const selectedMailchimpFolders = contentImport.mailchimp_folders || [];
  const { data } = useIntegrations({ provider: IntegrationProvider.MAILCHIMP, enabled: hasMailchimpSettings });
  const mailchimpIntegrations = useMemo(() => data?.pages.flatMap((page) => page.integrations) || [], [data]);
  const selectedMailchimpIntegration = mailchimpIntegrations.find(integration => integration.id === contentImport.integration_id);

  return (
    <Card className="flex flex-col gap-y-6">
      <CardHeader
        title="Summary"
        description="Any posts that you import will only be published on your website and not sent to you subscribers."
      />

      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Imported from
        </Typography>
        <Typography token="font-medium/text/sm" className="capitalize">
          {contentImport.platform}
        </Typography>
      </TypographyStack>

      {hasUrl && (
        <TypographyStack>
          <Typography token="font-normal/text/sm" colorWeight="600">
            Publication URL
          </Typography>
          <Typography token="font-medium/text/sm" className="break-all">
            {contentImport.url && `https://${contentImport.url}`}
          </Typography>
        </TypographyStack>
      )}

      {hasSubstackPremiumContentUpload && (
        <TypographyStack>
          <Typography token="font-normal/text/sm" colorWeight="600">
            Paywall Content Upload (Optional)
          </Typography>
          <Typography token="font-medium/text/sm" className="break-all">
            {contentImport.file?.name}
          </Typography>
        </TypographyStack>
      )}

      {hasGhostApiSettings && (
        <>
          <TypographyStack>
            <Typography token="font-normal/text/sm" colorWeight="600">
              API Key
            </Typography>
            <Typography token="font-medium/text/sm" className="break-all">
              {contentImport.api_key}
            </Typography>
          </TypographyStack>

          <TypographyStack>
            <Typography token="font-normal/text/sm" colorWeight="600">
              API URL
            </Typography>
            <Typography token="font-medium/text/sm" className="break-all">
              {contentImport.url && `https://${contentImport.url}`}
            </Typography>
          </TypographyStack>
        </>
      )}

      {hasMailchimpSettings && (
        <>
          <TypographyStack>
            <Typography token="font-normal/text/sm" colorWeight="600">
              Account ID
            </Typography>
            <Typography token="font-medium/text/sm" className="break-all">
              {selectedMailchimpIntegration && `#${selectedMailchimpIntegration.uid}`}
            </Typography>
          </TypographyStack>

          <TypographyStack>
            <Typography token="font-normal/text/sm" colorWeight="600">
              Folders
            </Typography>
            {contentImport.integration_id && (
              <Typography token="font-medium/text/sm" className="break-all">
                {selectedMailchimpFolders.length > 0 ? (
                  <ul>
                    {selectedMailchimpFolders.map(folder => <li key={folder}>{folder}</li>)}
                  </ul>
                ) : (
                  'All folders'
                )}
              </Typography>
            )}
          </TypographyStack>
        </>
      )}
    </Card>
  )
};

export default Summary;
