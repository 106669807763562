import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { UseMutationResult } from 'react-query';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor, JSONContent } from '@tiptap/react';
import { AxiosResponse } from 'axios';

import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';

export type PostContextContent = {
  authors: Author[];
  editor: Editor | null;
  setEditor: (editor: Editor) => void;
  editorIsLoading: boolean;
  setEditorIsLoading: (isLoading: boolean) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  wordCount: number;
  setWordCount: (wordCount: number) => void;
  provider: TiptapCollabProvider | undefined;
  setProvider: (provider: TiptapCollabProvider | undefined) => void;
  formData: Post | undefined;
  users: EditorUser[];
  setUsers: (users: EditorUser[]) => void;
  showSidebar: boolean;
  setShowSidebar: (showSidebar: boolean) => void;
  showSearchAndReplaceMenu: boolean;
  setShowSearchAndReplaceMenu: (showSearchAndReplaceMenu: boolean) => void;
  collaborationEnabled: boolean;
  showThreadsSidebar: boolean;
  setShowThreadsSidebar: (showThreads: boolean) => void;
  loadingNodes: Record<string, boolean>;
  setLoadingNodes: Dispatch<SetStateAction<Record<string, boolean>>>;
  onChange: (data: any) => void;
  setPostEditorStep?: Dispatch<SetStateAction<PostEditorSteps>>;
  publishPost?: UseMutationResult<AxiosResponse<any, any>, unknown, void, unknown>;
  publishUpdates: any;
  thumbnail_id: string | null;

  activeThreadId: string | null;
  setActiveThreadId: (threadId: string | null) => void;
  selectThread: (threadId: string) => void;
  unselectThread: () => void;
  threads: TCollabThread[] | null;
  createThread: (content: JSONContent) => void;
  highlightThread: (threadId: string) => void;
  removeHighlightThread: (threadId: string) => void;

  emailSubjectEdited: boolean;
  setEmailSubjectEdited: (edited: boolean) => void;
  emailPreviewTextEdited: boolean;
  setEmailPreviewTextEdited: (edited: boolean) => void;
  seoMetaTitleEdited: boolean;
  setSeoMetaTitleEdited: (edited: boolean) => void;
  seoMetaDescriptionEdited: boolean;
  setSeoMetaDescriptionEdited: (edited: boolean) => void;
  seoMetaOpenGraphTitleEdited: boolean;
  setSeoMetaOpenGraphTitleEdited: (edited: boolean) => void;
  seoMetaOpenGraphDescriptionEdited: boolean;
  setSeoMetaOpenGraphDescriptionEdited: (edited: boolean) => void;
  seoMetaTwitterTitleEdited: boolean;
  setSeoMetaTwitterTitleEdited: (edited: boolean) => void;
  seoMetaTwitterDescriptionEdited: boolean;
  setSeoMetaTwitterDescriptionEdited: (edited: boolean) => void;
  showIncludeExcludeSegments: boolean;
  setShowIncludeExcludeSegments: React.Dispatch<React.SetStateAction<boolean>>;
  tiersEdited: boolean;
  setTiersEdited: React.Dispatch<React.SetStateAction<boolean>>;

  showScheduleModal: boolean;
  setShowScheduleModal: (value: boolean) => void;
};

export const PostContext = createContext<PostContextContent>({
  authors: [],
  editor: null,
  setEditor: () => {},
  editorIsLoading: false,
  setEditorIsLoading: () => {},
  isSaving: false,
  setIsSaving: () => {},
  unsavedChanges: false,
  setUnsavedChanges: () => {},
  wordCount: 0,
  setWordCount: () => {},
  provider: undefined,
  setProvider: () => {},
  formData: undefined,
  users: [],
  setUsers: () => {},
  showSidebar: true,
  setShowSidebar: () => {},
  showSearchAndReplaceMenu: false,
  setShowSearchAndReplaceMenu: () => {},
  collaborationEnabled: true,
  showThreadsSidebar: false,
  loadingNodes: {},
  setLoadingNodes: () => {},
  onChange: () => {},
  setPostEditorStep: () => {},
  publishUpdates: () => {},
  // threads
  setShowThreadsSidebar: () => {},
  activeThreadId: null,
  setActiveThreadId: () => {},
  selectThread: () => {},
  unselectThread: () => {},

  threads: [],
  createThread: () => {},
  highlightThread: () => {},
  removeHighlightThread: () => {},

  emailSubjectEdited: false,
  setEmailSubjectEdited: () => {},

  emailPreviewTextEdited: false,
  setEmailPreviewTextEdited: () => {},

  seoMetaTitleEdited: false,
  setSeoMetaTitleEdited: () => {},

  seoMetaDescriptionEdited: false,
  setSeoMetaDescriptionEdited: () => {},

  seoMetaOpenGraphTitleEdited: false,
  setSeoMetaOpenGraphTitleEdited: () => {},

  seoMetaOpenGraphDescriptionEdited: false,
  setSeoMetaOpenGraphDescriptionEdited: () => {},

  seoMetaTwitterTitleEdited: false,
  setSeoMetaTwitterTitleEdited: () => {},

  seoMetaTwitterDescriptionEdited: false,
  setSeoMetaTwitterDescriptionEdited: () => {},

  showIncludeExcludeSegments: false,
  setShowIncludeExcludeSegments: () => {},

  tiersEdited: false,
  setTiersEdited: () => {},

  showScheduleModal: false,
  setShowScheduleModal: () => {},
  thumbnail_id: null,
});

export const usePostContext = () => useContext(PostContext);
