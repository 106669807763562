import { useCallback, useEffect, useState } from 'react';
import { COLUMN_DEFAULTS, CONTAINER_DEFAULTS, HEADING_DEFAULTS, PARAGRAPH_DEFAULTS } from '@shared/dream-components';

import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { parseCssValue } from '../utils/parseCssValue';

type PaddingValue = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

const getDefaultPadding = (type: string) => {
  const isRoot = true;

  if (type === 'column') return isRoot ? COLUMN_DEFAULTS.paddingRoot : COLUMN_DEFAULTS.padding;
  if (['h1', 'h2', 'h3'].includes(type || '')) return isRoot ? HEADING_DEFAULTS.paddingRoot : HEADING_DEFAULTS.padding;
  if (type === 'paragraph') return isRoot ? PARAGRAPH_DEFAULTS.paddingRoot : PARAGRAPH_DEFAULTS.padding;
  return CONTAINER_DEFAULTS.padding;
};

type PaddingSettingsProps = AttributeSettingProps & {
  property: string;
};

export const PaddingSettings = ({ editor, property, activeNodeResult }: PaddingSettingsProps) => {
  const unit = 'px';
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const [padding, setPadding] = useState<PaddingValue | undefined>(undefined);

  useEffect(() => {
    return () => {
      setPadding(undefined);
    };
  }, []);

  const updatePadding = useCallback(
    (side: 'top' | 'right' | 'bottom' | 'left' | 'all', value: number) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      const p = { ...padding, [side]: value } as PaddingValue;

      if (side === 'all') {
        setPadding({
          top: value,
          right: value,
          bottom: value,
          left: value,
        });

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(
            activeNodePos,
            property,
            `${value}${unit} ${value}${unit} ${value}${unit} ${value}${unit}`
          );
          return true;
        });
      } else {
        setPadding(p);

        editor.commands.command(({ tr }) => {
          // this one seems more promising because it does not
          // override the other attributes.
          tr.setNodeAttribute(
            activeNodePos,
            property,
            `${p.top}${unit} ${p.right}${unit} ${p.bottom}${unit} ${p.left}${unit}`
          );
          return true;
        });
      }
    },
    [activeNodePos, activeNodeType, editor, padding, unit, property]
  );

  useEffect(() => {
    if (activeNodePos === undefined || !activeNodeType) return;

    let nodePadding = activeNodeAttributes?.[property];

    if (!nodePadding) {
      nodePadding = getDefaultPadding(activeNodeType);
    }

    // padding is always 4 numbers top, right, bottom, left
    const paddingArray = nodePadding.split(' ');
    if (paddingArray.length < 4) return;

    const top = paddingArray[0];
    const right = paddingArray[1];
    const bottom = paddingArray[2];
    const left = paddingArray[3];

    setPadding({
      top: parseCssValue(top, unit).value,
      right: parseCssValue(right, unit).value,
      bottom: parseCssValue(bottom, unit).value,
      left: parseCssValue(left, unit).value,
    });
  }, [activeNodeType, activeNodePos, activeNodeAttributes, property]);

  return (
    <BoxModelToggle
      title="Padding"
      values={{
        top: padding?.top || 0,
        right: padding?.right || 0,
        bottom: padding?.bottom || 0,
        left: padding?.left || 0,
      }}
      suffixes={{
        top: 't',
        right: 'r',
        bottom: 'b',
        left: 'l',
      }}
      onUpdate={updatePadding}
    />
  );
};
