import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  rhsColumn: ReactNode;
  useAlternateWidth?: boolean;
}>;

const TwoColumnPageContainer = ({ children, rhsColumn, useAlternateWidth = false }: Props) => (
  <div className="mx-6 flex flex-col gap-y-6 w-full sm:justify-center sm:flex-row sm:gap-x-6 sm:my-12 sm:mx-0">
    <div
      className={classNames(
        'flex flex-col gap-y-6 w-full',
        useAlternateWidth ? 'md:max-w-[546px]' : 'md:max-w-[592px]'
      )}
    >
      {children}
    </div>
    <div className={classNames('w-full', useAlternateWidth ? 'md:max-w-[432px]' : 'md:max-w-[306px]')}>{rhsColumn}</div>
  </div>
);

export default TwoColumnPageContainer;
