import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Banner from '@/components/Banner';
import LoadingBox from '@/components/LoadingBox';
import { useCurrentUser } from '@/context/current-user-context';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { SettingsPageLayout } from '@/pages/Settings/Components';

import PageContainer from '../publication/components/PageContainer';

import BillingDetails from './_components/BillingDetails';
import BillingSummary from './_components/BillingSummary';
import CancellationSection from './_components/CancellationSection';
import CurrentPlan from './_components/CurrentPlan';
import PausePlanNotice from './_components/PausePlanNotice';
import StartTrial from './_components/StartTrial';
import UsageInformation from './_components/UsageInformation';
import { useAnalyticsTracking } from './_hooks';

const Billing = () => {
  const { currentUser } = useCurrentUser();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication, isLoading, isError } = usePublication(currentPublicationId);
  const trialStatusQuery = useTrialStatus(currentPublicationId);
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;

  const { organization_id: organizationId } = currentPublication || {};
  const location = useLocation();
  const navigate = useNavigate();
  const [offerApplied, setofferApplied] = useState(false);
  useAnalyticsTracking();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has('offer_applied')) {
      setofferApplied(true);
      query.delete('offer_applied');
      navigate({ search: query.toString() }, { replace: true });
    }
  }, [location, navigate]);

  if (hasPublicationSettingsV2) {
    return organizationId ? (
      <>
        {offerApplied && (
          <Banner
            className="w-2/3 mx-auto"
            isScreenWide={false}
            variant="success"
            title="Offer applied! The discount will be reflected on your bills for the next 3 months."
          />
        )}
        <PausePlanNotice organizationId={organizationId} />
        <BillingSummary organizationId={organizationId} />
        <UsageInformation organizationId={organizationId} publicationId={currentPublicationId} />
        <CurrentPlan organizationId={organizationId} publicationId={currentPublicationId} />
        {trialEligible && <StartTrial organizationId={organizationId} />}
        <BillingDetails organizationId={organizationId} publicationId={currentPublicationId} />
        <CancellationSection organizationId={organizationId} publicationId={currentPublicationId} />
      </>
    ) : null;
  }

  return (
    <SettingsPageLayout title="Billing">
      <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent">
        {organizationId ? (
          <div className="flex flex-row w-full justify-center">
            <PageContainer>
              {offerApplied && (
                <Banner
                  className="w-2/3 mx-auto"
                  isScreenWide={false}
                  variant="success"
                  title="Offer applied! The discount will be reflected on your bills for the next 3 months."
                />
              )}
              <PausePlanNotice organizationId={organizationId} />
              <BillingSummary organizationId={organizationId} />
              <UsageInformation organizationId={organizationId} publicationId={currentPublicationId} />
              <CurrentPlan organizationId={organizationId} publicationId={currentPublicationId} />
              {trialEligible && <StartTrial organizationId={organizationId} />}
              <BillingDetails organizationId={organizationId} publicationId={currentPublicationId} />
              <CancellationSection organizationId={organizationId} publicationId={currentPublicationId} />
            </PageContainer>
          </div>
        ) : null}
      </LoadingBox>
    </SettingsPageLayout>
  );
};

export default Billing;
