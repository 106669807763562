import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { PricingCardsView } from './views/PricingCardsView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pricingCardiingCards: {
      setPricingCardiingCards: () => ReturnType;
    };
  }
}

export const PricingCards = Node.create<{}, {}>({
  name: 'pricingCards',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      gap: {
        default: 8,
      },
      background: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-background'),
        renderHTML: (attributes) => ({ 'data-background': attributes.background }),
      },
      color: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => ({ 'data-color': attributes.color }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      shadow: {
        default: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      border_radius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.border_radius }),
      },
      data: {
        default: {
          tiers: [],
        },
      },
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PricingCardsView);
  },
});

export default PricingCards;
