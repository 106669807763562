import { useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import IconButton from '@/components/IconHelpers/IconButton';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import { CurrentUserOrganization } from '@/hooks/useCurrentUserOrganizations';
import { SETTINGS_ORGANIZATION_ID_PARAM_NAME } from '@/hooks/useOrganization/useCurrentOrganizationId';
import { SETTINGS_PUBLICATION_ID_PARAM_NAME } from '@/hooks/usePublications/useCurrentPublicationId';

import WorkspaceNav from './WorkspaceNav';

interface Props {
  organizations: CurrentUserOrganization[];
  onLinkClick: () => void;
  isLoading: boolean;
}

const MultiplePublicationSettings = ({ organizations, onLinkClick, isLoading }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPublicationId = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);
  const currentOrganizationId = searchParams.get(SETTINGS_ORGANIZATION_ID_PARAM_NAME);

  const listContainer = useRef<HTMLDivElement>(null);

  const publicationIdsByOrganizationId: Record<string, string[]> =
    useMemo(
      () =>
        organizations?.reduce((pv, cv) => {
          return {
            ...pv,
            [cv.id]: cv.publications.map((publication) => publication.id),
          };
        }, {}),
      [organizations]
    ) || {};

  const handleClickLink = (shouldScrollToTop: boolean) => {
    if (shouldScrollToTop) {
      listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    onLinkClick();
  };

  if (isLoading) {
    return (
      <div className="flex flex-col border-x border-surface-100 bg-white w-[230px]">
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="p-4 mt-12 space-y-2">
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
            </div>
          }
        >
          &nbsp;
        </SkeletonLoader>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4 border-x border-surface-100 bg-white w-[230px]">
      <div className="px-4 pt-5">
        <Typography token="font-medium/text/sm" className="flex flex-row gap-x-2 items-center">
          <IconButton onClick={() => navigate('/')}>
            <ArrowLeftIcon className="w-3 h-3" />
          </IconButton>
          Settings
        </Typography>
      </div>
      <div className="flex flex-col gap-y-2 px-4 pb-4 overflow-y-scroll" ref={listContainer}>
        {organizations?.map((organization) => (
          <WorkspaceNav
            key={organization.id}
            organization={organization}
            currentPublicationId={currentPublicationId}
            isOpen={
              (currentPublicationId &&
                publicationIdsByOrganizationId[organization.id].includes(currentPublicationId)) ||
              currentOrganizationId === organization.id
            }
            onLinkClick={handleClickLink}
          />
        ))}
      </div>
    </div>
  );
};

export default MultiplePublicationSettings;
