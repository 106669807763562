import { useEmailMessagePreview, useOptInEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';

import EmailOverview from '../components/EmailOverview';

interface Props {
  publication: Publication;
}

const Overview = ({ publication }: Props) => {
  const { data: optInEmail } = useOptInEmail(publication.id);
  const { data: emailMessagePreview } = useEmailMessagePreview(publication.id, optInEmail?.id);
  const { html } = emailMessagePreview || {};

  return (
    <EmailOverview
      subjectLine={optInEmail?.subject_line || ''}
      previewText={optInEmail?.preview_text || ''}
      html={html}
    />
  );
};

export default Overview;
