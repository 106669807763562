import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { cx } from 'class-variance-authority';

import { Checkbox, Switch } from '@/components/Form';
import Multiselect from '@/components/Multiselect';
import Tooltip from '@/components/Tooltip';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSegments } from '@/hooks';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { useCreatePostTarget, useDeletePostTarget } from '@/hooks/usePostTargets';
import usePublication from '@/hooks/usePublications/usePublication';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import usePublicationSubscriberCounts from '@/hooks/usePublications/usePublicationSubscriberCounts';
import { Option } from '@/interfaces/general';
import { Post, PostSendStatus } from '@/interfaces/post';
import {
  PostTarget,
  PostTargetAction,
  PostTargetPlatform,
  PostTargetReceiverType,
  PostTargetTier,
} from '@/interfaces/post_target';
import { Segment } from '@/interfaces/segment';
import { Tier } from '@/interfaces/tier';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { usePostContext } from '../PostContext';

interface Props {
  display: boolean;
  emailPostSendTargetsLoading: boolean;
  emailPostSendTargets: PostTarget[];
  formData: Post;
  paidTiers: Tier[];
}

const EmailAudience = ({ display, emailPostSendTargets, emailPostSendTargetsLoading, formData, paidTiers }: Props) => {
  const [emailIncludeSegmentsQuery, setEmailIncludeSegmentsQuery] = useState('');
  const [emailExcludeSegmentsQuery, setEmailExcludeSegmentsQuery] = useState('');
  const [includedOptionsSegments, setIncludedOptionsSegments] = useState<Option[]>([]);
  const [excludedOptionsSegments, setExcludedOptionsSegments] = useState<Option[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [initialTargetsLoaded, setInitialTargetsLoaded] = useState(false);
  const [excludedSegmentPostTargets, setExcludedSegmentPostTargets] = useState<PostTarget[]>([]);
  const [includedSegmentPostTargets, setIncludedSegmentPostTargets] = useState<PostTarget[]>([]);
  const settings = usePublicationSettings(formData?.publication_id || '');
  const { data: subscriberCounts } = usePublicationSubscriberCounts(formData?.publication_id || '');
  const numActiveFreeSubscribers = subscriberCounts?.num_active_free_subscribers || 0;
  const numActivePaidSubscribers = subscriberCounts?.num_active_paid_subscribers || 0;
  const { showIncludeExcludeSegments, setShowIncludeExcludeSegments, tiersEdited, setTiersEdited } = usePostContext();
  const { mutateAsync: createEmailPostTarget } = useCreatePostTarget(formData?.id || '', PostTargetPlatform.EMAIL);
  const { mutateAsync: deletePostTarget } = useDeletePostTarget(formData?.id || '');
  const { data: publication } = usePublication(formData?.publication_id || '');
  const navigate = useNavigate();
  const segmentsEnabled = settings?.data?.send_to_segments;
  const premiumSubscriptionsEnabled = publication?.is_premium_enabled;
  const { data: postDeliveryData } = usePostDeliveryData(formData?.id);
  const { data: segmentsData } = useSegments({
    search: '',
    shouldFetchAll: true,
  });

  useEffect(() => {
    if (segmentsData) {
      setSegments(segmentsData.pages.flatMap((page) => page.segments));
    }
  }, [segmentsData]);

  useEffect(() => {
    setExcludedSegmentPostTargets(
      emailPostSendTargets.filter(
        (target) =>
          target.receiver_type === PostTargetReceiverType.SEGMENT && target.action === PostTargetAction.EXCLUDE
      )
    );
    setIncludedSegmentPostTargets(
      emailPostSendTargets.filter(
        (target) =>
          target.receiver_type === PostTargetReceiverType.SEGMENT && target.action === PostTargetAction.INCLUDE
      )
    );
  }, [emailPostSendTargets]);

  useEffect(() => {
    const optionSegments = segments
      .filter(
        (segment) =>
          includedSegmentPostTargets?.every((target) => target.receiver_id !== segment.id) &&
          segment.name.toLowerCase().includes(emailExcludeSegmentsQuery.toLowerCase())
      )
      .flatMap((segment) => ({
        label: segment.name,
        value: segment.id,
        subtitle: `${segment.num_members.toLocaleString()} subscribers`,
      }));

    setExcludedOptionsSegments(optionSegments);
  }, [segments, emailExcludeSegmentsQuery, includedSegmentPostTargets]);

  useEffect(() => {
    const optionSegments = segments
      .filter(
        (segment) =>
          excludedSegmentPostTargets?.every((target) => target.receiver_id !== segment.id) &&
          segment.name.toLowerCase().includes(emailIncludeSegmentsQuery.toLowerCase())
      )
      .flatMap((segment) => ({
        label: segment.name,
        value: segment.id,
        subtitle: `${segment.num_members.toLocaleString()} subscribers`,
      }));

    setIncludedOptionsSegments(optionSegments);
  }, [segments, emailIncludeSegmentsQuery, excludedSegmentPostTargets]);

  const audienceOptions: Option<PostTargetTier>[] = [
    {
      label: 'All Free Subscribers',
      value: PostTargetTier.FREE,
      subtitle: `${numActiveFreeSubscribers} subscribers`,
      onlySelectionHelpText: 'Free subscribers are selected by default',
    },
  ];

  if (premiumSubscriptionsEnabled) {
    audienceOptions.push({
      label: 'All Paid Subscribers',
      value: PostTargetTier.PREMIUM,
      subtitle: `${numActivePaidSubscribers} subscribers`,
      subItems: [
        ...paidTiers
          .filter((tier) => !tier.is_default)
          .map((tier) => {
            return {
              label: `All ${tier.name}`,
              value: tier.id,
              subtitle: `${tier.active_subscriptions_count?.toLocaleString() || 0} Subscribers`,
            };
          }),
      ],
    });
  }

  const handleDeselectEmailSegment = (name: string, deselected: string) => {
    const postTarget = emailPostSendTargets.find((target) => target.receiver_id === deselected);
    if (postTarget) {
      deletePostTarget(postTarget.id);
    }
  };

  const toggleShowIncludeExcludeSegments = () => {
    if (showIncludeExcludeSegments) {
      emailPostSendTargets.forEach((target) => {
        if (target.receiver_type === PostTargetReceiverType.SEGMENT) {
          deletePostTarget(target.id, {});
        }
      });
    }

    setShowIncludeExcludeSegments((prevShowIncludeExcludeSegments: boolean) => !prevShowIncludeExcludeSegments);
  };

  const postSent = formData.send_status === PostSendStatus.SENT;

  const onDefaultAudienceChange = (checked: boolean, event?: ChangeEvent<HTMLInputElement> | undefined) => {
    setTiersEdited(true);

    if (checked) {
      const newItems = [...checkedItems, event?.target.name || ''];
      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if all paid tiers are checked and premium is not checked, check premium
      if (allPaidTiersChecked && !newItems.includes(PostTargetTier.PREMIUM)) {
        newItems.push(PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is checked and not all paid tiers are checked, check all paid tiers
      if (newItems.includes(PostTargetTier.PREMIUM)) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            if (!newItems.includes(tier.id)) {
              newItems.push(tier.id);
            }
          });
      }

      setCheckedItems(newItems);
    } else {
      let newItems = checkedItems.filter((item) => item !== event?.target.name);
      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if not all paid tiers are checked and "All paid subscribers" is checked, uncheck "All paid subscribers"
      if (!allPaidTiersChecked && newItems.includes(PostTargetTier.PREMIUM)) {
        newItems = newItems.filter((item) => item !== PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is unchecked, uncheck all paid tiers
      if (event?.target.name === PostTargetTier.PREMIUM) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            newItems = newItems.filter((item) => item !== tier.id);
          });
      }

      setCheckedItems(newItems);
    }
  };

  const createAndDestroyPostTargets = async () => {
    const allMutations: any[] = [];
    const idsToDelete: Set<string> = new Set();
    const idsToCreate: Set<string> = new Set();

    // loop through all emailPostSendTargets and delete the ones that are not checked
    emailPostSendTargets.forEach((target) => {
      // if "All paid subscribers" is unchecked and a paid tier is checked, delete the target for the paid tier
      if (
        !checkedItems.includes(PostTargetTier.PREMIUM) &&
        target.tier === PostTargetTier.PREMIUM &&
        target.receiver_type === PostTargetReceiverType.PUBLICATION
      ) {
        idsToDelete.add(target.id);
      }

      if (!checkedItems.includes(target.tier_id) && target.receiver_type === PostTargetReceiverType.PUBLICATION) {
        allMutations.push(deletePostTarget(target.id));
      }
    });

    // loop through all checkedItems and emailPostSendTargets for each tier if one does not exist
    checkedItems.forEach((item) => {
      const isPaid = paidTiers.some((tier) => tier.id === item);
      // if "All paid subscribers" is checked, skip creating a target if the it's a paid tier, it's unecessary.
      const skipItem = isPaid && checkedItems.includes(PostTargetTier.PREMIUM);

      if (!skipItem) {
        idsToCreate.add(item);
      }
    });

    idsToCreate.forEach((id) => {
      const doesNotAlreadyExist = emailPostSendTargets
        .filter((target) => target.receiver_type === PostTargetReceiverType.PUBLICATION)
        .every((target) => target.tier_id !== id);

      if (doesNotAlreadyExist) {
        allMutations.push(
          createEmailPostTarget({
            params: {
              action: PostTargetAction.INCLUDE,
              receiver_type: PostTargetReceiverType.PUBLICATION,
              receiver_id: formData?.publication_id || '',
              tier_id: id,
            },
          })
        );
      }
    });

    idsToDelete.forEach((id) => {
      const targetExists = emailPostSendTargets.some(
        (target) => target.tier_id === id && target.receiver_type === PostTargetReceiverType.PUBLICATION
      );

      if (targetExists) {
        allMutations.push(deletePostTarget(id));
      }
    });

    try {
      await Promise.all(allMutations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (initialTargetsLoaded) {
      createAndDestroyPostTargets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  useEffect(() => {
    // this should run only once when the emailPostSendTargets are loaded
    if (!initialTargetsLoaded && !emailPostSendTargetsLoading) {
      const newItems = emailPostSendTargets
        .filter((target) => target.receiver_type === PostTargetReceiverType.PUBLICATION)
        .map((target) => target.tier_id);

      const allPaidTiersChecked =
        premiumSubscriptionsEnabled &&
        paidTiers.filter((tier) => !tier.is_default).length > 0 &&
        newItems.filter((item) => item !== PostTargetTier.PREMIUM && item !== PostTargetTier.FREE).length ===
          paidTiers.filter((tier) => !tier.is_default).length;

      // if all paid tiers are checked and "All paid subscribers" is not checked, check "All paid subscribers"
      if (allPaidTiersChecked && !newItems.includes(PostTargetTier.PREMIUM)) {
        newItems.push(PostTargetTier.PREMIUM);
      }

      // if "All paid subscribers" is checked and not all paid tiers are checked, check all paid tiers
      if (newItems.includes(PostTargetTier.PREMIUM)) {
        paidTiers
          .filter((tier) => !tier.is_default)
          .forEach((tier) => {
            if (!newItems.includes(tier.id)) {
              newItems.push(tier.id);
            }
          });
      }

      if (newItems.length === 0 && !postDeliveryData?.for_email && !postDeliveryData?.for_web && !tiersEdited) {
        newItems.push(PostTargetTier.FREE);

        createEmailPostTarget({
          params: {
            action: PostTargetAction.INCLUDE,
            receiver_type: PostTargetReceiverType.PUBLICATION,
            receiver_id: formData?.publication_id || '',
            tier_id: PostTargetTier.FREE as string,
          },
        });
      }

      setCheckedItems(newItems);

      setTimeout(() => {
        setInitialTargetsLoaded(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPostSendTargetsLoading]);

  useEffect(() => {
    if (includedSegmentPostTargets.length > 0 || excludedSegmentPostTargets.length > 0) {
      setShowIncludeExcludeSegments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedSegmentPostTargets, excludedSegmentPostTargets]);

  const flattenedOptions = audienceOptions.flatMap((option) => {
    if (option.subItems) {
      return [option, ...option.subItems];
    }
    return option;
  });

  const totalRecipients = postDeliveryData?.target_subscriptions || 0;

  const totalRecipientsHeader = emailPostSendTargets.length > 0 && `${totalRecipients.toLocaleString()} recipients`;

  return (
    <div className={display ? '' : 'hidden'}>
      <Card className="w-full mx-auto flex flex-col gap-6 max-w-[40rem] rounded-t-none border-t-0 rounded-b-none border-b-0">
        <Typography token="font-medium/text/base">
          Email Audience{' '}
          <Typography token="font-normal/text/base" colorWeight="500" className="ml-2">
            {totalRecipientsHeader}
          </Typography>
        </Typography>
        <Typography token="font-medium/text/sm">Default audiences</Typography>
        <div className="flex flex-col gap-3">
          {flattenedOptions.map((option) => {
            const isChecked = checkedItems.includes(option.value as string);

            return (
              <div
                className={cx('flex w-full', {
                  'pl-4': option.value !== PostTargetTier.FREE && option.value !== PostTargetTier.PREMIUM,
                })}
              >
                <Checkbox
                  id={`email-audience-${option.value}`}
                  key={option.value}
                  name={option.value as string}
                  disabled={postSent}
                  checked={isChecked}
                  onChange={onDefaultAudienceChange}
                  color="tertiary"
                />
                <label
                  htmlFor={`email-audience-${option.value}`}
                  className="text-gray-700 text-sm font-normal w-full flex ml-2 mt-1"
                >
                  <Typography token="font-medium/text/sm" colorWeight="700">
                    {option.label}
                  </Typography>
                  <Typography token="font-normal/text/sm" colorWeight="600" className="ml-1">
                    ({option.subtitle})
                  </Typography>
                </label>
              </div>
            );
          })}
          {!segmentsEnabled && (
            <Tooltip
              id="segments-upgrade-tooltip"
              tooltipClass="text-center"
              text="Segment Sends is part of the Scale plan."
              showIcon={false}
              autoWidth={false}
            >
              <div className="shadow-xs inline-flex gap-x-2 items-center relative group bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-surface-900 font-medium w-full mt-6">
                <span>Include and exclude segments</span>
                <div className="ml-auto items-center relative group">
                  <Button Icon={SparklesIcon} onClick={() => navigate('/settings/billing')} className="w-fit">
                    Upgrade to Scale
                  </Button>
                </div>
              </div>
            </Tooltip>
          )}
          {segmentsEnabled && (
            <Card className="mt-6 w-full mx-auto flex flex-col gap-6 rounded-lg max-w-[40rem]">
              <div className="flex">
                <TypographyStack>
                  <Typography token="font-medium/text/base">Include and exclude segments</Typography>
                </TypographyStack>
                <Switch
                  name="show-include-exclude-segments"
                  checked={
                    showIncludeExcludeSegments ||
                    ((includedSegmentPostTargets.length > 0 || excludedSegmentPostTargets.length > 0) && postSent)
                  }
                  onChange={toggleShowIncludeExcludeSegments}
                  variant="primary"
                  showIcons={false}
                  className="ml-auto"
                  disabled={postSent}
                />
              </div>

              {(showIncludeExcludeSegments ||
                (postSent && (includedSegmentPostTargets.length > 0 || excludedSegmentPostTargets.length > 0))) && (
                <>
                  <Multiselect
                    badgeClassName="border !border-[#DDD6FE] !bg-[#F5F3FF]"
                    badgeTextClassName="!px-0 !py-0"
                    badgeLabelClassName="text-[#5B21B6]"
                    badgeSubtitleClassName="text-[#7C3AED]"
                    emptyLabel="Search and select"
                    shouldCloseOnSelection
                    labelText="Included segments"
                    placeholderText="Search and select"
                    name="email-included-segments"
                    showClearAll={false}
                    disabled={postSent}
                    showValuesWhenMenuOpen
                    onDeselect={handleDeselectEmailSegment}
                    onSearchQueryChange={(query) => setEmailIncludeSegmentsQuery(query)}
                    onSearch={() => {
                      return new Promise((resolve) => {
                        resolve(includedOptionsSegments);
                      });
                    }}
                    onDeselectAll={() => {}}
                    onSelect={async (name, value) => {
                      await createEmailPostTarget({
                        params: {
                          action: PostTargetAction.INCLUDE,
                          receiver_type: PostTargetReceiverType.SEGMENT,
                          receiver_id: value,
                        },
                      });
                    }}
                    values={
                      includedSegmentPostTargets.map((target) => {
                        return {
                          value: target.receiver_id,
                          label: target.receiver_display_name,
                          subtitle: `${target.num_active_subscribers.toLocaleString()} subscribers`,
                        };
                      }) || []
                    }
                  />
                  <Multiselect
                    badgeClassName="border !border-[#DDD6FE] !bg-[#F5F3FF]"
                    badgeTextClassName="!px-0 !py-0"
                    badgeLabelClassName="text-[#5B21B6]"
                    badgeSubtitleClassName="text-[#7C3AED]"
                    emptyLabel="Search and select"
                    shouldCloseOnSelection
                    labelText="Excluded segments"
                    placeholderText="Search and select"
                    name="email-excluded-segments"
                    showClearAll={false}
                    disabled={postSent}
                    showValuesWhenMenuOpen
                    onDeselect={handleDeselectEmailSegment}
                    onSearchQueryChange={(query) => setEmailExcludeSegmentsQuery(query)}
                    onSearch={() => {
                      return new Promise((resolve) => {
                        resolve(excludedOptionsSegments);
                      });
                    }}
                    onDeselectAll={() => {}}
                    onSelect={async (name, value) => {
                      await createEmailPostTarget({
                        params: {
                          action: PostTargetAction.EXCLUDE,
                          receiver_type: PostTargetReceiverType.SEGMENT,
                          receiver_id: value,
                        },
                      });
                    }}
                    values={
                      excludedSegmentPostTargets.map((target) => ({
                        value: target.receiver_id,
                        label: target.receiver_display_name,
                        subtitle: `${target.num_active_subscribers.toLocaleString()} subscribers`,
                      })) || []
                    }
                  />
                  <Button
                    onClick={() => window.open('/segments', '_blank', 'rel=noopener noreferrer')}
                    type="button"
                    variant="flush"
                    size="sm"
                    className="w-fit !p-0 !bg-0 hover:!bg-white"
                  >
                    <Typography
                      token="font-medium/text/xs"
                      color="secondary"
                      colorWeight="600"
                      className="underline cursor-pointer"
                    >
                      Manage segments
                    </Typography>
                  </Button>
                </>
              )}
            </Card>
          )}
        </div>
      </Card>
    </div>
  );
};

export default EmailAudience;
