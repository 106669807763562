import toast from 'react-hot-toast';
import { NodeViewProps } from '@tiptap/core';

import { authors } from '../../../dummyData/authors';
import { SelectionItem, SelectionModal, useSelectionModal } from '../../../SelectionModal';

const AuthorsSelectionModal = ({ editor, node }: Partial<NodeViewProps>) => {
  const { isOpen, onOpen, onClose, searchTerm, setSearchTerm, selections, setSelections } = useSelectionModal<any>();

  const handleSubmit = () => {
    const updatedAttributes = {
      ...node?.attrs, // Spread the existing attributes to ensure they aren't lost
      data: {
        authors: selections,
      },
    };

    editor?.chain().focus().updateAttributes('authors', updatedAttributes).run();

    toast.success('Authors selected');
    onClose();
  };

  return (
    <SelectionModal
      title="Select Authors"
      buttonText="Select Authors"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSubmit={handleSubmit}
      showingCount={selections.length}
      totalCount={authors.length}
      selectedCount={selections.length}
      isLoading={false}
      isFetching={false}
      hasNextPage={false}
      onLoadMore={() => {}}
    >
      {authors.map((author) => {
        const isSelected = Boolean(selections.some((p) => p.id === author.id));

        return (
          <SelectionItem
            key={author.id}
            onSelect={() => {
              if (!isSelected) {
                setSelections([...selections, author]);
              } else {
                setSelections(selections.filter((p) => p.id !== author.id));
              }
            }}
            title={author.name}
            description={author.name}
            isSelected={isSelected}
          >
            {null}
          </SelectionItem>
        );
      })}
    </SelectionModal>
  );
};

export default AuthorsSelectionModal;
