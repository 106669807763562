import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ImageElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';

export const ImageBlockView: FC<NodeViewProps> = ({ node, updateAttributes }) => {
  const { onDragStart } = useReactNodeView();
  const { publicationId } = usePublicationContext();
  // const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const {
    id,
    src,
    alt,
    width,
    height,
    borderColor = undefined,
    borderStyle = undefined,
    align = undefined,
  } = node.attrs;

  useEffect(() => {
    if (id && !src) {
      // setLoading(true);

      API.getAsset({
        assetId: id,
        publicationId,
      })
        .then((res) => {
          updateAttributes({
            src: res.data.url,
            title: res.data.title || '',
            alt: res.data.alt || '',
          });
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
      // .finally(() => setLoading(false));
    }
  }, [id, src, publicationId, updateAttributes]);

  useEffect(() => {
    if (src) {
      // append the base URL to the src
      setImage(`${window?.env?.REACT_APP_API_BASE_URL}${src}`);
    }
  }, [src]);

  return (
    <ImageElement
      element={{
        type: 'imageBlock',
        id: node.attrs.id,
        attrs: {
          id: node.attrs.id,
          src: image || undefined,
          alt: alt || undefined,
          height: height || undefined,
          width: width || undefined,
          borderColor: borderColor || undefined,
          borderStyle: borderStyle || undefined,
          align: align || undefined,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      {null}
    </ImageElement>
  );
};
