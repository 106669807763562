const SECTION_CATEGORIES = [
  {
    value: 'heroes',
    label: 'Heroes',
  },
];

const BLOCK_CATEGORIES = [
  {
    value: 'posts',
    label: 'Posts',
  },
];

export { BLOCK_CATEGORIES, SECTION_CATEGORIES };
