import { useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import { Select } from '@/components/Form';
import useTestSvixEndpoint from '@/hooks/webhooks/useTestSvixEndpoint';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { Button } from '@/ui/Button';

interface Props {
  endpoint: WebhookEndpoint;
  publicationId: string;
}

const SendTestMessage = ({ endpoint, publicationId }: Props) => {
  const [selectedEventType, setSelectedEventType] = useState<string>(endpoint.event_types[0]);

  const testEndpoint = useTestSvixEndpoint(publicationId, {
    endpointId: endpoint.id,
  });

  const handleTestWebhook = async (eventType: string) => {
    try {
      await testEndpoint.mutateAsync({ eventType });
      toast.success('Test webhook sent!');
    } catch (error) {
      toast.error('Failed to send test webhook!');
    }
  };

  return (
    <div className="flex flex-col gap-y-4 p-6 bg-surface-50 border border-surface-200 rounded-md">
      <Select
        name="testEventType"
        labelText="Send Test Events"
        options={endpoint.event_types.map((eventType) => ({
          label: eventType,
          value: eventType,
        }))}
        value={selectedEventType}
        onSelect={(_: string, value: string) => setSelectedEventType(value)}
      />
      <div className="flex flex-row w-full justify-end">
        <Button
          onClick={() => handleTestWebhook(selectedEventType)}
          disabled={testEndpoint.isLoading}
          className="w-fit"
          Icon={ArrowUpRightIcon}
          iconRight
          size="xs"
        >
          {testEndpoint.isLoading ? 'Sending...' : 'Send Test'}
        </Button>
      </div>
    </div>
  );
};

export default SendTestMessage;
