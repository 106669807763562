import { Copy, FileArrowDown, Trash } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { useCallback, useState } from 'react';

import { Button } from '../../UI/Button';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';
import { TemplateModal } from '../Templates';

import { AlignmentSettings } from './components/AlignmentSettings';
import { AuthorsWidgetSettings } from './components/AuthorsWidgetSettings';
import { BlockTypeSettings } from './components/BlockTypeSettings';
import { ButtonStyleSettings } from './components/ButtonStyleSettings';
import { ColorSettings } from './components/ColorSettings';
import { ColumnSettings } from './components/ColumnSettings';
import { ColumnsSettings } from './components/ColumnsSettings';
import { ContainerSettings } from './components/ContainerSettings';
// import { Debug } from './components/Debug';
import { FontSettings } from './components/FontSettings';
import { FontStyleSettings } from './components/FontStyleSettings';
import { FormatSettings } from './components/FormatSettings';
import { HighlightSettings } from './components/HighlightSettings';
import { ImageAltSettings } from './components/ImageAltSettings';
import { ImageSrcSettings } from './components/ImageSrcSettings';
import { LinkToSettings } from './components/LinkToSettings';
import { NewTabSettings } from './components/NewTabSettings';
import { PostSettings } from './components/PostsSettings';
import { SectionSettings } from './components/SectionSettings';
import { SignupWidgetSettings } from './components/SignupWidgetSettings';
import { SurveyWidgetSettings } from './components/SurveyWidgetSettings';
import SectionRenderer from './helpers/SectionRenderer';
import { RenderCondition } from './RenderCondition';

export const AttributesPanel = ({ editor }: { editor: Editor }) => {
  const hasLink = editor.isActive('link');
  const onRemoveLink = useCallback(() => editor.chain().focus().unsetLink().run(), [editor]);

  const preventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const activeNodeResult = useActiveNode(editor);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const onRemove = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const nodeType = activeNodeResult.activeNodeType;
      if (nodeType) {
        editor.chain().focus().deleteSelection().run();
      }
    },
    [editor, activeNodeResult.activeNodeType]
  );

  const onCopy = useCallback(() => {
    // TODO: copy selection not implemented

    console.log(editor.state.selection);
  }, [editor]);

  const onTemplate = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setTemplateModalOpen(true);
    },
    [setTemplateModalOpen]
  );

  return (
    <div className="flex flex-col p-3" onClick={preventPropagation} role="none">
      <SectionRenderer
        title="Block Type"
        actions={
          <div className="flex gap-2">
            <Button
              variant="secondary"
              size="sm"
              LeftIcon={FileArrowDown}
              className="text-wb-secondary"
              onClick={onTemplate}
            />
            <Button variant="secondary" size="sm" LeftIcon={Trash} className="text-wb-secondary" onClick={onRemove} />
            <Button variant="secondary" size="sm" LeftIcon={Copy} className="text-wb-secondary" onClick={onCopy} />
            {activeNodeResult.activeNode && (
              <TemplateModal
                isOpen={templateModalOpen}
                onClose={() => setTemplateModalOpen(false)}
                node={activeNodeResult.activeNode}
              />
            )}
          </div>
        }
      >
        <BlockTypeSettings editor={editor} />
      </SectionRenderer>

      <RenderCondition editor={editor} allowedNodeTypes={['paragraph', 'heading', 'button']}>
        <SectionRenderer title="Typography">
          <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
          <FormatSettings editor={editor} />
          <FontSettings editor={editor} />
          <FontStyleSettings editor={editor} />
          <ColorSettings
            title="Text"
            property="color"
            editor={editor}
            activeNodeResult={activeNodeResult}
            isMarkStyle
          />
          <HighlightSettings editor={editor} />
        </SectionRenderer>
      </RenderCondition>

      <SurveyWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
      <AuthorsWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
      <PostSettings editor={editor} />
      <SignupWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
      <SectionSettings editor={editor} activeNodeResult={activeNodeResult} />
      <ContainerSettings editor={editor} activeNodeResult={activeNodeResult} />
      <ColumnsSettings editor={editor} activeNodeResult={activeNodeResult} />
      <ColumnSettings editor={editor} activeNodeResult={activeNodeResult} />

      <RenderCondition editor={editor} allowedNodeTypes={['imageBlock']}>
        <SectionRenderer title="Media">
          <ImageSrcSettings editor={editor} />
          <ImageAltSettings editor={editor} />
        </SectionRenderer>
      </RenderCondition>

      <RenderCondition editor={editor} allowedNodeTypes={['button']}>
        <SectionRenderer title="Style">
          <ButtonStyleSettings editor={editor} activeNodeResult={activeNodeResult} />
        </SectionRenderer>
      </RenderCondition>

      {hasLink && (
        <SectionRenderer title="Link">
          <Button variant="ghost" size="sm" className="text-wb-accent" onClick={onRemoveLink}>
            Remove
          </Button>
          <LinkToSettings editor={editor} />
          <NewTabSettings editor={editor} />
        </SectionRenderer>
      )}

      {/* <Debug editor={editor} /> */}
    </div>
  );
};
