import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { BillingActions } from '../../interfaces/billing';
import api from '../../services/swarm';

interface ApiResponse {
  stripe_session_url: string;
}

interface Props {
  organizationId: string;
  publicationId: string;
  action: BillingActions;
  redirectToBillingHome: Function;
  plan?: string;
  planInterval?: string;
}

const useStripeApi = ({ organizationId, publicationId, action, redirectToBillingHome, plan, planInterval }: Props) => {
  return useMutation<ApiResponse, unknown>(
    () =>
      api
        .post(`/organizations/${organizationId}/stripe_api`, {
          publication_id: publicationId,
          stripe_api: {
            action,
            plan,
            plan_interval: planInterval,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        redirectToBillingHome();
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useStripeApi;
