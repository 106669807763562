import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import { Input, Switch } from '@/components/Form';
import { useOrganization, useUpdateEmailMessage, useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { Button } from '@/ui/Button';
import { isValidUrl } from '@/utils';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';
import FormRow from '../components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const PresetForm = ({ publication }: Props) => {
  const navigate = useNavigate();

  const { handleChangeInAttribute, settings, savingAttribute, setSavingAttribute } = usePublicationSettings();
  const { data: welcomeEmail } = useWelcomeEmail(publication.id);
  const { data: organization } = useOrganization(publication.organization_id, publication.id);
  const queryClient = useQueryClient();

  const { mutateAsync: updateEmailMessage } = useUpdateEmailMessage(publication.id, {
    emailMessageId: welcomeEmail?.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['welcome_email', publication.id], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', welcomeEmail?.id], { exact: true });
      queryClient.invalidateQueries(['publications', publication.id]);
      toast.success('Welcome Email saved!');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });

  const isContentSet = !!(welcomeEmail?.preview_text && welcomeEmail?.subject_line);
  const isVerified = Boolean(organization?.verified_at);

  return (
    <BodyContainer>
      <CardHeader title="Preset Emails" description="Configure new subscriber triggered emails" />
      <FormRow
        label="Welcome Email"
        helperText="Enable a welcome email that is sent to new subscribers"
        tip={
          !isVerified ? 'Welcome emails will only begin sending once your publication has been verified!' : undefined
        }
        cta={
          <Button
            size="xs"
            type="button"
            variant="primary-inverse"
            Icon={ArrowUpRightIcon}
            iconRight
            onClick={() =>
              navigate(
                appendSettingsPublicationId(
                  isContentSet
                    ? '/settings/publication/emails/welcome_email'
                    : '/settings/publication/emails/welcome_email/edit',
                  publication.id
                )
              )
            }
          >
            {isContentSet ? 'View Welcome Email' : 'Configure Welcome Email'}
          </Button>
        }
      >
        <Switch
          variant="primary"
          checked={welcomeEmail?.active || false}
          name="active"
          onChange={async (_name: string, value: boolean) => {
            try {
              setSavingAttribute('email_message.active');
              await updateEmailMessage({ active: value });
            } catch (error: any) {
              // do nothing
            }

            setSavingAttribute(null);
          }}
          disabledText={!isContentSet ? 'Please configure Welcome Email first' : undefined}
          disabled={savingAttribute === 'email_message.active' || !isContentSet}
        />
      </FormRow>
      <FormRow
        label="Double Opt in Email"
        helperText="Require new subscribers to confirm their email address before adding them to your list. This will not impact your existing subscribers or subscriber imports"
        cta={
          <Button
            size="xs"
            type="button"
            variant="primary-inverse"
            Icon={ArrowUpRightIcon}
            iconRight
            onClick={() =>
              navigate(appendSettingsPublicationId('/settings/publication/emails/opt_in_email', publication.id))
            }
          >
            View Opt In Email
          </Button>
        }
      >
        <Switch
          variant="primary"
          checked={publication.double_opt_required}
          name="double_opt_required"
          onChange={(_name: string, value: boolean) => {
            handleChangeInAttribute('double_opt_required')(value);
          }}
          disabled={savingAttribute === 'double_opt_required'}
        />
      </FormRow>
      <FormRow
        label="Smart Nudge"
        helperText="Enable to send a reminder email after 48 hours if a subscriber has not confirmed their email address. "
      >
        <Switch
          variant="primary"
          checked={publication.auto_resend_opt_in}
          name="auto_resend_opt_in"
          onChange={(_name: string, value: boolean) => {
            handleChangeInAttribute('auto_resend_opt_in')(value);
          }}
          disabled={savingAttribute === 'auto_resend_opt_in'}
        />
      </FormRow>
      {settings?.opt_in_email_editor && publication.double_opt_required && (
        <Input
          leadingText="https://"
          labelText="Opt in Redirect URL"
          helperText="Set the url you want a subscriber to be redirected to after confirming their subscription (if not set, they will be redirected to your beehiiv homepage by default)"
          placeholder="johnsnewsletter.beehiiv.com"
          name="opt_in_redirect_url"
          defaultValue={publication.opt_in_redirect_url}
          onBlur={(e) => {
            const newValue = e.target.value;
            if (isValidUrl(newValue)) {
              handleChangeInAttribute('opt_in_redirect_url')(newValue);
            } else {
              toast.error('Please enter a valid URL');
            }
          }}
          disabled={savingAttribute === 'opt_in_redirect_url'}
        />
      )}
    </BodyContainer>
  );
};

export default PresetForm;
