import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import CardHeader from '@/routes/settings/publication/components/CardHeader';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

import { BillingDetails } from '../../../../../interfaces/billing';

import Body from './PlanCard/Body';
import Header from './PlanCard/Header';

interface Props {
  billingDetails: BillingDetails | undefined;
  onCancel: () => void;
  onNextStep: () => void;
  isLoading: boolean;
}

const Review = (props: Props) => {
  const { billingDetails, onCancel, onNextStep, isLoading } = props;

  const currentPlan = {
    name: billingDetails?.current_plan.name || '',
    max_subscriptions: billingDetails?.organization_max_subscriptions || 0,
    max_publications: billingDetails?.current_plan.name === PLAN.MAX ? 10 : 3,
  };

  const launchPlan = { name: 'Launch', max_subscriptions: 2500, max_publications: 3 };

  return (
    <div className="flex flex-col gap-10">
      <CardHeader
        title="Review your plan changes"
        description="Here's what to expect at the end of your billing period."
      />

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        {billingDetails && (
          <Header
            billingDetails={billingDetails}
            currentPlan={currentPlan}
            newPlan={launchPlan}
            renderTopRowArrow={false}
          />
        )}
      </SkeletonLoader>

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        {billingDetails && <Body billingDetails={billingDetails} currentPlan={currentPlan} />}
      </SkeletonLoader>

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        <div className="flex flex-col gap-y-2 gap-x-2 justify-end sm:flex-row">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>
          <Button variant="primary" loading={isLoading} onClick={onNextStep}>
            Continue
          </Button>
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default Review;
