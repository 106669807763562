import LoadingBox from "@/components/LoadingBox";
import { usePublication } from "@/hooks/usePublications";

import PageContainer from "../components/PageContainer";
import { usePublicationSettings } from "../context";

import Feeds from "./Feeds";

const PublicationRSSSendFeedSettings: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Feeds publicationId={currentPublicationId} />
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationRSSSendFeedSettings;
