import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { OrganizationsUpgradeAnalytics } from '@/interfaces/organizations/upgrade_analytics';
import api from '@/services/swarm';

interface Props {
  shouldFetch?: boolean;
  signature?: string | null;
}

const useFetchUpgradeAnalytics = ({shouldFetch = true, signature}: Props) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<OrganizationsUpgradeAnalytics>(
    ['organizations', publicationId, 'upgrade_analytics'],
    () =>
      api
        .get(`/organizations/upgrade_analytics`, {
          params: {
            publication_id: publicationId,
            signature_jwt: signature,
          },
        })
        .then((res) => res.data),
    {
      cacheTime: 0, // Don't cache this query
      enabled: shouldFetch,
      onError: (err: any) => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong fetching upgrade analytics: ', err?.response?.data?.message);
      },
    }
  );
};

export default useFetchUpgradeAnalytics;
