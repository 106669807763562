import { FC, useEffect, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { Placement } from 'tippy.js';

import Form from '@/components/_domain/ExternalRSSFeedForm';
import Badge from '@/components/Badge';
import SlideOver from '@/components/SlideOver';
import { capitalize } from '@/utils';

import { Button } from '../../components/ui/Button';
import { Icon } from '../../components/ui/Icon';
import { SearchList, SearchListItemProps } from '../../components/ui/SearchList';
import { API } from '../../lib/api';
import { usePublicationContext } from '../../lib/context/PublicationContext';

import { Styled } from './RssView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

const entryLayoutMap = {
  '1-col': 'grid-cols-1',
  '2-col': 'grid-cols-2',
  '3-col': 'grid-cols-3',
  '4-col': 'grid-cols-4',
};

const formatOptions = (newOptions: any) => {
  const mappedOptions = newOptions.map((option: any) => {
    return {
      id: option.id,
      name: `${option.name} (${option.url})`,
    };
  });

  return mappedOptions;
};

const extractFirstImage = (htmlString: string) => {
  const match = htmlString.match(/<img[^>]+src="([^">]+)"/);
  return match ? match[1] : null;
};

export const RssView: FC<NodeViewProps> = ({ editor, node, updateAttributes }) => {
  const { publicationId } = usePublicationContext();
  const {
    id: externalRssFeedId,
    data,
    settings,
    entryLayout,
  } = node.attrs as {
    id: string;
    data: any;
    settings: any;
    entryLayout: '1-col' | '2-col' | '3-col' | '4-col';
  };
  const [options, setOptions] = useState([]);
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(true);
  const [newExternalRSSFeedMenuOpen, setNewExternalRSSFeedMenuOpen] = useState(false);

  const onSearch = useMemo(() => {
    return debounce((q: string) => {
      API.getExternalRssFeeds({ publicationId }).then((res) => {
        setOptions(
          formatOptions(
            res.data.external_rss_feeds.filter((feed: any) => feed.name.toLowerCase().includes(q.toLowerCase()))
          )
        );
      });
    }, 500);
  }, [publicationId]);

  const renderCategories = (categories: string[] | string) => {
    if (categories && Array.isArray(categories)) {
      return categories.map((category) => (
        <Badge key={category} type="info_blue" size="md">
          {capitalize(category)}
        </Badge>
      ));
    }

    return (
      <Badge type="info_blue" size="md">
        {capitalize(categories)}
      </Badge>
    );
  };

  useEffect(() => {
    if (externalRssFeedId && Object.keys(data).length === 0) {
      API.getExternalRssFeed({ publicationId, externalRssFeedId }).then((response) => {
        updateAttributes({
          ...node.attrs,
          data: response.data,
        });
      });
    }
  }, [publicationId, externalRssFeedId, node.attrs, data, updateAttributes]);

  useEffect(() => {
    if (selectionMenuOpen) {
      onSearch('');
    }
  }, [selectionMenuOpen, onSearch]);

  const closeExternalRssFeedMenu = () => {
    setNewExternalRSSFeedMenuOpen(false);
  };

  const closeSelectionMenu = () => {
    setSelectionMenuOpen(false);
  };

  return (
    <NodeViewWrapper data-id={externalRssFeedId} data-drag-handle>
      {Object.keys(data).length > 0 && data.content ? (
        <div className={cx('grid', entryLayoutMap[entryLayout], 'gap-8')}>
          {data.content.slice(0, settings.entriesToShow).map((item: any) => {
            const articleThumbnail = item.thumbnail || (item.content && extractFirstImage(item.content));

            return (
              <NodeViewWrapper key={item.id} data-id={item.id} data-drag-handle>
                <div className="flex flex-col items-start gap-2">
                  {settings.displayTitle && settings.displayTitleAboveThumbnail && (
                    <h2 className="!mt-0">{item.title}</h2>
                  )}
                  {settings.displayThumbnail && articleThumbnail && !settings.displayContent && (
                    <img className="w-full h-48 object-cover" src={articleThumbnail} alt={item.title} />
                  )}
                  {settings.displayCategories && item.categories && (
                    <div className="flex flex-row gap-3 flex-wrap">{renderCategories(item.categories)}</div>
                  )}
                  {settings.displayTitle && !settings.displayTitleAboveThumbnail && (
                    <h2 className="!mt-0">{item.title}</h2>
                  )}
                  {settings.displayAuthor && <p>by {item.author}</p>}
                  {settings.displayDescription && <blockquote dangerouslySetInnerHTML={{ __html: item.description }} />}
                  {settings.displayContent && <div dangerouslySetInnerHTML={{ __html: item.content }} />}
                  {settings.displayCta && (
                    <Styled.Button href={item.link} target="_blank" className="content-element-button">
                      {settings.ctaText || 'View Article'}
                    </Styled.Button>
                  )}
                </div>
              </NodeViewWrapper>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center p-8">
          <SlideOver
            bodyId="new-external-rss-feed"
            headerText="Add RSS Feed"
            isOpen={newExternalRSSFeedMenuOpen}
            onClose={closeExternalRssFeedMenu}
          >
            <Form
              publicationId={publicationId}
              onSuccess={closeExternalRssFeedMenu}
              onCancel={closeExternalRssFeedMenu}
              slideOverBodyId="new-external-rss-feed"
            />
          </SlideOver>
          <Tippy
            offset={[0, 8]}
            placement="bottom"
            interactive
            visible={selectionMenuOpen}
            onHidden={closeSelectionMenu}
            onClickOutside={closeSelectionMenu}
            render={(attrs: TippyProps) =>
              selectionMenuOpen ? (
                <SearchList
                  tabIndex={-1}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...attrs}
                  items={options.map((option: any) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  searchable
                  searchPlaceholder="Search an RSS Feed"
                  onSearch={(q: string) => {
                    onSearch(q);
                  }}
                  onSelect={(item: SearchListItemProps) => {
                    editor.chain().focus().updateAttributes('rss', { id: item.value }).run();
                  }}
                  onBack={() => {
                    setSelectionMenuOpen(false);
                  }}
                  footer={
                    <div className="space-y-4">
                      <Button
                        $variant="primary"
                        $size="small"
                        $rightSlot={<Icon name="RSS" />}
                        $fullWidth
                        as="a"
                        onClick={() => {
                          setNewExternalRSSFeedMenuOpen(true);
                        }}
                      >
                        Add RSS Feed
                      </Button>
                    </div>
                  }
                />
              ) : null
            }
          >
            <Button
              $variant="secondary"
              $size="small"
              $active={selectionMenuOpen}
              $leftSlot={<Icon name="RSS" />}
              onClick={() => {
                setSelectionMenuOpen(!selectionMenuOpen);
              }}
            >
              Select an RSS Feed
            </Button>
          </Tippy>
        </div>
      )}
    </NodeViewWrapper>
  );
};
