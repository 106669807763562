import { useCallback } from 'react';
import { ArrowsVertical } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

const GAP_OPTIONS = [
  {
    label: '0px',
    value: '0px',
  },
  {
    label: '8px',
    value: '8px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '24px',
    value: '24px',
  },
  {
    label: '32px',
    value: '32px',
  },
];

type Props = AttributeSettingProps & {
  property: string;
  title: string;
};

export const GapSettings = ({ editor, activeNodeResult, property, title = 'Gap' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue = GAP_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label || '0px';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={ArrowsVertical}>
      <div className="flex flex-col gap-2">
        {GAP_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
