import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { MediaLibrary } from '@/components/MediaLibrary';
import API from '@/components/TiptapEditor/lib/api';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useContentTags } from '@/hooks';
import useGuestAuthors from '@/hooks/useGuestAuthors';
import useUsers from '@/hooks/useUsers';

import { usePostContext } from '../PostContext';

import CreateGuestAuthorModal from './AuthorsMenu/CreateGuestAuthorModal';
import AuthorsMenu from './AuthorsMenu';
import { ContentTagsProvider } from './ContentTagsProvider';
import Subtitle from './Subtitle';
import Thumbnail from './Thumbnail';
import Title from './Title';

interface Props {
  onReady: () => void;
}

const PostMeta = ({ onReady }: Props) => {
  const { formData, onChange } = usePostContext();
  const [showCreateGuestAuthorModal, setShowCreateGuestAuthorModal] = useState(false);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const thumbnailFileRef = useRef<HTMLInputElement>(null);
  const [publicationId] = useCurrentPublicationState();
  const contentTagsQuery = useContentTags({ search: '', amountPerPage: 100 });
  const {
    data: contentTagsResp,
    refetch: refetchContentTags,
    isLoading: isLoadingContentTags,
    hasNextPage: hasNextPageContentTags,
    fetchNextPage: fetchNextPageContentTags,
    isFetchingNextPage: isfetchingNextPageContentTags,
  } = contentTagsQuery;
  const { data: users, isLoading: isLoadingUsers, isError: isErrorUsers } = useUsers();
  const {
    data: guestAuthors,
    isLoading: isLoadingGuestAuthors,
    isError: isErrorGuestAuthors,
    refetch: refetchGuestAuthors,
  } = useGuestAuthors();
  const contentTags = contentTagsResp?.pages.flatMap((page) => page.contentTags).filter(Boolean) || [];
  const usersEditable = !isLoadingUsers && !isErrorUsers && formData && formData?.user_ids.length > 0;
  const guestAuthorsEditable =
    !isLoadingGuestAuthors && !isErrorGuestAuthors && formData && formData?.guest_author_ids.length > 0;

  const uploadThumbnail = (file: File) => {
    if (isThumbnailUploading) return;

    setIsThumbnailUploading(true);

    API.uploadPublicationAsset({
      file,
      publicationId,
    })
      .then(async (res) => {
        const { id } = res.data;

        onChange({ thumbnail_id: id, thumbnail: res.data });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsThumbnailUploading(false));
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      uploadThumbnail(file);
    }
  };

  const onThumbnailReady = () => {
    setThumbnailLoaded(true);
  };

  useEffect(() => {
    if (
      !isLoadingGuestAuthors &&
      !isLoadingUsers &&
      !isLoadingContentTags &&
      (thumbnailLoaded || !formData?.thumbnail?.url)
    ) {
      onReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGuestAuthors, isLoadingUsers, isLoadingContentTags, thumbnailLoaded]);

  return (
    <>
      <div className="relative mx-auto mt-6 max-w-[40rem]">
        {formData?.thumbnail?.url && (
          <Thumbnail
            onLoad={onThumbnailReady}
            thumbnailFileRef={thumbnailFileRef}
            setShowImageLibrary={setShowImageLibrary}
          />
        )}
        <ContentTagsProvider
          refetchContentTags={refetchContentTags}
          hasNextPage={hasNextPageContentTags}
          fetchNextPage={fetchNextPageContentTags}
          isfetchingNextPage={isfetchingNextPageContentTags}
          contentTags={contentTags}
        >
          <Title
            guestAuthors={guestAuthors}
            setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
            setShowImageLibrary={setShowImageLibrary}
            thumbnailFileRef={thumbnailFileRef}
            users={users}
          />
        </ContentTagsProvider>
        <Subtitle />
        {(usersEditable || guestAuthorsEditable) && (
          <div className="mt-6">
            <AuthorsMenu
              users={users || []}
              guestAuthors={guestAuthors?.guest_authors || []}
              setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
            />
          </div>
        )}
      </div>
      <CreateGuestAuthorModal
        isOpen={showCreateGuestAuthorModal}
        onClose={() => setShowCreateGuestAuthorModal(false)}
        onSuccess={() => {
          refetchGuestAuthors();
          setShowCreateGuestAuthorModal(false);
        }}
      />
      <input
        className="w-0 h-0 overflow-hidden"
        onInput={onFileChange}
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.gif"
        ref={thumbnailFileRef}
      />
      <MediaLibrary
        onClose={() => setShowImageLibrary(false)}
        isOpen={showImageLibrary}
        onMediaSelect={async (payload) => {
          await onChange({ thumbnail_id: payload.media.id, thumbnail: payload.media });
          setShowImageLibrary(false);
        }}
        publicationId={publicationId}
      />
    </>
  );
};

export default PostMeta;
