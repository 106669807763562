import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { survey } from '../../dummyData/survey';

import { SurveyView } from './views/SurveyView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    survey: {
      setSurvey: () => ReturnType;
    };
  }
}

export const Survey = Node.create<{}, {}>({
  name: 'survey',

  group: 'block',

  draggable: true,

  selectable: true,

  addAttributes() {
    return {
      gap: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-gap') || '0px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      height: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
      },
      width: {
        default: '700px',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: '#e0e0e0',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      borderRadius: {
        default: '4px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      accentColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-accent-color'),
        renderHTML: (attributes) => ({ 'data-accent-color': attributes.accentColor }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      data: {
        default: survey,
      },
    };
  },

  addCommands() {
    return {
      setSurvey:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SurveyView);
  },
});

export default Survey;
