export type PermissionLevel = 'read' | 'create' | 'update' | 'delete';
export type PermissionResource =
  | 'api_key'
  | 'automation'
  | 'billing'
  | 'content_import'
  | 'data_export'
  | 'domain_setting'
  | 'external_embed'
  | 'organization'
  | 'publication'
  | 'posts/schedule'
  | 'posts/test_sends/custom_recipients'
  | 'subscriber'
  | 'views/automations/overview'
  | 'views/dashboard/audience'
  | 'views/dashboard/engagement'
  | 'views/dashboard/monetization'
  | 'views/dashboard/subscription_chart'
  | 'views/nav/analyze/reports'
  | 'views/nav/audience/automations'
  | 'views/nav/audience/polls'
  | 'views/nav/audience/segments'
  | 'views/nav/audience/subscribe_forms'
  | 'views/nav/audience/subscribers'
  | 'views/nav/audience/surveys'
  | 'views/nav/grow/boosts'
  | 'views/nav/grow/magic_links'
  | 'views/nav/grow/recommendations'
  | 'views/nav/grow/referral_program'
  | 'views/nav/monetize/ads'
  | 'views/nav/monetize/boosts'
  | 'views/nav/monetize/subscriptions'
  | 'views/nav/settings'
  | 'views/nav/share_and_earn'
  | 'views/nav/wallet'
  | 'views/settings/billing'
  | 'views/settings/company'
  | 'views/settings/integrations'
  | 'views/settings/payment_accounts'
  | 'views/settings/publication'
  | 'views/settings/web_builder'
  | 'web_template'
  | 'web_theme';

export type Permissions = {
  [key: string]: any;
};

export const NO_PERMISSION_MESSAGE =
  "Your current role can't access this. Contact an admin to change your permissions.";

// This list is incomplete and should be updated as needed.
// It should be the namespaced class name of the model being checked.
export type PermissionResourceType = 'Automation' | 'PostTemplate';
