import { Editor } from '@tiptap/core';

import { useCurrentPublication } from '@/hooks';

import { useActiveNode } from '../../../extensions/ActiveNode/hooks/useActiveNode';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import DividerSettings from '../DividerSettings';
import { GapSettings } from '../GapSettings';
import { LineClampSettings } from '../LineClampSettings';
import OrientationSettings from '../OrientationSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleBorderRadiusSettings } from '../SimpleBorderRadiusSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';
import { TransformSettings } from '../TransformSettings';

import { StructureSettings } from './StructureSettings';
import { TimestampSettings } from './TimestampSettings';

export const PostSettings = ({ editor }: { editor: Editor }) => {
  const { data: currentPublication } = useCurrentPublication();

  const activeNodeResult = useActiveNode(editor);
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['post']}>
      <SectionRenderer title="Structure" defaultIsOpen={false}>
        <StructureSettings editor={editor} activeNodeResult={activeNodeResult} property="structure" />
      </SectionRenderer>

      <SectionRenderer title="Layout" defaultIsOpen={false}>
        <OrientationSettings editor={editor} activeNodePos={activeNodePos} />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <DividerSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Card" defaultIsOpen={false}>
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Background" property="background" editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Image" defaultIsOpen={false}>
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="imageEnabled" title="Enabled" />
      </SectionRenderer>

      <SectionRenderer title="Titles" defaultIsOpen={false}>
        <ColorSettings
          title="Title Color"
          property="titleTextColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <LineClampSettings
          title="Title Lines"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleLineClamp"
        />
        <SimpleFontSizeSettings
          title="Title Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleFontSize"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="subtitleShowing"
          title="Subtitle Enabled"
        />
        {activeNodeAttributes?.subtitleShowing && (
          <>
            <ColorSettings
              title="Subtitle Color"
              property="subtitleTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <LineClampSettings
              title="Subtitle Lines"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleLineClamp"
            />
            <SimpleFontSizeSettings
              title="Subtitle Size"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleFontSize"
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Tags" defaultIsOpen={false}>
        <ColorSettings
          title="Color"
          property="tagTextColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
          initialColor={activeNodeAttributes?.tagTextColor}
        />
        <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="tagTransform" />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="tagBadgeEnabled"
          title="Badge Enabled"
        />
        {activeNodeAttributes?.tagBadgeEnabled && (
          <>
            <ColorSettings
              title="Background"
              property="tagBackgroundColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.tagBackgroundColor}
            />
            <SimpleBorderRadiusSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="tagBorderRadius"
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Authors" defaultIsOpen={false}>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsEnabled"
          title="Enabled"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsImagesEnabled"
          title="Images"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTextEnabled"
          title="Text"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTruncateListEnabled"
          title="Truncate List"
        />
        {activeNodeAttributes?.authorsEnabled && (
          <>
            <ColorSettings
              title="Color"
              property="authorsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.authorsTextColor}
            />
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="authorsTextTransform" />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Timestamp" defaultIsOpen={false}>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="timestampsEnabled"
          title="Enabled"
        />
        {activeNodeAttributes?.timestampsEnabled && (
          <>
            <TimestampSettings editor={editor} activeNodeResult={activeNodeResult} property="timestampsDisplay" />
            <ColorSettings
              title="Color"
              property="timestampsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.timestampsTextColor}
            />
          </>
        )}
      </SectionRenderer>

      {currentPublication?.is_premium_enabled && (
        <SectionRenderer title="Premium" defaultIsOpen={false}>
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumIconEnabled"
            title="Icon"
          />
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumTextEnabled"
            title="Text"
          />
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumBadgeEnabled"
            title="Badge"
          />
          <ColorSettings
            title="Color"
            property="premiumTextColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
            initialColor={activeNodeAttributes?.premiumTextColor}
          />
          {activeNodeAttributes?.premiumBadgeEnabled && (
            <ColorSettings
              title="Background"
              property="premiumBackgroundColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.premiumBackgroundColor}
            />
          )}
          {activeNodeAttributes?.premiumTextEnabled && (
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="premiumTextTransform" />
          )}
        </SectionRenderer>
      )}
    </RenderCondition>
  );
};
