import { Fragment, ReactNode, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { Option } from '../../interfaces/general';
import { SIMPLE_SELECT_OPTIONS_Z_INDEX } from '../zIndexes';

import { SelectSubElementClassNames } from './types';

export interface Props {
  className?: string;
  name: string;
  labelText?: string;
  required?: boolean;
  value?: string | string[];
  onSelect: (name: string, value: string | any) => void;
  options: Option[];
  disabled?: boolean;
  helperText?: string;
  placeholderText?: string | ReactNode;
  errorText?: string;
  dropdownDirection?: 'up' | 'down';
  button?: ReactNode;
  buttonClassNames?: SelectSubElementClassNames;
  optionsContainerClassNames?: SelectSubElementClassNames;
  suffixElement?: ReactNode;
  emptyLabel?: string;
  shouldBindOptionAsValue?: boolean;
  multiple?: boolean;
}

/**
 * Please note:
 *
 * The other Select component is a bit more complex with multiple portals that are nested within themselves.
 * This presented some issues on mobile and it was beneficial to have a simpler version of the component for use
 * around the app.
 *
 * In general, we should use this component for simpler cases where we need a select dropdown.
 *
 */

const SimpleSelect = ({
  className,
  name,
  labelText,
  onSelect,
  value,
  options,
  helperText,
  placeholderText,
  errorText,
  emptyLabel,
  button,
  required = false,
  disabled = false,
  multiple = false,
  shouldBindOptionAsValue = false,
  dropdownDirection = 'down',
  optionsContainerClassNames = {},
  buttonClassNames = {},
  suffixElement = <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />,
}: Props) => {
  const values = [value].flat();
  const selectedOptions = useMemo(
    () => options.filter((option) => !option.isOptGroup && values.includes(option.value)),
    [options, values]
  );
  const selectedOptionsClassNames = useMemo(() => {
    const firstOption = selectedOptions[0];

    if (selectedOptions.length !== 1 || !firstOption.colorClassName) {
      return 'w-full inline-flex truncate';
    }

    return `bg-${firstOption.colorClassName}-100 text-${firstOption.colorClassName}-800 border-${firstOption.colorClassName}-200 px-2.5 py-0.5 text-xs rounded-md border shadow-xs inline-flex gap-x-1`;
  }, [selectedOptions]);

  const handleSelect = (selectedValue: string | string[]) => {
    return onSelect(name, selectedValue);
  };

  return (
    <div className={className}>
      <Listbox
        value={value}
        onChange={handleSelect}
        disabled={disabled}
        multiple={multiple}
        by={shouldBindOptionAsValue ? 'value' : undefined}
      >
        {({ open }) => (
          <>
            {labelText && (
              <div className="flex justify-between">
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  {labelText}
                  {required ? ' *' : ''}
                </Listbox.Label>
              </div>
            )}
            <div className={cx('relative', labelText ? 'mt-1' : undefined)}>
              <div>
                {button && <Listbox.Button>{button}</Listbox.Button>}
                {!button && (
                  <Listbox.Button
                    className={cx(
                      'relative w-full rounded-md focus:outline-none focus:ring-1',
                      buttonClassNames.focus || 'focus:ring-surface-500 focus:border-surface-500',
                      buttonClassNames.text || 'text-left sm:text-sm',
                      buttonClassNames.cursor || 'cursor-default',
                      buttonClassNames.shadow || 'shadow-sm',
                      buttonClassNames.padding || 'py-2 pl-3 pr-10',
                      buttonClassNames.border || 'border border-gray-300',
                      buttonClassNames.hover,
                      buttonClassNames.height,
                      !disabled && (buttonClassNames.background || 'bg-white'),
                      disabled ? 'bg-gray-50 cursor-not-allowed text-gray-400' : 'cursor-pointer'
                    )}
                  >
                    {selectedOptions.length > 0 && (
                      <span className={selectedOptionsClassNames}>
                        <span className="truncate">{selectedOptions.map((option) => option.label).join(', ')}</span>
                      </span>
                    )}
                    {selectedOptions.length === 0 && (
                      <span className="w-full inline-flex truncate">
                        <span className="truncate">{placeholderText || 'Select an Option'}</span>
                      </span>
                    )}
                    {suffixElement && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        {suffixElement}
                      </span>
                    )}
                  </Listbox.Button>
                )}

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={cx(
                      'absolute bg-white shadow-lg max-h-60 rounded-md py-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                      dropdownDirection === 'up' ? 'bottom-10' : 'shadow-lg',
                      optionsContainerClassNames.height || 'max-h-60', // Max height has some weird behavior with modals so allowing for an escape hatch here. It couldn't be overridden otherwise
                      optionsContainerClassNames.position || 'origin-top-right right-0',
                      optionsContainerClassNames.width || 'w-full',
                      optionsContainerClassNames.margin || 'mt-1',
                      SIMPLE_SELECT_OPTIONS_Z_INDEX
                    )}
                  >
                    {options.length === 0 && emptyLabel ? (
                      <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div>
                    ) : null}
                    {options.map((option, index) => {
                      if (option.isOptGroup) {
                        return (
                          <div
                            key={`select-group-label-${option.label}-${generateUuid()}`}
                            className={cx(
                              'py-2 pl-3 pr-9 font-bold text-gray-400 text-xs',
                              index > 0 && 'mt-1 border-t'
                            )}
                          >
                            {option.label}
                          </div>
                        );
                      }

                      return (
                        <Listbox.Option
                          key={option.value}
                          className={({ active }) =>
                            cx(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-800',
                              'cursor-pointer select-none relative py-2 pl-3 pr-9',
                              option.optionAction ? 'group' : ''
                            )
                          }
                          value={shouldBindOptionAsValue ? option : option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <div
                                className={
                                  option.colorClassName
                                    ? `bg-${option.colorClassName}-100 text-${option.colorClassName}-800 border-${option.colorClassName}-200 px-2.5 py-0.5 text-xs rounded-md border shadow-xs inline-flex gap-x-1`
                                    : 'flex gap-2 items-center'
                                }
                              >
                                <span className={cx(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                  {option.label}
                                </span>

                                {option.optionAction && (
                                  <div className="md:opacity-0 md:group-hover:opacity-100">{option.optionAction}</div>
                                )}
                              </div>

                              {option.description ? (
                                <span className={cx('font-normal truncate text-xs text-gray-400', 'truncate')}>
                                  {option.description}
                                </span>
                              ) : null}

                              {!selected && option?.optionSuffix ? (
                                <span
                                  className={cx(
                                    active ? 'text-gray-900' : 'text-gray-800',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  {option.optionSuffix}
                                </span>
                              ) : null}

                              {selected ? (
                                <span
                                  className={cx(
                                    active ? 'text-gray-900' : 'text-gray-800',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>

              {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
              {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SimpleSelect;
