import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import { useEmailMessage, useEmailMessagePreview, useEmailMessageStats } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import ActionModal from '../../../ActionModal';
import Header from '../../../Header';
import LoadingBox from '../../../LoadingBox';
import Text from '../../../Text';
import ClicksTable from '../ClicksTable';
import { VerticalMetricsTable } from '../VerticalMetricsTable';

interface EmailInfoProps {
  emailMessageId: string;
  indexPath: string;
  editPath: string;
  showSegment?: boolean;
}

const EmailInfo = ({ emailMessageId, indexPath, editPath, showSegment }: EmailInfoProps) => {
  const queryClient = useQueryClient();
  const currentPublicationId = useCurrentPublicationId();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useEmailMessage({
    emailMessageId,
  });

  const {
    data: stats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
  } = useEmailMessageStats(currentPublicationId, emailMessageId);
  const {
    data: preview,
    isLoading: isLoadingPreview,
    isError: isErrorPreview,
  } = useEmailMessagePreview(currentPublicationId, emailMessageId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const unscheduleEmail = useMutation(
    () =>
      api
        .delete(`/email_messages/${emailMessageId}/schedule`, {
          data: { publication_id: currentPublicationId },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['email_messages', emailMessageId], { exact: true });
        toast.success('Email unscheduled');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
      },
    }
  );

  const deleteEmail = useMutation(
    () =>
      api.delete(`/email_messages/${emailMessageId}`, {
        data: { publication_id: currentPublicationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['email_messages', currentPublicationId], { exact: true });
        toast.success('Email successfully deleted');
        navigate(indexPath);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
      },
    }
  );

  const isScheduled = !!data?.last_sent_at;
  const sendTime = isScheduled ? moment(data.last_sent_at || undefined) : undefined;
  const hasSent = sendTime && sendTime.isBefore();

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {data && (
        <div className="space-y-8">
          <div className="flex justify-between items-end">
            <div>
              <Header as="h2">{data.subject_line || '[No Subject]'}</Header>
              <Text>{data.preview_text}</Text>
            </div>
            {!isScheduled && (
              <>
                <ActionModal
                  isOpen={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  isWorking={deleteEmail.isLoading}
                  onProceed={() => deleteEmail.mutateAsync()}
                  resourceId={emailMessageId}
                  headerText="Delete Email"
                >
                  <p className="text-sm text-gray-500 mt-2 max-w-xl">
                    Are you sure that you want to delete this email? This action cannot be undone.
                  </p>
                </ActionModal>
                <div className="flex space-x-2">
                  <Button variant="primary-inverse" onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </Button>
                  <Link to={editPath}>
                    <Button type="button">Edit</Button>
                  </Link>
                </div>
              </>
            )}
            {isScheduled && !hasSent && (
              <Button
                variant="primary-inverse"
                loading={unscheduleEmail.isLoading}
                onClick={() => unscheduleEmail.mutateAsync()}
              >
                Unschedule
              </Button>
            )}
          </div>
          <hr />
          <div className="grid grid-cols-4 gap-8">
            <div>
              {showSegment && (
                <div className="mb-8">
                  <p className="text-xs uppercase font-semibold tracking-tight text-gray-500">Segment</p>
                  <p className="text-gray-900 font-semibold capitalize">{data.resource_display || '-'}</p>
                </div>
              )}

              <div className="mb-8">
                <p className="text-xs uppercase font-semibold tracking-tight text-gray-500">Send Time</p>
                <p className="text-gray-900 font-semibold capitalize">{sendTime ? sendTime.format('LLLL') : '-'}</p>
              </div>

              {!hasSent ? (
                <p className="text-gray-600 text-sm">Metrics will show once the email has sent</p>
              ) : (
                <VerticalMetricsTable stats={stats} isLoading={isLoadingStats} isError={isErrorStats} />
              )}
            </div>
            <div className="col-span-4 sm:col-span-3">
              <LoadingBox isLoading={isLoadingPreview} isError={isErrorPreview}>
                {preview && (
                  <iframe
                    title="preview"
                    className="w-full rounded overflow-hidden h-full"
                    srcDoc={preview.html}
                    style={{
                      minHeight: '750px',
                    }}
                  />
                )}
              </LoadingBox>
            </div>
          </div>
          <div className="bg-white p-4 rounded">
            <ClicksTable publicationId={currentPublicationId} emailMessageId={emailMessageId} />
          </div>
        </div>
      )}
    </LoadingBox>
  );
};

export default EmailInfo;
