import Modal from "@/components/Modal";
import { Typography, TypographyStack } from "@/components/Typography";
import { useSupportModalContext } from "@/context/support-modal-context";
import { Button } from '@/ui/Button';

export interface ApplyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotQualifiedModal = ({ isOpen, onClose }: ApplyModalProps) => {
  const { setIsSupportModalOpen } = useSupportModalContext();
  const handleContactSupport = () => {
    setIsSupportModalOpen(true);
    onClose();
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      includeCloseButton={false}
      className="max-w-lg"
    >
      <section className="p-6">
        <TypographyStack gap="4">
          <Typography as="p">
            Uh oh, it doesn&apos;t look like you qualify to monetize via Boosts at the moment.
          </Typography>
          <Typography as="p">
            Boosts is a trusted ecosystem of active newsletters. If you&apos;re just getting started on beehiiv, no need to worry — you should have access shortly.
          </Typography>
          <Typography as="p">
            Monetization tools on beehiiv require careful vetting to prevent abuse, and we just haven&apos;t gotten a chance to know you yet. Check back again later. 🙂
          </Typography>
          <Typography as="p">
            For additional information, please{' '}
            <button
              type="button"
              onClick={handleContactSupport}
              className="text-primary-500 hover:underline hover:text-primary-600"
            >
              contact support
            </button>
            .
          </Typography>
        </TypographyStack>

        <div className="flex flex-row justify-end mt-6">
          <Button
            variant='primary'
            type="button"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default NotQualifiedModal;
