import React from 'react';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';

import BodyContainer from '../../components/BodyContainer';
import { StepProps } from '../new/types';

const GhostApiForm: React.FunctionComponent<StepProps> = ({ contentImport, updateContentImport, errors }: StepProps) => (
  <BodyContainer>
    <Typography token="font-normal/text/sm" colorWeight="500">
      Input your Ghost Content API key and API URL below. This information can be found in Ghost by going to{' '}
      <b>Settings</b> &gt; <b>Integrations</b> and selecting or creating a custom integration.
    </Typography>

    <Input
      name="apiKey"
      labelText="Content API key"
      defaultValue={contentImport.api_key}
      onChange={(e) => updateContentImport({ api_key: e.target.value })}
      errorText={errors?.apiKey}
    />

    <Input
      labelText="API URL"
      leadingText="https://"
      placeholderText="demo.ghost.io"
      name="url"
      defaultValue={contentImport.url}
      onBlur={(e) => updateContentImport({ url: e.target.value })}
      errorText={errors?.url}
    />
  </BodyContainer>
);

export default GhostApiForm;
