import { Extension } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';

type ActiveNodeOptions = {};
type ActiveNodeStorage = {
  activeDOMNode?: Element;
  activeNodeRect?: DOMRect;
};

export const ActiveNode = Extension.create<ActiveNodeOptions, ActiveNodeStorage>({
  name: 'activeNode',

  // @ts-ignore TODO(albert): remove ts-ignore
  onSelectionUpdate({ editor }: { editor: Editor }) {
    // The selection has changed.
    const { selection } = editor.state;

    if (selection instanceof NodeSelection) {
      const nodeDOM = editor.view.nodeDOM(selection.$anchor.pos);
      if (nodeDOM && nodeDOM instanceof Element) {
        this.storage.activeDOMNode = nodeDOM;
        this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
      }
    } else {
      // leaf node, select the parent
      const nodeDOM = editor.view.nodeDOM(selection.$head.start(selection.$head.depth) - 1);

      if (nodeDOM && nodeDOM instanceof Element) {
        this.storage.activeDOMNode = nodeDOM;
        this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
      }
    }
  },

  // @ts-ignore TODO(albert): remove ts-ignore
  onUpdate({ editor }: { editor: Editor }) {
    const { selection } = editor.state;

    if (selection instanceof NodeSelection) {
      const nodeDOM = editor.view.nodeDOM(selection.$anchor.pos);
      if (nodeDOM && nodeDOM instanceof Element) {
        this.storage.activeDOMNode = nodeDOM;
        this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
      }
    } else {
      // leaf node, select the parent
      const nodeDOM = editor.view.nodeDOM(selection.$head.start(selection.$head.depth) - 1);

      if (nodeDOM && nodeDOM instanceof Element) {
        this.storage.activeDOMNode = nodeDOM;
        this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
      }
    }
  },
});
