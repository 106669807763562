import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { Input, RadioSelect, SimpleSelect } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import SlideOver from '@/components/SlideOver';
import { useSendInvite } from '@/hooks/useInvites';
import { useOrganizationPublications } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { Organization, RoleOption } from '@/interfaces/organization';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';

import makeUniqueRoleValue from '../makeUniqueRoleValue';
import { CurrentRoleOption } from '../types';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

const NewMember = ({ title = 'Invite Team Member', isOpen, onClose, organization }: Props) => {
  const publicationIdInUrl = useCurrentPublicationId();
  const [selectedOption, setSelectedOption] = useState<CurrentRoleOption | null>(null);
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [selectedPublicationId, setSelectedPublicationId] = useState<string>('');
  const [errors, setErrors] = useState<{
    publicationId?: boolean;
    inviteEmail?: boolean;
  }>({});

  const {
    data: organizationPublications,
    isLoading: isLoadingPublications,
    isError: isErrorPublications,
  } = useOrganizationPublications({ organizationId: organization.id });

  const handleClose = () => {
    setInviteEmail('');
    setSelectedOption(null);
    setSelectedPublicationId('');
    setErrors({});
    onClose();
  };

  const sendInviteMutation = useSendInvite(organization.id);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!inviteEmail) {
      setErrors({
        ...errors,
        inviteEmail: true,
      });
      toast.error('Please provide a valid email');
      return;
    }

    if (!selectedOption) {
      toast.error('Please select a role');
      return;
    }

    if (selectedOption.object_type === 'Publication' && !selectedPublicationId) {
      setErrors({
        publicationId: true,
      });
      toast.error('Please select a Publication');
      return;
    }

    try {
      await sendInviteMutation.mutateAsync({
        invite: {
          invited_email: inviteEmail,
          role_id: String(selectedOption.role_id),
          invitable_type: selectedOption.object_type,
          invitable_id: selectedOption.object_type === 'Organization' ? organization.id : selectedPublicationId,
        },
        publicationId: selectedOption.object_type === 'Publication' ? selectedPublicationId : publicationIdInUrl, // Publication id is required for authentication
      });

      handleClose();
    } catch (err) {
      toast.error('Oops! Something went wrong!');
    }
  };

  const publicationOptions = useMemo(
    () =>
      organizationPublications?.map(
        (publication) =>
          ({
            label: publication.name,
            value: publication.id,
          } as Option)
      ),
    [organizationPublications]
  );

  const handleSelectRoleOption = (value: string) => {
    const selectedRole = organization?.role_options.find(
      (option) => makeUniqueRoleValue(option.object_type, option.role_id) === value
    );

    if (selectedRole) {
      setSelectedOption({
        role_id: selectedRole.role_id,
        object_id: selectedRole.object_id,
        object_type: selectedRole.object_type,
        name: selectedRole.name,
      });
    }
  };

  return (
    <SlideOver bodyId={organization?.id} isOpen={isOpen} onClose={handleClose} headerText={title}>
      <LoadingBox isLoading={isLoadingPublications} isError={isErrorPublications}>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <Input
              className="w-full"
              name="email"
              labelText="Email"
              placeholder="new.member@email.com"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value.trim())}
              errorText={errors.inviteEmail ? 'Provide a valid email' : undefined}
            />
            {organization?.role_options && (
              <RadioSelect
                labelText="Select Permission Level"
                value={
                  selectedOption ? makeUniqueRoleValue(selectedOption.object_type, selectedOption.role_id) : undefined
                }
                onSelect={handleSelectRoleOption}
                options={organization?.role_options.map((option: RoleOption) => ({
                  name: capitalize(option.name),
                  value: makeUniqueRoleValue(option.object_type, option.role_id),
                  description: option.description,
                }))}
              />
            )}
            {selectedOption?.object_type === 'Publication' ? (
              <SimpleSelect
                labelText="Select Publication"
                name="publication_id"
                value={selectedPublicationId}
                options={publicationOptions || []}
                onSelect={(_name: string, value: string) => setSelectedPublicationId(value)}
                errorText={errors.publicationId ? 'Select a publication' : undefined}
              />
            ) : null}
            <div className="w-full flex justify-end space-x-4 pt-4">
              <Button type="submit" variant="primary" onClick={() => {}} loading={sendInviteMutation.isLoading}>
                <div className="flex">
                  <EnvelopeIcon className="h-5 w-5 mr-2" />
                  {sendInviteMutation.isLoading ? 'Sending...' : 'Send Invite'}
                </div>
              </Button>
            </div>
          </div>
        </form>
      </LoadingBox>
    </SlideOver>
  );
};

export default NewMember;
