import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import ConfirmSubscriptionChanges from '@/routes/mailer_actions/confirm_subscription_changes/$token';
import Onboarding from '@/routes/onboarding/_layout';
import OnboardingBusinessQuestionnaire from '@/routes/onboarding/business_questionnaire';
import OnboardingPersonas from '@/routes/onboarding/personas';
import OnboardingPlatformMigration from '@/routes/onboarding/platform_migration';
import OnboardingPublicationDetails from '@/routes/onboarding/publication_details';
import OnboardingTrialFeatures from '@/routes/onboarding/trial_features';
import SubscriberLayout from '@/routes/subscribers/$subscriber_id/v1/_layout';
import SubscriberLayoutV2 from '@/routes/subscribers/$subscriber_id/v2/_layout';

import EnsureSettingsQueryParams from './components/EnsureSettingsQueryParams';
import { AdminReportLayout, FullscreenLayout, SystemAdminLayout } from './components/Layout';
import AppLayout from './components/Layout/AppLayout';
import PageLayout from './components/Layout/PageLayout';
import RequireAuth from './components/RequireAuth';
import RouteAdapter from './components/RouteAdapter';
import SwitchPublicationViaQuery from './components/SwitchPublicationViaQuery';
import UnderConstruction from './components/UnderContruction';
import { AppLayoutProvider } from './context/app-layout-context';
import { CurrentPublicationProvider } from './context/current-publication-context';
import { CurrentUserContext, CurrentUserProvider } from './context/current-user-context';
import { PermissionsContext, PermissionsProvider } from './context/permissions-context';
import { RolesProvider } from './context/roles-context';
import { SettingsContext, SettingsProvider } from './context/settings-context';
import { SupportModalProvider } from './context/support-modal-context';
import AdminReportLedgers from './pages/AdminReports/Ledgers/List';
import AutomationsList from './pages/Automations/List';
import NewAutomation from './pages/Automations/New';
import AutomationsTeaser from './pages/Automations/Teaser';
import AutomationView from './pages/AutomationView';
import AutomationViewEdit from './pages/AutomationView/Edit';
import AutomationViewOverview from './pages/AutomationView/Overview';
import AutomationViewSettings from './pages/AutomationView/Settings';
import BoostsDisabled from './pages/BoostsDisabled';
import ContentTags from './pages/ContentTags';
import EditContentTag from './pages/ContentTags/EditContentTag';
import NewContentTag from './pages/ContentTags/NewContentTag';
import CustomFooterEdit from './pages/CustomFooter';
import CustomPages from './pages/CustomPages';
import EditCustomPage from './pages/CustomPages/Edit';
import NewCustomPage from './pages/CustomPages/New';
import Dashboard from './pages/Dashboard';
import DesignLabHomePage from './pages/DesignLab/HomePage';
import DesignLabLandingPage from './pages/DesignLab/LandingPage';
import DesignLabUpgradePage from './pages/DesignLab/UpgradePage';
import ErrorPage from './pages/Error';
import FormPageLayout from './pages/Forms/_layout';
import EditForm from './pages/Forms/Edit';
import FormBuilder from './pages/Forms/Edit/FormBuilder';
import FormSettings from './pages/Forms/Edit/FormSettings';
import FormSummary from './pages/Forms/FormSummary';
import Forms from './pages/Forms/Index';
import Form from './pages/Forms/Show';
import FormsTeaser from './pages/Forms/Teaser';
import GrowBoostOfferEdit from './pages/Grow/Boosts/BoostOffer/Edit';
import GrowBoostOfferNew from './pages/Grow/Boosts/BoostOffer/New';
import GrowBoostsDashboard from './pages/Grow/Boosts/Dashboard';
import GrowBoostsIntro from './pages/Grow/Boosts/Intro';
import RecommendingPublication from './pages/Grow/Boosts/RecommendingPublication';
import GrowBoostsOverview from './pages/Grow/Boosts/Teaser';
import GuestAuthorsView from './pages/GuestAuthorsView';
import InviteAccept from './pages/InviteAccept';
import NewInvite from './pages/Invites/New';
import Loading from './pages/Loading';
import MagicLinksView from './pages/MagicLinksView';
import BoostedPublication from './pages/Monetize/Boosts/BoostedPublication';
import MonetizeBoostsDashboard from './pages/Monetize/Boosts/Dashboard';
import MonetizeBoostsMarketplace from './pages/Monetize/Boosts/Marketplace';
import MonetizeSubscriptionsNext from './pages/Monetize/Subscriptions';
import MonetizeSubscriptions from './pages/MonetizeSubscriptions/Index';
import MonetizeSubscriptionsTeaser from './pages/MonetizeSubscriptions/Teaser';
import Notifications from './pages/Notifications';
import PartnerProgram from './pages/PartnerProgram';
import PasswordReset from './pages/PasswordReset';
import Polls from './pages/Polls/Index';
import Poll from './pages/Polls/Show';
import PollsTeaser from './pages/Polls/Teaser';
import PostAnalytics from './pages/Post/Analytics';
import ClickDetails from './pages/Post/Analytics/ClickDetails';
import ClickMap from './pages/Post/Analytics/ClickMap';
import CommentsContainer from './pages/Post/CommentsContainer';
import PostEdit from './pages/Post/Edit';
import EngagementOverview, { TabType } from './pages/Post/EngagementOverview';
import PostOverview from './pages/Post/Overview';
import PollsContainer from './pages/Post/PollsContainer';
import PostPremiumData from './pages/Post/PremiumData';
import RecipientsContainer from './pages/Post/RecipientsContainer';
import SplitTest from './pages/Post/SplitTest';
import Posts from './pages/Posts';
import RecommendationsView from './pages/RecommendationsView';
import RecommendationsViewExternal from './pages/RecommendationsView/External';
import RecommendationsViewList from './pages/RecommendationsView/List';
import ReferralProgramConfigureExportData from './pages/ReferralProgram/Configure/ExportData';
import ReferralProgramConfigureMilestones from './pages/ReferralProgram/Configure/Milestones';
import ReferralProgramConfigureEditMilestone from './pages/ReferralProgram/Configure/Milestones/EditMilestone';
import ReferralProgramConfigureNewMilestone from './pages/ReferralProgram/Configure/Milestones/NewMilestone';
import ReferralProgramConfigureRewards from './pages/ReferralProgram/Configure/Rewards';
import ReferralProgramConfigureSettings from './pages/ReferralProgram/Configure/Settings';
import ReferralProgramFulfillment from './pages/ReferralProgram/Fulfillment';
import ReferralProgramOverview from './pages/ReferralProgram/Overview';
import ReportsShow from './pages/Reports/Show';
import ReportsTeaser from './pages/Reports/Teaser';
import RequestPassword from './pages/RequestPassword';
import Segments from './pages/Segments';
import NewSegment from './pages/Segments/NewSegment';
import SegmentView from './pages/SegmentView';
import SegmentViewBlastEdit from './pages/SegmentView/Blasts/Edit';
import SegmentViewBlasts from './pages/SegmentView/Blasts/Index';
import SegmentViewBlast from './pages/SegmentView/Blasts/Show';
import SegmentViewEdit from './pages/SegmentView/Edit';
import SegmentViewShow from './pages/SegmentView/Show';
import Company from './pages/Settings/Pages/Company';
import CompanyGeneral from './pages/Settings/Pages/Company/General';
import CompanyPublications from './pages/Settings/Pages/Company/Publications';
import CompanyTeam from './pages/Settings/Pages/Company/Team';
import Downgrade from './pages/Settings/Pages/Downgrade';
import Integrations from './pages/Settings/Pages/Integrations';
import IntegrationsApi from './pages/Settings/Pages/Integrations/Api';
import IntegrationsImports from './pages/Settings/Pages/Integrations/Imports';
import IntegrationsWebhooks from './pages/Settings/Pages/Integrations/Webhooks';
import IntegrationsWebhooksEndpointsCreate from './pages/Settings/Pages/Integrations/Webhooks/Endpoints/Create';
import IntegrationsWebhooksEndpoint from './pages/Settings/Pages/Integrations/Webhooks/Endpoints/Show';
import IntegrationsWebhooksEndpointMessage from './pages/Settings/Pages/Integrations/Webhooks/Endpoints/Show/Messages/Show';
import NotificationSettings from './pages/Settings/Pages/Notifications';
import Password from './pages/Settings/Pages/Password';
import PaymentAccounts from './pages/Settings/Pages/PaymentAccounts';
import Profile from './pages/Settings/Pages/Profile';
import PublicationDomains from './pages/Settings/Pages/Publication/Domains';
import PublicationExportData from './pages/Settings/Pages/Publication/ExportData';
import PublicationGeneral from './pages/Settings/Pages/Publication/General';
import PublicationImportContent from './pages/Settings/Pages/Publication/ImportContent';
import PublicationImportSubscribers from './pages/Settings/Pages/Publication/ImportSubscribers';
import PublicationRSSFeed from './pages/Settings/Pages/Publication/RSSFeed';
import PublicationSusbcribeForms from './pages/Settings/Pages/Publication/SubscribeForms';
import PublicationWidgets from './pages/Settings/Pages/Publication/Widgets';
import Website from './pages/Settings/Pages/Website';
import WebsiteAdvanced from './pages/Settings/Pages/Website/Advanced';
import WebsiteAnalytics from './pages/Settings/Pages/Website/Analytics';
import WebsiteComments from './pages/Settings/Pages/Website/Comments';
import WebsiteGeneral from './pages/Settings/Pages/Website/General';
import WebsiteNavbar from './pages/Settings/Pages/Website/Navbar';
import WebsitePages from './pages/Settings/Pages/Website/Pages';
import SystemAdminUserMasquerade from './pages/SystemAdmin/Users/Masquerade';
import TemplatesView from './pages/TemplatesView';
import EditTemplate from './pages/TemplatesView/Edit';
import Templates from './pages/TemplatesView/List';
import NewTemplate from './pages/TemplatesView/New';
import Template from './pages/TemplatesView/Show';
import Testimonials from './pages/Testimonials/Index';
import ThemeEditor from './pages/ThemeEditor';
import VerificationRequest from './pages/VerificationRequest';
import Wallet from './pages/Wallet';
import Wrapped from './pages/Wrapped';
import AuthMailchimp from './routes/auth/mailchimp';
import AuthMailchimpCallback from './routes/auth/mailchimp/callback';
import CurrentUserSettingsLayout from './routes/current_user/_layout';
import AccountSecuritySettings from './routes/current_user/account_security';
import CurrentUserNotificationPreferences from './routes/current_user/notification_preferences';
import PersonalInfoSettings from './routes/current_user/personal_info';
import HelpPage from './routes/help';
import MonetizeAdsOverview from './routes/monetize/ads';
import MonetizeAdsLayout from './routes/monetize/ads/_layout';
import MonetizeAdsOpportunity from './routes/monetize/ads/opportunities/$opportunity_id';
import MonetizeAdsReports from './routes/monetize/ads/reports';
import MonetizeAdsReport from './routes/monetize/ads/reports/$report_id';
import MonetizeAdsRemittance from './routes/monetize/ads/reports/remittance';
import MonetizeSubscriptionsAttribution from './routes/monetize/subscriptions/attribution';
import MonetizeSubscriptionsBreakdown from './routes/monetize/subscriptions/breakdown';
import MonetizeSubscriptionsGrowth from './routes/monetize/subscriptions/growth';
import MonetizeSubscriptionsPaidTiers from './routes/monetize/subscriptions/paid_tiers';
import MonetizeSubscriptionsPaidTiersEdit from './routes/monetize/subscriptions/paid_tiers/edit';
import MonetizeSubscriptionsPaidTiersNew from './routes/monetize/subscriptions/paid_tiers/new';
import OnboardingHowDidYouHearAboutUs from './routes/onboarding/how_did_you_hear_about_us';
import OnboardingNextSteps from './routes/onboarding/next_steps';
import OptInEmailLayout from './routes/opt_in_email/_layout';
import OptInEmailAnalytics from './routes/opt_in_email/analytics';
import OptInEmailEdit from './routes/opt_in_email/edit';
import OptInEmailOverview from './routes/opt_in_email/overview';
import NewReferralProgramOverview from './routes/referral_program';
import ReferralProgramLayout from './routes/referral_program/_layout';
import EditReferralProgram from './routes/referral_program/milestones/$milestone_id/edit';
import NewReferralProgram from './routes/referral_program/milestones/new';
import SettingsLayout from './routes/settings/_layout';
import Billing from './routes/settings/billing';
import PublicationSettingsLayout from './routes/settings/publication/_layout';
import NotAuthorized from './routes/settings/publication/components/NotAuthorized';
import PublicationContentImportSettings from './routes/settings/publication/content_import';
import NewContentImport from './routes/settings/publication/content_import/new';
import PublicationDomainSettings from './routes/settings/publication/domain';
import PublicationEmailSettings from './routes/settings/publication/emails';
import PublicationOptInEmailSettings from './routes/settings/publication/emails/opt_in_email';
import EditOptInEmail from './routes/settings/publication/emails/opt_in_email/edit';
import PublicationWelcomeEmailSettings from './routes/settings/publication/emails/welcome_email';
import EditWelcomeEmail from './routes/settings/publication/emails/welcome_email/edit';
import PublicationExportDataSettings from './routes/settings/publication/export_data';
import PublicationGeneralSettings from './routes/settings/publication/general';
import PublicationPaymentAccountsSettings from './routes/settings/publication/payment_accounts';
import PublicationPremiumSettings from './routes/settings/publication/premium';
import TierConfigurationLayout from './routes/settings/publication/premium/tiers/_layout';
import TierConfigurationExisting from './routes/settings/publication/premium/tiers/$tier_id';
import TierConfigurationNew from './routes/settings/publication/premium/tiers/new';
import PublicationRSSSendFeedSettings from './routes/settings/publication/rss_send_feed';
import PublicationSubscribersImportSettings from './routes/settings/publication/subscribers_import';
import ImportForm from './routes/settings/publication/subscribers_import/new/ImportForm';
import PublicationWebhooksSettings from './routes/settings/publication/webhooks';
import EditWebhookEndpoint from './routes/settings/publication/webhooks/edit';
import NewWebhookEndpoint from './routes/settings/publication/webhooks/new';
import ShowWebhookEndpoint from './routes/settings/publication/webhooks/show';
import ShowMessageAttempt from './routes/settings/publication/webhooks/show/message/message_attempt/show';
import WorkspaceAPI from './routes/settings/workspace/api';
import WorkspaceBillingAndPlan from './routes/settings/workspace/billing_and_plan';
import WorkspaceBillingAndPlanDowngrade from './routes/settings/workspace/billing_and_plan/downgrade';
import WorkspaceNewPublication from './routes/settings/workspace/new_publication';
import WorkspaceOverview from './routes/settings/workspace/overview';
import WorkspaceTeam from './routes/settings/workspace/team';
import StripeMigrations from './routes/stripe_migrations';
import StripeMigrationWizardStepTwo from './routes/stripe_migrations/$stripe_migration_id/account_details';
import StripeMigrationWizardStepFive from './routes/stripe_migrations/$stripe_migration_id/finished';
import StripeMigrationWizardStepThree from './routes/stripe_migrations/$stripe_migration_id/map_products';
import StripeMigrationWizardStepFour from './routes/stripe_migrations/$stripe_migration_id/preview_subscriptions';
import StripeMigrationWizardStepOne from './routes/stripe_migrations/pan_migration';
import Subscribers from './routes/subscribers';
import SubscribersLayout from './routes/subscribers/_layout';
import SubscriberPostActivity from './routes/subscribers/$subscriber_id/v1';
import SubscriberAutomationActivity from './routes/subscribers/$subscriber_id/v1/automations';
import SubscriberOtherEmailActivity from './routes/subscribers/$subscriber_id/v1/other_emails';
import SubscriberPollActivity from './routes/subscribers/$subscriber_id/v1/polls';
import SubscriberReferralActivity from './routes/subscribers/$subscriber_id/v1/referrals';
import SubscriberPostActivityV2 from './routes/subscribers/$subscriber_id/v2';
import SubscriberAutomationActivityV2 from './routes/subscribers/$subscriber_id/v2/automations';
import SubscriberBillingHistoryV2 from './routes/subscribers/$subscriber_id/v2/billing_history';
import SubscriberOtherEmailActivityV2 from './routes/subscribers/$subscriber_id/v2/other_emails';
import SubscriberPollActivityV2 from './routes/subscribers/$subscriber_id/v2/polls';
import SubscriberProfileEventsV2 from './routes/subscribers/$subscriber_id/v2/profile_events';
import SubscriberReferralActivityV2 from './routes/subscribers/$subscriber_id/v2/referrals';
import CustomFields from './routes/subscribers/custom_fields';
import EditCustomField from './routes/subscribers/custom_fields/$custom_field_id/edit';
import NewCustomField from './routes/subscribers/custom_fields/new';
import Preferences from './routes/subscribers/preferences';
import Tags from './routes/subscribers/tags';
import UpsellEmailLayout from './routes/upsell_email/_layout';
import UpsellEmailAnalytics from './routes/upsell_email/analytics';
import UpsellEmailEdit from './routes/upsell_email/edit';
import UpsellEmailOverview from './routes/upsell_email/overview';
import WebsiteBuilderV2 from './routes/website';
import WebsiteBuilderV2NavbarEditor from './routes/website/navbar';
import WebsiteBuilderV2Onboarding from './routes/website/onboarding';
import WebsiteBuilderV2PageEditor from './routes/website/page';
import WebsiteBuilderV2SettingsGeneral from './routes/website/settings';
import WebsiteBuilderV2SettingsLayout from './routes/website/settings/_layout';
import WebsiteBuilderV2SettingsComments from './routes/website/settings/comments';
import WebsiteBuilderV2SettingsGDPR from './routes/website/settings/gdpr';
import WebsiteBuilderV2SettingsNotifications from './routes/website/settings/notifications';
import WebsiteSettingsPage from './routes/website/settings/page';
import WebsiteBuilderV2SettingsPixels from './routes/website/settings/pixels';
import WebsiteBuilderV2SettingsSecurity from './routes/website/settings/security';
import WebsiteBuilderV2SettingsSEO from './routes/website/settings/seo';
import WebsiteBuilderV2SettingsSignupFlows from './routes/website/settings/signup_flows';
import LayoutPage from './routes/website_builder';
import WebBuilderCustomPages from './routes/website_builder/custom_pages';
import CustomPageBuilder from './routes/website_builder/custom_pages/$customPageId';
import NewCustomPageBuilder from './routes/website_builder/custom_pages/new';
import WebBuilderCustomPagesView from './routes/website_builder/custom_pages/view';
import AdvancedPage from './routes/website_builder/settings';
import WebBuilderComments from './routes/website_builder/settings/comments';
import WebBuilderEmailCapture from './routes/website_builder/settings/email_capture';
import WebBuilderEmbedRecommendationsWidget from './routes/website_builder/settings/embed_recommendations_widget';
import WebBuilderMobileApp from './routes/website_builder/settings/mobile_app';
import WebBuilderNotifications from './routes/website_builder/settings/notifications';
import WebBuilderPixels from './routes/website_builder/settings/pixels';
import WebBuilderSecurity from './routes/website_builder/settings/security';
import WebBuilderSeo from './routes/website_builder/settings/seo';
import WebBuilderSignupFlow from './routes/website_builder/settings/signup_flow';
import WebBuilderSocialLinks from './routes/website_builder/settings/social_links';
import WebBuilderStaticPages from './routes/website_builder/settings/static_pages';
import WebBuilderThemeReset from './routes/website_builder/settings/theme_reset';
import WelcomeEmailLayout from './routes/welcome_email/_layout';
import WelcomeEmailAnalytics from './routes/welcome_email/analytics';
import WelcomeEmailEdit from './routes/welcome_email/edit';
import WelcomeEmailOverview from './routes/welcome_email/overview';
import WelcomeEmailPolls from './routes/welcome_email/polls';
import WelcomeEmailPremiumData from './routes/welcome_email/premium_data';
import AdNetworkApp from './AdNetworkApp';
import AdvertiserAccessApp from './AdvertiserAccessApp';

// lazy load system admin pages
const SystemAdminAllowedEmails = React.lazy(() => import('./pages/SystemAdmin/AllowedEmails/Index'));
const SystemAdminBlockedDomains = React.lazy(() => import('./pages/SystemAdmin/BlockedDomains/Index'));
const SystemAdminBlockedEmails = React.lazy(() => import('./pages/SystemAdmin/BlockedEmails/Index'));
const SystemAdminBlockedLinks = React.lazy(() => import('./pages/SystemAdmin/BlockedLinks/Index'));
const SystemAdminBlockedImages = React.lazy(() => import('./pages/SystemAdmin/BlockedImages/Index'));
const SystemAdminBlockedPhoneNumbers = React.lazy(() => import('./pages/SystemAdmin/BlockedPhoneNumbers/Index'));
const SystemAdminLegitOrganizations = React.lazy(() => import('./pages/SystemAdmin/LegitOrganizations/Index'));
const SystemAdminOrganizationFlags = React.lazy(() => import('./pages/SystemAdmin/FlaggedOrganizations/Index'));
const SystemAdminOrganizations = React.lazy(() => import('./pages/SystemAdmin/Organizations/Index'));
const SystemAdminSpamContent = React.lazy(() => import('./pages/SystemAdmin/SpamContent/Index'));
const SystemAdminSuppressedEmails = React.lazy(() => import('./pages/SystemAdmin/SuppressedEmails/Index'));
const SystemAdminTempUsers = React.lazy(() => import('./pages/SystemAdmin/TempUsers/Index'));
const SystemAdminUsers = React.lazy(() => import('./pages/SystemAdmin/Users/Index'));
const SystemAdminUser = React.lazy(() => import('./pages/SystemAdmin/Users/$user_id/Index'));
const SystemAdminOrganization = React.lazy(() => import('./pages/SystemAdmin/Organizations/$organization_id/Index'));
const SystemAdminPublication = React.lazy(() => import('./pages/SystemAdmin/Publications/$publication_id/Index'));
const SystemAdminBugReport = React.lazy(() => import('./pages/SystemAdmin/BugReports/$bug_report_id/Index'));

const AuthenticatedRoutes = ({
  hasMultipleTeamMembers,
  hasPremiumSubscribersDashboard,
  hasPremiumSubscribersDashboardV2,
  hasReferralProgramV2,
  hideBillingPage,
  hidePremiumSettingsPage,
  isAutomationsEnabled,
  isBoostsEnabled,
  isBoostsLocked,
  isCommentsEnabled,
  isContentTagsEnabled,
  isCustomFieldsEnabled,
  isDowngradingEnabled,
  isFormsEnabled,
  isLandingPagesEnabled,
  isPostsDashboardV2Enabled,
  isPagesEnabled,
  isSubscriberProfileV2Enabled,
  isSubscriberTaggingEnabled,
  areSubscribersPreferencesEnabled,
  isUpgradePageEnabled,
  isWalletEnabled,
  isWebBuilderEnabled,
  isSurveysV2Enabled,
  isPublicationSettingsV2Enabled,
  isPartnerProgramEnabled,
  isHiivDreamEnabled,
}: {
  hasMultipleTeamMembers: boolean;
  hasPremiumSubscribersDashboard: boolean;
  hasPremiumSubscribersDashboardV2: boolean;
  hasReferralProgramV2: boolean;
  hideBillingPage: boolean;
  hidePremiumSettingsPage: boolean;
  isAutomationsEnabled: boolean;
  isBoostsEnabled: boolean;
  isBoostsLocked: boolean;
  isCommentsEnabled: boolean;
  isContentTagsEnabled: boolean;
  isCustomFieldsEnabled: boolean;
  isDowngradingEnabled: boolean;
  isFormsEnabled: boolean;
  isLandingPagesEnabled: boolean;
  isPostsDashboardV2Enabled: boolean;
  isPagesEnabled: boolean;
  isSubscriberProfileV2Enabled: boolean;
  isSubscriberTaggingEnabled: boolean;
  areSubscribersPreferencesEnabled: boolean;
  isUpgradePageEnabled: boolean;
  isWalletEnabled: boolean;
  isWebBuilderEnabled: boolean;
  isSurveysV2Enabled: boolean;
  isPublicationSettingsV2Enabled: boolean;
  isPartnerProgramEnabled: boolean;
  isHiivDreamEnabled: boolean;
}) => (
  <QueryParamProvider ReactRouterRoute={RouteAdapter}>
    <SwitchPublicationViaQuery />
    <Routes>
      <Route path="/404" element={<ErrorPage statusCode={404} />} />
      <Route path="/500" element={<ErrorPage statusCode={500} />} />
      <Route path="/request_password_reset" element={<RequestPassword />} />
      <Route path="/password_reset" element={<PasswordReset />} />

      <Route path="/onboarding" element={<Onboarding />}>
        <Route path="/onboarding/personas" element={<OnboardingPersonas />} />
        <Route path="/onboarding/platform_migration" element={<OnboardingPlatformMigration />} />
        <Route path="/onboarding/publication_details" element={<OnboardingPublicationDetails />} />
        <Route path="/onboarding/how_did_you_hear_about_us" element={<OnboardingHowDidYouHearAboutUs />} />
        <Route path="/onboarding/trial_features" element={<OnboardingTrialFeatures />} />
        <Route path="/onboarding/business_questionnaire" element={<OnboardingBusinessQuestionnaire />} />
        <Route path="/onboarding/next_steps" element={<OnboardingNextSteps />} />
        <Route path="/onboarding" element={<Navigate to="/onboarding/personas" />} />
      </Route>

      <Route path="subscription_change/confirm/:token" element={<ConfirmSubscriptionChanges />} />

      <Route element={<AppLayout />}>
        <Route path="/" element={<PageLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/notifications" element={<Notifications />} />
          {isPartnerProgramEnabled && (
            <Route
              path="/partner_program"
              element={
                <RequireAuth roles={['admin']}>
                  <PartnerProgram />
                </RequireAuth>
              }
            />
          )}
          <Route path="/wrapped" element={<Wrapped />} />
          <Route path="/help" element={<HelpPage />} />

          {isBoostsLocked && (
            <>
              <Route
                path="/monetize/boosts"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route path="/monetize/boosts" element={<BoostsDisabled />} />
                <Route path="/monetize/boosts/marketplace" element={<BoostsDisabled />} />
                <Route path="/monetize/boosts/boosted_publication/:boostedPublicationId" element={<BoostsDisabled />} />
              </Route>
              <Route
                path="/grow/boosts"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route path="/grow/boosts" element={<BoostsDisabled />} />
                <Route path="/grow/boosts/new" element={<BoostsDisabled />} />
                <Route path="/grow/boosts/introduction" element={<BoostsDisabled />} />
                <Route
                  path="/grow/boosts/recommending_publication/:recommendingPublicationId"
                  element={<BoostsDisabled />}
                />
                <Route path="/grow/boosts/offers/:boostOfferId/edit" element={<BoostsDisabled />} />
              </Route>
            </>
          )}
          {isBoostsEnabled ? (
            <>
              <Route
                path="/monetize/boosts"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route path="/monetize/boosts" element={<MonetizeBoostsDashboard />} />
                <Route path="/monetize/boosts/marketplace" element={<MonetizeBoostsMarketplace />} />
                <Route
                  path="/monetize/boosts/boosted_publication/:boostedPublicationId"
                  element={<BoostedPublication />}
                />
                <Route path="/monetize/boosts/overview" element={<Navigate to="/monetize/boosts" />} />
              </Route>
              <Route
                path="/grow/boosts"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route path="/grow/boosts" element={<GrowBoostsDashboard />} />
                <Route path="/grow/boosts/new" element={<GrowBoostOfferNew />} />
                <Route path="/grow/boosts/introduction" element={<GrowBoostsIntro />} />
                <Route
                  path="/grow/boosts/recommending_publication/:recommendingPublicationId"
                  element={<RecommendingPublication />}
                />
                {/* This is acting as a redirect from the old page */}
                <Route
                  path="/grow/boosts/offers/:boostOfferId/applications"
                  element={<Navigate to="/grow/boosts?tab=applications" />}
                />
                <Route path="/grow/boosts/offers/:boostOfferId/edit" element={<GrowBoostOfferEdit />} />
                <Route path="/grow/boosts/overview" element={<Navigate to="/grow/boosts" />} />
              </Route>
            </>
          ) : (
            <>
              <Route
                path="/grow/boosts/overview"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <GrowBoostsOverview />
                  </RequireAuth>
                }
              />
              <Route path="/grow/boosts/*" element={<Navigate to="/grow/boosts/overview" />} />
              <Route
                path="/monetize/boosts/overview"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <MonetizeBoostsMarketplace />
                  </RequireAuth>
                }
              />
              <Route
                path="/monetize/boosts/marketplace"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <MonetizeBoostsMarketplace />
                  </RequireAuth>
                }
              />
              <Route path="/monetize/boosts/*" element={<Navigate to="/monetize/boosts/overview" />} />
            </>
          )}
          {isWalletEnabled ? (
            <Route
              path="/wallet"
              element={
                <RequireAuth roles={['admin']}>
                  <Wallet />
                </RequireAuth>
              }
            />
          ) : (
            <Route path="/wallet" element={<Navigate to="/" />} />
          )}

          <Route
            path="monetize/ads"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <MonetizeAdsLayout />
              </RequireAuth>
            }
          >
            <Route index element={<MonetizeAdsOverview />} />
            <Route path="reports" element={<MonetizeAdsReports />} />
          </Route>
          <Route
            path="/monetize/ads/opportunities/:opportunity_id"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <MonetizeAdsOpportunity />
              </RequireAuth>
            }
          />
          <Route
            path="/monetize/ads/reports"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/monetize/ads/reports/:report_id" element={<MonetizeAdsReport />} />
            <Route path="/monetize/ads/reports/:report_id/remittance" element={<MonetizeAdsRemittance />} />
          </Route>
          <Route path="/monetize/ads/pending" element={<Navigate to="/monetize/ads" />} />
          <Route path="/monetize/ads/upcoming" element={<Navigate to="/monetize/ads" />} />
          <Route path="/monetize/ads/past" element={<Navigate to="/monetize/ads" />} />

          {isContentTagsEnabled && (
            <>
              <Route path="/content_tags" element={<ContentTags />} />
              <Route path="/content_tags/new" element={<NewContentTag />} />
              <Route path="/content_tags/:contentTagId/edit" element={<EditContentTag />} />
            </>
          )}

          {isFormsEnabled ? (
            <Route
              path="/forms"
              element={
                <RequireAuth roles={['admin']}>
                  <FormPageLayout />
                </RequireAuth>
              }
            >
              <Route path="/forms" element={<Forms />} />
              <Route path="/forms/:formId/edit" element={<EditForm />} />
              <Route path="/forms/:formId" element={<Form />} />
              <Route path="/forms/:formId/summary" element={<FormSummary />} />
            </Route>
          ) : (
            <Route path="/forms/*" element={<FormsTeaser />} />
          )}
          {isLandingPagesEnabled && <Route path="/testimonials" element={<Testimonials />} />}
          <Route path="/verification_requests/new" element={<VerificationRequest />} />

          {hasReferralProgramV2 ? (
            <Route
              path="/referral_program"
              element={
                <RequireAuth roles={['admin', 'member']}>
                  <ReferralProgramLayout />
                </RequireAuth>
              }
            >
              <Route path="/referral_program/overview" element={<NewReferralProgramOverview />} />
              <Route path="/referral_program" element={<NewReferralProgramOverview />} />
              <Route path="/referral_program/milestones/new" element={<NewReferralProgram />} />
              <Route path="/referral_program/milestones/:milestoneId/edit" element={<EditReferralProgram />} />
            </Route>
          ) : (
            <Route
              path="/referral_program"
              element={
                <RequireAuth roles={['admin', 'member']}>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route path="/referral_program" element={<Navigate to="/referral_program/overview" />} />
              <Route path="/referral_program/overview" element={<ReferralProgramOverview />} />
              <Route path="/referral_program/fulfillment" element={<ReferralProgramFulfillment />} />
              <Route
                path="/referral_program/configure"
                element={<Navigate to="/referral_program/configure/milestones" />}
              />
              <Route path="/referral_program/configure/milestones" element={<ReferralProgramConfigureMilestones />} />
              <Route
                path="/referral_program/configure/milestones/new"
                element={<ReferralProgramConfigureNewMilestone />}
              />
              <Route
                path="/referral_program/configure/milestones/:milestoneId/edit"
                element={<ReferralProgramConfigureEditMilestone />}
              />
              <Route path="/referral_program/configure/rewards" element={<ReferralProgramConfigureRewards />} />
              <Route path="/referral_program/configure/settings" element={<ReferralProgramConfigureSettings />} />
              <Route path="/referral_program/configure/export" element={<ReferralProgramConfigureExportData />} />
            </Route>
          )}

          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/signup" element={<Navigate to="/" />} />
          {hasMultipleTeamMembers && <Route path="/invites/new" element={<NewInvite />} />}
          <Route path="/invites/:inviteId/accept" element={<InviteAccept />} />
          <Route path="/oauth_callback" element={<Navigate to="/" />} />
          <Route path="/guest_authors" element={<GuestAuthorsView />} />
          <Route
            path="/magic_links"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <MagicLinksView />
              </RequireAuth>
            }
          />
          <Route path="/posts" element={<Posts />} />
          <Route path="/posts/:postId" element={<PostOverview />} />
          <Route path="/posts/:postId/analytics" element={<PostAnalytics />} />
          <Route path="/posts/:postId/analytics/:factPostClickId" element={<ClickDetails />} />
          <Route path="/posts/:postId/analytics/click_map" element={<ClickMap />} />
          <Route path="/posts/:postId/ab_test" element={<SplitTest />} />
          <Route path="/posts/:postId/engagement-overview" element={<EngagementOverview />} />
          <Route path="/posts/:postId/premium_data" element={<PostPremiumData />} />
          {isPostsDashboardV2Enabled ? (
            <>
              <Route path="/posts/:postId/polls" element={<EngagementOverview defaultTab={TabType.POLLS} />} />
              {isCommentsEnabled && (
                <Route path="/posts/:postId/comments" element={<EngagementOverview defaultTab={TabType.COMMENTS} />} />
              )}
              <Route
                path="/posts/:postId/engagement"
                element={<EngagementOverview defaultTab={TabType.TOP_SUBSCRIBERS} />}
              />
            </>
          ) : (
            <>
              <Route path="/posts/:postId/polls" element={<PollsContainer />} />
              {isCommentsEnabled && <Route path="/posts/:postId/comments" element={<CommentsContainer />} />}
              <Route path="/posts/:postId/engagement" element={<RecipientsContainer />} />
            </>
          )}

          <Route path="/templates/:resourceType" element={<TemplatesView />}>
            <Route path="" element={<Templates />} />
            <Route path=":templateId" element={<Template />} />
            <Route path=":templateId/edit" element={<EditTemplate />} />
            <Route path="new" element={<NewTemplate />} />
          </Route>
          <Route
            path="/polls"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/polls" element={<Polls />} />
            <Route path="/polls/:pollId" element={<Poll />} />
            <Route path="/polls/overview" element={<PollsTeaser />} />
          </Route>
          <Route
            path="/subscribe_forms"
            element={
              <RequireAuth redirectTo="/settings" roles={['admin']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/subscribe_forms" element={<PublicationSusbcribeForms />} />
          </Route>
          <Route
            path="/recommendations"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <RecommendationsView />
              </RequireAuth>
            }
          >
            <Route path="" element={<RecommendationsViewList />} />
            <Route path="external" element={<RecommendationsViewExternal />} />
          </Route>
          <Route
            path="/reports"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/reports/:reportType" element={<ReportsShow />} />
            <Route path="/reports/:reportType/overview" element={<ReportsTeaser />} />
          </Route>
          <Route
            path="/subscribers"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <SubscribersLayout />
              </RequireAuth>
            }
          >
            <Route index element={<Subscribers />} />
            {isCustomFieldsEnabled && (
              <>
                <Route path="custom_fields" element={<CustomFields />} />
                <Route path="custom_fields/new" element={<NewCustomField />} />
                <Route path="custom_fields/:customFieldId/edit" element={<EditCustomField />} />
              </>
            )}
            {isSubscriberTaggingEnabled && <Route path="tags" element={<Tags />} />}
            {areSubscribersPreferencesEnabled && (
              <>
                <Route path="preferences/:subscriberPreferenceId/edit" element={<Preferences showForm />} />
                <Route path="preferences/new" element={<Preferences showForm />} />
                <Route path="preferences" element={<Preferences />} />
              </>
            )}
          </Route>

          {isSubscriberProfileV2Enabled ? (
            <Route
              path="/subscribers/:subscriberId"
              element={
                <RequireAuth roles={['admin', 'member']}>
                  <SubscriberLayoutV2 />
                </RequireAuth>
              }
            >
              <Route path="" element={<SubscriberPostActivityV2 />} />
              <Route path="automations" element={<SubscriberAutomationActivityV2 />} />
              <Route path="polls" element={<SubscriberPollActivityV2 />} />
              <Route path="referrals" element={<SubscriberReferralActivityV2 />} />
              <Route path="other_emails" element={<SubscriberOtherEmailActivityV2 />} />
              <Route path="profile_events" element={<SubscriberProfileEventsV2 />} />
              <Route path="billing_history" element={<SubscriberBillingHistoryV2 />} />

              {/* Legacy Routes */}
              <Route path="acquisition" element={<Navigate to="/subscribers" />} />
            </Route>
          ) : (
            <Route
              path="/subscribers/:subscriberId"
              element={
                <RequireAuth roles={['admin', 'member']}>
                  <SubscriberLayout />
                </RequireAuth>
              }
            >
              <Route path="" element={<SubscriberPostActivity />} />
              <Route path="automations" element={<SubscriberAutomationActivity />} />
              <Route path="polls" element={<SubscriberPollActivity />} />
              <Route path="referrals" element={<SubscriberReferralActivity />} />
              <Route path="other_emails" element={<SubscriberOtherEmailActivity />} />

              {/* Legacy Routes */}
              <Route path="acquisition" element={<Navigate to="/subscribers" />} />
            </Route>
          )}

          <Route
            path="/segments"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/segments" element={<Segments />} />
            <Route path="/segments/new" element={<NewSegment />} />
            <Route path="/segments/:segmentId" element={<SegmentView />}>
              <Route path="" element={<SegmentViewShow />} />
              <Route path="edit" element={<SegmentViewEdit />} />
              <Route path="blasts" element={<SegmentViewBlasts />} />
            </Route>
            <Route path="/segments/:segmentId/blasts/:emailMessageId" element={<SegmentViewBlast />} />
          </Route>

          <Route path="/welcome_email" element={<WelcomeEmailLayout />}>
            <Route path="overview" element={<WelcomeEmailOverview />} />
            <Route path="analytics" element={<WelcomeEmailAnalytics />} />
            <Route path="edit" element={<WelcomeEmailEdit />} />
            <Route path="polls" element={<WelcomeEmailPolls />} />
            <Route path="premium_data" element={<WelcomeEmailPremiumData />} />
          </Route>

          <Route path="/opt_in_email" element={<OptInEmailLayout />}>
            <Route path="overview" element={<OptInEmailOverview />} />
            <Route path="analytics" element={<OptInEmailAnalytics />} />
            <Route path="edit" element={<OptInEmailEdit />} />
          </Route>

          <Route path="/upsell_email" element={<UpsellEmailLayout />}>
            <Route path="overview" element={<UpsellEmailOverview />} />
            <Route path="analytics" element={<UpsellEmailAnalytics />} />
            <Route path="edit" element={<UpsellEmailEdit />} />
          </Route>

          <Route path="/stripe_migrations" element={<StripeMigrations />} />
          <Route path="/stripe_migrations/new" element={<StripeMigrationWizardStepOne />} />
          <Route
            path="/stripe_migrations/:stripeMigrationId/account_details"
            element={<StripeMigrationWizardStepTwo />}
          />
          <Route
            path="/stripe_migrations/:stripeMigrationId/map_products"
            element={<StripeMigrationWizardStepThree />}
          />
          <Route
            path="/stripe_migrations/:stripeMigrationId/preview_subscriptions"
            element={<StripeMigrationWizardStepFour />}
          />
          <Route path="/stripe_migrations/:stripeMigrationId/finished" element={<StripeMigrationWizardStepFive />} />

          <Route path="/custom_footer/edit" element={<CustomFooterEdit />} />
          <Route
            path="/automations"
            element={
              <RequireAuth roles={['admin', 'member', 'contributor']}>
                <Outlet />
              </RequireAuth>
            }
          >
            {isAutomationsEnabled && <Route path="/automations/new" element={<NewAutomation />} />}
            {isAutomationsEnabled ? (
              <Route path="/automations" element={<AutomationsList />} />
            ) : (
              <Route path="/automations" element={<AutomationsTeaser />} />
            )}
          </Route>
          <>
            {hasPremiumSubscribersDashboardV2 && (
              <Route
                path="/monetize/subscriptions"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route path="/monetize/subscriptions" element={<Navigate to="/monetize/subscriptions/growth" />} />
                <Route path="/monetize/subscriptions/growth" element={<MonetizeSubscriptionsGrowth />} />
                <Route path="/monetize/subscriptions/attribution" element={<MonetizeSubscriptionsAttribution />} />
                <Route path="/monetize/subscriptions/breakdown" element={<MonetizeSubscriptionsBreakdown />} />
                {isPublicationSettingsV2Enabled && (
                  <>
                    <Route path="/monetize/subscriptions/paid_tiers" element={<MonetizeSubscriptionsPaidTiers />} />
                    <Route
                      path="/monetize/subscriptions/paid_tiers"
                      element={
                        <RequireAuth redirectTo="/settings" roles={['admin']}>
                          {hidePremiumSettingsPage ? <UnderConstruction /> : <TierConfigurationLayout />}
                        </RequireAuth>
                      }
                    >
                      <Route path="new" element={<MonetizeSubscriptionsPaidTiersNew />} />
                      <Route path=":tierId/edit" element={<MonetizeSubscriptionsPaidTiersEdit />} />
                    </Route>
                  </>
                )}
              </Route>
            )}
            {hasPremiumSubscribersDashboard && !hasPremiumSubscribersDashboardV2 && (
              <Route
                path="/monetize/subscriptions"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <MonetizeSubscriptionsNext />
                  </RequireAuth>
                }
              />
            )}
            {!hasPremiumSubscribersDashboard && !hasPremiumSubscribersDashboardV2 && (
              <Route
                path="/monetize/subscriptions"
                element={
                  <RequireAuth roles={['admin', 'member']}>
                    <MonetizeSubscriptions />
                  </RequireAuth>
                }
              />
            )}
            <Route
              path="/monetize/subscriptions/overview"
              element={
                <RequireAuth roles={['admin', 'member']}>
                  <MonetizeSubscriptionsTeaser />
                </RequireAuth>
              }
            />
          </>
        </Route>
        {/* Account Routes */}
        <Route
          path="/settings"
          element={
            <RequireAuth roles={['admin', 'member']}>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route path="/settings" element={<Profile />} />
          <Route path="/settings/password" element={<Password />} />
          <Route path="/settings/notifications" element={<NotificationSettings />} />

          {/* Admin Routes */}
          {/* Settings V2 routes */}
          {isPublicationSettingsV2Enabled && (
            <>
              <Route path="/settings/publication" element={<SettingsLayout />}>
                <Route path="/settings/publication/general" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationGeneralSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/emails" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationEmailSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/emails/opt_in_email" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationOptInEmailSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/emails/opt_in_email/edit" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <EditOptInEmail />
                  </RequireAuth>}
                />
                <Route
                  path="/settings/publication/emails/welcome_email" element={
                    <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                      <PublicationWelcomeEmailSettings />
                    </RequireAuth>}
                />
                <Route path="/settings/publication/emails/welcome_email/edit" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <EditWelcomeEmail />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/content_import" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationContentImportSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/content_import/new" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <NewContentImport />
                  </RequireAuth>}
                />
                <Route
                  path="/settings/publication/subscribers_import" element={
                    <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                      <PublicationSubscribersImportSettings />
                    </RequireAuth>}
                />
                <Route path="/settings/publication/subscribers_import/new" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <ImportForm />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/export_data" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationExportDataSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/domain" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationDomainSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/webhooks" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <PublicationWebhooksSettings />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/webhooks/new" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <NewWebhookEndpoint />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/webhooks/:endpointId/edit" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <EditWebhookEndpoint />
                  </RequireAuth>}
                />
                <Route path="/settings/publication/webhooks/:endpointId/show" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <ShowWebhookEndpoint />
                  </RequireAuth>}
                />
                <Route
                  path="/settings/publication/webhooks/:endpointId/messages/:messageId/message_attempts/:messageAttemptId"
                  element={
                    <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                      <ShowMessageAttempt />
                    </RequireAuth>}
                />
                <Route
                  path="/settings/publication/payment_accounts"
                  element={
                    <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                      {isWalletEnabled ? (
                        <PublicationPaymentAccountsSettings />
                      ) : (
                        <Navigate to="/monetize/boosts/overview" />
                      )}
                    </RequireAuth>
                  }
                />
                <Route path="/settings/publication/rss_send_feed" element={<PublicationRSSSendFeedSettings />} />
              </Route>
              <Route path="/settings/workspace" element={<SettingsLayout />}>
                <Route path="/settings/workspace" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceOverview />
                  </RequireAuth>}
                />
                <Route path="/settings/workspace/overview" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceOverview />
                  </RequireAuth>}
                />
                <Route path="/settings/workspace/team" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceTeam />
                  </RequireAuth>}
                />
                <Route path="/settings/workspace/billing_and_plan" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceBillingAndPlan />
                  </RequireAuth>}
                />
                <Route
                  path="/settings/workspace/billing_and_plan/downgrade" element={
                    <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                      <WorkspaceBillingAndPlanDowngrade />
                    </RequireAuth>}
                />
                <Route path="/settings/workspace/api" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceAPI />
                  </RequireAuth>}
                />
                <Route path="/settings/workspace/new_publication" element={
                  <RequireAuth fallback={<NotAuthorized />} roles={['admin']}>
                    <WorkspaceNewPublication />
                  </RequireAuth>}
                />
              </Route>
            </>
          )}
          <Route
            path="/settings/publication"
            element={
              <RequireAuth redirectTo="/" roles={['admin']}>
                <PublicationSettingsLayout />
              </RequireAuth>
            }
          >
            <Route path="/settings/publication" element={<PublicationGeneral />} />

            <Route path="/settings/publication/domains" element={<PublicationDomains />} />
            <Route
              path="/settings/publication/premium"
              element={hidePremiumSettingsPage ? <UnderConstruction /> : <PublicationPremiumSettings />}
            />
            <Route path="/settings/publication/rss_feed" element={<PublicationRSSFeed />} />
            <Route path="/settings/publication/import_subscribers" element={<PublicationImportSubscribers />} />
            <Route path="/settings/publication/import_content" element={<PublicationImportContent />} />
            <Route path="/settings/publication/export_data" element={<PublicationExportData />} />
            <Route path="/settings/publication/subscribe_forms" element={<Navigate to="/subscribe_forms" />} />
            <Route path="/settings/publication/widgets" element={<PublicationWidgets />} />

            {/* This is acting as a redirect from the old page */}
            <Route path="/settings/publication/boosts" element={<Navigate to="/settings/payment_accounts" />} />
          </Route>

          <Route
            path="/settings/publication/premium/tiers"
            element={
              <RequireAuth redirectTo="/" roles={['admin']}>
                {hidePremiumSettingsPage ? <UnderConstruction /> : <TierConfigurationLayout />}
              </RequireAuth>
            }
          >
            <Route path="new" element={<TierConfigurationNew />} />
            <Route path=":tierId" element={<TierConfigurationExisting />} />
          </Route>

          <Route
            path="/settings/website"
            element={
              <RequireAuth redirectTo="/" roles={['admin']}>
                <Website />
              </RequireAuth>
            }
          >
            <Route path="/settings/website" element={<WebsiteGeneral />} />
            <Route path="/settings/website/analytics" element={<WebsiteAnalytics />} />
            <Route path="/settings/website/advanced" element={<WebsiteAdvanced />} />
            <Route path="/settings/website/pages" element={<WebsitePages />} />
            <Route path="/settings/website/navbar" element={<WebsiteNavbar />} />
            {isCommentsEnabled && <Route path="/settings/website/comments" element={<WebsiteComments />} />}
          </Route>

          <Route
            path="/settings/payment_accounts"
            element={
              <RequireAuth redirectTo="/" roles={['admin']}>
                {isWalletEnabled ? <PaymentAccounts /> : <Navigate to="/monetize/boosts/overview" />}
              </RequireAuth>
            }
          />

          {/* Organization Level Admin Routes */}
          <Route
            path="/settings/integrations"
            element={
              <RequireAuth fallback={<NotAuthorized />} roles={['organization_admin']}>
                <Integrations />
              </RequireAuth>
            }
          >
            <Route path="/settings/integrations" element={<Navigate to="/settings/integrations/api" />} />
            <Route path="/settings/integrations/api" element={<IntegrationsApi />} />
            <Route path="/settings/integrations/imports" element={<IntegrationsImports />} />
            <Route path="/settings/integrations/webhooks" element={<IntegrationsWebhooks />} />
            <Route
              path="/settings/integrations/webhooks/endpoints/new"
              element={<IntegrationsWebhooksEndpointsCreate />}
            />
            <Route
              path="/settings/integrations/webhooks/endpoints/:endpointId"
              element={<IntegrationsWebhooksEndpoint />}
            />
            <Route
              path="/settings/integrations/webhooks/endpoints/:endpointId/messages/:messageId/message_attempts/:messageAttemptId"
              element={<IntegrationsWebhooksEndpointMessage />}
            />
          </Route>
          <Route
            path="/settings/billing"
            element={
              <RequireAuth fallback={<NotAuthorized />} roles={['organization_admin']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/settings/billing" element={hideBillingPage ? <UnderConstruction /> : <Billing />} />
            {isDowngradingEnabled && <Route path="/settings/billing/downgrade" element={<Downgrade />} />}
          </Route>
          <Route
            path="/settings/company"
            element={
              <RequireAuth fallback={<NotAuthorized />} roles={['organization_admin']}>
                <Company />
              </RequireAuth>
            }
          >
            <Route path="/settings/company" element={<CompanyGeneral />} />
            <Route path="/settings/company/publications" element={<CompanyPublications />} />
            <Route path="/settings/company/team" element={<CompanyTeam />} />
          </Route>
        </Route>

        {/* Current User Routes */}
        {isPublicationSettingsV2Enabled && (
          <Route
            path="/current_user"
            element={
              <RequireAuth roles={['admin', 'member']}>
                <Outlet />
              </RequireAuth>
            }
          >
            <Route path="/current_user" element={<CurrentUserSettingsLayout />}>
              <Route path="/current_user/personal_info" element={<PersonalInfoSettings />} />
              <Route path="/current_user/account_security" element={<AccountSecuritySettings />} />
              <Route path="/current_user/notification_preferences" element={<CurrentUserNotificationPreferences />} />
            </Route>
          </Route>
        )}
      </Route>

      {/* Old settings redirects */}
      <Route path="/" element={<Outlet />}>
        <Route path="/admin" element={<Navigate to="/settings/company" />} />
        <Route path="/api_keys" element={<Navigate to="/settings/integrations" />} />
        <Route path="/billing" element={<Navigate to="/settings/billing" />} />
        <Route path="/company/edit" element={<Navigate to="/settings/company" />} />
        <Route path="/content_imports/*" element={<Navigate to="/settings/publication/import_content" />} />
        <Route path="/custom_domains" element={<Navigate to="/settings/publication/domains" />} />
        <Route path="/domain_settings/edit" element={<Navigate to="/settings/publication/domains" />} />
        <Route path="/export_data" element={<Navigate to="/settings/publication/export_data" />} />
        <Route path="/external_embeds/*" element={<Navigate to="/settings/publication/subscribe_forms" />} />
        <Route path="/notifications/edit" element={<Navigate to="/settings/notifications" />} />
        <Route path="/password/edit" element={<Navigate to="/settings/password" />} />
        <Route path="/premium/edit" element={<Navigate to="/settings/publication/premium" />} />
        <Route path="/profile/edit" element={<Navigate to="/settings" />} />
        <Route path="/publication/edit" element={<Navigate to="/settings/publication" />} />
        <Route path="/publications/new" element={<Navigate to="/settings/publication" />} />
        <Route path="/settings/premium_gifts/*" element={<Navigate to="/settings/publication/premium" />} />
        <Route
          path="/settings/import_subscribers"
          element={<Navigate to="/settings/publication/import_subscribers" />}
        />
        <Route path="/settings/rss_feeds" element={<Navigate to="/settings/publication/rss_feed" />} />
        <Route path="/users/:id" element={<Navigate to="/settings/company/team" />} />
        <Route path="/web_template/edit" element={<Navigate to="/settings/website" />} />
      </Route>

      <Route element={<FullscreenLayout />}>
        {/** WEBSITE BUILDER V2 */}
        {isHiivDreamEnabled && (
          <Route path="/website_builder_v2" element={<Outlet />}>
            <Route path="/website_builder_v2/" element={<WebsiteBuilderV2 />} />
            <Route path="/website_builder_v2/onboarding" element={<WebsiteBuilderV2Onboarding />} />
            <Route path="/website_builder_v2/settings" element={<WebsiteBuilderV2SettingsLayout />}>
              <Route path="/website_builder_v2/settings/" element={<WebsiteBuilderV2SettingsGeneral />} />
              <Route path="/website_builder_v2/settings/seo" element={<WebsiteBuilderV2SettingsSEO />} />
              <Route path="/website_builder_v2/settings/comments" element={<WebsiteBuilderV2SettingsComments />} />
              <Route
                path="/website_builder_v2/settings/signup_flows"
                element={<WebsiteBuilderV2SettingsSignupFlows />}
              />
              <Route path="/website_builder_v2/settings/pixels" element={<WebsiteBuilderV2SettingsPixels />} />
              <Route path="/website_builder_v2/settings/gdpr" element={<WebsiteBuilderV2SettingsGDPR />} />
              <Route path="/website_builder_v2/settings/page/:pageId" element={<WebsiteSettingsPage />} />
              <Route
                path="/website_builder_v2/settings/notifications"
                element={<WebsiteBuilderV2SettingsNotifications />}
              />
              <Route path="/website_builder_v2/settings/security" element={<WebsiteBuilderV2SettingsSecurity />} />
            </Route>
            <Route path="/website_builder_v2/page/:pageId" element={<WebsiteBuilderV2PageEditor />} />
            <Route path="/website_builder_v2/navbar" element={<WebsiteBuilderV2NavbarEditor />} />
          </Route>
        )}

        {/** WEBSITE BUILDER V1 */}
        {isWebBuilderEnabled && (
          <Route path="/website_builder" element={<Outlet />}>
            <Route path="/website_builder/" element={<LayoutPage />} />
            <Route path="/website_builder/custom_pages/view" element={<WebBuilderCustomPagesView />} />
            <Route path="/website_builder/custom_pages" element={<WebBuilderCustomPages />}>
              <Route path="/website_builder/custom_pages/new" element={<NewCustomPageBuilder />} />
              <Route path="/website_builder/custom_pages/:customPageId" element={<CustomPageBuilder />} />
            </Route>
            <Route path="/website_builder/settings" element={<AdvancedPage />}>
              <Route path="/website_builder/settings/pixels" element={<WebBuilderPixels />} />
              <Route path="/website_builder/settings/seo" element={<WebBuilderSeo />} />
              <Route path="/website_builder/settings/social_links" element={<WebBuilderSocialLinks />} />
              <Route path="/website_builder/settings/email_capture" element={<WebBuilderEmailCapture />} />
              <Route path="/website_builder/settings/signup_flow" element={<WebBuilderSignupFlow />} />
              <Route path="/website_builder/settings/static_pages" element={<WebBuilderStaticPages />} />
              <Route path="/website_builder/settings/comments" element={<WebBuilderComments />} />
              <Route path="/website_builder/settings/notifications" element={<WebBuilderNotifications />} />
              <Route path="/website_builder/settings/mobile_app" element={<WebBuilderMobileApp />} />
              <Route path="/website_builder/settings/security" element={<WebBuilderSecurity />} />
              <Route path="/website_builder/settings/theme_reset" element={<WebBuilderThemeReset />} />
              <Route
                path="/website_builder/settings/embed_recommendations_widget"
                element={<WebBuilderEmbedRecommendationsWidget />}
              />
              <Route path="/website_builder/settings" element={<Navigate to="/website_builder/settings/pixels" />} />
            </Route>
          </Route>
        )}

        {isPagesEnabled && (
          <Route path="/pages" element={<CustomPages />}>
            <Route path="/pages/new" element={<NewCustomPage />} />
            <Route path="/pages/:pageId/edit" element={<EditCustomPage />} />
          </Route>
        )}

        {isLandingPagesEnabled && !isWebBuilderEnabled && (
          <Route path="design_lab/landing_page" element={<DesignLabLandingPage />} />
        )}
        {isUpgradePageEnabled && !isWebBuilderEnabled && (
          <Route path="design_lab/upgrade_page" element={<DesignLabUpgradePage />} />
        )}
        {isLandingPagesEnabled && !isWebBuilderEnabled && (
          <Route path="design_lab/home_page" element={<DesignLabHomePage />} />
        )}
        <Route path="/posts/:postId/edit" element={<PostEdit />} />
        <Route path="/post_themes/edit" element={<ThemeEditor />} />
        <Route path="/post_themes/:postId/edit" element={<ThemeEditor />} />
        <Route path="/segments/:segmentId/blasts/:emailMessageId/edit" element={<SegmentViewBlastEdit />} />
        {isAutomationsEnabled && (
          <Route path="/automations/:automationId" element={<AutomationView />}>
            <Route path="" element={<Navigate to="overview" />} />
            <Route path="workflow" element={<AutomationViewEdit />} />
            <Route path="workflow/steps/:automationStepId/configure" element={<AutomationViewEdit />} />
            <Route path="workflow/triggers/configure" element={<AutomationViewEdit />} />
            <Route path="workflow/triggers/:triggerId/configure" element={<AutomationViewEdit />} />
            <Route path="overview" element={<AutomationViewOverview />} />
            <Route path="settings" element={<AutomationViewSettings />} />
          </Route>
        )}
        {isFormsEnabled && isSurveysV2Enabled && (
          <Route
            path="/forms/:formId/edit"
            element={
              <RequireAuth roles={['admin']}>
                <EditForm />
              </RequireAuth>
            }
          >
            <Route path="" element={<Navigate to="form_builder" />} />
            <Route path="form_builder" element={<FormBuilder />} />
            <Route path="responses" element={<Form isNewLayout />} />
            <Route path="summary" element={<FormSummary isNewLayout />} />
            <Route path="settings" element={<FormSettings isNewLayout />} />
          </Route>
        )}
      </Route>

      <Route
        element={
          <RequireAuth redirectTo="/" roles={['system_admin']}>
            <SystemAdminLayout />
          </RequireAuth>
        }
      >
        <Route path="/system_admin/users" element={<SystemAdminUsers />} />
        <Route path="/system_admin/users/:userId" element={<SystemAdminUser />} />
        <Route path="/system_admin/users/masquerade" element={<SystemAdminUserMasquerade />} />
        <Route path="/system_admin/organizations" element={<SystemAdminOrganizations />} />
        <Route path="/system_admin/organizations/:organizationId" element={<SystemAdminOrganization />} />
        <Route path="/system_admin/blocked_domains" element={<SystemAdminBlockedDomains />} />
        <Route path="/system_admin/allowed_emails" element={<SystemAdminAllowedEmails />} />
        <Route path="/system_admin/blocked_links" element={<SystemAdminBlockedLinks />} />
        <Route path="/system_admin/blocked_images" element={<SystemAdminBlockedImages />} />
        <Route path="/system_admin/blocked_emails" element={<SystemAdminBlockedEmails />} />
        <Route path="/system_admin/suppressed_emails" element={<SystemAdminSuppressedEmails />} />
        <Route path="/system_admin/blocked_phone_numbers" element={<SystemAdminBlockedPhoneNumbers />} />
        <Route path="/system_admin/legit_organizations" element={<SystemAdminLegitOrganizations />} />
        <Route path="/system_admin/spam_content" element={<SystemAdminSpamContent />} />
        <Route path="/system_admin/organization_flags" element={<SystemAdminOrganizationFlags />} />
        <Route path="/system_admin/temp_users" element={<SystemAdminTempUsers />} />
        <Route path="/system_admin/publications/:publicationId" element={<SystemAdminPublication />} />
        <Route path="/system_admin/bug_reports/:bugReportId" element={<SystemAdminBugReport />} />
      </Route>

      <Route
        element={
          <RequireAuth redirectTo="/settings" roles={['system_accountant']}>
            <AdminReportLayout />
          </RequireAuth>
        }
      >
        <Route path="/admin_reports/ledgers" element={<AdminReportLedgers />} />
      </Route>

      <Route
        path="ad_network/*"
        element={
          <RequireAuth redirectTo="/settings" roles={['ad_network_admin']}>
            <AdNetworkApp />
          </RequireAuth>
        }
      />
      <Route path="advertiser_access/*" element={<AdvertiserAccessApp />} />

      <Route path="/auth/mailchimp" element={<AuthMailchimp />} />
      <Route path="/auth/mailchimp/callback" element={<AuthMailchimpCallback />} />

      <Route path="*" element={<ErrorPage statusCode={404} />} />
    </Routes>
  </QueryParamProvider>
);

const App: React.FunctionComponent = () => (
  <CurrentUserProvider>
    <CurrentUserContext.Consumer>
      {(value) =>
        value?.isLoading ? (
          <Loading />
        ) : (
          value?.currentUser && (
            <CurrentPublicationProvider fallbackPublicationId={value?.currentUser?.primary_publication_id || ''}>
              <EnsureSettingsQueryParams hasSettingsV2={value?.currentUser?.has_settings_v2}>
                <SettingsProvider>
                  <AppLayoutProvider>
                    <SupportModalProvider>
                      <PermissionsProvider>
                        <RolesProvider>
                          <PermissionsContext.Consumer>
                            {(permissionsValue) => {
                              return (
                                <SettingsContext.Consumer>
                                  {(settingsValue) => {
                                    return (
                                      <Suspense fallback={<Loading />}>
                                        {!settingsValue?.isLoading && !permissionsValue?.isLoading && (
                                          <AuthenticatedRoutes
                                            hasMultipleTeamMembers={
                                              settingsValue?.settings?.multiple_team_members || false
                                            }
                                            hasPremiumSubscribersDashboard={
                                              settingsValue?.settings?.subscribers_premium_dashboard || false
                                            }
                                            hasPremiumSubscribersDashboardV2={
                                              settingsValue?.settings?.subscribers_premium_dashboard_v2 || false
                                            }
                                            hasReferralProgramV2={settingsValue?.settings?.referral_program_v2 || false}
                                            hideBillingPage={settingsValue?.settings?.tiered_pricing_rollout || false}
                                            hidePremiumSettingsPage={
                                              settingsValue?.settings?.premium_tiers_rollout || false
                                            }
                                            isAutomationsEnabled={settingsValue?.settings?.automations || false}
                                            isBoostsEnabled={settingsValue?.settings?.boosts || false}
                                            isBoostsLocked={!settingsValue?.settings?.organization_boosts || false}
                                            isWalletEnabled={settingsValue?.settings?.wallet || false}
                                            isCommentsEnabled={settingsValue?.settings?.comments || false}
                                            isContentTagsEnabled={settingsValue?.settings?.content_tags || false}
                                            isCustomFieldsEnabled={settingsValue?.settings?.custom_fields || false}
                                            isDowngradingEnabled={settingsValue?.settings?.downgrades || false}
                                            isFormsEnabled={settingsValue?.settings?.forms || false}
                                            isLandingPagesEnabled={settingsValue?.settings?.landing_pages || false}
                                            isPagesEnabled={settingsValue?.settings?.custom_pages || false}
                                            isPostsDashboardV2Enabled={
                                              settingsValue?.settings?.posts_dashboard_v2 || false
                                            }
                                            isSubscriberProfileV2Enabled={
                                              settingsValue?.settings?.subscriber_profile_v2 || false
                                            }
                                            isSubscriberTaggingEnabled={
                                              settingsValue?.settings?.subscriber_tagging || false
                                            }
                                            areSubscribersPreferencesEnabled={
                                              settingsValue?.settings?.subscribers_preferences || false
                                            }
                                            isUpgradePageEnabled={settingsValue?.settings?.upgrade_page || false}
                                            isWebBuilderEnabled={settingsValue?.settings?.web_builder || false}
                                            isSurveysV2Enabled={settingsValue?.settings?.surveys_v2 || false}
                                            isPublicationSettingsV2Enabled={
                                              value?.currentUser?.has_settings_v2 || false
                                            }
                                            isPartnerProgramEnabled={settingsValue?.settings?.partner_program || false}
                                            isHiivDreamEnabled={settingsValue?.settings?.hiiv_dream || false}
                                          />
                                        )}
                                      </Suspense>
                                    );
                                  }}
                                </SettingsContext.Consumer>
                              );
                            }}
                          </PermissionsContext.Consumer>
                        </RolesProvider>
                      </PermissionsProvider>
                    </SupportModalProvider>
                  </AppLayoutProvider>
                </SettingsProvider>
              </EnsureSettingsQueryParams>
            </CurrentPublicationProvider>
          )
        )
      }
    </CurrentUserContext.Consumer>
  </CurrentUserProvider>
);

export default App;
