import React from 'react';
import { CloudArrowDownIcon } from "@heroicons/react/24/solid";

import { Button } from '@/ui/Button';

import BodyContainer from '../components/BodyContainer';
import CardHeader from '../components/CardHeader';

import { useExportDataContext } from './context';

const buttonTitles = {
  basic_subscriber: 'Export All Basic Subscribers',
  subscribers: 'Export All Subscribers',
  posts: 'Export All Posts',
};

interface ExportActionProps {
  title: string;
  description: string;
  type: 'basic_subscriber' | 'subscribers' | 'posts';
}

const ExportAction: React.FC<ExportActionProps> = ({ title, description, type }) => {
  const { areSubscribersExporting, arePostsExporting, disabled, createExport } = useExportDataContext();
  const isExporting = type === 'posts' ? arePostsExporting : areSubscribersExporting;
  const buttonTitle = buttonTitles[type];

  return (
    <BodyContainer>
      <CardHeader title={title} description={description} />

      <div>
        <Button
          variant="primary"
          type="submit"
          loading={isExporting}
          Icon={CloudArrowDownIcon}
          disabled={disabled}
          onClick={() => createExport(type)}
        >
          {isExporting ? 'Exporting...' : buttonTitle}
        </Button>
      </div>
    </BodyContainer>
  );
};

export default ExportAction;
