import { ChangeEvent, useCallback, useState } from 'react';
import { TextT, Trash } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { StyleSheetManager } from 'styled-components';

import { MediaLibrary } from '@/components/MediaLibrary';
import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';
import { useFileUpload } from '@/components/TiptapEditor/extensions/ImageUpload/view/hooks';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Asset } from '@/interfaces/asset';

import { Button } from '../../../UI/Button';
import { ColorPicker } from '../../../UI/ColorPicker';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../UI/Tabs';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';
import { useUploader } from '../../hooks/useUploader';

const DEFAULT_COLOR = '#000000FF';

export const BackgroundSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodePos, activeNodeAttributes } = useActiveNode(editor);

  const { backgroundColor, backgroundVideo, backgroundImage, backgroundDarken } = activeNodeAttributes;

  const [currentPublicationId] = useCurrentPublicationState();
  const [showImageLibrary, setShowImageLibrary] = useState(false);

  const onUpload = useCallback(
    (asset: Asset) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundImage', asset.url);
        return true;
      });
    },
    [activeNodePos, editor]
  );

  const { uploadFile } = useUploader({ publicationId: currentPublicationId, onUpload });
  const { handleUploadClick, ref: fileInputRef } = useFileUpload();

  const handleUpload = useCallback(
    (payload: OnMediaPayload) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundImage', payload.media.url);
        return true;
      });
    },
    [activeNodePos, editor]
  );

  const handleDelete = useCallback(() => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'backgroundImage', null);
      return true;
    });
  }, [activeNodePos, editor]);

  const handleBackgroundDarkenChange = useCallback(
    (value: string) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundDarken', value === 'yes');
        return true;
      });
    },
    [activeNodePos, editor]
  );

  const handleMediaURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundImage', event.target.value);
        return true;
      });
    },
    [activeNodePos, editor]
  );

  const [color, setColor] = useState<string>(backgroundColor || DEFAULT_COLOR);

  const onSetColor = useCallback(
    (value: string | null) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundColor', value || DEFAULT_COLOR);
        return true;
      });
    },
    [activeNodePos, editor]
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Fill
          </Text>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <TextT className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  Image
                </Text>
              </div>
              <Trash className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={20}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <Text size="sm" weight="semibold">
            Background
          </Text>
          <Tabs defaultValue="media">
            <TabsList>
              <TabsTrigger value="color">Color</TabsTrigger>
              <TabsTrigger value="media">Media</TabsTrigger>
            </TabsList>
            <TabsContent value="color">
              <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
                <ColorPicker
                  selectedColor={color}
                  onSetColor={(value: string | null) => {
                    if (!value) {
                      setColor(DEFAULT_COLOR);
                      onSetColor(null);
                      return;
                    }
                    setColor(value);
                    onSetColor(value);
                  }}
                />
              </div>
            </TabsContent>
            <TabsContent value="media" className="flex flex-col gap-6">
              <div className="w-full min-w-0 flex justify-center items-center relative">
                {/* 
                  This is an escape hatch to allow styled components to work outside
                  of the editor's iframe.
                 */}
                <StyleSheetManager target={document.head}>
                  <MediaLibrary
                    isOpen={showImageLibrary}
                    onClose={() => setShowImageLibrary(false)}
                    publicationId={currentPublicationId}
                    onMediaSelect={handleUpload}
                  />
                </StyleSheetManager>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="h-0 opacity-0 overflow-hidden w-0"
                  accept=".jpg,.jpeg,.png,.webp,.gif"
                  onChange={(e) => (e.target.files ? uploadFile(e.target.files[0]) : null)}
                />
                <div className="w-full h-[150px] bg-wb-secondary rounded-lg shadow-sm flex flex-col gap-2 items-center justify-center">
                  {backgroundImage && (
                    <img src={backgroundImage} alt="background" className="w-full h-full object-contain" />
                  )}
                  {!(backgroundImage || backgroundVideo) && (
                    <>
                      <Button size="sm" variant="secondary" onClick={handleUploadClick}>
                        Upload an Image
                      </Button>

                      <Text size="2xs" weight="medium" variant="secondary">
                        OR
                      </Text>

                      <Button size="sm" variant="secondary" onClick={() => setShowImageLibrary(true)}>
                        Use from library
                      </Button>
                    </>
                  )}
                </div>

                <div className="absolute top-3 right-3 cursor-pointer hover:bg-wb-secondary/50 rounded-lg p-1">
                  <Trash className="text-wb-secondary hover:text-wb-danger" weight="bold" onClick={handleDelete} />
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <Text size="xs" weight="medium" variant="secondary">
                  OR
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Text size="2xs" variant="secondary" weight="medium" className="w-[100px]">
                    Media URL
                  </Text>
                  <input
                    className="outline-none bg-wb-secondary rounded-lg shadow-sm p-2 border-transparent border focus:outline-none w-full text-xs"
                    placeholder="https://google.com"
                    value={backgroundImage}
                    onChange={handleMediaURLChange}
                  />
                </div>
              </div>
              <hr />
              <div className="flex items-center gap-2">
                <Text size="2xs" variant="secondary" weight="medium" className="w-[100px]">
                  Darkened
                </Text>
                <div className="w-full bg-wb-secondary rounded-lg shadow-sm">
                  <ToggleGroup
                    className="p-[2px]"
                    type="single"
                    defaultValue="yes"
                    value={backgroundDarken ? 'yes' : 'no'}
                    onValueChange={handleBackgroundDarkenChange}
                  >
                    <ToggleGroupItem value="yes" className="grow">
                      <Text variant={backgroundDarken ? 'accent' : 'secondary'} size="2xs" weight="medium">
                        Yes
                      </Text>
                    </ToggleGroupItem>
                    <ToggleGroupItem value="no" className="grow">
                      <Text variant={!backgroundDarken ? 'accent' : 'secondary'} size="2xs" weight="medium">
                        No
                      </Text>
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </PopoverContent>
    </Popover>
  );
};
