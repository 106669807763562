import { SparklesIcon } from '@heroicons/react/20/solid';

import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { capitalize } from '@/utils';
import getLocaleString from '@/utils/getLocaleString';

import PlanCardBase from '../PlanCardBase';

const TrialPlan = () => {
  const currentPublicationId = useCurrentPublicationId();
  const trialStatusQuery = useTrialStatus(currentPublicationId);
  const { data: trialStatus } = trialStatusQuery || [];
  const underlineText = trialStatus
    ? `On day ${getLocaleString(trialStatus?.current_day)} of ${getLocaleString(trialStatus?.total_days)}`
    : '';

  return (
    <PlanCardBase
      title={`${capitalize(trialStatus?.selected_plan || 'Scale')} Plan`}
      variant="tertiary"
      tagText="Trial"
      percentCompleted={Math.min(trialStatus?.percentage_completed, 100) || 0}
      overlineText="Free Trial time period"
      underlineText={underlineText}
      TagIcon={SparklesIcon}
    />
  );
};

export default TrialPlan;
