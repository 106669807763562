import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { rootStyles } from '@shared/dream-components';
import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import { CollabHistory, CollabOnUpdateProps } from '@tiptap-pro/extension-collaboration-history';
import cx from 'classnames';

import { LinkMenu, RemoveBlockMenu, TextMenu } from '@/components/TiptapEditor/components/menus';
import { ThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer';
import {
  ThreadComposerConsumer,
  ThreadComposerProvider,
} from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { AdvertisementOpportunityMenu } from '@/components/TiptapEditor/extensions/AdvertisementOpportunity/menus/AdvertisementOpportunityMenu';
import { AdvertisementOpportunityLogoMenu } from '@/components/TiptapEditor/extensions/AdvertisementOpportunityLogo/menus/AdvertisementOpportunityLogoMenu';
import { AudioMenu } from '@/components/TiptapEditor/extensions/Audio/menus/AudioMenu';
import { BlockquoteFigureMenu } from '@/components/TiptapEditor/extensions/BlockquoteFigure/menus';
import { ButtonMenu } from '@/components/TiptapEditor/extensions/Button/menus';
import { FileAttachmentMenu } from '@/components/TiptapEditor/extensions/FileAttachment/menus/FileAttachmentMenu';
import { GenericEmbedMenu } from '@/components/TiptapEditor/extensions/GenericEmbed/menus';
import { CaptionMenu } from '@/components/TiptapEditor/extensions/ImageBlock/menus/CaptionMenu';
import { ImageBlockMenu } from '@/components/TiptapEditor/extensions/ImageBlock/menus/ImageBlockMenu';
import { ColumnsMenu } from '@/components/TiptapEditor/extensions/MultiColumn/menus/ColumnsMenu';
import { PollBlockMenu } from '@/components/TiptapEditor/extensions/Poll/menus/PollBlockMenu';
import { TableColumnMenu, TableRowMenu } from '@/components/TiptapEditor/extensions/Table/menus';
import { TableOfContentsMenu } from '@/components/TiptapEditor/extensions/TableOfContents/menus';
import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { UpgradeIntent as UpgradeIntentModal } from '@/components/UpgradeIntent';
import { Settings } from '@/interfaces/setting';
import { IntentAction } from '@/interfaces/upgrades';
import { EditorContext } from '@/pages/Post/Edit/EditorContext';
import { PLAN, PREMIUM_PLANS } from '@/utils/plans';

import { AttributesPanel } from './AttributesPanel/AttributesPanel';
import { ExtensionKit } from './extensions/extension-kit';
import { DragHandle } from './extensions/Hover/views/DragHandle';
import { HoverBox } from './extensions/Hover/views/HoverBox';
import { InsertPanel } from './InsertPanel/InsertPanel';
import { SelectionBox } from './SelectionBox/SelectionBox';
import { ATTRIBUTES_PANEL_ID, LAYERS_PANEL_ID, SIDE_INSERT_PANEL_ID, VERSION_HISTORY_PANEL_ID } from './constants';
import { LayersPanel } from './LayersPanel';
import { Styled } from './styled';
import { VersionHistoryPanel } from './VersionHistoryPanel';
import { ColumnHandle } from './extensions/Columns/views/ColumnHandle';

interface TiptapEditorProps {
  allowAds?: boolean;
  allowPolls?: boolean;
  className?: string;
  content?: Content;
  onBlur?: (props: { editor: Editor; event: Event }) => void;
  onUpdate?: (props: { editor: Editor; transaction: Transaction }) => void;
  onUsersUpdate?: (users: EditorUser[]) => void;
  provider?: TiptapCollabProvider;
  publicationId: string;
  settings: Settings;
  useCollabHistory?: boolean;
  userColor?: string;
  userId?: string;
  userName?: string;
  usesCollaboration?: boolean;
  onVersionUpdate?: (payload: CollabOnUpdateProps) => void;
  threadsSidebarOpen?: boolean;
  shouldAutoFocus?: boolean;
}

interface UpgradeIntentModalState {
  isOpen: boolean;
  action?: IntentAction;
  plan?: keyof typeof PREMIUM_PLANS;
}

export const DreamEditor = ({
  publicationId,
  settings,
  onBlur,
  onUpdate,
  onUsersUpdate,
  userId,
  userName,
  userColor,
  usesCollaboration,
  provider,
  className,
  allowPolls = false,
  allowAds = false,
  content = '',
  useCollabHistory = false,
  onVersionUpdate,
  threadsSidebarOpen,
  shouldAutoFocus = true,
}: TiptapEditorProps): JSX.Element | null => {
  const menuContainerRef = useRef(null);
  const [hideTextMenu, setHideTextMenu] = useState(false);
  const hasContent = content && Object.keys(content).length > 0;

  const { setEditor, setShowSearchAndReplaceMenu, setShowThreadsSidebar } = useContext(EditorContext);

  const [upgradeIntentModal, setUpgradeIntentModal] = useState<UpgradeIntentModalState>({
    isOpen: false,
    action: undefined,
  });

  const editor = useEditor({
    extensions: [
      ...(ExtensionKit({
        publicationId,
        userName,
        userId,
        userColor,
        usesCollaboration,
        provider,
        settings,
        allowPolls,
        allowAds,
        onToggleUpgradeIntentModal: (action: IntentAction, plan: keyof typeof PREMIUM_PLANS) => {
          setUpgradeIntentModal((prevState) => ({ isOpen: !prevState.isOpen, action, plan }));
        },
        additionalShortcuts: {
          'Mod-f': () => {
            setShowSearchAndReplaceMenu(true);

            setTimeout(() => document.querySelector<HTMLInputElement>('#search-input')?.focus());

            return true;
          },
        },
        openThreadsSidebar: () => setShowThreadsSidebar(true),
      }) as any),
      useCollabHistory ? CollabHistory.configure({ provider, onUpdate: onVersionUpdate }) : null,
    ],
    editorProps: {
      attributes: {
        class: `${className || ''} ${
          rootStyles.rootElement
        } relative flex flex-col items-center justify-start whitespace-prewrap overflow-break-wrap min-h-dvh outline-none`,
      },
    },
    onCreate: ({ editor: tiptapEditor }) => {
      setEditor(tiptapEditor as any);

      tiptapEditor.view.dom.addEventListener('mousedown', () => setHideTextMenu(true));
      tiptapEditor.view.dom.addEventListener('mouseup', () => setHideTextMenu(false));
    },
    onBlur: (props) => {
      onBlur?.(props);
    },
    onUpdate,
    autofocus: shouldAutoFocus,
    ...(usesCollaboration
      ? {}
      : {
          content: hasContent
            ? content
            : {
                type: 'doc',
                content: [
                  {
                    type: 'section',
                    attrs: {
                      id: 'id',
                    },
                  },
                ],
              },
        }),
  });

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      const names = user.name?.split(' ');
      const firstName = names?.[0];
      const lastName = names?.[names.length - 1];
      const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`;

      return { ...user, initials: initials.length ? initials : '?' };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  const sideInsertPanelEl = useRef<HTMLDivElement | null>(null);
  const attributesPanelEl = useRef<HTMLDivElement | null>(null);
  const layersPanelEl = useRef<HTMLDivElement | null>(null);
  const versionHistoryPanelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    sideInsertPanelEl.current = document.getElementById(SIDE_INSERT_PANEL_ID) as HTMLDivElement;
    attributesPanelEl.current = document.getElementById(ATTRIBUTES_PANEL_ID) as HTMLDivElement;
    layersPanelEl.current = document.getElementById(LAYERS_PANEL_ID) as HTMLDivElement;
    versionHistoryPanelEl.current = document.getElementById(VERSION_HISTORY_PANEL_ID) as HTMLDivElement;
  }, []);

  useEffect(() => {
    onUsersUpdate?.(users);
  }, [users, onUsersUpdate]);

  if (!editor) {
    return null;
  }

  return (
    <ThreadComposerProvider>
      <ThreadComposerConsumer>
        {({ isComposing }) => (
          <>
            <ThreadComposer editor={editor} />

            <Styled.Container>
              {/* TODO(DOC): Add images & videos from Root/Doc element here */}
              <EditorContent
                editor={editor}
                suppressContentEditableWarning
                className={cx(threadsSidebarOpen && 'threads-sidebar-open')}
              />
            </Styled.Container>
            <div ref={menuContainerRef}>
              {/* If we have a side insert panel, render it */}
              {sideInsertPanelEl.current && createPortal(<InsertPanel />, sideInsertPanelEl.current)}
              {attributesPanelEl.current &&
                createPortal(<AttributesPanel editor={editor} />, attributesPanelEl.current)}
              {layersPanelEl.current && createPortal(<LayersPanel editor={editor} />, layersPanelEl.current)}
              {versionHistoryPanelEl.current &&
                createPortal(<VersionHistoryPanel editor={editor} />, versionHistoryPanelEl.current)}
              <SelectionBox editor={editor} />
              <DragHandle editor={editor} />
              <HoverBox editor={editor} />
              <ColumnHandle editor={editor} />
              <RemoveBlockMenu editor={editor} appendTo={menuContainerRef} />
              <LinkMenu editor={editor} appendTo={menuContainerRef} />
              <TextMenu editor={editor} appendTo={menuContainerRef} shouldHide={hideTextMenu || isComposing} />
              <BlockquoteFigureMenu editor={editor} appendTo={menuContainerRef} />
              <GenericEmbedMenu editor={editor} appendTo={menuContainerRef} />
              <ButtonMenu editor={editor} appendTo={menuContainerRef} />
              <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
              <CaptionMenu editor={editor} appendTo={menuContainerRef} />
              <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
              <FileAttachmentMenu editor={editor} appendTo={menuContainerRef} />
              <AudioMenu editor={editor} appendTo={menuContainerRef} />
              <AdvertisementOpportunityMenu editor={editor} appendTo={menuContainerRef} />
              <AdvertisementOpportunityLogoMenu editor={editor} appendTo={menuContainerRef} />
              <PollBlockMenu editor={editor} appendTo={menuContainerRef} />
              <TableOfContentsMenu editor={editor} appendTo={menuContainerRef} />
              <TableRowMenu editor={editor} appendTo={menuContainerRef} />
              <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
            </div>
            <UpgradeIntentModal
              isOpen={upgradeIntentModal.isOpen}
              intentAction={upgradeIntentModal.action}
              preselectedPlan={upgradeIntentModal.plan || PLAN.SCALE}
              onClose={() => {
                setUpgradeIntentModal({ ...upgradeIntentModal, isOpen: false });
              }}
            />
          </>
        )}
      </ThreadComposerConsumer>
    </ThreadComposerProvider>
  );
};

export default DreamEditor;
