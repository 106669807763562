import { useCallback, useMemo } from 'react';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useSettings } from '@/context/settings-context';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';

import '@shared/dream-components/style.css';

export const Editor = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const { page, content, setContent, changesMade, setChangesMade } = useDreamEditorContext();

  const { settings } = useSettings();

  const handleUpdate = useCallback(
    ({ editor }: any) => {
      if (!changesMade) {
        setChangesMade(true);
      }

      setContent(editor.getJSON());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changesMade, content, setChangesMade, setContent]
  );

  const initialContent = useMemo(() => page?.draft_page_version?.content, [page?.draft_page_version?.content]);

  return (
    <div className="grow relative overflow-y-scroll min-h-0 h-full">
      <PublicationProvider id={currentPublicationId}>
        {settings && (
          <DreamEditor
            publicationId={currentPublicationId}
            settings={settings}
            className="typedream content"
            onUpdate={handleUpdate}
            content={initialContent}
          />
        )}
      </PublicationProvider>
    </div>
  );
};
