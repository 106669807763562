import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

import { useCurrentUser } from '@/context/current-user-context';
import { useCreateSiteTemplate } from '@/hooks/useSiteTemplates';
import { TemplateLevel } from '@/interfaces/site_template';

import { Input } from '../../UI/Input';
import InputWrapper from '../../UI/InputWrapper';
import Modal from '../../UI/Modal';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../UI/Select';
import { Switch } from '../../UI/Switch';
import { Textarea } from '../../UI/Textarea';

import { BLOCK_CATEGORIES, SECTION_CATEGORIES } from './constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isEditing?: boolean;
  node: ProseMirrorNode;
}

const getLevel = (nodeJSON: any): TemplateLevel => {
  if (nodeJSON.type === 'section') {
    return 'section';
  }

  if (nodeJSON.type === 'doc') {
    return 'page';
  }

  return 'block';
};

const TemplateModal = ({ isOpen, onClose, isEditing, node }: Props) => {
  const queryClient = useQueryClient();

  const { currentUser } = useCurrentUser();
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const isSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && !isMasquerading;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [availableToPublic, setAvailableToPublic] = useState(false);

  const title = isEditing ? 'Edit Template' : 'Create Template';
  const ctaText = isEditing ? 'Save' : 'Create';
  const isLoadingText = isEditing ? 'Saving...' : 'Creating...';
  const categories = node?.type?.name === 'section' ? SECTION_CATEGORIES : BLOCK_CATEGORIES;

  const createSiteTemplate = useCreateSiteTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries('site_templates');
      onClose();
    },
  });

  const handleCreateSiteTemplate = () => {
    const content = node.toJSON();

    createSiteTemplate.mutate({
      name,
      description,
      category,
      content,
      level: getLevel(content),
      available_to_public: availableToPublic,
      created_by_super_admin: isSystemAdmin,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      onConfirm={handleCreateSiteTemplate}
      ctaText={ctaText}
      ctaVariant="primary"
      isLoading={createSiteTemplate.isLoading}
      isLoadingText={isLoadingText}
    >
      <InputWrapper name="category" labelText="Category" className="w-full">
        <Select
          defaultValue=""
          onValueChange={(value: any) => {
            setCategory(value);
          }}
        >
          <SelectTrigger className="w-full" id="by_status">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {categories.map((cat) => (
                <SelectItem key={cat.value} value={cat.value}>
                  {cat.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </InputWrapper>
      <Input
        name="name"
        labelText="Name"
        placeholder="New Template"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Textarea
        name="description"
        placeholder="A reusable section for landing pages..."
        value={description}
        labelText="Description"
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <Switch
        id="available_to_public"
        labelText="Available to public"
        checked={availableToPublic}
        onCheckedChange={(value: boolean) => {
          setAvailableToPublic(value);
        }}
      />
    </Modal>
  );
};

export default TemplateModal;
