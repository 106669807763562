import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import moment from 'moment-mini';

import PostEditorLayout from '@/components/Layout/PostEditorLayout';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import PreviewModalV2 from '@/components/PreviewModalV2';
import { useCurrentPublication } from '@/hooks';
import { IData } from '@/interfaces/general';
import { Post, PostPlatform } from '@/interfaces/post';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import Audience from './Audience';
import Compose from './Compose';
import Email from './Email';
import { PostContext, PostContextContent } from './PostContext';
import { PostMetaContext, PostMetaContextContent } from './PostMetaContext';
import Review from './Review';
import Web from './Web';

interface Props {
  postId: string;
}

const getPostEditorStepFromParam = (step: string): PostEditorSteps => {
  switch (step) {
    case 'audience':
      return PostEditorSteps.AUDIENCE;
    case 'email':
      return PostEditorSteps.EMAIL;
    case 'web':
      return PostEditorSteps.WEB;
    case 'review':
      return PostEditorSteps.REVIEW;
    case 'compose':
      return PostEditorSteps.COMPOSE;
    default:
      return PostEditorSteps.COMPOSE;
  }
};

const Form = ({ postId }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const queryParams = new URLSearchParams(window.location.search) as URLSearchParams;
  const step = queryParams.get('step');
  const [postEditorStep, setPostEditorStep] = useState<PostEditorSteps>(getPostEditorStepFromParam(step || 'compose'));
  const [previewActive, setPreviewActive] = useState(false);
  const [previewOptions, setPreviewOptions] = useState<('Email' | 'Web')[]>([]);
  const [emailSubjectEdited, setEmailSubjectEdited] = useState(false);
  const [emailPreviewTextEdited, setEmailPreviewTextEdited] = useState(false);
  const [postSlugEdited, setPostSlugEdited] = useState(false);
  const [seoMetaTitleEdited, setSeoMetaTitleEdited] = useState(false);
  const [seoMetaDescriptionEdited, setSeoMetaDescriptionEdited] = useState(false);
  const [seoMetaOpenGraphTitleEdited, setSeoMetaOpenGraphTitleEdited] = useState(false);
  const [seoMetaOpenGraphDescriptionEdited, setSeoMetaOpenGraphDescriptionEdited] = useState(false);
  const [seoMetaTwitterTitleEdited, setSeoMetaTwitterTitleEdited] = useState(false);
  const [seoMetaTwitterDescriptionEdited, setSeoMetaTwitterDescriptionEdited] = useState(false);
  const [showIncludeExcludeSegments, setShowIncludeExcludeSegments] = useState(false);
  const [tiersEdited, setTiersEdited] = useState(false);

  const [postMetaLastUpdated, setPostMetaLastUpdated] = useState<number | undefined>();
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const post = useQuery<Post>(
    ['post-v2', postId, postEditorStep],
    () => api.get(`/posts/${postId}`).then((res) => res.data),
    {
      cacheTime: 0,
      enabled: !!currentPublication?.id,
    }
  );

  const renderTab = (): ReactElement => {
    if (!post.data) return <template />;

    switch (postEditorStep) {
      case PostEditorSteps.AUDIENCE:
        return <Audience />;
      case PostEditorSteps.EMAIL:
        return <Email />;
      case PostEditorSteps.WEB:
        return <Web />;
      case PostEditorSteps.REVIEW:
        return <Review />;
      default:
        return <Compose />;
    }
  };

  const fetchPreview = useMemo(
    () => async (platform: string, advancedParams: IData, onFetch: (html: string) => void) => {
      if (!currentPublication?.id) {
        return;
      }

      const params = {
        publication_id: currentPublication?.id,
        is_v2: true,
        platform,
        ...advancedParams,
      };

      await api.get(`/posts/${postId}/preview`, { params }).then((resp) => onFetch(resp.data.html));
    },
    [currentPublication?.id, postId]
  );

  const [formData, setFormData] = useState<Post | undefined>(post.data);

  useEffect(() => {
    setFormData(post.data);
  }, [post.data]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: Partial<Post>) => {
        setIsSaving(true);
        abortControllerRef.current = new AbortController();

        return api
          .patch(
            `/posts/${post?.data?.id}`,
            { post: { ...data, id: post?.data?.id } },
            {
              signal: abortControllerRef.current.signal,
            }
          )
          .then((resp) => {
            if (resp.data.errors) {
              toast.error('There was an error saving the post');
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              toast.error('There was an error saving the post');
            }
          })
          .finally(() => {
            abortControllerRef.current = null;

            setIsSaving(false);
          });
      }, 450),
    [post?.data?.id]
  );

  const cancelSave = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const onChange = useMemo(
    () => (data: any) => {
      cancelSave();

      setFormData((prev) => {
        const newData = {
          ...prev,
          ...data,
        };

        save(data);

        setPostMetaLastUpdated(Date.now());

        return newData;
      });
    },
    [cancelSave, save]
  );

  const publishPost = useMutation(
    () => {
      return api.patch(`/posts/${post?.data?.id}/transition`, { status: 'confirmed' });
    },
    {
      onSuccess: () => {
        const showPostModal = formData?.platform === PostPlatform.BOTH || formData?.platform === PostPlatform.WEB;
        if (formData?.scheduled_at && moment(formData?.scheduled_at).isAfter(moment())) {
          analytics.track('Scheduled Post');
          toast.success('Post successfully scheduled');

          navigate(`/posts/${post?.data?.id}${showPostModal ? '?scheduled=true' : ''}`);
        } else {
          analytics.track('Sent Post');
          toast.success('Post successfully published');
          navigate(`/posts/${post?.data?.id}${showPostModal ? '?launch=true' : ''}`);
        }
      },
      onError: (err: any) => {
        toast.error(() => (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: err?.response?.data?.message || 'Something went wrong, please try again',
            }}
          />
        ));
      },
    }
  );

  const publishUpdates = useMutation(
    () => {
      return api.post(`/posts/${post?.data?.id}/content/publish_draft`, {
        publication_id: currentPublication?.id,
      });
    },
    {
      onSuccess: () => {
        toast.success('Post successfully updated');
        queryClient.invalidateQueries(['post-v2', postId]);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
      },
    }
  );

  const postMetaProvider = useMemo<PostMetaContextContent>(() => {
    return {
      postMetaLastUpdated,
      setPostMetaLastUpdated,
    };
  }, [postMetaLastUpdated, setPostMetaLastUpdated]);

  const providerValue = useMemo<PostContextContent>(() => {
    return {
      editor: null,
      setEditor: () => {},
      editorIsLoading: false,
      setEditorIsLoading: () => {},
      isSaving,
      setIsSaving,
      unsavedChanges: false,
      setUnsavedChanges: () => {},
      wordCount: 0,
      setWordCount: () => {},
      provider: undefined,
      setProvider: () => {},
      formData,
      users: [],
      setUsers: () => {},
      showSidebar: true,
      setShowSidebar: () => {},
      showSearchAndReplaceMenu: false,
      setShowSearchAndReplaceMenu: () => {},
      collaborationEnabled: false,
      showThreadsSidebar: false,
      setShowThreadsSidebar: () => {},
      loadingNodes: {},
      setLoadingNodes: () => {},
      activeThreadId: null,
      setActiveThreadId: () => {},
      selectThread: () => {},
      unselectThread: () => {},
      threads: null,
      createThread: () => {},
      highlightThread: () => {},
      removeHighlightThread: () => {},
      onChange,
      setPostEditorStep,
      publishPost,
      publishUpdates,
      emailSubjectEdited,
      setEmailSubjectEdited,
      emailPreviewTextEdited,
      setEmailPreviewTextEdited,
      postSlugEdited,
      setPostSlugEdited,
      seoMetaTitleEdited,
      setSeoMetaTitleEdited,
      seoMetaDescriptionEdited,
      setSeoMetaDescriptionEdited,
      seoMetaOpenGraphTitleEdited,
      setSeoMetaOpenGraphTitleEdited,
      seoMetaOpenGraphDescriptionEdited,
      setSeoMetaOpenGraphDescriptionEdited,
      seoMetaTwitterTitleEdited,
      setSeoMetaTwitterTitleEdited,
      seoMetaTwitterDescriptionEdited,
      setSeoMetaTwitterDescriptionEdited,
      showIncludeExcludeSegments,
      setShowIncludeExcludeSegments,
      showScheduleModal,
      setShowScheduleModal,
      tiersEdited,
      setTiersEdited,
      authors: [],
      thumbnail_id: '',
    };
  }, [
    formData,
    onChange,
    publishPost,
    publishUpdates,
    emailSubjectEdited,
    emailPreviewTextEdited,
    postSlugEdited,
    setPostSlugEdited,
    seoMetaTitleEdited,
    setSeoMetaTitleEdited,
    seoMetaDescriptionEdited,
    setSeoMetaDescriptionEdited,
    seoMetaOpenGraphTitleEdited,
    setSeoMetaOpenGraphTitleEdited,
    seoMetaOpenGraphDescriptionEdited,
    setSeoMetaOpenGraphDescriptionEdited,
    seoMetaTwitterTitleEdited,
    setSeoMetaTwitterTitleEdited,
    seoMetaTwitterDescriptionEdited,
    setSeoMetaTwitterDescriptionEdited,
    showIncludeExcludeSegments,
    setShowIncludeExcludeSegments,
    isSaving,
    showScheduleModal,
    tiersEdited,
    setTiersEdited,
  ]);

  return (
    <PostMetaContext.Provider value={postMetaProvider}>
      <PostContext.Provider value={providerValue}>
        <PostEditorLayout
          postId={postId}
          currentPostEditorStep={postEditorStep}
          setPostEditorStep={setPostEditorStep}
          setPreviewActive={setPreviewActive}
          setPreviewOptions={setPreviewOptions}
          isV2
        >
          <PreviewModalV2
            fetchPreview={fetchPreview}
            active={previewActive}
            tabs={previewOptions}
            publication={currentPublication}
            post={post?.data}
            onClose={() => setPreviewActive(false)}
            showSubscriberSelect
          />

          {renderTab()}
        </PostEditorLayout>
      </PostContext.Provider>
    </PostMetaContext.Provider>
  );
};

export default Form;
