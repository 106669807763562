import {
  ArrowsOutLineVertical,
  Image,
  ListBullets,
  ListNumbers,
  CursorClick,
  Quotes,
  Square,
  SquareSplitHorizontal,
  Textbox,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';

import { selectThreeRandomAuthors } from '../dummyData/authors';
import { selectThreeRandomPosts } from '../dummyData/posts';
import { tiers } from '../dummyData/pricing';
import { survey } from '../dummyData/survey';

export const components = {
  paragraph: {
    label: 'Text',
    category: 'text',
    Icon: TextT,
    block: () => ({
      type: 'paragraph',
      attr: {
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'paragraph',
        },
      ],
    }),
  },
  heading1: {
    label: 'Head 1',
    category: 'text',
    Icon: TextHOne,
    block: () => ({
      type: 'heading',
      attrs: {
        level: 1,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 1',
        },
      ],
    }),
  },
  heading2: {
    label: 'Head 2',
    category: 'text',
    Icon: TextHTwo,
    block: () => ({
      type: 'heading',
      attrs: {
        level: 2,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 2',
        },
      ],
    }),
  },
  heading3: {
    label: 'Head 3',
    category: 'text',
    Icon: TextHThree,
    block: () => ({
      type: 'heading',
      attrs: {
        level: 3,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 3',
        },
      ],
    }),
  },
  blockquote: {
    label: 'Blockquote',
    category: 'media',
    Icon: Quotes,
    block: () => ({
      type: 'blockquoteFigure',
      attrs: {},
      content: [
        {
          type: 'text',
          text: 'Heading 3',
        },
        {
          type: 'quoteCaption',
          content: [],
        },
      ],
    }),
  },
  pricingCards: {
    label: 'Pricing Cards',
    category: 'widget',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: 'pricingCards',
      attrs: {
        data: {
          tiers,
        },
      },
    }),
  },
  section: {
    label: 'Section',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: 'section',
      attrs: {},
    }),
  },
  container: {
    label: 'Container',
    category: 'layout',
    Icon: Square,
    block: () => ({
      type: 'container',
      attrs: {},
    }),
  },
  columns: {
    label: 'Columns',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: 'columns',
      attrs: {
        width: '100%',
        padding: '20px',
      },
      content: [
        {
          type: 'column',
          attrs: {
            width: '50%',
          },
          content: [
            {
              type: 'container',
              attrs: {},
            },
          ],
        },
        {
          type: 'column',
          attrs: {
            width: '50%',
          },
          content: [
            {
              type: 'container',
              attrs: {},
            },
          ],
        },
      ],
    }),
  },
  signup: {
    label: 'Signup',
    category: 'widget',
    Icon: Textbox,
    block: () => ({
      type: 'signup',
      attrs: {},
      content: [],
    }),
  },
  divider: {
    label: 'Divider',
    category: 'layout',
    Icon: ArrowsOutLineVertical,
    block: () => ({
      type: 'divider',
      attrs: {},
      content: [],
    }),
  },
  numberedList: {
    label: 'Numbered List',
    category: 'lists',
    Icon: ListNumbers,
    block: () => ({
      type: 'orderedList',
      attrs: {},
      content: [
        {
          type: 'listItem',
          attrs: {},
          content: [
            {
              type: 'paragraph',
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  bulletedList: {
    label: 'Bullet List',
    category: 'lists',
    Icon: ListBullets,
    block: () => ({
      type: 'bulletList',
      attrs: {},
      content: [
        {
          type: 'listItem',
          attrs: {},
          content: [
            {
              type: 'paragraph',
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  post: {
    label: 'Post',
    category: 'widget',
    Icon: ListBullets,
    block: () => ({
      type: 'post',
      attrs: {
        width: 'full',
        cardCount: 3,
        gap: 8,
        orientation: 'horizontal',

        data: {
          posts: selectThreeRandomPosts({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  survey: {
    label: 'Survey',
    Icon: Image,
    category: 'media',
    block: () => ({
      type: 'survey',
      attrs: {
        src: '',
        data: survey,
      },
      content: [],
    }),
  },
  media: {
    label: 'Image',
    Icon: Image,
    category: 'media',
    block: () => ({
      type: 'image',
      attrs: {
        src: '',
      },
      content: [],
    }),
  },
  authors: {
    label: 'Authors',
    category: 'widget',
    Icon: Users,
    block: () => ({
      type: 'authors',
      attrs: {
        data: {
          authors: selectThreeRandomAuthors({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  cool_signup_widget: {
    label: 'Cool Signup Widget',
    category: 'template',
    Icon: Textbox,
    block: () => ({
      type: 'signup',
      attrs: {
        backgroundColor: 'rgba(0,0,0,.05)',
        borderRadius: '999px',
        inputPlaceholder: 'jamie@example.com',
        buttonText: 'Signup',
        buttonBackgroundColor: '#e20a39',
        buttonTextColor: '#FFFFFF',
        buttonBorderRadius: '999px',
      },
      content: [],
    }),
  },
  zenith_signup_widget: {
    label: 'Zenith Signup',
    category: 'template',
    Icon: Textbox,
    block: () => ({
      type: 'signup',
      attrs: {
        backgroundColor: 'transparent',
        borderColor: '#000000',
        borderWidth: '1px',
        inputPlaceholder: 'youremail@example.com',
        inputPadding: '10px 20px',
        buttonText: 'Subscribe',
        buttonBackgroundColor: '#000000',
        buttonTextColor: '#FFFFFF',
      },
      content: [],
    }),
  },
  socials_complete: {
    label: 'Socials',
    category: 'widget',
    Icon: XLogo,
    block: () => ({
      type: 'socials',
      content: [],
    }),
  },
  button: {
    label: 'Button',
    category: 'widget',
    Icon: CursorClick,
    block: () => ({
      type: 'button',
      content: [
        {
          type: 'text',
          text: 'Subscribe',
        },
      ],
    }),
  },
};
