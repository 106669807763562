/* eslint-disable */

import { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from '@/components/Modal';
import cx from 'classnames';

// Interfaces
import { IData } from '@/interfaces/general';
import { Post } from '@/interfaces/post';
import { Platform, ScreenSize } from './types';
import Navbar from './Navbar';
import EmailInformation from './EmailInformation';
import BrowserBar from './BrowserBar';
import { Publication } from '@/interfaces/publication';
import LoadingBox from '../LoadingBox';

interface PreviewContainerProps {
  screenSize: ScreenSize;
}

const PreviewContainer = styled.iframe<PreviewContainerProps>`
  ${({ screenSize }) =>
    screenSize === 'mobile' ? 'width: 448px; height: 100%; margin: 0 auto;' : 'width: 100%; height: 100%;'}
`;

interface Props {
  active: boolean;
  fetchPreview: (platform: string, advancedParams: IData, onFetch: (html: string) => void) => Promise<void>;
  post?: Post;
  tabs?: Platform[] | undefined;
  onClose: () => void;
  showSubscriberSelect: boolean;
  publication?: Publication;
}

const PreviewModalV2: FC<Props> = (props: Props) => {
  const { fetchPreview, post, active, onClose, tabs, showSubscriberSelect } = props;

  const previewContainer = useRef(null);

  const [activeTab, setActiveTab] = useState<Platform>(tabs && tabs.length > 0 ? tabs[0] : 'Web');

  const [emailPreview, setEmailPreview] = useState('');

  const [screenSize, setScreenSize] = useState<ScreenSize>('desktop');
  const [currentSubscriberId, setCurrentSubscriberId] = useState('');
  const [currentSubscriberEmail, setCurrentSubscriberEmail] = useState<string | undefined>(undefined);
  const [currentTierId, setCurrentTierId] = useState('free');
  const [currentTierName, setCurrentTierName] = useState<string | undefined>(undefined);

  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    if (active && activeTab === 'Web') {
      setEmailPreview('');
      return;
    }

    const functions: { [key: string]: (html: string) => void } = {
      email: (html: string) => setEmailPreview(html),
    };

    const advancedParams = {
      ...(currentTierId && currentTierId.length > 0 && { current_tier_id: currentTierId }),
      ...(currentSubscriberId && currentSubscriberId.length > 0 && { current_subscriber_id: currentSubscriberId }),
    };

    const fetchData = async () => {
      await fetchPreview(activeTab.toLowerCase(), advancedParams, functions[activeTab.toLowerCase()]);
      setPreviewLoading(false);
    };

    if (active) {
      fetchData();
    }
  }, [active, activeTab, currentSubscriberId, currentTierId]);

  useEffect(() => {
    if (active) {
      setCurrentTierId('free');
      setCurrentSubscriberId('');
    }
  }, [active]);

  const handleClose = () => {
    setCurrentSubscriberId('');
    onClose();
  };

  const handleScreenSizeChange = (newSize: ScreenSize) => {
    if (screenSize !== newSize) {
      setScreenSize(newSize);
    }
  };

  const handleCurrentSubscriberSelect = (subscriberId: string, subscriberEmail: string) => {
    setCurrentTierId('');
    setCurrentSubscriberEmail(subscriberEmail);
    setCurrentSubscriberId(subscriberId);
  };

  const handleCurrentSubscriberClear = () => {
    setCurrentSubscriberEmail(undefined);
    setCurrentSubscriberId('');
  };

  const handleCurrentTierSelect = (tierId: string, tierName: string) => {
    setCurrentSubscriberId('');
    setCurrentTierName(tierName);
    setCurrentTierId(tierId);
  };

  const handleCurrentTierClear = () => {
    setCurrentTierName(undefined);
    setCurrentTierId('');
  };

  return (
    <Modal isOpen={active} onClose={handleClose} includeCloseButton={false}>
      <div className="rounded-lg h-[95vh] w-[95vw] overflow-hidden">
        <div className="flex flex-col rounded-lg w-full h-full overflow-hidden" ref={previewContainer}>
          <Navbar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            screenSize={screenSize}
            handleScreenSizeChange={handleScreenSizeChange}
            showSubscriberSelect={showSubscriberSelect}
            handleCurrentSubscriberSelect={handleCurrentSubscriberSelect}
            handleCurrentSubscriberClear={handleCurrentSubscriberClear}
            handleCurrentTierSelect={handleCurrentTierSelect}
            handleCurrentTierClear={handleCurrentTierClear}
            handleClose={handleClose}
          />
          <div className="h-full w-full rounded-r-md overflow-auto">
            <div
              className={cx(
                screenSize === 'desktop' ? 'max-w-7xl' : 'max-w-md',
                'h-full m-auto flex flex-col shadow-xl rounded-xl border-b border-surface-200'
              )}
            >
              <BrowserBar screenSize={screenSize} />
              {activeTab === 'Email' && post && (
                <EmailInformation
                  post={post}
                  screenSize={screenSize}
                  to={currentSubscriberEmail?.length ? currentSubscriberId : currentTierName}
                />
              )}
              <LoadingBox isLoading={previewLoading} isError={false} className="!h-full">
                {post?.draft_url && activeTab === 'Web' && (
                  <PreviewContainer
                    src={`${post?.draft_url}&current_subscriber_id=${currentSubscriberId}&current_tier_id=${currentTierId}`}
                    screenSize={screenSize}
                    className="rounded-b-md"
                  />
                )}
                {activeTab === 'Email' && (
                  <PreviewContainer srcDoc={emailPreview} screenSize={screenSize} className="rounded-b-md" />
                )}
              </LoadingBox>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PreviewModalV2.defaultProps = {
  tabs: ['Email'],
  showSubscriberSelect: false,
};

export default PreviewModalV2;
