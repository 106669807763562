import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useSetting } from '@/hooks';
import { Setting } from '@/interfaces/setting';

import { UpsellPage } from '../../../components/UpsellPage';
import { useSettings } from '../../../context/settings-context';
import useUpgradeIntent from '../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../interfaces/upgrades';
import { PLAN } from '../../../utils/plans';

const Teaser = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_PREMIUM_SUBSCRIPTIONS });
  const { settings } = useSettings();
  const tieredPricing = useSetting({ setting: Setting.TIERED_PRICING, defaultValue: false });

  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.premium_subscriptions) {
      toast.success('Paid subscriptions are now enabled.');
      navigate('/monetize/subscriptions');
    }
  }, [settings, navigate]);

  return (
    <UpsellPage
      plan={tieredPricing ? PLAN.SCALE : PLAN.GROW}
      title="Paid newsletters made simple"
      description="The most flexible, expansive toolkit for newsletter subscriptions, and we don't even take a cut of your revenue."
      youtubeUrl="https://www.youtube.com/watch?v=5hXVEDbgnlA"
      cards={[
        {
          heading: 'Turn your audience into recurring revenue.',
          description: 'Offer monthly or annual plans for your paid newsletter. You keep 100% of the revenue.*',
          imageUrl: 'https://media.beehiiv.net/static_assets/premium_subscription_dashboard',
        },
        {
          heading: 'Convert free readers into paying customers.',
          description:
            "Create custom offers and free trials directly in the platform. Use these to incentivize upgrades, and we'll track the conversions. Never worry about user authentication, paywalls, payments, or billing settings. We'll take care of all that and more.",
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=828,quality=75/www/monetize-page/monetize-feature-2.svg',
        },
      ]}
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/monetize/subscriptions"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
