import { useState } from 'react';
import toast from 'react-hot-toast';
import { UseMutationResult } from 'react-query';
import moment from 'moment-mini';

import { Input, RadioSelect, Textarea } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { useCurrentTimeZone } from '@/hooks';
import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';
import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Button } from '@/ui/Button';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  campaignOpportunityGroup?: AdNetworkCampaignOpportunityGroup;
  campaign: AdNetworkCampaign;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const Form = ({ onSubmitMutation, campaignOpportunityGroup, campaign }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [name, setName] = useState(campaignOpportunityGroup?.name || '');
  const [payoutPerClickCents, setPayoutPerClickCents] = useState(campaignOpportunityGroup?.payout_per_click_cents || 0);
  const [payoutModel, setPayoutModel] = useState(campaignOpportunityGroup?.payout_model || AdNetworkPayoutModel.CPC);
  const [tierOnePayoutPerMilleCents, setTierOnePayoutPerMilleCents] = useState(
    campaignOpportunityGroup?.tier_one_payout_per_mille_cents || 0
  );
  const [tierTwoPayoutPerMilleCents, setTierTwoPayoutPerMilleCents] = useState(
    campaignOpportunityGroup?.tier_two_payout_per_mille_cents || 0
  );
  const [tierThreePayoutPerMilleCents, setTierThreePayoutPerMilleCents] = useState(
    campaignOpportunityGroup?.tier_three_payout_per_mille_cents || 0
  );
  const [tierFourPayoutPerMilleCents, setTierFourPayoutPerMilleCents] = useState(
    campaignOpportunityGroup?.tier_four_payout_per_mille_cents || 0
  );
  const [tierUnknownPayoutPerMilleCents, setTierUnknownPayoutPerMilleCents] = useState(
    campaignOpportunityGroup?.tier_unknown_payout_per_mille_cents || 0
  );
  const [deadline, setDeadline] = useState(
    campaignOpportunityGroup?.deadline || moment().add(3, 'days').endOf('day').toISOString()
  );
  const [messageToPublisher, setMessageToPublisher] = useState(campaignOpportunityGroup?.message_to_publisher || '');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('campaign_opportunity_group[name]', name);
    formData.append('campaign_opportunity_group[payout_model]', payoutModel);
    formData.append('campaign_opportunity_group[payout_per_click_cents]', payoutPerClickCents.toString());
    formData.append(
      'campaign_opportunity_group[tier_one_payout_per_mille_cents]',
      tierOnePayoutPerMilleCents.toString()
    );
    formData.append(
      'campaign_opportunity_group[tier_two_payout_per_mille_cents]',
      tierTwoPayoutPerMilleCents.toString()
    );
    formData.append(
      'campaign_opportunity_group[tier_three_payout_per_mille_cents]',
      tierThreePayoutPerMilleCents.toString()
    );
    formData.append(
      'campaign_opportunity_group[tier_four_payout_per_mille_cents]',
      tierFourPayoutPerMilleCents.toString()
    );
    formData.append(
      'campaign_opportunity_group[tier_unknown_payout_per_mille_cents]',
      tierUnknownPayoutPerMilleCents.toString()
    );
    formData.append('campaign_opportunity_group[deadline]', deadline.toString());
    formData.append('campaign_opportunity_group[message_to_publisher]', messageToPublisher);

    mutateAsync(formData).catch((error) => {
      toast.error(error || 'Something went wrong');
    });
  };

  const handleSetDeadline = (date: string) => {
    setDeadline(moment(date).endOf('day').toISOString());
  };

  const timeZone = useCurrentTimeZone();

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-4">
        <form className="divide-y" onSubmit={onSubmit}>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
              <p className="text-sm text-gray-500">Specify the details for this opportunity group.</p>
            </div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="opportunity[name]"
              labelText="Name"
              helperText="The name of this opportunity group."
            />
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Payment</h2>
              <p className="text-sm text-gray-500">Specify the terms for how you will pay the publisher.</p>
            </div>
            <RadioSelect
              value={payoutModel}
              onSelect={(val) => setPayoutModel(val as AdNetworkPayoutModel)}
              options={[
                {
                  name: 'CPC',
                  description: 'Pay publishers for each click they generate.',
                  value: AdNetworkPayoutModel.CPC,
                },
                {
                  name: 'CPM',
                  description: 'Pay publishers for every 1,000 unique opens they generate.',
                  value: AdNetworkPayoutModel.CPM,
                },
              ]}
            />
            {payoutModel === AdNetworkPayoutModel.CPC ? (
              <CurrencyInput
                value={payoutPerClickCents}
                onChange={(val) => setPayoutPerClickCents(val || 0)}
                name="opportunity[payout_per_click_cents]"
                labelText="Payout Per Click"
                placeholder={currencyFormatter.format(campaign.cost_per_click_cents / 100)}
                maxCents={campaign.cost_per_click_cents}
                helperText={`The amount you'll pay the publisher per click (Max: ${currencyFormatter.format(
                  campaign.cost_per_click_cents / 100
                )})`}
              />
            ) : (
              <>
                <CurrencyInput
                  value={tierOnePayoutPerMilleCents}
                  onChange={(val) => setTierOnePayoutPerMilleCents(val || 0)}
                  name="opportunity[tier_one_payout_per_mille_cents]"
                  labelText="Tier One Payout Per Mille"
                  helperText="The amount you'll pay Tier One publishers per 1,000 unique opens."
                />
                <CurrencyInput
                  value={tierTwoPayoutPerMilleCents}
                  onChange={(val) => setTierTwoPayoutPerMilleCents(val || 0)}
                  name="opportunity[tier_two_payout_per_mille_cents]"
                  labelText="Tier Two Payout Per Mille"
                  helperText="The amount you'll pay Tier Two publishers per 1,000 unique opens."
                />
                <CurrencyInput
                  value={tierThreePayoutPerMilleCents}
                  onChange={(val) => setTierThreePayoutPerMilleCents(val || 0)}
                  name="opportunity[tier_three_payout_per_mille_cents]"
                  labelText="Tier Three Payout Per Mille"
                  helperText="The amount you'll pay Tier Three publishers per 1,000 unique opens."
                />
                <CurrencyInput
                  value={tierFourPayoutPerMilleCents}
                  onChange={(val) => setTierFourPayoutPerMilleCents(val || 0)}
                  name="opportunity[tier_four_payout_per_mille_cents]"
                  labelText="Tier Four Payout Per Mille"
                  helperText="The amount you'll pay Tier Four publishers per 1,000 unique opens."
                />
                <CurrencyInput
                  value={tierUnknownPayoutPerMilleCents}
                  onChange={(val) => setTierUnknownPayoutPerMilleCents(val || 0)}
                  name="opportunity[tier_unknown_payout_per_mille_cents]"
                  labelText="Tier Unknown Payout Per Mille"
                  helperText="The amount you'll pay Tier Unknown publishers per 1,000 unique opens."
                />
              </>
            )}
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Timeframe</h2>
              <p className="text-sm text-gray-500">Specify the terms for when a publisher needs to run the campaign.</p>
            </div>
            <Input
              value={moment(deadline).format('YYYY-MM-DD')}
              type="date"
              onChange={(e) => handleSetDeadline(e.target.value)}
              name="opportunity[deadline]"
              labelText="Deadline"
              helperText={`The date the publisher needs to respond by. (End of day, ${timeZone})`}
              max={moment(campaign.window_end_date).format('YYYY-MM-DD')}
              min={moment().format('YYYY-MM-DD')}
            />
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Message to publisher</h2>
              <p className="text-sm text-gray-500">Highlight any important details about this opportunity.</p>
            </div>
            <Textarea
              value={messageToPublisher}
              onChange={(e) => setMessageToPublisher(e.target.value)}
              name="opportunity[message_to_publisher]"
              labelText="Message"
              rows={5}
            />
          </div>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
