import SectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { ColorSettings } from "./ColorSettings";
import { FontSettings } from "./FontSettings";


export function NavItemStylingSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;


  return <SectionRenderer title="Styling" >
    <FontSettings />
    <ColorSettings title="Text" property="text_color" />
    {
      selectedContent.attrs?.type === 'button' && (
        <ColorSettings title="Fill" property="background" />
      )
    }
  </SectionRenderer>;
}
