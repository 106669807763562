import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Checkbox, Input, Select } from '@/components/Form';
import HelperText from '@/components/Form/HelperText';
import IconButton from '@/components/IconHelpers/IconButton';
import { LineDivider } from '@/components/LineDivider';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import {
  useCreateRecommendationWidget,
  useRecommendationWidget,
  useUpdateRecommendationWidget,
} from '@/hooks/useRecommendationWidget';
import { ExternalEmbed } from '@/interfaces/external_embed';
import { Option } from '@/interfaces/general';
import { TriggerOption } from '@/interfaces/recommendation_widget';
import { TutorialType } from '@/interfaces/tutorial';
import VideoTutorial from '@/pages/Settings/Components/VideoTutorial';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import CodeBlock from '@/ui/CodeBlock';

import SelectExternalEmbed from './SelectExternalEmbed';

const makeScriptCode = (publicationId: string) => {
  const attributes = [
    `src="${window.env.REACT_APP_EXTERNAL_EMBED_BASE_URL}/recommendations.js?_bhpid=${publicationId}&v=1"`,
    'data-beehiiv-recommendations-widget',
    'defer',
  ];

  return `<script ${attributes.join(' ')}></script>`;
};

const makeShowRecommendationsCode = (email: string = '') => {
  return (
    '(function() {\n' +
    '\tif (window.beehiiv && window.beehiiv.showRecommendations) {\n' +
    `\t\twindow.beehiiv.showRecommendations(${email ? `'${email}'` : ''});\n` +
    '\t}\n' +
    '})();\n'
  );
};

const AUTOMATIC_TRIGGER_OPTION: Option = {
  label: 'On page load',
  value: 'automatic',
  description: 'The widget will appear on the webpage specified by URL after the reader submits their email.',
};
const VIA_SUBSCRIBER_FORM_TRIGGER_OPTION: Option = {
  label: 'Email Submission via Subscribe Form',
  value: 'via_subscriber_forms',
  description: "If you're utilizing a beehiiv Subscribe Form the widget will appear after the reader subscribes.",
};
const MANUAL_TRIGGER_OPTION: Option = {
  label: 'Manual',
  value: 'manual',
  description:
    'We provide a JavaScript code snippet you can embed into your site to trigger the widget when and how you would like.',
};
const TRIGGER_OPTIONS: Option[] = [AUTOMATIC_TRIGGER_OPTION, VIA_SUBSCRIBER_FORM_TRIGGER_OPTION, MANUAL_TRIGGER_OPTION];
const TRIGGER_OPTIONS_BY_VALUE: Record<string, Option> = {
  [AUTOMATIC_TRIGGER_OPTION.value]: AUTOMATIC_TRIGGER_OPTION,
  [VIA_SUBSCRIBER_FORM_TRIGGER_OPTION.value]: VIA_SUBSCRIBER_FORM_TRIGGER_OPTION,
  [MANUAL_TRIGGER_OPTION.value]: MANUAL_TRIGGER_OPTION,
};

const Widgets = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  const [publicationId] = useCurrentPublicationState();
  const { data: recommendationWidgetData, isLoading } = useRecommendationWidget();

  const [webpageUrlErrorIndex, setWebpageUrlErrorIndex] = useState<number>();
  const handleUpdateError = (errorData: any) => {
    if (errorData?.errors?.trigger_urls) {
      toast.error('Invalid Webpage URL(s)');
      setWebpageUrlErrorIndex(-1);
    } else {
      toast.error('Something went wrong, please try again!');
    }
  };
  const { mutateAsync: updateRecommendationWidget, isLoading: isUpdating } = useUpdateRecommendationWidget({
    onError: handleUpdateError,
  });

  const { mutateAsync: createRecommendationWidget, isLoading: isCreating } = useCreateRecommendationWidget({});

  const [afterOption, setAfterOption] = useState<'redirect' | 'close'>();
  const [triggerUrls, setTriggerUrls] = useState<string[]>([]);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [triggerOption, setTriggerOption] = useState<TriggerOption>('automatic');
  const [selectedExternalEmbeds, setSelectedExternalEmbeds] = useState<ExternalEmbed[]>([]);
  const [isFilteringByExternalEmbeds, setIsFilteringByExternalEmbeds] = useState(false);

  const webpageUrlsContainerRef = useRef<HTMLInputElement>(null);

  const handleClickSave = () => {
    setWebpageUrlErrorIndex(undefined);

    const triggerUrlValues = triggerOption === 'automatic' ? triggerUrls : [];
    const redirectUrlValue = afterOption === 'redirect' ? redirectUrl : null;

    const payload = {
      trigger_option: triggerOption,
      redirect_url: redirectUrlValue,
      trigger_urls: triggerUrlValues,
    };

    if (triggerUrlValues.length > 0) {
      let canSave = true;
      triggerUrlValues.forEach((url, index) => {
        try {
          // eslint-disable-next-line no-new
          new URL(url);
        } catch (error: any) {
          setWebpageUrlErrorIndex(index);
          webpageUrlsContainerRef?.current?.scrollIntoView();
          canSave = false;
        }
      });

      if (!canSave) {
        return;
      }
    }

    if (recommendationWidgetData?.recommendation_widget?.id) {
      updateRecommendationWidget({
        recommendationWidget: {
          ...recommendationWidgetData?.recommendation_widget,
          ...payload,
        },
        externalEmbedIds: selectedExternalEmbeds.map(({ id }) => id),
      });
    } else {
      createRecommendationWidget(payload);
    }
  };

  const handleSelectExternalEmbed = (externalEmbed: ExternalEmbed) => {
    setSelectedExternalEmbeds([...selectedExternalEmbeds, externalEmbed]);
  };

  const handleDeselectExternalEmbed = (id: string) => {
    const newExternalEmbeds = [...selectedExternalEmbeds];
    const indexOfDeselectedValue = newExternalEmbeds.findIndex((externalEmbed) => externalEmbed.id === id);

    if (indexOfDeselectedValue !== -1) {
      newExternalEmbeds.splice(indexOfDeselectedValue, 1);
      setSelectedExternalEmbeds(newExternalEmbeds);
    }
  };

  const handleDeselectAllExternalEmbeds = () => {
    setSelectedExternalEmbeds([]);
  };

  const handleChangeInFilterByExternalEmbeds = () => {
    const newValue = !isFilteringByExternalEmbeds;
    setIsFilteringByExternalEmbeds(newValue);
    if (!newValue) {
      setSelectedExternalEmbeds([]);
    }
  };

  const handleChangeInTriggerOption = (_name: string, value: TriggerOption) => {
    setTriggerOption(value);
    if (value === 'automatic' && triggerUrls.length === 0) {
      // Add first one
      setTriggerUrls(['']);
    } else {
      // Reset
      setTriggerUrls([]);
    }
  };

  const handleChangeInWebpageUrl = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    setWebpageUrlErrorIndex(undefined);
    const newTriggerUrls = [...triggerUrls];
    newTriggerUrls[index] = event.target.value;
    setTriggerUrls(newTriggerUrls);
  };

  const handleClickAddTriggerUrl = () => {
    setTriggerUrls([...triggerUrls, '']);
  };

  const handleClickDeleteTriggerUrl = (index: number) => () => {
    const newTriggerUrls = [...triggerUrls];
    newTriggerUrls.splice(index, 1);
    setTriggerUrls([...newTriggerUrls]);
  };

  useEffect(() => {
    if (!isLoading) {
      const redirectUrlInData = recommendationWidgetData?.recommendation_widget?.redirect_url || '';
      const triggerUrlsInData = recommendationWidgetData?.recommendation_widget?.trigger_urls || [''];
      setAfterOption(redirectUrlInData ? 'redirect' : 'close');
      setTriggerUrls(triggerUrlsInData);
      setRedirectUrl(redirectUrlInData || '');
      setTriggerOption(recommendationWidgetData?.recommendation_widget?.trigger_option || 'automatic');
      const persistedExternalEmbeds = recommendationWidgetData?.recommendation_widget?.external_embeds || [];
      setSelectedExternalEmbeds(persistedExternalEmbeds);
      setIsFilteringByExternalEmbeds(persistedExternalEmbeds.length > 0);
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  if (settings?.recommendations_widget !== true) {
    navigate('/404');
    return null;
  }

  return (
    <div className="max-w-xl flex flex-col gap-y-10 mx-auto my-10">
      <Card className="flex flex-col gap-y-6">
        <TypographyStack>
          <Typography token="font-medium/text/2xl">Embed Recommendations Widget</Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            Add your beehiiv recommendations and Boosts to external sites through our custom widget below. You can
            customize the trigger as well as the redirect link below.
          </Typography>
          <VideoTutorial classNames="text-left" type={TutorialType.WIDGET_RECOMMEND} />
        </TypographyStack>

        <LineDivider />

        <TypographyStack>
          <Typography token="font-medium/text/2xl">Triggers</Typography>
          <Typography token="font-normal/text/base" colorWeight="700">
            Choose what triggers the widget to pop up for your site visitors after they subscribe.
          </Typography>
        </TypographyStack>

        <Select
          labelText="Trigger Options"
          helperText={TRIGGER_OPTIONS_BY_VALUE[triggerOption].description}
          name="recommendations_widget_trigger_option"
          value={triggerOption}
          options={TRIGGER_OPTIONS}
          onSelect={handleChangeInTriggerOption}
        />

        {triggerOption === 'automatic' && (
          <>
            <div ref={webpageUrlsContainerRef} className="flex flex-col gap-y-2">
              {triggerUrls.map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="flex flex-col gap-y-2 items-center" key={`webpage-url-${index}`}>
                  <div className="flex gap-x-2 items-center w-full">
                    <Input
                      name="recommendations_widget_trigger_url"
                      value={triggerUrls[index]}
                      onChange={handleChangeInWebpageUrl(index)}
                      labelText={index === 0 ? 'Webpage URL' : undefined}
                      placeholder="https://yourwebsite.com/thank-you"
                      errorText={webpageUrlErrorIndex === index ? 'Please provide a valid URL' : undefined}
                      className="flex-1"
                    />

                    {index > 0 && (
                      <IconButton onClick={handleClickDeleteTriggerUrl(index)}>
                        <TrashIcon />
                      </IconButton>
                    )}
                  </div>
                  {index === triggerUrls.length - 1 && (
                    <HelperText>
                      The widget will appear on the webpage specified by the URL(s) after the reader submits their
                      email.
                    </HelperText>
                  )}
                </div>
              ))}
              <div className="flex justify-end">
                <Button
                  variant="primary-inverse"
                  size="xs"
                  onClick={handleClickAddTriggerUrl}
                  className="w-fit"
                  Icon={PlusIcon}
                >
                  Add URL
                </Button>
              </div>
            </div>

            <CodeBlock canCopy code={makeScriptCode(publicationId)} shouldOverflow={false} />
          </>
        )}

        {triggerOption === 'via_subscriber_forms' && (
          <>
            <Checkbox
              checked={isFilteringByExternalEmbeds}
              onChange={handleChangeInFilterByExternalEmbeds}
              name="filter_by_external_embeds"
              labelText="Select Subscribe Form"
              helperText="The widget will be displayed on all Subscribe Forms by default, you can choose to show the widget only when selected Subscribe Forms are submitted"
            />
            {isFilteringByExternalEmbeds && (
              <SelectExternalEmbed
                selectedExternalEmbeds={selectedExternalEmbeds}
                onSelect={handleSelectExternalEmbed}
                onDeselect={handleDeselectExternalEmbed}
                onDeselectAll={handleDeselectAllExternalEmbeds}
              />
            )}
            <CodeBlock
              labelText="Submitting Subscribe Form"
              helperText="When your site visitor submits a beehiiv subscribe form you have connected, the widget will pop up."
              canCopy
              code={makeScriptCode(publicationId)}
              shouldOverflow={false}
            />
          </>
        )}

        {triggerOption === 'manual' && (
          <>
            <TypographyStack>
              <Typography token="font-medium/text/base">Manual Trigger Steps</Typography>
              <Typography token="font-light/text/xs">
                To show the widget manually, please follow the steps below.
              </Typography>
            </TypographyStack>

            <Card className="flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-2">
                <Typography token="font-normal/text/sm" colorWeight="700">
                  Step 1: Copy the script and paste it the head section of your HTML page
                </Typography>
                <CodeBlock canCopy code={makeScriptCode(publicationId)} shouldOverflow={false} />
              </div>

              <div className="flex flex-col gap-y-2">
                <Typography token="font-normal/text/sm" colorWeight="700">
                  Step 2: Run this code when you want to show the widget
                </Typography>
                <CodeBlock
                  canCopy
                  code={makeShowRecommendationsCode()}
                  helperText="For example; when user clicks the Subscribe button"
                />
              </div>
            </Card>
          </>
        )}
      </Card>

      <Card className="flex flex-col gap-y-6">
        <TypographyStack>
          <Typography token="font-medium/text/2xl">Redirect</Typography>
          <Typography token="font-normal/text/base" colorWeight="700">
            Choose where to redirect your site visitors after they interact with your widget.
          </Typography>
        </TypographyStack>

        <div className="flex flex-col gap-y-6">
          <Select
            name="recommendations_widget_after_selection_option"
            value={afterOption}
            labelText="Redirect Options"
            options={[
              { label: 'Close Widget', value: 'close' },
              { label: 'Redirect', value: 'redirect' },
            ]}
            onSelect={(_name, value) => setAfterOption(value)}
          />

          {afterOption === 'redirect' && (
            <Input
              name="recommendations_widget_redirect_url"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.target.value)}
              labelText="URL"
              helperText="When your site visitors finish interacting with the widget, choose where to redirect them."
              placeholder="https://yourwebsite.com/thank-you"
            />
          )}
        </div>
      </Card>

      <div className="flex w-full justify-end">
        <Button variant="primary" onClick={handleClickSave} disabled={isUpdating || isCreating}>
          Save Widget Settings
        </Button>
      </div>
    </div>
  );
};

export default Widgets;
