import { ChangeEvent } from 'react';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { v4 as generateUuid } from 'uuid';

import Input from '@/components/Form/Input';
import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';

import { usePreferencesContext } from '../../preferences/context';

const indexToAlphabet = (index: number) => (index + 1 + 9).toString(36).toUpperCase();

const OptionsForm = () => {
  const { editablePreference, setEditablePreference } = usePreferencesContext();

  const handleChangeInOption = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newOptions = [...editablePreference.subscriber_preference_options];
    const editableOptionIndex = newOptions.findIndex((option) => option.id === id);

    if (editableOptionIndex === -1) {
      return;
    }

    newOptions[editableOptionIndex].value = e.currentTarget.value;
    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  const handleClickDeleteOption = (id: string) => {
    const newOptions = [...editablePreference.subscriber_preference_options];
    const editableOptionIndex = newOptions.findIndex((option) => option.id === id);

    if (editableOptionIndex === -1) {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (newOptions[editableOptionIndex]._isNew === true) {
      delete newOptions[editableOptionIndex];
    } else {
      // eslint-disable-next-line no-underscore-dangle
      newOptions[editableOptionIndex]._destroy = true;
    }
    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  const handleClickAdd = () => {
    const newOptions = [...editablePreference.subscriber_preference_options];

    newOptions.push({
      id: generateUuid(),
      deleted_at: null,
      created_at: null,
      updated_at: null,
      deleted_by_user_id: null,
      subscriber_preference_id: editablePreference.id,
      sort_order: newOptions.length,
      value: '',
      _isNew: true,
    });

    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      {editablePreference.subscriber_preference_options
        // eslint-disable-next-line no-underscore-dangle
        .filter((preferenceOption) => preferenceOption._destroy !== true)
        .map((option, index) => (
          <div className="flex flex-row gap-x-2 items-center" key={option.id}>
            <Typography token="font-medium/text/xs">Option</Typography>
            <Input
              className="w-2/3"
              name={`option_${option.id}`}
              placeholderText={`Option ${indexToAlphabet(index)}`}
              type="text"
              defaultValue={option.value}
              onBlur={handleChangeInOption(option.id)}
              required
            />
            <IconButton onClick={() => handleClickDeleteOption(option.id)}>
              <TrashIcon />
            </IconButton>
          </div>
        ))}
      <button type="button" className="w-fit" onClick={handleClickAdd}>
        <Typography
          token="font-semibold/text/xs"
          color="secondary"
          colorWeight="600"
          className="flex flex-row gap-x-1 items-center"
        >
          <PlusIcon className="w-3 h-3" />
          Add option
        </Typography>
      </button>
    </div>
  );
};

export default OptionsForm;
