import { AdNetworkPayoutModel } from '../shared/payout_model';

import { AdNetworkDisbursementStatus } from './disbursement/types';

export enum AdNetworkOpportunityStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
}

export interface AdNetworkOpportunity {
  id: string;
  payout_model: AdNetworkPayoutModel;
  payout_per_mille: string;
  payout_per_mille_cents: number;
  payout_per_click_cents: number;
  payout_per_click: string;
  deadline: string;
  estimated_payout_cents: number;
  estimated_payout: string;
  payout_duration_hours: number;
  selected_date: string;
  status: AdNetworkOpportunityStatus;
  created_at: string;
  updated_at: string;
  advertisement_id: string | null;
  window_start_at: string | null;
  window_end_at: string | null;
  campaign_opportunity_group_id: string | null;
  advertiser: {
    id: string;
    name: string;
    logo: {
      url: string;
    };
  };
  campaign: {
    id: string;
    name: string;
    window_end_date: string;
    window_start_date: string;
  };
  publication: {
    id: string;
    name: string;
    active_subscription_count: number;
    owner_email: string;
    logo: {
      url: string;
    };
    banned: boolean;
  };
  disbursement: {
    status: AdNetworkDisbursementStatus;
  };
  message_to_publisher: string | null;
  rejection_reason: string | null;
  rejection_reason_comment: string | null;
  provisioned_at: string | null;
}
