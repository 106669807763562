import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AdjustmentsVerticalIcon, Cog8ToothIcon, CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { UpgradePage } from '@/interfaces/upgrade_page';

import { useCurrentPublication } from '../../../hooks';
import { usePremiumSettings } from '../../../hooks/usePublications';
import { useRequirePermission } from '../../../hooks/useRequirePermission';
import { usePublishUpgradePage, useUpgradePage } from '../../../hooks/useUpgradePages';
import { Button } from '../../../ui/Button';
import Navigator from '../components/Navigator';
import { PageProvider } from '../components/PageContext';
import PageIframe from '../components/PageIframe';
import SavingIndicatorHelper from '../components/SavingIndicatorHelper';
import ScreenSizeHelper from '../components/ScreenSizeHelper';
import ViewSiteLink from '../components/ViewSiteLink';

import EnablePlanModal from './components/EnablePlanModal';
import FAQSection from './FAQSection';
import MainSection from './MainSection';
import TestimonialsSection from './TestimonialsSection';

const UpgradePageView = () => {
  useRequirePermission({
    resource: 'web_theme',
    permission: 'update',
    message: 'You do not have access to the Design Lab',
    redirectTo: '/',
  });

  const navigate = useNavigate();

  const { data: currentPublication } = useCurrentPublication();
  const { data: premiumSettings } = usePremiumSettings();
  const iframeUrl = `${currentPublication?.url}upgrade?version=draft`;
  const upgradePageQuery = useUpgradePage({ status: 'draft' });
  const { data: upgradePage } = upgradePageQuery;

  const publishUpgradePage = usePublishUpgradePage({
    pageId: upgradePage?.id || '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const [isEnablePlanModalOpen, setIsEnablePlanModalOpen] = useState(false);
  const actionText = premiumSettings?.premium_tier_active ? 'Publish to Site' : 'Enable Paid Subscriptions';
  const actionIcon = premiumSettings?.premium_tier_active ? CursorArrowRaysIcon : AdjustmentsVerticalIcon;
  const handleOnActionClick = () => {
    if (premiumSettings?.premium_tier_active) {
      publishUpgradePage.mutate();
    } else {
      setIsEnablePlanModalOpen(true);
    }
  };

  return (
    <>
      <EnablePlanModal isOpen={isEnablePlanModalOpen} onClose={() => setIsEnablePlanModalOpen(false)} />
      <PageProvider<UpgradePage>
        bgColor="#ffffff"
        pageData={upgradePage}
        actionChildren={
          <div className="flex items-center justify-between w-full">
            <div className="w-full flex justify-between items-center mr-4">
              <div className="flex items-center space-x-2">
                <Navigator />
                <Button variant="primary-inverse" onClick={() => navigate('/settings/publication/premium')}>
                  <Cog8ToothIcon className="w-5 h-5 text-gray-500" />
                </Button>
              </div>
              <SavingIndicatorHelper />
            </div>

            <div className={cx('flex justify-center')}>
              <div className="w-20">
                <ScreenSizeHelper />
              </div>
            </div>

            <div className={cx('w-full flex between')}>
              {premiumSettings?.premium_tier_active ? (
                <ViewSiteLink url={`${currentPublication?.url}upgrade`} />
              ) : (
                <div />
              )}

              <div className="w-full flex justify-end">
                <div className="flex items-center space-x-2 pt-0.5">
                  <Button variant="primary-inverse" type="button" onClick={() => navigate('/settings/website/pages')}>
                    Exit
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    Icon={actionIcon}
                    disabled={publishUpgradePage.isLoading}
                    loading={publishUpgradePage.isLoading}
                    onClick={() => {
                      handleOnActionClick();
                    }}
                  >
                    {publishUpgradePage.isLoading ? 'Publishing...' : actionText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
        sidePanelChildren={
          <div>
            <MainSection />
            <TestimonialsSection />
            <FAQSection />
          </div>
        }
      >
        <PageIframe title="Upgrade Page" url={iframeUrl} />
      </PageProvider>
    </>
  );
};

export default UpgradePageView;
