import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Frame, { useFrame } from 'react-frame-component';
import { StyleSheetManager } from "styled-components";

import { ATTRIBUTES_PANEL_ID } from "@/routes/website/_components/DreamEditor/constants";

import { AttributesPanel } from "./AttributesPanel/AttributesPanel";
import { DragHandle } from "./DragHandle";
import { NavbarProvider, useNavbarContext } from "./NavbarContext";
import { SelectionBox } from "./SelectionBox";
import { NavbarSerializer } from "./serializer";

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

function generateTextSkeleton(length: number) {
  return Array.from({ length }).map(() => (<div key={`skeleton-${Math.random().toString(36).substr(2, 9)}`} className="flex flex-col w-full p-12 justify-center items-center gap-8">
    <div className="h-8 w-[30%] bg-gray-900/5 rounded-full" />
    <div className="h-4 w-[70%] bg-gray-900/5 rounded-full" />
    <div className="h-4 w-[50%] bg-gray-900/5 rounded-full" />
  </div>
  ))
}

const IFRAME_CONTENT_WIDTH = 1024; // px

const IframeWrapper = React.forwardRef<HTMLIFrameElement, IframeWrapperProps>(
  ({ children, width, height, iframeProps }, ref) => {

    const [initialContent, setInitialContent] = useState('');

    useEffect(() => {
      fetch('/canvas/index.html')
        .then((d) => d.text())
        .then(setInitialContent);
    }, []);

    if (!initialContent) return <div>loading..</div>;

    const scale = width / IFRAME_CONTENT_WIDTH;

    return (
      <Frame
        ref={ref}
        initialContent={initialContent}
        {...iframeProps}
        head={
          <style>
            {`
          .ProseMirror {
            outline: none !important;
          }
          .ProseMirror:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        `}
          </style>
        }
        title="Page Editor Content"
        mountTarget="body"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: IFRAME_CONTENT_WIDTH,
          height: height / scale,
          border: 'none',
          outline: 'none',
          ...iframeProps?.style,
        }}
      >
        <StyleManager>{children}</StyleManager>
      </Frame>
    );
  }
);

const NavbarContent = ({
  portalRef
}: {
  portalRef: React.RefObject<HTMLDivElement>;
}) => {
  const { content } = useNavbarContext();
  if (!content) return null;
  return <NavbarSerializer node={content} portalRef={portalRef} />;
};

export const NavbarEditor = ({
  editorContainerRef,
  editorRect,
}: {
  editorContainerRef: React.RefObject<HTMLDivElement>;
  editorRect: Partial<DOMRect>;
}) => {

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const attributesPanelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    attributesPanelEl.current = document.getElementById(ATTRIBUTES_PANEL_ID) as HTMLDivElement;
  }, []);

  return <NavbarProvider iframeRef={iframeRef} editorContainerRef={editorContainerRef}>
    <IframeWrapper width={editorRect.width || 0} height={editorRect.height || 0} ref={iframeRef} >
      <div className="grow relative overflow-y-auto min-h-0 h-screen w-full flex flex-col justify-start items-center"
        id="scrollable-container"
        style={{
          backgroundColor: 'white'
        }}>
        <NavbarContent portalRef={portalRef} />
        <div className="flex flex-col w-full">
          {generateTextSkeleton(300)}
        </div>
      </div>
      <div ref={portalRef} id="portal-ref" className='z-50' />
    </IframeWrapper>
    <SelectionBox />
    <DragHandle />

    <div>
      {attributesPanelEl.current &&
        createPortal(<AttributesPanel />, attributesPanelEl.current)}
    </div>
  </NavbarProvider>;
};
