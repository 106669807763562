import SectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { AlignmentSettings } from "./AlignmentSettings";
import { RowNumberSettings } from "./RowNumberSettings";
import { WidthSettings } from "./WidthSettings";

export function NavMenuLayoutSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_menu" && selectedContent.type !== "navbar_menu_list") return null;

  return <SectionRenderer title="Navigation Bar">
    <AlignmentSettings />
    <WidthSettings />
    <RowNumberSettings />
  </SectionRenderer>;
}
