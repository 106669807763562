/* eslint-disable */
import { ElementType } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import {
  CalendarDaysIcon,
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  TagIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-mini';
import { Button } from '@/ui/Button';

import useCampaignReport from '@/hooks/useAdNetwork/advertiser_access/useCampaignReport';
import { AdNetworkCampaignReport, AdNetworkCampaignReportCampaignMonthlyBreakdown } from '@/interfaces/ad_network/advertiser_access/campaign_report';
import currencyFormatter from '@/pages/Grow/Boosts/utils/currencyFormatter';
import useDownloadCsv from './_hooks/useDownloadCsv';
import ScaledPreviews from '@/components/ScaledPreviews';
import TabNavigation from '@/components/TabNavigation';
import { Tab } from '@/interfaces/general';
import opportunities from '@/routes/ad_network/publications/$publication_id/opportunities';
import useMonthlyBreakdown from '@/hooks/useAdNetwork/advertiser_access/useMonthlyBreakdown';
import { Heading } from '@/ui/Heading';

import cx from 'classnames';
import { LoadingSpinner } from '@/components/LoadingSpinner';

interface Props {
  campaignReport: AdNetworkCampaignReport;
  advertiserId: string;
  campaignId: string;
}

const formatter = new Intl.NumberFormat('en-US');

const KPICardBase = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="p-6 rounded-lg bg-white flex items-center border-2 border-gray-900  duration-300">
      <div className="space-y-3 w-full">{children}</div>
    </div>
  );
};

const KPICard = ({ value, label, Icon }: { value: string; label: string; Icon: ElementType }) => {
  return (
    <KPICardBase>
      <div className="flex">
        <div className="bg-primary-50 rounded-full flex p-4 text-primary-500">
          <Icon className="h-6 w-6" />
        </div>
      </div>
      <p className="text-feedback-info-600 font-black text-4xl truncate">{value}</p>
      <p className="font-bold truncate">{label}</p>
    </KPICardBase>
  );
};

const Campaign = ({ campaignReport, advertiserId, campaignId }: Props) => {
  const { pathname } = window.location;

  const tabs = [
    {
      name: 'delivered',
      label: 'Delivered',
      targetRoute: `./delivered`,
      selected: pathname.includes('/delivered'),
    },
    {
      name: 'upcoming',
      label: 'Upcoming',
      targetRoute: `./upcoming`,
      selected: pathname.includes('/upcoming'),
    },
  ] as Tab[];

  const {mutate: csvMutate, isLoading: csvIsLoading} = useDownloadCsv({advertiserId: campaignReport.advertiser.id, campaignId: campaignReport.campaign.id});
  const { data: monthlyBreakdown, isSuccess: breakdownIsSuccess } = useMonthlyBreakdown({ campaignId, advertiserId });

  return (
    <div className="divide-y-2 divide-gray-900">
      <section className="py-12 px-6 space-y-6">
        <div className="space-y-6 max-w-6xl mx-auto flex justify-between items-center">
          <Heading as="h1">Ad Network Reporting</Heading>
          <div className="flex">
            <img
              src={campaignReport.advertiser.logo_url}
              className="border object-cover h-24 rounded"
              alt={campaignReport.advertiser.name}
            />
          </div>
        </div>
      </section>
      <section className="py-12 px-6">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Campaign Details</Heading>
            <p>A quick look at the campaign&apos;s key details and goals.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <KPICardBase>
              <p>{campaignReport.advertiser.name}</p>
            </KPICardBase>
            <KPICardBase>
              <p className="font-bold">Campaign Name:</p>
              <p>{campaignReport.campaign.name}</p>
            </KPICardBase>
            <KPICardBase>
              <p className="font-bold">Campaign Dates:</p>
              <p>
                {moment(campaignReport.campaign.window_start_date).format('LL')} -{' '}
                {!campaignReport.campaign.window_end_date
                  ? 'TBD'
                  : moment(campaignReport.campaign.window_end_date).format('LL')}
              </p>
            </KPICardBase>
            <KPICard value={campaignReport.campaign.total_spend} label="Campaign Budget" Icon={TagIcon} />
            <KPICard value={campaignReport.campaign.cost_per_click} label="CPC" Icon={CurrencyDollarIcon} />
            <KPICard
              value={formatter.format(campaignReport.campaign.clicks_goal)}
              label="Guaranteed Unique Clicks"
              Icon={CursorArrowRaysIcon}
            />
          </div>
        </div>
      </section>
      <section className="py-12 px-6 bg-pink-200">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Performance Overview</Heading>
            <p>Current delivery data, updated in real time.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={formatter.format(campaignReport.performance.total_impressions)}
                label="Impressions"
                Icon={EyeIcon}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={`${campaignReport.performance.average_click_through_rate}%`}
                label="Average CTR"
                Icon={CurrencyDollarIcon}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={
                  campaignReport.campaign.window_end_date === null
                    ? 'TBD'
                    : formatter.format(
                        Math.max(moment(campaignReport.campaign.window_end_date).diff(moment(), 'days'), 0)
                      )
                }
                label="Days Remaining"
                Icon={CalendarDaysIcon}
              />
            </div>
            <div className="col-span-3">
              <KPICardBase>
                <div className="grid grid-cols-1 md:grid-cols-4 md:divide-x-2 divide-y-2 md:divide-y-0">
                  <div className="space-y-3 col-span-3 md:pr-4 pb-4 md:pb-0">
                    <div className="flex">
                      <div className="bg-primary-50 rounded-full flex p-4 text-primary-500">
                        <CursorArrowRaysIcon className="h-6 w-6" />
                      </div>
                    </div>
                    <p className="text-feedback-info-600 font-black text-4xl">
                      {formatter.format(campaignReport.performance.completed_clicks)}/
                      {formatter.format(campaignReport.campaign.clicks_goal)}
                    </p>
                    <div className="h-3 bg-feedback-info-100 rounded-full">
                      <div
                        className="bg-feedback-info-500 rounded-full h-full"
                        style={{
                          width: `${Math.min(
                            (campaignReport.performance.completed_clicks / campaignReport.campaign.clicks_goal) * 100,
                            100
                          )}%`,
                          minWidth: '0.75rem',
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                    <p className="font-bold">Payable (unique) clicks</p>
                  </div>
                  <div className="space-y-3 col-span-1 md:pl-4 pt-4 md:pt-0 flex flex-col justify-center w-full ">
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Total Clicks</span>
                        <span>{formatter.format(campaignReport.performance.total_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Unique Clicks</span>
                        <span>{formatter.format(campaignReport.performance.completed_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Remaining Clicks</span>
                        <span>{formatter.format(campaignReport.performance.remaining_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Upcoming Est. Clicks</span>
                        <span>{formatter.format(campaignReport.performance.upcoming_estimated_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Avg. CTR</span>
                        <span>{campaignReport.performance.average_click_through_rate}%</span>
                      </p>
                    </div>
                  </div>
                </div>
              </KPICardBase>
            </div>
            <div className="col-span-3">
              <KPICardBase>
                <h3 className="uppercase font-black text-2xl">Monthly Breakdown</h3>
                {breakdownIsSuccess ? (
                  <>
                    <div className="grid grid-cols-3 font-bold">
                      <div>&nbsp;</div>
                      <div className="text-center">Unique Clicks</div>
                      <div className="text-center">Spend</div>
                    </div>
                    {monthlyBreakdown.map((month) => (
                      <div key={moment(month.date).format('YMD')} className="grid grid-cols-3 font-bold">
                        <div className="text-right">{moment(month.date).utc().format('MMMM Y')}</div>
                        <div className="text-center">{month.payable_clicks || 0}</div>
                        <div className="text-center">{currencyFormatter.format(month.revenue || 0)}</div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center text-gray-400 text-sm h-12">
                    <LoadingSpinner className="mr-2" />
                    <p>Loading chart...</p>
                  </div>
                )}
              </KPICardBase>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 px-6">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Creative Previews</Heading>
            <p>Check out the available advertisements for this campaign.</p>
          </div>
          <div className="flex gap-6">
            <ScaledPreviews
              advertisements={campaignReport.advertisements}
            />
          </div>
        </div>
      </section>
      <section className="pt-12 pb-0 px-6">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div className="grid grid-cols-3">
            <div className="col-span-2">
              <Heading as="h2">Delivery Breakdown</Heading>
              <p>Deep dive into each placement.</p>
            </div>
            <div className="col-span-1">
              <Button
                variant="primary-inverse"
                size="xs"
                disabled={!opportunities}
                type="button"
                onClick={csvMutate}
                loading={csvIsLoading}
                className="float-right"
              >
                Download CSV
              </Button>
            </div>
          </div>
          <TabNavigation tabs={tabs} className="sm:border-b mb-4" />
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId, campaign_id: campaignId } = useParams<{
    advertiser_id: string;
    campaign_id: string;
  }>() as { advertiser_id: string; campaign_id: string };

  const { data, isSuccess } = useCampaignReport({ campaignId, advertiserId });

  if (!isSuccess) return null;

  return (
    <Campaign
      campaignReport={data}
      advertiserId={advertiserId}
      campaignId={campaignId}
    />
  );
}
