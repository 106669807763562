import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

import { useCurrentUser } from '../../../../../context/current-user-context';
import { useSettings } from '../../../../../context/settings-context';
import { EmailMessage } from '../../../../../interfaces/email_message';
import { IUser } from '../../../../../interfaces/user';
import api from '../../../../../services/swarm';
import { Checkbox, Textarea } from '../../../../Form';
import { LoadingSpinner } from '../../../../LoadingSpinner';
import SubscriberSelect from '../../../../SubscriberSelect';

interface TestProps {
  emailMessage: EmailMessage;
}

const Test: React.FunctionComponent<TestProps> = ({ emailMessage }: TestProps) => {
  const { currentUser } = useCurrentUser();
  const { settings } = useSettings();
  const [emailOptions, setEmailOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([currentUser?.email]);
  const [additionalEmails, setAdditionalEmails] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();

  const fetchUsers = useCallback(() => {
    if (currentUser) {
      api.get(`/publications/${emailMessage.publication_id}/users`).then((resp) => {
        setIsLoading(false);
        setEmailOptions(resp.data.map((u: IUser) => u.email));
      });
    }
  }, [currentUser, emailMessage.publication_id]);

  const submitTest = () => {
    api
      .post(`/email_messages/${emailMessage.id}/test_send`, {
        publication_id: emailMessage.publication_id,
        test_send: {
          selected_emails: selectedEmails,
          extra_addresses: additionalEmails,
          current_subscriber_id: currentSubscriberId,
        },
      })
      .then(() => {
        toast.success('Test email sent');
      })
      .catch((errPayload) => {
        toast.error(errPayload.response.data.error || 'There was a problem sending your test');
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (!currentUser || !settings) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner className="mr-3" />
          <p className="text-gray-500">Loading</p>
        </div>
      ) : (
        <div className="h-full px-4 pt-4 pb-2 overflow-auto">
          <p className="text-xl text-gray-800 mb-2">Send a test</p>
          {settings.external_test_sends ? (
            <p className="text-sm text-gray-500">Select up to 10 email addresses to receive the test.</p>
          ) : (
            <p className="text-sm text-gray-500">
              Select up to 10 email addresses to receive the test. If you need to send tests to emails outside of your
              workspace,&nbsp;
              <a
                className="underline hover:text-primary-600"
                href="https://support.beehiiv.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer"
              >
                contact support
              </a>
            </p>
          )}
          <div className="mt-8 space-y-3">
            {emailOptions.map((email) => (
              <Checkbox
                key={email}
                labelText={email}
                name={email}
                checked={selectedEmails.includes(email)}
                onChange={(checked) => {
                  if (checked) {
                    setSelectedEmails([...selectedEmails, email]);
                  } else {
                    setSelectedEmails(selectedEmails.filter((e) => e !== email));
                  }
                }}
              />
            ))}
          </div>
          {settings.external_test_sends && (
            <div className="mt-8">
              <Textarea
                name="additional_emails"
                labelText="Enter additional emails, separated by commas"
                value={additionalEmails}
                onChange={(e) => setAdditionalEmails(e.target.value)}
              />
            </div>
          )}
          <div className="mt-16">
            <p className="text-lg text-gray-800 mb-4">Advanced</p>
            <SubscriberSelect
              labelText="Simulated Subscriber"
              helperText="This still only sends the test to the emails selected above, but will send the email personalized as if sent to this selected subscriber. Great for testing personalization or referral program."
              onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
              onClearSubscriber={() => setCurrentSubscriberId('')}
            />
          </div>
          <div className="flex justify-end mt-8">
            <Button variant="primary-inverse" onClick={submitTest}>
              Send
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Test;
