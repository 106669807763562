import React, { createElement } from 'react';
import cx from 'classnames';

interface LoaderProps {
  isLoading: boolean;
  children: React.ReactNode;
  skeletons?: React.ReactNode | React.ReactNode[];
  className?: string;
  as?: string;
  useFragment?: boolean;
}

const SkeletonLoader: React.FC<LoaderProps> = ({
  isLoading,
  children,
  skeletons,
  className,
  as = 'div',
  useFragment = false,
}: LoaderProps) => {
  const returningElements = isLoading ? (
    <div className={cx('w-full animate-pulse', className)}>{skeletons}</div>
  ) : (
    children
  );

  if (useFragment) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{returningElements}</>;
  }

  return createElement(as, {}, returningElements);
};

SkeletonLoader.defaultProps = {
  className: '',
};

export default SkeletonLoader;
