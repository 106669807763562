import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { PostView } from './views/PostView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    post: {
      setPost: () => ReturnType;
    };
  }
}

type CardSchema = {
  node: string;
  className: string;
  children: CardSchema[];
};

const cardStructure: CardSchema = {
  node: 'div',
  className: 'flex flex-col gap-3 w-full w-full',
  children: [
    {
      node: 'image',
      className: '',
      children: [],
    },
    {
      node: 'div',
      className: 'flex flex-col gap-2',
      children: [
        {
          node: 'tags',
          className: '',
          children: [],
        },
        {
          node: 'div',
          className: 'flex flex-col gap-1',
          children: [
            {
              node: 'titles',
              className: '',
              children: [],
            },
            {
              node: 'authors',
              className: 'text-xs',
              children: [],
            },
            {
              node: 'div',
              className: 'flex flex-row gap-1 items-center',
              children: [
                {
                  node: 'timestamp',
                  className: '',
                  children: [],
                },
                {
                  node: 'premium',
                  className: '',
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const Post = Node.create<{}, {}>({
  name: 'post',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },

      // Used to determine if this is fake data
      insertedFromSidebar: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },

      gap: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      cardCount: {
        default: 3,
        parseHTML: (element) => element.getAttribute('data-card-count'),
        renderHTML: (attributes) => ({ 'data-card-count': attributes.cardCount }),
      },
      orientation: {
        default: 'vertical',
        parseHTML: (element) => element.getAttribute('data-orientation'),
        renderHTML: (attributes) => ({ 'data-orientation': attributes.orientation }),
      },
      variant: {
        default: 'vertical',
        parseHTML: (element) => element.getAttribute('data-variant'),
        renderHTML: (attributes) => ({ 'data-variant': attributes.variant }),
      },
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({ 'data-text-align': attributes.textAlign }),
      },
      background: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-background'),
        renderHTML: (attributes) => ({ 'data-background': attributes.background }),
      },
      color: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => ({ 'data-color': attributes.color }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      dividerTop: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-divider-top'),
        renderHTML: (attributes) => ({ 'data-divider-top': attributes.dividerTop }),
      },
      dividerColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-divider-color'),
        renderHTML: (attributes) => ({ 'data-divider-color': attributes.dividerColor }),
      },
      dividerStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-divider-style'),
        renderHTML: (attributes) => ({ 'data-divider-style': attributes.dividerStyle }),
      },
      shadow: {
        default: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      border_radius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.border_radius }),
      },

      // Image
      imageEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-image-enabled'),
        renderHTML: (attributes) => ({ 'data-image-enabled': attributes.imageEnabled }),
      },

      // Tags
      tagTextColor: {
        default: '#111827',
        parseHTML: (element) => element.getAttribute('data-tag-text-color'),
        renderHTML: (attributes) => ({ 'data-tag-text-color': attributes.tagTextColor }),
      },
      tagTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-tag-transform'),
        renderHTML: (attributes) => ({ 'data-tag-transform': attributes.tagTransform }),
      },
      tagBadgeEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-tag-badge-enabled'),
        renderHTML: (attributes) => ({ 'data-tag-badge-enabled': attributes.tagBadgeEnabled }),
      },
      tagBackgroundColor: {
        default: '#f3f4f6',
        parseHTML: (element) => element.getAttribute('data-tag-background'),
        renderHTML: (attributes) => ({ 'data-tag-background': attributes.tagBackground }),
      },
      tagBorderRadius: {
        default: '9999px',
        parseHTML: (element) => element.getAttribute('data-tag-border-radius'),
        renderHTML: (attributes) => ({ 'data-tag-border-radius': attributes.tagBorderRadius }),
      },

      // Titles
      titleTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-title-text-color'),
        renderHTML: (attributes) => ({ 'data-title-text-color': attributes.titleTextColor }),
      },
      titleFontSize: {
        default: 'text-lg',
        parseHTML: (element) => element.getAttribute('data-title-font-size'),
        renderHTML: (attributes) => ({ 'data-title-font-size': attributes.titleFontSize }),
      },
      titleLineClamp: {
        default: 'line-clamp-none',
        parseHTML: (element) => element.getAttribute('data-title-line-clamp'),
        renderHTML: (attributes) => ({ 'data-title-line-clamp': attributes.titleLineClamp }),
      },
      subtitleTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-subtitle-text-color'),
        renderHTML: (attributes) => ({ 'data-subtitle-text-color': attributes.subtitleTextColor }),
      },
      subtitleFontSize: {
        default: '14px',
        parseHTML: (element) => element.getAttribute('data-subtitle-font-size'),
        renderHTML: (attributes) => ({ 'data-subtitle-font-size': attributes.subtitleFontSize }),
      },
      subtitleLineClamp: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-subtitle-line-clamp'),
        renderHTML: (attributes) => ({ 'data-subtitle-line-clamp': attributes.subtitleLineClamp }),
      },
      subtitleShowing: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-subtitle-showing'),
        renderHTML: (attributes) => ({ 'data-subtitle-showing': attributes.subtitleShowing }),
      },

      // Authors
      authorsEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-author-enabled'),
        renderHTML: (attributes) => ({ 'data-author-enabled': attributes.authorEnabled }),
      },
      authorsImagesEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-author-images-enabled'),
        renderHTML: (attributes) => ({ 'data-author-images-enabled': attributes.authorImagesEnabled }),
      },
      authorsTextEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-author-text-enabled'),
        renderHTML: (attributes) => ({ 'data-author-text-enabled': attributes.authorTextEnabled }),
      },
      authorsTruncateListEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-author-truncate-list-enabled'),
        renderHTML: (attributes) => ({ 'data-author-truncate-list-enabled': attributes.authorTruncateListEnabled }),
      },
      authorsTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-author-text-color'),
        renderHTML: (attributes) => ({ 'data-author-text-color': attributes.authorTextColor }),
      },
      authorsTextTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-author-text-transform'),
        renderHTML: (attributes) => ({ 'data-author-text-transform': attributes.authorTextTransform }),
      },

      // Timestamps
      timestampsEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-timestamp-enabled'),
        renderHTML: (attributes) => ({ 'data-timestamp-enabled': attributes.timestampEnabled }),
      },
      timestampsTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-timestamp-text-color'),
        renderHTML: (attributes) => ({ 'data-timestamp-text-color': attributes.timestampTextColor }),
      },
      timestampsDisplay: {
        default: 'short_date',
        parseHTML: (element) => element.getAttribute('data-timestamp-line-clamp'),
        renderHTML: (attributes) => ({ 'data-timestamp-line-clamp': attributes.timestampLineClamp }),
      },

      // Premium
      premiumIconEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-icon-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-icon-enabled': attributes.premiumIconEnabled }),
      },
      premiumTextEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-text-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-text-enabled': attributes.premiumTextEnabled }),
      },
      premiumBadgeEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-badge-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-badge-enabled': attributes.premiumBadgeEnabled }),
      },
      premiumTextColor: {
        default: '#111827',
        parseHTML: (element) => element.getAttribute('data-premium-text-color'),
        renderHTML: (attributes) => ({ 'data-premium-text-color': attributes.premiumTextColor }),
      },
      premiumBackgroundColor: {
        default: '#f3f4f6',
        parseHTML: (element) => element.getAttribute('data-premium-background'),
        renderHTML: (attributes) => ({ 'data-premium-background': attributes.premiumBackground }),
      },
      premiumTextTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-premium-text-transform'),
        renderHTML: (attributes) => ({ 'data-premium-text-transform': attributes.premiumTextTransform }),
      },

      cardStructure: {
        default: cardStructure,
        parseHTML: (element) => element.getAttribute('data-card-structure'),
        renderHTML: (attributes) => ({ 'data-card-structure': attributes.cardStructure }),
      },

      data: {
        default: {
          posts: [],
        },
      },
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setPost:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PostView);
  },
});

export default Post;

// const cardStructure2 = {
//   node: 'div',
//   className: 'flex gap-2',
//   children: [
//     {
//       node: 'image',
//       className: '',
//       children: [],
//     },
//     {
//       node: 'div',
//       className: 'flex flex-col',
//       children: [
//         {
//           node: 'tags',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'titles',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'authors',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'timestamp',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'premium',
//           className: '',
//           children: [],
//         },
//       ],
//     },
//   ],
// };
